// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_CTI_Converter.ts
 * "UC-Server-Access-Protocol-CTI" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_CTI from "./ENetUC_CTI";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";

// [PrintTSEncoderDecoderCode] AsnCtiCallData
export class AsnCtiCallData_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiCallData, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiCallData
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiCallData", newContext);
		value += TSConverter.addStringParam("key", obj.key, newContext);
		value += TSConverter.addStringParam("value", obj.value, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiCallData, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiCallData");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallData"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiCallData;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiCallData
			if (TSConverter.validateParam(data, "key", "string", errors, newContext, false))
				obj.key = data.key;
			if (TSConverter.validateParam(data, "value", "string", errors, newContext, false))
				obj.value = data.value;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallData"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineInfo
export class AsnCtiLineInfo_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineInfo, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineInfo
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineInfo", newContext);
		value += TSConverter.addNumberParam("iType", obj.iType, newContext);
		value += TSConverter.addNumberParam("iLineOpenStatus", obj.iLineOpenStatus, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sDescriptor", obj.u8sDescriptor, newContext);
		value += TSConverter.addStringParam("u8sPhoneNumber", obj.u8sPhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sLineLocationID", obj.u8sLineLocationID, newContext);
		value += TSConverter.addNumberParam("iLineFeatures", obj.iLineFeatures, newContext);
		value += TSConverter.addNumberParam("iForwardFeaturesPBX", obj.iForwardFeaturesPBX, newContext);
		value += TSConverter.addNumberParam("iForwardFeaturesSERVER", obj.iForwardFeaturesSERVER, newContext);
		value += TSConverter.addNumberParam("iForwardFeaturesUSER", obj.iForwardFeaturesUSER, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineInfo, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineInfo");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineInfo"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineInfo;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineInfo
			if (TSConverter.validateParam(data, "iType", "number", errors, newContext, false))
				obj.iType = data.iType;
			if (TSConverter.validateParam(data, "iLineOpenStatus", "number", errors, newContext, false))
				obj.iLineOpenStatus = data.iLineOpenStatus;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "u8sDescriptor", "string", errors, newContext, false))
				obj.u8sDescriptor = data.u8sDescriptor;
			if (TSConverter.validateParam(data, "u8sPhoneNumber", "string", errors, newContext, false))
				obj.u8sPhoneNumber = data.u8sPhoneNumber;
			if (TSConverter.validateParam(data, "u8sLineLocationID", "string", errors, newContext, false))
				obj.u8sLineLocationID = data.u8sLineLocationID;
			if (TSConverter.validateParam(data, "iLineFeatures", "number", errors, newContext, false))
				obj.iLineFeatures = data.iLineFeatures;
			if (TSConverter.validateParam(data, "iForwardFeaturesPBX", "number", errors, newContext, false))
				obj.iForwardFeaturesPBX = data.iForwardFeaturesPBX;
			if (TSConverter.validateParam(data, "iForwardFeaturesSERVER", "number", errors, newContext, false))
				obj.iForwardFeaturesSERVER = data.iForwardFeaturesSERVER;
			if (TSConverter.validateParam(data, "iForwardFeaturesUSER", "number", errors, newContext, false))
				obj.iForwardFeaturesUSER = data.iForwardFeaturesUSER;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineInfo"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRemoteOfficeState
export class AsnRemoteOfficeState_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnRemoteOfficeState, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRemoteOfficeState
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRemoteOfficeState", newContext);
		value += TSConverter.addStringParam("u8sController", obj.u8sController, newContext);
		value += TSConverter.addNumberParam("iEnabled", obj.iEnabled, newContext);
		value += TSConverter.addStringParam("u8sDestination", obj.u8sDestination, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnRemoteOfficeState, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRemoteOfficeState");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRemoteOfficeState"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnRemoteOfficeState;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRemoteOfficeState
			if (TSConverter.validateParam(data, "u8sController", "string", errors, newContext, false))
				obj.u8sController = data.u8sController;
			if (TSConverter.validateParam(data, "iEnabled", "number", errors, newContext, false))
				obj.iEnabled = data.iEnabled;
			if (TSConverter.validateParam(data, "u8sDestination", "string", errors, newContext, false))
				obj.u8sDestination = data.u8sDestination;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRemoteOfficeState"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSnapshotPhoneLineArgument
export class AsnCtiSnapshotPhoneLineArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSnapshotPhoneLineArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSnapshotPhoneLineArgument", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSnapshotPhoneLineArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSnapshotPhoneLineArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSnapshotPhoneLineArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSnapshotPhoneLineArgument
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSnapshotPhoneLineArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineMonitorStartArgument
export class AsnCtiLineMonitorStartArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineMonitorStartArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineMonitorStartArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineMonitorStartArgument", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineMonitorStartArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineMonitorStartArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineMonitorStartArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineMonitorStartArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineMonitorStartArgument
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineMonitorStartArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineMonitorStopArgument
export class AsnCtiLineMonitorStopArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineMonitorStopArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineMonitorStopArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineMonitorStopArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineMonitorStopArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineMonitorStopArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineMonitorStopArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineMonitorStopArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineMonitorStopArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineMonitorStopArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineMonitorStopResult
export class AsnCtiLineMonitorStopResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineMonitorStopResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineMonitorStopResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineMonitorStopResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineMonitorStopResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineMonitorStopResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineMonitorStopResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineMonitorStopResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineMonitorStopResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineMonitorStopResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineSetDoNotDisturbArgument
export class AsnCtiLineSetDoNotDisturbArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineSetDoNotDisturbArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineSetDoNotDisturbArgument", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addBooleanParam("bDoNotDisturb", obj.bDoNotDisturb, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineSetDoNotDisturbArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetDoNotDisturbArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineSetDoNotDisturbArgument
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "bDoNotDisturb", "boolean", errors, newContext, false))
				obj.bDoNotDisturb = data.bDoNotDisturb;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetDoNotDisturbArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineSetDoNotDisturbResult
export class AsnCtiLineSetDoNotDisturbResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineSetDoNotDisturbResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineSetDoNotDisturbResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineSetDoNotDisturbResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetDoNotDisturbResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineSetDoNotDisturbResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineSetDoNotDisturbResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetDoNotDisturbResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineSetRemoteOfficeResult
export class AsnCtiLineSetRemoteOfficeResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineSetRemoteOfficeResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineSetRemoteOfficeResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineSetRemoteOfficeResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetRemoteOfficeResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineSetRemoteOfficeResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetRemoteOfficeResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineSetNewPasswordArgument
export class AsnCtiLineSetNewPasswordArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineSetNewPasswordArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineSetNewPasswordArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineSetNewPasswordArgument", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sPassword", obj.u8sPassword, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineSetNewPasswordArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineSetNewPasswordArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetNewPasswordArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineSetNewPasswordArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineSetNewPasswordArgument
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "u8sPassword", "string", errors, newContext, false))
				obj.u8sPassword = data.u8sPassword;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetNewPasswordArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineSetNewPasswordResult
export class AsnCtiLineSetNewPasswordResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineSetNewPasswordResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineSetNewPasswordResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineSetNewPasswordResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineSetNewPasswordResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineSetNewPasswordResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetNewPasswordResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineSetNewPasswordResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineSetNewPasswordResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetNewPasswordResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiQueryPBXLineArgument
export class AsnCtiQueryPBXLineArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiQueryPBXLineArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiQueryPBXLineArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiQueryPBXLineArgument", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiQueryPBXLineArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiQueryPBXLineArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiQueryPBXLineArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiQueryPBXLineArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiQueryPBXLineArgument
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiQueryPBXLineArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiQueryPBXLineResult
export class AsnCtiQueryPBXLineResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiQueryPBXLineResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiQueryPBXLineResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiQueryPBXLineResult", newContext);
		value += TSConverter.addObjectAsStringParam("seqLinesFound", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.seqLinesFound, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiQueryPBXLineResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiQueryPBXLineResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiQueryPBXLineResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiQueryPBXLineResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiQueryPBXLineResult
			const tscv_seqlinesfound = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.seqLinesFound, tscv_seqlinesfound, errors, newContext, "seqLinesFound", false))
				obj.seqLinesFound = tscv_seqlinesfound;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiQueryPBXLineResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineDoNotDisturbChangedArgument
export class AsnCtiNotifyLineDoNotDisturbChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineDoNotDisturbChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineDoNotDisturbChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addNumberParam("iDoNotDisturb", obj.iDoNotDisturb, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineDoNotDisturbChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineDoNotDisturbChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineDoNotDisturbChangedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "iDoNotDisturb", "number", errors, newContext, false))
				obj.iDoNotDisturb = data.iDoNotDisturb;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineDoNotDisturbChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineInvalidPasswordChangedArgument
export class AsnCtiNotifyLineInvalidPasswordChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineInvalidPasswordChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineInvalidPasswordChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addNumberParam("invalidPasswordState", obj.invalidPasswordState, newContext);
		value += TSConverter.addStringParam("u8sUserName", obj.u8sUserName, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineInvalidPasswordChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineInvalidPasswordChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineInvalidPasswordChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineInvalidPasswordChangedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "invalidPasswordState", "number", errors, newContext, false))
				obj.invalidPasswordState = data.invalidPasswordState;
			if (TSConverter.validateParam(data, "u8sUserName", "string", errors, newContext, false))
				obj.u8sUserName = data.u8sUserName;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineInvalidPasswordChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyMonitorStoppedArgument
export class AsnCtiNotifyMonitorStoppedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyMonitorStoppedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyMonitorStoppedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyMonitorStoppedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyMonitorStoppedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyMonitorStoppedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyMonitorStoppedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyMonitorStoppedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineSortOrderChangedArgument
export class AsnCtiNotifyLineSortOrderChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineSortOrderChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineSortOrderChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addObjectAsStringParam("u8sLineURISortOrder", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sLineURISortOrder, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineSortOrderChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineSortOrderChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineSortOrderChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineSortOrderChangedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			const tscv_u8slineurisortorder = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sLineURISortOrder, tscv_u8slineurisortorder, errors, newContext, "u8sLineURISortOrder", false))
				obj.u8sLineURISortOrder = tscv_u8slineurisortorder;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineSortOrderChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineForwardingChangedArgument
export class AsnCtiNotifyLineForwardingChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineForwardingChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineForwardingChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("seqLineForwards", ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(obj.seqLineForwards, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineForwardingChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineForwardingChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineForwardingChangedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_seqlineforwards = new ENetUC_Common_SIPCTI.AsnLineForwards();
			if (ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(data.seqLineForwards, tscv_seqlineforwards, errors, newContext, "seqLineForwards", false))
				obj.seqLineForwards = tscv_seqlineforwards;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineForwardingChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyMakeCallResultArgument
export class AsnCtiNotifyMakeCallResultArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyMakeCallResultArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyMakeCallResultArgument", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addNumberParam("success", obj.success, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyMakeCallResultArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyMakeCallResultArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyMakeCallResultArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyMakeCallResultArgument
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "success", "number", errors, newContext, false))
				obj.success = data.success;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyMakeCallResultArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiCallFeature
export class AsnCtiCallFeature_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiCallFeature, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiCallFeature
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiCallFeature", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addNumberParam("iFeatures", obj.iFeatures, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiCallFeature, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiCallFeature");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallFeature"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiCallFeature;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiCallFeature
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "iFeatures", "number", errors, newContext, false))
				obj.iFeatures = data.iFeatures;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallFeature"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineProjectSettingsChangedArgument
export class AsnCtiNotifyLineProjectSettingsChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineProjectSettingsChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineProjectSettingsChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sLocationID", obj.u8sLocationID, newContext);
		value += TSConverter.addObjectAsStringParam("projectList", ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.toJSON(obj.projectList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineProjectSettingsChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineProjectSettingsChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineProjectSettingsChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineProjectSettingsChangedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "u8sLocationID", "string", errors, newContext, false))
				obj.u8sLocationID = data.u8sLocationID;
			const tscv_projectlist = new ENetUC_Common_SIPCTI.AsnProjectPinRuleList();
			if (ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.fromJSON(data.projectList, tscv_projectlist, errors, newContext, "projectList", false))
				obj.projectList = tscv_projectlist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineProjectSettingsChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiMakeCallArgument
export class AsnCtiMakeCallArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiMakeCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiMakeCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiMakeCallArgument", newContext);
		value += TSConverter.addStringParam("u8sPhoneNumberFrom", obj.u8sPhoneNumberFrom, newContext);
		value += TSConverter.addStringParam("u8sPhoneNumberTo", obj.u8sPhoneNumberTo, newContext);
		value += TSConverter.addNumberParam("iNumberFormat", obj.iNumberFormat, newContext);
		value += TSConverter.addObjectAsStringParam("newCallParams", ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(obj.newCallParams, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiMakeCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiMakeCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiMakeCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiMakeCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiMakeCallArgument
			if (TSConverter.validateParam(data, "u8sPhoneNumberFrom", "string", errors, newContext, false))
				obj.u8sPhoneNumberFrom = data.u8sPhoneNumberFrom;
			if (TSConverter.validateParam(data, "u8sPhoneNumberTo", "string", errors, newContext, false))
				obj.u8sPhoneNumberTo = data.u8sPhoneNumberTo;
			if (TSConverter.validateParam(data, "iNumberFormat", "number", errors, newContext, false))
				obj.iNumberFormat = data.iNumberFormat;
			const tscv_newcallparams = ENetUC_Common_SIPCTI.AsnCtiNewCallParams.initEmpty();
			if (ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(data.newCallParams, tscv_newcallparams, errors, newContext, "newCallParams", true))
				obj.newCallParams = tscv_newcallparams;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiMakeCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiMakeCallResult
export class AsnCtiMakeCallResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiMakeCallResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiMakeCallResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiMakeCallResult", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiMakeCallResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiMakeCallResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiMakeCallResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiMakeCallResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiMakeCallResult
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiMakeCallResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineSetForwardStateArgument
export class AsnCtiLineSetForwardStateArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineSetForwardStateArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineSetForwardStateArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineSetForwardStateArgument", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("seqLineForwards", ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(obj.seqLineForwards, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineSetForwardStateArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineSetForwardStateArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetForwardStateArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineSetForwardStateArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineSetForwardStateArgument
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_seqlineforwards = new ENetUC_Common_SIPCTI.AsnLineForwards();
			if (ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(data.seqLineForwards, tscv_seqlineforwards, errors, newContext, "seqLineForwards", false))
				obj.seqLineForwards = tscv_seqlineforwards;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetForwardStateArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineSetForwardStateResult
export class AsnCtiLineSetForwardStateResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineSetForwardStateResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineSetForwardStateResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineSetForwardStateResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineSetForwardStateResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineSetForwardStateResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetForwardStateResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineSetForwardStateResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineSetForwardStateResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetForwardStateResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiDropCallArgument
export class AsnCtiDropCallArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiDropCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiDropCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiDropCallArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiDropCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiDropCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiDropCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiDropCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiDropCallArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiDropCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiDropCallResult
export class AsnCtiDropCallResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiDropCallResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiDropCallResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiDropCallResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiDropCallResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiDropCallResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiDropCallResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiDropCallResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiDropCallResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiDropCallResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineDropCallArgument
export class AsnCtiLineDropCallArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineDropCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineDropCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineDropCallArgument", newContext);
		value += TSConverter.addStringParam("u8sLineDescriptor", obj.u8sLineDescriptor, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineDropCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineDropCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineDropCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineDropCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineDropCallArgument
			if (TSConverter.validateParam(data, "u8sLineDescriptor", "string", errors, newContext, false))
				obj.u8sLineDescriptor = data.u8sLineDescriptor;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineDropCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineDropCallResult
export class AsnCtiLineDropCallResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineDropCallResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineDropCallResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineDropCallResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineDropCallResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineDropCallResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineDropCallResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineDropCallResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineDropCallResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineDropCallResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiAnswerCallArgument
export class AsnCtiAnswerCallArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiAnswerCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiAnswerCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiAnswerCallArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiAnswerCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiAnswerCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiAnswerCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiAnswerCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiAnswerCallArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiAnswerCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiAnswerCallResult
export class AsnCtiAnswerCallResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiAnswerCallResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiAnswerCallResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiAnswerCallResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiAnswerCallResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiAnswerCallResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiAnswerCallResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiAnswerCallResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiAnswerCallResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiAnswerCallResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiRedirectCallArgument
export class AsnCtiRedirectCallArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiRedirectCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiRedirectCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiRedirectCallArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addStringParam("u8sPhoneNumber", obj.u8sPhoneNumber, newContext);
		value += TSConverter.addNumberParam("iNumberFormat", obj.iNumberFormat, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiRedirectCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiRedirectCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiRedirectCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiRedirectCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiRedirectCallArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "u8sPhoneNumber", "string", errors, newContext, false))
				obj.u8sPhoneNumber = data.u8sPhoneNumber;
			if (TSConverter.validateParam(data, "iNumberFormat", "number", errors, newContext, false))
				obj.iNumberFormat = data.iNumberFormat;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiRedirectCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiRedirectCallResult
export class AsnCtiRedirectCallResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiRedirectCallResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiRedirectCallResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiRedirectCallResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiRedirectCallResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiRedirectCallResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiRedirectCallResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiRedirectCallResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiRedirectCallResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiRedirectCallResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiPickupCallArgument
export class AsnCtiPickupCallArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiPickupCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiPickupCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiPickupCallArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addStringParam("u8sPhoneNumber", obj.u8sPhoneNumber, newContext);
		value += TSConverter.addNumberParam("iNumberFormat", obj.iNumberFormat, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiPickupCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiPickupCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiPickupCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiPickupCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiPickupCallArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "u8sPhoneNumber", "string", errors, newContext, false))
				obj.u8sPhoneNumber = data.u8sPhoneNumber;
			if (TSConverter.validateParam(data, "iNumberFormat", "number", errors, newContext, false))
				obj.iNumberFormat = data.iNumberFormat;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiPickupCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiPickupCallResult
export class AsnCtiPickupCallResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiPickupCallResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiPickupCallResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiPickupCallResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiPickupCallResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiPickupCallResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiPickupCallResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiPickupCallResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiPickupCallResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiPickupCallResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiStartCallRecordingArgument
export class AsnCtiStartCallRecordingArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiStartCallRecordingArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiStartCallRecordingArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiStartCallRecordingArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiStartCallRecordingArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiStartCallRecordingArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiStartCallRecordingArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiStartCallRecordingArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiStartCallRecordingArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiStartCallRecordingArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiStartCallRecordingResult
export class AsnCtiStartCallRecordingResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiStartCallRecordingResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiStartCallRecordingResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiStartCallRecordingResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiStartCallRecordingResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiStartCallRecordingResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiStartCallRecordingResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiStartCallRecordingResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiStartCallRecordingResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiStartCallRecordingResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiStopCallRecordingArgument
export class AsnCtiStopCallRecordingArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiStopCallRecordingArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiStopCallRecordingArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiStopCallRecordingArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiStopCallRecordingArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiStopCallRecordingArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiStopCallRecordingArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiStopCallRecordingArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiStopCallRecordingArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiStopCallRecordingArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiStopCallRecordingResult
export class AsnCtiStopCallRecordingResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiStopCallRecordingResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiStopCallRecordingResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiStopCallRecordingResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiStopCallRecordingResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiStopCallRecordingResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiStopCallRecordingResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiStopCallRecordingResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiStopCallRecordingResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiStopCallRecordingResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSetCallDataResult
export class AsnCtiSetCallDataResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSetCallDataResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSetCallDataResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSetCallDataResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSetCallDataResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSetCallDataResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetCallDataResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSetCallDataResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSetCallDataResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetCallDataResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiMergeCallsArgument
export class AsnCtiMergeCallsArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiMergeCallsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiMergeCallsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiMergeCallsArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID1", obj.u8sConnectionID1, newContext);
		value += TSConverter.addStringParam("u8sConnectionID2", obj.u8sConnectionID2, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiMergeCallsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiMergeCallsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiMergeCallsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiMergeCallsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiMergeCallsArgument
			if (TSConverter.validateParam(data, "u8sConnectionID1", "string", errors, newContext, false))
				obj.u8sConnectionID1 = data.u8sConnectionID1;
			if (TSConverter.validateParam(data, "u8sConnectionID2", "string", errors, newContext, false))
				obj.u8sConnectionID2 = data.u8sConnectionID2;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiMergeCallsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiMergeCallsResult
export class AsnCtiMergeCallsResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiMergeCallsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiMergeCallsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiMergeCallsResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiMergeCallsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiMergeCallsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiMergeCallsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiMergeCallsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiMergeCallsResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiMergeCallsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSwapHoldArgument
export class AsnCtiSwapHoldArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSwapHoldArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSwapHoldArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSwapHoldArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSwapHoldArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSwapHoldArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSwapHoldArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSwapHoldArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSwapHoldArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSwapHoldArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSwapHoldResult
export class AsnCtiSwapHoldResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSwapHoldResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSwapHoldResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSwapHoldResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSwapHoldResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSwapHoldResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSwapHoldResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSwapHoldResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSwapHoldResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSwapHoldResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiHoldArgument
export class AsnCtiHoldArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiHoldArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiHoldArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiHoldArgument", newContext);
		value += TSConverter.addBooleanParam("bHold", obj.bHold, newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiHoldArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiHoldArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiHoldArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiHoldArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiHoldArgument
			if (TSConverter.validateParam(data, "bHold", "boolean", errors, newContext, false))
				obj.bHold = data.bHold;
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiHoldArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiHoldResult
export class AsnCtiHoldResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiHoldResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiHoldResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiHoldResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiHoldResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiHoldResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiHoldResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiHoldResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiHoldResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiHoldResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSetForwardArgument
export class AsnCtiSetForwardArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSetForwardArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSetForwardArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSetForwardArgument", newContext);
		value += TSConverter.addStringParam("u8sPhoneNumberFrom", obj.u8sPhoneNumberFrom, newContext);
		value += TSConverter.addStringParam("u8sPhoneNumberTo", obj.u8sPhoneNumberTo, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSetForwardArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSetForwardArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetForwardArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSetForwardArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSetForwardArgument
			if (TSConverter.validateParam(data, "u8sPhoneNumberFrom", "string", errors, newContext, false))
				obj.u8sPhoneNumberFrom = data.u8sPhoneNumberFrom;
			if (TSConverter.validateParam(data, "u8sPhoneNumberTo", "string", errors, newContext, false))
				obj.u8sPhoneNumberTo = data.u8sPhoneNumberTo;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetForwardArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSetForwardResult
export class AsnCtiSetForwardResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSetForwardResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSetForwardResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSetForwardResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addBooleanParam("bExistingForwardingChanged", obj.bExistingForwardingChanged, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSetForwardResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSetForwardResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetForwardResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSetForwardResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSetForwardResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			if (TSConverter.validateParam(data, "bExistingForwardingChanged", "boolean", errors, newContext, false))
				obj.bExistingForwardingChanged = data.bExistingForwardingChanged;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetForwardResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiRemoveForwardArgument
export class AsnCtiRemoveForwardArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiRemoveForwardArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiRemoveForwardArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiRemoveForwardArgument", newContext);
		value += TSConverter.addStringParam("u8sPhoneNumberFrom", obj.u8sPhoneNumberFrom, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiRemoveForwardArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiRemoveForwardArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiRemoveForwardArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiRemoveForwardArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiRemoveForwardArgument
			if (TSConverter.validateParam(data, "u8sPhoneNumberFrom", "string", errors, newContext, false))
				obj.u8sPhoneNumberFrom = data.u8sPhoneNumberFrom;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiRemoveForwardArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiRemoveForwardResult
export class AsnCtiRemoveForwardResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiRemoveForwardResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiRemoveForwardResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiRemoveForwardResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiRemoveForwardResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiRemoveForwardResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiRemoveForwardResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiRemoveForwardResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiRemoveForwardResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiRemoveForwardResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiDialDigitsArgument
export class AsnCtiDialDigitsArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiDialDigitsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiDialDigitsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiDialDigitsArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addNumberParam("iDigitMode", obj.iDigitMode, newContext);
		value += TSConverter.addNumberParam("iDigitDuration", obj.iDigitDuration, newContext);
		value += TSConverter.addStringParam("u8sDigitsToDial", obj.u8sDigitsToDial, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiDialDigitsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiDialDigitsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiDialDigitsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiDialDigitsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiDialDigitsArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "iDigitMode", "number", errors, newContext, false))
				obj.iDigitMode = data.iDigitMode;
			if (TSConverter.validateParam(data, "iDigitDuration", "number", errors, newContext, false))
				obj.iDigitDuration = data.iDigitDuration;
			if (TSConverter.validateParam(data, "u8sDigitsToDial", "string", errors, newContext, false))
				obj.u8sDigitsToDial = data.u8sDigitsToDial;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiDialDigitsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiDialDigitsResult
export class AsnCtiDialDigitsResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiDialDigitsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiDialDigitsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiDialDigitsResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiDialDigitsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiDialDigitsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiDialDigitsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiDialDigitsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiDialDigitsResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiDialDigitsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiCompleteCallArgument
export class AsnCtiCompleteCallArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiCompleteCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiCompleteCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiCompleteCallArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiCompleteCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiCompleteCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCompleteCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiCompleteCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiCompleteCallArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCompleteCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiCompleteCallResult
export class AsnCtiCompleteCallResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiCompleteCallResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiCompleteCallResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiCompleteCallResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiCompleteCallResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiCompleteCallResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCompleteCallResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiCompleteCallResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiCompleteCallResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCompleteCallResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiConferenceArgument
export class AsnCtiConferenceArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiConferenceArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiConferenceArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiConferenceArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID1", obj.u8sConnectionID1, newContext);
		value += TSConverter.addStringParam("u8sConnectionID2", obj.u8sConnectionID2, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiConferenceArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiConferenceArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiConferenceArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiConferenceArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiConferenceArgument
			if (TSConverter.validateParam(data, "u8sConnectionID1", "string", errors, newContext, false))
				obj.u8sConnectionID1 = data.u8sConnectionID1;
			if (TSConverter.validateParam(data, "u8sConnectionID2", "string", errors, newContext, false))
				obj.u8sConnectionID2 = data.u8sConnectionID2;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiConferenceArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiConferenceResult
export class AsnCtiConferenceResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiConferenceResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiConferenceResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiConferenceResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiConferenceResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiConferenceResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiConferenceResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiConferenceResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiConferenceResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiConferenceResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSetupConferenceArgument
export class AsnCtiSetupConferenceArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSetupConferenceArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSetupConferenceArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSetupConferenceArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addStringParam("u8sPhoneNumberTo", obj.u8sPhoneNumberTo, newContext);
		value += TSConverter.addNumberParam("iNumberFormat", obj.iNumberFormat, newContext);
		value += TSConverter.addObjectAsStringParam("newCallParams", ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(obj.newCallParams, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSetupConferenceArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSetupConferenceArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetupConferenceArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSetupConferenceArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSetupConferenceArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "u8sPhoneNumberTo", "string", errors, newContext, false))
				obj.u8sPhoneNumberTo = data.u8sPhoneNumberTo;
			if (TSConverter.validateParam(data, "iNumberFormat", "number", errors, newContext, false))
				obj.iNumberFormat = data.iNumberFormat;
			const tscv_newcallparams = ENetUC_Common_SIPCTI.AsnCtiNewCallParams.initEmpty();
			if (ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(data.newCallParams, tscv_newcallparams, errors, newContext, "newCallParams", true))
				obj.newCallParams = tscv_newcallparams;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetupConferenceArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSetupConferenceResult
export class AsnCtiSetupConferenceResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSetupConferenceResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSetupConferenceResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSetupConferenceResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSetupConferenceResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSetupConferenceResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetupConferenceResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSetupConferenceResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSetupConferenceResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetupConferenceResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSetActiveLineAddressArgument
export class AsnCtiSetActiveLineAddressArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSetActiveLineAddressArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSetActiveLineAddressArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSetActiveLineAddressArgument", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addNumberParam("iAddressID", obj.iAddressID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSetActiveLineAddressArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSetActiveLineAddressArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetActiveLineAddressArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSetActiveLineAddressArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSetActiveLineAddressArgument
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "iAddressID", "number", errors, newContext, false))
				obj.iAddressID = data.iAddressID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetActiveLineAddressArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSetActiveLineAddressResult
export class AsnCtiSetActiveLineAddressResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSetActiveLineAddressResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSetActiveLineAddressResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSetActiveLineAddressResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSetActiveLineAddressResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSetActiveLineAddressResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetActiveLineAddressResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSetActiveLineAddressResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSetActiveLineAddressResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetActiveLineAddressResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineActiveAddressChangedArgument
export class AsnCtiNotifyLineActiveAddressChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineActiveAddressChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineActiveAddressChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addNumberParam("iAddressID", obj.iAddressID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineActiveAddressChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineActiveAddressChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineActiveAddressChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineActiveAddressChangedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "iAddressID", "number", errors, newContext, false))
				obj.iAddressID = data.iAddressID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineActiveAddressChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnMobileRegisterDeviceArgument
export class AsnMobileRegisterDeviceArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnMobileRegisterDeviceArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnMobileRegisterDeviceArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnMobileRegisterDeviceArgument", newContext);
		value += TSConverter.addNumberParam("iRegisterType", obj.iRegisterType, newContext);
		value += TSConverter.addStringParam("u8sDeviceIMEI", obj.u8sDeviceIMEI, newContext);
		value += TSConverter.addStringParam("u8sUserIMSI", obj.u8sUserIMSI, newContext);
		value += TSConverter.addStringParam("u8sDevicePhoneNumber", obj.u8sDevicePhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sGroupName", obj.u8sGroupName, newContext);
		value += TSConverter.addStringParam("u8sNetworkAvailable", obj.u8sNetworkAvailable, newContext);
		value += TSConverter.addNumberParam("iLineFeatures", obj.iLineFeatures, newContext);
		value += TSConverter.addNumberParam("iForwardFeaturesPBX", obj.iForwardFeaturesPBX, newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnMobileRegisterDeviceArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnMobileRegisterDeviceArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnMobileRegisterDeviceArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnMobileRegisterDeviceArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnMobileRegisterDeviceArgument
			if (TSConverter.validateParam(data, "iRegisterType", "number", errors, newContext, false))
				obj.iRegisterType = data.iRegisterType;
			if (TSConverter.validateParam(data, "u8sDeviceIMEI", "string", errors, newContext, false))
				obj.u8sDeviceIMEI = data.u8sDeviceIMEI;
			if (TSConverter.validateParam(data, "u8sUserIMSI", "string", errors, newContext, false))
				obj.u8sUserIMSI = data.u8sUserIMSI;
			if (TSConverter.validateParam(data, "u8sDevicePhoneNumber", "string", errors, newContext, false))
				obj.u8sDevicePhoneNumber = data.u8sDevicePhoneNumber;
			if (TSConverter.validateParam(data, "u8sGroupName", "string", errors, newContext, false))
				obj.u8sGroupName = data.u8sGroupName;
			if (TSConverter.validateParam(data, "u8sNetworkAvailable", "string", errors, newContext, false))
				obj.u8sNetworkAvailable = data.u8sNetworkAvailable;
			if (TSConverter.validateParam(data, "iLineFeatures", "number", errors, newContext, false))
				obj.iLineFeatures = data.iLineFeatures;
			if (TSConverter.validateParam(data, "iForwardFeaturesPBX", "number", errors, newContext, false))
				obj.iForwardFeaturesPBX = data.iForwardFeaturesPBX;
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, true))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnMobileRegisterDeviceArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnMobileRegisterDeviceResult
export class AsnMobileRegisterDeviceResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnMobileRegisterDeviceResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnMobileRegisterDeviceResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnMobileRegisterDeviceResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnMobileRegisterDeviceResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnMobileRegisterDeviceResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnMobileRegisterDeviceResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnMobileRegisterDeviceResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnMobileRegisterDeviceResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnMobileRegisterDeviceResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiCallDataList
export class AsnCtiCallDataList_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiCallDataList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnCtiCallDataList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnCtiCallData_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiCallDataList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiCallDataList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallDataList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiCallDataList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnCtiCallDataList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_CTI.AsnCtiCallData.initEmpty();
				if (AsnCtiCallData_Converter.fromJSON(elem, entry, errors, newContext, "AsnCtiCallData", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallDataList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineInfoList
export class AsnCtiLineInfoList_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineInfoList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnCtiLineInfoList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnCtiLineInfo_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineInfoList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineInfoList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineInfoList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineInfoList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnCtiLineInfoList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_CTI.AsnCtiLineInfo.initEmpty();
				if (AsnCtiLineInfo_Converter.fromJSON(elem, entry, errors, newContext, "AsnCtiLineInfo", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineInfoList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiCallInformation
export class AsnCtiCallInformation_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiCallInformation, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiCallInformation
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiCallInformation", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addNumberParam("iCallState", obj.iCallState, newContext);
		value += TSConverter.addNumberParam("iCallType", obj.iCallType, newContext);
		value += TSConverter.addNumberParam("iDisconnectedReason", obj.iDisconnectedReason, newContext);
		value += TSConverter.addNumberParam("iFeatures", obj.iFeatures, newContext);
		value += TSConverter.addBooleanParam("bOutbound", obj.bOutbound, newContext);
		value += TSConverter.addBooleanParam("bInternal", obj.bInternal, newContext);
		value += TSConverter.addBooleanParam("bCallerIDBlocked", obj.bCallerIDBlocked, newContext);
		value += TSConverter.addNumberParam("iCallRecordingState", obj.iCallRecordingState, newContext);
		value += TSConverter.addBooleanParam("bConferenceCtlr", obj.bConferenceCtlr, newContext);
		value += TSConverter.addStringParam("u8sConferenceCtlrID", obj.u8sConferenceCtlrID, newContext);
		value += TSConverter.addStringParam("u8sLocalNumberSC", obj.u8sLocalNumberSC, newContext);
		value += TSConverter.addStringParam("u8sRemoteNumberSC", obj.u8sRemoteNumberSC, newContext);
		value += TSConverter.addNumberParam("iRemoteContactSource", obj.iRemoteContactSource, newContext);
		value += TSConverter.addObjectAsStringParam("projectPinRule", ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.toJSON(obj.projectPinRule, newContext), newContext);
		value += TSConverter.addStringParam("u8sNote", obj.u8sNote, newContext);
		value += TSConverter.addStringParam("u8sTrackerID", obj.u8sTrackerID, newContext);
		value += TSConverter.addDateParam("utcCallCreated", obj.utcCallCreated, newContext);
		value += TSConverter.addDateParam("utcCallConnected", obj.utcCallConnected, newContext);
		value += TSConverter.addDateParam("utcCallDisconnected", obj.utcCallDisconnected, newContext);
		value += TSConverter.addBooleanParam("bIsUnknownHoldContact", obj.bIsUnknownHoldContact, newContext);
		value += TSConverter.addObjectAsStringParam("pLocalContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.pLocalContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("pRemoteContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.pRemoteContact, newContext), newContext);
		value += TSConverter.addStringParam("pu8sHoldNumberSC", obj.pu8sHoldNumberSC, newContext);
		value += TSConverter.addObjectAsStringParam("pHoldContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.pHoldContact, newContext), newContext);
		value += TSConverter.addStringParam("pu8sRedirectingNumber", obj.pu8sRedirectingNumber, newContext);
		value += TSConverter.addObjectAsStringParam("pRedirectingContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.pRedirectingContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("callDataList", AsnCtiCallDataList_Converter.toJSON(obj.callDataList, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiCallInformation, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiCallInformation");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallInformation"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiCallInformation;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiCallInformation
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "iCallState", "number", errors, newContext, false))
				obj.iCallState = data.iCallState;
			if (TSConverter.validateParam(data, "iCallType", "number", errors, newContext, false))
				obj.iCallType = data.iCallType;
			if (TSConverter.validateParam(data, "iDisconnectedReason", "number", errors, newContext, false))
				obj.iDisconnectedReason = data.iDisconnectedReason;
			if (TSConverter.validateParam(data, "iFeatures", "number", errors, newContext, false))
				obj.iFeatures = data.iFeatures;
			if (TSConverter.validateParam(data, "bOutbound", "boolean", errors, newContext, false))
				obj.bOutbound = data.bOutbound;
			if (TSConverter.validateParam(data, "bInternal", "boolean", errors, newContext, false))
				obj.bInternal = data.bInternal;
			if (TSConverter.validateParam(data, "bCallerIDBlocked", "boolean", errors, newContext, false))
				obj.bCallerIDBlocked = data.bCallerIDBlocked;
			if (TSConverter.validateParam(data, "iCallRecordingState", "number", errors, newContext, false))
				obj.iCallRecordingState = data.iCallRecordingState;
			if (TSConverter.validateParam(data, "bConferenceCtlr", "boolean", errors, newContext, false))
				obj.bConferenceCtlr = data.bConferenceCtlr;
			if (TSConverter.validateParam(data, "u8sConferenceCtlrID", "string", errors, newContext, false))
				obj.u8sConferenceCtlrID = data.u8sConferenceCtlrID;
			if (TSConverter.validateParam(data, "u8sLocalNumberSC", "string", errors, newContext, false))
				obj.u8sLocalNumberSC = data.u8sLocalNumberSC;
			if (TSConverter.validateParam(data, "u8sRemoteNumberSC", "string", errors, newContext, false))
				obj.u8sRemoteNumberSC = data.u8sRemoteNumberSC;
			if (TSConverter.validateParam(data, "iRemoteContactSource", "number", errors, newContext, false))
				obj.iRemoteContactSource = data.iRemoteContactSource;
			const tscv_projectpinrule = ENetUC_Common_SIPCTI.AsnProjectPinRule.initEmpty();
			if (ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.fromJSON(data.projectPinRule, tscv_projectpinrule, errors, newContext, "projectPinRule", false))
				obj.projectPinRule = tscv_projectpinrule;
			if (TSConverter.validateParam(data, "u8sNote", "string", errors, newContext, false))
				obj.u8sNote = data.u8sNote;
			if (TSConverter.validateParam(data, "u8sTrackerID", "string", errors, newContext, false))
				obj.u8sTrackerID = data.u8sTrackerID;
			if (TSConverter.validateParam(data, "utcCallCreated", "string", errors, newContext, false))
				obj.utcCallCreated = new Date(data.utcCallCreated);
			if (TSConverter.validateParam(data, "utcCallConnected", "string", errors, newContext, false))
				obj.utcCallConnected = new Date(data.utcCallConnected);
			if (TSConverter.validateParam(data, "utcCallDisconnected", "string", errors, newContext, false))
				obj.utcCallDisconnected = new Date(data.utcCallDisconnected);
			if (TSConverter.validateParam(data, "bIsUnknownHoldContact", "boolean", errors, newContext, false))
				obj.bIsUnknownHoldContact = data.bIsUnknownHoldContact;
			const tscv_plocalcontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.pLocalContact, tscv_plocalcontact, errors, newContext, "pLocalContact", true))
				obj.pLocalContact = tscv_plocalcontact;
			const tscv_premotecontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.pRemoteContact, tscv_premotecontact, errors, newContext, "pRemoteContact", true))
				obj.pRemoteContact = tscv_premotecontact;
			if (TSConverter.validateParam(data, "pu8sHoldNumberSC", "string", errors, newContext, true))
				obj.pu8sHoldNumberSC = data.pu8sHoldNumberSC;
			const tscv_pholdcontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.pHoldContact, tscv_pholdcontact, errors, newContext, "pHoldContact", true))
				obj.pHoldContact = tscv_pholdcontact;
			if (TSConverter.validateParam(data, "pu8sRedirectingNumber", "string", errors, newContext, true))
				obj.pu8sRedirectingNumber = data.pu8sRedirectingNumber;
			const tscv_predirectingcontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.pRedirectingContact, tscv_predirectingcontact, errors, newContext, "pRedirectingContact", true))
				obj.pRedirectingContact = tscv_predirectingcontact;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
			const tscv_calldatalist = new ENetUC_CTI.AsnCtiCallDataList();
			if (AsnCtiCallDataList_Converter.fromJSON(data.callDataList, tscv_calldatalist, errors, newContext, "callDataList", true))
				obj.callDataList = tscv_calldatalist;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallInformation"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiCallInformationList
export class AsnCtiCallInformationList_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiCallInformationList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnCtiCallInformationList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnCtiCallInformation_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiCallInformationList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiCallInformationList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallInformationList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiCallInformationList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnCtiCallInformationList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_CTI.AsnCtiCallInformation.initEmpty();
				if (AsnCtiCallInformation_Converter.fromJSON(elem, entry, errors, newContext, "AsnCtiCallInformation", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallInformationList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiEnumPhoneLinesArgument
export class AsnCtiEnumPhoneLinesArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiEnumPhoneLinesArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiEnumPhoneLinesArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiEnumPhoneLinesArgument", newContext);
		value += TSConverter.addNumberParam("typeofLines", obj.typeofLines, newContext);
		value += TSConverter.addNumberParam("attachChangeNotify", obj.attachChangeNotify, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiEnumPhoneLinesArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiEnumPhoneLinesArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiEnumPhoneLinesArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiEnumPhoneLinesArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiEnumPhoneLinesArgument
			if (TSConverter.validateParam(data, "typeofLines", "number", errors, newContext, false))
				obj.typeofLines = data.typeofLines;
			if (TSConverter.validateParam(data, "attachChangeNotify", "number", errors, newContext, false))
				obj.attachChangeNotify = data.attachChangeNotify;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiEnumPhoneLinesArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiEnumPhoneLinesResult
export class AsnCtiEnumPhoneLinesResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiEnumPhoneLinesResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiEnumPhoneLinesResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiEnumPhoneLinesResult", newContext);
		value += TSConverter.addObjectAsStringParam("seqLines", AsnCtiLineInfoList_Converter.toJSON(obj.seqLines, newContext), newContext);
		value += TSConverter.addStringParam("u8sLineMonitorCrossRefID", obj.u8sLineMonitorCrossRefID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiEnumPhoneLinesResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiEnumPhoneLinesResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiEnumPhoneLinesResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiEnumPhoneLinesResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiEnumPhoneLinesResult
			const tscv_seqlines = new ENetUC_CTI.AsnCtiLineInfoList();
			if (AsnCtiLineInfoList_Converter.fromJSON(data.seqLines, tscv_seqlines, errors, newContext, "seqLines", false))
				obj.seqLines = tscv_seqlines;
			if (TSConverter.validateParam(data, "u8sLineMonitorCrossRefID", "string", errors, newContext, true))
				obj.u8sLineMonitorCrossRefID = data.u8sLineMonitorCrossRefID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiEnumPhoneLinesResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSnapshotPhoneLineResult
export class AsnCtiSnapshotPhoneLineResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSnapshotPhoneLineResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSnapshotPhoneLineResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSnapshotPhoneLineResult", newContext);
		value += TSConverter.addObjectAsStringParam("lineInfo", AsnCtiLineInfo_Converter.toJSON(obj.lineInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("seqCalls", AsnCtiCallInformationList_Converter.toJSON(obj.seqCalls, newContext), newContext);
		value += TSConverter.addNumberParam("iDoNotDisturb", obj.iDoNotDisturb, newContext);
		value += TSConverter.addObjectAsStringParam("seqLineForwards", ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(obj.seqLineForwards, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("remoteOfficeState", AsnRemoteOfficeState_Converter.toJSON(obj.remoteOfficeState, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSnapshotPhoneLineResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSnapshotPhoneLineResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSnapshotPhoneLineResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSnapshotPhoneLineResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSnapshotPhoneLineResult
			const tscv_lineinfo = ENetUC_CTI.AsnCtiLineInfo.initEmpty();
			if (AsnCtiLineInfo_Converter.fromJSON(data.lineInfo, tscv_lineinfo, errors, newContext, "lineInfo", false))
				obj.lineInfo = tscv_lineinfo;
			const tscv_seqcalls = new ENetUC_CTI.AsnCtiCallInformationList();
			if (AsnCtiCallInformationList_Converter.fromJSON(data.seqCalls, tscv_seqcalls, errors, newContext, "seqCalls", false))
				obj.seqCalls = tscv_seqcalls;
			if (TSConverter.validateParam(data, "iDoNotDisturb", "number", errors, newContext, false))
				obj.iDoNotDisturb = data.iDoNotDisturb;
			const tscv_seqlineforwards = new ENetUC_Common_SIPCTI.AsnLineForwards();
			if (ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(data.seqLineForwards, tscv_seqlineforwards, errors, newContext, "seqLineForwards", false))
				obj.seqLineForwards = tscv_seqlineforwards;
			const tscv_remoteofficestate = ENetUC_CTI.AsnRemoteOfficeState.initEmpty();
			if (AsnRemoteOfficeState_Converter.fromJSON(data.remoteOfficeState, tscv_remoteofficestate, errors, newContext, "remoteOfficeState", false))
				obj.remoteOfficeState = tscv_remoteofficestate;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSnapshotPhoneLineResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineMonitorStartResult
export class AsnCtiLineMonitorStartResult_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineMonitorStartResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineMonitorStartResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineMonitorStartResult", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addObjectAsStringParam("lineInfo", AsnCtiLineInfo_Converter.toJSON(obj.lineInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("seqCalls", AsnCtiCallInformationList_Converter.toJSON(obj.seqCalls, newContext), newContext);
		value += TSConverter.addNumberParam("iDoNotDisturb", obj.iDoNotDisturb, newContext);
		value += TSConverter.addObjectAsStringParam("seqLineForwards", ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(obj.seqLineForwards, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("remoteOfficeState", AsnRemoteOfficeState_Converter.toJSON(obj.remoteOfficeState, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineMonitorStartResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineMonitorStartResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineMonitorStartResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineMonitorStartResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineMonitorStartResult
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			const tscv_lineinfo = ENetUC_CTI.AsnCtiLineInfo.initEmpty();
			if (AsnCtiLineInfo_Converter.fromJSON(data.lineInfo, tscv_lineinfo, errors, newContext, "lineInfo", false))
				obj.lineInfo = tscv_lineinfo;
			const tscv_seqcalls = new ENetUC_CTI.AsnCtiCallInformationList();
			if (AsnCtiCallInformationList_Converter.fromJSON(data.seqCalls, tscv_seqcalls, errors, newContext, "seqCalls", false))
				obj.seqCalls = tscv_seqcalls;
			if (TSConverter.validateParam(data, "iDoNotDisturb", "number", errors, newContext, false))
				obj.iDoNotDisturb = data.iDoNotDisturb;
			const tscv_seqlineforwards = new ENetUC_Common_SIPCTI.AsnLineForwards();
			if (ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(data.seqLineForwards, tscv_seqlineforwards, errors, newContext, "seqLineForwards", false))
				obj.seqLineForwards = tscv_seqlineforwards;
			const tscv_remoteofficestate = ENetUC_CTI.AsnRemoteOfficeState.initEmpty();
			if (AsnRemoteOfficeState_Converter.fromJSON(data.remoteOfficeState, tscv_remoteofficestate, errors, newContext, "remoteOfficeState", false))
				obj.remoteOfficeState = tscv_remoteofficestate;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineMonitorStartResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiLineSetRemoteOfficeArgument
export class AsnCtiLineSetRemoteOfficeArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiLineSetRemoteOfficeArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiLineSetRemoteOfficeArgument", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("remoteOfficeState", AsnRemoteOfficeState_Converter.toJSON(obj.remoteOfficeState, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiLineSetRemoteOfficeArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetRemoteOfficeArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiLineSetRemoteOfficeArgument
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_remoteofficestate = ENetUC_CTI.AsnRemoteOfficeState.initEmpty();
			if (AsnRemoteOfficeState_Converter.fromJSON(data.remoteOfficeState, tscv_remoteofficestate, errors, newContext, "remoteOfficeState", false))
				obj.remoteOfficeState = tscv_remoteofficestate;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiLineSetRemoteOfficeArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineInfoChangedArgument
export class AsnCtiNotifyLineInfoChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineInfoChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineInfoChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("lineInfo", AsnCtiLineInfo_Converter.toJSON(obj.lineInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineInfoChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineInfoChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineInfoChangedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_lineinfo = ENetUC_CTI.AsnCtiLineInfo.initEmpty();
			if (AsnCtiLineInfo_Converter.fromJSON(data.lineInfo, tscv_lineinfo, errors, newContext, "lineInfo", false))
				obj.lineInfo = tscv_lineinfo;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineInfoChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineRemoteOfficeChangedArgument
export class AsnCtiNotifyLineRemoteOfficeChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineRemoteOfficeChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineRemoteOfficeChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("remoteOfficeState", AsnRemoteOfficeState_Converter.toJSON(obj.remoteOfficeState, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineRemoteOfficeChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineRemoteOfficeChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineRemoteOfficeChangedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_remoteofficestate = ENetUC_CTI.AsnRemoteOfficeState.initEmpty();
			if (AsnRemoteOfficeState_Converter.fromJSON(data.remoteOfficeState, tscv_remoteofficestate, errors, newContext, "remoteOfficeState", false))
				obj.remoteOfficeState = tscv_remoteofficestate;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineRemoteOfficeChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineCallInformationChangedArgument
export class AsnCtiNotifyLineCallInformationChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineCallInformationChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineCallInformationChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addNumberParam("reason", obj.reason, newContext);
		value += TSConverter.addObjectAsStringParam("callinformation", AsnCtiCallInformation_Converter.toJSON(obj.callinformation, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineCallInformationChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineCallInformationChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineCallInformationChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineCallInformationChangedArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "reason", "number", errors, newContext, false))
				obj.reason = data.reason;
			const tscv_callinformation = ENetUC_CTI.AsnCtiCallInformation.initEmpty();
			if (AsnCtiCallInformation_Converter.fromJSON(data.callinformation, tscv_callinformation, errors, newContext, "callinformation", false))
				obj.callinformation = tscv_callinformation;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineCallInformationChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineAddRemoveArgument
export class AsnCtiNotifyLineAddRemoveArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineAddRemoveArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineAddRemoveArgument", newContext);
		value += TSConverter.addStringParam("u8sLineMonitorCrossRefID", obj.u8sLineMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addNumberParam("addremove", obj.addremove, newContext);
		value += TSConverter.addObjectAsStringParam("lineInfo", AsnCtiLineInfo_Converter.toJSON(obj.lineInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineAddRemoveArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineAddRemoveArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineAddRemoveArgument
			if (TSConverter.validateParam(data, "u8sLineMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sLineMonitorCrossRefID = data.u8sLineMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "addremove", "number", errors, newContext, false))
				obj.addremove = data.addremove;
			const tscv_lineinfo = ENetUC_CTI.AsnCtiLineInfo.initEmpty();
			if (AsnCtiLineInfo_Converter.fromJSON(data.lineInfo, tscv_lineinfo, errors, newContext, "lineInfo", false))
				obj.lineInfo = tscv_lineinfo;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineAddRemoveArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyConferenceCreatedArgument
export class AsnCtiNotifyConferenceCreatedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyConferenceCreatedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyConferenceCreatedArgument", newContext);
		value += TSConverter.addStringParam("u8sLineMonitorCrossRefID", obj.u8sLineMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("conferenceControllerCallInfo", AsnCtiCallInformation_Converter.toJSON(obj.conferenceControllerCallInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("conferenceMemberCallInfoList", AsnCtiCallInformationList_Converter.toJSON(obj.conferenceMemberCallInfoList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyConferenceCreatedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyConferenceCreatedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyConferenceCreatedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyConferenceCreatedArgument
			if (TSConverter.validateParam(data, "u8sLineMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sLineMonitorCrossRefID = data.u8sLineMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_conferencecontrollercallinfo = ENetUC_CTI.AsnCtiCallInformation.initEmpty();
			if (AsnCtiCallInformation_Converter.fromJSON(data.conferenceControllerCallInfo, tscv_conferencecontrollercallinfo, errors, newContext, "conferenceControllerCallInfo", false))
				obj.conferenceControllerCallInfo = tscv_conferencecontrollercallinfo;
			const tscv_conferencemembercallinfolist = new ENetUC_CTI.AsnCtiCallInformationList();
			if (AsnCtiCallInformationList_Converter.fromJSON(data.conferenceMemberCallInfoList, tscv_conferencemembercallinfolist, errors, newContext, "conferenceMemberCallInfoList", false))
				obj.conferenceMemberCallInfoList = tscv_conferencemembercallinfolist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyConferenceCreatedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyConferenceDestroyedArgument
export class AsnCtiNotifyConferenceDestroyedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyConferenceDestroyedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyConferenceDestroyedArgument", newContext);
		value += TSConverter.addStringParam("u8sLineMonitorCrossRefID", obj.u8sLineMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("conferenceControllerCallInfo", AsnCtiCallInformation_Converter.toJSON(obj.conferenceControllerCallInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("conferenceMemberCallInfoList", AsnCtiCallInformationList_Converter.toJSON(obj.conferenceMemberCallInfoList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyConferenceDestroyedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyConferenceDestroyedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyConferenceDestroyedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyConferenceDestroyedArgument
			if (TSConverter.validateParam(data, "u8sLineMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sLineMonitorCrossRefID = data.u8sLineMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_conferencecontrollercallinfo = ENetUC_CTI.AsnCtiCallInformation.initEmpty();
			if (AsnCtiCallInformation_Converter.fromJSON(data.conferenceControllerCallInfo, tscv_conferencecontrollercallinfo, errors, newContext, "conferenceControllerCallInfo", false))
				obj.conferenceControllerCallInfo = tscv_conferencecontrollercallinfo;
			const tscv_conferencemembercallinfolist = new ENetUC_CTI.AsnCtiCallInformationList();
			if (AsnCtiCallInformationList_Converter.fromJSON(data.conferenceMemberCallInfoList, tscv_conferencemembercallinfolist, errors, newContext, "conferenceMemberCallInfoList", false))
				obj.conferenceMemberCallInfoList = tscv_conferencemembercallinfolist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyConferenceDestroyedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyConferencePartyAddedArgument
export class AsnCtiNotifyConferencePartyAddedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyConferencePartyAddedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyConferencePartyAddedArgument", newContext);
		value += TSConverter.addStringParam("u8sLineMonitorCrossRefID", obj.u8sLineMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("conferenceControllerCallInfo", AsnCtiCallInformation_Converter.toJSON(obj.conferenceControllerCallInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("conferenceMemberCallInfo", AsnCtiCallInformation_Converter.toJSON(obj.conferenceMemberCallInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyConferencePartyAddedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyConferencePartyAddedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyConferencePartyAddedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyConferencePartyAddedArgument
			if (TSConverter.validateParam(data, "u8sLineMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sLineMonitorCrossRefID = data.u8sLineMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_conferencecontrollercallinfo = ENetUC_CTI.AsnCtiCallInformation.initEmpty();
			if (AsnCtiCallInformation_Converter.fromJSON(data.conferenceControllerCallInfo, tscv_conferencecontrollercallinfo, errors, newContext, "conferenceControllerCallInfo", false))
				obj.conferenceControllerCallInfo = tscv_conferencecontrollercallinfo;
			const tscv_conferencemembercallinfo = ENetUC_CTI.AsnCtiCallInformation.initEmpty();
			if (AsnCtiCallInformation_Converter.fromJSON(data.conferenceMemberCallInfo, tscv_conferencemembercallinfo, errors, newContext, "conferenceMemberCallInfo", false))
				obj.conferenceMemberCallInfo = tscv_conferencemembercallinfo;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyConferencePartyAddedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyConferencePartyRemovedArgument
export class AsnCtiNotifyConferencePartyRemovedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyConferencePartyRemovedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyConferencePartyRemovedArgument", newContext);
		value += TSConverter.addStringParam("u8sLineMonitorCrossRefID", obj.u8sLineMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("conferenceControllerCallInfo", AsnCtiCallInformation_Converter.toJSON(obj.conferenceControllerCallInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("conferenceMemberCallInfo", AsnCtiCallInformation_Converter.toJSON(obj.conferenceMemberCallInfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyConferencePartyRemovedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyConferencePartyRemovedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyConferencePartyRemovedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyConferencePartyRemovedArgument
			if (TSConverter.validateParam(data, "u8sLineMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sLineMonitorCrossRefID = data.u8sLineMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_conferencecontrollercallinfo = ENetUC_CTI.AsnCtiCallInformation.initEmpty();
			if (AsnCtiCallInformation_Converter.fromJSON(data.conferenceControllerCallInfo, tscv_conferencecontrollercallinfo, errors, newContext, "conferenceControllerCallInfo", false))
				obj.conferenceControllerCallInfo = tscv_conferencecontrollercallinfo;
			const tscv_conferencemembercallinfo = ENetUC_CTI.AsnCtiCallInformation.initEmpty();
			if (AsnCtiCallInformation_Converter.fromJSON(data.conferenceMemberCallInfo, tscv_conferencemembercallinfo, errors, newContext, "conferenceMemberCallInfo", false))
				obj.conferenceMemberCallInfo = tscv_conferencemembercallinfo;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyConferencePartyRemovedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiCallFeaturesList
export class AsnCtiCallFeaturesList_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiCallFeaturesList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnCtiCallFeaturesList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnCtiCallFeature_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiCallFeaturesList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiCallFeaturesList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallFeaturesList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiCallFeaturesList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnCtiCallFeaturesList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_CTI.AsnCtiCallFeature.initEmpty();
				if (AsnCtiCallFeature_Converter.fromJSON(elem, entry, errors, newContext, "AsnCtiCallFeature", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiCallFeaturesList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNotifyLineCallFeaturesChangedArgument
export class AsnCtiNotifyLineCallFeaturesChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNotifyLineCallFeaturesChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNotifyLineCallFeaturesChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sLineMonitorCrossRefID", obj.u8sLineMonitorCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addObjectAsStringParam("callFeaturesList", AsnCtiCallFeaturesList_Converter.toJSON(obj.callFeaturesList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNotifyLineCallFeaturesChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineCallFeaturesChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiNotifyLineCallFeaturesChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNotifyLineCallFeaturesChangedArgument
			if (TSConverter.validateParam(data, "u8sLineMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sLineMonitorCrossRefID = data.u8sLineMonitorCrossRefID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			const tscv_callfeatureslist = new ENetUC_CTI.AsnCtiCallFeaturesList();
			if (AsnCtiCallFeaturesList_Converter.fromJSON(data.callFeaturesList, tscv_callfeatureslist, errors, newContext, "callFeaturesList", false))
				obj.callFeaturesList = tscv_callfeatureslist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNotifyLineCallFeaturesChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiSetCallDataArgument
export class AsnCtiSetCallDataArgument_Converter {
	public static toJSON(obj?: ENetUC_CTI.AsnCtiSetCallDataArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiSetCallDataArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiSetCallDataArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("callDataList", AsnCtiCallDataList_Converter.toJSON(obj.callDataList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_CTI.AsnCtiSetCallDataArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiSetCallDataArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetCallDataArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_CTI.AsnCtiSetCallDataArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiSetCallDataArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_calldatalist = new ENetUC_CTI.AsnCtiCallDataList();
			if (AsnCtiCallDataList_Converter.fromJSON(data.callDataList, tscv_calldatalist, errors, newContext, "callDataList", false))
				obj.callDataList = tscv_calldatalist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiSetCallDataArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
