import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { TooltipProps } from "@mui/material";
import { Tooltip } from "@mui/material";
import { ComponentProps, ReactElement, ReactNode } from "react";

export interface IInfoIconProps extends Omit<ComponentProps<typeof FontAwesomeIcon>, "icon"> {
	tooltip?: ReactNode;
	tooltipPlacement?: TooltipProps["placement"];
}

/**
 * An Icon providing additional information to the user when hovered.
 *
 * @param root0
 * @param root0.tooltip
 * @param root0.tooltipPlacement
 */
export function InfoIcon({ tooltip, tooltipPlacement, ...rest }: IInfoIconProps): ReactElement {
	return (
		<Tooltip title={tooltip ?? ""} placement={tooltipPlacement}>
			<span>
				<FontAwesomeIcon icon={faInfoCircle} {...rest} />
			</span>
		</Tooltip>
	);
}
