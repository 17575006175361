// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_Journal_Converter.ts
 * "UC-Server-Access-Protocol-Journal" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_Journal from "./ENetUC_Journal";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";

// [PrintTSEncoderDecoderCode] AsnGetOfflineJournalArgument
export class AsnGetOfflineJournalArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnGetOfflineJournalArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetOfflineJournalArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetOfflineJournalArgument", newContext);
		value += TSConverter.addNumberParam("iMaxDays", obj.iMaxDays, newContext);
		value += TSConverter.addNumberParam("iMarkRead", obj.iMarkRead, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnGetOfflineJournalArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetOfflineJournalArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetOfflineJournalArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnGetOfflineJournalArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetOfflineJournalArgument
			if (TSConverter.validateParam(data, "iMaxDays", "number", errors, newContext, false))
				obj.iMaxDays = data.iMaxDays;
			if (TSConverter.validateParam(data, "iMarkRead", "number", errors, newContext, false))
				obj.iMarkRead = data.iMarkRead;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetOfflineJournalArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNetDatabaseJournal
export class AsnNetDatabaseJournal_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnNetDatabaseJournal, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNetDatabaseJournal
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNetDatabaseJournal", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addDateParam("stStartTime", obj.stStartTime, newContext);
		value += TSConverter.addDateParam("stStopTime", obj.stStopTime, newContext);
		value += TSConverter.addNumberParam("iDurationTotal", obj.iDurationTotal, newContext);
		value += TSConverter.addDateParam("stConnectTime", obj.stConnectTime, newContext);
		value += TSConverter.addDateParam("stDisconnectTime", obj.stDisconnectTime, newContext);
		value += TSConverter.addNumberParam("iDurationConnected", obj.iDurationConnected, newContext);
		value += TSConverter.addNumberParam("iOrigin", obj.iOrigin, newContext);
		value += TSConverter.addNumberParam("iInternal", obj.iInternal, newContext);
		value += TSConverter.addStringParam("u8sLineNumber", obj.u8sLineNumber, newContext);
		value += TSConverter.addStringParam("u8sLineName", obj.u8sLineName, newContext);
		value += TSConverter.addStringParam("u8sLineUserName", obj.u8sLineUserName, newContext);
		value += TSConverter.addNumberParam("iLineUserOffline", obj.iLineUserOffline, newContext);
		value += TSConverter.addStringParam("u8sLocalNumber", obj.u8sLocalNumber, newContext);
		value += TSConverter.addStringParam("u8sPhoneNumber", obj.u8sPhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sRedirectingNumber", obj.u8sRedirectingNumber, newContext);
		value += TSConverter.addStringParam("u8sContactName", obj.u8sContactName, newContext);
		value += TSConverter.addStringParam("u8sContactCompany", obj.u8sContactCompany, newContext);
		value += TSConverter.addStringParam("u8sContactEntryID", obj.u8sContactEntryID, newContext);
		value += TSConverter.addStringParam("u8sContactEntryStoreID", obj.u8sContactEntryStoreID, newContext);
		value += TSConverter.addStringParam("u8sContactEntryDBID", obj.u8sContactEntryDBID, newContext);
		value += TSConverter.addStringParam("u8sCtiServerUserName", obj.u8sCtiServerUserName, newContext);
		value += TSConverter.addStringParam("u8sProviderNumber", obj.u8sProviderNumber, newContext);
		value += TSConverter.addNumberParam("rCharging", obj.rCharging, newContext);
		value += TSConverter.addNumberParam("iSucessState", obj.iSucessState, newContext);
		value += TSConverter.addStringParam("u8sSubject", obj.u8sSubject, newContext);
		value += TSConverter.addStringParam("u8sMemo", obj.u8sMemo, newContext);
		value += TSConverter.addNumberParam("iReadFlag", obj.iReadFlag, newContext);
		value += TSConverter.addObjectAsStringParam("projectPinRule", ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.toJSON(obj.projectPinRule, newContext), newContext);
		value += TSConverter.addStringParam("u8sTrackerID", obj.u8sTrackerID, newContext);
		value += TSConverter.addStringParam("u8sAcceptorName", obj.u8sAcceptorName, newContext);
		value += TSConverter.addStringParam("u8sAcceptorCtiServerUserName", obj.u8sAcceptorCtiServerUserName, newContext);
		value += TSConverter.addStringParam("u8sAcceptorConnectionID", obj.u8sAcceptorConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnNetDatabaseJournal, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNetDatabaseJournal");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseJournal"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnNetDatabaseJournal;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNetDatabaseJournal
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "stStartTime", "string", errors, newContext, false))
				obj.stStartTime = new Date(data.stStartTime);
			if (TSConverter.validateParam(data, "stStopTime", "string", errors, newContext, false))
				obj.stStopTime = new Date(data.stStopTime);
			if (TSConverter.validateParam(data, "iDurationTotal", "number", errors, newContext, false))
				obj.iDurationTotal = data.iDurationTotal;
			if (TSConverter.validateParam(data, "stConnectTime", "string", errors, newContext, false))
				obj.stConnectTime = new Date(data.stConnectTime);
			if (TSConverter.validateParam(data, "stDisconnectTime", "string", errors, newContext, false))
				obj.stDisconnectTime = new Date(data.stDisconnectTime);
			if (TSConverter.validateParam(data, "iDurationConnected", "number", errors, newContext, false))
				obj.iDurationConnected = data.iDurationConnected;
			if (TSConverter.validateParam(data, "iOrigin", "number", errors, newContext, false))
				obj.iOrigin = data.iOrigin;
			if (TSConverter.validateParam(data, "iInternal", "number", errors, newContext, false))
				obj.iInternal = data.iInternal;
			if (TSConverter.validateParam(data, "u8sLineNumber", "string", errors, newContext, false))
				obj.u8sLineNumber = data.u8sLineNumber;
			if (TSConverter.validateParam(data, "u8sLineName", "string", errors, newContext, false))
				obj.u8sLineName = data.u8sLineName;
			if (TSConverter.validateParam(data, "u8sLineUserName", "string", errors, newContext, false))
				obj.u8sLineUserName = data.u8sLineUserName;
			if (TSConverter.validateParam(data, "iLineUserOffline", "number", errors, newContext, false))
				obj.iLineUserOffline = data.iLineUserOffline;
			if (TSConverter.validateParam(data, "u8sLocalNumber", "string", errors, newContext, false))
				obj.u8sLocalNumber = data.u8sLocalNumber;
			if (TSConverter.validateParam(data, "u8sPhoneNumber", "string", errors, newContext, false))
				obj.u8sPhoneNumber = data.u8sPhoneNumber;
			if (TSConverter.validateParam(data, "u8sRedirectingNumber", "string", errors, newContext, false))
				obj.u8sRedirectingNumber = data.u8sRedirectingNumber;
			if (TSConverter.validateParam(data, "u8sContactName", "string", errors, newContext, false))
				obj.u8sContactName = data.u8sContactName;
			if (TSConverter.validateParam(data, "u8sContactCompany", "string", errors, newContext, false))
				obj.u8sContactCompany = data.u8sContactCompany;
			if (TSConverter.validateParam(data, "u8sContactEntryID", "string", errors, newContext, false))
				obj.u8sContactEntryID = data.u8sContactEntryID;
			if (TSConverter.validateParam(data, "u8sContactEntryStoreID", "string", errors, newContext, false))
				obj.u8sContactEntryStoreID = data.u8sContactEntryStoreID;
			if (TSConverter.validateParam(data, "u8sContactEntryDBID", "string", errors, newContext, false))
				obj.u8sContactEntryDBID = data.u8sContactEntryDBID;
			if (TSConverter.validateParam(data, "u8sCtiServerUserName", "string", errors, newContext, false))
				obj.u8sCtiServerUserName = data.u8sCtiServerUserName;
			if (TSConverter.validateParam(data, "u8sProviderNumber", "string", errors, newContext, false))
				obj.u8sProviderNumber = data.u8sProviderNumber;
			if (TSConverter.validateParam(data, "rCharging", "number", errors, newContext, false))
				obj.rCharging = data.rCharging;
			if (TSConverter.validateParam(data, "iSucessState", "number", errors, newContext, false))
				obj.iSucessState = data.iSucessState;
			if (TSConverter.validateParam(data, "u8sSubject", "string", errors, newContext, false))
				obj.u8sSubject = data.u8sSubject;
			if (TSConverter.validateParam(data, "u8sMemo", "string", errors, newContext, false))
				obj.u8sMemo = data.u8sMemo;
			if (TSConverter.validateParam(data, "iReadFlag", "number", errors, newContext, false))
				obj.iReadFlag = data.iReadFlag;
			const tscv_projectpinrule = ENetUC_Common_SIPCTI.AsnProjectPinRule.initEmpty();
			if (ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.fromJSON(data.projectPinRule, tscv_projectpinrule, errors, newContext, "projectPinRule", false))
				obj.projectPinRule = tscv_projectpinrule;
			if (TSConverter.validateParam(data, "u8sTrackerID", "string", errors, newContext, false))
				obj.u8sTrackerID = data.u8sTrackerID;
			if (TSConverter.validateParam(data, "u8sAcceptorName", "string", errors, newContext, false))
				obj.u8sAcceptorName = data.u8sAcceptorName;
			if (TSConverter.validateParam(data, "u8sAcceptorCtiServerUserName", "string", errors, newContext, false))
				obj.u8sAcceptorCtiServerUserName = data.u8sAcceptorCtiServerUserName;
			if (TSConverter.validateParam(data, "u8sAcceptorConnectionID", "string", errors, newContext, false))
				obj.u8sAcceptorConnectionID = data.u8sAcceptorConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseJournal"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateCallAndJournalContactAndNumberArgument
export class AsnUpdateCallAndJournalContactAndNumberArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateCallAndJournalContactAndNumberArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateCallAndJournalContactAndNumberArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addStringParam("u8sRemoteNumberSC", obj.u8sRemoteNumberSC, newContext);
		value += TSConverter.addObjectAsStringParam("remoteContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.remoteContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateCallAndJournalContactAndNumberArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateCallAndJournalContactAndNumberArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateCallAndJournalContactAndNumberArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "u8sRemoteNumberSC", "string", errors, newContext, false))
				obj.u8sRemoteNumberSC = data.u8sRemoteNumberSC;
			const tscv_remotecontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.remoteContact, tscv_remotecontact, errors, newContext, "remoteContact", false))
				obj.remoteContact = tscv_remotecontact;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateCallAndJournalContactAndNumberArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateCallAndJournalContactAndNumberResult
export class AsnUpdateCallAndJournalContactAndNumberResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateCallAndJournalContactAndNumberResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateCallAndJournalContactAndNumberResult", newContext);
		value += TSConverter.addBooleanParam("bResultCallInfoUpdate", obj.bResultCallInfoUpdate, newContext);
		value += TSConverter.addBooleanParam("bResultJournalUpdate", obj.bResultJournalUpdate, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateCallAndJournalContactAndNumberResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateCallAndJournalContactAndNumberResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateCallAndJournalContactAndNumberResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateCallAndJournalContactAndNumberResult
			if (TSConverter.validateParam(data, "bResultCallInfoUpdate", "boolean", errors, newContext, false))
				obj.bResultCallInfoUpdate = data.bResultCallInfoUpdate;
			if (TSConverter.validateParam(data, "bResultJournalUpdate", "boolean", errors, newContext, false))
				obj.bResultJournalUpdate = data.bResultJournalUpdate;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateCallAndJournalContactAndNumberResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateCallLocalContactArgument
export class AsnUpdateCallLocalContactArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateCallLocalContactArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateCallLocalContactArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateCallLocalContactArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("localContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.localContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateCallLocalContactArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateCallLocalContactArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateCallLocalContactArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateCallLocalContactArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateCallLocalContactArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_localcontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.localContact, tscv_localcontact, errors, newContext, "localContact", false))
				obj.localContact = tscv_localcontact;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateCallLocalContactArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateJournalMemoArgument
export class AsnUpdateJournalMemoArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateJournalMemoArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateJournalMemoArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateJournalMemoArgument", newContext);
		value += TSConverter.addObjectAsStringParam("u8sConnectionIDList", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sConnectionIDList, newContext), newContext);
		value += TSConverter.addStringParam("u8sMemo", obj.u8sMemo, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateJournalMemoArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateJournalMemoArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalMemoArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateJournalMemoArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateJournalMemoArgument
			const tscv_u8sconnectionidlist = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sConnectionIDList, tscv_u8sconnectionidlist, errors, newContext, "u8sConnectionIDList", false))
				obj.u8sConnectionIDList = tscv_u8sconnectionidlist;
			if (TSConverter.validateParam(data, "u8sMemo", "string", errors, newContext, false))
				obj.u8sMemo = data.u8sMemo;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalMemoArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateJournalMemoResult
export class AsnUpdateJournalMemoResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateJournalMemoResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateJournalMemoResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateJournalMemoResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateJournalMemoResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateJournalMemoResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalMemoResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateJournalMemoResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateJournalMemoResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalMemoResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateJournalProjectArgument
export class AsnUpdateJournalProjectArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateJournalProjectArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateJournalProjectArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateJournalProjectArgument", newContext);
		value += TSConverter.addObjectAsStringParam("u8sConnectionIDList", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sConnectionIDList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("projectPinRule", ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.toJSON(obj.projectPinRule, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateJournalProjectArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateJournalProjectArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalProjectArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateJournalProjectArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateJournalProjectArgument
			const tscv_u8sconnectionidlist = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sConnectionIDList, tscv_u8sconnectionidlist, errors, newContext, "u8sConnectionIDList", false))
				obj.u8sConnectionIDList = tscv_u8sconnectionidlist;
			const tscv_projectpinrule = ENetUC_Common_SIPCTI.AsnProjectPinRule.initEmpty();
			if (ENetUC_Common_SIPCTI_Converter.AsnProjectPinRule_Converter.fromJSON(data.projectPinRule, tscv_projectpinrule, errors, newContext, "projectPinRule", false))
				obj.projectPinRule = tscv_projectpinrule;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalProjectArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateJournalProjectResult
export class AsnUpdateJournalProjectResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateJournalProjectResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateJournalProjectResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateJournalProjectResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateJournalProjectResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateJournalProjectResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalProjectResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateJournalProjectResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateJournalProjectResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalProjectResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateJournalReadFlagArgument
export class AsnUpdateJournalReadFlagArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateJournalReadFlagArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateJournalReadFlagArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateJournalReadFlagArgument", newContext);
		value += TSConverter.addObjectAsStringParam("u8sConnectionIDList", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sConnectionIDList, newContext), newContext);
		value += TSConverter.addBooleanParam("bReadFlag", obj.bReadFlag, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateJournalReadFlagArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateJournalReadFlagArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalReadFlagArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateJournalReadFlagArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateJournalReadFlagArgument
			const tscv_u8sconnectionidlist = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sConnectionIDList, tscv_u8sconnectionidlist, errors, newContext, "u8sConnectionIDList", false))
				obj.u8sConnectionIDList = tscv_u8sconnectionidlist;
			if (TSConverter.validateParam(data, "bReadFlag", "boolean", errors, newContext, false))
				obj.bReadFlag = data.bReadFlag;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalReadFlagArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateJournalReadFlagResult
export class AsnUpdateJournalReadFlagResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateJournalReadFlagResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateJournalReadFlagResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateJournalReadFlagResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateJournalReadFlagResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateJournalReadFlagResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalReadFlagResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateJournalReadFlagResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateJournalReadFlagResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalReadFlagResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateJournalSubjectArgument
export class AsnUpdateJournalSubjectArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateJournalSubjectArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateJournalSubjectArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateJournalSubjectArgument", newContext);
		value += TSConverter.addObjectAsStringParam("u8sConnectionIDList", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sConnectionIDList, newContext), newContext);
		value += TSConverter.addStringParam("u8sSubject", obj.u8sSubject, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateJournalSubjectArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateJournalSubjectArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalSubjectArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateJournalSubjectArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateJournalSubjectArgument
			const tscv_u8sconnectionidlist = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sConnectionIDList, tscv_u8sconnectionidlist, errors, newContext, "u8sConnectionIDList", false))
				obj.u8sConnectionIDList = tscv_u8sconnectionidlist;
			if (TSConverter.validateParam(data, "u8sSubject", "string", errors, newContext, false))
				obj.u8sSubject = data.u8sSubject;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalSubjectArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateJournalSubjectResult
export class AsnUpdateJournalSubjectResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateJournalSubjectResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateJournalSubjectResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateJournalSubjectResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateJournalSubjectResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateJournalSubjectResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalSubjectResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateJournalSubjectResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateJournalSubjectResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalSubjectResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetJournalEntriesCountResult
export class AsnGetJournalEntriesCountResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnGetJournalEntriesCountResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetJournalEntriesCountResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetJournalEntriesCountResult", newContext);
		value += TSConverter.addNumberParam("iCount", obj.iCount, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnGetJournalEntriesCountResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetJournalEntriesCountResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetJournalEntriesCountResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnGetJournalEntriesCountResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetJournalEntriesCountResult
			if (TSConverter.validateParam(data, "iCount", "number", errors, newContext, false))
				obj.iCount = data.iCount;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetJournalEntriesCountResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJournalEntryGetArgument
export class AsnJournalEntryGetArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnJournalEntryGetArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJournalEntryGetArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJournalEntryGetArgument", newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnJournalEntryGetArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJournalEntryGetArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryGetArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnJournalEntryGetArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJournalEntryGetArgument
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryGetArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJournalSubscribeEventsArgument
export class AsnJournalSubscribeEventsArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnJournalSubscribeEventsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJournalSubscribeEventsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJournalSubscribeEventsArgument", newContext);
		value += TSConverter.addBooleanParam("bReceiveJournalEvents", obj.bReceiveJournalEvents, newContext);
		value += TSConverter.addNumberParam("iLastKnownGlobTransactionID", obj.iLastKnownGlobTransactionID, newContext);
		value += TSConverter.addNumberParam("iMaxEntries", obj.iMaxEntries, newContext);
		value += TSConverter.addNumberParam("iFlags", obj.iFlags, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnJournalSubscribeEventsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJournalSubscribeEventsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalSubscribeEventsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnJournalSubscribeEventsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJournalSubscribeEventsArgument
			if (TSConverter.validateParam(data, "bReceiveJournalEvents", "boolean", errors, newContext, false))
				obj.bReceiveJournalEvents = data.bReceiveJournalEvents;
			if (TSConverter.validateParam(data, "iLastKnownGlobTransactionID", "number", errors, newContext, true))
				obj.iLastKnownGlobTransactionID = data.iLastKnownGlobTransactionID;
			if (TSConverter.validateParam(data, "iMaxEntries", "number", errors, newContext, true))
				obj.iMaxEntries = data.iMaxEntries;
			if (TSConverter.validateParam(data, "iFlags", "number", errors, newContext, true))
				obj.iFlags = data.iFlags;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalSubscribeEventsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJournalSubscribeEventsResult
export class AsnJournalSubscribeEventsResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnJournalSubscribeEventsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJournalSubscribeEventsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJournalSubscribeEventsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnJournalSubscribeEventsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJournalSubscribeEventsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalSubscribeEventsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnJournalSubscribeEventsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJournalSubscribeEventsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalSubscribeEventsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJournalGetSettingsArgument
export class AsnJournalGetSettingsArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnJournalGetSettingsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJournalGetSettingsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJournalGetSettingsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnJournalGetSettingsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJournalGetSettingsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalGetSettingsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnJournalGetSettingsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJournalGetSettingsArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalGetSettingsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDeleteJournalEntriesArgument
export class AsnDeleteJournalEntriesArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnDeleteJournalEntriesArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDeleteJournalEntriesArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDeleteJournalEntriesArgument", newContext);
		value += TSConverter.addObjectAsStringParam("u8sConnectionIDList", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sConnectionIDList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnDeleteJournalEntriesArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDeleteJournalEntriesArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDeleteJournalEntriesArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnDeleteJournalEntriesArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDeleteJournalEntriesArgument
			const tscv_u8sconnectionidlist = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sConnectionIDList, tscv_u8sconnectionidlist, errors, newContext, "u8sConnectionIDList", false))
				obj.u8sConnectionIDList = tscv_u8sconnectionidlist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDeleteJournalEntriesArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDeleteJournalEntriesResult
export class AsnDeleteJournalEntriesResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnDeleteJournalEntriesResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDeleteJournalEntriesResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDeleteJournalEntriesResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnDeleteJournalEntriesResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDeleteJournalEntriesResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDeleteJournalEntriesResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnDeleteJournalEntriesResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDeleteJournalEntriesResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDeleteJournalEntriesResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnMarkAllJournalEntriesAsReadArgument
export class AsnMarkAllJournalEntriesAsReadArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnMarkAllJournalEntriesAsReadArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnMarkAllJournalEntriesAsReadArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnMarkAllJournalEntriesAsReadArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnMarkAllJournalEntriesAsReadArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnMarkAllJournalEntriesAsReadArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnMarkAllJournalEntriesAsReadArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnMarkAllJournalEntriesAsReadArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnMarkAllJournalEntriesAsReadResult
export class AsnMarkAllJournalEntriesAsReadResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnMarkAllJournalEntriesAsReadResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnMarkAllJournalEntriesAsReadResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnMarkAllJournalEntriesAsReadResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnMarkAllJournalEntriesAsReadResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnMarkAllJournalEntriesAsReadResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnMarkAllJournalEntriesAsReadResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnMarkAllJournalEntriesAsReadResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNetDatabaseJournalList
export class AsnNetDatabaseJournalList_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnNetDatabaseJournalList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnNetDatabaseJournalList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnNetDatabaseJournal_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnNetDatabaseJournalList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNetDatabaseJournalList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseJournalList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnNetDatabaseJournalList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnNetDatabaseJournalList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Journal.AsnNetDatabaseJournal.initEmpty();
				if (AsnNetDatabaseJournal_Converter.fromJSON(elem, entry, errors, newContext, "AsnNetDatabaseJournal", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseJournalList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNetDatabaseJournalFindOptions
export class AsnNetDatabaseJournalFindOptions_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnNetDatabaseJournalFindOptions, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNetDatabaseJournalFindOptions
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNetDatabaseJournalFindOptions", newContext);
		value += TSConverter.addDateParam("stStartTimeFrom", obj.stStartTimeFrom, newContext);
		value += TSConverter.addDateParam("stStartTimeTo", obj.stStartTimeTo, newContext);
		value += TSConverter.addObjectAsStringParam("u8slistLineUserNames", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistLineUserNames, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("u8slistPhoneNumber", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistPhoneNumber, newContext), newContext);
		value += TSConverter.addStringParam("u8sContactName", obj.u8sContactName, newContext);
		value += TSConverter.addStringParam("u8sCompanyName", obj.u8sCompanyName, newContext);
		value += TSConverter.addStringParam("u8sFilterText", obj.u8sFilterText, newContext);
		value += TSConverter.addNumberParam("bsFlags", obj.bsFlags, newContext);
		value += TSConverter.addNumberParam("iMaxNumEntries", obj.iMaxNumEntries, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnNetDatabaseJournalFindOptions, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNetDatabaseJournalFindOptions");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseJournalFindOptions"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnNetDatabaseJournalFindOptions;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNetDatabaseJournalFindOptions
			if (TSConverter.validateParam(data, "stStartTimeFrom", "string", errors, newContext, false))
				obj.stStartTimeFrom = new Date(data.stStartTimeFrom);
			if (TSConverter.validateParam(data, "stStartTimeTo", "string", errors, newContext, false))
				obj.stStartTimeTo = new Date(data.stStartTimeTo);
			const tscv_u8slistlineusernames = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistLineUserNames, tscv_u8slistlineusernames, errors, newContext, "u8slistLineUserNames", false))
				obj.u8slistLineUserNames = tscv_u8slistlineusernames;
			const tscv_u8slistphonenumber = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistPhoneNumber, tscv_u8slistphonenumber, errors, newContext, "u8slistPhoneNumber", false))
				obj.u8slistPhoneNumber = tscv_u8slistphonenumber;
			if (TSConverter.validateParam(data, "u8sContactName", "string", errors, newContext, false))
				obj.u8sContactName = data.u8sContactName;
			if (TSConverter.validateParam(data, "u8sCompanyName", "string", errors, newContext, false))
				obj.u8sCompanyName = data.u8sCompanyName;
			if (TSConverter.validateParam(data, "u8sFilterText", "string", errors, newContext, false))
				obj.u8sFilterText = data.u8sFilterText;
			if (TSConverter.validateParam(data, "bsFlags", "number", errors, newContext, false))
				obj.bsFlags = data.bsFlags;
			if (TSConverter.validateParam(data, "iMaxNumEntries", "number", errors, newContext, false))
				obj.iMaxNumEntries = data.iMaxNumEntries;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseJournalFindOptions"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetJournalEntriesArgument
export class AsnGetJournalEntriesArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnGetJournalEntriesArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetJournalEntriesArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetJournalEntriesArgument", newContext);
		value += TSConverter.addObjectAsStringParam("findOptions", AsnNetDatabaseJournalFindOptions_Converter.toJSON(obj.findOptions, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnGetJournalEntriesArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetJournalEntriesArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetJournalEntriesArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnGetJournalEntriesArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetJournalEntriesArgument
			const tscv_findoptions = ENetUC_Journal.AsnNetDatabaseJournalFindOptions.initEmpty();
			if (AsnNetDatabaseJournalFindOptions_Converter.fromJSON(data.findOptions, tscv_findoptions, errors, newContext, "findOptions", false))
				obj.findOptions = tscv_findoptions;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetJournalEntriesArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetJournalEntriesResult
export class AsnGetJournalEntriesResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnGetJournalEntriesResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetJournalEntriesResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetJournalEntriesResult", newContext);
		value += TSConverter.addObjectAsStringParam("journalList", AsnNetDatabaseJournalList_Converter.toJSON(obj.journalList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnGetJournalEntriesResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetJournalEntriesResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetJournalEntriesResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnGetJournalEntriesResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetJournalEntriesResult
			const tscv_journallist = new ENetUC_Journal.AsnNetDatabaseJournalList();
			if (AsnNetDatabaseJournalList_Converter.fromJSON(data.journalList, tscv_journallist, errors, newContext, "journalList", false))
				obj.journalList = tscv_journallist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetJournalEntriesResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetJournalEntriesCountArgument
export class AsnGetJournalEntriesCountArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnGetJournalEntriesCountArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetJournalEntriesCountArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetJournalEntriesCountArgument", newContext);
		value += TSConverter.addObjectAsStringParam("findOptions", AsnNetDatabaseJournalFindOptions_Converter.toJSON(obj.findOptions, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnGetJournalEntriesCountArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetJournalEntriesCountArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetJournalEntriesCountArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnGetJournalEntriesCountArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetJournalEntriesCountArgument
			const tscv_findoptions = ENetUC_Journal.AsnNetDatabaseJournalFindOptions.initEmpty();
			if (AsnNetDatabaseJournalFindOptions_Converter.fromJSON(data.findOptions, tscv_findoptions, errors, newContext, "findOptions", false))
				obj.findOptions = tscv_findoptions;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetJournalEntriesCountArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJournalEntryGetResult
export class AsnJournalEntryGetResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnJournalEntryGetResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJournalEntryGetResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJournalEntryGetResult", newContext);
		value += TSConverter.addObjectAsStringParam("journalEntry", AsnNetDatabaseJournal_Converter.toJSON(obj.journalEntry, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnJournalEntryGetResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJournalEntryGetResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryGetResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnJournalEntryGetResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJournalEntryGetResult
			const tscv_journalentry = ENetUC_Journal.AsnNetDatabaseJournal.initEmpty();
			if (AsnNetDatabaseJournal_Converter.fromJSON(data.journalEntry, tscv_journalentry, errors, newContext, "journalEntry", false))
				obj.journalEntry = tscv_journalentry;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryGetResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJournalEntryChangedArgument
export class AsnJournalEntryChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnJournalEntryChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJournalEntryChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJournalEntryChangedArgument", newContext);
		value += TSConverter.addNumberParam("iFlags", obj.iFlags, newContext);
		value += TSConverter.addObjectAsStringParam("journalEntry", AsnNetDatabaseJournal_Converter.toJSON(obj.journalEntry, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnJournalEntryChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJournalEntryChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnJournalEntryChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJournalEntryChangedArgument
			if (TSConverter.validateParam(data, "iFlags", "number", errors, newContext, false))
				obj.iFlags = data.iFlags;
			const tscv_journalentry = ENetUC_Journal.AsnNetDatabaseJournal.initEmpty();
			if (AsnNetDatabaseJournal_Converter.fromJSON(data.journalEntry, tscv_journalentry, errors, newContext, "journalEntry", false))
				obj.journalEntry = tscv_journalentry;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJournalEntryChangedList
export class AsnJournalEntryChangedList_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnJournalEntryChangedList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnJournalEntryChangedList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnJournalEntryChangedArgument_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnJournalEntryChangedList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJournalEntryChangedList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryChangedList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnJournalEntryChangedList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnJournalEntryChangedList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Journal.AsnJournalEntryChangedArgument.initEmpty();
				if (AsnJournalEntryChangedArgument_Converter.fromJSON(elem, entry, errors, newContext, "AsnJournalEntryChangedArgument", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryChangedList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJournalEntryChangedListArgument
export class AsnJournalEntryChangedListArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnJournalEntryChangedListArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJournalEntryChangedListArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJournalEntryChangedListArgument", newContext);
		value += TSConverter.addNumberParam("iLastTransactionID", obj.iLastTransactionID, newContext);
		value += TSConverter.addObjectAsStringParam("journalEntryChangedList", AsnJournalEntryChangedList_Converter.toJSON(obj.journalEntryChangedList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnJournalEntryChangedListArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJournalEntryChangedListArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryChangedListArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnJournalEntryChangedListArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJournalEntryChangedListArgument
			if (TSConverter.validateParam(data, "iLastTransactionID", "number", errors, newContext, false))
				obj.iLastTransactionID = data.iLastTransactionID;
			const tscv_journalentrychangedlist = new ENetUC_Journal.AsnJournalEntryChangedList();
			if (AsnJournalEntryChangedList_Converter.fromJSON(data.journalEntryChangedList, tscv_journalentrychangedlist, errors, newContext, "journalEntryChangedList", false))
				obj.journalEntryChangedList = tscv_journalentrychangedlist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalEntryChangedListArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJournalGetSettingsResult
export class AsnJournalGetSettingsResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnJournalGetSettingsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJournalGetSettingsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJournalGetSettingsResult", newContext);
		value += TSConverter.addNumberParam("ePrivacyLevel", obj.ePrivacyLevel, newContext);
		value += TSConverter.addBooleanParam("bUseOfflineJournal", obj.bUseOfflineJournal, newContext);
		value += TSConverter.addBooleanParam("bOfflineDeleteOldEntries", obj.bOfflineDeleteOldEntries, newContext);
		value += TSConverter.addNumberParam("iOfflineDaysNoDelete", obj.iOfflineDaysNoDelete, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnJournalGetSettingsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJournalGetSettingsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalGetSettingsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnJournalGetSettingsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJournalGetSettingsResult
			if (TSConverter.validateParam(data, "ePrivacyLevel", "number", errors, newContext, false))
				obj.ePrivacyLevel = data.ePrivacyLevel;
			if (TSConverter.validateParam(data, "bUseOfflineJournal", "boolean", errors, newContext, false))
				obj.bUseOfflineJournal = data.bUseOfflineJournal;
			if (TSConverter.validateParam(data, "bOfflineDeleteOldEntries", "boolean", errors, newContext, false))
				obj.bOfflineDeleteOldEntries = data.bOfflineDeleteOldEntries;
			if (TSConverter.validateParam(data, "iOfflineDaysNoDelete", "number", errors, newContext, false))
				obj.iOfflineDaysNoDelete = data.iOfflineDaysNoDelete;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJournalGetSettingsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateJournalArgument
export class AsnUpdateJournalArgument_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnUpdateJournalArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateJournalArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateJournalArgument", newContext);
		value += TSConverter.addObjectAsStringParam("journalList", AsnNetDatabaseJournalList_Converter.toJSON(obj.journalList, newContext), newContext);
		value += TSConverter.addNumberParam("iFlags", obj.iFlags, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnUpdateJournalArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateJournalArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnUpdateJournalArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateJournalArgument
			const tscv_journallist = new ENetUC_Journal.AsnNetDatabaseJournalList();
			if (AsnNetDatabaseJournalList_Converter.fromJSON(data.journalList, tscv_journallist, errors, newContext, "journalList", false))
				obj.journalList = tscv_journallist;
			if (TSConverter.validateParam(data, "iFlags", "number", errors, newContext, false))
				obj.iFlags = data.iFlags;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateJournalArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetOfflineJournalResult
export class AsnGetOfflineJournalResult_Converter {
	public static toJSON(obj?: ENetUC_Journal.AsnGetOfflineJournalResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetOfflineJournalResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetOfflineJournalResult", newContext);
		value += TSConverter.addObjectAsStringParam("journalList", AsnNetDatabaseJournalList_Converter.toJSON(obj.journalList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Journal.AsnGetOfflineJournalResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetOfflineJournalResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetOfflineJournalResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Journal.AsnGetOfflineJournalResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetOfflineJournalResult
			const tscv_journallist = new ENetUC_Journal.AsnNetDatabaseJournalList();
			if (AsnNetDatabaseJournalList_Converter.fromJSON(data.journalList, tscv_journallist, errors, newContext, "journalList", false))
				obj.journalList = tscv_journallist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetOfflineJournalResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
