// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_ClientPersistence.ts
 * "UC-Server-Access-Protocol-ClientPersistence" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * Client persistence interface
 * ## Module description
 * This module defines structures and operations for a CRUD item store and its eventing.
 * With these operations, each client can persist client specific data on the UCServer and can attach to the according change notify interface.
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";

// [PrintTSTypeDefCode] AsnClientPersistenceResultDetailsEnum
// [PrintTSEnumDefCode] AsnClientPersistenceResultDetailsEnum
export enum AsnClientPersistenceResultDetailsEnum {
	/** kein Fehler */
	noerror = 0,
	/** Zugriff auf ItemStore nicht gestattet */
	itemstoreaccessdenied = 1,
	/** Zugriff nicht gestattet (lesend\/schreibend) */
	dataaccessdenied = 2,
	/** ungÃ¼ltiger Subscribe\/Unsubscribe */
	invalidsubscription = 3,
	/** ungÃ¼ltige CrossRefID angegeben */
	invalidcrossrefid = 4,
	/** ungÃ¼ltiges key-Element */
	invalidkey = 5,
	/** ungÃ¼ltiges data-Element (z.b. zu groÃ) */
	invaliddata = 6,
	/** zu viele Request von einem Client (ein Client darf maximal 10 offene Anfragen [aka \"asynchrone Requests\"] am Server haben) */
	clientpersistenceoverload = 7,
	/** Datenbankfehler (lesend\/schreibend) */
	databasefail = 8,
	/** Client frÃ¤gt mit einer zu alten Revision an d.h. Client muss seine Daten verwerfen und nochmals anfragen (= Initialdaten holen) */
	revisionoutdated = 9,
	/** ClientPersistence-System ist nicht aktiv */
	clientpersistencedisabled = 99
}

// [PrintTSTypeDefCode] AsnClientPersistenceReadWildcardTypeEnum
// [PrintTSEnumDefCode] AsnClientPersistenceReadWildcardTypeEnum
export enum AsnClientPersistenceReadWildcardTypeEnum {
	/** no wildcard read - key has to match exactly (DEFAULT) */
	noWildcard = 0,
	/** the given key is used as prefix for result key(s) */
	prefixKey = 1,
	/** the given key is used as postfix for result key(s) */
	postfixKey = 2
}

// [PrintTSTypeDefCode] AsnClientPersistenceSubscribeTypeEnum
// [PrintTSEnumDefCode] AsnClientPersistenceSubscribeTypeEnum
export enum AsnClientPersistenceSubscribeTypeEnum {
	subscribe = 1,
	unsubscribe = 2
}

// [PrintTSTypeDefCode] AsnClientPersistenceItem
// [PrintTSSeqDefCode] AsnClientPersistenceItem
/**
 * Definition eines Persistence-Items
 * Ein ItemStore kann beliebige Persistence-Items aufnehmen. Ein Persistence-Item gehÃ¶rt immer genau zu einem ItemStore.
 * Der SchlÃ¼ssel eines Persistence-Item (u8sItemStoreID) muss innerhalb des ItemStore eindeutig sein.
 * Falls der Client beim Erzeugen eines Elements keine u8sItemStoreID angibt, erzeugt der Server selbst diesen SchlÃ¼ssel.
 * Der Client ist selbst dafÃ¼r verantwortlich, die Nutzdaten in geeigneter Form zu verwalten.
 *
 * @private
 */
export class AsnClientPersistenceItem {
	public constructor(that: AsnClientPersistenceItem) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceItem {
		return new AsnClientPersistenceItem({
			u8sItemStore: "",
			u8sItemStoreID: "",
			data: ""
		});
	}

	/** bspw. \"FAQEntry\", \"usersettings\" */
	public u8sItemStore!: string;
	/** Key des Eintrags, muss innerhalb des ItemStore eindeutig sein */
	public u8sItemStoreID!: string;
	/** die Nutzdaten des Elements */
	public data!: string;
	/** aktuelle Revision des Items */
	public iRevision?: number;
}

// [PrintTSTypeDefCode] AsnClientPersistenceCreateArgument
// [PrintTSSeqDefCode] AsnClientPersistenceCreateArgument
export class AsnClientPersistenceCreateArgument {
	public constructor(that: AsnClientPersistenceCreateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceCreateArgument {
		return new AsnClientPersistenceCreateArgument({
			u8sCrossRefID: "",
			u8sItemStore: "",
			data: ""
		});
	}

	/** wird vom Client generiert und muss innerhalb der ClientSession eindeutig sein */
	public u8sCrossRefID!: string;
	/** ItemStore des Elements */
	public u8sItemStore!: string;
	/** wenn eine StoreID angegeben ist, darf diese noch nicht existieren */
	public u8sItemStoreID?: string;
	/** Nutzdaten des Elements */
	public data!: string;
}

// [PrintTSTypeDefCode] AsnClientPersistenceCreateResult
// [PrintTSSeqDefCode] AsnClientPersistenceCreateResult
export class AsnClientPersistenceCreateResult {
	public constructor(that?: AsnClientPersistenceCreateResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceCreateResult {
		return new AsnClientPersistenceCreateResult();
	}
}

// [PrintTSTypeDefCode] AsnClientPersistenceReadArgument
// [PrintTSSeqDefCode] AsnClientPersistenceReadArgument
export class AsnClientPersistenceReadArgument {
	public constructor(that: AsnClientPersistenceReadArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceReadArgument {
		return new AsnClientPersistenceReadArgument({
			u8sCrossRefID: "",
			u8sItemStore: ""
		});
	}

	/** wird vom Client generiert und muss innerhalb der ClientSession eindeutig sein */
	public u8sCrossRefID!: string;
	/** aus diesem ItemStore werden die Elemente gelesen */
	public u8sItemStore!: string;
	/** Key des Elements (ohne Angabe werden alle verfÃ¼gbaren Elemente zurÃ¼ckgeliefert) */
	public u8sItemStoreID?: string;
	/** ohne Angabe eines u8sItemStoreID werden alle Elemente mit grÃ¶Ãerer Revision geliefert */
	public iLastKnownGlobalRevision?: number;
	/** Key des Elements (u8sItemStoreID) soll als Wildcard-Prefix\/Postfix behandelt werden */
	public iItemKeyWildcardType?: number;
	/** false: Default\/Standard-Verhalten - es werden alle passenden Elemente zurÃ¼ckgeliefert */
	public bCountAvailableItemsOnly?: boolean;
	/** Limitierung der Ã¼bermittelten Items - Anzahl der zurÃ¼ckgelieferten (Wildcard-)Elemente beschrÃ¤nken */
	public iItemLimit?: number;
}

// [PrintTSTypeDefCode] AsnClientPersistenceReadResult
// [PrintTSSeqDefCode] AsnClientPersistenceReadResult
export class AsnClientPersistenceReadResult {
	public constructor(that?: AsnClientPersistenceReadResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceReadResult {
		return new AsnClientPersistenceReadResult();
	}
}

// [PrintTSTypeDefCode] AsnClientPersistenceUpdateArgument
// [PrintTSSeqDefCode] AsnClientPersistenceUpdateArgument
export class AsnClientPersistenceUpdateArgument {
	public constructor(that: AsnClientPersistenceUpdateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceUpdateArgument {
		return new AsnClientPersistenceUpdateArgument({
			u8sCrossRefID: "",
			u8sItemStore: "",
			u8sItemStoreID: "",
			data: ""
		});
	}

	/** wird vom Client generiert und muss innerhalb der ClientSession eindeutig sein */
	public u8sCrossRefID!: string;
	/** ItemStore des Elements */
	public u8sItemStore!: string;
	/** Key des Elements */
	public u8sItemStoreID!: string;
	/** Nutzdaten des Elements */
	public data!: string;
}

// [PrintTSTypeDefCode] AsnClientPersistenceUpdateResult
// [PrintTSSeqDefCode] AsnClientPersistenceUpdateResult
export class AsnClientPersistenceUpdateResult {
	public constructor(that?: AsnClientPersistenceUpdateResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceUpdateResult {
		return new AsnClientPersistenceUpdateResult();
	}
}

// [PrintTSTypeDefCode] AsnClientPersistenceDeleteArgument
// [PrintTSSeqDefCode] AsnClientPersistenceDeleteArgument
export class AsnClientPersistenceDeleteArgument {
	public constructor(that: AsnClientPersistenceDeleteArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceDeleteArgument {
		return new AsnClientPersistenceDeleteArgument({
			u8sCrossRefID: "",
			u8sItemStore: "",
			u8sItemStoreID: ""
		});
	}

	/** wird vom Client generiert und muss innerhalb der ClientSession eindeutig sein */
	public u8sCrossRefID!: string;
	/** ItemStore des Elements */
	public u8sItemStore!: string;
	/** Key des Elements */
	public u8sItemStoreID!: string;
}

// [PrintTSTypeDefCode] AsnClientPersistenceDeleteResult
// [PrintTSSeqDefCode] AsnClientPersistenceDeleteResult
export class AsnClientPersistenceDeleteResult {
	public constructor(that?: AsnClientPersistenceDeleteResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceDeleteResult {
		return new AsnClientPersistenceDeleteResult();
	}
}

// [PrintTSTypeDefCode] AsnClientPersistenceSubscribeArgument
// [PrintTSSeqDefCode] AsnClientPersistenceSubscribeArgument
export class AsnClientPersistenceSubscribeArgument {
	public constructor(that: AsnClientPersistenceSubscribeArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceSubscribeArgument {
		return new AsnClientPersistenceSubscribeArgument({
			u8sCrossRefID: "",
			iSubscribeType: 0,
			u8sItemStore: ""
		});
	}

	/** wird vom Client generiert und muss innerhalb der ClientSession eindeutig sein\t */
	public u8sCrossRefID!: string;
	public iSubscribeType!: number;
	/** ItemStore fÃ¼r den die Aktion erfolgen soll */
	public u8sItemStore!: string;
}

// [PrintTSTypeDefCode] AsnClientPersistenceSubscribeResult
// [PrintTSSeqDefCode] AsnClientPersistenceSubscribeResult
export class AsnClientPersistenceSubscribeResult {
	public constructor(that?: AsnClientPersistenceSubscribeResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceSubscribeResult {
		return new AsnClientPersistenceSubscribeResult();
	}
}

// [PrintTSTypeDefCode] AsnClientPersistenceGetDatabaseIDArgument
// [PrintTSSeqDefCode] AsnClientPersistenceGetDatabaseIDArgument
export class AsnClientPersistenceGetDatabaseIDArgument {
	public constructor(that?: AsnClientPersistenceGetDatabaseIDArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceGetDatabaseIDArgument {
		return new AsnClientPersistenceGetDatabaseIDArgument();
	}
}

// [PrintTSTypeDefCode] AsnClientPersistenceGetDatabaseIDResult
// [PrintTSSeqDefCode] AsnClientPersistenceGetDatabaseIDResult
export class AsnClientPersistenceGetDatabaseIDResult {
	public constructor(that: AsnClientPersistenceGetDatabaseIDResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceGetDatabaseIDResult {
		return new AsnClientPersistenceGetDatabaseIDResult({
			u8sDatabaseID: ""
		});
	}

	/** Unique ID der aktuellen Server Datenbank */
	public u8sDatabaseID!: string;
}

// [PrintTSTypeDefCode] AsnClientPersistenceItemList
// [PrintTSSetOfDefCode] AsnClientPersistenceItemList
export class AsnClientPersistenceItemList extends Array<AsnClientPersistenceItem> { }

// [PrintTSTypeDefCode] AsnClientPersistenceEventArgument
// [PrintTSSeqDefCode] AsnClientPersistenceEventArgument
export class AsnClientPersistenceEventArgument {
	public constructor(that: AsnClientPersistenceEventArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientPersistenceEventArgument {
		return new AsnClientPersistenceEventArgument({
			iResult: 0,
			u8sCrossRefID: ""
		});
	}

	public iResult!: number;
	/** wenn nicht leer, ist es eine Antwort auf einen Request des Clients, sonst ein server-generierter Event */
	public u8sCrossRefID!: string;
	/** vom Server verwaltete TransaktionsID, der zu diesem Event gefÃ¼hrt hat */
	public iGlobalRevision?: number;
	/** Liste von geÃ¤nderten Elementen (die Liste enthÃ¤lt ggf. auch aus mehreren ItemStores) */
	public changedItems?: AsnClientPersistenceItemList;
	/** Liste von gelÃ¶schten Elementen (die Liste enthÃ¤lt ggf. auch aus mehreren ItemStores) */
	public deletedItems?: AsnClientPersistenceItemList;
	/** Unique ID der aktuellen Server Datenbank, wird vom Server auf Anfrage verschickt - oder automatisch, falls sich die Db-ID geÃ¤ndert hat */
	public u8sDatabaseID?: string;
	/** falls bei asnClientPersistenceRead nur die Anzahl Elemente angefordert wurde, wird diese hier zurÃ¼ckgeliefert */
	public iAvailableItemsCount?: number;
}
