import { debounce } from "lodash";

import { ILogData } from "../../web-shared-components/helpers/logger/ILogger";
import { IBaseSingletons } from "../interfaces/IBaseSingletons";
import BaseSingleton from "../lib/BaseSingleton";
import { SocketTransport } from "../session/SocketTransport";
import {
	AsnCtiEnumPhoneLinesArgumentEnum,
	AsnCtiLineMonitorStopResult,
	AsnCtiSetForwardResult
} from "../ucserver/stubs/ENetUC_CTI";
import { ENetUC_CTI } from "../ucserver/stubs/types";
import { ILineInfoContainer } from "../zustand/ctiSlice";
import { getState, subscribe } from "../zustand/store";

// Simplified interface to handle the messages received through the websocket
export interface ICtiHandler {
	onResult_asnCtiPhoneLineSubscribeEvents(result: ENetUC_CTI.AsnCtiEnumPhoneLinesResult): void;
	onResult_asnCtiLineMonitorStartResult(result: ENetUC_CTI.AsnCtiLineMonitorStartResult): void;
	onEvent_asnCtiNotifyLineAddRemove(argument: ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument): void;
	onEvent_asnCtiNotifyLineInfoChanged(argument: ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument): void;
	onEvent_asnCtiNotifyLineDoNotDisturbChanged(argument: ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument): void;
	onEvent_asnCtiNotifyLineRemoteOfficeChanged(argument: ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument): void;
	onEvent_asnCtiNotifyLineForwardingChanged(argument: ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument): void;
}

const VideoLineIdentifier = "phys=ClientAV-";
const SoftPhoneLineIdentifier = "phys=SIPAV-";

/**
 * Filters the lines that are video lines
 *
 * @param seqLines - the lines to filter
 * @returns - lines without video lines
 */
function filterVideoLines(seqLines: ENetUC_CTI.AsnCtiLineInfoList) {
	return seqLines.filter((line) => !line.u8sLinePhoneNumber.includes(VideoLineIdentifier));
}

/**
 *
 */
export class CtiManager extends BaseSingleton implements ICtiHandler {
	// The singleton instance of this class
	private static instance: CtiManager;
	private socket: SocketTransport;
	private subscribedLinePhoneNumbers: Map<string, string> = new Map();
	private attemptToSubscribeLinePhoneNumbers: Set<string> = new Set();

	/**
	 * Constructs CtiManager.
	 * Method is private as we follow the Singleton Approach using getInstance
	 *
	 * @param attributes - the constructor attributes
	 */
	private constructor(attributes: IBaseSingletons) {
		super(attributes);
		this.socket = SocketTransport.getInstance();
		this.socket.setCtiHandler(this);
		subscribe((state) => state.myPhoneLines, debounce(this.monitorLines, 500));
	}

	/**
	 * Gets instance of CtiManager to use as singleton.
	 *
	 * @param attributes - the constructor attributes
	 * @returns - an instance of this class.
	 */
	public static getInstance(attributes: IBaseSingletons): CtiManager {
		if (!CtiManager.instance) {
			CtiManager.instance = new CtiManager(attributes);
		}
		return CtiManager.instance;
	}

	/**
	 * The Loggers getLogData callback (used in all the log methods called in this class, add the classname to every log entry)
	 *
	 * @returns - an ILogData log data object provided additional data for all the logger calls in this class
	 */
	public getLogData(): ILogData {
		return {
			className: "CtiManager"
		};
	}

	private monitorLines = (myPhoneLines: Map<string, ILineInfoContainer>) => {
		const phoneLinesToSubscribe: string[] = [];
		for (const [u8sLinePhoneNumber] of myPhoneLines) {
			if (this.subscribedLinePhoneNumbers.has(u8sLinePhoneNumber)) {
				return;
			}

			this.attemptToSubscribeLinePhoneNumbers.add(u8sLinePhoneNumber);
			phoneLinesToSubscribe.push(u8sLinePhoneNumber);
		}

		const removedPhoneLines = [...this.subscribedLinePhoneNumbers].filter(
			([u8sLinePhoneNumber]) => !myPhoneLines.has(u8sLinePhoneNumber)
		);

		if (phoneLinesToSubscribe.length > 0) {
			for (const phoneLinesToStart of phoneLinesToSubscribe) {
				void this.ctiLineMonitorStart(phoneLinesToStart);
			}
		}

		if (removedPhoneLines.length) {
			for (const [phoneLineNumber, crossRefId] of removedPhoneLines) {
				void this.ctiLineMonitorStop(crossRefId).then(() => {
					this.subscribedLinePhoneNumbers.delete(phoneLineNumber);
					this.attemptToSubscribeLinePhoneNumbers.delete(phoneLineNumber);
				});
			}
		}
	};

	/**
	 * Called by the socket after subscribing to add/removes line
	 *
	 * @param result - the result
	 */
	public onResult_asnCtiPhoneLineSubscribeEvents(result: ENetUC_CTI.AsnCtiEnumPhoneLinesResult) {
		const withoutVideoLines = filterVideoLines(result.seqLines);
		getState().setPhoneLines(
			withoutVideoLines.map((line) => ({
				lineInfo: line,
				isSoftPhone: line.u8sLinePhoneNumber?.includes(SoftPhoneLineIdentifier)
			}))
		);
	}

	/**
	 * Called by the socket after subscribing to line changes
	 *
	 * @param result - the result
	 */
	public onResult_asnCtiLineMonitorStartResult(result: ENetUC_CTI.AsnCtiLineMonitorStartResult) {
		this.subscribedLinePhoneNumbers.set(result.lineInfo.u8sLinePhoneNumber, result.u8sMonitorCrossRefID);
		getState().setPhoneLines([
			{
				lineInfo: result.lineInfo,
				bDoNotDisturb: result.iDoNotDisturb === 1 ? true : false,
				remoteOfficeState: result.remoteOfficeState,
				seqLineForwards: result.seqLineForwards
			}
		]);
	}

	/**
	 * Called by the socket on asnCtiNotifyLineAddRemove from UCServer
	 *
	 * @param argument - the argument
	 */
	public onEvent_asnCtiNotifyLineAddRemove(argument: ENetUC_CTI.AsnCtiNotifyLineAddRemoveArgument) {
		if (argument.lineInfo.u8sLinePhoneNumber.includes(VideoLineIdentifier)) {
			return;
		}

		const isAdded = argument.addremove === 0;
		const isRemoved = argument.addremove === 1;

		if (isAdded) {
			getState().setPhoneLines([{ lineInfo: argument.lineInfo }]);
		}

		if (isRemoved) {
			getState().removePhoneLines([argument.u8sLinePhoneNumber]);
		}
	}

	/**
	 * Called by the socket on asnJournalEntryListChanged from UCServer
	 *
	 * @param argument - the argument
	 */
	public onEvent_asnCtiNotifyLineInfoChanged(argument: ENetUC_CTI.AsnCtiNotifyLineInfoChangedArgument) {}

	/**
	 * Called by the socket on asnCtiNotifyLineDoNotDisturbChanged from UCServer
	 *
	 * @param argument - the argument
	 */
	public onEvent_asnCtiNotifyLineDoNotDisturbChanged(argument: ENetUC_CTI.AsnCtiNotifyLineDoNotDisturbChangedArgument) {
		getState().setDoNotDisturb(argument.u8sLinePhoneNumber, argument.iDoNotDisturb);
	}

	/**
	 * Called by the socket on asnCtiNotifyLineRemoteOfficeChanged from UCServer
	 *
	 * @param argument - the argument
	 */
	public onEvent_asnCtiNotifyLineRemoteOfficeChanged(argument: ENetUC_CTI.AsnCtiNotifyLineRemoteOfficeChangedArgument) {
		getState().setRemoteOffice(argument.u8sLinePhoneNumber, argument.remoteOfficeState);
	}

	/**
	 * Called by the socket on asnCtiNotifyLineForwardingChanged from UCServer
	 *
	 * @param argument - the argument
	 */
	public onEvent_asnCtiNotifyLineForwardingChanged(argument: ENetUC_CTI.AsnCtiNotifyLineForwardingChangedArgument) {
		getState().setLineForwards(argument.u8sLinePhoneNumber, argument.seqLineForwards);
		getState().setLocalPhoneLine(argument.u8sLinePhoneNumber, argument.seqLineForwards[0].u8sDestination);
	}

	/**
	 * Subscribe to add/remove line events
	 *
	 */
	public async ctiPhoneLineSubscribeEvents() {
		const argument = new ENetUC_CTI.AsnCtiEnumPhoneLinesArgument({
			attachChangeNotify: 1,
			typeofLines: AsnCtiEnumPhoneLinesArgumentEnum.userOwnLines
		});
		this.socket.send("asnCtiEnumPhoneLines", argument);
	}

	/**
	 * Start monitoring a line
	 *
	 * @param u8sLinePhoneNumber - the line to monitor
	 */
	private async ctiLineMonitorStart(u8sLinePhoneNumber: string) {
		const argument = new ENetUC_CTI.AsnCtiLineMonitorStartArgument({
			u8sLinePhoneNumber
		});
		this.socket.send("asnCtiLineMonitorStart", argument);
	}

	/**
	 * Stop monitoring a line
	 *
	 * @param u8sMonitorCrossRefID - the line to stop monitoring
	 */
	private async ctiLineMonitorStop(u8sMonitorCrossRefID: string) {
		const argument = new ENetUC_CTI.AsnCtiLineMonitorStopArgument({
			u8sMonitorCrossRefID
		});
		return new Promise((resolve, reject) => {
			const callback = (result: unknown) => {
				if (result instanceof Error) {
					reject(result);
				} else {
					resolve(result as AsnCtiLineMonitorStopResult);
				}
			};
			this.socket.send("asnCtiLineMonitorStop", argument, callback);
		});
	}

	/**
	 * Set DnD for a line
	 *
	 * @param u8sLinePhoneNumber - the line to set DnD
	 * @param bDoNotDisturb - true to set DnD, false to unset DnD
	 */
	public async ctiLineSetDoNotDisturb(u8sLinePhoneNumber: string, bDoNotDisturb: boolean) {
		const argument = new ENetUC_CTI.AsnCtiLineSetDoNotDisturbArgument({
			u8sLinePhoneNumber,
			bDoNotDisturb
		});
		this.socket.send("asnCtiLineSetDoNotDisturb", argument);
	}

	/**
	 * Set all lines DnD
	 *
	 * @param bDoNotDisturb - true to set DnD, false to unset DnD
	 */
	public async setDoNotDisturbAllLines(bDoNotDisturb: boolean) {
		const myPhoneLines = getState().myPhoneLines;
		for (const [u8sLinePhoneNumber] of myPhoneLines) {
			void this.ctiLineSetDoNotDisturb(u8sLinePhoneNumber, bDoNotDisturb);
		}
	}

	/**
	 * Set call forwarding
	 *
	 * @param argument.u8sPhoneNumberFrom - the line to set call forwarding
	 * @param argument.u8sPhoneNumberTo - the line to forward to
	 * @param argument.u8sPhoneNumberFrom.u8sPhoneNumberFrom
	 * @param argument.u8sPhoneNumberFrom.u8sPhoneNumberTo
	 */
	public async ctiSetForward({
		u8sPhoneNumberFrom,
		u8sPhoneNumberTo
	}: {
		u8sPhoneNumberFrom: string;
		u8sPhoneNumberTo: string;
	}): Promise<AsnCtiSetForwardResult> {
		const argument = new ENetUC_CTI.AsnCtiSetForwardArgument({
			u8sPhoneNumberFrom,
			u8sPhoneNumberTo
		});

		return new Promise((resolve, reject) => {
			const callback = (result: unknown) => {
				if (result instanceof Error) {
					this.logger.error("Error ctiSetForward", "ctiSetForward", this, { result });
					reject(result);
				} else {
					resolve(result as ENetUC_CTI.AsnCtiSetForwardResult);
				}
			};
			this.socket.send("asnCtiSetForward", argument, callback);
		});
	}

	/**
	 * Remove call forwarding
	 *
	 * @param argument.u8sPhoneNumberFrom - the line to set call forwarding
	 * @param argument.u8sPhoneNumberTo - the line to forward to
	 * @param argument.u8sPhoneNumberFrom.u8sPhoneNumberFrom
	 * @param argument.u8sPhoneNumberFrom.u8sPhoneNumberTo
	 * @param u8sPhoneNumberFrom
	 */
	public async ctiRemoveForward(u8sPhoneNumberFrom: string) {
		const argument = new ENetUC_CTI.AsnCtiRemoveForwardArgument({
			u8sPhoneNumberFrom
		});
		this.socket.send("asnCtiRemoveForward", argument);
	}

	/**
	 * Set remote office
	 *
	 * @param root0
	 * @param root0.u8sLinePhoneNumber
	 * @param root0.iEnabled
	 * @param root0.u8sDestination
	 */
	public async ctiLineSetRemoteOffice({
		u8sLinePhoneNumber,
		iEnabled,
		u8sDestination
	}: {
		u8sLinePhoneNumber: string;
		iEnabled: number;
		u8sDestination: string;
	}): Promise<ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult> {
		const remoteOfficeState = ENetUC_CTI.AsnRemoteOfficeState.initEmpty();
		remoteOfficeState.iEnabled = iEnabled;
		remoteOfficeState.u8sDestination = u8sDestination;

		const argument = new ENetUC_CTI.AsnCtiLineSetRemoteOfficeArgument({
			u8sLinePhoneNumber,
			remoteOfficeState
		});

		return new Promise((resolve, reject) => {
			const callback = (result: unknown) => {
				if (result instanceof Error) {
					this.logger.error("Error ctiLineSetRemoteOffice", "ctiLineSetRemoteOffice", this, { result });
					reject(result);
				} else {
					resolve(result as ENetUC_CTI.AsnCtiLineSetRemoteOfficeResult);
				}
			};
			this.socket.send("asnCtiLineSetRemoteOffice", argument, callback);
		});
	}
}
