// [PrintTSCode]
// [PrintTSComments]
/*
 * SNACCROSE.ts
 * "SNACC-ROSE" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSImports]

// [PrintTSTypeDefCode] GeneralProblem
// [PrintTSimpleDefCode] GeneralProblem
export type GeneralProblem = number;
export enum GeneralProblemenum {
	unrecognisedAPDU = 0,
	mistypedAPDU = 1,
	badlyStructuredAPDU = 2
}

// [PrintTSTypeDefCode] InvokeProblem
// [PrintTSimpleDefCode] InvokeProblem
export type InvokeProblem = number;
export enum InvokeProblemenum {
	duplicateInvocation = 0,
	unrecognisedOperation = 1,
	mistypedArgument = 2,
	resourceLimitation = 3,
	initiatorReleasing = 4,
	unrecognisedLinkedID = 5,
	linkedResponseUnexpected = 6,
	unexpectedChildOperation = 7,
	invalidSessionID = 8,
	authenticationIncomplete = 9,
	authenticationFailed = 10
}

// [PrintTSTypeDefCode] ReturnResultProblem
// [PrintTSimpleDefCode] ReturnResultProblem
export type ReturnResultProblem = number;
export enum ReturnResultProblemenum {
	unrecognisedInvocation = 0,
	resultResponseUnexpected = 1,
	mistypedResult = 2
}

// [PrintTSTypeDefCode] ReturnErrorProblem
// [PrintTSimpleDefCode] ReturnErrorProblem
export type ReturnErrorProblem = number;
export enum ReturnErrorProblemenum {
	unrecognisedInvocation = 0,
	errorResponseUnexpected = 1,
	unrecognisedError = 2,
	unexpectedError = 3,
	mistypedParameter = 4
}

// [PrintTSTypeDefCode] ROSERejectChoice
// [PrintTSChoiceDefCode] ROSERejectChoice
export class ROSERejectChoice {
	public constructor(that?: ROSERejectChoice) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSERejectChoice {
		return new ROSERejectChoice();
	}

	public invokedID?: number = undefined;
	public invokednull?: object = undefined;
}

// [PrintTSTypeDefCode] ROSEResultSeq
// [PrintTSSeqDefCode] ROSEResultSeq
export class ROSEResultSeq {
	public constructor(that: ROSEResultSeq) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSEResultSeq {
		return new ROSEResultSeq({
			resultValue: 0,
			result: undefined
		});
	}

	public resultValue!: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public result!: any;
}

// [PrintTSTypeDefCode] ROSEAuth
// [PrintTSSeqDefCode] ROSEAuth
export class ROSEAuth {
	public constructor(that: ROSEAuth) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSEAuth {
		return new ROSEAuth({
			method: "",
			authdata: new Uint8Array(0)
		});
	}

	public method!: string;
	public authdata!: Uint8Array;
}

// [PrintTSTypeDefCode] ROSEError
// [PrintTSSeqDefCode] ROSEError
export class ROSEError {
	public constructor(that: ROSEError) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSEError {
		return new ROSEError({
			invokedID: 0,
			error_value: 0
		});
	}

	public sessionID?: string;
	public invokedID!: number;
	public error_value!: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public error?: any;
}

// [PrintTSTypeDefCode] ROSEAuthList
// [PrintTSSetOfDefCode] ROSEAuthList
export class ROSEAuthList extends Array<ROSEAuth> { }

// [PrintTSTypeDefCode] ROSEAuthRequest
// [PrintTSSeqDefCode] ROSEAuthRequest
export class ROSEAuthRequest {
	public constructor(that: ROSEAuthRequest) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSEAuthRequest {
		return new ROSEAuthRequest({
			auth: ROSEAuth.initEmpty()
		});
	}

	public auth!: ROSEAuth;
	public context?: string;
}

// [PrintTSTypeDefCode] ROSEAuthResult
// [PrintTSSeqDefCode] ROSEAuthResult
export class ROSEAuthResult {
	public constructor(that: ROSEAuthResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSEAuthResult {
		return new ROSEAuthResult({
			authList: new ROSEAuthList()
		});
	}

	public authList!: ROSEAuthList;
	public context?: string;
}

// [PrintTSTypeDefCode] ROSEInvoke
// [PrintTSSeqDefCode] ROSEInvoke
export class ROSEInvoke {
	public constructor(that: ROSEInvoke) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSEInvoke {
		return new ROSEInvoke({
			invokeID: 0,
			operationID: 0
		});
	}

	public sessionID?: string;
	public invokeID!: number;
	public linked_ID?: number;
	public operationName?: string;
	public authentication?: ROSEAuthRequest;
	public operationID!: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public argument?: any;
}

// [PrintTSTypeDefCode] ROSEResult
// [PrintTSSeqDefCode] ROSEResult
export class ROSEResult {
	public constructor(that: ROSEResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSEResult {
		return new ROSEResult({
			invokeID: 0
		});
	}

	public sessionID?: string;
	public invokeID!: number;
	public result?: ROSEResultSeq;
}

// [PrintTSTypeDefCode] RejectProblem
// [PrintTSChoiceDefCode] RejectProblem
export class RejectProblem {
	public constructor(that?: RejectProblem) {
		Object.assign(this, that);
	}

	public static initEmpty(): RejectProblem {
		return new RejectProblem();
	}

	public generalProblem?: number = undefined;
	public invokeProblem?: number = undefined;
	public returnResultProblem?: number = undefined;
	public returnErrorProblem?: number = undefined;
}

// [PrintTSTypeDefCode] ROSEReject
// [PrintTSSeqDefCode] ROSEReject
export class ROSEReject {
	public constructor(that: ROSEReject) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSEReject {
		return new ROSEReject({
			invokedID: ROSERejectChoice.initEmpty()
		});
	}

	public sessionID?: string;
	public invokedID!: ROSERejectChoice;
	public reject?: RejectProblem;
	public details?: string;
	public authentication?: ROSEAuthResult;
}

// [PrintTSTypeDefCode] ROSEMessage
// [PrintTSChoiceDefCode] ROSEMessage
export class ROSEMessage {
	public constructor(that?: ROSEMessage) {
		Object.assign(this, that);
	}

	public static initEmpty(): ROSEMessage {
		return new ROSEMessage();
	}

	public invoke?: ROSEInvoke = undefined;
	public result?: ROSEResult = undefined;
	public error?: ROSEError = undefined;
	public reject?: ROSEReject = undefined;
}
