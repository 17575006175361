import { t } from "i18next";

import { EError, ILogData } from "../../web-shared-components/helpers/logger/ILogger";
import {
	IS_WEB_VIEW,
	theClientPersistenceManager,
	theContactManager,
	theCtiManager,
	theJournalManager,
	theLoginController
} from "../globals";
import { IBaseSingletons } from "../interfaces/IBaseSingletons";
import BaseSingleton from "../lib/BaseSingleton";
import { generateGUID } from "../lib/common";
import { AsnNegotiateServerConnectionResult } from "../ucserver/stubs/ENetUC_Auth";
import {
	AsnClientCapabilitiesV2,
	AsnClientCapabilityEnum,
	AsnSetClientCapabilitiesV2Argument
} from "../ucserver/stubs/ENetUC_ClientCapabilities";
import { AsnRequestError, AsnStringPairList } from "../ucserver/stubs/ENetUC_Common";
import { AsnContact } from "../ucserver/stubs/ENetUC_Common_AsnContact";
import { getState } from "../zustand/store";
import { IUCGetTokenRequest } from "./LoginController";
import { SocketTransport } from "./SocketTransport";

/**
 * Interface for the created sessions, stored into an array.
 */
interface IStoredSession {
	sessionID: string;
	ucserverUri: string;
}

// Result of the get UC Proxy request
interface IGetUCWS {
	redirect: string;
}

// Result of the get UCServer version
interface IGetUCSToken {
	ucserverprotocolversion: string;
}

// Result of the get UCServer version
export interface IUCServerInfo {
	ucserverinterfaceversion: string;
	ucserverprotocolversion: string;
	ucserverversion: string;
}

// Result of the create UCServer session
interface ICreateUCSSession {
	sessionid: string;
	ownContact?: AsnContact;
}

// Simplified interface to handle the messages received through the websocket
export interface IUCClientHandler {
	on_WebSocketClosed(): void;
}

/**
 * Private client for UCServer.
 * It takes care of doing all the stuff needed to create a session against UCServer.
 *
 */
export class UCClient extends BaseSingleton implements IUCClientHandler {
	// Instance of this class to use as singleton.
	private static instance: UCClient;
	// The UC Connect Controller URL
	private ucControllerURL = "https://uccontroller.ucconnect.de";
	private ucServerURI: string | undefined;
	private ucsid: string | undefined;
	private token: string | undefined;
	private socket: SocketTransport;
	public sessionID?: string;
	private previousSessions: IStoredSession[] = [];
	private ownContact?: AsnContact;
	private isConnecting = false;
	// Take track if the connection has been lost once. Needed to resubscribe to the presences after a reconnection.
	private connectionWasLost = false;
	private reconnectingInterval: NodeJS.Timer | null = null;

	/**
	 * Constructs UcsPrivateClient.
	 * Method is private as we follow the Singleton Approach using getInstance
	 *
	 * @param attributes - the constructor attributes
	 */
	private constructor(attributes: IBaseSingletons) {
		super(attributes);

		this.socket = SocketTransport.getInstance();
		this.socket.setUCClientHandler(this);
	}

	/**
	 * Gets instance of UCClient to use as singleton.
	 *
	 * @param attributes - the constructor attributes
	 * @returns - an instance of this class.
	 */
	public static getInstance(attributes: IBaseSingletons): UCClient {
		if (!UCClient.instance) {
			UCClient.instance = new UCClient(attributes);
		}
		return UCClient.instance;
	}

	/**
	 * The Loggers getLogData callback (used in all the log methods called in this class, add the classname to every log entry)
	 *
	 * @returns - an ILogData log data object provided additional data for all the logger calls in this class
	 */
	public getLogData(): ILogData {
		return {
			className: "UCClient"
		};
	}

	/**
	 * Handling the web socket closed event.
	 * Start a reconnecting trial on intervals.
	 */
	public on_WebSocketClosed() {
		this.connectionWasLost = true;
		if (this.reconnectingInterval) {
			clearInterval(this.reconnectingInterval);
		}
		if (IS_WEB_VIEW) {
			this.reconnectingInterval = setInterval(this.connectWebView.bind(this), 8000);
		} else {
			this.reconnectingInterval = setInterval(this.reconnectStandAlone.bind(this), 8000);
		}
	}

	/**
	 * Start reconnecting in case when the interval is not yet set.
	 */
	private startReconnecting() {
		this.connectionWasLost = true;
		if (!this.reconnectingInterval) {
			if (IS_WEB_VIEW) {
				this.reconnectingInterval = setInterval(this.connectWebView.bind(this), 10000);
			} else if (this.ucsid) {
				this.reconnectingInterval = setInterval(this.reconnectStandAlone.bind(this), 10000);
			}
		}
	}

	/**
	 * Clear the reconnecting related parameters
	 */
	private clearReconnecting() {
		if (this.reconnectingInterval) {
			clearInterval(this.reconnectingInterval);
		}
	}

	/**
	 * Reconnect logic for stand-alone application
	 */
	private async reconnectStandAlone() {
		// Without a stored token it cannot try to reconnect.
		// We don't store any password.
		if (!this.token) {
			return;
		}
		await this.connectStandAlone(this.ucsid, this.ucServerURI, this.token, undefined, undefined);
	}

	/**
	 * Connect to UCserver via websocket.
	 * Logic for the StandAlone application.
	 *
	 * @param ucsid - the ucsid
	 * @param ucserveruri - use ucserver url
	 * @param token - token, in case we already have one
	 * @param username - username, in case we don't have a token
	 * @param password - password, in case we don't have a token
	 */
	public async connectStandAlone(
		ucsid: string | undefined,
		ucserveruri: string | undefined,
		token: string | undefined,
		username: string | undefined,
		password: string | undefined
	): Promise<string | undefined> {
		if (this.isConnecting) {
			return;
		}
		this.isConnecting = true;
		if (ucserveruri) {
			theLoginController.setServerURI(ucserveruri);
		}
		theLoginController.setUCControllerURL(this.ucControllerURL);

		try {
			let result;
			if (ucsid) {
				this.ucsid = ucsid;
				result = await theLoginController.getUserToken(ucsid, token, username, password);
			} else if (ucserveruri) {
				this.ucServerURI = ucserveruri;
				result = await theLoginController.getUserTokenLocal(token, username, password);
			}

			this.isConnecting = false;

			if (result && !(result instanceof AsnRequestError)) {
				if (result.sToken) {
					this.token = result.sToken;
					const startResult = await this.start(result.sToken);
					if (startResult instanceof Error || startResult instanceof AsnRequestError) {
						throw startResult;
					}
					return this.token;
				}
			}
		} catch (e) {
			// store.dispatch(setBanner("noServerConnection"));
			this.isConnecting = false;
			// In case the server is not responding since the beginning, let's start the reconnecting interval.
			// Otherwise, the interval is set on web socket closed event.
			this.startReconnecting();
			return undefined;
		}
	}

	/**
	 * Connect to UCserver via websocket.
	 * Logic for the WebView application.
	 *
	 * 1. Get information about the connection (asnNegotiateServerConnection)
	 * 2. Get an existing token from ProCall client
	 * 3. Create the session and set the client capabilities (calling this.start)
	 * 4. Subscribe to the chat events
	 */
	public async connectWebView() {
		if (this.isConnecting) {
			return;
		}
		let ucscontroller = "";
		let localucweb = "";
		this.isConnecting = true;

		try {
			const negotiateServerConnectionArgument = {
				_type: "AsnNegotiateServerConnectionArgument",
				iClientProtocolVersion: 70,
				optionalParams: {
					SoftphoneClient: true,
					ProvideAVLine: true
				}
			};
			const init = {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(negotiateServerConnectionArgument)
			};
			const negotiateServerConnectionRequest = await fetch(
				"http://ecticlient.local/asnWebRequest/asnNegotiateServerConnection",
				init
			);
			const negotiateServerConnectionResult =
				(await negotiateServerConnectionRequest.json()) as AsnNegotiateServerConnectionResult;
			const settingsRequest = await fetch("http://ecticlient.local/hostWebRequest/settings.json");
			const settingsResult = (await settingsRequest.json()) as unknown;

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (!settingsResult.IsWebSocket && negotiateServerConnectionResult.optionalParams.localUCWeb) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore

				localucweb = negotiateServerConnectionResult.optionalParams.localUCWeb as unknown;

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			} else if (
				// @ts-ignore
				negotiateServerConnectionResult.optionalParams.ucsid &&
				// @ts-ignore
				negotiateServerConnectionResult.optionalParams.ucscontroller
			) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				this.ucsid = negotiateServerConnectionResult.optionalParams.ucsid as unknown;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				ucscontroller = negotiateServerConnectionResult.optionalParams.ucscontroller as unknown;
			}
			const oArgument = {
				_type: "AsnGetUserTokenArgument",
				iType: 1
			};
			const oInit = {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(oArgument)
			};
			const req = await fetch("http://ecticlient.local/asnWebRequest/asnGetUserToken", oInit);
			const result = (await req.json()) as IUCGetTokenRequest;
			if (result && result.sToken) {
				if (localucweb) {
					this.ucServerURI = localucweb;
				} else if (ucscontroller) {
					this.ucControllerURL = ucscontroller;
				}
				const startResult = await this.start(result.sToken);
				if (startResult instanceof Error || startResult instanceof AsnRequestError) {
					throw startResult;
				}
				this.token = result.sToken;

				// store.dispatch(setBanner("noServerConnection"));
			} else {
				this.isConnecting = false;
			}
		} catch (e) {
			// store.dispatch(setBanner("noServerConnection"));
			console.log(e); // TODO: add error logs / messages
			this.isConnecting = false;
			// In case the server is not responding since the beginning, let's start the reconnecting interval.
			// Otherwise, the interval is set on web socket closed event.
			this.startReconnecting();
		}
	}

	/**
	 * Starts the client:
	 * 1. creates a session in UCServer;
	 * 2. connects a WebSocket to it, using the returned sessionid;
	 * 3. sets the client capabilities;
	 *
	 * @param token - a string containing the token
	 * @returns - a promise with errors or true if no errors
	 */
	public async start(token: string): Promise<boolean | Error | AsnRequestError> {
		const sessionID = await this.createSession(token);
		if (sessionID instanceof AsnRequestError) {
			return sessionID;
		}
		if (typeof sessionID === "string") {
			this.sessionID = sessionID;
			try {
				await this.socket.connect(sessionID);
			} catch (e) {
				return e as Error;
			}
			if (this.setClientCapabilities() instanceof Error) {
				return new Error("Unable to set the capabilities via WebSocket");
			}
			this.clearReconnecting();
			this.isConnecting = false;
			// Setup subscriptions
			if (this.connectionWasLost) {
				theContactManager.initAndResubscribe();
			}
			await theJournalManager.journalSubscribeEvents();
			await theCtiManager.ctiPhoneLineSubscribeEvents();
			await theClientPersistenceManager.init();

			getState().setMySelfIsLogged(true);
			if (this.ucsid) {
				getState().setMySelfUCSID(this.ucsid);
			} else if (this.ucServerURI) {
				getState().setMySelfUCServerURI(this.ucServerURI);
			}
			return true;
		} else {
			this.isConnecting = false;
			getState().setMySelfToken(undefined);
			getState().setMySelfIsLogged(false);

			return new Error("The session id returned by UCServer is not a string");
		}
	}

	/**
	 * Stops the client.
	 * Destroys the session in UCServer;
	 *
	 * @returns - a promise with errors or true if no errors
	 */
	private async stop(): Promise<boolean | Error> {
		if ((await this.destroySession()) instanceof Error) {
			return new Error("Unable to set destroy session in UCServer");
		}
		return true;
	}

	/**
	 * Gets the URI of UCServer from UCController.
	 *
	 * @param ucsid - the ucsid
	 * @returns - a promise with a string containing the URI, or an error
	 */
	private async getUriFromUCC(ucsid: string): Promise<string | AsnRequestError> {
		if (!this.ucControllerURL) {
			this.logger.error(
				"You need to setup the uccontroller URL",
				"getUriFromUCC",
				this,
				{},
				EError.InvalidUCControllerURL
			);
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "You need to setup the uccontroller URL" });
		}
		const url = this.ucControllerURL + "/controller/client/ucws?ucsid=" + ucsid + "&needswcs=1";
		try {
			const getUcwebUrlResponse = await fetch(url);
			const ucwebUrlData = (await getUcwebUrlResponse.json()) as IGetUCWS;
			if (ucwebUrlData && ucwebUrlData.redirect) {
				return ucwebUrlData.redirect;
			} else {
				this.ucsid = undefined;
				this.logger.error("Invalid ucsid", "getUriFromUCC", this, { ucwebUrlData }, EError.InvalidUCSID);
				return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucsid" });
			}
		} catch (e) {
			this.ucsid = undefined;
			this.logger.error("Invalid ucsid", "getUriFromUCC", this, { e }, EError.InvalidUCSID);
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucsid" });
		}
	}

	/**
	 * Gets UCServer version from local server or from UCController.
	 *
	 * @returns - a promise with a string containing the server info, or an error
	 */
	public async getUCServerVersion(): Promise<IUCServerInfo | AsnRequestError> {
		let url;
		if (this.ucsid) {
			url =
				this.ucControllerURL +
				"/controller/client/ucserverversion?ucsid=" +
				this.ucsid +
				"&needswcs=1&nocache=" +
				Date.now();
		} else if (this.ucServerURI) {
			url = this.ucServerURI + "/ws/client/ucserverversion?nocache=" + Date.now();
		}
		if (!url) {
			this.logger.error(
				"No ucsid or ucserver url provided",
				"getUCServerVersion",
				this,
				{},
				EError.InvalidUCControllerURL
			);
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "No ucsid or ucserver url provided" });
		}
		try {
			const getUcwebUrlResponse = await fetch(url);
			const ucwebUrlData = (await getUcwebUrlResponse.json()) as IUCServerInfo;
			if (ucwebUrlData && ucwebUrlData.ucserverprotocolversion) {
				return ucwebUrlData;
			} else {
				this.logger.error(
					"Invalid ucserverprotocolversion",
					"getUCServerVersion",
					this,
					{},
					EError.InvalidProtocolVersion
				);
				return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucserverprotocolversion" });
			}
		} catch (e) {
			this.logger.error(
				"Invalid ucserverprotocolversion",
				"getUCServerVersion",
				this,
				{
					e
				},
				EError.InvalidProtocolVersion
			);
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucserverprotocolversion" });
		}
	}

	/**
	 * get the client version
	 *
	 * @returns the clientVersion as a string
	 */
	public getClientVersion(): string | undefined {
		const clientVersion = getState().clientVersion;
		return clientVersion;
	}

	/**
	 * get the client device  ID
	 *
	 * @returns the clientDeviceID as a string
	 */
	public getClientDeviceID(): string | undefined {
		const clientDeviceId = generateGUID();
		return clientDeviceId;
	}

	/**
	 * get the client Device Name
	 *
	 * @returns the clientDeviceName as a string
	 */
	public getClientDeviceName(): string | undefined {
		const applicationName = getState().applicationName;
		const clientDeviceName = applicationName + "-" + this.getClientDeviceID();
		return clientDeviceName;
	}

	/**
	 * Creates a session on UCServer.
	 *
	 * @param token - a string containing the token
	 * @returns - a promise containing the session id or an error
	 */
	private async createSession(token: string): Promise<string | Error | AsnRequestError> {
		const loginString = "JWT " + token;

		/* const ucserverProtocolVersion = await this.getUCServerVersion(ucsid);
		if (ucserverProtocolVersion instanceof AsnRequestError)
			return ucserverProtocolVersion; */

		const ClientDeviceId = this.getClientDeviceID();
		const ClientDeviceName = this.getClientDeviceName();
		const ClientVersion = this.getClientVersion();

		const createSessionPayload = {
			negotiate: {
				iClientProtocolVersion: 70,
				optionalParams: {
					waitForReconnectTimeInSec: 5,
					SoftphoneClient: 1,
					ProvideAVLine: 1,
					ClientDeviceName,
					ClientDeviceId
				}
			},
			logon: {
				u8sVersion: ClientVersion
			}
		};

		const body = JSON.stringify(createSessionPayload);

		// In case the ucserver uri is not defined and we use ucsid, let's ask ucconnect
		// ucsid is set ONLY when the login form shows that field, which is mutally excluding the local server,
		// so we want to ask the url on each createSession
		if (this.ucsid) {
			const ucserverUri = await this.getUriFromUCC(this.ucsid);
			if (ucserverUri instanceof AsnRequestError) {
				return ucserverUri;
			}
			this.ucServerURI = ucserverUri;
		}

		if (!this.ucServerURI) {
			return new Error("You need to setup the UCServer URI");
		}

		this.socket.setUCCEndpoint(this.ucServerURI);

		const TeamsAppId = "28";

		const url = this.ucServerURI + `/ws/client/createsession?clientappid=${TeamsAppId}`;

		const headers: Record<string, string> = {
			Authorization: loginString,
			// "x-ucsid": ucsid,
			"x-epid": generateGUID(),
			"x-no401": "1",
			"Content-Type": "application/json"
		};

		if (this.ucsid) {
			headers["x-ucsid"] = this.ucsid;
		}

		const options = {
			method: "POST",
			headers,
			body
		};

		try {
			// Before creating a new session clean the potential existing ones
			await this.killPreviousSessions();

			const sessionResponse = await fetch(url, options);
			const response: unknown = await sessionResponse.json();
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (response.error) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const err = response.error as AsnLogonError;
				this.logger.error(err.u8sErrorString || "", "createSession", this, { err }, EError.SessionNoID);
				return new Error("Server returned no session id");
			} else {
				const session = response as ICreateUCSSession;
				const lastContact = localStorage.getItem("last-logged-user-id");
				if (session && session.sessionid && session.ownContact) {
					if (lastContact && lastContact !== session.ownContact.u8sContactId) {
						// The logged-in user is different from the previous one:
						// - Clean up store
						getState().reset();
						// - Clean up the browser cache API
						// await theFileTransferManager.deleteCaches();
						// - Set the new user contact id in the localStorage for the next check
						localStorage.setItem("last-logged-user-id", session.ownContact.u8sContactId);
					} else {
						localStorage.setItem("last-logged-user-id", session.ownContact.u8sContactId);
					}
					this.ownContact = session.ownContact;
					console.log("Own contact", this.ownContact);

					getState().setOwnContactId(this.ownContact.u8sContactId);
					getState().setOwnUserPropertyBag(this.ownContact.asnUserPropertyBag);
					getState().setContactsDetails([
						{ ...this.ownContact.asnRemoteContact, u8sEntryID: this.ownContact.u8sContactId }
					]);

					// Keep track of the created sessions and store them into an array.
					this.previousSessions.push({
						sessionID: session.sessionid,
						ucserverUri: this.ucServerURI
					});
					return session.sessionid;
				} else {
					return new Error("Server returned no session id");
				}
			}
		} catch (e) {
			this.logger.error("Error creating session", "createSession", this, { e }, EError.SessionCreateGeneric);
			return e as Error;
		}
	}

	/**
	 * Destroys the UCServer session.
	 *
	 * @param sessionID - optional session id to destroy. In case is not set it destroys the current one.
	 * @param ucserverUri - optional path where to call the destroysession method. In case is not set it uses the current stored one.
	 * @returns - a promise true if success, false in case of no session available or an error if fails.
	 */
	private async destroySession(sessionID?: string, ucserverUri?: string): Promise<boolean | Error> {
		if (!this.sessionID || !sessionID) {
			return false;
		}
		const url =
			(ucserverUri || this.ucServerURI) + "/ws/client/destroysession?ucsessionid=" + (sessionID || this.sessionID);
		const options = {
			method: "GET"
		};
		try {
			await fetch(url, options);
			// const destroySessionResponse = await fetch(url, options);
			// const destroySessionData = await destroySessionResponse.json();
			// console.log(destroySessionData);
			this.ownContact = undefined;
			return true;
		} catch (e) {
			this.logger.error("Error destroying session", "createSession", this, { e }, EError.SessionDestroyGeneric);
			return e as Error;
		}
	}

	/**
	 * Kills the previous created sessions.
	 */
	private async killPreviousSessions() {
		await Promise.all(
			this.previousSessions.map(async (session: IStoredSession) => {
				await this.destroySession(session.sessionID, session.ucserverUri);
			})
		);
		this.previousSessions = [];
	}

	/**
	 * Sets the client capabilities for chat.
	 *
	 * @returns - true or an error
	 */
	private setClientCapabilities(): boolean | Error {
		const capabilitiesEnum = [
			AsnClientCapabilityEnum.bPublicCall,
			AsnClientCapabilityEnum.bAudio,
			AsnClientCapabilityEnum.bVideo
		];
		const capabilities: AsnClientCapabilitiesV2 = new AsnClientCapabilitiesV2({
			eClientCapabilities: capabilitiesEnum,
			customCapabilities: new AsnStringPairList()
		});

		const capabilitiesArg: AsnSetClientCapabilitiesV2Argument = new AsnSetClientCapabilitiesV2Argument({
			capabilities
		});
		return this.socket.send("asnSetClientCapabilitiesV2", capabilitiesArg);
	}

	/**
	 * Get my own contact as resulted by the session creation
	 *
	 * @returns - my own contact or undefined if not set
	 */
	public getOwnContact(): AsnContact | undefined {
		return this.ownContact;
	}
}
