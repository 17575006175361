// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetROSEInterface.ts
 * "UC-Server-Access-Protocol-Main" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.16, 10.08.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * General interface
 * ## Module description
 * General interfacefunctions not included in any other module.
 */
// [PrintTSImports]
import { TSDeprecatedCallback } from "./TSDeprecatedCallback";
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
import * as ENetUC_Common_Appointments from "./ENetUC_Common_Appointments";
// [PrintTSRootTypes]
export const moduleName = "ENetROSEInterface";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnContactStatusParameterEnum {
	activeContactID = 0,
	phoneNumber = 1,
	eMail = 2
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnETAPIDialNumberFormat {
	/** Normal formatting, all rules apply */
	eTAPIDIALNUMBERFORMATNORMAL = 0,
	/** Make all numbers upercanoncal (e.g. +49815136856177) */
	eTAPIDIALNUMBERFORMATSUPERCANONIC = 1,
	/** No rules apply, numbers will be dialed like they were entered; only special character will be removed */
	eTAPIDIALNUMBERFORMATPLAIN = 2,
	/** Plain numbers will be dialed like they were entered, supercanonical numbers will be formatted. The PBX will always get dialable numbers. */
	eTAPIDIALNUMBERFORMATDIALABLEPLAIN = 3
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnERemoteTapiNumberFormat {
	/** Plain, wie von PBX */
	eREMOTETAPINUMBERFORMATPLAIN = 0,
	/** Vorformatiert, aber nicht Superkanonisch */
	eREMOTETAPINUMBERFORMATPREFORMAT = 1,
	/** Superkanonisch */
	eREMOTETAPINUMBERFORMATSUPERCANONIC = 2
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnERemoteTapiDialFormat {
	/** Plain wählen, nur PC Wahl Final anwenden */
	eREMOTETAPIDIALFORMATPLAIN = 0,
	/** Komplett Formatieren */
	eREMOTETAPIDIALFORMATALL = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnERemoteTapiLineNames {
	/** Wie vom TAPI-Treiber gemeldet */
	eREMOTETAPILINENAMESORIGINAL = 0,
	/** Mit Standortinformationen erweitert */
	eREMOTETAPILINENAMESLINENUMBER = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnForwardControllerMode {
	/** Die Rufumleitung wird in der PBX gesetzt */
	iCTIFORWARDCONTROLLERPBX = 0,
	/** Die Rufumleitung wird im TapiServer gesetzt (Administrative Umleitung) */
	iCTIFORWARDCONTROLLERTAPISERVERADMIN = 1,
	/** Die Rufumleitung wird im TapiServer gesetzt (User Umleitung) */
	iCTIFORWARDCONTROLLERTAPISERVERUSER = 2
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnForwardMode {
	/** Forward unknown */
	iCTIFORWARDUNKNOWN = 0,
	/** Forward Immediate */
	iCTIFORWARDIMMEDIATE = 1,
	/** Forward on Busy */
	iCTIFORWARDBUSY = 2,
	/** Forward on No Answer */
	iCTIFORWARDNOANSW = 3,
	/** Forward on Busy for internal */
	iCTIFORWARDBUSYINT = 4,
	/** Forward on Busy for external */
	iCTIFORWARDBUSYEXT = 5,
	/** Forward on No answer for int */
	iCTIFORWARDNOANSINT = 6,
	/** Forward on No answer for ext */
	iCTIFORWARDNOANSEXT = 7,
	/** Forward immediate for intern */
	iCTIFORWARDIMMINT = 8,
	/** Forward immediate for extern */
	iCTIFORWARDIMMEXT = 9,
	/** Backend specific forwarding */
	iCTIBACKENDSPECIFIC = 10
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPresenceStateEnum {
	/** Presence is not available, contact is unknown. */
	eUNCONTROLLED = 0,
	/** Contact is available. */
	eAVAILABLE = 1,
	/** Contact is busy. */
	eBUSY = 2,
	/** Contact is absent. */
	eABSENT = 3,
	/** Contact wished to not being disturbed. */
	eDONOTDISTURB = 4,
	/** Something does not work, line is not connected, device is broken etc. Contact may not get into this state. */
	eOUTOFORDER = 7
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPresenceStateFlags {
	/** 0x00010000 - The contact is doing work after a call and should be treated as \"busy\". (added after PCE 5.1) */
	eWORKINGAFTERCALL = 65536,
	/** 0x00020000 - The contact is participating a conference with our conferencing product (added with PCE 7.1) */
	eCONFERENCING = 131072,
	/** 0x01000000 - The contact has a call ringing on one of his phone or A\/V lines. */
	eRINGING = 16777216,
	/** 0x02000000 - The contavct has currently an active appointment in \"absent\", \"busy\" or \"free\" state. */
	eAPPOINTMENT = 33554432,
	/** 0x04000000 - The contact is in a call. */
	eCALL = 67108864,
	/** 0x08000000 - The contact is offline. */
	eOFFLINE = 134217728,
	/** 0x10000000 - The contact has set a note. */
	eNOTESET = 268435456,
	/** 0x20000000 - The contact does not used his mouse or keyboard for a time or is only logged in with a mobile device. */
	eIDLE = 536870912,
	/** 0x40000000 - The contact is most likely reachable on his mobile device. */
	eMOBILEAVAILABLITY = 1073741824
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPresenceStateMasks {
	/** 0x000000ff - Currently the lower byte contains the presence state */
	ePresenceStateMask = 255,
	/** 0x00ff0000 - Additional flags introduced later in the development process of ProCall after Version 5.1. */
	ePresenceStateFlagsMaskAdd = 16711680,
	/** 0x7f000000 - Currently the top most byte of the DWORD contains the presence state flags. Addendum: a technical constraint in the c# library prevents the usage of the top most bit. */
	ePresenceStateFlagsMask = 2130706432
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumCallState {
	/** Call is in an unknown state. */
	eCALLSTATEUNKNOWN = 0,
	/** Call was destroyed by dropping it. */
	eCALLSTATEDESTROYED = 1,
	/** Call is ringing inbound. */
	eCALLSTATEOFFERING = 2,
	/** User is dialing. */
	eCALLSTATEDIALING = 4,
	/** Call is ringing outbound (call number is complete) */
	eCALLSTATERINGBACK = 8,
	/** Call outbound is busy. */
	eCALLSTATEBUSY = 16,
	/** Call is connected (same for in- and outbound) */
	eCALLSTATECONNECTED = 32,
	/** Call is part of a call conference. */
	eCALLSTATECONFERENCED = 64,
	/** Call is on hold. */
	eCALLSTATEHOLD = 128,
	/** Call is disconnected by the call partner. The user should drop the call too, then it will get destroyed too. */
	eCALLSTATEDISCONNECTED = 256,
	/** Call is in a prepare state (special for procall, this is not a normal PBX state). */
	eCALLSTATEPREPARECALL = 512
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumCallDisconnectedReason {
	/** Disconnect reason unknown, even if the call was successful connected and one of the participants has dropped the call. */
	eDISCONNECTEDREASONUNKNOWN = 0,
	/** The call partner did not answer the call. */
	eDISCONNECTEDREASONUNCONNECTED = 1,
	/** The call partner is busy. */
	eDISCONNECTEDREASONBUSY = 2,
	/**
	 * Anruf wurde aus einem ECALLSTATE_OFFERING heraus mit redirect oder Pickup weggeholt
	 * The call was redirected or picked in the state offering (eCALLSTATEOFFERING) by an unkown line or participant.
	 */
	eDISCONNECTEDREASONREDIRECTEDUNKNOWN = 3,
	/** The call was redirected or picked in the state offering (eCALLSTATEOFFERING) by the line owner. */
	eDISCONNECTEDREASONREDIRECTEDBYLINEOWNER = 4
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumRemoteContactSource {
	/** Data source is unknown\/not set */
	eUNKNOWN = 0,
	/** Server provided the contact data */
	eSERVERPROVIDED = 1,
	/** The client provided the contact data */
	eCLIENTPROVIDED = 2,
	/** Another client provided the contact data (if the same user has more than one client running) */
	eOTHERCLIENTPROVIDED = 3
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumCallType {
	/** Just a normal call. */
	eCALLTYPENORMAL = 0,
	/** Consultation call. */
	eCALLTYPECONSULT = 1,
	/** Conference controller call. */
	eCALLTYPECONFERENCECONTROLER = 2,
	/** Callback call (callback while busy or callback was not answered) */
	eCALLTYPECALLBACK = 3,
	/** Conference member call. */
	eCALLTYPECONFERENCEMEMBER = 4,
	/** Conference controller while building up the conference (US conference model) */
	eCALLTYPECONFERENCECONTROLERPENDING = 5,
	/** Conference member while building up the conference (US conference model) */
	eCALLTYPECONFERENCEMEMBERPENDING = 6
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumCallRecordingState {
	/** Call recording not active. */
	eNOCALLRECODRING = 0,
	/** This is the call recording call to the ISDN\/CAPI device usewd to record the call. */
	eCALLRECORDERCALL = 1,
	/** Call which will be recorded. */
	eCALLRECORDINGMEMBER = 2,
	/** Call which was the **recorder** call. */
	eWASCALLRECORDERCALL = 3,
	/** Call which was the **recorded** call. */
	eWASCALLRECORDINGMEMBER = 4
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnLicensedFeature {
	asnlicensedfeatureusers = 1,
	asnlicensedfeaturelines = 2,
	asnlicensedfeatureclients = 3,
	asnlicensedfeatureumreplicator = 4,
	asnlicensedfeatureanalytics = 5,
	asnlicensedfeatureinstances = 6,
	asnlicensedfeatureserverlines = 7,
	asnlicensedfeaturechallengepbx = 8,
	asnlicensedfeaturecstaconnections = 9,
	asnlicensedfeaturechannels = 10,
	asnlicensedfeatureucconnectmobileaccess = 11,
	asnlicensedfeaturehoursvideocommunication = 12,
	asnlicensedfeatureuniquerequest = 13,
	asnlicensedfeaturelivechatclients = 14,
	asnlicensedfeaturemeshconnections = 15,
	asnlicensedfeaturev7 = 16,
	asnlicensedfeaturedecouplepbx = 17,
	asnlicensedfeatureanalyticssites = 18,
	asnlicencedfeatureucconnectconferencing = 19,
	asnlicencedfeaturetapiserver = 20,
	asnlicencedfeaturedatacenterservers = 21,
	asnlicencedfeaturevoiceservice = 22,
	asnlicencedfeaturevoiceservicerequest = 23
}

// [PrintTSTypeDefCode]
// [PrintTSimpleDefCode]
export type AsnContactID = string;

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPresenceRightsFlags {
	eVisible = 1,
	eChangeable = 2,
	/** 0x0000 0010 */
	eAppointmentPrivate = 16,
	/** 0x0000 0020 */
	eAppointmentPublic = 32,
	/** 0x0000 8000 */
	ePatternUsed = 32768,
	eBlocked = 0,
	/** 0x0001 0000 */
	ePublic = 65536,
	/** 0x0002 0000 */
	eCompany = 131072,
	/** 0x0004 0000 */
	eTeam = 262144,
	/** 0x0008 0000 */
	ePersonal = 524288
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPresenceRightsMasks {
	/** 0x0000 00F0 */
	eAppointmentMask = 240,
	/** 0x000F 0000 */
	ePatternFlagsMask = 983040
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPhoneControlRightsFlags {
	eBlocked = 0,
	/** 0x0000 0001 */
	eNumbersOutbound = 1,
	/** 0x0000 0002 */
	eNumbersInbound = 2,
	/** 0x0000 0004 */
	eNumbersRedirected = 4,
	/** 0x0000 0100 */
	eSetForwards = 256,
	/** 0x0000 0200 */
	eSetPickupCalls = 512
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPresenceCommonRights {
	/** 0x0000 0000      Blocked \/ not set */
	eBlocked = 0,
	/** 0x0001 8001      UR_PRESENCE_PUBLIC  | UR_PRESENCE_PATTERN_USED                                                                                                  | UR_PRESENCE_VISIBLE */
	ePublic = 98305,
	/** 0x0002 8021      UR_PRESENCE_COMPANY | UR_PRESENCE_PATTERN_USED | UR_PRESENCE_APPOINTMENT_PUBLIC                                                                 | UR_PRESENCE_VISIBLE */
	eCompany = 163873,
	/** 0x0004 8023      UR_PRESENCE_TEAM    | UR_PRESENCE_PATTERN_USED | UR_PRESENCE_APPOINTMENT_PUBLIC                                     | UR_PRESENCE_CHANGEABLE    | UR_PRESENCE_VISIBLE */
	eTeam = 294947,
	/** 0x0008 8033      UR_PRESENCE_PERSONAL| UR_PRESENCE_PATTERN_USED | UR_PRESENCE_APPOINTMENT_PUBLIC | UR_PRESENCE_APPOINTMENT_PRIVATE   | UR_PRESENCE_CHANGEABLE    | UR_PRESENCE_VISIBLE */
	ePersonal = 557107
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPhoneControlPrimaryCommonRights {
	/** 0x0000	Blocked \/ not set */
	eBlocked = 0,
	/** 0x0007  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED */
	eCompany = 7,
	/** 0x0207  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED    | UR_PHONE_PICKUP_CALLS */
	eTeam = 519,
	/** 0x0307  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED    | UR_PHONE_PICKUP_CALLS | UR_PHONE_SET_FORWARDS */
	ePersonal = 775
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnPhoneControlSecondaryCommonRights {
	/** 0x0000	Blocked \/ not set */
	eBlocked = 0,
	/** 0x0007  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED */
	eTeam = 7,
	/** 0x0307  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED    | UR_PHONE_PICKUP_CALLS | UR_PHONE_SET_FORWARDS */
	ePersonal = 775
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnSetSettingsDynamicRightsErrorEnum {
	general = 0,
	permissiondenied = 1,
	writeprofiledenied = 2,
	unknownrights = 3
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnGetSettingsDynamicRightsErrorEnum {
	general = 0,
	permissiondenied = 1,
	readprofiledenied = 2
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnContactFeatureSetEnum {
	/** Nothing is alllowed on this contact. */
	eCONTACTFEATURENONE = 0,
	/** Dial on the primary line of the other contact. */
	eCONTACTFEATUREDIAL = 1,
	/** Set forward on the first line of the other contact. */
	eCONTACTFEATURESETFORWARDL1 = 2,
	/** Set forward on the second line of the other contact. */
	eCONTACTFEATURESETFORWARDL2 = 4,
	/** Swap Call. */
	eCONTACTFEATURESWAP = 8,
	/** Answer call. */
	eCONTACTFEATUREANSWER = 16,
	/** Hang up. */
	eCONTACTFEATUREHANGUP = 32,
	/** Pickup a ringing call on the first line of the contact. */
	eCONTACTFEATUREPICKUPL1 = 64,
	/** Pickup a ringing call on the second line of the contact. */
	eCONTACTFEATUREPICKUPL2 = 128,
	/** Hold the active call. */
	eCONTACTFEATUREHOLD = 256,
	/** Send a message. */
	eCONTACTFEATUREMESSAGE = 512,
	/** Redirect a ringing call. */
	eCONTACTFEATUREREDIRECT = 1024,
	/** Set absent state of the contact. */
	eCONTACTFEATURESETABSENT = 2048
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnMetaDirectoryPasswordEncryption {
	/** Passwort verschlüsselt übertragen */
	iEncrypted = 0,
	/** Passwort als Klartext übermitteln */
	iPlain = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumEmailImportance {
	eNORMAL = 0,
	eHIGH = 1,
	eLOW = 2
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumEMailAttachmentEncoding {
	eEncodingUnknown = 0,
	eEncodingTXTUTF8 = 1,
	eEncodingTXTUNICODE = 2,
	eEncodingTXTANSI = 3,
	eEncodingVCF = 4,
	eEncodingWAV = 5,
	eEncodingMP3 = 6,
	eEncodingBinary = 7
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum EnumEMailBodyEncoding {
	eEncodingUnknown = 0,
	eEncodingTXTUTF8 = 1,
	eEncodingTXTUNICODE = 2,
	eEncodingTXTANSI = 3
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnNetDatabaseFindOptionIFlags {
	/** Alt: Wird nicht mehr benötigt! \/\/ Im Feld \"m_strLastName\" kann sich eine Komination aus Vor- und Nachnamen befinden, \"m_strFirstName\" wird ignoriert */
	eRAWNAMEDATA = 1,
	/** Telefonbücher in Suche einbeziehen */
	ePHONEBOOKS = 2,
	/** Restliche, hier bisher nicht genannte Datenbanken in Suche einbeziehen */
	eOTHERDBS = 4,
	/**
	 * Wurde der Benutzer bereits überprüft, ob er die nötigen Berechtigungen hat
	 * Wenn das Flag gesetzt ist, wurde der Benutzer bereits überprüft
	 */
	eRIGHTSCHECKED = 8,
	/** Phonetische Suche anschalten */
	ePHONETICAL = 16,
	/** MetaDirectory mit in die Suche einbeziehen */
	eMETADIRECTORY = 32,
	/** Tel-Buch-Einträge mit mehreren Nummern werden nicht zusammengefasst, sondern einzeln gehalten. Die Telefonnummer steht in \"m_strPhoneBusiness\" */
	eSINGLENUMBER = 64,
	/** Die Suche nach Mobilrufnummern bei TVG zulassen, auch wenn kein Ort angegeben wurde (zeitintensiv!!) */
	eFORCEMOBILE = 128,
	/** nur der LastName enthält einen Suchstring, der in mehreren Feldern gesucht wird (auch in Company...) die anderen Suchfelder sollten leer bleiben */
	eMULTIFIELDNAME = 256,
	/** Nur CtiServer User suchen (betrifft nur NachCache im CtiServer) */
	eONLYCTISERVERUSERS = 512,
	/** Die Suche wurde manuell vom Benutzer gestartet und ist keine Suche, die durch einen Call automatisch angestoßen wurde */
	eMANUALSEARCH = 1024,
	/** Die Suchergebnisse innerhalb des Suchknotens (!) nach dem Anzeigenamen sortiert zurückgeben */
	eSORTBYDISPLAYNAME = 2048,
	/** Immer neu suchen */
	eUSENOCACHE = 4096,
	/** Suche nach Firmenrufnummern (ignoriere Durchwahlen) */
	eCOMPANYSEARCH = 8192,
	/** Telefonbücher NICHT in Suche einbeziehen */
	eNOPHONEBOOKS = 16384,
	/** Suche nach gerufener Nummer (Mandantensuche, Hotline-Funktion) */
	eMANDANTSEARCH = 32768,
	/** Nur im MetaDirectory suchen */
	eONLYMETADIRECTORY = 65536,
	/** Wenn nicht gesetzt, werden die Bild-Binärdaten direkt im optionalParam 'jpegPhoto' übertragen. Andernfalls wird nur der optionalParam 'jpegPhotoHash' übertragen, anhand dem mittels 'asnRequestContactImage' die Binärdaten separat abgeholt werden können. Funktioniert nur bei UCServer Benutzern. */
	eJPEGPHOTOHASH = 131072
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAppsRegisterPushTypeFlags {
	/** %0000 0000 0000 0000 (0x00)  logoff, no events */
	eLOGOFF = 0,
	/** %0000 0000 0000 0001 (0x01)  Audio Video calls */
	eAVCALLS = 1,
	/** %0000 0000 0000 0010 (0x02)  Chat messages */
	eCHATMESSAGES = 2,
	/** %0000 0000 0000 0100 (0x04)  Journal events */
	eJOURNALEVENTS = 4,
	/** %1111 1111 1111 1111 (0xff)  Register to all events */
	eSUBSCRIBETOALL = 255
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAppsPushEncryption {
	none = 0,
	aes128 = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnSMSStatus {
	/** SMS erfolgreich versendet */
	eSMSSTATUSSUCCESS = 0,
	/** SMS konnte nicht übermittelt werden */
	eSMSSTATUSERROR = 1,
	/** SMS wird später verschickt (z.B. retry) */
	eSMSSTATUSDELAYED = 2
}

// [PrintTSTypeDefCode]
// [PrintTSChoiceDefCode]
export class AsnLineForwardProfileChoice {
	public constructor(that?: AsnLineForwardProfileChoice) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLineForwardProfileChoice {
		return new AsnLineForwardProfileChoice();
	}

	public static type = "AsnLineForwardProfileChoice";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Choice {
		return new asn1ts.Choice({
			name: "AsnLineForwardProfileChoice",
			...params,
			value: [
				new asn1ts.Integer({ name: "iAllUserLines", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sSingleLineURI", idBlock: { optionalID: 1 } })
			]
		});
	}

	public iAllUserLines?: number;
	public u8sSingleLineURI?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Regular expression to reformat a phone number. Replaces AsnNumberFormatRegExpression.
 */
export class AsnNumberFormatRegExpression2 {
	public constructor(that: AsnNumberFormatRegExpression2) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNumberFormatRegExpression2 {
		return new AsnNumberFormatRegExpression2({
			u8sSearch: "",
			u8sReplace: "",
			bMatchReplace: false
		});
	}

	public static type = "AsnNumberFormatRegExpression2";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNumberFormatRegExpression2",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sSearch" }),
				new asn1ts.Utf8String({ name: "u8sReplace" }),
				new asn1ts.Boolean({ name: "bMatchReplace" })
			]
		});
	}

	public u8sSearch!: string;
	public u8sReplace!: string;
	public bMatchReplace!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argunment for asnKeepAlive.
 */
export class AsnKeepAliveArgument {
	public constructor(that: AsnKeepAliveArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnKeepAliveArgument {
		return new AsnKeepAliveArgument({
			dummy: null
		});
	}

	public static type = "AsnKeepAliveArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnKeepAliveArgument",
			...params,
			value: [
				new asn1ts.Null({ name: "dummy" })
			]
		});
	}

	/** no parameter */
	public dummy!: null;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnGetLocationInformation and asnGetLocationInformation2.
 */
export class AsnGetLocationInformationArgument {
	public constructor(that: AsnGetLocationInformationArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLocationInformationArgument {
		return new AsnGetLocationInformationArgument({
			u8sLocationID: ""
		});
	}

	public static type = "AsnGetLocationInformationArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetLocationInformationArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sLocationID" })
			]
		});
	}

	/** The identifier of a location information. See asnGetLocationIDs as an example how to get a location id. */
	public u8sLocationID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnSetLocationInformation2Result {
	public constructor(that?: AsnSetLocationInformation2Result) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetLocationInformation2Result {
		return new AsnSetLocationInformation2Result();
	}

	public static type = "AsnSetLocationInformation2Result";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetLocationInformation2Result",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Liefert die LocationIDs mit passender MD5 Prüfsumme.
 * Wird vom Client verwendet um zu Prüfen ob seine Location Settings noch aktuell sind
 */
export class AsnGetLocationIDsArgument {
	public constructor(that?: AsnGetLocationIDsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLocationIDsArgument {
		return new AsnGetLocationIDsArgument();
	}

	public static type = "AsnGetLocationIDsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetLocationIDsArgument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "locationIDs", idBlock: { optionalID: 0 } })
			]
		});
	}

	public locationIDs?: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnGetLocationIDsResult {
	public constructor(that: AsnGetLocationIDsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLocationIDsResult {
		return new AsnGetLocationIDsResult({
			locationIDMD5ChecksumList: new ENetUC_Common.AsnStringPairList()
		});
	}

	public static type = "AsnGetLocationIDsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetLocationIDsResult",
			...params,
			value: [
				ENetUC_Common.AsnStringPairList.getASN1Schema({ name: "locationIDMD5ChecksumList" })
			]
		});
	}

	/** first LocationID, last MD5 Checksum */
	public locationIDMD5ChecksumList!: ENetUC_Common.AsnStringPairList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnLocationInformationChangedArgument {
	public constructor(that: AsnLocationInformationChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLocationInformationChangedArgument {
		return new AsnLocationInformationChangedArgument({
			u8sLocationID: ""
		});
	}

	public static type = "AsnLocationInformationChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnLocationInformationChangedArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sLocationID" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	public u8sLocationID!: string;
	/** optionalParams Name \"MD5CheckSum\" Value UTF8String MD5 Prüfsumme über den Standort */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetLicensedFeatureCountArgument {
	public constructor(that?: AsnGetLicensedFeatureCountArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLicensedFeatureCountArgument {
		return new AsnGetLicensedFeatureCountArgument();
	}

	public static type = "AsnGetLicensedFeatureCountArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetLicensedFeatureCountArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetLicensedFeatureCountResult {
	public constructor(that: AsnGetLicensedFeatureCountResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLicensedFeatureCountResult {
		return new AsnGetLicensedFeatureCountResult({
			licensedFeatures: new ENetUC_Common.AsnIntegerPairList()
		});
	}

	public static type = "AsnGetLicensedFeatureCountResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetLicensedFeatureCountResult",
			...params,
			value: [
				ENetUC_Common.AsnIntegerPairList.getASN1Schema({ name: "licensedFeatures" })
			]
		});
	}

	public licensedFeatures!: ENetUC_Common.AsnIntegerPairList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetAdministrativeMonitorContentArgument {
	public constructor(that?: AsnGetAdministrativeMonitorContentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetAdministrativeMonitorContentArgument {
		return new AsnGetAdministrativeMonitorContentArgument();
	}

	public static type = "AsnGetAdministrativeMonitorContentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetAdministrativeMonitorContentArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetAdministrativeMonitorContentResult {
	public constructor(that: AsnGetAdministrativeMonitorContentResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetAdministrativeMonitorContentResult {
		return new AsnGetAdministrativeMonitorContentResult({
			iDisableAllMonitorConfiguration: 0,
			listContactIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	public static type = "AsnGetAdministrativeMonitorContentResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetAdministrativeMonitorContentResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iDisableAllMonitorConfiguration" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "listContactIDs" })
			]
		});
	}

	public iDisableAllMonitorConfiguration!: number;
	public listContactIDs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnReloadAdministrativeMonitorContentArgument {
	public constructor(that?: AsnReloadAdministrativeMonitorContentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnReloadAdministrativeMonitorContentArgument {
		return new AsnReloadAdministrativeMonitorContentArgument();
	}

	public static type = "AsnReloadAdministrativeMonitorContentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnReloadAdministrativeMonitorContentArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRetriggerFederationConnectionArgument {
	public constructor(that: AsnRetriggerFederationConnectionArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRetriggerFederationConnectionArgument {
		return new AsnRetriggerFederationConnectionArgument({
			u8sContactID: ""
		});
	}

	public static type = "AsnRetriggerFederationConnectionArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRetriggerFederationConnectionArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" })
			]
		});
	}

	public u8sContactID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnActionFilterEntry {
	public constructor(that: AsnActionFilterEntry) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnActionFilterEntry {
		return new AsnActionFilterEntry({
			u8sFilterFieldName: "",
			u8sFilterFieldExpression: ""
		});
	}

	public static type = "AsnActionFilterEntry";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnActionFilterEntry",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sFilterFieldName" }),
				new asn1ts.Utf8String({ name: "u8sFilterFieldExpression" })
			]
		});
	}

	public u8sFilterFieldName!: string;
	public u8sFilterFieldExpression!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetAppointmentForwardsResult {
	public constructor(that?: AsnSetAppointmentForwardsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetAppointmentForwardsResult {
		return new AsnSetAppointmentForwardsResult();
	}

	public static type = "AsnSetAppointmentForwardsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetAppointmentForwardsResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetAppointmentForwardsArgument {
	public constructor(that?: AsnGetAppointmentForwardsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetAppointmentForwardsArgument {
		return new AsnGetAppointmentForwardsArgument();
	}

	public static type = "AsnGetAppointmentForwardsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetAppointmentForwardsArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnOperationCompleteArgument {
	public constructor(that: AsnOperationCompleteArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOperationCompleteArgument {
		return new AsnOperationCompleteArgument({
			u8sCompletionID: ""
		});
	}

	public static type = "AsnOperationCompleteArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnOperationCompleteArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sCompletionID" })
			]
		});
	}

	/** CompletionID, die der Client als optiona Param im openContacts angegeben hatte */
	public u8sCompletionID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnOpenContactsResult {
	public constructor(that: AsnOpenContactsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOpenContactsResult {
		return new AsnOpenContactsResult({
			contacts: new ENetUC_Common_AsnContact.AsnContacts()
		});
	}

	public static type = "AsnOpenContactsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnOpenContactsResult",
			...params,
			value: [
				ENetUC_Common_AsnContact.AsnContacts.getASN1Schema({ name: "contacts" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	public contacts!: ENetUC_Common_AsnContact.AsnContacts;
	/** optionalParams Name \"CompletedEventID\" Value string - requests an asnOperationComplete as soon as all status events were dispatched */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnCloseContactsResult {
	public constructor(that: AsnCloseContactsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCloseContactsResult {
		return new AsnCloseContactsResult({
			iResult: 0,
			u8sComment: ""
		});
	}

	public static type = "AsnCloseContactsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCloseContactsResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				new asn1ts.Utf8String({ name: "u8sComment" })
			]
		});
	}

	/** Rückgabewert ob das geklappt hat, wenn nicht != 0 */
	public iResult!: number;
	/** Kommentar, wenn es nicht geklappt hat */
	public u8sComment!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetSettingsDynamicRightsArgument {
	public constructor(that: AsnSetSettingsDynamicRightsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetSettingsDynamicRightsArgument {
		return new AsnSetSettingsDynamicRightsArgument({
			u8sContactID: "",
			seqDynamicRightsList: new ENetUC_Common_AsnContact.AsnUserDynamicRightsList()
		});
	}

	public static type = "AsnSetSettingsDynamicRightsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetSettingsDynamicRightsArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" }),
				ENetUC_Common_AsnContact.AsnUserDynamicRightsList.getASN1Schema({ name: "seqDynamicRightsList" })
			]
		});
	}

	/** ContactID des Kontaktes, dessen Rechteliste gestzt werden soll (im Moment darf man nur bei sich selbst ändern). */
	public u8sContactID!: string;
	/**
	 * Berechtigungsliste der ausschließlich vom Benutzer selbst vergebenen Berechtigungen. Achtung: hier immer die vollständige Liste setzen; fehlende Elemente werden am Server als \"entfernt\" interpretiert!
	 * Um über diese Funktion einzelne Rechte zu ergänzen\/ändern, muss vorher mit asnGetSettingsDynamicRights die aktuelle Liste abgeholt werden.
	 * Für das einfache Hinzufügen\/ändern\/Löschen von einzelnen Einträgen asnSetDynamicRights\/asnRemoveDynamicRights im Modul PresenceV2 verwenden.
	 */
	public seqDynamicRightsList!: ENetUC_Common_AsnContact.AsnUserDynamicRightsList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetSettingsDynamicRightsResult {
	public constructor(that: AsnSetSettingsDynamicRightsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetSettingsDynamicRightsResult {
		return new AsnSetSettingsDynamicRightsResult({
			iResult: 0
		});
	}

	public static type = "AsnSetSettingsDynamicRightsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetSettingsDynamicRightsResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" })
			]
		});
	}

	/** Rückgabewert ob das geklappt hat, wenn nicht != 0 */
	public iResult!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetSettingsDynamicRightsArgument {
	public constructor(that: AsnGetSettingsDynamicRightsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetSettingsDynamicRightsArgument {
		return new AsnGetSettingsDynamicRightsArgument({
			u8sContactID: ""
		});
	}

	public static type = "AsnGetSettingsDynamicRightsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetSettingsDynamicRightsArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" })
			]
		});
	}

	/** ContactID des Kontaktes dessen Rechteliste gelesen werden soll (im Moment kann man nur die eigene lesen) */
	public u8sContactID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetSettingsDynamicRightsResult {
	public constructor(that: AsnGetSettingsDynamicRightsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetSettingsDynamicRightsResult {
		return new AsnGetSettingsDynamicRightsResult({
			iResult: 0,
			seqDynamicRightsList: new ENetUC_Common_AsnContact.AsnUserDynamicRightsList()
		});
	}

	public static type = "AsnGetSettingsDynamicRightsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetSettingsDynamicRightsResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				ENetUC_Common_AsnContact.AsnUserDynamicRightsList.getASN1Schema({ name: "seqDynamicRightsList" })
			]
		});
	}

	/** Rückgabewert ob das geklappt hat, wenn nicht != 0 */
	public iResult!: number;
	/** Rechteliste die gelesen wurde */
	public seqDynamicRightsList!: ENetUC_Common_AsnContact.AsnUserDynamicRightsList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSettingsGlobalDynamicRightsChangedArgument {
	public constructor(that: AsnSettingsGlobalDynamicRightsChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSettingsGlobalDynamicRightsChangedArgument {
		return new AsnSettingsGlobalDynamicRightsChangedArgument({
			globalDynamicRights: ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty()
		});
	}

	public static type = "AsnSettingsGlobalDynamicRightsChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSettingsGlobalDynamicRightsChangedArgument",
			...params,
			value: [
				ENetUC_Common_AsnContact.AsnUserDynamicRights.getASN1Schema({ name: "globalDynamicRights" })
			]
		});
	}

	/** Globale Berechtigungsstufe, die für alle Benutzer gesetzt wurde. */
	public globalDynamicRights!: ENetUC_Common_AsnContact.AsnUserDynamicRights;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetSettingsGlobalDynamicRightsResult {
	public constructor(that: AsnGetSettingsGlobalDynamicRightsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetSettingsGlobalDynamicRightsResult {
		return new AsnGetSettingsGlobalDynamicRightsResult({
			iResult: 0,
			globalDynamicRights: ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty()
		});
	}

	public static type = "AsnGetSettingsGlobalDynamicRightsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetSettingsGlobalDynamicRightsResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				ENetUC_Common_AsnContact.AsnUserDynamicRights.getASN1Schema({ name: "globalDynamicRights" })
			]
		});
	}

	/** Rückgabewert ob das geklappt hat, wenn nicht != 0 */
	public iResult!: number;
	/** Global gesetzte Berechtigungen */
	public globalDynamicRights!: ENetUC_Common_AsnContact.AsnUserDynamicRights;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUpdateMyAppointmentArgument {
	public constructor(that: AsnUpdateMyAppointmentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateMyAppointmentArgument {
		return new AsnUpdateMyAppointmentArgument({
			u8sContactID: "",
			seqActualAppointments: new ENetUC_Common_Appointments.SEQAppointmentList(),
			asnNextAppointment: ENetUC_Common_Appointments.AsnAppointmentEntry.initEmpty(),
			iPresenceState: 0
		});
	}

	public static type = "AsnUpdateMyAppointmentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateMyAppointmentArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" }),
				ENetUC_Common_Appointments.SEQAppointmentList.getASN1Schema({ name: "seqActualAppointments" }),
				ENetUC_Common_Appointments.AsnAppointmentEntry.getASN1Schema({ name: "asnNextAppointment" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** Der Kontakt, für den die Änderung gilt */
	public u8sContactID!: string;
	/** Liste der gerade aktiven Termine */
	public seqActualAppointments!: ENetUC_Common_Appointments.SEQAppointmentList;
	/** Der nächste Termin der aktiv wird */
	public asnNextAppointment!: ENetUC_Common_Appointments.AsnAppointmentEntry;
	public iPresenceState!: number;
	/** optionalParams Name \"NextAppointments\" Value BER or JSON encoded SEQAppointmentList */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
export class AsnUpdateMyCallInformationArgument extends ENetUC_Common_AsnContact.AsnContactCallInformation {
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * update für ME-Contact
 *
 * @private
 */
export class AsnNotifyLineForwardStateArgument {
	public constructor(that: AsnNotifyLineForwardStateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNotifyLineForwardStateArgument {
		return new AsnNotifyLineForwardStateArgument({
			u8sContactID: "",
			iContactFeatureSet: 0,
			iPresenceState: 0,
			u8sLinenumber: "",
			seqLineForwards: new ENetUC_Common_SIPCTI.AsnLineForwards()
		});
	}

	public static type = "AsnNotifyLineForwardStateArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNotifyLineForwardStateArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" }),
				new asn1ts.Integer({ name: "iContactFeatureSet" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				new asn1ts.Utf8String({ name: "u8sLinenumber" }),
				ENetUC_Common_SIPCTI.AsnLineForwards.getASN1Schema({ name: "seqLineForwards" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** ContactID des Kontaktes, dessen Leitung umgeleitet werden soll (Owner) */
	public u8sContactID!: string;
	/** was mit dem Kontakt getan werden darf (wird nur für die Notifies benutzt */
	public iContactFeatureSet!: number;
	/** aktueller Präsenzzustand beim Versenden der Nachricht */
	public iPresenceState!: number;
	/** Nummer der Leitung */
	public u8sLinenumber!: string;
	/** Forwardzustand der Leitung */
	public seqLineForwards!: ENetUC_Common_SIPCTI.AsnLineForwards;
	/** optionalParams Name \"LineURI\" Value String */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUpdateMyContactConfigurationArgument {
	public constructor(that: AsnUpdateMyContactConfigurationArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateMyContactConfigurationArgument {
		return new AsnUpdateMyContactConfigurationArgument({
			contactChanged: ENetUC_Common_AsnContact.AsnContact.initEmpty()
		});
	}

	public static type = "AsnUpdateMyContactConfigurationArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateMyContactConfigurationArgument",
			...params,
			value: [
				ENetUC_Common_AsnContact.AsnContact.getASN1Schema({ name: "contactChanged" })
			]
		});
	}

	public contactChanged!: ENetUC_Common_AsnContact.AsnContact;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUpdateServerBasedClientSettingsArgument {
	public constructor(that?: AsnUpdateServerBasedClientSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateServerBasedClientSettingsArgument {
		return new AsnUpdateServerBasedClientSettingsArgument();
	}

	public static type = "AsnUpdateServerBasedClientSettingsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateServerBasedClientSettingsArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnMyPresenceRightsChallengeArgument {
	public constructor(that: AsnMyPresenceRightsChallengeArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMyPresenceRightsChallengeArgument {
		return new AsnMyPresenceRightsChallengeArgument({
			contactsChallenging: new ENetUC_Common.AsnNetDatabaseContactList()
		});
	}

	public static type = "AsnMyPresenceRightsChallengeArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnMyPresenceRightsChallengeArgument",
			...params,
			value: [
				ENetUC_Common.AsnNetDatabaseContactList.getASN1Schema({ name: "contactsChallenging" })
			]
		});
	}

	public contactsChallenging!: ENetUC_Common.AsnNetDatabaseContactList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRequestMyPresenceRightsChallengeArgument {
	public constructor(that?: AsnRequestMyPresenceRightsChallengeArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestMyPresenceRightsChallengeArgument {
		return new AsnRequestMyPresenceRightsChallengeArgument();
	}

	public static type = "AsnRequestMyPresenceRightsChallengeArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestMyPresenceRightsChallengeArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnResetMyPresenceRightsChallengesArgument {
	public constructor(that?: AsnResetMyPresenceRightsChallengesArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnResetMyPresenceRightsChallengesArgument {
		return new AsnResetMyPresenceRightsChallengesArgument();
	}

	public static type = "AsnResetMyPresenceRightsChallengesArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnResetMyPresenceRightsChallengesArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnClientInfo {
	public constructor(that: AsnClientInfo) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientInfo {
		return new AsnClientInfo({
			iConnectionType: 0,
			bMyConnectionInfo: false,
			stUTCLoginTime: new Date(),
			u8sComputername: "",
			u8sAdditionalInfo: ""
		});
	}

	public static type = "AsnClientInfo";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientInfo",
			...params,
			value: [
				new asn1ts.Integer({ name: "iConnectionType" }),
				new asn1ts.Boolean({ name: "bMyConnectionInfo" }),
				new asn1ts.Real({ name: "stUTCLoginTime" }),
				new asn1ts.Utf8String({ name: "u8sComputername" }),
				new asn1ts.Utf8String({ name: "u8sAdditionalInfo" })
			]
		});
	}

	/** Typ der Verbindung; Mögliche Konstanten in ApplicationTypes.h. */
	public iConnectionType!: number;
	/** Das ist meine Connection-Information; für TS-Szenarien wichtig */
	public bMyConnectionInfo!: boolean;
	/** Logontime */
	public stUTCLoginTime!: Date;
	/** Computer auf dem der Client läuft bzw. auf dem die Terminals-Session läuft. */
	public u8sComputername!: string;
	/** Mögliche Zusatzinformationen (Text) */
	public u8sAdditionalInfo!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnMyUpdateClientContentArgument {
	public constructor(that: AsnMyUpdateClientContentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMyUpdateClientContentArgument {
		return new AsnMyUpdateClientContentArgument({
			u8sNewRevision: ""
		});
	}

	public static type = "AsnMyUpdateClientContentArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnMyUpdateClientContentArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sNewRevision" })
			]
		});
	}

	public u8sNewRevision!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactRemoveContactArgument {
	public constructor(that: AsnContactRemoveContactArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactRemoveContactArgument {
		return new AsnContactRemoveContactArgument({
			u8sContactID: ""
		});
	}

	public static type = "AsnContactRemoveContactArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactRemoveContactArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" })
			]
		});
	}

	public u8sContactID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnAbsentStateSetUser
 * Set change the manual presence of a user, a AsnAbsentState struct has to be sent to the ucserver.
 */
export class AsnAbsentStateSetUserArgument {
	public constructor(that: AsnAbsentStateSetUserArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateSetUserArgument {
		return new AsnAbsentStateSetUserArgument({
			absentstate: ENetUC_Common_AsnContact.AsnAbsentState.initEmpty()
		});
	}

	public static type = "AsnAbsentStateSetUserArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAbsentStateSetUserArgument",
			...params,
			value: [
				ENetUC_Common_AsnContact.AsnAbsentState.getASN1Schema({ name: "absentstate" })
			]
		});
	}

	/** The AsnAbsentState data to change the manual presence of the user. */
	public absentstate!: ENetUC_Common_AsnContact.AsnAbsentState;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnAbsentStateSetUser if the operation executes successfully.
 * Result of asnAbsentStateSetUser if the operation executes successfully.
 */
export class AsnAbsentStateSetUserResult {
	public constructor(that: AsnAbsentStateSetUserResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateSetUserResult {
		return new AsnAbsentStateSetUserResult({
			bSuccess: false
		});
	}

	public static type = "AsnAbsentStateSetUserResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAbsentStateSetUserResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** Should always be true. */
	public bSuccess!: boolean;
	/** optionalParams Name \"RemoteInvokeID\" Value String - Some dirty internal UCServer logic Simon added and should get reworked, the interface does NOT need this property here */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for asnAbsentStateReset
 * The argument for asnAbsentStateReset conatins the ContactID for the contact to reset its absent state to 'none'.
 */
export class AsnAbsentStateResetArgument {
	public constructor(that: AsnAbsentStateResetArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateResetArgument {
		return new AsnAbsentStateResetArgument({
			u8sContactId: ""
		});
	}

	public static type = "AsnAbsentStateResetArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAbsentStateResetArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactId" })
			]
		});
	}

	public u8sContactId!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnAbsentStateReset that indicates, that the oepration was successful.
 */
export class AsnAbsentStateResetResult {
	public constructor(that?: AsnAbsentStateResetResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateResetResult {
		return new AsnAbsentStateResetResult();
	}

	public static type = "AsnAbsentStateResetResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAbsentStateResetResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAbsentStateGetUserResult {
	public constructor(that: AsnAbsentStateGetUserResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateGetUserResult {
		return new AsnAbsentStateGetUserResult({
			absentstate: ENetUC_Common_AsnContact.AsnAbsentState.initEmpty()
		});
	}

	public static type = "AsnAbsentStateGetUserResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAbsentStateGetUserResult",
			...params,
			value: [
				ENetUC_Common_AsnContact.AsnAbsentState.getASN1Schema({ name: "absentstate" })
			]
		});
	}

	public absentstate!: ENetUC_Common_AsnContact.AsnAbsentState;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetClientIdleStateArgument {
	public constructor(that: AsnSetClientIdleStateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientIdleStateArgument {
		return new AsnSetClientIdleStateArgument({
			u8sContactId: "",
			iIdleState: 0
		});
	}

	public static type = "AsnSetClientIdleStateArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetClientIdleStateArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactId" }),
				new asn1ts.Integer({ name: "iIdleState" })
			]
		});
	}

	public u8sContactId!: string;
	/** AsnPresenceStateEnum ohne AsnPresenceStateFlags! */
	public iIdleState!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetClientIdleStateResult {
	public constructor(that: AsnSetClientIdleStateResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientIdleStateResult {
		return new AsnSetClientIdleStateResult({
			dummy: null
		});
	}

	public static type = "AsnSetClientIdleStateResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetClientIdleStateResult",
			...params,
			value: [
				new asn1ts.Null({ name: "dummy" })
			]
		});
	}

	public dummy!: null;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUpdateMyAbsentStateArgument {
	public constructor(that: AsnUpdateMyAbsentStateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateMyAbsentStateArgument {
		return new AsnUpdateMyAbsentStateArgument({
			absentstate: ENetUC_Common_AsnContact.AsnAbsentState.initEmpty()
		});
	}

	public static type = "AsnUpdateMyAbsentStateArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateMyAbsentStateArgument",
			...params,
			value: [
				ENetUC_Common_AsnContact.AsnAbsentState.getASN1Schema({ name: "absentstate" })
			]
		});
	}

	public absentstate!: ENetUC_Common_AsnContact.AsnAbsentState;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnCustomNoteSetArgument {
	public constructor(that: AsnCustomNoteSetArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCustomNoteSetArgument {
		return new AsnCustomNoteSetArgument({
			customnote: ENetUC_Common_AsnContact.AsnCustomNote.initEmpty()
		});
	}

	public static type = "AsnCustomNoteSetArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCustomNoteSetArgument",
			...params,
			value: [
				ENetUC_Common_AsnContact.AsnCustomNote.getASN1Schema({ name: "customnote" })
			]
		});
	}

	public customnote!: ENetUC_Common_AsnContact.AsnCustomNote;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnCustomNoteSetResult {
	public constructor(that?: AsnCustomNoteSetResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCustomNoteSetResult {
		return new AsnCustomNoteSetResult();
	}

	public static type = "AsnCustomNoteSetResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCustomNoteSetResult",
			...params,
			value: [
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** optionalParams Name \"RemoteInvokeID\" Value String - Some dirty internal UCServer logic Simon added and should get reworked, the interface does NOT need this property here */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUpdateMyCustomNoteArgument {
	public constructor(that: AsnUpdateMyCustomNoteArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateMyCustomNoteArgument {
		return new AsnUpdateMyCustomNoteArgument({
			customnote: ENetUC_Common_AsnContact.AsnCustomNote.initEmpty()
		});
	}

	public static type = "AsnUpdateMyCustomNoteArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUpdateMyCustomNoteArgument",
			...params,
			value: [
				ENetUC_Common_AsnContact.AsnCustomNote.getASN1Schema({ name: "customnote" })
			]
		});
	}

	public customnote!: ENetUC_Common_AsnContact.AsnCustomNote;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetMetaSettingsResult {
	public constructor(that?: AsnSetMetaSettingsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetMetaSettingsResult {
		return new AsnSetMetaSettingsResult();
	}

	public static type = "AsnSetMetaSettingsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetMetaSettingsResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRequestFeatureArgument {
	public constructor(that: AsnRequestFeatureArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestFeatureArgument {
		return new AsnRequestFeatureArgument({
			iFeature: 0
		});
	}

	public static type = "AsnRequestFeatureArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestFeatureArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iFeature" })
			]
		});
	}

	/** requested feature */
	public iFeature!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRequestFeatureResult {
	public constructor(that: AsnRequestFeatureResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestFeatureResult {
		return new AsnRequestFeatureResult({
			bNotForResale: false,
			iFeatureSet: 0
		});
	}

	public static type = "AsnRequestFeatureResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRequestFeatureResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bNotForResale" }),
				new asn1ts.Integer({ name: "iFeatureSet" })
			]
		});
	}

	public bNotForResale!: boolean;
	public iFeatureSet!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnEMailAttachment {
	public constructor(that: AsnEMailAttachment) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnEMailAttachment {
		return new AsnEMailAttachment({
			binAttachmentData: new Uint8Array(0),
			u8sAttachmentFileName: "",
			u8sContentID: "",
			iAttachmentEncoding: 0
		});
	}

	public static type = "AsnEMailAttachment";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnEMailAttachment",
			...params,
			value: [
				new asn1ts.OctetString({ name: "binAttachmentData" }),
				new asn1ts.Utf8String({ name: "u8sAttachmentFileName" }),
				new asn1ts.Utf8String({ name: "u8sContentID" }),
				new asn1ts.Integer({ name: "iAttachmentEncoding" })
			]
		});
	}

	public binAttachmentData!: Uint8Array;
	public u8sAttachmentFileName!: string;
	public u8sContentID!: string;
	public iAttachmentEncoding!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnEMailParty {
	public constructor(that: AsnEMailParty) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnEMailParty {
		return new AsnEMailParty({
			u8sAddress: "",
			u8sName: ""
		});
	}

	public static type = "AsnEMailParty";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnEMailParty",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sAddress" }),
				new asn1ts.Utf8String({ name: "u8sName" })
			]
		});
	}

	public u8sAddress!: string;
	public u8sName!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnEMailBody {
	public constructor(that: AsnEMailBody) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnEMailBody {
		return new AsnEMailBody({
			binBodyData: new Uint8Array(0),
			iBodyEncoding: 0
		});
	}

	public static type = "AsnEMailBody";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnEMailBody",
			...params,
			value: [
				new asn1ts.OctetString({ name: "binBodyData" }),
				new asn1ts.Integer({ name: "iBodyEncoding" })
			]
		});
	}

	public binBodyData!: Uint8Array;
	public iBodyEncoding!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * ID der Datenbanken (EntryIDDB) Stand 23.09.2015
 * Aktuell immer in \\global\\include\\EDBEntryIDs.h, da Produktübergreifend (z.B. Meta) verwendet
 * DatabaseID der in ProCall Express 2.2 integrierten Datenbank.
 * Wird im Moment in ProCall 3.0 nicht verwendet
 * EntryIDDB: DB01
 * EntryIDStore:
 * EntryID:
 *
 * DatabaseID für Windows Addressbook.
 * Das ist das Addressbuch von Outlook Express.
 * EntryIDDB: DB02
 * EntryIDStore: WAB Store EntryID (wie Exchange)
 * EntryID: EntryID
 *
 * DatabaseID für Exchange (Mapi)
 * EntryIDDB: DB03
 * EntryIDStore: Message Store EntryID
 * EntryID: EntryID
 *
 * DatabaseID für Mapi Addressbook (Personal Adressbook)
 * EntryIDDB: DB04
 * EntryIDStore: Addressbook Store EntryID
 * EntryID: EntryID
 *
 * DatabaseID für Lotus Notes
 * EntryIDDB: DB05
 * EntryIDStore: selbstgebauter String aus \"Server DatabaseName\" z.B. \"avalon\"
 * EntryID: Notes UniversalID des Eintrags
 *
 * DatabaseID für ODBC Datenquellen
 * EntryIDDB: DB06
 * EntryIDStore: selbstgebauter String
 * EntryID: Inhalt des konfigurierten IndexFelds (UUID oder Zahl)
 *
 * DatabaseID für LDAP Datenquellen
 * EntryIDDB: DB07
 * EntryIDStore: selbstgebauter string \"ldap:\/\/server:port\/baseDN\" z.b. \"ldap:\/\/avalon:712\/dc=meta\"
 * EntryID: dn des Eintrags z.B. \"cn=Stefan Eckbauer,dc=estos,dc=de\"
 *
 * DatabaseID für ActiveDirectory (LDAP)
 * EntryIDDB: DB08
 * EntryIDStore: selbstgebauter string \"ldap:\/\/server:port\/baseDN\" z.b. \"ldap:\/\/avalon:712\/dc=meta\"
 * EntryID: dn des Eintrags z.B. \"cn=Stefan Eckbauer,dc=estos,dc=de\"
 *
 * DatabaseID für Tobit David
 * EntryIDDB: DB09
 * EntryIDStore: tobit \/ undefined
 * EntryID: Voller Pfad zum Eintrag \"ArchiveID\\ID\" z.B. \"\\\\WHOOP\\DAVID\\ARCHIVE\\ADDRESS\\1\\3E\\3\"
 *
 * DatabaseID für ADO Datenquellen
 * Momentan nicht verwendet
 * EntryIDDB: DB10
 * EntryIDStore:
 * EntryID:
 *
 * DatabaseID für CtiServer3 interne Daten
 * Zeigt an, dass der Kontakt aus der CtiServer-Benutzerverwaltung kommt.
 * Dabei ist zu beachten, dass die Felder EntryID und EntryIDStore aber
 * unterschiedliche Bedeutung haben wenn unterschiedliche Backends verwendet werden!
 * Leitungen:
 * EntryIDDB: DB11
 * EntryIDStore: \"lines\"
 * EntryID: l:leitungsrufnummer z.b. \"l:+49815136856110\"
 * User:
 * EntryIDDB: DB11
 * EntryIDStore: \"users\"
 * EntryID: u:username z.b. \"u:ste\@estos.de\"
 *
 *
 *
 * DatabaseID für Abacus Addressen
 * EntryIDDB: DB12
 * EntryIDStore: mandant (nr des mandanten) z.B. \"5\"
 * EntryID: Datensatz ID z.b. \"588\"
 *
 * DatabaseID für Abacus Kontakte
 * EntryIDDB: DB13
 * EntryIDStore: mandant (nr des mandanten) z.B. \"5\"
 * EntryID: Datensatz ID z.b. \"588\"
 *
 * DatabaseID für Microsoft CRM 1 (SOAP)
 * EntryIDDB: DB14
 * EntryIDStore: \"host:port typ\" z.B. \"avalon:80 ACCOUNT\" ACCOUNT und CONTACT
 * EntryID: Datensatz ID z.b. \"588\"
 *
 * DatabaseID für gefakte Kontakte
 * Wird für URL links verwendet, damit die nicht zu lang werden
 * z.B. phone:
 * Diese dient dazu, in Links den Kontakt dann einfach nochmal zu suchen.
 * EntryIDDB: DB15
 * EntryIDStore: Rufnummer des Kontaktes (kodiert)
 * EntryID: Name des Kontaktes (kodiert)
 *
 * DatabaseID für Telefonbücher
 * EntryIDDB: DB16
 * EntryIDStore: ID des Telefonbuchs
 * IID_PCSTOREIDKLICKTEL				_T(\"00000001\")
 * IID_PCSTOREIDDETEMEDIEN				_T(\"00000002\")
 * IID_PCSTOREIDTWIXTEL				_T(\"00000003\")
 * IID_PCSTOREIDHEROLDWHITEPAGES		_T(\"00000004\")
 * IID_PCSTOREIDHEROLDYELLOWPAGES		_T(\"00000005\")
 * IID_PCSTOREIDDETEMEDIENWEBSERVICE	_T(\"00000006\")
 * IID_PCSTOREIDSWISSCOM				_T(\"00000007\")
 * EntryID: Name des Kontaktes (kodiert)
 *
 * DatabaseID für StepAhead
 * EntryIDDB: DB17
 * EntryIDStore: undefined
 * EntryID: Index des Kontaktes
 *
 * DatabaseID für Special DB: Funktionen in einem Client
 * EntryIDDB: DB18
 * EntryIDStore: undefined
 * EntryID: undefined
 *
 * DatabaseID für Datev
 * EntryIDDB: DB19
 * EntryIDStore: undefined
 * EntryID: primärschlüssel des Eintrags
 *
 * DatabaseID für Datev Eigenorganisation
 * EntryIDDB: DB20
 * EntryIDStore: undefined
 * EntryID: primärschlüssel des Eintrags
 *
 * DatabaseID für Datev Eigenorganisation Komfort
 * EntryIDDB: DB21
 * EntryIDStore: undefined
 * EntryID: primärschlüssel des Eintrags
 *
 * DatabaseID für Kontakte die aus der TAPI Information der Telefonanlage generiert wurden
 * EntryIDDB: DB22
 * EntryIDStore: undefined
 * EntryID: Primärschlüssel, vollkanonische Nummer des Kontaktes
 *
 * DatabaseID für Kontakte die von einem anderen Server verwaltet werden, der eine Federation-Verbindung hat.
 * EntryIDDB: DB23
 * EntryIDStore: Kann (wenn bekannt) die Verwaltungsdomain sein
 * EntryID: s:&lt;URI&gt;
 *
 * DatabaseID für das SDK. das SDK selbst hat natuerlich KEINE contacte ABER jedes AddIn wird als Store abgebildet
 * EntryIDDB: DB24
 * EntryIDStore: AddInName als DisplayName eines Monikers
 * EntryID: s: bleibt dem implementierer des AddIn ueberlassen
 *
 * DatabaseID für Datev SDD
 * EntryIDDB: DB25
 * EntryIDStore: undefined
 * EntryID: primärschlüssel des Eintrags
 *
 * DatabaseID für die Favoriten-Kontakte. Dies sind alle Kontakte, die im DataModel vorhanden sind.
 * Diese ID wird nur gebraucht, um den Favoriten-Suchknoten \"sauber\" in die RegisteredSearchNodes
 * einfügen zu können. Die Kontakte im DataModel haben niemals diese DatenbankID, sondern tragen
 * ausschließlich die EntryIDs der Quelldatenbank (z.B. IID_PCDBEXCHANGE; IID_PCDBTAPISERVER; IID_PCDBREMOTESIP etc).
 * Das Verhalten ist damit vergleichbar zu einem MetaDirectory, das auch immer die Original-EntryIDs zurückgibt,
 * also die EntryIDs der Datenquelle, die vom MetaDirectory repliziert wurde.
 * EntryIDDB: DB26
 * EntryIDStore: undefined
 * EntryID: undefined.
 *
 * DatabaseID für Private\/Lokale SQLite Datenbanken
 * EntryIDDB: DB27
 * EntryIDStore: File Name des Source files.
 * EntryID: id
 *
 *
 * DatabaseID für integrierte Meta4 Datenbank
 * EntryIDDB: DB28
 * EntryIDStore: selbstgebauter string \"ldap:\/\/server:port\/dc=users\"
 * EntryID: dn des Eintrags z.B. \"cn=al,dc=users,dc=de\"
 *
 * DatabaseID für lokales Adressbuch auf MobilePhone-Device
 * EntryIDDB: DB29
 * EntryIDStore: selbstgebauter string z.B. \"mobile phone device address book\"
 * EntryID: id des Eintrags
 *
 * DatabaseID für Kontakte, die aus VCFs generiert wurden
 * EntryIDDB: DB30
 * EntryIDStore: undefined (empty)
 * EntryID: Dateipfad der VCF
 *
 * DatabaseID für Kontakte, die von exact online
 * EntryIDDB: DB31
 * EntryIDStore: host-url
 * EntryID: GUID des Eintrags in der Quelle
 *
 * DatabaseID für alle EComContact-basierten Replikatoren
 * EntryIDDB: DB32
 * EntryIDStore: CLASSID d. IECOMContact-Implementierung
 * EntryID: undefined
 *
 * DatabaseID für Ferrari-WebService.
 * EntryIDDB: DB33
 * EntryIDStore: Postfach
 * EntryID: JobID
 *
 * DatabaseID für Merge-Contacts.
 * EntryIDDB: DB34
 * EntryIDStore: DB34
 * EntryID: rowID
 *
 * DatabaseID für anonyme Kontakte (temporär erzeugt).
 * Bildet die anonyme Anrufer aus dem Web ab.
 * Typischerweise: go.estos.de
 * EntryIDDB: DB35
 * EntryIDStore: Domäne (bsp. \"go.estos.de\")
 * EntryID: GUID\@domain.de
 *
 * DatabaseID für Mobile Devices (Bluetooth)
 * EntryIDDB: DB36
 * EntryIDStore: Bluetooth Address des Devices
 * EntryID: Name des Kontaktes (kodiert)
 *
 * DatabaseID für das EWS AddIn im UCClient
 * EntryIDDB: DB37
 * EntryIDStore: E-Mail
 * EntryID: EWS-ID
 *
 * DatabaseID für Kontakte die aus einem TextFile (Meta) gelesen wurden
 * EntryIDDB: DB99
 * EntryIDStore: File Name des Source files.
 * EntryID: EntryID des Kontaktes (aus Quelle)
 *
 * @private
 */
export class AsnDatabaseEntryID {
	public constructor(that: AsnDatabaseEntryID) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseEntryID {
		return new AsnDatabaseEntryID({
			u8sEntryIDDB: "",
			u8sEntryIDStore: "",
			u8sEntryID: ""
		});
	}

	public static type = "AsnDatabaseEntryID";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDatabaseEntryID",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sEntryIDDB" }),
				new asn1ts.Utf8String({ name: "u8sEntryIDStore" }),
				new asn1ts.Utf8String({ name: "u8sEntryID" })
			]
		});
	}

	public u8sEntryIDDB!: string;
	public u8sEntryIDStore!: string;
	public u8sEntryID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnDatabaseFindOption {
	public constructor(that: AsnDatabaseFindOption) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseFindOption {
		return new AsnDatabaseFindOption({
			u8sFirstName: "",
			u8sLastName: "",
			u8sCompanyName: "",
			u8sCustomerID: "",
			u8sDepartment: "",
			u8sStreetAddress: "",
			u8sAreaCodeOrCity: "",
			u8sPhoneNo: "",
			u8sEMail: "",
			iMaxNumEntries: 0,
			u8sDatabaseName: "",
			u8sUsername: "",
			iFlags: 0
		});
	}

	public static type = "AsnDatabaseFindOption";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDatabaseFindOption",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sFirstName" }),
				new asn1ts.Utf8String({ name: "u8sLastName" }),
				new asn1ts.Utf8String({ name: "u8sCompanyName" }),
				new asn1ts.Utf8String({ name: "u8sCustomerID" }),
				new asn1ts.Utf8String({ name: "u8sDepartment" }),
				new asn1ts.Utf8String({ name: "u8sStreetAddress" }),
				new asn1ts.Utf8String({ name: "u8sAreaCodeOrCity" }),
				new asn1ts.Utf8String({ name: "u8sPhoneNo" }),
				new asn1ts.Utf8String({ name: "u8sEMail" }),
				new asn1ts.Integer({ name: "iMaxNumEntries" }),
				new asn1ts.Utf8String({ name: "u8sDatabaseName" }),
				new asn1ts.Utf8String({ name: "u8sUsername" }),
				new asn1ts.Integer({ name: "iFlags" })
			]
		});
	}

	/** Vorname */
	public u8sFirstName!: string;
	/** Nachname oder Suchbegriff für mehrere Felder */
	public u8sLastName!: string;
	/** Firmenname */
	public u8sCompanyName!: string;
	/** Kundennummer */
	public u8sCustomerID!: string;
	/** Abteilung */
	public u8sDepartment!: string;
	/** Strasse */
	public u8sStreetAddress!: string;
	/** Postleitzahl oder Ortsname */
	public u8sAreaCodeOrCity!: string;
	/** Telefonnummer */
	public u8sPhoneNo!: string;
	/** E-Mail */
	public u8sEMail!: string;
	/** Maximale Anzahl der Datensätze */
	public iMaxNumEntries!: number;
	/** Suche nur in dieser Datenbank */
	public u8sDatabaseName!: string;
	/** Benutzer, unter dem die Anfrage ausgeführt wird */
	public u8sUsername!: string;
	/** Flags ENDBF_xxx Konstanten (AsnNetDatabaseFindOptionIFlags aus ENetDatabaseFindOptions.h) */
	public iFlags!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnDBPhoneBook {
	public constructor(that: AsnDBPhoneBook) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDBPhoneBook {
		return new AsnDBPhoneBook({
			u8sName: "",
			u8sInfo: "",
			iCapabilities: 0,
			iType: 0,
			errors: new ENetUC_Common.AsnRequestErrorList()
		});
	}

	public static type = "AsnDBPhoneBook";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDBPhoneBook",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sName" }),
				new asn1ts.Utf8String({ name: "u8sInfo" }),
				new asn1ts.Integer({ name: "iCapabilities" }),
				new asn1ts.Integer({ name: "iType" }),
				ENetUC_Common.AsnRequestErrorList.getASN1Schema({ name: "errors" })
			]
		});
	}

	/**
	 * Name des Telefonbuchs
	 * z.B. klickTel oder DeTeMedien oder TwixTel.
	 * Achtung, sollte in allen Fällen gleich geschrieben werden.
	 */
	public u8sName!: string;
	/**
	 * Info about.
	 * Custom String von dem Backend (z.B. für DeTeMedien)
	 */
	public u8sInfo!: string;
	/**
	 * Capabilities des Telefonbuchs.
	 * z.B. ENDBC_EXACTINEXACTMATCH
	 */
	public iCapabilities!: number;
	/**
	 * Typ des Telefonbuchs.
	 * z.B.  ENDBN_PHONEBOOK
	 */
	public iType!: number;
	public errors!: ENetUC_Common.AsnRequestErrorList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnNetDatabaseFindOptions {
	public constructor(that: AsnNetDatabaseFindOptions) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNetDatabaseFindOptions {
		return new AsnNetDatabaseFindOptions({
			u8sFirstName: "",
			u8sLastName: "",
			u8sCompanyName: "",
			u8sCustomerID: "",
			u8sDepartment: "",
			u8sStreetAddress: "",
			u8sAreaCodeOrCity: "",
			u8sPhoneNo: "",
			u8sEMail: "",
			u8spairlistCustomFieldsValues: new ENetUC_Common.AsnStringPairList(),
			iMaxNumEntries: 0,
			u8slistDatabaseNames: new ENetUC_Common.UTF8StringList(),
			u8sUsername: "",
			iFlags: 0,
			iDisplayNameFormat: 0
		});
	}

	public static type = "AsnNetDatabaseFindOptions";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNetDatabaseFindOptions",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sFirstName" }),
				new asn1ts.Utf8String({ name: "u8sLastName" }),
				new asn1ts.Utf8String({ name: "u8sCompanyName" }),
				new asn1ts.Utf8String({ name: "u8sCustomerID" }),
				new asn1ts.Utf8String({ name: "u8sDepartment" }),
				new asn1ts.Utf8String({ name: "u8sStreetAddress" }),
				new asn1ts.Utf8String({ name: "u8sAreaCodeOrCity" }),
				new asn1ts.Utf8String({ name: "u8sPhoneNo" }),
				new asn1ts.Utf8String({ name: "u8sEMail" }),
				ENetUC_Common.AsnStringPairList.getASN1Schema({ name: "u8spairlistCustomFieldsValues" }),
				new asn1ts.Integer({ name: "iMaxNumEntries" }),
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8slistDatabaseNames" }),
				new asn1ts.Utf8String({ name: "u8sUsername" }),
				new asn1ts.Integer({ name: "iFlags" }),
				new asn1ts.Integer({ name: "iDisplayNameFormat" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** Vorname */
	public u8sFirstName!: string;
	/** Nachname oder Suchbegriff für mehrere Felder */
	public u8sLastName!: string;
	/** Firmenname */
	public u8sCompanyName!: string;
	/** Kundennummer */
	public u8sCustomerID!: string;
	/** Abteilung */
	public u8sDepartment!: string;
	/** Strasse */
	public u8sStreetAddress!: string;
	/** Postleitzahl oder Ortsname */
	public u8sAreaCodeOrCity!: string;
	/** Telefonnummer */
	public u8sPhoneNo!: string;
	/** E-Mail */
	public u8sEMail!: string;
	/** Benutzerdefinierte Suchparameter */
	public u8spairlistCustomFieldsValues!: ENetUC_Common.AsnStringPairList;
	/** Maximale Anzahl der Datensätze */
	public iMaxNumEntries!: number;
	/**
	 * Suche in diesen Datenbanken
	 * if \"ActiveUsersOnly\" is added to the list of databases, the result set will be reduced to the enabled users of this server only
	 */
	public u8slistDatabaseNames!: ENetUC_Common.UTF8StringList;
	/** Benutzer, unter dem die Anfrage ausgeführt wird */
	public u8sUsername!: string;
	/** Flags ENDBF_xxx Konstanten (AsnNetDatabaseFindOptionIFlags aus ENetDatabaseFindOptions.h) */
	public iFlags!: number;
	/** DISPLAYNAME_FIRSTLAST (0) oder DISPLAYNAME_LASTFIRST (1) */
	public iDisplayNameFormat!: number;
	/**
	 * iPageOffset			INTEGER		Ab welchem Datensatz (inklusive) sollen die Ergebnisse ausgeliefert werden
	 * iPageSize			INTEGER		Wieviele Ergebnisse sollen ab dem Datensatz iPageOffset ausgeliefert werden
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetContactByEntryIDArgument {
	public constructor(that: AsnGetContactByEntryIDArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetContactByEntryIDArgument {
		return new AsnGetContactByEntryIDArgument({
			u8sEntryIDDB: "",
			u8sEntryIDStore: "",
			u8sEntryID: ""
		});
	}

	public static type = "AsnGetContactByEntryIDArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetContactByEntryIDArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sEntryIDDB" }),
				new asn1ts.Utf8String({ name: "u8sEntryIDStore" }),
				new asn1ts.Utf8String({ name: "u8sEntryID" })
			]
		});
	}

	public u8sEntryIDDB!: string;
	public u8sEntryIDStore!: string;
	public u8sEntryID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetContactByEntryIDResult {
	public constructor(that: AsnGetContactByEntryIDResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetContactByEntryIDResult {
		return new AsnGetContactByEntryIDResult({
			bSuccess: false,
			contact: ENetUC_Common.AsnNetDatabaseContact.initEmpty()
		});
	}

	public static type = "AsnGetContactByEntryIDResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetContactByEntryIDResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bSuccess" }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "contact" })
			]
		});
	}

	public bSuccess!: boolean;
	public contact!: ENetUC_Common.AsnNetDatabaseContact;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetPreferredContactRefArgument {
	public constructor(that: AsnGetPreferredContactRefArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetPreferredContactRefArgument {
		return new AsnGetPreferredContactRefArgument({
			u8sContactID: ""
		});
	}

	public static type = "AsnGetPreferredContactRefArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetPreferredContactRefArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" })
			]
		});
	}

	public u8sContactID!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetPreferredContactRefResult {
	public constructor(that: AsnGetPreferredContactRefResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetPreferredContactRefResult {
		return new AsnGetPreferredContactRefResult({
			bContactRefFound: false,
			u8sEntryID: "",
			u8sStoreID: "",
			u8sDBID: "",
			u8sDisplayName: ""
		});
	}

	public static type = "AsnGetPreferredContactRefResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetPreferredContactRefResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bContactRefFound" }),
				new asn1ts.Utf8String({ name: "u8sEntryID" }),
				new asn1ts.Utf8String({ name: "u8sStoreID" }),
				new asn1ts.Utf8String({ name: "u8sDBID" }),
				new asn1ts.Utf8String({ name: "u8sDisplayName" })
			]
		});
	}

	public bContactRefFound!: boolean;
	public u8sEntryID!: string;
	public u8sStoreID!: string;
	public u8sDBID!: string;
	/** Anzeigename als zusätzliche Information, als Fallback, falls der Kontakt später mit den EntryID-Triples nicht mehr gefunden werden kann. */
	public u8sDisplayName!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetPreferredContactRefArgument {
	public constructor(that: AsnSetPreferredContactRefArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetPreferredContactRefArgument {
		return new AsnSetPreferredContactRefArgument({
			u8sContactID: "",
			u8sEntryID: "",
			u8sStoreID: "",
			u8sDBID: "",
			u8sDisplayName: ""
		});
	}

	public static type = "AsnSetPreferredContactRefArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetPreferredContactRefArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" }),
				new asn1ts.Utf8String({ name: "u8sEntryID" }),
				new asn1ts.Utf8String({ name: "u8sStoreID" }),
				new asn1ts.Utf8String({ name: "u8sDBID" }),
				new asn1ts.Utf8String({ name: "u8sDisplayName" })
			]
		});
	}

	public u8sContactID!: string;
	public u8sEntryID!: string;
	public u8sStoreID!: string;
	public u8sDBID!: string;
	/** Anzeigename als zusätzliche Information, als Fallback, falls der Kontakt später mit den EntryID-Triples nicht mehr gefunden werden kann. */
	public u8sDisplayName!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * DatabaseConnector:
 * Ein Database Connector verwendet folgende Funktionen
 * asnDatabaseConnectorLogin
 * asnDatabaseConnectorLogoff
 * Er muss folgende Funktionen implementieren
 * asnFindDBContacts
 * asnGetPhoneBooks
 * An und abmeldung eines Datenbank Connectors am Server
 *
 * @private
 */
export class AsnDatabaseConnectorLoginArgument {
	public constructor(that: AsnDatabaseConnectorLoginArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseConnectorLoginArgument {
		return new AsnDatabaseConnectorLoginArgument({
			u8sUserName: "",
			u8sPassword: "",
			iVersion: 0,
			iDatabaseCapabilities: 0,
			iDatabaseType: 0,
			u8sDatabaseName: "",
			u8sDatabaseInfo: ""
		});
	}

	public static type = "AsnDatabaseConnectorLoginArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDatabaseConnectorLoginArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sUserName" }),
				new asn1ts.Utf8String({ name: "u8sPassword" }),
				new asn1ts.Integer({ name: "iVersion" }),
				new asn1ts.Integer({ name: "iDatabaseCapabilities" }),
				new asn1ts.Integer({ name: "iDatabaseType" }),
				new asn1ts.Utf8String({ name: "u8sDatabaseName" }),
				new asn1ts.Utf8String({ name: "u8sDatabaseInfo" })
			]
		});
	}

	public u8sUserName!: string;
	public u8sPassword!: string;
	public iVersion!: number;
	public iDatabaseCapabilities!: number;
	public iDatabaseType!: number;
	public u8sDatabaseName!: string;
	public u8sDatabaseInfo!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnDatabaseConnectorLoginResult {
	public constructor(that: AsnDatabaseConnectorLoginResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseConnectorLoginResult {
		return new AsnDatabaseConnectorLoginResult({
			iConnectorCrossRefID: 0
		});
	}

	public static type = "AsnDatabaseConnectorLoginResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDatabaseConnectorLoginResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iConnectorCrossRefID" })
			]
		});
	}

	public iConnectorCrossRefID!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnDatabaseConnectorLogoffArgument {
	public constructor(that: AsnDatabaseConnectorLogoffArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseConnectorLogoffArgument {
		return new AsnDatabaseConnectorLogoffArgument({
			iConnectorCrossRefID: 0
		});
	}

	public static type = "AsnDatabaseConnectorLogoffArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDatabaseConnectorLogoffArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iConnectorCrossRefID" })
			]
		});
	}

	public iConnectorCrossRefID!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnDatabaseConnectorLogoffResult {
	public constructor(that?: AsnDatabaseConnectorLogoffResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseConnectorLogoffResult {
		return new AsnDatabaseConnectorLogoffResult();
	}

	public static type = "AsnDatabaseConnectorLogoffResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnDatabaseConnectorLogoffResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetPhoneBooksArgument {
	public constructor(that?: AsnGetPhoneBooksArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetPhoneBooksArgument {
		return new AsnGetPhoneBooksArgument();
	}

	public static type = "AsnGetPhoneBooksArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetPhoneBooksArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * ***********************************************************--
 * *********** UserPropertyBag Settings speichern ************--
 * ***********************************************************--
 *
 * @private
 */
export class AsnSetUserPropertyBagArgument {
	public constructor(that: AsnSetUserPropertyBagArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetUserPropertyBagArgument {
		return new AsnSetUserPropertyBagArgument({
			asnUserPropertyBag: ENetUC_Common.AsnUserPropertyBag.initEmpty()
		});
	}

	public static type = "AsnSetUserPropertyBagArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetUserPropertyBagArgument",
			...params,
			value: [
				ENetUC_Common.AsnUserPropertyBag.getASN1Schema({ name: "asnUserPropertyBag" })
			]
		});
	}

	public asnUserPropertyBag!: ENetUC_Common.AsnUserPropertyBag;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetUserPropertyBagResult {
	public constructor(that: AsnSetUserPropertyBagResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetUserPropertyBagResult {
		return new AsnSetUserPropertyBagResult({
			iResult: 0
		});
	}

	public static type = "AsnSetUserPropertyBagResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetUserPropertyBagResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" })
			]
		});
	}

	/** 0 = Success (NO_ERROR), sonst Fehlercode (i.d.R. &lt; 0) */
	public iResult!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnServerBasedClientSettingsEx {
	public constructor(that: AsnServerBasedClientSettingsEx) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnServerBasedClientSettingsEx {
		return new AsnServerBasedClientSettingsEx({
			u8sXMLSettingsData: ""
		});
	}

	public static type = "AsnServerBasedClientSettingsEx";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnServerBasedClientSettingsEx",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sXMLSettingsData" })
			]
		});
	}

	/** XML formatted settings */
	public u8sXMLSettingsData!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactStatusResultEntry {
	public constructor(that: AsnContactStatusResultEntry) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusResultEntry {
		return new AsnContactStatusResultEntry({
			iClientSearchID: 0,
			iClientRowID: 0,
			iPresenceState: 0,
			u8sContactID: ""
		});
	}

	public static type = "AsnContactStatusResultEntry";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactStatusResultEntry",
			...params,
			value: [
				new asn1ts.Integer({ name: "iClientSearchID" }),
				new asn1ts.Integer({ name: "iClientRowID" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				new asn1ts.Utf8String({ name: "u8sContactID" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** ID der Suchanfrage um z.B. mehrere, gleichzeitige Suchfenster am Client unterscheiden zu können */
	public iClientSearchID!: number;
	/** ID der Zeile in der oben angegebenen Suchanfrage */
	public iClientRowID!: number;
	/** Aktueller Zustand des entsprechenden ActiveContacts am Server */
	public iPresenceState!: number;
	/** ContactID des korrespondierenden Contacts am Server, falls vorhanden */
	public u8sContactID!: string;
	/** optionalParams Name \"note\" Value String */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactSearchCloseArgument {
	public constructor(that: AsnContactSearchCloseArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactSearchCloseArgument {
		return new AsnContactSearchCloseArgument({
			iClientSearchID: 0
		});
	}

	public static type = "AsnContactSearchCloseArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactSearchCloseArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iClientSearchID" })
			]
		});
	}

	public iClientSearchID!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactSearchCloseResult {
	public constructor(that: AsnContactSearchCloseResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactSearchCloseResult {
		return new AsnContactSearchCloseResult({
			iResult: 0
		});
	}

	public static type = "AsnContactSearchCloseResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactSearchCloseResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" })
			]
		});
	}

	public iResult!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAppsRegisterPushArgument {
	public constructor(that: AsnAppsRegisterPushArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAppsRegisterPushArgument {
		return new AsnAppsRegisterPushArgument({
			iRegisterType: 0,
			u8sDeviceID: "",
			u8sPushID: "",
			u8sPushChannelID: ""
		});
	}

	public static type = "AsnAppsRegisterPushArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAppsRegisterPushArgument",
			...params,
			value: [
				new asn1ts.Integer({ name: "iRegisterType" }),
				new asn1ts.Utf8String({ name: "u8sDeviceID" }),
				new asn1ts.Utf8String({ name: "u8sPushID" }),
				new asn1ts.Utf8String({ name: "u8sPushChannelID" }),
				new asn1ts.Integer({ name: "iEncryption", idBlock: { optionalID: 0 } }),
				new asn1ts.Utf8String({ name: "u8sPushIDVoIP", idBlock: { optionalID: 1 } }),
				new asn1ts.Utf8String({ name: "u8sPushChannelIDVoIP", idBlock: { optionalID: 2 } })
			]
		});
	}

	/** Type of Registration */
	public iRegisterType!: number;
	/** mandatory - DeviceID unique ID for the device (Installation Instance)&lt;br \/&gt;mandatory bei Anmeldung, sonst optional  Push ID of the Device (z.B. ios: 0124f138fc90e83bf91b6cc58815ad7a2136d7339acece26e310110713ca928e,&lt;br \/&gt;gcm: APA91bHPmxUoXJ4OXgsvOJq45qCRo8K4UB8iYqU27dR8lzzLWnEJg8NUs3mPXSqXBsjqJ4hHljGNwEjrisIg3vEw7UXHBkTR2s3fK8QVmPS9N42Vf_wagKiiaKmp9FnwFGOJbhXcIQ4O-FkFEx7HjWTQ-oZZx9UqIA */
	public u8sDeviceID!: string;
	/** mandatory bei Anmeldung, sonst optional - Push Channel ID (z.B. ios.de.estos.apps.procallmobile.dev) */
	public u8sPushID!: string;
	public u8sPushChannelID!: string;
	/**
	 * Tells the server to create an key for encrypting the push message payload
	 * Type of encryption is given with the AsnAppsPushEncryption enum
	 */
	public iEncryption?: number;
	/** optional - Wenn gesetzt, so sendet der Server 'avnewcall' Benachrichtigungen für Softphone Calls über diese ID. */
	public u8sPushIDVoIP?: string;
	/** mandatory wenn u8sPushIDVoIP gesetzt ist, ansonsten optional. */
	public u8sPushChannelIDVoIP?: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAppsRegisterPushResult {
	public constructor(that?: AsnAppsRegisterPushResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAppsRegisterPushResult {
		return new AsnAppsRegisterPushResult();
	}

	public static type = "AsnAppsRegisterPushResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAppsRegisterPushResult",
			...params,
			value: [
				new asn1ts.OctetString({ name: "key", optional: true })
			]
		});
	}

	/** The key for encrypting\/decrypting the push message payload (sent when 'encyrpted' was requested) */
	public key?: Uint8Array;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnPublicUserContactData {
	public constructor(that: AsnPublicUserContactData) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnPublicUserContactData {
		return new AsnPublicUserContactData({
			contactData: ENetUC_Common.AsnNetDatabaseContact.initEmpty()
		});
	}

	public static type = "AsnPublicUserContactData";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnPublicUserContactData",
			...params,
			value: [
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "contactData" })
			]
		});
	}

	public contactData!: ENetUC_Common.AsnNetDatabaseContact;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnPublicUserSetContactDataResult {
	public constructor(that: AsnPublicUserSetContactDataResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnPublicUserSetContactDataResult {
		return new AsnPublicUserSetContactDataResult({
			iResult: 0
		});
	}

	public static type = "AsnPublicUserSetContactDataResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnPublicUserSetContactDataResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" })
			]
		});
	}

	public iResult!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetGoogleIntegrationSettingsArgument {
	public constructor(that?: AsnGetGoogleIntegrationSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetGoogleIntegrationSettingsArgument {
		return new AsnGetGoogleIntegrationSettingsArgument();
	}

	public static type = "AsnGetGoogleIntegrationSettingsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetGoogleIntegrationSettingsArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetGoogleIntegrationSettingsResult {
	public constructor(that: AsnGetGoogleIntegrationSettingsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetGoogleIntegrationSettingsResult {
		return new AsnGetGoogleIntegrationSettingsResult({
			iResult: 0,
			iEnabled: 0,
			u8sClientID: "",
			u8sClientSecret: ""
		});
	}

	public static type = "AsnGetGoogleIntegrationSettingsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetGoogleIntegrationSettingsResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				new asn1ts.Integer({ name: "iEnabled" }),
				new asn1ts.Utf8String({ name: "u8sClientID" }),
				new asn1ts.Utf8String({ name: "u8sClientSecret" })
			]
		});
	}

	public iResult!: number;
	public iEnabled!: number;
	public u8sClientID!: string;
	public u8sClientSecret!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSMSMessage {
	public constructor(that: AsnSMSMessage) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSMSMessage {
		return new AsnSMSMessage({
			u8sRefId: "",
			u8sSenderNumberSC: "",
			u8sReceiverNumberSC: "",
			u8sMessage: "",
			iContentIsPrivate: 0
		});
	}

	public static type = "AsnSMSMessage";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSMSMessage",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sRefId" }),
				new asn1ts.Utf8String({ name: "u8sSenderNumberSC" }),
				new asn1ts.Utf8String({ name: "u8sReceiverNumberSC" }),
				new asn1ts.Utf8String({ name: "u8sMessage" }),
				new asn1ts.Integer({ name: "iContentIsPrivate" }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "contactReceiver", idBlock: { optionalID: 0 } })
			]
		});
	}

	/** Referenz ID der Nachricht, wird vom Server vergeben; Beim Versendne einfach leer lassen. (in der Struktur um die jeweilige, lokale Verwaltung zu vereinfachen) */
	public u8sRefId!: string;
	/** (Mobil-)Rufnummer des Absenders (leer lassen, dann ergänzt der Server entsprechend Einstellungen anhand des eingeloggten Benutzers) */
	public u8sSenderNumberSC!: string;
	/** Mobil-Rufnummer des Empfängers (SC!) */
	public u8sReceiverNumberSC!: string;
	/** Die zu versendende Nachricht. */
	public u8sMessage!: string;
	/** 0: Textnachricht wird vollständig im Log vermerkt; !=0: Text der Nachricht wird im Log nicht mitgespeichert. */
	public iContentIsPrivate!: number;
	/** Optional Kontaktdaten zum Empfänger, um ggf. bessere Ausgaben\/Journaleinträge zu ermöglichen */
	public contactReceiver?: ENetUC_Common.AsnNetDatabaseContact;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSendSMSResult {
	public constructor(that: AsnSendSMSResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendSMSResult {
		return new AsnSendSMSResult({
			u8sCrossRefIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	public static type = "AsnSendSMSResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSendSMSResult",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8sCrossRefIDs" })
			]
		});
	}

	/** CrossrefIDs für die Nachrichten in der gleichen Reihenfolge */
	public u8sCrossRefIDs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUserChangePasswordArgument {
	public constructor(that: AsnUserChangePasswordArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUserChangePasswordArgument {
		return new AsnUserChangePasswordArgument({
			u8sOldPassword: "",
			u8sNewPassword: ""
		});
	}

	public static type = "AsnUserChangePasswordArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUserChangePasswordArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sOldPassword" }),
				new asn1ts.Utf8String({ name: "u8sNewPassword" })
			]
		});
	}

	public u8sOldPassword!: string;
	public u8sNewPassword!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUserChangePasswordResult {
	public constructor(that: AsnUserChangePasswordResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUserChangePasswordResult {
		return new AsnUserChangePasswordResult({
			success: false
		});
	}

	public static type = "AsnUserChangePasswordResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUserChangePasswordResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "success" })
			]
		});
	}

	public success!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetUserdefinedCustomFieldsArgument {
	public constructor(that?: AsnGetUserdefinedCustomFieldsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetUserdefinedCustomFieldsArgument {
		return new AsnGetUserdefinedCustomFieldsArgument();
	}

	public static type = "AsnGetUserdefinedCustomFieldsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetUserdefinedCustomFieldsArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnUserDefinedCustomField {
	public constructor(that: AsnUserDefinedCustomField) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUserDefinedCustomField {
		return new AsnUserDefinedCustomField({
			u8sFieldName: "",
			u8sFieldValue: ""
		});
	}

	public static type = "AsnUserDefinedCustomField";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUserDefinedCustomField",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sFieldName" }),
				new asn1ts.Utf8String({ name: "u8sFieldValue" })
			]
		});
	}

	public u8sFieldName!: string;
	public u8sFieldValue!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSendMailArgument {
	public constructor(that: AsnSendMailArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendMailArgument {
		return new AsnSendMailArgument({
			u8sReceiverSIPURI: "",
			u8sMessage: "",
			u8sSubject: ""
		});
	}

	public static type = "AsnSendMailArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSendMailArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sReceiverSIPURI" }),
				new asn1ts.Utf8String({ name: "u8sMessage" }),
				new asn1ts.Utf8String({ name: "u8sSubject" })
			]
		});
	}

	/** sipuri des Empfängers\/Gruppe (zum Spamschutz hier keine Mailadresse! Der Server ermittelt zur übergebenen sipuri die zugehörige Mailadresse(n) selbst) */
	public u8sReceiverSIPURI!: string;
	/** Die zu versendende Nachricht. */
	public u8sMessage!: string;
	/** Der Nachrichten-Betreff. */
	public u8sSubject!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSendMailResult {
	public constructor(that: AsnSendMailResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendMailResult {
		return new AsnSendMailResult({
			success: false,
			u8sErrReason: ""
		});
	}

	public static type = "AsnSendMailResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSendMailResult",
			...params,
			value: [
				new asn1ts.Boolean({ name: "success" }),
				new asn1ts.Utf8String({ name: "u8sErrReason" })
			]
		});
	}

	public success!: boolean;
	/** Im Fehlerfall kann hier noch ein Grund stehen (logging) */
	public u8sErrReason!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetServerInterfaceVersionArgument {
	public constructor(that?: AsnGetServerInterfaceVersionArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetServerInterfaceVersionArgument {
		return new AsnGetServerInterfaceVersionArgument();
	}

	public static type = "AsnGetServerInterfaceVersionArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetServerInterfaceVersionArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetServerInterfaceVersionResult {
	public constructor(that: AsnGetServerInterfaceVersionResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetServerInterfaceVersionResult {
		return new AsnGetServerInterfaceVersionResult({
			iServerInterfaceVersion: 0
		});
	}

	public static type = "AsnGetServerInterfaceVersionResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetServerInterfaceVersionResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iServerInterfaceVersion" })
			]
		});
	}

	/** Interface-Version des UCServers */
	public iServerInterfaceVersion!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnRemoteInvokeCompleteArgument {
	public constructor(that: AsnRemoteInvokeCompleteArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRemoteInvokeCompleteArgument {
		return new AsnRemoteInvokeCompleteArgument({
			u8sInvokeID: "",
			u8sInvokingConnection: "",
			iResult: 0
		});
	}

	public static type = "AsnRemoteInvokeCompleteArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRemoteInvokeCompleteArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sInvokeID" }),
				new asn1ts.Utf8String({ name: "u8sInvokingConnection" }),
				new asn1ts.Integer({ name: "iResult" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 0 } })
			]
		});
	}

	public u8sInvokeID!: string;
	public u8sInvokingConnection!: string;
	public iResult!: number;
	/**
	 * optionalParams Name \"res\" Value String (JSON), is possibly only used internally (not really clear what that usecase is)
	 * optionalParams Name \"err\" Value String (JSON), is possibly only used internally (not really clear what that usecase is)
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnNumberFormatRegExpressList2 extends Array<AsnNumberFormatRegExpression2> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnNumberFormatRegExpression2.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Encapsulates AsnNumberFormatRegExpressList2 together with a readonly flag.
 */
export class AsnNumberFormatRegExpressions2 {
	public constructor(that: AsnNumberFormatRegExpressions2) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNumberFormatRegExpressions2 {
		return new AsnNumberFormatRegExpressions2({
			bIsReadOnlyFromFile: false,
			formatRules: new AsnNumberFormatRegExpressList2()
		});
	}

	public static type = "AsnNumberFormatRegExpressions2";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnNumberFormatRegExpressions2",
			...params,
			value: [
				new asn1ts.Boolean({ name: "bIsReadOnlyFromFile" }),
				AsnNumberFormatRegExpressList2.getASN1Schema({ name: "formatRules" })
			]
		});
	}

	public bIsReadOnlyFromFile!: boolean;
	public formatRules!: AsnNumberFormatRegExpressList2;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Description of formatting and dialing rules for phone numbers.
 * Each PBX is connected to a phone network which defines the rules to apply if number should be dialed or how
 * phone numbers are routed and transmitted.
 *
 * The AsnLocationInformation contains information how to handle
 * phone numbers to allow comfort functions for the user like formatting phone numbers to display them in a readable manner
 * or to enable the user to enter numbers in a short format etc.
 *
 * Replaces AsnLocationInformation.
 */
export class AsnLocationInformation2 {
	public constructor(that: AsnLocationInformation2) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLocationInformation2 {
		return new AsnLocationInformation2({
			u8sLocationID: "",
			u8sLocationCheckSum: "",
			bUseVanity: false,
			bShowExtendedSettings: false,
			bUseTkSystem: false,
			bUseAdvancedLCR: false,
			bFixedNumberLenExtensionDTMFDialing: false,
			bMultiLocationSupport: false,
			bAutoCreateOtherLocationRules: false,
			bAllowNumberSearchOtherLocations: false,
			bShortenOtherLocationSCNumbersForDialing: false,
			iCountryID: 0,
			iLanguage: 0,
			u8sCountryCode: "",
			u8sLocalPrefix: "",
			u8sNationalPrefix: "",
			u8sInternationalPrefix: "",
			u8sLocalDialingRule: "",
			u8sNationalDialingRule: "",
			u8sInternationalDialingRule: "",
			u8sCityCodeNoPrefix: "",
			u8sVanityNumbers: "",
			u8sTKLocalAccessCode: "",
			u8sTKLongDistanceAccessCode: "",
			u8sTKInternationalAccessCode: "",
			u8sTKPrivateAccessCode: "",
			u8sTKForwardingAccessCode: "",
			u8sTKAnschlussNr: "",
			iTKMinLenInternalNumbers: 0,
			iTKMaxLenInternalNumbers: 0,
			numberFormatInternal: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatExternal: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatAutomaticOtherLocationsDDItoSC: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatAutomaticOtherLocationsSCtoDDI: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatRegExpressListIn: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatRegExpressListOut: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatRegExpressListDial: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatRegExpressListFinalDial: AsnNumberFormatRegExpressions2.initEmpty(),
			projectPINRules: new ENetUC_Common_SIPCTI.AsnProjectPinRuleList(),
			u8sDDIRangeBegin: "",
			u8sDDIRangeEnd: "",
			iDialingNumberFormat: 0,
			bNoLocalDialing: false
		});
	}

	public static type = "AsnLocationInformation2";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnLocationInformation2",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sLocationID" }),
				new asn1ts.Utf8String({ name: "u8sLocationCheckSum" }),
				new asn1ts.Boolean({ name: "bUseVanity" }),
				new asn1ts.Boolean({ name: "bShowExtendedSettings" }),
				new asn1ts.Boolean({ name: "bUseTkSystem" }),
				new asn1ts.Boolean({ name: "bUseAdvancedLCR" }),
				new asn1ts.Boolean({ name: "bFixedNumberLenExtensionDTMFDialing" }),
				new asn1ts.Boolean({ name: "bMultiLocationSupport" }),
				new asn1ts.Boolean({ name: "bAutoCreateOtherLocationRules" }),
				new asn1ts.Boolean({ name: "bAllowNumberSearchOtherLocations" }),
				new asn1ts.Boolean({ name: "bShortenOtherLocationSCNumbersForDialing" }),
				new asn1ts.Integer({ name: "iCountryID" }),
				new asn1ts.Integer({ name: "iLanguage" }),
				new asn1ts.Utf8String({ name: "u8sCountryCode" }),
				new asn1ts.Utf8String({ name: "u8sLocalPrefix" }),
				new asn1ts.Utf8String({ name: "u8sNationalPrefix" }),
				new asn1ts.Utf8String({ name: "u8sInternationalPrefix" }),
				new asn1ts.Utf8String({ name: "u8sLocalDialingRule" }),
				new asn1ts.Utf8String({ name: "u8sNationalDialingRule" }),
				new asn1ts.Utf8String({ name: "u8sInternationalDialingRule" }),
				new asn1ts.Utf8String({ name: "u8sCityCodeNoPrefix" }),
				new asn1ts.Utf8String({ name: "u8sVanityNumbers" }),
				new asn1ts.Utf8String({ name: "u8sTKLocalAccessCode" }),
				new asn1ts.Utf8String({ name: "u8sTKLongDistanceAccessCode" }),
				new asn1ts.Utf8String({ name: "u8sTKInternationalAccessCode" }),
				new asn1ts.Utf8String({ name: "u8sTKPrivateAccessCode" }),
				new asn1ts.Utf8String({ name: "u8sTKForwardingAccessCode" }),
				new asn1ts.Utf8String({ name: "u8sTKAnschlussNr" }),
				new asn1ts.Integer({ name: "iTKMinLenInternalNumbers" }),
				new asn1ts.Integer({ name: "iTKMaxLenInternalNumbers" }),
				AsnNumberFormatRegExpressions2.getASN1Schema({ name: "numberFormatInternal" }),
				AsnNumberFormatRegExpressions2.getASN1Schema({ name: "numberFormatExternal" }),
				AsnNumberFormatRegExpressions2.getASN1Schema({ name: "numberFormatAutomaticOtherLocationsDDItoSC" }),
				AsnNumberFormatRegExpressions2.getASN1Schema({ name: "numberFormatAutomaticOtherLocationsSCtoDDI" }),
				AsnNumberFormatRegExpressions2.getASN1Schema({ name: "numberFormatRegExpressListIn" }),
				AsnNumberFormatRegExpressions2.getASN1Schema({ name: "numberFormatRegExpressListOut" }),
				AsnNumberFormatRegExpressions2.getASN1Schema({ name: "numberFormatRegExpressListDial" }),
				AsnNumberFormatRegExpressions2.getASN1Schema({ name: "numberFormatRegExpressListFinalDial" }),
				ENetUC_Common_SIPCTI.AsnProjectPinRuleList.getASN1Schema({ name: "projectPINRules" }),
				new asn1ts.Utf8String({ name: "u8sDDIRangeBegin" }),
				new asn1ts.Utf8String({ name: "u8sDDIRangeEnd" }),
				new asn1ts.Integer({ name: "iDialingNumberFormat" }),
				new asn1ts.Boolean({ name: "bNoLocalDialing" })
			]
		});
	}

	public u8sLocationID!: string;
	/** Checksum to detec changes in the AsnLocationInformation2. */
	public u8sLocationCheckSum!: string;
	public bUseVanity!: boolean;
	public bShowExtendedSettings!: boolean;
	public bUseTkSystem!: boolean;
	public bUseAdvancedLCR!: boolean;
	public bFixedNumberLenExtensionDTMFDialing!: boolean;
	public bMultiLocationSupport!: boolean;
	public bAutoCreateOtherLocationRules!: boolean;
	public bAllowNumberSearchOtherLocations!: boolean;
	public bShortenOtherLocationSCNumbersForDialing!: boolean;
	public iCountryID!: number;
	public iLanguage!: number;
	public u8sCountryCode!: string;
	public u8sLocalPrefix!: string;
	public u8sNationalPrefix!: string;
	public u8sInternationalPrefix!: string;
	public u8sLocalDialingRule!: string;
	public u8sNationalDialingRule!: string;
	public u8sInternationalDialingRule!: string;
	public u8sCityCodeNoPrefix!: string;
	public u8sVanityNumbers!: string;
	public u8sTKLocalAccessCode!: string;
	public u8sTKLongDistanceAccessCode!: string;
	public u8sTKInternationalAccessCode!: string;
	public u8sTKPrivateAccessCode!: string;
	public u8sTKForwardingAccessCode!: string;
	public u8sTKAnschlussNr!: string;
	public iTKMinLenInternalNumbers!: number;
	public iTKMaxLenInternalNumbers!: number;
	public numberFormatInternal!: AsnNumberFormatRegExpressions2;
	public numberFormatExternal!: AsnNumberFormatRegExpressions2;
	public numberFormatAutomaticOtherLocationsDDItoSC!: AsnNumberFormatRegExpressions2;
	public numberFormatAutomaticOtherLocationsSCtoDDI!: AsnNumberFormatRegExpressions2;
	public numberFormatRegExpressListIn!: AsnNumberFormatRegExpressions2;
	public numberFormatRegExpressListOut!: AsnNumberFormatRegExpressions2;
	public numberFormatRegExpressListDial!: AsnNumberFormatRegExpressions2;
	public numberFormatRegExpressListFinalDial!: AsnNumberFormatRegExpressions2;
	public projectPINRules!: ENetUC_Common_SIPCTI.AsnProjectPinRuleList;
	public u8sDDIRangeBegin!: string;
	public u8sDDIRangeEnd!: string;
	/** Dialing Number Format. See AsnETAPIDialNumberFormat for details. */
	public iDialingNumberFormat!: number;
	public bNoLocalDialing!: boolean;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Result of asnGetLocationInformation2.
 */
export class AsnGetLocationInformation2Result {
	public constructor(that: AsnGetLocationInformation2Result) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLocationInformation2Result {
		return new AsnGetLocationInformation2Result({
			asnLocationData: AsnLocationInformation2.initEmpty()
		});
	}

	public static type = "AsnGetLocationInformation2Result";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetLocationInformation2Result",
			...params,
			value: [
				AsnLocationInformation2.getASN1Schema({ name: "asnLocationData" })
			]
		});
	}

	/** Location information (v2) about the phone number rules on the line. */
	public asnLocationData!: AsnLocationInformation2;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnSetLocationInformation2Argument {
	public constructor(that: AsnSetLocationInformation2Argument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetLocationInformation2Argument {
		return new AsnSetLocationInformation2Argument({
			asnLocationData: AsnLocationInformation2.initEmpty()
		});
	}

	public static type = "AsnSetLocationInformation2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetLocationInformation2Argument",
			...params,
			value: [
				AsnLocationInformation2.getASN1Schema({ name: "asnLocationData" })
			]
		});
	}

	public asnLocationData!: AsnLocationInformation2;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnActionFilters extends Array<AsnActionFilterEntry> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnActionFilterEntry.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnLineForwardProfile {
	public constructor(that: AsnLineForwardProfile) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLineForwardProfile {
		return new AsnLineForwardProfile({
			u8sGUID: "",
			u8sDisplayName: "",
			lineIdentification: AsnLineForwardProfileChoice.initEmpty(),
			seqLineForward: new ENetUC_Common_SIPCTI.AsnLineForwards()
		});
	}

	public static type = "AsnLineForwardProfile";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnLineForwardProfile",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sGUID" }),
				new asn1ts.Utf8String({ name: "u8sDisplayName" }),
				AsnLineForwardProfileChoice.getASN1Schema({ name: "lineIdentification" }),
				ENetUC_Common_SIPCTI.AsnLineForwards.getASN1Schema({ name: "seqLineForward" })
			]
		});
	}

	/** Eindeutige ID des Profils */
	public u8sGUID!: string;
	/** Anzeigename für die Umleitung */
	public u8sDisplayName!: string;
	/**
	 * Auf welchen Leitungen:
	 * Wenn iAllUSerLines angegeben wurde, sind alle, aktuellen Benutzerleitungen betroffen
	 * sonst eine Leitung, die mit der LineURI angegeben wird
	 */
	public lineIdentification!: AsnLineForwardProfileChoice;
	/** Liste der Forwards die gesetzt werden */
	public seqLineForward!: ENetUC_Common_SIPCTI.AsnLineForwards;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAppointmentForwardEntry {
	public constructor(that: AsnAppointmentForwardEntry) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAppointmentForwardEntry {
		return new AsnAppointmentForwardEntry({
			iAppointmentContactState: 0,
			lineForwardProfile: AsnLineForwardProfile.initEmpty()
		});
	}

	public static type = "AsnAppointmentForwardEntry";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAppointmentForwardEntry",
			...params,
			value: [
				new asn1ts.Integer({ name: "iAppointmentContactState" }),
				AsnLineForwardProfile.getASN1Schema({ name: "lineForwardProfile" }),
				AsnActionFilters.getASN1Schema({ name: "seqFilters", idBlock: { optionalID: 0 } })
			]
		});
	}

	/** Zustand bei dem diese Umleitung gesetzt werden soll */
	public iAppointmentContactState!: number;
	/** Umleitungsprofil welches mit dem Termin aktiviert\/deaktiviert werden soll */
	public lineForwardProfile!: AsnLineForwardProfile;
	/** Optionale Liste von Filterausdrücken, um den Termin noch weiter eingrenzen zu können */
	public seqFilters?: AsnActionFilters;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnAppointmentForwardEntries extends Array<AsnAppointmentForwardEntry> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnAppointmentForwardEntry.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetAppointmentForwardsArgument {
	public constructor(that: AsnSetAppointmentForwardsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetAppointmentForwardsArgument {
		return new AsnSetAppointmentForwardsArgument({
			seqAppointmentForwardEntries: new AsnAppointmentForwardEntries()
		});
	}

	public static type = "AsnSetAppointmentForwardsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetAppointmentForwardsArgument",
			...params,
			value: [
				AsnAppointmentForwardEntries.getASN1Schema({ name: "seqAppointmentForwardEntries" })
			]
		});
	}

	public seqAppointmentForwardEntries!: AsnAppointmentForwardEntries;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetAppointmentForwardsResult {
	public constructor(that: AsnGetAppointmentForwardsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetAppointmentForwardsResult {
		return new AsnGetAppointmentForwardsResult({
			seqAppointmentForwardEntries: new AsnAppointmentForwardEntries()
		});
	}

	public static type = "AsnGetAppointmentForwardsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetAppointmentForwardsResult",
			...params,
			value: [
				AsnAppointmentForwardEntries.getASN1Schema({ name: "seqAppointmentForwardEntries" })
			]
		});
	}

	public seqAppointmentForwardEntries!: AsnAppointmentForwardEntries;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnContactIDs extends Array<AsnContactID> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: new asn1ts.Utf8String()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnCloseContactsArgument {
	public constructor(that: AsnCloseContactsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCloseContactsArgument {
		return new AsnCloseContactsArgument({
			seqContactIDs: new AsnContactIDs()
		});
	}

	public static type = "AsnCloseContactsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCloseContactsArgument",
			...params,
			value: [
				AsnContactIDs.getASN1Schema({ name: "seqContactIDs" })
			]
		});
	}

	/** Contacts welche zu schliessen sind, nur die IDs */
	public seqContactIDs!: AsnContactIDs;
}

// [PrintTSTypeDefCode]
export { AsnSetSettingsDynamicRightsArgument as AsnSettingsDynamicRightsChangedArgument };

// [PrintTSTypeDefCode]
export { AsnSetSettingsDynamicRightsArgument as AsnSettingsDynamicGroupRightsChangedArgument };

// [PrintTSTypeDefCode]
export { AsnGetSettingsDynamicRightsArgument as AsnGetSettingsDynamicGroupRightsArgument };

// [PrintTSTypeDefCode]
export { AsnGetSettingsDynamicRightsResult as AsnGetSettingsDynamicGroupRightsResult };

// [PrintTSTypeDefCode]
export { AsnGetSettingsDynamicRightsArgument as AsnGetSettingsGlobalDynamicRightsArgument };

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnPresenceState {
	public constructor(that: AsnPresenceState) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnPresenceState {
		return new AsnPresenceState({
			contactID: "",
			iPresenceState: 0,
			iContactFeatureSet: 0,
			asnRemoteContact: ENetUC_Common.AsnNetDatabaseContact.initEmpty(),
			iContactConnectionState: 0,
			u8sAdditionalConnectionInfo: ""
		});
	}

	public static type = "AsnPresenceState";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnPresenceState",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "contactID" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				new asn1ts.Integer({ name: "iContactFeatureSet" }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnRemoteContact" }),
				new asn1ts.Integer({ name: "iContactConnectionState" }),
				new asn1ts.Utf8String({ name: "u8sAdditionalConnectionInfo" }),
				ENetUC_Common_AsnContact.AsnContactPhoneLines.getASN1Schema({ name: "seqPhoneLines", idBlock: { optionalID: 0 } }),
				new asn1ts.Real({ name: "utcAbsentStartTime", idBlock: { optionalID: 1 } }),
				new asn1ts.Real({ name: "utcAbsentEndTime", idBlock: { optionalID: 2 } }),
				new asn1ts.Utf8String({ name: "u8sAbsentReason", idBlock: { optionalID: 3 } }),
				new asn1ts.Utf8String({ name: "u8sAbsentMessage", idBlock: { optionalID: 4 } }),
				new asn1ts.Utf8String({ name: "u8sNote", idBlock: { optionalID: 5 } }),
				ENetUC_Common_Appointments.SEQAppointmentList.getASN1Schema({ name: "appointmentEntryList", idBlock: { optionalID: 6 } }),
				ENetUC_Common_Appointments.AsnAppointmentEntry.getASN1Schema({ name: "asnNextAppointment", idBlock: { optionalID: 7 } })
			]
		});
	}

	public contactID!: string;
	/** PresenceState; defined in EContactConstants.h */
	public iPresenceState!: number;
	/** Set of features the user can access on this Contact */
	public iContactFeatureSet!: number;
	/**
	 * RemoteContact
	 * DetailInfo: AsnNetDatabaseContact ist Asn-Reprüsentation eines ENetDatabaseContacts
	 */
	public asnRemoteContact!: ENetUC_Common.AsnNetDatabaseContact;
	/**
	 * Verbindung zum Kontakt
	 * Zustand der Verbindung zum Kontakt; Für \"Federation\"-Kontakte wichtig
	 */
	public iContactConnectionState!: number;
	/** Mögliche, weitere Information zur Verbindung */
	public u8sAdditionalConnectionInfo!: string;
	/**
	 * Lines des Contacts
	 * State of the Phonelines of the Contact
	 */
	public seqPhoneLines?: ENetUC_Common_AsnContact.AsnContactPhoneLines;
	/**
	 * AbsentState
	 * Starttime of the Absentstate in UTC
	 */
	public utcAbsentStartTime?: Date;
	/** Endtime of the Absentstate in UTC */
	public utcAbsentEndTime?: Date;
	/** Short Text of the Absentreason */
	public u8sAbsentReason?: string;
	/** Message for the Absentstate */
	public u8sAbsentMessage?: string;
	/**
	 * Notiztext
	 * User Note
	 */
	public u8sNote?: string;
	/** Appointment */
	public appointmentEntryList?: ENetUC_Common_Appointments.SEQAppointmentList;
	public asnNextAppointment?: ENetUC_Common_Appointments.AsnAppointmentEntry;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactAbsentState {
	public constructor(that: AsnContactAbsentState) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactAbsentState {
		return new AsnContactAbsentState({
			contactID: "",
			iPresenceState: 0,
			utcAbsentStartTime: new Date(),
			utcAbsentEndTime: new Date(),
			u8sAbsentReason: "",
			u8sAbsentMessage: ""
		});
	}

	public static type = "AsnContactAbsentState";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactAbsentState",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "contactID" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				new asn1ts.Real({ name: "utcAbsentStartTime" }),
				new asn1ts.Real({ name: "utcAbsentEndTime" }),
				new asn1ts.Utf8String({ name: "u8sAbsentReason" }),
				new asn1ts.Utf8String({ name: "u8sAbsentMessage" })
			]
		});
	}

	public contactID!: string;
	/** PresenceState; defined in EContactConstats.h */
	public iPresenceState!: number;
	/** AbsentState */
	public utcAbsentStartTime!: Date;
	public utcAbsentEndTime!: Date;
	public u8sAbsentReason!: string;
	public u8sAbsentMessage!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactAppointmentState {
	public constructor(that: AsnContactAppointmentState) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactAppointmentState {
		return new AsnContactAppointmentState({
			contactID: "",
			iPresenceState: 0,
			appointmentEntryList: new ENetUC_Common_Appointments.SEQAppointmentList(),
			asnNextAppointment: ENetUC_Common_Appointments.AsnAppointmentEntry.initEmpty()
		});
	}

	public static type = "AsnContactAppointmentState";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactAppointmentState",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "contactID" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				ENetUC_Common_Appointments.SEQAppointmentList.getASN1Schema({ name: "appointmentEntryList" }),
				ENetUC_Common_Appointments.AsnAppointmentEntry.getASN1Schema({ name: "asnNextAppointment" })
			]
		});
	}

	public contactID!: string;
	/** PresenceState; defined in EContactConstats.h */
	public iPresenceState!: number;
	/** Appointment */
	public appointmentEntryList!: ENetUC_Common_Appointments.SEQAppointmentList;
	public asnNextAppointment!: ENetUC_Common_Appointments.AsnAppointmentEntry;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactCallInformationChangedArgument {
	public constructor(that: AsnContactCallInformationChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactCallInformationChangedArgument {
		return new AsnContactCallInformationChangedArgument({
			contactID: "",
			iPresenceState: 0,
			callinfo: ENetUC_Common_AsnContact.AsnContactCallInformation.initEmpty()
		});
	}

	public static type = "AsnContactCallInformationChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactCallInformationChangedArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "contactID" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				ENetUC_Common_AsnContact.AsnContactCallInformation.getASN1Schema({ name: "callinfo" })
			]
		});
	}

	/** KontaktID des Kontaktes, der diese Nachricht versendet */
	public contactID!: string;
	/** PresenceState; defined in EContactConstats.h */
	public iPresenceState!: number;
	public callinfo!: ENetUC_Common_AsnContact.AsnContactCallInformation;
}

// [PrintTSTypeDefCode]
export { AsnNotifyLineForwardStateArgument as AsnUpdateMyPhoneForwardStateArgument };

// [PrintTSTypeDefCode]
export { AsnNotifyLineForwardStateArgument as AsnContactPhoneForwardStateChangedArgument };

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnClientInfoList extends Array<AsnClientInfo> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnClientInfo.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnMyUpdateConnectedClientListArgument {
	public constructor(that: AsnMyUpdateConnectedClientListArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMyUpdateConnectedClientListArgument {
		return new AsnMyUpdateConnectedClientListArgument({
			listClientInfos: new AsnClientInfoList()
		});
	}

	public static type = "AsnMyUpdateConnectedClientListArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnMyUpdateConnectedClientListArgument",
			...params,
			value: [
				AsnClientInfoList.getASN1Schema({ name: "listClientInfos" })
			]
		});
	}

	public listClientInfos!: AsnClientInfoList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnAbsentStateGetUserArgument {
	public constructor(that: AsnAbsentStateGetUserArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateGetUserArgument {
		return new AsnAbsentStateGetUserArgument({
			contactId: ""
		});
	}

	public static type = "AsnAbsentStateGetUserArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAbsentStateGetUserArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "contactId" })
			]
		});
	}

	public contactId!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetMetaSettingsArgument {
	public constructor(that: AsnGetMetaSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetMetaSettingsArgument {
		return new AsnGetMetaSettingsArgument({
			eSendPlainPassword: AsnMetaDirectoryPasswordEncryption.iEncrypted
		});
	}

	public static type = "AsnGetMetaSettingsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetMetaSettingsArgument",
			...params,
			value: [
				new asn1ts.Enumerated({ name: "eSendPlainPassword" })
			]
		});
	}

	/** Soll das Passwort für das MetaDirectory verschlüsselt übermittelt werden 1: ja, 0(default): nein */
	public eSendPlainPassword!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnMetaDirectorySettings {
	public constructor(that: AsnMetaDirectorySettings) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMetaDirectorySettings {
		return new AsnMetaDirectorySettings({
			iMetaEnabled: 0,
			iMetaPort: 0,
			u8sMetaServer: "",
			u8sBaseDN: "",
			iMetaForceSSL: 0,
			iMetaUseAccount: 0,
			u8sMetaUser: "",
			ePasswordEncryption: AsnMetaDirectoryPasswordEncryption.iEncrypted,
			u8sMetaPassword: "",
			iMetaUseInServer: 0,
			iMetaUseInClient: 0
		});
	}

	public static type = "AsnMetaDirectorySettings";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnMetaDirectorySettings",
			...params,
			value: [
				new asn1ts.Integer({ name: "iMetaEnabled" }),
				new asn1ts.Integer({ name: "iMetaPort" }),
				new asn1ts.Utf8String({ name: "u8sMetaServer" }),
				new asn1ts.Utf8String({ name: "u8sBaseDN" }),
				new asn1ts.Integer({ name: "iMetaForceSSL" }),
				new asn1ts.Integer({ name: "iMetaUseAccount" }),
				new asn1ts.Utf8String({ name: "u8sMetaUser" }),
				new asn1ts.Enumerated({ name: "ePasswordEncryption" }),
				new asn1ts.Utf8String({ name: "u8sMetaPassword" }),
				new asn1ts.Integer({ name: "iMetaUseInServer" }),
				new asn1ts.Integer({ name: "iMetaUseInClient" })
			]
		});
	}

	/** Meta interface enabled? */
	public iMetaEnabled!: number;
	/** TCP Port */
	public iMetaPort!: number;
	/** Servername */
	public u8sMetaServer!: string;
	/** BaseDN */
	public u8sBaseDN!: string;
	/** Soll eine LDAPS-Verbindung aufgebaut werden */
	public iMetaForceSSL!: number;
	/** Soll User und Passwort für die Metaverbindung verwendet werden */
	public iMetaUseAccount!: number;
	/** Username am MetaDirectory */
	public u8sMetaUser!: string;
	/** Passwort ist verschlüsselt (0) oder Plaintext (1) */
	public ePasswordEncryption!: number;
	/** Passwort des Users am MetaDirectory */
	public u8sMetaPassword!: string;
	/** Use the meta with the CTI Server */
	public iMetaUseInServer!: number;
	/** Use the meta with the ProCall Client */
	public iMetaUseInClient!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetMetaSettingsArgument {
	public constructor(that: AsnSetMetaSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetMetaSettingsArgument {
		return new AsnSetMetaSettingsArgument({
			eSendPlainPassword: AsnMetaDirectoryPasswordEncryption.iEncrypted,
			metasettings: AsnMetaDirectorySettings.initEmpty()
		});
	}

	public static type = "AsnSetMetaSettingsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetMetaSettingsArgument",
			...params,
			value: [
				new asn1ts.Enumerated({ name: "eSendPlainPassword" }),
				AsnMetaDirectorySettings.getASN1Schema({ name: "metasettings" })
			]
		});
	}

	/** Soll das Passwort für das MetaDirectory verschlüsselt übermittelt werden 1: ja, 0(default): nein */
	public eSendPlainPassword!: number;
	public metasettings!: AsnMetaDirectorySettings;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnEMailAttachmentList extends Array<AsnEMailAttachment> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnEMailAttachment.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnEMailPartyList extends Array<AsnEMailParty> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnEMailParty.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnEMailBodyList extends Array<AsnEMailBody> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnEMailBody.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnEMail {
	public constructor(that: AsnEMail) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnEMail {
		return new AsnEMail({
			originatorParty: AsnEMailParty.initEmpty(),
			recipientParties: new AsnEMailPartyList(),
			u8sSubject: "",
			bodyList: new AsnEMailBodyList()
		});
	}

	public static type = "AsnEMail";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnEMail",
			...params,
			value: [
				AsnEMailParty.getASN1Schema({ name: "originatorParty" }),
				AsnEMailPartyList.getASN1Schema({ name: "recipientParties" }),
				new asn1ts.Utf8String({ name: "u8sSubject" }),
				AsnEMailBodyList.getASN1Schema({ name: "bodyList" }),
				AsnEMailAttachmentList.getASN1Schema({ name: "attachmentList", idBlock: { optionalID: 0 } }),
				AsnEMailPartyList.getASN1Schema({ name: "returnParties", idBlock: { optionalID: 1 } }),
				AsnEMailPartyList.getASN1Schema({ name: "carbonCopyParties", idBlock: { optionalID: 2 } }),
				AsnEMailPartyList.getASN1Schema({ name: "blindCarbonParties", idBlock: { optionalID: 3 } }),
				new asn1ts.Boolean({ name: "bSendNotification", idBlock: { optionalID: 4 } }),
				new asn1ts.Boolean({ name: "bReadNotification", idBlock: { optionalID: 5 } }),
				new asn1ts.Integer({ name: "iEmailImportance", idBlock: { optionalID: 6 } }),
				new asn1ts.Utf8String({ name: "u8sMessageID", idBlock: { optionalID: 7 } }),
				ENetUC_Common.AsnStringPairList.getASN1Schema({ name: "u8sMapOptionalHeaderElements", idBlock: { optionalID: 8 } })
			]
		});
	}

	public originatorParty!: AsnEMailParty;
	public recipientParties!: AsnEMailPartyList;
	public u8sSubject!: string;
	public bodyList!: AsnEMailBodyList;
	public attachmentList?: AsnEMailAttachmentList;
	public returnParties?: AsnEMailPartyList;
	public carbonCopyParties?: AsnEMailPartyList;
	public blindCarbonParties?: AsnEMailPartyList;
	public bSendNotification?: boolean;
	public bReadNotification?: boolean;
	/** EnumEmailImportance */
	public iEmailImportance?: number;
	public u8sMessageID?: string;
	public u8sMapOptionalHeaderElements?: ENetUC_Common.AsnStringPairList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnEMailList extends Array<AsnEMail> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnEMail.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSendClientSideMailArgument {
	public constructor(that: AsnSendClientSideMailArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendClientSideMailArgument {
		return new AsnSendClientSideMailArgument({
			mails: new AsnEMailList()
		});
	}

	public static type = "AsnSendClientSideMailArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSendClientSideMailArgument",
			...params,
			value: [
				AsnEMailList.getASN1Schema({ name: "mails" })
			]
		});
	}

	public mails!: AsnEMailList;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnDBPhoneBookList extends Array<AsnDBPhoneBook> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnDBPhoneBook.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSChoiceDefCode]
export class AsnFindDBContactsArgument {
	public constructor(that?: AsnFindDBContactsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnFindDBContactsArgument {
		return new AsnFindDBContactsArgument();
	}

	public static type = "AsnFindDBContactsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Choice {
		return new asn1ts.Choice({
			name: "AsnFindDBContactsArgument",
			...params,
			value: [
				AsnDatabaseFindOption.getASN1Schema({ name: "findoptions", idBlock: { optionalID: 0 } }),
				AsnDatabaseEntryID.getASN1Schema({ name: "entryid", idBlock: { optionalID: 1 } })
			]
		});
	}

	public findoptions?: AsnDatabaseFindOption;
	public entryid?: AsnDatabaseEntryID;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnFindDBContactsResult {
	public constructor(that: AsnFindDBContactsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnFindDBContactsResult {
		return new AsnFindDBContactsResult({
			contactList: new ENetUC_Common.AsnNetDatabaseContactList(),
			phonebookList: new AsnDBPhoneBookList()
		});
	}

	public static type = "AsnFindDBContactsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnFindDBContactsResult",
			...params,
			value: [
				ENetUC_Common.AsnNetDatabaseContactList.getASN1Schema({ name: "contactList" }),
				AsnDBPhoneBookList.getASN1Schema({ name: "phonebookList" })
			]
		});
	}

	public contactList!: ENetUC_Common.AsnNetDatabaseContactList;
	public phonebookList!: AsnDBPhoneBookList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnFindContactsArgument {
	public constructor(that: AsnFindContactsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnFindContactsArgument {
		return new AsnFindContactsArgument({
			findOptions: AsnNetDatabaseFindOptions.initEmpty()
		});
	}

	public static type = "AsnFindContactsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnFindContactsArgument",
			...params,
			value: [
				AsnNetDatabaseFindOptions.getASN1Schema({ name: "findOptions" })
			]
		});
	}

	public findOptions!: AsnNetDatabaseFindOptions;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnFindContactsResult {
	public constructor(that: AsnFindContactsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnFindContactsResult {
		return new AsnFindContactsResult({
			contactList: new ENetUC_Common.AsnNetDatabaseContactList(),
			phonebookList: new AsnDBPhoneBookList()
		});
	}

	public static type = "AsnFindContactsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnFindContactsResult",
			...params,
			value: [
				ENetUC_Common.AsnNetDatabaseContactList.getASN1Schema({ name: "contactList" }),
				AsnDBPhoneBookList.getASN1Schema({ name: "phonebookList" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	public contactList!: ENetUC_Common.AsnNetDatabaseContactList;
	public phonebookList!: AsnDBPhoneBookList;
	/**
	 * iPageOffset			INTEGER		Ab welchem Datensatz (inklusive) beginnen die Eregbnisse
	 * iPageSize			INTEGER		Wieviele Ergebnisse sind im Datensatz enthalten
	 * iTotalResultCount	INTEGER		Wieviele Ergebnisse sind insgesamt am Server erhältlich
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetPhoneBooksResult {
	public constructor(that: AsnGetPhoneBooksResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetPhoneBooksResult {
		return new AsnGetPhoneBooksResult({
			phonebooks: new AsnDBPhoneBookList()
		});
	}

	public static type = "AsnGetPhoneBooksResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetPhoneBooksResult",
			...params,
			value: [
				AsnDBPhoneBookList.getASN1Schema({ name: "phonebooks" })
			]
		});
	}

	public phonebooks!: AsnDBPhoneBookList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * !!!! DRAFT !!!! -&gt; not yet implemented
 * asnSetServerBasedClientSettingsEx OPERATION
 * ARGUMENT	arg AsnSetServerBasedClientSettingsExArgument
 * RESULT		res AsnSetServerBasedClientSettingsExResult
 * ERRORS		{AsnRequestError}
 * ::= 2200
 * Diese Schnittstelle dient zur Anforderung und übermittlung von Contact-Stati
 * für Suchlisten am Client.
 * **
 * ** Datatype for transport of ContactStatus Messages
 * **
 *
 * @private
 */
export class AsnContactStatusParameter {
	public constructor(that: AsnContactStatusParameter) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusParameter {
		return new AsnContactStatusParameter({
			typeOfValue: AsnContactStatusParameterEnum.activeContactID,
			u8sValue: ""
		});
	}

	public static type = "AsnContactStatusParameter";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactStatusParameter",
			...params,
			value: [
				new asn1ts.Enumerated({ name: "typeOfValue" }),
				new asn1ts.Utf8String({ name: "u8sValue" })
			]
		});
	}

	/** @private */
	public typeOfValue!: number;
	public u8sValue!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class SEQContactStatusParameter extends Array<AsnContactStatusParameter> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnContactStatusParameter.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactStatusRequestEntry {
	public constructor(that: AsnContactStatusRequestEntry) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusRequestEntry {
		return new AsnContactStatusRequestEntry({
			iClientSearchID: 0,
			iClientRowID: 0,
			listSearchParameter: new SEQContactStatusParameter(),
			bActivateNotify: false,
			listDeactivateNotify: new ENetUC_Common.SEQInteger()
		});
	}

	public static type = "AsnContactStatusRequestEntry";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactStatusRequestEntry",
			...params,
			value: [
				new asn1ts.Integer({ name: "iClientSearchID" }),
				new asn1ts.Integer({ name: "iClientRowID" }),
				SEQContactStatusParameter.getASN1Schema({ name: "listSearchParameter" }),
				new asn1ts.Boolean({ name: "bActivateNotify" }),
				ENetUC_Common.SEQInteger.getASN1Schema({ name: "listDeactivateNotify" })
			]
		});
	}

	/** ID der Suchanfrage um z.B. mehrere, gleichzeitige Suchfenster am Client unterscheiden zu können */
	public iClientSearchID!: number;
	/** ID der Zeile in der oben angegebenen Suchanfrage sollte bei 1 beginnen um 0 bei listDeactivateNotify verwenden zu können */
	public iClientRowID!: number;
	/** Liste der Suchparameter (email\/rufnummer\/entryid) */
	public listSearchParameter!: SEQContactStatusParameter;
	/** Sollen die Ergebnisse als Notifies registriert werden ? true = ja, false = nein (Not Null) */
	public bActivateNotify!: boolean;
	/** Liste von RowIDs, die von der Notifylist am Server gelöscht werden sollen (Not Null), muss mind. ein ASNInterger beinhalten */
	public listDeactivateNotify!: ENetUC_Common.SEQInteger;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class SEQContactStatusRequest extends Array<AsnContactStatusRequestEntry> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnContactStatusRequestEntry.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class SEQContactStatusResult extends Array<AsnContactStatusResultEntry> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnContactStatusResultEntry.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactStatusRequestArgument {
	public constructor(that: AsnContactStatusRequestArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusRequestArgument {
		return new AsnContactStatusRequestArgument({
			request: new SEQContactStatusRequest()
		});
	}

	public static type = "AsnContactStatusRequestArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactStatusRequestArgument",
			...params,
			value: [
				SEQContactStatusRequest.getASN1Schema({ name: "request" })
			]
		});
	}

	public request!: SEQContactStatusRequest;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnContactStatusRequestResult {
	public constructor(that: AsnContactStatusRequestResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusRequestResult {
		return new AsnContactStatusRequestResult({
			iResult: 0,
			iCount: 0,
			listStatusResult: new SEQContactStatusResult()
		});
	}

	public static type = "AsnContactStatusRequestResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactStatusRequestResult",
			...params,
			value: [
				new asn1ts.Integer({ name: "iResult" }),
				new asn1ts.Integer({ name: "iCount" }),
				SEQContactStatusResult.getASN1Schema({ name: "listStatusResult" })
			]
		});
	}

	public iResult!: number;
	public iCount!: number;
	public listStatusResult!: SEQContactStatusResult;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnPublicUserSetContactDataArgument {
	public constructor(that: AsnPublicUserSetContactDataArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnPublicUserSetContactDataArgument {
		return new AsnPublicUserSetContactDataArgument({
			contactData: AsnPublicUserContactData.initEmpty()
		});
	}

	public static type = "AsnPublicUserSetContactDataArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnPublicUserSetContactDataArgument",
			...params,
			value: [
				AsnPublicUserContactData.getASN1Schema({ name: "contactData" })
			]
		});
	}

	public contactData!: AsnPublicUserContactData;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnSMSMessages extends Array<AsnSMSMessage> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnSMSMessage.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSendSMSArgument {
	public constructor(that: AsnSendSMSArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendSMSArgument {
		return new AsnSendSMSArgument({
			messages: new AsnSMSMessages()
		});
	}

	public static type = "AsnSendSMSArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSendSMSArgument",
			...params,
			value: [
				AsnSMSMessages.getASN1Schema({ name: "messages" })
			]
		});
	}

	/** Liste der SMS die verschickt werden sollen */
	public messages!: AsnSMSMessages;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSMSStatusNotifyArgument {
	public constructor(that: AsnSMSStatusNotifyArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSMSStatusNotifyArgument {
		return new AsnSMSStatusNotifyArgument({
			u8sMonitorCrossRefID: "",
			status: AsnSMSStatus.eSMSSTATUSSUCCESS,
			u8sErrReason: ""
		});
	}

	public static type = "AsnSMSStatusNotifyArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSMSStatusNotifyArgument",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sMonitorCrossRefID" }),
				new asn1ts.Enumerated({ name: "status" }),
				new asn1ts.Utf8String({ name: "u8sErrReason" })
			]
		});
	}

	public u8sMonitorCrossRefID!: string;
	public status!: number;
	/** Bei eSMSSTATUSERROR kann hier noch ein Grund stehen (logging) */
	public u8sErrReason!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnUserDefinedCustomFields extends Array<AsnUserDefinedCustomField> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnUserDefinedCustomField.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetUserdefinedCustomFieldsResult {
	public constructor(that: AsnGetUserdefinedCustomFieldsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetUserdefinedCustomFieldsResult {
		return new AsnGetUserdefinedCustomFieldsResult({
			customfields: new AsnUserDefinedCustomFields()
		});
	}

	public static type = "AsnGetUserdefinedCustomFieldsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetUserdefinedCustomFieldsResult",
			...params,
			value: [
				AsnUserDefinedCustomFields.getASN1Schema({ name: "customfields" })
			]
		});
	}

	public customfields!: AsnUserDefinedCustomFields;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnOpenContactsArgument {
	public constructor(that: AsnOpenContactsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOpenContactsArgument {
		return new AsnOpenContactsArgument({
			seqContactIDs: new AsnContactIDs()
		});
	}

	public static type = "AsnOpenContactsArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnOpenContactsArgument",
			...params,
			value: [
				AsnContactIDs.getASN1Schema({ name: "seqContactIDs" }),
				new asn1ts.Boolean({ name: "bOpenOnlyInternalContacts", idBlock: { optionalID: 0 } }),
				new asn1ts.Boolean({ name: "bDontRequestPermissions", idBlock: { optionalID: 1 } }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 3 } })
			]
		});
	}

	/** Contacts welche zu holen sind; wenn liste leer dann alle */
	public seqContactIDs!: AsnContactIDs;
	/** Nur die Kontakte öffnen, die der entsprechende Server selbst verwaltet */
	public bOpenOnlyInternalContacts?: boolean;
	/** Keine Berechtigungsanfrage starten */
	public bDontRequestPermissions?: boolean;
	/** optionalParams Name \"CompletedEventID\" Value string - requests an asnOperationComplete as soon as all status events were dispatched */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetMetaSettingsResult {
	public constructor(that: AsnGetMetaSettingsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetMetaSettingsResult {
		return new AsnGetMetaSettingsResult({
			metasettings: AsnMetaDirectorySettings.initEmpty()
		});
	}

	public static type = "AsnGetMetaSettingsResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetMetaSettingsResult",
			...params,
			value: [
				AsnMetaDirectorySettings.getASN1Schema({ name: "metasettings" })
			]
		});
	}

	public metasettings!: AsnMetaDirectorySettings;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnAbsentStates extends Array<ENetUC_Common_AsnContact.AsnAbsentState> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: new asn1ts.SequenceOf({ value: ENetUC_Common_AsnContact.AsnAbsentState.getASN1Schema() })
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetPreferredContactRefResult {
	public constructor(that?: AsnSetPreferredContactRefResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetPreferredContactRefResult {
		return new AsnSetPreferredContactRefResult();
	}

	public static type = "AsnSetPreferredContactRefResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetPreferredContactRefResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetServerBasedClientSettingsExArgument {
	public constructor(that?: AsnGetServerBasedClientSettingsExArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetServerBasedClientSettingsExArgument {
		return new AsnGetServerBasedClientSettingsExArgument();
	}

	public static type = "AsnGetServerBasedClientSettingsExArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetServerBasedClientSettingsExArgument",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnGetServerBasedClientSettingsExResult {
	public constructor(that: AsnGetServerBasedClientSettingsExResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetServerBasedClientSettingsExResult {
		return new AsnGetServerBasedClientSettingsExResult({
			clientSettings: AsnServerBasedClientSettingsEx.initEmpty()
		});
	}

	public static type = "AsnGetServerBasedClientSettingsExResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetServerBasedClientSettingsExResult",
			...params,
			value: [
				AsnServerBasedClientSettingsEx.getASN1Schema({ name: "clientSettings" })
			]
		});
	}

	public clientSettings!: AsnServerBasedClientSettingsEx;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * !!!! DRAFT !!!! -&gt; not yet implemented
 * asnGetServerBasedClientSettingsEx OPERATION
 * ARGUMENT	arg AsnGetServerBasedClientSettingsExArgument
 * RESULT		res AsnGetServerBasedClientSettingsExResult
 * ERRORS		{AsnRequestError}
 * ::= 2199
 *
 * @private
 */
export class AsnSetServerBasedClientSettingsExArgument {
	public constructor(that: AsnSetServerBasedClientSettingsExArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetServerBasedClientSettingsExArgument {
		return new AsnSetServerBasedClientSettingsExArgument({
			clientSettings: AsnServerBasedClientSettingsEx.initEmpty()
		});
	}

	public static type = "AsnSetServerBasedClientSettingsExArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetServerBasedClientSettingsExArgument",
			...params,
			value: [
				AsnServerBasedClientSettingsEx.getASN1Schema({ name: "clientSettings" })
			]
		});
	}

	public clientSettings!: AsnServerBasedClientSettingsEx;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * @private
 */
export class AsnSetServerBasedClientSettingsExResult {
	public constructor(that?: AsnSetServerBasedClientSettingsExResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetServerBasedClientSettingsExResult {
		return new AsnSetServerBasedClientSettingsExResult();
	}

	public static type = "AsnSetServerBasedClientSettingsExResult";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetServerBasedClientSettingsExResult",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * wird nur zwischen 2 ucservern ueber redis verschickt
 *
 * @private
 */
export class AsnRedisRemoteInvoke {
	public constructor(that: AsnRedisRemoteInvoke) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRedisRemoteInvoke {
		return new AsnRedisRemoteInvoke({
			u8sInvokeID: "",
			u8sInvoker: "",
			u8sInvokingServer: "",
			u8sInvokingConnection: "",
			u8sOp: "",
			u8sArg: ""
		});
	}

	public static type = "AsnRedisRemoteInvoke";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnRedisRemoteInvoke",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sInvokeID" }),
				new asn1ts.Utf8String({ name: "u8sInvoker" }),
				new asn1ts.Utf8String({ name: "u8sInvokingServer" }),
				new asn1ts.Utf8String({ name: "u8sInvokingConnection" }),
				new asn1ts.Utf8String({ name: "u8sOp" }),
				new asn1ts.Utf8String({ name: "u8sArg" })
			]
		});
	}

	public u8sInvokeID!: string;
	public u8sInvoker!: string;
	public u8sInvokingServer!: string;
	public u8sInvokingConnection!: string;
	public u8sOp!: string;
	public u8sArg!: string;
}
