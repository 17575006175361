import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Checkbox, FormControlLabel, Paper, Stack, Unstable_Grid2 as Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";

import { Button } from "../../components/common/Button/Button";
import { TextField } from "../../components/common/TextField/TextField";
import { Typography } from "../../components/common/Typography/Typography";
import { theUCClient } from "../../globals";
import { black87 } from "../../theme/theme";
import { useStore } from "../../zustand/store";
import styles from "./Login.module.scss";

interface IFormInputs {
	username: string;
	password: string;
	connectionString: string;
	stayLogged: boolean;
	isUsingUCConnect: boolean;
}

export const Login = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const location = useLocation();
	const [hasError, setHasError] = useState(false);

	const setMySelfStayLogged = useStore((state) => state.setMySelfStayLogged);
	const setMySelfToken = useStore((state) => state.setMySelfToken);

	const from = location.state?.from?.pathname || "/";

	const schema = useMemo(
		() =>
			object({
				username: string().required(t("IDS_LOGIN_USERNAME_HINT") || ""),
				password: string().required(t("IDS_LOGIN_PASSWORD_HINT") || ""),
				connectionString: string().required(t("IDS_LOGIN_UCSID_HINT") || "")
			}).defined(),
		[t]
	);

	const {
		handleSubmit,
		control,
		formState: { isSubmitting }
	} = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		defaultValues: {
			username: localStorage.getItem("last-username-string") || "",
			password: "",
			connectionString: localStorage.getItem("last-connection-string") || undefined,
			isUsingUCConnect: true,
			stayLogged: false
		}
	});

	const login = async ({ username, password, stayLogged, isUsingUCConnect, connectionString }: IFormInputs) => {
		const [ucsid, ucserveruri] = isUsingUCConnect ? [connectionString, undefined] : [undefined, connectionString];

		const token = await theUCClient.connectStandAlone(
			ucsid?.trim(),
			ucserveruri?.trim(),
			undefined,
			username.trim(),
			password
		);

		if (!token) {
			setHasError(true);
			return;
		}

		if (stayLogged) {
			setMySelfStayLogged(true);
			setMySelfToken(token);
		}
		localStorage.setItem("last-connection-string", connectionString);
		localStorage.setItem("last-username-string", username);
		setHasError(false);
		navigate(from, { replace: true });
	};

	const onSubmit = async (data: IFormInputs) => {
		await login(data);
	};

	const isUsingUCConnect = useWatch({
		control,
		name: "isUsingUCConnect"
	});

	const connectionStringLabel = isUsingUCConnect ? t("IDS_LOGIN_UCCONNECT_ID") : t("IDS_LOGIN_UCSERVER_URL");

	return (
		<Box className={styles.loginBackground}>
			<Stack sx={{ alignItems: "center", justifyContent: "center", flex: "1 1 100%" }}>
				<Paper sx={{ borderRadius: "22px", p: 3, m: 2, minHeight: "500px" }}>
					<Stack justifyContent={"center"} height="100%">
						<Grid container spacing={3}>
							<Grid xs={12} md={6}>
								<Stack justifyContent={"center"} height={"100%"}>
									<Typography variant="h1" textAlign={"center"}>
										{t("IDS_WELCOME_UCMSTEAMSAPP")}
									</Typography>
									<Typography variant="body1" textAlign={"center"} mx={4} mt={1}>
										{t("IDS_WELCOME_UCMSTEAMSAPP_SUBTITLE")}
									</Typography>
								</Stack>
							</Grid>
							<Grid xs={12} md={6}>
								<Typography variant="body2" color={black87} fontWeight={600} mb={2}>
									{t("IDS_LOGIN_ACCOUNT_REQUEST")}
								</Typography>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Stack direction="column" spacing={1}>
										<Box>
											<Controller
												render={({ field, fieldState }) => (
													<TextField
														label={t("IDS_LOGIN_USERNAME")}
														autoComplete="username"
														fieldError={fieldState.error}
														{...field}
													/>
												)}
												name="username"
												control={control}
											/>
											<Controller
												render={({ field, fieldState }) => (
													<TextField
														label={t("IDS_LOGIN_PASSWORD")}
														type="password"
														autoComplete="current-password"
														fieldError={fieldState.error}
														{...field}
													/>
												)}
												name="password"
												control={control}
											/>
											<Controller
												render={({ field: { value, ...restField } }) => (
													<FormControlLabel
														id="isUsingUCConnect"
														sx={{ pb: 1 }}
														control={<Checkbox checked={value} {...restField} />}
														label={t("IDS_LOGIN_UCCONNECT_USE")}
													/>
												)}
												name="isUsingUCConnect"
												control={control}
											/>
											<Controller
												render={({ field, fieldState }) => (
													<TextField label={connectionStringLabel} fieldError={fieldState.error} {...field} />
												)}
												name="connectionString"
												control={control}
											/>
											<Controller
												render={({ field: { value, ...restField } }) => (
													<FormControlLabel
														id="stayLogged"
														control={<Checkbox checked={value} {...restField} />}
														label={t("IDS_STAY_LOGGED_IN")}
													/>
												)}
												name="stayLogged"
												control={control}
											/>
										</Box>
										<Button disabled={isSubmitting} onClick={handleSubmit(onSubmit)} type="submit">
											{t("IDS_LOGIN_BUTTON")}
										</Button>
										{hasError && (
											<Typography variant="body1" sx={{ color: theme.palette.error.main }}>
												{t("IDS_LOGIN_ERROR")}
											</Typography>
										)}
									</Stack>
								</form>
							</Grid>
						</Grid>
					</Stack>
				</Paper>
			</Stack>
		</Box>
	);
};
