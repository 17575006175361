import { Stack } from "@mui/material";
import { Suspense, useEffect, useRef, useState } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

import { AsnRequestError } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { ErrorBanner } from "../components/ErrorBanner/ErrorBanner";
import { theUCClient } from "../globals";
import { Dashboard } from "../pages/Dashboard";
import { Login } from "../pages/Login/Login";
import { IUCServerInfo } from "../session/UCClient";
import { getState, useStore } from "../zustand/store";
import { LoginRouteWrapper } from "./LoginRouteWrapper";
import { ProtectedRoute } from "./ProtectedRoute";

const router = createBrowserRouter([
	{
		path: "/login",
		element: (
			<LoginRouteWrapper>
				<Login />
			</LoginRouteWrapper>
		)
	},
	{
		path: "/dashboard",
		element: (
			<ProtectedRoute>
				<Dashboard />
			</ProtectedRoute>
		)
	},
	{
		path: "/",
		element: (
			<ProtectedRoute>
				<Navigate replace to="/dashboard" />
			</ProtectedRoute>
		)
	}
]);

/**
 * Router
 *
 * @returns Router
 */
export const Router = () => {
	const [loading, setLoading] = useState(true);
	const loggedIn = useStore((state) => state.loggedIn);
	const token = useStore((state) => state.token);
	const ucsid = useStore((state) => state.ucsid);
	const ucserveruri = useStore((state) => state.ucserveruri);
	const stayLogged = useStore((state) => state.stayLogged);
	const setMySelfIsLogged = useStore((state) => state.setMySelfIsLogged);

	const isConnected = useRef(false);

	useEffect(() => {
		if (isConnected.current) {
			return;
		}
		setLoading(true);
		const login = async (token: string, ucsid: string | undefined, ucserveruri: string | undefined) => {
			await theUCClient.connectStandAlone(ucsid, ucserveruri, token, undefined, undefined);
		};

		// If the token is set, and we wanted to stay logged in the previous login, try to use the token to login
		if (token && stayLogged) {
			login(token, ucsid, ucserveruri)
				.then(() => {})
				.finally(() => {
					setLoading(false);
				});
		} else {
			setLoading(false);
			setMySelfIsLogged(false);
		}

		isConnected.current = true;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loggedIn, token]);

	if (loading) {
		return null;
	}

	return (
		<Suspense fallback={<div />}>
			<Stack height="100%">
				<ErrorBanner spinner showCloseIcon />
				<RouterProvider router={router} />
			</Stack>
		</Suspense>
	);
};
