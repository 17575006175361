/* eslint-disable */
import {
	FnCreateEmptyContentPic,
	FnCreateEmptyStatusInfos,
	IContentDetails,
	IContentPic,
	IDynamicStatusInfosCD,
	IEditorContentDummyData,
	IKeyValuePair,
	U8sAllowedNamesMap
} from "../models/ModelContent";
import { AsnNetDatabaseContact, AsnOptionalParam, AsnStringPairList } from "../ucserver/stubs/ENetUC_Common";
import { AsnReachabilityEnum } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import { AsnAgentStateEnum } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Agent";

/**
 * Combines incoming u8sContactDetails with the status infos to a ContentDetails object.
 *
 * @param entryContentJson - incoming AsnNetDatabaseContact as JSON
 * @returns an IContentDetails object.
 */
export const ConstrContentDetailsFromJson = (
	entryContentJson: AsnNetDatabaseContact | IEditorContentDummyData
): IContentDetails => {
	// -- u8sDetails --
	const u8sDetails: Map<string, string> = new Map<string, string>();

	let content: keyof AsnNetDatabaseContact;
	for (content in entryContentJson) {
		let key = entryContentJson[content];
		if (
			key !== "optionalParams" &&
			key !== "statusInfos" &&
			key !== "customFields" &&
			U8sAllowedNamesMap.get(content) === true
		)
			u8sDetails.set(content, key as string);
	}

	// -- contentPic --
	const optParams: AsnOptionalParam[] | undefined = entryContentJson.optionalParams as AsnOptionalParam[];

	const contentPic: IContentPic = FnCreateEmptyContentPic();

	if (optParams) {
		for (const [key, param] of Object.entries(optParams)) {
			if (key === "jpegPhoto") {
				for (const [innerkey, innerValue] of Object.entries(param)) {
					if (innerkey === "binarydata") contentPic.jpegPhoto = innerValue;
				}
			}
			if (key === "jpegPhotoHash") {
				for (const [innerkey, innerValue] of Object.entries(param)) {
					if (innerkey === "binarydata") contentPic.jpegPhotoHash = innerValue;
				}
			}
		}
	}

	// -- statusInfos --
	let statusInfos: IDynamicStatusInfosCD = FnCreateEmptyStatusInfos();

	const entryDummyData: IEditorContentDummyData = entryContentJson as IEditorContentDummyData;

	if (entryDummyData.statusInfos) {
		statusInfos = {
			presence: entryDummyData.statusInfos.presence ? entryDummyData.statusInfos.presence : 0,
			u8sNote: entryDummyData.statusInfos.asnCustomNote ? entryDummyData.statusInfos.asnCustomNote : "",
			remoteContact: { outbound: 0, displayName: "" },
			nextAppointment: entryDummyData.statusInfos.asnNextAppointment,
			currentAppointments: entryDummyData.statusInfos.currentAppointments,
			isMobileAvailable: false,
			reachability: entryDummyData.statusInfos.reachability ?? AsnReachabilityEnum.eREACHABILITYNORMAL,
			agentState: entryDummyData.statusInfos.agentState ?? AsnAgentStateEnum.eAGENTSTATENOAGENT,
			msTeamsEmail: entryDummyData.statusInfos.msTeamsEmail,
			seqCalls: entryDummyData.statusInfos.seqCalls,
			seqPhoneLines: entryDummyData.statusInfos.seqPhoneLines,
			seqLineForwards: entryDummyData.statusInfos.seqLineForwards
		};
	}

	// -- customfields && contactUrls --
	const entryCustomFields: AsnStringPairList | undefined = entryContentJson.customFields;
	const customFields: IKeyValuePair[] = [];
	const contactURLs: IKeyValuePair[] = [];

	if (entryCustomFields && entryCustomFields.length && entryCustomFields.length > 0) {
		for (let i = 0; i < entryCustomFields.length; i++) {
			if (entryCustomFields[i].key.startsWith("Custom"))
				customFields.push({ key: entryCustomFields[i].key, value: entryCustomFields[i].value });
			else if (entryCustomFields[i].key.startsWith("ContactURL"))
				contactURLs.push({ key: entryCustomFields[i].key, value: entryCustomFields[i].value });
		}
	}

	const contentDetails: IContentDetails = {
		contentPic,
		u8sDetails,
		statusInfos,
		customFields,
		contactURLs
	};

	return contentDetails;
};
