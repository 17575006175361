import { faPhone, faVideo } from "@fortawesome/pro-light-svg-icons";
import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { IKeyValuePair } from "../../../web-shared-components/interfaces/IKeyValuePair";
import { getPhoneNumbersFromAsnContact } from "../../../web-shared-components/react-components/contactDetailsComponent/processing/ContactDetailsHelper";
import { CallOptionsMenu } from "../../components/CallOptionsMenu/CallOptionsMenu";
import { IQuickActionItem, QuickActions } from "../../components/common/QuickActions/QuickActions";
import { checkUserHasVideoChat, removeSipPrefixIfExists } from "../../lib/commonHelper";
import { IClientContent } from "../../zustand/contactSlice";
import { useStore } from "../../zustand/store";

interface IContactDetailsHeaderActionsProps {
	contact?: IClientContent;
}

export const ContactDetailsHeaderActions = ({ contact }: IContactDetailsHeaderActionsProps) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const contacts = useStore((state) => state.contactsPresence);
	const statusInfos = contacts.get(contact?.u8sSIPAddress ?? "");

	const contactDetails = useStore((state) => state.contactDetails);
	const customLabels = useStore((state) => state.customLabels);

	const phoneNumbers = useMemo((): IKeyValuePair[] => {
		if (!contact) {
			return [];
		}
		return getPhoneNumbersFromAsnContact(contact, customLabels.CustLabels, contactDetails.ContactDetails);
	}, [contact, contactDetails.ContactDetails, customLabels.CustLabels]);

	const quickActions: IQuickActionItem[] = useMemo(() => {
		return [
			{
				icon: faPhone,
				tooltip: t("IDS_CONTACT_DETAILS_QUICK_CALL"),
				disabled: !checkUserHasVideoChat(statusInfos) && !phoneNumbers.length,
				onClick: (event: React.MouseEvent<HTMLButtonElement | HTMLElement>) => {
					event.stopPropagation();
					event.preventDefault();
					setAnchorEl(event.currentTarget);
				}
			},
			{
				icon: faVideo,
				tooltip: t("IDS_CONTACT_DETAILS_QUICK_VIDEO"),
				disabled: !checkUserHasVideoChat(statusInfos),
				onClick: () => {
					window.open(`eucc-video:${removeSipPrefixIfExists(contact?.u8sSIPAddress)}`, "_self");
				}
			}
		];
	}, [t, statusInfos, phoneNumbers.length, contact?.u8sSIPAddress]);

	const handleClose = () => {
		setAnchorEl(null);
	};

	if (!contact) {
		return null;
	}

	return (
		<Box ml={2}>
			<QuickActions items={quickActions} />
			<CallOptionsMenu contact={contact} anchorEl={anchorEl} onClose={handleClose} />
		</Box>
	);
};
