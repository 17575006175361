// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_ClientCapabilities_Converter.ts
 * "UC-Server-Access-Protocol-ClientCapabilities" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_ClientCapabilities from "./ENetUC_ClientCapabilities";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";

// [PrintTSEncoderDecoderCode] AsnClientCapabilitiesV2
export class AsnClientCapabilitiesV2_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientCapabilitiesV2
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientCapabilitiesV2", newContext);
		value += TSConverter.addObjectAsStringParam("eClientCapabilities", ENetUC_Common_Converter.SEQInteger_Converter.toJSON(obj.eClientCapabilities, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("customCapabilities", ENetUC_Common_Converter.AsnStringPairList_Converter.toJSON(obj.customCapabilities, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientCapabilitiesV2");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesV2"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnClientCapabilitiesV2;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientCapabilitiesV2
			const tscv_eclientcapabilities = new ENetUC_Common.SEQInteger();
			if (ENetUC_Common_Converter.SEQInteger_Converter.fromJSON(data.eClientCapabilities, tscv_eclientcapabilities, errors, newContext, "eClientCapabilities", false))
				obj.eClientCapabilities = tscv_eclientcapabilities;
			const tscv_customcapabilities = new ENetUC_Common.AsnStringPairList();
			if (ENetUC_Common_Converter.AsnStringPairList_Converter.fromJSON(data.customCapabilities, tscv_customcapabilities, errors, newContext, "customCapabilities", false))
				obj.customCapabilities = tscv_customcapabilities;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesV2"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetClientCapabilitiesV2Result
export class AsnSetClientCapabilitiesV2Result_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetClientCapabilitiesV2Result
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetClientCapabilitiesV2Result", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetClientCapabilitiesV2Result");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientCapabilitiesV2Result"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Result;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetClientCapabilitiesV2Result
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientCapabilitiesV2Result"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetClientCapabilitiesV2Argument
export class AsnGetClientCapabilitiesV2Argument_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetClientCapabilitiesV2Argument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetClientCapabilitiesV2Argument", newContext);
		value += TSConverter.addObjectAsStringParam("u8slistContactIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistContactIDs, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetClientCapabilitiesV2Argument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientCapabilitiesV2Argument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Argument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetClientCapabilitiesV2Argument
			const tscv_u8slistcontactids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistContactIDs, tscv_u8slistcontactids, errors, newContext, "u8slistContactIDs", false))
				obj.u8slistContactIDs = tscv_u8slistcontactids;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientCapabilitiesV2Argument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetClientCapabilitiesArgument
export class AsnSetClientCapabilitiesArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetClientCapabilitiesArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetClientCapabilitiesArgument", newContext);
		value += TSConverter.addStringParam("osXMLContent", obj.osXMLContent, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetClientCapabilitiesArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientCapabilitiesArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnSetClientCapabilitiesArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetClientCapabilitiesArgument
			if (TSConverter.validateParam(data, "osXMLContent", "string", errors, newContext, false))
				obj.osXMLContent = data.osXMLContent;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientCapabilitiesArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientCapabilities
export class AsnClientCapabilities_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnClientCapabilities, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientCapabilities
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientCapabilities", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addStringParam("osXMLContent", obj.osXMLContent, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnClientCapabilities, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientCapabilities");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilities"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnClientCapabilities;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientCapabilities
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			if (TSConverter.validateParam(data, "osXMLContent", "string", errors, newContext, false))
				obj.osXMLContent = data.osXMLContent;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilities"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetClientCapabilitiesArgument
export class AsnGetClientCapabilitiesArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetClientCapabilitiesArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetClientCapabilitiesArgument", newContext);
		value += TSConverter.addObjectAsStringParam("u8slistContactIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistContactIDs, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetClientCapabilitiesArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientCapabilitiesArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnGetClientCapabilitiesArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetClientCapabilitiesArgument
			const tscv_u8slistcontactids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistContactIDs, tscv_u8slistcontactids, errors, newContext, "u8slistContactIDs", false))
				obj.u8slistContactIDs = tscv_u8slistcontactids;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientCapabilitiesArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientCapabilitiesEntry
export class AsnClientCapabilitiesEntry_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnClientCapabilitiesEntry, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientCapabilitiesEntry
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientCapabilitiesEntry", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addStringParam("osXMLContent", obj.osXMLContent, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnClientCapabilitiesEntry, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientCapabilitiesEntry");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesEntry"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnClientCapabilitiesEntry;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientCapabilitiesEntry
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			if (TSConverter.validateParam(data, "osXMLContent", "string", errors, newContext, false))
				obj.osXMLContent = data.osXMLContent;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesEntry"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientCapabilitiesV2ByID
export class AsnClientCapabilitiesV2ByID_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientCapabilitiesV2ByID
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientCapabilitiesV2ByID", newContext);
		value += TSConverter.addStringParam("u8sID", obj.u8sID, newContext);
		value += TSConverter.addObjectAsStringParam("capabilities", AsnClientCapabilitiesV2_Converter.toJSON(obj.capabilities, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientCapabilitiesV2ByID");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesV2ByID"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientCapabilitiesV2ByID
			if (TSConverter.validateParam(data, "u8sID", "string", errors, newContext, false))
				obj.u8sID = data.u8sID;
			const tscv_capabilities = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2.initEmpty();
			if (AsnClientCapabilitiesV2_Converter.fromJSON(data.capabilities, tscv_capabilities, errors, newContext, "capabilities", false))
				obj.capabilities = tscv_capabilities;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesV2ByID"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientCapabilitiesV2ByIDList
export class AsnClientCapabilitiesV2ByIDList_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnClientCapabilitiesV2ByIDList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnClientCapabilitiesV2ByID_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientCapabilitiesV2ByIDList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesV2ByIDList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnClientCapabilitiesV2ByIDList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByID.initEmpty();
				if (AsnClientCapabilitiesV2ByID_Converter.fromJSON(elem, entry, errors, newContext, "AsnClientCapabilitiesV2ByID", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesV2ByIDList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetClientCapabilitiesV2Argument
export class AsnSetClientCapabilitiesV2Argument_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetClientCapabilitiesV2Argument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetClientCapabilitiesV2Argument", newContext);
		value += TSConverter.addObjectAsStringParam("capabilities", AsnClientCapabilitiesV2_Converter.toJSON(obj.capabilities, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetClientCapabilitiesV2Argument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientCapabilitiesV2Argument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnSetClientCapabilitiesV2Argument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetClientCapabilitiesV2Argument
			const tscv_capabilities = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2.initEmpty();
			if (AsnClientCapabilitiesV2_Converter.fromJSON(data.capabilities, tscv_capabilities, errors, newContext, "capabilities", false))
				obj.capabilities = tscv_capabilities;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientCapabilitiesV2Argument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetClientCapabilitiesV2Result
export class AsnGetClientCapabilitiesV2Result_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetClientCapabilitiesV2Result
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetClientCapabilitiesV2Result", newContext);
		value += TSConverter.addObjectAsStringParam("capabilities", AsnClientCapabilitiesV2ByIDList_Converter.toJSON(obj.capabilities, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetClientCapabilitiesV2Result");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientCapabilitiesV2Result"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnGetClientCapabilitiesV2Result;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetClientCapabilitiesV2Result
			const tscv_capabilities = new ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList();
			if (AsnClientCapabilitiesV2ByIDList_Converter.fromJSON(data.capabilities, tscv_capabilities, errors, newContext, "capabilities", false))
				obj.capabilities = tscv_capabilities;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientCapabilitiesV2Result"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientCapabilitiesV2ChangedArgument
export class AsnClientCapabilitiesV2ChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientCapabilitiesV2ChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientCapabilitiesV2ChangedArgument", newContext);
		value += TSConverter.addObjectAsStringParam("capabilities", AsnClientCapabilitiesV2ByIDList_Converter.toJSON(obj.capabilities, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientCapabilitiesV2ChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesV2ChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientCapabilitiesV2ChangedArgument
			const tscv_capabilities = new ENetUC_ClientCapabilities.AsnClientCapabilitiesV2ByIDList();
			if (AsnClientCapabilitiesV2ByIDList_Converter.fromJSON(data.capabilities, tscv_capabilities, errors, newContext, "capabilities", false))
				obj.capabilities = tscv_capabilities;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesV2ChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientCapabilitiesList
export class AsnClientCapabilitiesList_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnClientCapabilitiesList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnClientCapabilitiesList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnClientCapabilities_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnClientCapabilitiesList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientCapabilitiesList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnClientCapabilitiesList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnClientCapabilitiesList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientCapabilities.AsnClientCapabilities.initEmpty();
				if (AsnClientCapabilities_Converter.fromJSON(elem, entry, errors, newContext, "AsnClientCapabilities", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientCapabilitiesChangedArgument
export class AsnClientCapabilitiesChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnClientCapabilitiesChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientCapabilitiesChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientCapabilitiesChangedArgument", newContext);
		value += TSConverter.addObjectAsStringParam("listCapabilities", AsnClientCapabilitiesList_Converter.toJSON(obj.listCapabilities, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnClientCapabilitiesChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientCapabilitiesChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnClientCapabilitiesChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientCapabilitiesChangedArgument
			const tscv_listcapabilities = new ENetUC_ClientCapabilities.AsnClientCapabilitiesList();
			if (AsnClientCapabilitiesList_Converter.fromJSON(data.listCapabilities, tscv_listcapabilities, errors, newContext, "listCapabilities", false))
				obj.listCapabilities = tscv_listcapabilities;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientCapabilitiesEntryList
export class AsnClientCapabilitiesEntryList_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnClientCapabilitiesEntryList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnClientCapabilitiesEntryList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnClientCapabilitiesEntry_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnClientCapabilitiesEntryList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientCapabilitiesEntryList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesEntryList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnClientCapabilitiesEntryList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnClientCapabilitiesEntryList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientCapabilities.AsnClientCapabilitiesEntry.initEmpty();
				if (AsnClientCapabilitiesEntry_Converter.fromJSON(elem, entry, errors, newContext, "AsnClientCapabilitiesEntry", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientCapabilitiesEntryList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetClientCapabilitiesResult
export class AsnGetClientCapabilitiesResult_Converter {
	public static toJSON(obj?: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetClientCapabilitiesResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetClientCapabilitiesResult", newContext);
		value += TSConverter.addObjectAsStringParam("listClientCapabilities", AsnClientCapabilitiesEntryList_Converter.toJSON(obj.listClientCapabilities, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientCapabilities.AsnGetClientCapabilitiesResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetClientCapabilitiesResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientCapabilitiesResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientCapabilities.AsnGetClientCapabilitiesResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetClientCapabilitiesResult
			const tscv_listclientcapabilities = new ENetUC_ClientCapabilities.AsnClientCapabilitiesEntryList();
			if (AsnClientCapabilitiesEntryList_Converter.fromJSON(data.listClientCapabilities, tscv_listclientcapabilities, errors, newContext, "listClientCapabilities", false))
				obj.listClientCapabilities = tscv_listclientcapabilities;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientCapabilitiesResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
