import { faArrowLeft, faArrowRight, faExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AsnNetDatabaseJournal } from "../../../ucserver/stubs/ENetUC_Journal";

interface ICallOriginIconProps {
	journalEntry: AsnNetDatabaseJournal;
}

export const CallOriginIcon = ({ journalEntry }: ICallOriginIconProps) => {
	const getCallOrigin = (iOrigin: number, callDurationSec: number) => {
		// iOrigin: 1 - outgoing, 0 - incoming
		if (iOrigin === 1 && callDurationSec > 0) {
			return <FontAwesomeIcon width={12} icon={faArrowRight} />;
		}
		if (iOrigin === 1 && callDurationSec === 0) {
			return <FontAwesomeIcon width={12} icon={faArrowRight} />;
		}
		if (iOrigin === 0 && callDurationSec > 0) {
			return <FontAwesomeIcon width={12} icon={faArrowLeft} />;
		}
		if (iOrigin === 1 && callDurationSec === 0) {
			return <FontAwesomeIcon width={12} icon={faArrowLeft} />;
		}
		return <FontAwesomeIcon width={12} icon={faExclamation} />;
	};

	return getCallOrigin(journalEntry.iOrigin, journalEntry.iDurationConnected);
};
