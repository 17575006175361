import { compact, groupBy } from "lodash";

import { AsnGetContactByEntryIDArgument } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetROSEInterface";
import { AsnNetDatabaseContact } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common";
import { AsnNetDatabaseJournalList } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Journal";
import { ILogData } from "../../web-shared-components/helpers/logger/ILogger";
import { theContactManager } from "../globals";
import { IBaseSingletons } from "../interfaces/IBaseSingletons";
import BaseSingleton from "../lib/BaseSingleton";
import { SocketTransport } from "../session/SocketTransport";
import { AsnOptionalParam } from "../ucserver/stubs/ENetUC_Common";
import * as ENetUC_Journal from "../ucserver/stubs/ENetUC_Journal";
import { IAsnNetDatabaseJournalEx } from "../zustand/journalSlice";
import { getState } from "../zustand/store";

// JournalEntryTypes and JournalEntryCallTypes must be aligned
const JournalEntryTypes =
	ENetUC_Journal.AsnNetDatabaseJournalFilterEnumeration.eFilterFlagPhoneCalls +
	ENetUC_Journal.AsnNetDatabaseJournalFilterEnumeration.eFilterFlagAudioChats +
	ENetUC_Journal.AsnNetDatabaseJournalFilterEnumeration.eFilterFlagVideoChats;
const JournalEntryCallTypes = [
	ENetUC_Journal.AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEPBX,
	ENetUC_Journal.AsnNetDatabaseJournalCallType.eJOURNALCALLTYPESIPAVCALL,
	ENetUC_Journal.AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEBLUETOOTHMOBILE,
	ENetUC_Journal.AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEBLUETOOTHMOBILEUNKNOWNDURATION,
	ENetUC_Journal.AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOVIDEOCHAT,
	ENetUC_Journal.AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOCHAT,
	ENetUC_Journal.AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEVIDEOCHAT
];

// Simplified interface to handle the messages received through the websocket
export interface IJournalHandler {
	onResult_asnJournalSubscribeEvents(result: ENetUC_Journal.AsnJournalSubscribeEventsResult): void;
	onEvent_asnJournalEntryListChanged(argument: ENetUC_Journal.AsnJournalEntryChangedListArgument): void;
}

/**
 *
 */
export class JournalManager extends BaseSingleton implements IJournalHandler {
	// The singleton instance of this class
	private static instance: JournalManager;
	private socket: SocketTransport;

	/**
	 * Constructs JournalManager.
	 * Method is private as we follow the Singleton Approach using getInstance
	 *
	 * @param attributes - the constructor attributes
	 */
	private constructor(attributes: IBaseSingletons) {
		super(attributes);
		this.socket = SocketTransport.getInstance();
		this.socket.setJournalHandler(this);
	}

	/**
	 * Gets instance of JournalManager to use as singleton.
	 *
	 * @param attributes - the constructor attributes
	 * @returns - an instance of this class.
	 */
	public static getInstance(attributes: IBaseSingletons): JournalManager {
		if (!JournalManager.instance) {
			JournalManager.instance = new JournalManager(attributes);
		}
		return JournalManager.instance;
	}

	/**
	 * The Loggers getLogData callback (used in all the log methods called in this class, add the classname to every log entry)
	 *
	 * @returns - an ILogData log data object provided additional data for all the logger calls in this class
	 */
	public getLogData(): ILogData {
		return {
			className: "JournalManager"
		};
	}

	/**
	 * Called by the socket after subscribing to the journal events
	 *
	 * @param result - the result
	 */
	public onResult_asnJournalSubscribeEvents(result: ENetUC_Journal.AsnJournalSubscribeEventsResult) {
		this.getMyJournalEntries()
			.then(async (data) => {
				void this.getContactsFromJournal(data.journalList)
					.then((result) => {
						if (result) {
							getState().setJournals(result);
						}
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	/**
	 *
	 * Let's get the real SIP Addresses from the server
	 *
	 * @param journal - the journal
	 * @returns the journal entries enhanced with sip address from the ucServer
	 */
	private async getContactsFromJournal(journal: AsnNetDatabaseJournalList): Promise<IAsnNetDatabaseJournalEx[]> {
		// let's get the triples of how contacts are referred to from all journal entries
		const triples = journal.map((item) => ({
			u8sContactEntryDBID: item.u8sContactEntryDBID,
			u8sContactEntryStoreID: item.u8sContactEntryStoreID,
			u8sContactEntryID: item.u8sContactEntryID
		}));

		// group them to get only one for each
		const groupedTriples = groupBy(
			triples,
			(item) => `"${item.u8sContactEntryID}, ${item.u8sContactEntryStoreID}, ${item.u8sContactEntryDBID}"`
		);

		// internal helper function to resolve all sip addresses from the server
		const getSIPAddressByTriple = async (
			args: AsnGetContactByEntryIDArgument[]
		): Promise<(AsnNetDatabaseContact | undefined)[]> => {
			const allResults = await Promise.all(
				args
					.map(async (argument): Promise<AsnNetDatabaseContact | undefined> => {
						const result = await theContactManager.getUserByID(argument);
						if (result instanceof Error) {
							console.log("Error returned from theContactManager.getUserByID: ", result, " - requested: ", argument);
							return;
						}
						return result.contact;
					})
					.filter((item) => item !== undefined)
			);
			return allResults;
		};

		// build all argumente we need for the server requests
		const argumentTriples: AsnGetContactByEntryIDArgument[] = [];
		for (const [, values] of Object.entries(groupedTriples)) {
			if (!values || !values.length) {
				continue;
			}
			const firstItem = values[0];
			if (
				firstItem.u8sContactEntryStoreID !== "" ||
				firstItem.u8sContactEntryDBID !== "" ||
				firstItem.u8sContactEntryID !== ""
			) {
				const argument: AsnGetContactByEntryIDArgument = {
					u8sEntryIDDB: firstItem.u8sContactEntryDBID,
					u8sEntryIDStore: firstItem.u8sContactEntryStoreID,
					u8sEntryID: firstItem.u8sContactEntryID
				};
				argumentTriples.push(argument);
			}
		}

		const result = await getSIPAddressByTriple(argumentTriples);

		// need to compact to filter undefined entries from the resulting array.
		const newList = compact(
			journal.map((item) => {
				const contact = result.find((searchItem) => searchItem?.u8sEntryID === item.u8sContactEntryID);
				const newJournalEntry: IAsnNetDatabaseJournalEx = {
					...item,
					u8sSIPAddress: contact?.u8sSIPAddress || ""
				};
				return newJournalEntry;
			})
		);

		return newList;
	}

	/**
	 * Called by the socket on asnJournalEntryListChanged from UCServer
	 *
	 * @param argument - the argument
	 */
	public onEvent_asnJournalEntryListChanged(argument: ENetUC_Journal.AsnJournalEntryChangedListArgument) {
		argument.journalEntryChangedList.forEach((entry) => {
			// @ts-ignore
			if (!JournalEntryCallTypes.includes(entry.journalEntry?.optionalParams?.CallType)) {
				return;
			}

			const isAdded = entry.iFlags === 1;
			const isModified = entry.iFlags === 2;
			const isDeleted = entry.iFlags === 3;
			const isAddedOrModified = entry.iFlags === 4;

			if (isAdded || isModified || isAddedOrModified) {
				void this.getContactsFromJournal([entry.journalEntry])
					.then((list) => {
						getState().setJournals(list);
					})
					.catch((error) => {
						console.log(error);
					});
			}

			if (isDeleted) {
				getState().deleteJournals([entry.journalEntry.u8sConnectionID]);
			}
		});
	}

	/**
	 * Subscribe to journal events
	 */
	public async journalSubscribeEvents() {
		const iLastKnownGlobTransactionID = getState().iLastTransactionID;
		const argument = new ENetUC_Journal.AsnJournalSubscribeEventsArgument({
			bReceiveJournalEvents: true, // Muss true sein, ansonsten bekommt man auf getConversationOverview keine Antwort
			// iLastKnownGlobTransactionID,
			iMaxEntries: 100 // TODO auch hier? Muss 1 sein, bei 0 bekommt man den kompletten Datensatz vom Server
		});
		this.socket.send("asnJournalSubscribeEvents", argument);
	}

	/**
	 *
	 * @param u8sContactName
	 * @param u8slistPhoneNumber
	 */
	public async getContactJournalEntries(
		u8slistPhoneNumber: string[]
	): Promise<ENetUC_Journal.AsnGetJournalEntriesResult> {
		const argument = ENetUC_Journal.AsnGetJournalEntriesArgument.initEmpty();
		argument.findOptions.stStartTimeFrom = new Date("2020-01-01");
		argument.findOptions.iMaxNumEntries = 6;
		argument.findOptions.u8slistPhoneNumber = u8slistPhoneNumber;
		argument.findOptions.optionalParams = [
			new AsnOptionalParam({
				key: "iAdditionalFlags",
				value: {
					integerdata: JournalEntryTypes
				}
			})
		];
		return this.getJournalEntries(argument);
	}

	/**
	 *
	 */
	private async getMyJournalEntries(): Promise<ENetUC_Journal.AsnGetJournalEntriesResult> {
		const argument = ENetUC_Journal.AsnGetJournalEntriesArgument.initEmpty();
		argument.findOptions.stStartTimeFrom = new Date("2020-01-01");
		argument.findOptions.iMaxNumEntries = 100;
		argument.findOptions.optionalParams = [
			new AsnOptionalParam({
				key: "iAdditionalFlags",
				value: {
					integerdata: JournalEntryTypes
				}
			})
		];
		return this.getJournalEntries(argument);
	}

	/**
	 * Get journal entries
	 *
	 * @param argument
	 */
	private async getJournalEntries(
		argument: ENetUC_Journal.AsnGetJournalEntriesArgument
	): Promise<ENetUC_Journal.AsnGetJournalEntriesResult> {
		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) {
					this.logger.error("Error getJournalEntries", "getJournalEntries", this, { result });
					reject(result);
				} else {
					resolve(result as ENetUC_Journal.AsnGetJournalEntriesResult);
				}
			};
			this.socket.send("asnGetJournalEntries", argument, callBack);
		});
	}

	/**
	 * Delete journal entries
	 *
	 * @param u8sConnectionIDList - List of connection ids to indentify the journal entries to delete
	 */
	public async deleteJournalEntries(
		u8sConnectionIDList: string[]
	): Promise<ENetUC_Journal.AsnDeleteJournalEntriesResult> {
		const argument = ENetUC_Journal.AsnDeleteJournalEntriesArgument.initEmpty();
		argument.u8sConnectionIDList = u8sConnectionIDList;

		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) {
					this.logger.error("Error deleteJournalEntries", "deleteJournalEntries", this, { result });
					reject(result);
				} else {
					getState().deleteJournals(u8sConnectionIDList);
					resolve(result as ENetUC_Journal.AsnDeleteJournalEntriesResult);
				}
			};
			this.socket.send("asnDeleteJournalEntries", argument, callBack);
		});
	}

	/**
	 * Update journal read flags
	 *
	 * @param u8sConnectionIDList - List of ids to specify the journal entries to change their read flag
	 * @param bReadFlag - True if the entry(s) should be set to "read", False if they should be set to "unread"
	 */
	public async updateJournalReadFlags(
		u8sConnectionIDList: string[],
		bReadFlag: boolean
	): Promise<ENetUC_Journal.AsnUpdateJournalReadFlagResult> {
		const argument = ENetUC_Journal.AsnUpdateJournalReadFlagArgument.initEmpty();
		argument.u8sConnectionIDList = u8sConnectionIDList;
		argument.bReadFlag = bReadFlag;

		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) {
					this.logger.error("Error updateJournalReadFlags", "updateJournalReadFlags", this, { result });
					reject(result);
				} else {
					getState().updateJournalReadFlags(u8sConnectionIDList, bReadFlag);
					resolve(result as ENetUC_Journal.AsnUpdateJournalReadFlagResult);
				}
			};
			this.socket.send("asnUpdateJournalReadFlag", argument, callBack);
		});
	}

	/**
	 * Update journal memo
	 *
	 * @param u8sConnectionIDList - List of ids to specify the journal entries to change their read flag
	 * @param bReadFlag - True if the entry(s) should be set to "read", False if they should be set to "unread"
	 * @param u8sMemo
	 */
	public async updateJournalMemo(
		u8sConnectionIDList: string[],
		u8sMemo: string
	): Promise<ENetUC_Journal.AsnUpdateJournalReadFlagResult> {
		const argument = ENetUC_Journal.AsnUpdateJournalMemoArgument.initEmpty();
		argument.u8sConnectionIDList = u8sConnectionIDList;
		argument.u8sMemo = u8sMemo;

		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) {
					this.logger.error("Error updateJournalMemo", "updateJournalMemo", this, { result });
					reject(result);
				} else {
					getState().updateJournalMemo(u8sConnectionIDList, u8sMemo);
					resolve(result as ENetUC_Journal.AsnUpdateJournalReadFlagResult);
				}
			};
			this.socket.send("asnUpdateJournalMemo", argument, callBack);
		});
	}
}
