// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_PresenceV2_Converter.ts
 * "UC-Server-Access-Protocol-PresenceV2" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_PresenceV2 from "./ENetUC_PresenceV2";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
import * as ENetUC_Common_Appointments from "./ENetUC_Common_Appointments";
import * as ENetUC_ClientCapabilities from "./ENetUC_ClientCapabilities";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_AsnContact_Converter from "./ENetUC_Common_AsnContact_Converter";
import * as ENetUC_Common_Appointments_Converter from "./ENetUC_Common_Appointments_Converter";
import * as ENetUC_ClientCapabilities_Converter from "./ENetUC_ClientCapabilities_Converter";

// [PrintTSEncoderDecoderCode] AsnPresenceLine
export class AsnPresenceLine_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnPresenceLine, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnPresenceLine
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnPresenceLine", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sLineNumber", obj.u8sLineNumber, newContext);
		value += TSConverter.addNumberParam("iState", obj.iState, newContext);
		value += TSConverter.addNumberParam("iDoNotDisturb", obj.iDoNotDisturb, newContext);
		value += TSConverter.addNumberParam("iForwardFeaturesPBX", obj.iForwardFeaturesPBX, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnPresenceLine, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPresenceLine");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceLine"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnPresenceLine;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnPresenceLine
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "u8sLineNumber", "string", errors, newContext, false))
				obj.u8sLineNumber = data.u8sLineNumber;
			if (TSConverter.validateParam(data, "iState", "number", errors, newContext, false))
				obj.iState = data.iState;
			if (TSConverter.validateParam(data, "iDoNotDisturb", "number", errors, newContext, false))
				obj.iDoNotDisturb = data.iDoNotDisturb;
			if (TSConverter.validateParam(data, "iForwardFeaturesPBX", "number", errors, newContext, false))
				obj.iForwardFeaturesPBX = data.iForwardFeaturesPBX;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceLine"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPresenceCall
export class AsnPresenceCall_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnPresenceCall, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnPresenceCall
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnPresenceCall", newContext);
		value += TSConverter.addNumberParam("iAction", obj.iAction, newContext);
		value += TSConverter.addStringParam("u8sConnectionID", obj.u8sConnectionID, newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sLineNumber", obj.u8sLineNumber, newContext);
		value += TSConverter.addStringParam("u8sCurrentNumberSC", obj.u8sCurrentNumberSC, newContext);
		value += TSConverter.addNumberParam("iCallState", obj.iCallState, newContext);
		value += TSConverter.addNumberParam("iFeatures", obj.iFeatures, newContext);
		value += TSConverter.addNumberParam("iOutbound", obj.iOutbound, newContext);
		value += TSConverter.addNumberParam("iInternal", obj.iInternal, newContext);
		value += TSConverter.addNumberParam("iCallType", obj.iCallType, newContext);
		value += TSConverter.addObjectAsStringParam("remoteContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.remoteContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnPresenceCall, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPresenceCall");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceCall"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnPresenceCall;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnPresenceCall
			if (TSConverter.validateParam(data, "iAction", "number", errors, newContext, false))
				obj.iAction = data.iAction;
			if (TSConverter.validateParam(data, "u8sConnectionID", "string", errors, newContext, false))
				obj.u8sConnectionID = data.u8sConnectionID;
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "u8sLineNumber", "string", errors, newContext, false))
				obj.u8sLineNumber = data.u8sLineNumber;
			if (TSConverter.validateParam(data, "u8sCurrentNumberSC", "string", errors, newContext, false))
				obj.u8sCurrentNumberSC = data.u8sCurrentNumberSC;
			if (TSConverter.validateParam(data, "iCallState", "number", errors, newContext, false))
				obj.iCallState = data.iCallState;
			if (TSConverter.validateParam(data, "iFeatures", "number", errors, newContext, false))
				obj.iFeatures = data.iFeatures;
			if (TSConverter.validateParam(data, "iOutbound", "number", errors, newContext, false))
				obj.iOutbound = data.iOutbound;
			if (TSConverter.validateParam(data, "iInternal", "number", errors, newContext, false))
				obj.iInternal = data.iInternal;
			if (TSConverter.validateParam(data, "iCallType", "number", errors, newContext, false))
				obj.iCallType = data.iCallType;
			const tscv_remotecontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.remoteContact, tscv_remotecontact, errors, newContext, "remoteContact", true))
				obj.remoteContact = tscv_remotecontact;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceCall"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPresenceForward
export class AsnPresenceForward_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnPresenceForward, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnPresenceForward
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnPresenceForward", newContext);
		value += TSConverter.addStringParam("u8sLinePhoneNumber", obj.u8sLinePhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sLineNumber", obj.u8sLineNumber, newContext);
		value += TSConverter.addNumberParam("iInternalID", obj.iInternalID, newContext);
		value += TSConverter.addStringParam("u8sDestination", obj.u8sDestination, newContext);
		value += TSConverter.addNumberParam("iForwardMode", obj.iForwardMode, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnPresenceForward, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPresenceForward");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceForward"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnPresenceForward;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnPresenceForward
			if (TSConverter.validateParam(data, "u8sLinePhoneNumber", "string", errors, newContext, false))
				obj.u8sLinePhoneNumber = data.u8sLinePhoneNumber;
			if (TSConverter.validateParam(data, "u8sLineNumber", "string", errors, newContext, false))
				obj.u8sLineNumber = data.u8sLineNumber;
			if (TSConverter.validateParam(data, "iInternalID", "number", errors, newContext, false))
				obj.iInternalID = data.iInternalID;
			if (TSConverter.validateParam(data, "u8sDestination", "string", errors, newContext, false))
				obj.u8sDestination = data.u8sDestination;
			if (TSConverter.validateParam(data, "iForwardMode", "number", errors, newContext, false))
				obj.iForwardMode = data.iForwardMode;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceForward"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactCapabilities
export class AsnContactCapabilities_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnContactCapabilities, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactCapabilities
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactCapabilities", newContext);
		value += TSConverter.addBooleanParam("bAudio", obj.bAudio, newContext);
		value += TSConverter.addBooleanParam("bVideo", obj.bVideo, newContext);
		value += TSConverter.addBooleanParam("bScreenShare", obj.bScreenShare, newContext);
		value += TSConverter.addBooleanParam("bExchangeCalendar", obj.bExchangeCalendar, newContext);
		value += TSConverter.addBooleanParam("bNotesCalendar", obj.bNotesCalendar, newContext);
		value += TSConverter.addBooleanParam("bCamControl", obj.bCamControl, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnContactCapabilities, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactCapabilities");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactCapabilities"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnContactCapabilities;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactCapabilities
			if (TSConverter.validateParam(data, "bAudio", "boolean", errors, newContext, false))
				obj.bAudio = data.bAudio;
			if (TSConverter.validateParam(data, "bVideo", "boolean", errors, newContext, false))
				obj.bVideo = data.bVideo;
			if (TSConverter.validateParam(data, "bScreenShare", "boolean", errors, newContext, false))
				obj.bScreenShare = data.bScreenShare;
			if (TSConverter.validateParam(data, "bExchangeCalendar", "boolean", errors, newContext, false))
				obj.bExchangeCalendar = data.bExchangeCalendar;
			if (TSConverter.validateParam(data, "bNotesCalendar", "boolean", errors, newContext, false))
				obj.bNotesCalendar = data.bNotesCalendar;
			if (TSConverter.validateParam(data, "bCamControl", "boolean", errors, newContext, false))
				obj.bCamControl = data.bCamControl;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactCapabilities"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnActualUserDynamicRights
export class AsnActualUserDynamicRights_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnActualUserDynamicRights, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnActualUserDynamicRights
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnActualUserDynamicRights", newContext);
		value += TSConverter.addObjectAsStringParam("asnMinPossibleRights", ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(obj.asnMinPossibleRights, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnMaxPossibleRights", ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(obj.asnMaxPossibleRights, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnCurrentRights", ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(obj.asnCurrentRights, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnActualUserDynamicRights, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnActualUserDynamicRights");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnActualUserDynamicRights"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnActualUserDynamicRights;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnActualUserDynamicRights
			const tscv_asnminpossiblerights = ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(data.asnMinPossibleRights, tscv_asnminpossiblerights, errors, newContext, "asnMinPossibleRights", false))
				obj.asnMinPossibleRights = tscv_asnminpossiblerights;
			const tscv_asnmaxpossiblerights = ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(data.asnMaxPossibleRights, tscv_asnmaxpossiblerights, errors, newContext, "asnMaxPossibleRights", false))
				obj.asnMaxPossibleRights = tscv_asnmaxpossiblerights;
			const tscv_asncurrentrights = ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(data.asnCurrentRights, tscv_asncurrentrights, errors, newContext, "asnCurrentRights", true))
				obj.asnCurrentRights = tscv_asncurrentrights;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnActualUserDynamicRights"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAgentState
export class AsnAgentState_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnAgentState, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAgentState
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAgentState", newContext);
		value += TSConverter.addNumberParam("dwState", obj.dwState, newContext);
		value += TSConverter.addObjectAsStringParam("mapDetails", ENetUC_Common_Converter.AsnStringIntegerPairList_Converter.toJSON(obj.mapDetails, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnAgentState, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAgentState");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAgentState"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnAgentState;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAgentState
			if (TSConverter.validateParam(data, "dwState", "number", errors, newContext, false))
				obj.dwState = data.dwState;
			const tscv_mapdetails = new ENetUC_Common.AsnStringIntegerPairList();
			if (ENetUC_Common_Converter.AsnStringIntegerPairList_Converter.fromJSON(data.mapDetails, tscv_mapdetails, errors, newContext, "mapDetails", true))
				obj.mapDetails = tscv_mapdetails;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAgentState"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRemovePresenceArgument
export class AsnRemovePresenceArgument_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnRemovePresenceArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRemovePresenceArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRemovePresenceArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnRemovePresenceArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRemovePresenceArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRemovePresenceArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnRemovePresenceArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRemovePresenceArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRemovePresenceArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetAndSubscribePresenceArgument
export class AsnGetAndSubscribePresenceArgument_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetAndSubscribePresenceArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetAndSubscribePresenceArgument", newContext);
		value += TSConverter.addObjectAsStringParam("seqContactIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.seqContactIDs, newContext), newContext);
		value += TSConverter.addNumberParam("iEventFlagsToSubscribe", obj.iEventFlagsToSubscribe, newContext);
		value += TSConverter.addNumberParam("iContactDetailsReturnFilter", obj.iContactDetailsReturnFilter, newContext);
		value += TSConverter.addBooleanParam("bOpenOnlyInternalContacts", obj.bOpenOnlyInternalContacts, newContext);
		value += TSConverter.addBooleanParam("bDontRequestPermissions", obj.bDontRequestPermissions, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetAndSubscribePresenceArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAndSubscribePresenceArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnGetAndSubscribePresenceArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetAndSubscribePresenceArgument
			const tscv_seqcontactids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.seqContactIDs, tscv_seqcontactids, errors, newContext, "seqContactIDs", false))
				obj.seqContactIDs = tscv_seqcontactids;
			if (TSConverter.validateParam(data, "iEventFlagsToSubscribe", "number", errors, newContext, true))
				obj.iEventFlagsToSubscribe = data.iEventFlagsToSubscribe;
			if (TSConverter.validateParam(data, "iContactDetailsReturnFilter", "number", errors, newContext, true))
				obj.iContactDetailsReturnFilter = data.iContactDetailsReturnFilter;
			if (TSConverter.validateParam(data, "bOpenOnlyInternalContacts", "boolean", errors, newContext, true))
				obj.bOpenOnlyInternalContacts = data.bOpenOnlyInternalContacts;
			if (TSConverter.validateParam(data, "bDontRequestPermissions", "boolean", errors, newContext, true))
				obj.bDontRequestPermissions = data.bDontRequestPermissions;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAndSubscribePresenceArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUnsubscribePresenceArgument
export class AsnUnsubscribePresenceArgument_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnUnsubscribePresenceArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUnsubscribePresenceArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUnsubscribePresenceArgument", newContext);
		value += TSConverter.addObjectAsStringParam("seqContactIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.seqContactIDs, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnUnsubscribePresenceArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUnsubscribePresenceArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribePresenceArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnUnsubscribePresenceArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUnsubscribePresenceArgument
			const tscv_seqcontactids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.seqContactIDs, tscv_seqcontactids, errors, newContext, "seqContactIDs", false))
				obj.seqContactIDs = tscv_seqcontactids;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribePresenceArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUnsubscribePresenceResult
export class AsnUnsubscribePresenceResult_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnUnsubscribePresenceResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUnsubscribePresenceResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUnsubscribePresenceResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnUnsubscribePresenceResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUnsubscribePresenceResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribePresenceResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnUnsubscribePresenceResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUnsubscribePresenceResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribePresenceResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetDynamicRightsArgument
export class AsnSetDynamicRightsArgument_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnSetDynamicRightsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetDynamicRightsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetDynamicRightsArgument", newContext);
		value += TSConverter.addStringParam("u8sOwnerContactID", obj.u8sOwnerContactID, newContext);
		value += TSConverter.addStringParam("u8sRemoteContactID", obj.u8sRemoteContactID, newContext);
		value += TSConverter.addNumberParam("iDynamicRights", obj.iDynamicRights, newContext);
		value += TSConverter.addObjectAsStringParam("dynamicRights", ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(obj.dynamicRights, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnSetDynamicRightsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetDynamicRightsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetDynamicRightsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnSetDynamicRightsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetDynamicRightsArgument
			if (TSConverter.validateParam(data, "u8sOwnerContactID", "string", errors, newContext, false))
				obj.u8sOwnerContactID = data.u8sOwnerContactID;
			if (TSConverter.validateParam(data, "u8sRemoteContactID", "string", errors, newContext, false))
				obj.u8sRemoteContactID = data.u8sRemoteContactID;
			if (TSConverter.validateParam(data, "iDynamicRights", "number", errors, newContext, false))
				obj.iDynamicRights = data.iDynamicRights;
			const tscv_dynamicrights = ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(data.dynamicRights, tscv_dynamicrights, errors, newContext, "dynamicRights", true))
				obj.dynamicRights = tscv_dynamicrights;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetDynamicRightsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetDynamicRightsResult
export class AsnSetDynamicRightsResult_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnSetDynamicRightsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetDynamicRightsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetDynamicRightsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("dynamicRights", ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(obj.dynamicRights, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnSetDynamicRightsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetDynamicRightsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetDynamicRightsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnSetDynamicRightsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetDynamicRightsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_dynamicrights = ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(data.dynamicRights, tscv_dynamicrights, errors, newContext, "dynamicRights", false))
				obj.dynamicRights = tscv_dynamicrights;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetDynamicRightsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRemoveDynamicRightsArgument
export class AsnRemoveDynamicRightsArgument_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRemoveDynamicRightsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRemoveDynamicRightsArgument", newContext);
		value += TSConverter.addStringParam("u8sOwnerContactID", obj.u8sOwnerContactID, newContext);
		value += TSConverter.addStringParam("u8sRemoteContactID", obj.u8sRemoteContactID, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRemoveDynamicRightsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRemoveDynamicRightsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnRemoveDynamicRightsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRemoveDynamicRightsArgument
			if (TSConverter.validateParam(data, "u8sOwnerContactID", "string", errors, newContext, false))
				obj.u8sOwnerContactID = data.u8sOwnerContactID;
			if (TSConverter.validateParam(data, "u8sRemoteContactID", "string", errors, newContext, false))
				obj.u8sRemoteContactID = data.u8sRemoteContactID;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRemoveDynamicRightsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRemoveDynamicRightsResult
export class AsnRemoveDynamicRightsResult_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnRemoveDynamicRightsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRemoveDynamicRightsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRemoveDynamicRightsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnRemoveDynamicRightsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRemoveDynamicRightsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRemoveDynamicRightsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnRemoveDynamicRightsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRemoveDynamicRightsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRemoveDynamicRightsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestLargeProfileImageArgument
export class AsnRequestLargeProfileImageArgument_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestLargeProfileImageArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestLargeProfileImageArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addStringParam("u8sKnownHash", obj.u8sKnownHash, newContext);
		value += TSConverter.addObjectAsStringParam("netDatabaseContactID", ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toJSON(obj.netDatabaseContactID, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestLargeProfileImageArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestLargeProfileImageArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnRequestLargeProfileImageArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestLargeProfileImageArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			if (TSConverter.validateParam(data, "u8sKnownHash", "string", errors, newContext, false))
				obj.u8sKnownHash = data.u8sKnownHash;
			const tscv_netdatabasecontactid = ENetUC_Common.AsnNetDatabaseContactID.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromJSON(data.netDatabaseContactID, tscv_netdatabasecontactid, errors, newContext, "netDatabaseContactID", true))
				obj.netDatabaseContactID = tscv_netdatabasecontactid;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestLargeProfileImageArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestLargeProfileImageResult
export class AsnRequestLargeProfileImageResult_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnRequestLargeProfileImageResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestLargeProfileImageResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestLargeProfileImageResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addBinaryParam("sImageData", obj.sImageData, newContext);
		value += TSConverter.addStringParam("u8sImageHash", obj.u8sImageHash, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnRequestLargeProfileImageResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestLargeProfileImageResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestLargeProfileImageResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnRequestLargeProfileImageResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestLargeProfileImageResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			if (TSConverter.validateParam(data, "sImageData", "string", errors, newContext, true) && data.sImageData)
				obj.sImageData = TSConverter.decode64(data.sImageData.toString());
			if (TSConverter.validateParam(data, "u8sImageHash", "string", errors, newContext, true))
				obj.u8sImageHash = data.u8sImageHash;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestLargeProfileImageResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestContactImageArgument
export class AsnRequestContactImageArgument_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnRequestContactImageArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestContactImageArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestContactImageArgument", newContext);
		value += TSConverter.addObjectAsStringParam("netDatabaseContactID", ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toJSON(obj.netDatabaseContactID, newContext), newContext);
		value += TSConverter.addStringParam("u8sKnownHash", obj.u8sKnownHash, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnRequestContactImageArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestContactImageArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestContactImageArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnRequestContactImageArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestContactImageArgument
			const tscv_netdatabasecontactid = ENetUC_Common.AsnNetDatabaseContactID.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromJSON(data.netDatabaseContactID, tscv_netdatabasecontactid, errors, newContext, "netDatabaseContactID", false))
				obj.netDatabaseContactID = tscv_netdatabasecontactid;
			if (TSConverter.validateParam(data, "u8sKnownHash", "string", errors, newContext, false))
				obj.u8sKnownHash = data.u8sKnownHash;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestContactImageArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestContactImageResult
export class AsnRequestContactImageResult_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnRequestContactImageResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestContactImageResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestContactImageResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addBinaryParam("sImageData", obj.sImageData, newContext);
		value += TSConverter.addStringParam("u8sImageHash", obj.u8sImageHash, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnRequestContactImageResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestContactImageResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestContactImageResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnRequestContactImageResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestContactImageResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			if (TSConverter.validateParam(data, "sImageData", "string", errors, newContext, true) && data.sImageData)
				obj.sImageData = TSConverter.decode64(data.sImageData.toString());
			if (TSConverter.validateParam(data, "u8sImageHash", "string", errors, newContext, true))
				obj.u8sImageHash = data.u8sImageHash;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestContactImageResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnMonitorGroupingAttribute
export class AsnMonitorGroupingAttribute_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnMonitorGroupingAttribute, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnMonitorGroupingAttribute
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnMonitorGroupingAttribute", newContext);
		value += TSConverter.addStringParam("u8sAttributeGUID", obj.u8sAttributeGUID, newContext);
		value += TSConverter.addStringParam("u8sAttributeShort", obj.u8sAttributeShort, newContext);
		value += TSConverter.addStringParam("u8sAttributeLong", obj.u8sAttributeLong, newContext);
		value += TSConverter.addStringParam("u8sAdditionalText", obj.u8sAdditionalText, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnMonitorGroupingAttribute, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnMonitorGroupingAttribute");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnMonitorGroupingAttribute"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnMonitorGroupingAttribute;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnMonitorGroupingAttribute
			if (TSConverter.validateParam(data, "u8sAttributeGUID", "string", errors, newContext, true))
				obj.u8sAttributeGUID = data.u8sAttributeGUID;
			if (TSConverter.validateParam(data, "u8sAttributeShort", "string", errors, newContext, true))
				obj.u8sAttributeShort = data.u8sAttributeShort;
			if (TSConverter.validateParam(data, "u8sAttributeLong", "string", errors, newContext, true))
				obj.u8sAttributeLong = data.u8sAttributeLong;
			if (TSConverter.validateParam(data, "u8sAdditionalText", "string", errors, newContext, true))
				obj.u8sAdditionalText = data.u8sAdditionalText;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnMonitorGroupingAttribute"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetMonitorGroupingAttributeResult
export class AsnSetMonitorGroupingAttributeResult_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetMonitorGroupingAttributeResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetMonitorGroupingAttributeResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetMonitorGroupingAttributeResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetMonitorGroupingAttributeResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetMonitorGroupingAttributeResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetMonitorGroupingAttributeResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPresenceLines
export class AsnPresenceLines_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnPresenceLines, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnPresenceLines
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnPresenceLine_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnPresenceLines, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPresenceLines");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceLines"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnPresenceLines;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnPresenceLines
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_PresenceV2.AsnPresenceLine.initEmpty();
				if (AsnPresenceLine_Converter.fromJSON(elem, entry, errors, newContext, "AsnPresenceLine", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceLines"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPresenceCalls
export class AsnPresenceCalls_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnPresenceCalls, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnPresenceCalls
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnPresenceCall_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnPresenceCalls, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPresenceCalls");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceCalls"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnPresenceCalls;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnPresenceCalls
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_PresenceV2.AsnPresenceCall.initEmpty();
				if (AsnPresenceCall_Converter.fromJSON(elem, entry, errors, newContext, "AsnPresenceCall", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceCalls"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPresenceForwards
export class AsnPresenceForwards_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnPresenceForwards, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnPresenceForwards
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnPresenceForward_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnPresenceForwards, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPresenceForwards");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceForwards"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnPresenceForwards;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnPresenceForwards
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_PresenceV2.AsnPresenceForward.initEmpty();
				if (AsnPresenceForward_Converter.fromJSON(elem, entry, errors, newContext, "AsnPresenceForward", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceForwards"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPresence
export class AsnPresence_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnPresence, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnPresence
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnPresence", newContext);
		value += TSConverter.addStringParam("u8sContactId", obj.u8sContactId, newContext);
		value += TSConverter.addNumberParam("iPresenceState", obj.iPresenceState, newContext);
		value += TSConverter.addObjectAsStringParam("asnRemoteContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.asnRemoteContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnUserPropertyBag", ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toJSON(obj.asnUserPropertyBag, newContext), newContext);
		value += TSConverter.addNumberParam("iContactFeatureSet", obj.iContactFeatureSet, newContext);
		value += TSConverter.addNumberParam("iStaticRights", obj.iStaticRights, newContext);
		value += TSConverter.addNumberParam("iMonitorAccessRights", obj.iMonitorAccessRights, newContext);
		value += TSConverter.addObjectAsStringParam("asnAbsentState", ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(obj.asnAbsentState, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnCustomNote", ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toJSON(obj.asnCustomNote, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("seqActualAppointments", ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(obj.seqActualAppointments, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnNextAppointment", ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toJSON(obj.asnNextAppointment, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("seqPhoneLines", AsnPresenceLines_Converter.toJSON(obj.seqPhoneLines, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("seqCalls", AsnPresenceCalls_Converter.toJSON(obj.seqCalls, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("seqLineForwards", AsnPresenceForwards_Converter.toJSON(obj.seqLineForwards, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnContactCapabilities", AsnContactCapabilities_Converter.toJSON(obj.asnContactCapabilities, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnContactCapabilitiesEx", ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.toJSON(obj.asnContactCapabilitiesEx, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnCurrentUserDynamicRights", AsnActualUserDynamicRights_Converter.toJSON(obj.asnCurrentUserDynamicRights, newContext), newContext);
		value += TSConverter.addNumberParam("iStaticRights2", obj.iStaticRights2, newContext);
		value += TSConverter.addObjectAsStringParam("asnAgentState", AsnAgentState_Converter.toJSON(obj.asnAgentState, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnMonitorGroupingAttribute", AsnMonitorGroupingAttribute_Converter.toJSON(obj.asnMonitorGroupingAttribute, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnPresence, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPresence");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPresence"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnPresence;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnPresence
			if (TSConverter.validateParam(data, "u8sContactId", "string", errors, newContext, false))
				obj.u8sContactId = data.u8sContactId;
			if (TSConverter.validateParam(data, "iPresenceState", "number", errors, newContext, true))
				obj.iPresenceState = data.iPresenceState;
			const tscv_asnremotecontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.asnRemoteContact, tscv_asnremotecontact, errors, newContext, "asnRemoteContact", true))
				obj.asnRemoteContact = tscv_asnremotecontact;
			const tscv_asnuserpropertybag = ENetUC_Common.AsnUserPropertyBag.initEmpty();
			if (ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromJSON(data.asnUserPropertyBag, tscv_asnuserpropertybag, errors, newContext, "asnUserPropertyBag", true))
				obj.asnUserPropertyBag = tscv_asnuserpropertybag;
			if (TSConverter.validateParam(data, "iContactFeatureSet", "number", errors, newContext, true))
				obj.iContactFeatureSet = data.iContactFeatureSet;
			if (TSConverter.validateParam(data, "iStaticRights", "number", errors, newContext, true))
				obj.iStaticRights = data.iStaticRights;
			if (TSConverter.validateParam(data, "iMonitorAccessRights", "number", errors, newContext, true))
				obj.iMonitorAccessRights = data.iMonitorAccessRights;
			const tscv_asnabsentstate = ENetUC_Common_AsnContact.AsnAbsentState.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(data.asnAbsentState, tscv_asnabsentstate, errors, newContext, "asnAbsentState", true))
				obj.asnAbsentState = tscv_asnabsentstate;
			const tscv_asncustomnote = ENetUC_Common_AsnContact.AsnCustomNote.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromJSON(data.asnCustomNote, tscv_asncustomnote, errors, newContext, "asnCustomNote", true))
				obj.asnCustomNote = tscv_asncustomnote;
			const tscv_seqactualappointments = new ENetUC_Common_Appointments.SEQAppointmentList();
			if (ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(data.seqActualAppointments, tscv_seqactualappointments, errors, newContext, "seqActualAppointments", true))
				obj.seqActualAppointments = tscv_seqactualappointments;
			const tscv_asnnextappointment = ENetUC_Common_Appointments.AsnAppointmentEntry.initEmpty();
			if (ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromJSON(data.asnNextAppointment, tscv_asnnextappointment, errors, newContext, "asnNextAppointment", true))
				obj.asnNextAppointment = tscv_asnnextappointment;
			const tscv_seqphonelines = new ENetUC_PresenceV2.AsnPresenceLines();
			if (AsnPresenceLines_Converter.fromJSON(data.seqPhoneLines, tscv_seqphonelines, errors, newContext, "seqPhoneLines", true))
				obj.seqPhoneLines = tscv_seqphonelines;
			const tscv_seqcalls = new ENetUC_PresenceV2.AsnPresenceCalls();
			if (AsnPresenceCalls_Converter.fromJSON(data.seqCalls, tscv_seqcalls, errors, newContext, "seqCalls", true))
				obj.seqCalls = tscv_seqcalls;
			const tscv_seqlineforwards = new ENetUC_PresenceV2.AsnPresenceForwards();
			if (AsnPresenceForwards_Converter.fromJSON(data.seqLineForwards, tscv_seqlineforwards, errors, newContext, "seqLineForwards", true))
				obj.seqLineForwards = tscv_seqlineforwards;
			const tscv_asncontactcapabilities = ENetUC_PresenceV2.AsnContactCapabilities.initEmpty();
			if (AsnContactCapabilities_Converter.fromJSON(data.asnContactCapabilities, tscv_asncontactcapabilities, errors, newContext, "asnContactCapabilities", true))
				obj.asnContactCapabilities = tscv_asncontactcapabilities;
			const tscv_asncontactcapabilitiesex = ENetUC_ClientCapabilities.AsnClientCapabilitiesV2.initEmpty();
			if (ENetUC_ClientCapabilities_Converter.AsnClientCapabilitiesV2_Converter.fromJSON(data.asnContactCapabilitiesEx, tscv_asncontactcapabilitiesex, errors, newContext, "asnContactCapabilitiesEx", true))
				obj.asnContactCapabilitiesEx = tscv_asncontactcapabilitiesex;
			const tscv_asncurrentuserdynamicrights = ENetUC_PresenceV2.AsnActualUserDynamicRights.initEmpty();
			if (AsnActualUserDynamicRights_Converter.fromJSON(data.asnCurrentUserDynamicRights, tscv_asncurrentuserdynamicrights, errors, newContext, "asnCurrentUserDynamicRights", true))
				obj.asnCurrentUserDynamicRights = tscv_asncurrentuserdynamicrights;
			if (TSConverter.validateParam(data, "iStaticRights2", "number", errors, newContext, true))
				obj.iStaticRights2 = data.iStaticRights2;
			const tscv_asnagentstate = ENetUC_PresenceV2.AsnAgentState.initEmpty();
			if (AsnAgentState_Converter.fromJSON(data.asnAgentState, tscv_asnagentstate, errors, newContext, "asnAgentState", true))
				obj.asnAgentState = tscv_asnagentstate;
			const tscv_asnmonitorgroupingattribute = ENetUC_PresenceV2.AsnMonitorGroupingAttribute.initEmpty();
			if (AsnMonitorGroupingAttribute_Converter.fromJSON(data.asnMonitorGroupingAttribute, tscv_asnmonitorgroupingattribute, errors, newContext, "asnMonitorGroupingAttribute", true))
				obj.asnMonitorGroupingAttribute = tscv_asnmonitorgroupingattribute;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPresence"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPresenceList
export class AsnPresenceList_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnPresenceList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnPresenceList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnPresence_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnPresenceList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPresenceList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnPresenceList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnPresenceList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_PresenceV2.AsnPresence.initEmpty();
				if (AsnPresence_Converter.fromJSON(elem, entry, errors, newContext, "AsnPresence", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdatePresenceArgument
export class AsnUpdatePresenceArgument_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnUpdatePresenceArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdatePresenceArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdatePresenceArgument", newContext);
		value += TSConverter.addObjectAsStringParam("presence", AsnPresence_Converter.toJSON(obj.presence, newContext), newContext);
		value += TSConverter.addNumberParam("iChangedFlags", obj.iChangedFlags, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnUpdatePresenceArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdatePresenceArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdatePresenceArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnUpdatePresenceArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdatePresenceArgument
			const tscv_presence = ENetUC_PresenceV2.AsnPresence.initEmpty();
			if (AsnPresence_Converter.fromJSON(data.presence, tscv_presence, errors, newContext, "presence", false))
				obj.presence = tscv_presence;
			if (TSConverter.validateParam(data, "iChangedFlags", "number", errors, newContext, false))
				obj.iChangedFlags = data.iChangedFlags;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdatePresenceArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetAndSubscribePresenceResult
export class AsnGetAndSubscribePresenceResult_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetAndSubscribePresenceResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetAndSubscribePresenceResult", newContext);
		value += TSConverter.addObjectAsStringParam("seqSubscribedPresence", AsnPresenceList_Converter.toJSON(obj.seqSubscribedPresence, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetAndSubscribePresenceResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAndSubscribePresenceResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnGetAndSubscribePresenceResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetAndSubscribePresenceResult
			const tscv_seqsubscribedpresence = new ENetUC_PresenceV2.AsnPresenceList();
			if (AsnPresenceList_Converter.fromJSON(data.seqSubscribedPresence, tscv_seqsubscribedpresence, errors, newContext, "seqSubscribedPresence", false))
				obj.seqSubscribedPresence = tscv_seqsubscribedpresence;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAndSubscribePresenceResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetMonitorGroupingAttributeArgument
export class AsnSetMonitorGroupingAttributeArgument_Converter {
	public static toJSON(obj?: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetMonitorGroupingAttributeArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetMonitorGroupingAttributeArgument", newContext);
		value += TSConverter.addObjectAsStringParam("attribute", AsnMonitorGroupingAttribute_Converter.toJSON(obj.attribute, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetMonitorGroupingAttributeArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetMonitorGroupingAttributeArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_PresenceV2.AsnSetMonitorGroupingAttributeArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetMonitorGroupingAttributeArgument
			const tscv_attribute = ENetUC_PresenceV2.AsnMonitorGroupingAttribute.initEmpty();
			if (AsnMonitorGroupingAttribute_Converter.fromJSON(data.attribute, tscv_attribute, errors, newContext, "attribute", true))
				obj.attribute = tscv_attribute;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetMonitorGroupingAttributeArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
