import React, { useState, useRef } from "react";
import "./sharedMicSelectorButton.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Tooltip } from "@mui/material";
import { faAngleDown, faMicrophone, faMicrophoneSlash, IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { IMediaDevice } from "../../interfaces/IMediaDevice";
import { IButtonCommonProps } from "../../interfaces/IButtonCommonProps";
import { setFAIconShrinkSize, theSharedBrowser, theSharedTranslationManager } from "../../../../helpers/helpers";

interface IProps extends IButtonCommonProps {
	onToggleMic?: () => void;
	onMicSelected?: (mic: string) => void;
	selectedMicID: string | undefined;
	isMuted: boolean;
	micList: IMediaDevice[];
}

/**
 * Mic Selector Button
 *
 * @param props - props
 * @returns - the component
 */
export default function SharedMicSelectorButton(props: IProps): JSX.Element {
	const anchorRef = useRef<HTMLButtonElement>(null);
	const [listOpen, setListOpen] = useState<boolean>(false);

	const width: string = props.width + "px";
	const height: string = props.height + "px";

	/**
	 * Render the mic list in the dropdown menu
	 * @returns - the elements or null
	 */
	const renderMicList = (): JSX.Element[] | null => {
		const mics: JSX.Element[] = [];
		props.micList.forEach((item, idx) => (
			mics.push(
				<MenuItem
					key={item.deviceId}
					data-cy={"webrtc-mic-" + idx}
					selected={item.deviceId === props.selectedMicID}
					onClick={() => {
						setListOpen(false);
						props.onMicSelected && props.onMicSelected(item.deviceId);
					}}
				>
					{(item.label || `Microphone ${idx + 1}`) + (item.deviceId === props.selectedMicID ? " ✓" : "")}
				</MenuItem>
			)
		));
		return mics;
	};

	/**
	 * Gets the icon class according to the current mic / audio state
	 */
	const getIconClass = (): string => {
		if (props.isMuted)
			return "shared-mic-selector-muted";
		else
			return "shared-mic-selector-active";
	};

	/**
	 * Gets the fontawesome icon according to the current mic / audio state
	 */
		const getFaIcon = (): IconDefinition => {
			if (props.isMuted)
				return faMicrophoneSlash;
			else
				return faMicrophone;
		};

	return (
		<div className="shared-mic-selector-container">
			<Tooltip title={theSharedTranslationManager.getTranslation(props.isMuted ? "IDS_AV_UNMUTE_MICROPHONE" : "IDS_AV_MUTE_MICROPHONE")} placement="bottom">
				<span>
					<IconButton
						data-cy="webrtc-mic-toggle"
						{...(!theSharedBrowser.is.touchScreen ? { onClick: () => { props.onToggleMic && props.onToggleMic(); } } : {})}
						{...(theSharedBrowser.is.touchScreen ? { onTouchStart: () => { props.onToggleMic && props.onToggleMic(); } } : {})}
						sx={{
							padding: 0,
							margin: 0,
							width: width,
							height: height
						}}
						className={getIconClass()}
						disabled={props.isDisabled}
					>
						<FontAwesomeIcon
							icon={getFaIcon()}
							transform={setFAIconShrinkSize(5)}
						/>
					</IconButton>
				</span>
			</Tooltip>
			<div className="shared-mic-selector-arrow">
				<Tooltip title={theSharedTranslationManager.getTranslation("IDS_AV_CHOOSE_MICROPHONE")} placement="bottom">
					<IconButton
						data-cy="webrtc-mic-list-toggle"
						ref={anchorRef}
						{...(!theSharedBrowser.is.touchScreen ? { onClick: () => { setListOpen(!listOpen); } } : {})}
						{...(theSharedBrowser.is.touchScreen ? { onTouchStart: () => { setListOpen(!listOpen); } } : {})}
						sx={{
							padding: 0,
							margin: 0,
							width: "unset",
							height: "unset"
						}}
					>
						<FontAwesomeIcon transform={setFAIconShrinkSize(5)} icon={faAngleDown} />
					</IconButton>
				</Tooltip>
				<Popper
					transition
					placement="top-start"
					open={listOpen}
					anchorEl={anchorRef.current}
					sx={{
						zIndex: 3
					}}
				>
					{({TransitionProps: props, placement}) => (
						<Grow
							{...props}
							style={{transformOrigin: "right top"}}
						>
							<Paper>
								<ClickAwayListener onClickAway={()=> { setListOpen(false); }}>
									<MenuList
										data-cy="webrtc-mic-list"
										autoFocusItem={listOpen}
										onKeyDown={() => {}}
									>
										{renderMicList()}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		</div>
	);
}
