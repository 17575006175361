import { Box, List } from "@mui/material";
import { FC, forwardRef } from "react";
import type { ListProps, VirtuosoProps } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";

import { IContact } from "../../models/ModelContact";
import { AsnNetDatabaseContact, AsnNetDatabaseContactList } from "../../ucserver/stubs/ENetUC_Common";
import { useStore } from "../../zustand/store";
import { Placeholder } from "../Placeholder/Placeholder";
import { ContactListItem } from "./ContactListItem";

const ItemHeight = 70;

interface IListContext {
	isLoading?: boolean;
}

type VirtuosoListPropsT = VirtuosoProps<AsnNetDatabaseContact, IListContext>;

const MUIComponents = {
	List: forwardRef(({ style, children }, listRef) => {
		return (
			<List style={{ padding: 0, ...style, margin: 0 }} component="div" ref={listRef}>
				{children}
			</List>
		);
	}) as FC<ListProps>,
	// eslint-disable-next-line @typescript-eslint/naming-convention
	EmptyPlaceholder: ({ context }: VirtuosoListPropsT) => {
		const { isLoading } = context || {};
		return !isLoading ? <Placeholder /> : <></>;
	}
};

interface IContactListProps {
	contacts: AsnNetDatabaseContactList;
	selectedContactId: string | null;
	isLoading?: boolean;
	searchTerm?: string;
	onClick: (contact: AsnNetDatabaseContact) => void;
	endReached?: VirtuosoProps<IContact, unknown>["endReached"];
}

export const ContactList: FC<IContactListProps> = ({
	contacts,
	selectedContactId,
	isLoading,
	searchTerm,
	onClick,
	endReached
}) => {
	const contactsMap = useStore((state) => state.contactsPresence);
	return (
		<Box flex={1}>
			<Virtuoso
				components={MUIComponents}
				data={contacts}
				itemContent={(index, contact) => {
					return (
						<ContactListItem
							contact={contact}
							onClick={onClick}
							contactsMap={contactsMap}
							selected={selectedContactId === contact.u8sSIPAddress}
							searchTerm={searchTerm}
						/>
					);
				}}
				context={{ isLoading }}
				endReached={endReached}
				overscan={ItemHeight * 10}
			/>
		</Box>
	);
};
