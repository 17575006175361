import {
	AsnAVAlertArgument,
	AsnAVCallMessageArgument,
	AsnAVCallMessageICE,
	AsnAVCallOrConnection,
	AsnAVCallStateChangedArgument,
	AsnAVConnectArgument,
	AsnAVDropCallArgument,
	AsnAVMakeCallArgument,
	AsnAVMakeCallResult,
	AsnAVNewCallArgument,
	AsnGetSTUNandTURNArgument,
	AsnGetSTUNandTURNResult,
	AsnSTUNandTURNConfigChangedArgument
} from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_AV";
import { theAudioPlayer, theUCClient } from "../globals";
import {
	Eav_CallType,
	EAvCallState,
	EAvDropReason,
	EAvMessageType,
	ECallState,
	IAVCallMessage,
	IAVCallMessageDeviceState,
	IAVCallParams,
	IRTCIceCandidateSimplified
} from "../interfaces/IAVCall";
import { IAVCallHandler } from "../interfaces/IHandlers";
import { generateGUID } from "../lib/commonHelper";
import { SocketTransport } from "../session/SocketTransport";
import AVCall from "../webrtc/AVCall";
import { IRTCIceServer } from "../webrtc/PeerConnectionClient";
import WebRTCHelper from "../webrtc/WebRTCHelper";
import { IAvCall } from "../zustand/avCallSlice";
import { getState } from "../zustand/store";

/**
 *
 */
export class AVManager implements IAVCallHandler {
	// The singleton instance of this class
	private static instance: AVManager;
	private socket: SocketTransport;
	private mySip: string | undefined;
	private avCalls: AVCall[];
	private myEndpointID: string | undefined;
	private iceServers: IRTCIceServer[];

	/**
	 * Constructs AVCallManager.
	 * Method is private as we follow the Singleton Approach using getInstance
	 */
	private constructor() {
		this.socket = SocketTransport.getInstance();
		this.socket.setAVCallHandler(this);
		this.mySip = undefined;
		this.avCalls = [];
		this.myEndpointID = undefined;
		this.iceServers = [];
		// Funktionen, die vor einem ausgehenden VideoChat aufgerufen werden. Sie werden mit async.series
		// ausgeführt, müssen also am Ende ihrer Arbeit einen Callback (letzter Parameter) aufrufen.
		// TODO WEBRTCTODO
		// this.createCallInterceptors = [];
	}

	/**
	 * Gets instance of AVCallManager to use as singleton.
	 *
	 * @returns - an instance of this class.
	 */
	public static getInstance(): AVManager {
		if (!AVManager.instance) {
			AVManager.instance = new AVManager();
		}
		return AVManager.instance;
	}

	/**
	 * Get all the avCalls
	 *
	 * @returns - the avCalls or undefined
	 */
	public getAVCalls(): AVCall[] | undefined {
		if (this.avCalls) {
			return this.avCalls;
		} else {
			return undefined;
		}
	}

	/**
	 * Get an avCall by id
	 *
	 * @param id - the id to search
	 * @returns - the avCall or undefined
	 */
	public getAVCall(id?: string): AVCall | undefined {
		return this.avCalls.find((item) => item.id === id);
	}

	/**
	 * Get avCall by target sip
	 *
	 * @param sip - the sip
	 * @returns - the first found AVCall or undefined
	 */
	public getAVCallByTargetSip(sip: string): AVCall | undefined {
		for (let i = 0; i < this.avCalls.length; i++) {
			if (this.avCalls[i].callState < 20 && this.avCalls[i].params.asnConfig.targetSipUri === sip) {
				return this.avCalls[i];
			}
		}

		// Workaround: Falls während einem AVCall ein Reconnect zum UCServer passiert, bekommt der andere das "Auflegen" nicht mit.
		// Deshalb existieren zu einer SIP evtl. mehrere AVCalls
		for (let i = 0; i < this.avCalls.length; i++) {
			if (this.avCalls[i].params.asnConfig.targetSipUri === sip) {
				return this.avCalls[i];
			}
		}

		return undefined;
	}

	/**
	 * Get avCall by my sip
	 *
	 * @param sip - the sip
	 * @returns - the first found AVCall or undefined
	 */
	public getAVCallByMySip(sip: string): AVCall | undefined {
		for (let i = 0; i < this.avCalls.length; i++) {
			if (this.avCalls[i].callState < 20 && this.avCalls[i].params.asnConfig.mySipUri === sip) {
				return this.avCalls[i];
			}
		}

		// Workaround: Falls während einem AVCall ein Reconnect zum UCServer passiert, bekommt der andere das "Auflegen" nicht mit.
		// Deshalb existieren zu einer SIP evtl. mehrere AVCalls
		for (let i = 0; i < this.avCalls.length; i++) {
			if (this.avCalls[i].params.asnConfig.mySipUri === sip) {
				return this.avCalls[i];
			}
		}

		return undefined;
	}

	/**
	 * Remove an av call
	 *
	 * @param avCall - the avCall to remove
	 */
	private removeAvCall(avCall: AVCall) {
		let index = -1;

		for (let i = 0; i < this.avCalls.length; i++) {
			if (this.avCalls[i].id === avCall.id) {
				index = i;
				break;
			}
		}

		if (index >= 0) {
			this.avCalls.splice(index, 1);
		}

		if (avCall.id) {
			getState().avCallRemove(avCall.id);
		}
	}

	/**
	 * Creates the avCall
	 *
	 * @param mySip - my sip
	 * @param sip - destination sip
	 * @param associatedTextChatConversationId - conversation id
	 * @param isVoiceChat - is a voice chat (false if audio/video)
	 * @returns - the avCall or undefined
	 */
	public createAvCall(
		mySip: string | undefined,
		sip: string,
		associatedTextChatConversationId: string,
		isVoiceChat?: true
	): AVCall | undefined {
		if (!mySip) {
			return undefined;
		}

		const mediaConstraints = WebRTCHelper.getMediaConstraints(isVoiceChat);

		this.mySip = mySip;
		this.myEndpointID = theUCClient.sessionID;

		const params: IAVCallParams = {
			isInitiator: true,
			asnCallType: isVoiceChat ? Eav_CallType.EAV_CALLTYPE_AUDIO : Eav_CallType.EAV_CALLTYPE_AUDIOVIDEO,
			asnConfig: {
				isConnected: false,
				mySipUri: this.mySip,
				targetSipUri: sip,
				myEndpointID: this.myEndpointID,
				targetEndpointID: "",
				associatedTextChatConversationId
			},
			mediaConstraints,
			peerConnectionConfig: {
				iceTransportPolicy: "relay",
				iceServers: undefined
			}
		};
		const avCall = new AVCall(params);
		this.avCalls.push(avCall);
		return avCall;
	}

	/**
	 * Get ICE servers
	 *
	 * @returns - the IRTCIceServer array or an Error
	 */
	public async getIceServers(): Promise<IRTCIceServer[] | Error> {
		const argument = new AsnGetSTUNandTURNArgument();
		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) {
					reject(result);
				} else {
					const res = result as AsnGetSTUNandTURNResult;
					const configs = res.listConfigSTUNandTURN;
					const iceServers = [];

					for (const config of configs) {
						const iceServer: IRTCIceServer = {
							urls: config.listURIs,
							username: undefined,
							credential: undefined
						};
						if (config.u8sUsername && config.u8sUsername.length > 0) {
							iceServer.username = config.u8sUsername;
						}

						if (config.u8sPassword && config.u8sPassword.length > 0) {
							iceServer.credential = config.u8sPassword;
						}

						iceServers.push(iceServer);
					}
					resolve(iceServers);
				}
			};
			this.socket.send("asnGetSTUNandTURN", argument, callBack);
		});
	}

	/**
	 * Makes a call sending a asnAVMakeCall argument to socket
	 *
	 * @param argument - the asn argument to send
	 * @returns - the asn call result or an Error
	 */
	private async makeCall(argument: AsnAVMakeCallArgument): Promise<AsnAVMakeCallResult | Error> {
		return new Promise((resolve, reject) => {
			const callBack = (result: unknown) => {
				if (result instanceof Error) {
					reject(result);
				} else {
					resolve(result as AsnAVMakeCallResult);
				}
			};
			this.socket.send("asnAVMakeCall", argument, callBack);
		});
	}

	/**
	 * Send signaling message
	 *
	 * @param avCall - the avCall where to send
	 * @param message - the message to send
	 */
	public async sendSignalingMessage(avCall: AVCall, message: IAVCallMessage) {
		const asnConfig = avCall.params.asnConfig;
		const isNewCall = avCall.pcClient && avCall.pcClient.getPeerConnectionStates()?.iceConnectionState === "new";

		let argument = null;

		// Offer für neuen Call verschicken
		if (message.type === "offer" && isNewCall && asnConfig.mySipUri) {
			const sdp = message.content as RTCSessionDescriptionInit;
			if (!sdp.sdp) {
				return;
			}
			argument = AsnAVMakeCallArgument.initEmpty();
			argument.callType = avCall.asnCallType;
			argument.u8sUriTo = asnConfig.targetSipUri;
			argument.u8sUriFrom = asnConfig.mySipUri;
			argument.u8sContentType = "application/sdp";
			argument.u8sAssociatedTextChatConversationID = asnConfig.associatedTextChatConversationId;
			argument.u8sBody = sdp.sdp;

			if (asnConfig && asnConfig.isPublicCall) {
				argument.optionalParams = [
					{
						key: "IsPublicCall",
						value: { integerdata: 1 }
					}
				];
			}

			const callResult = await this.makeCall(argument);
			if (callResult instanceof Error) {
				console.error("AvManager: error in asnAVMakeCallResult", callResult);
				return;
			}
			void theAudioPlayer.play("outgoingCall", true);
			const callAndConnectionId = callResult.callAndConnectionID;
			avCall.id = callAndConnectionId.u8sCallID;
			asnConfig.u8sCallID = callAndConnectionId.u8sCallID;
			asnConfig.myEndpointID = callAndConnectionId.u8sEndpointID;

			const videoTrack = avCall.localStream?.getVideoTracks()[0];
			const audioTrack = avCall.localStream?.getAudioTracks()[0];

			const avCallRedux: IAvCall = {
				id: callAndConnectionId.u8sCallID,
				callType: avCall.asnCallType,
				callState: avCall.callState,
				from: asnConfig.mySipUri,
				to: asnConfig.targetSipUri,
				conversationID: asnConfig.associatedTextChatConversationId,
				isInitiator: true,
				localVideoTrackID: videoTrack?.id,
				localAudioTrackID: audioTrack?.id,
				isRemoteAudioMuted: true,
				isRemoteVideoMuted: true
			};
			getState().avCallAdd(avCallRedux);
		} else if (message.type === "answer" && isNewCall) {
			if (!asnConfig.targetEndpointID || !asnConfig.myEndpointID || !avCall.id) {
				return;
			}
			const sdp = message.content as RTCSessionDescriptionInit;
			if (!sdp.sdp) {
				return;
			}
			// Answer verschicken
			argument = AsnAVConnectArgument.initEmpty();
			// argument.noResponse = true;
			argument.u8sCallID = avCall.id;
			// FIXME: Es gibt Drop Call "Another Party got the call" Das ist hier der Fall wenn man falsche Endpoints zurückschickt.
			// Das muss auch hier in den Events berücksichtigt werden.
			// Vorallem darf dann der Call nicht abgebaut werden!!!
			argument.endpointIDTo = asnConfig.targetEndpointID;
			argument.connectedEndPointID = asnConfig.myEndpointID;
			argument.callType = avCall.asnCallType;
			argument.u8sUriTo = asnConfig.targetSipUri;
			argument.u8sContentType = "application/sdp";
			argument.u8sBody = sdp.sdp;

			this.socket.send("asnAVConnect", argument);
		} else if (message.type === "offer" && !isNewCall) {
			if (!asnConfig.targetEndpointID || !asnConfig.myEndpointID || !avCall.id) {
				return;
			}
			const sdp = message.content as RTCSessionDescriptionInit;
			if (!sdp.sdp) {
				return;
			}
			// Renegotiate Offer
			argument = AsnAVCallMessageArgument.initEmpty();
			// argument.noResponse = true;
			argument.u8sCallID = avCall.id;
			argument.endpointIDTo = asnConfig.targetEndpointID;
			argument.endpointIDFrom = asnConfig.myEndpointID;
			argument.messageType = EAvMessageType.RENEGOTIATE_OFFER;
			argument.u8sUriTo = asnConfig.targetSipUri;
			argument.u8sContentType = "application/sdp";
			argument.u8sBody = sdp.sdp;

			this.socket.send("asnAVCallMessage", argument);
		} else if (message.type === "answer" && !isNewCall) {
			if (!asnConfig.targetEndpointID || !asnConfig.myEndpointID || !avCall.id) {
				return;
			}
			const sdp = message.content as RTCSessionDescriptionInit;
			if (!sdp.sdp) {
				return;
			}
			// Renegotiate Answer
			argument = AsnAVCallMessageArgument.initEmpty();
			// argument.noResponse = true;
			argument.u8sCallID = avCall.id;
			argument.endpointIDTo = asnConfig.targetEndpointID;
			argument.endpointIDFrom = asnConfig.myEndpointID;
			argument.messageType = EAvMessageType.RENEGOTIATE_ANSWER;
			argument.u8sUriTo = asnConfig.targetSipUri;
			argument.u8sContentType = "application/sdp";
			argument.u8sBody = sdp.sdp;

			this.socket.send("asnAVCallMessage", argument);
		} else if (message.type === "candidate") {
			if (!avCall.id || !asnConfig.myEndpointID) {
				return;
			}
			const newCandidate = message.content as IRTCIceCandidateSimplified;
			if (!newCandidate.candidate || !newCandidate.sdpMid || newCandidate.sdpMLineIndex === null) {
				return;
			}
			// ICE Kandidaten verschicken
			// var candLine = 'a=mid:' + message.id + '\r\n' + message.candidate + '\r\n';
			const candidate = AsnAVCallMessageICE.initEmpty();
			candidate.candidate = newCandidate.candidate;
			candidate.sdpMid = newCandidate.sdpMid;
			candidate.sdpMLineIndex = newCandidate.sdpMLineIndex;

			argument = AsnAVCallMessageArgument.initEmpty();
			argument.u8sCallID = avCall.id;
			// argument.endpointIDTo = asnConfig.targetEndpointID;
			// Leerer Endpoint damit ICE Kandidaten sofort gesendet werden können. Noch bevor jemand den
			// Call über asnAvConnect angenomman hat. ProCall macht das genauso.
			argument.endpointIDTo = "";
			argument.endpointIDFrom = asnConfig.myEndpointID;
			argument.messageType = EAvMessageType.ICE_CANDIDATE_SDP;
			argument.u8sUriTo = asnConfig.targetSipUri;
			argument.u8sContentType = "application/sdp";
			argument.iceCandidate = candidate;

			this.socket.send("asnAVCallMessage", argument);
		} else if (message.type === "end-of-candidates") {
			if (!asnConfig.targetEndpointID || !asnConfig.myEndpointID || !avCall.id) {
				return;
			}
			argument = AsnAVCallMessageArgument.initEmpty();
			// argument.noResponse = true;
			argument.u8sCallID = avCall.id;
			argument.endpointIDTo = asnConfig.targetEndpointID;
			argument.endpointIDFrom = asnConfig.myEndpointID;
			argument.messageType = EAvMessageType.ICE_CANDIDATE_END;
			argument.u8sUriTo = asnConfig.targetSipUri;
			// argument.u8sContentType = 'application/sdp';
			// argument.u8sBody = message.sdp;

			this.socket.send("asnAVCallMessage", argument);
		} else if (message.type === "bye") {
			const reason = message.content as EAvDropReason;
			argument = AsnAVDropCallArgument.initEmpty();
			argument.u8sCause = "Normal Call Clearing";
			argument.iAvDropReason = reason ?? 0;
			argument.dropTarget = AsnAVCallOrConnection.initEmpty();
			argument.dropTarget.u8sCallID = avCall.id;

			this.socket.send("asnAVDropCall", argument);
		} else if (message.type === "callstatechange") {
			if (!asnConfig.targetEndpointID || !asnConfig.myEndpointID || !avCall.id || !asnConfig.mySipUri) {
				return;
			}
			const state = message.content as EAvCallState;
			// Callstates verschicken (estos spezifisch)
			argument = AsnAVCallStateChangedArgument.initEmpty();
			argument.u8sCallID = avCall.id;
			argument.endpointIDTo = asnConfig.targetEndpointID;
			argument.endpointIDFrom = asnConfig.myEndpointID;
			argument.u8sUriTo = asnConfig.targetSipUri;
			argument.u8sUriFrom = asnConfig.mySipUri;
			argument.callState = state;

			this.socket.send("asnAVCallStateChanged", argument);
		} else if (message.type === "devicestate") {
			if (!asnConfig.targetEndpointID || !asnConfig.myEndpointID || !avCall.id) {
				return;
			}
			const state = message.content as IAVCallMessageDeviceState;
			if (!state.asnType || !state.body) {
				return;
			}
			// Device status (mute/unmite und Kamerasteuerung) schicken
			argument = AsnAVCallMessageArgument.initEmpty();
			argument.u8sCallID = avCall.id;
			argument.endpointIDTo = asnConfig.targetEndpointID;
			argument.endpointIDFrom = asnConfig.myEndpointID;
			argument.messageType = state.asnType;
			argument.u8sUriTo = asnConfig.targetSipUri;
			argument.u8sContentType = "application/mediastate";
			argument.u8sBody = state.body;

			this.socket.send("asnAVCallMessage", argument);
		} else if (message.type === "dummy") {
			// Dummy Nachrichten werden verschickt um die Sendequeue in AvCall neu anzustoßen.
		} else {
			console.warn("AvManager: unknown signaling message ", message);
		}
	}

	/**
	 * Handling the newCall event from the websocket
	 *
	 * @param argument - the AsnAVNewCallArgument
	 */
	public onEvent_asnAVNewCall(argument: AsnAVNewCallArgument) {
		console.log("AvManager: got onAsnAVNewCall", argument);
		const offerMessage: IAVCallMessage = {
			type: "offer",
			content: {
				type: "offer",
				sdp: argument.u8sBody
			}
		};

		const audioOnly =
			argument.callType === Eav_CallType.EAV_CALLTYPE_AUDIO ||
			argument.callType === Eav_CallType.EAV_CALLTYPE_DESKTOPSHARING
				? true
				: undefined;
		const mediaConstraints = WebRTCHelper.getMediaConstraints(audioOnly);

		this.myEndpointID = theUCClient.sessionID;

		const params: IAVCallParams = {
			isInitiator: false,
			asnCallType: argument.callType,
			asnConfig: {
				isConnected: true,
				targetSipUri: argument.u8sUriFrom,
				mySipUri: argument.u8sUriTo,
				targetEndpointID: argument.endpointIDFrom,
				myEndpointID: this.myEndpointID,
				associatedTextChatConversationId: argument.u8sAssociatedTextChatConversationID ?? generateGUID()
			},
			mediaConstraints,
			peerConnectionConfig: {
				iceTransportPolicy: "relay",
				iceServers: undefined
			},
			messages: [offerMessage]
		};

		const avCall = new AVCall(params);
		avCall.id = argument.callAndConnectionID.u8sCallID; // Wird vom Server nach einem AsnAVMakeCallArgument (Offer) gesetzt.
		avCall.callState = ECallState.INCOMING;
		avCall.asnCallType = argument.callType;
		this.avCalls.push(avCall);

		if (!params.asnConfig.targetEndpointID || !params.asnConfig.myEndpointID) {
			return;
		}
		// Alert schicken. Teilt dem UCServer mit, dass es hier klingelt
		const alertArgument = AsnAVAlertArgument.initEmpty();
		alertArgument.u8sCallID = avCall.id;
		alertArgument.endpointIDTo = params.asnConfig.targetEndpointID;
		alertArgument.endpointIDFrom = params.asnConfig.myEndpointID;
		alertArgument.u8sUriTo = params.asnConfig.targetSipUri;

		this.socket.send("asnAVAlert", alertArgument);

		const avCallRedux: IAvCall = {
			id: avCall.id,
			callType: avCall.asnCallType,
			callState: avCall.callState,
			from: argument.u8sUriFrom,
			to: argument.u8sUriTo,
			conversationID: avCall.params.asnConfig.associatedTextChatConversationId,
			isInitiator: false,
			isRemoteAudioMuted: false,
			isRemoteVideoMuted: false
		};
		getState().avCallAdd(avCallRedux);

		// const notificationsAllowed = store.getState().settings.notifications.notificationsAllowed;
		// if (notificationsAllowed) {
		// 	// Notification
		// 	const notification: INotification = {
		// 		id: avCall.id,
		// 		notificationType: avCall.asnCallType === Eav_CallType.EAV_CALLTYPE_AUDIOVIDEO ? "videoCall" : "audioCall",
		// 		conversationID: avCall.params.asnConfig.associatedTextChatConversationId,
		// 		senderURI: argument.u8sUriFrom
		// 	};
		// 	store.dispatch(notifAddNotificationToQueue(notification));
		// }
	}

	/**
	 * Handling the asnAVConnect event from the websocket
	 *
	 * @param argument - the AsnAVConnectArgument
	 */
	public async onEvent_asnAVConnect(argument: AsnAVConnectArgument) {
		console.log("onEvent_asAVConnectArgument", argument);

		const avCall = this.getAVCall(argument.u8sCallID);

		if (!avCall) {
			console.error("AvManager: Got asnAVConnect event for unknown call id " + argument.u8sCallID);
			return;
		}

		avCall.callState = ECallState.CONNECTIONPENDING;

		const asnConfig = avCall.params.asnConfig;
		asnConfig.isConnected = true;

		if (avCall.params.isInitiator) {
			// asnConfig.endpointIDFrom = asnAVConnectArgument.endpointIDTo;     // ID des Message Empfaengers
			asnConfig.targetEndpointID = argument.connectedEndPointID; // ID des Message Sendenden
		}

		// Evtl. signaling messages verschicken, die noch in der Queue hängen.
		// Das sind vorallem ICE-Kandidaten.
		avCall.sendSignalingMessage({ type: "dummy" });

		// RemoteDescription an die PeerConnection weiterleiten
		const sdp: RTCSessionDescriptionInit = {
			type: "answer",
			sdp: argument.u8sBody
		};
		await avCall.onRecvSignalingChannelMessage({ type: "answer", content: sdp });

		// An Gegenstelle Callstate schicken
		await this.sendSignalingMessage(avCall, { type: "callstatechange", content: EAvCallState.CALLSTATE_CONNECT });

		// Connection Timeout starten
		avCall.startConnectionTimeout();
	}

	/**
	 * Handle asnAVCallStateChanged from websocket
	 *
	 * @param argument - the AsnAVCallStateChangedArgument
	 */
	public async onEvent_asnAVCallStateChanged(argument: AsnAVCallStateChangedArgument) {
		console.log("onEvent_asAVConnectArgument", argument);

		const avCall = this.getAVCall(argument.u8sCallID);
		if (!avCall) {
			return;
		}

		if (argument.callState === EAvCallState.CALLSTATE_CONNECT) {
			avCall.startConnectionTimeout();
		}
	}

	/**
	 * Handle asnAVCallMessage from websocket
	 *
	 * @param argument - the AsnAVCallMessageArgument
	 */
	public async onEvent_asnAVCallMessage(argument: AsnAVCallMessageArgument) {
		console.log("onEvent_asnAVCallMessageArgument", argument);
		const avCall = this.getAVCall(argument.u8sCallID);
		if (!avCall) {
			return;
		}

		// Remote ICE Kandidaten
		if (argument.messageType === EAvMessageType.ICE_CANDIDATE_SDP) {
			if (!argument.iceCandidate) {
				return;
			}
			await avCall.onRecvSignalingChannelMessage({
				type: "candidate",
				content: {
					sdpMLineIndex: argument.iceCandidate.sdpMLineIndex,
					sdpMid: argument.iceCandidate.sdpMid,
					candidate: argument.iceCandidate.candidate
				}
			});
		} else if (argument.messageType === EAvMessageType.ICE_CANDIDATE_END) {
			// Remote hat alle ICE Kandidaten geschickt
			console.log("AvManager: Received all remote candidates");

			await avCall.onRecvSignalingChannelMessage({
				type: "end-of-candidates"
			});
		} else if (argument.messageType === EAvMessageType.RENEGOTIATE_OFFER) {
			// Renegotiate offer. Im Body ist die Remote Session Description
			console.log("AvManager: got renegotiate offer", argument);
			const sdp: RTCSessionDescriptionInit = {
				type: "offer",
				sdp: argument.u8sBody
			};
			const offerMessage: IAVCallMessage = { type: "offer", content: sdp };
			await avCall.onRecvSignalingChannelMessage(offerMessage);
		} else if (argument.messageType === EAvMessageType.RENEGOTIATE_ANSWER) {
			// Renegotiate answer. Im Body ist die Remote Session Description

			// Evtl. signaling messages verschicken, die noch in der Queue hängen.
			// Das sind vorallem ICE-Kandidaten.
			avCall.sendSignalingMessage({ type: "dummy" });
			// RemoteDescription an die PeerConnection weiterleiten
			const sdp: RTCSessionDescriptionInit = {
				type: "answer",
				sdp: argument.u8sBody
			};
			await avCall.onRecvSignalingChannelMessage({ type: "answer", content: sdp });
		} else if (argument.messageType === EAvMessageType.MY_DEVICE_STATE) {
			// we may only want to trigger some kind of MY_DEVICE_STATE messages
			switch (argument.u8sBody) {
				case "MIC_MUTE":
				case "MIC_UNMUTE":
					if (avCall.id) {
						getState().avCallSetRemoteAudioMuted({
							id: avCall.id,
							isRemoteAudioMuted: argument.u8sBody === "MIC_MUTE"
						});
					}
					break;
				case "CAM_MUTE":
				case "CAM_UNMUTE":
					if (avCall.id) {
						getState().avCallSetRemoteVideoMuted({
							id: avCall.id,
							isRemoteVideoMuted: argument.u8sBody === "CAM_MUTE"
						});
					}
					break;
				default:
					break;
			}
		} else {
			console.warn("AvManager: got unhandled onAsnAVCallMessageArgument", argument);
		}
	}

	/**
	 * Handle AsnAVAlertArgument from websocket
	 *
	 * @param argument - the AsnAVAlertArgument
	 */
	public async onEvent_asnAVAlert(argument: AsnAVAlertArgument) {
		console.log("onEvent_asnAVAlert", argument);

		const avCall = this.getAVCall(argument.u8sCallID);

		if (avCall) {
			avCall.setAndDispatchCallState(ECallState.RINGING);
		} else {
			console.warn("AvManager: got asnAVAlert event for unknown callId " + argument.u8sCallID);
		}
	}

	/**
	 * Handle AsnAVDropCallArgument from websocket
	 *
	 * @param argument - the AsnAVDropCallArgument
	 */
	public async onEvent_asnAVDropCall(argument: AsnAVDropCallArgument) {
		console.log("onEvent_asnAVDropCall", argument);

		// FIXME: iAvDropReason aus Header in JS Enum abbilden

		let callId = argument.dropTarget && argument.dropTarget.u8sCallID;
		// callId = callId || (asnAVDropCallArgument.dropTarget && asnAVDropCallArgument.dropTarget.logconnectionID && asnAVDropCallArgument.dropTarget.logconnectionID.u8sCallID);
		callId =
			callId || (argument.dropTarget && argument.dropTarget.connectionID && argument.dropTarget.connectionID.u8sCallID);

		if (callId) {
			const avCall = this.getAVCall(callId);

			// iAvDropReason = 4 bedeutet "other party got the call"
			if (avCall && argument.iAvDropReason !== 4) {
				// if (avCall) {
				avCall.onRemoteHangup(argument.iAvDropReason);
				this.removeAvCall(avCall);
			}
		}
	}

	/**
	 * Handling asnSTUNandTURNConfigChanged event from websocket
	 *
	 * @param argument - the AsnSTUNandTURNConfigChangedArgument argument
	 */
	public async onEvent_asnSTUNandTURNConfigChanged(argument: AsnSTUNandTURNConfigChangedArgument) {
		console.log("onEvent_asnSTUNandTURNConfigChanged", argument);

		const configs = argument.listConfigSTUNandTURN;
		const iceServers = [];

		for (const config of configs) {
			const iceServer: IRTCIceServer = {
				urls: config.listURIs,
				username: undefined,
				credential: undefined
			};
			if (config.u8sUsername && config.u8sUsername.length > 0) {
				iceServer.username = config.u8sUsername;
			}

			if (config.u8sPassword && config.u8sPassword.length > 0) {
				iceServer.credential = config.u8sPassword;
			}

			iceServers.push(iceServer);
		}

		this.iceServers = iceServers;
	}

	/**
	 * Get ice candidate type helper function
	 *
	 * @param candidateStr - the candidate string
	 * @returns - the candidate type
	 */
	public getIceCandidateType(candidateStr: string): string {
		return candidateStr.split(" ")[7];
	}
}
