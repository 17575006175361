import React, { useState, useRef } from "react";
import "./sharedSpeakerSelectorButton.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Tooltip } from "@mui/material";
import { faVolume } from "@fortawesome/pro-light-svg-icons";
import { IMediaDevice } from "../../interfaces/IMediaDevice";
import { IButtonCommonProps } from "../../interfaces/IButtonCommonProps";
import { setFAIconShrinkSize, theSharedBrowser, theSharedTranslationManager } from "../../../../helpers/helpers";

interface IProps extends IButtonCommonProps {
	onSpeakerSelected?: (speaker: string) => void;
	selectedSpeakerID: string | undefined;
	speakerList: IMediaDevice[];
}

/**
 * Speaker Selector Button
 *
 * @param props - props
 * @returns - the component
 */
export default function SharedSpeakerSelectorButton(props: IProps): JSX.Element {
	const anchorRef = useRef<HTMLButtonElement>(null);
	const [listOpen, setListOpen] = useState<boolean>(false);

	const width: string = props.width + "px";
	const height: string = props.height + "px";

	/**
	 * Render the speaker list in the dropdown menu
	 * @returns - the elements or null
	 */
	const renderSpeakerList = (): JSX.Element[] | null => {
		if (props.speakerList.length === 0)
			return null;
		const speakers: JSX.Element[] = [];
		props.speakerList.forEach((item, idx) => (
			speakers.push(
				<MenuItem
					key={item.deviceId}
					selected={item.deviceId === props.selectedSpeakerID}
					onClick={() => {
						setListOpen(false);
						props.onSpeakerSelected && props.onSpeakerSelected(item.deviceId);
					}}
				>
					{(item.label || `Speaker ${idx + 1}`) + (item.deviceId === props.selectedSpeakerID ? " ✓" : "")}
				</MenuItem>
			)
		));
		return speakers;
	};

	return (
		<div className="shared-speaker-selector-container">
			<Tooltip title={theSharedTranslationManager.getTranslation("IDS_AV_CHOOSE_SPEAKER")} placement="bottom">
				<span>
					<IconButton
						ref={anchorRef}
						{...(!theSharedBrowser.is.touchScreen ? { onClick: () => { setListOpen(!listOpen); } } : {})}
						{...(theSharedBrowser.is.touchScreen ? { onTouchStart: () => { setListOpen(!listOpen); } } : {})}
						sx={{
							padding: 0,
							margin: 0,
							width: width,
							height: height
						}}
						className={"shared-speaker-selector-active"}
						disabled={props.isDisabled}
					>
						<FontAwesomeIcon icon={faVolume} transform={setFAIconShrinkSize(5)} />
					</IconButton>
				</span>
			</Tooltip>
			<Popper
				transition
				placement="top-start"
				open={listOpen}
				anchorEl={anchorRef.current}
				sx={{
					zIndex: 1
				}}
			>
				{({ TransitionProps: props, placement }) => (
					<Grow
						{...props}
						style={{ transformOrigin: "right top" }}
					>
						<Paper>
							<ClickAwayListener onClickAway={() => { setListOpen(false); }}>
								<MenuList
									autoFocusItem={listOpen}
									onKeyDown={() => { }}
								>
									{renderSpeakerList()}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}
