// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_ClientCapabilities.ts
 * "UC-Server-Access-Protocol-ClientCapabilities" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.16, 10.08.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * Data structures and methods to transport and notify client capabilities between clients and the server
 * Each client has certain capabilities he is able to handle. They are used to tell other clients the kind of interactions each client is able to process.
 * If a client wants to start an audio call to another client both clients have to be able to process audio (speakers and microphone present).
 * As soon as a client is capable to handle audio it tells the server and thus other clients that the client is now ready to process audio requests.
 * Beside well known capabilities clients may exchange customs capabilities. (e.g. client is capable for some sort of sharing and the appropriate sharing resource id)
 */
// [PrintTSImports]
import { TSDeprecatedCallback } from "./TSDeprecatedCallback";
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
// [PrintTSRootTypes]
export const moduleName = "ENetUC_ClientCapabilities";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnClientCapabilityEnum {
	/** nothing, dummy value to set an empty list */
	bNothing = 0,
	/** client\/connection is capable to handle audio */
	bAudio = 1,
	/** client\/connection is capable to handle video */
	bVideo = 2,
	/** client\/connection is capable to handle desktop\/screen sharing */
	bScreenShare = 3,
	/** client\/connection is capable to replicate exchange calendar data */
	bCalendarExchange = 4,
	/** client\/connection is capable to replicate notes calendar data */
	bCalendarNotes = 5,
	/** client\/connection is capable to control a camera for video (movement, zoom, ...) */
	bCameraControl = 6,
	/** client\/connection is capable to handle public group chats via LiveChat */
	bPublicChat = 7,
	/** client\/connection is capable to handle public audio\/video calls via LiveChat */
	bPublicCall = 8,
	/** client\/connection is capable to handle conferencing */
	bConferencing = 9
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Data structure to transport capabilities a client\/connection is capable to handle
 * This data structure is used to transport client capabilities between the clients and the server
 * In an invoke towards the server the client tells what the client is capable to handle. This list
 * contains well known properties as well as custom properties a client wants to exchange with other clients.
 * Well known capabilites are an integer list which contains enums values of the AsnClientCapabilityEnum.
 * Additionaly clients may specify additional information they need to exchange with other clients using the customCapabilities
 * The serve builds a sum over all connected clients an notifies clients via the PresenceV2 implementation or changed events
 * within this interface.
 */
export class AsnClientCapabilitiesV2 {
	public constructor(that: AsnClientCapabilitiesV2) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientCapabilitiesV2 {
		return new AsnClientCapabilitiesV2({
			eClientCapabilities: new ENetUC_Common.SEQInteger(),
			customCapabilities: new ENetUC_Common.AsnStringPairList()
		});
	}

	public static type = "AsnClientCapabilitiesV2";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientCapabilitiesV2",
			...params,
			value: [
				ENetUC_Common.SEQInteger.getASN1Schema({ name: "eClientCapabilities" }),
				ENetUC_Common.AsnStringPairList.getASN1Schema({ name: "customCapabilities" })
			]
		});
	}

	/** List of capabilities the client or connection is capable to handle */
	public eClientCapabilities!: ENetUC_Common.SEQInteger;
	/**
	 * Custom capabilites clients share with each other as a name:value list
	 * the server creates a sum over the client provided capabilites
	 * if clients provide properties with same name but different values the list will containt names multiple times
	 */
	public customCapabilities!: ENetUC_Common.AsnStringPairList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnSetClientCapabilitiesV2Result {
	public constructor(that?: AsnSetClientCapabilitiesV2Result) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientCapabilitiesV2Result {
		return new AsnSetClientCapabilitiesV2Result();
	}

	public static type = "AsnSetClientCapabilitiesV2Result";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetClientCapabilitiesV2Result",
			...params,
			value: [

			]
		});
	}

}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for the asnGetClientCapabilitiesV2 event
 */
export class AsnGetClientCapabilitiesV2Argument {
	public constructor(that: AsnGetClientCapabilitiesV2Argument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetClientCapabilitiesV2Argument {
		return new AsnGetClientCapabilitiesV2Argument({
			u8slistContactIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	public static type = "AsnGetClientCapabilitiesV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetClientCapabilitiesV2Argument",
			...params,
			value: [
				ENetUC_Common.UTF8StringList.getASN1Schema({ name: "u8slistContactIDs" })
			]
		});
	}

	/** ContactIDs we want to have the capabilities for */
	public u8slistContactIDs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Client Capabilities with the user identifier to whom they belong
 */
export class AsnClientCapabilitiesV2ByID {
	public constructor(that: AsnClientCapabilitiesV2ByID) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientCapabilitiesV2ByID {
		return new AsnClientCapabilitiesV2ByID({
			u8sID: "",
			capabilities: AsnClientCapabilitiesV2.initEmpty()
		});
	}

	public static type = "AsnClientCapabilitiesV2ByID";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientCapabilitiesV2ByID",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sID" }),
				AsnClientCapabilitiesV2.getASN1Schema({ name: "capabilities" })
			]
		});
	}

	/** ContactID or Connection GUI the clientCaps belong to */
	public u8sID!: string;
	/** The client capabilities */
	public capabilities!: AsnClientCapabilitiesV2;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnClientCapabilitiesV2ByIDList extends Array<AsnClientCapabilitiesV2ByID> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnClientCapabilitiesV2ByID.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for the asnSetClientCapabilitiesV2 event
 */
export class AsnSetClientCapabilitiesV2Argument {
	public constructor(that: AsnSetClientCapabilitiesV2Argument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientCapabilitiesV2Argument {
		return new AsnSetClientCapabilitiesV2Argument({
			capabilities: AsnClientCapabilitiesV2.initEmpty()
		});
	}

	public static type = "AsnSetClientCapabilitiesV2Argument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnSetClientCapabilitiesV2Argument",
			...params,
			value: [
				AsnClientCapabilitiesV2.getASN1Schema({ name: "capabilities" })
			]
		});
	}

	public capabilities!: AsnClientCapabilitiesV2;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnGetClientCapabilitiesV2Result {
	public constructor(that: AsnGetClientCapabilitiesV2Result) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetClientCapabilitiesV2Result {
		return new AsnGetClientCapabilitiesV2Result({
			capabilities: new AsnClientCapabilitiesV2ByIDList()
		});
	}

	public static type = "AsnGetClientCapabilitiesV2Result";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnGetClientCapabilitiesV2Result",
			...params,
			value: [
				AsnClientCapabilitiesV2ByIDList.getASN1Schema({ name: "capabilities" })
			]
		});
	}

	/** List of capabilities */
	public capabilities!: AsnClientCapabilitiesV2ByIDList;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Argument for the asnClientCapabilitiesV2Changed method
 */
export class AsnClientCapabilitiesV2ChangedArgument {
	public constructor(that: AsnClientCapabilitiesV2ChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientCapabilitiesV2ChangedArgument {
		return new AsnClientCapabilitiesV2ChangedArgument({
			capabilities: new AsnClientCapabilitiesV2ByIDList()
		});
	}

	public static type = "AsnClientCapabilitiesV2ChangedArgument";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnClientCapabilitiesV2ChangedArgument",
			...params,
			value: [
				AsnClientCapabilitiesV2ByIDList.getASN1Schema({ name: "capabilities" })
			]
		});
	}

	/** Client capabilities by user list */
	public capabilities!: AsnClientCapabilitiesV2ByIDList;
}
