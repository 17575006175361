import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Stack,
	Tooltip
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { ComponentProps, FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { Header } from "../../components/common/Header/Header";
import { Typography } from "../../components/common/Typography/Typography";
import { ContactDetails } from "../../components/ContactDetails/ContactDetails";
import { theJournalManager } from "../../globals";
import { useStore } from "../../zustand/store";
import { ContactDetailsHeaderActions } from "./ContactDetailsHeaderActions";
import { CurrentJournalEntry } from "./CurrentJournalEntry";
import { JournalEntryActions } from "./JournalEntryActions";
import { JournalEntrySummary } from "./JournalEntrySummary";
import { RecentJournalEntries } from "./RecentJournalEntries";

interface IJournalEntryDetails extends ComponentProps<typeof Paper> {
	// used when the component is used as a separate column in the dashboard
	onClose?: () => void;
	// used when the component is displayed on top of journal entries list
	onBack?: () => void;
	contactID: string | null;
	journalEntryID: string | null;
	showContactDetails: boolean;
	setShowContactDetails: (showDetails: boolean) => void;
}

export const JournalEntryDetails: FC<IJournalEntryDetails> = ({
	onClose,
	onBack,
	contactID,
	journalEntryID,
	showContactDetails: showDetails,
	setShowContactDetails: setShowDetails,
	...props
}) => {
	const { t } = useTranslation();
	const readFilter = useStore((state) => state.journalReadFilter);
	const journalEntries = useStore(useCallback((state) => state.getFilteredJournalEntries(readFilter), [readFilter]));
	const contactsDetails = useStore((state) => state.contactsDetails);
	const journalEntry = useStore((state) => state.journalEntries.get(journalEntryID!));
	const contact = contactsDetails.get(contactID!);
	const { data: recentJournalEntries } = useQuery(
		["journalEntries", journalEntry?.u8sConnectionID || ""],
		async () => {
			const result = await theJournalManager.getContactJournalEntries([journalEntry!.u8sPhoneNumber]); // TODO - we might should use the contactID here
			return result?.journalList;
		},
		{
			// The query will not execute until the journal entry exists
			enabled: !!journalEntry?.u8sConnectionID && !!journalEntry!.u8sPhoneNumber
		}
	);

	useEffect(() => {
		// deselect the journal entry if it is not existing anymore
		if (journalEntryID && !journalEntries.find((entry) => entry.u8sConnectionID === journalEntryID)) {
			onBack?.();
			onClose?.();
		}
	}, [journalEntries, journalEntryID, onBack, onClose]);

	const filteredContactJournalEntries = useMemo(() => {
		const entriesWithoutSelected = recentJournalEntries?.filter((entry) => entry.u8sConnectionID !== journalEntryID);
		return entriesWithoutSelected?.slice(0, 5);
	}, [recentJournalEntries, journalEntryID]);

	if (!journalEntry) {
		return null;
	}

	console.log("rerender");

	return (
		<Paper sx={{ height: "100%", overflow: "hidden" }} {...props}>
			<Stack sx={{ height: "100%", display: "flex", flexDirection: "row" }}>
				<AnimatePresence>
					{!showDetails && (
						<motion.div
							initial={{ x: "-100vw" }}
							animate={{ x: 0 }}
							exit={{ x: "-100vw" }}
							transition={{
								ease: "linear",
								duration: 0.25
							}}
							style={{ width: "100%", height: "100%" }}
						>
							<Stack sx={{ overflow: "hidden" }}>
								<Header
									title={contact?.u8sDisplayName || journalEntry.u8sContactName || journalEntry.u8sPhoneNumber}
									onBack={onBack}
									onClose={onClose}
									hideDivider
									sx={{ pb: 1 }}
								/>
								<ContactDetailsHeaderActions contact={contact} />

								<Divider sx={{ mx: 2, mt: 2, mb: 0 }} />
								<Stack sx={{ overflow: "auto" }}>
									<List>
										<ListItem disablePadding>
											<Tooltip title={!contact && t("IDS_CONTACT_DETAILS_NO_DATA")}>
												<div style={{ display: "flex", flex: "1 1 100%", flexDirection: "row" }}>
													<ListItemButton
														disabled={!contact}
														onClick={() => {
															setShowDetails(true);
														}}
													>
														<ListItemText primary={t("IDS_CONTACT_DETAILS")} />
														<ListItemIcon sx={{ justifyContent: "flex-end" }}>
															<FontAwesomeIcon icon={faArrowRight} />
														</ListItemIcon>
													</ListItemButton>
												</div>
											</Tooltip>
										</ListItem>
									</List>
									<Divider sx={{ mx: 2, mb: 1 }} />

									<JournalEntrySummary journalEntry={journalEntry} mx={2} mb={1} />
									<CurrentJournalEntry journalEntry={journalEntry} />
									{!!filteredContactJournalEntries?.length && (
										<>
											<Typography variant="body2" sx={{ ml: 2, mt: 1 }}>
												{t("IDS_CONTACT_DETAILS_RECENT_CALLS")}
											</Typography>
											<RecentJournalEntries journalEntries={filteredContactJournalEntries} />
										</>
									)}
									<Divider sx={{ mx: 2, my: 1 }} />
									<JournalEntryActions journalEntryID={journalEntry.u8sConnectionID} />
								</Stack>
							</Stack>
						</motion.div>
					)}
				</AnimatePresence>
				<AnimatePresence>
					{showDetails && (
						<motion.div
							initial={{ x: "100vw" }}
							animate={{ x: 0 }}
							exit={{ x: "100vw" }}
							transition={{
								ease: "linear",
								duration: 0.25
							}}
							style={{ width: "100%", height: "100%" }}
						>
							<ContactDetails
								contactID={contactID}
								onBack={() => {
									setShowDetails(false);
								}}
							/>
						</motion.div>
					)}
				</AnimatePresence>
			</Stack>
		</Paper>
	);
};
