// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetROSEInterface.ts
 * "UC-Server-Access-Protocol-Main" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * General interface
 * ## Module description
 * General interfacefunctions not included in any other module.
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
import * as ENetUC_Common_Appointments from "./ENetUC_Common_Appointments";

// [PrintTSTypeDefCode] AsnContactStatusParameterEnum
// [PrintTSEnumDefCode] AsnContactStatusParameterEnum
export enum AsnContactStatusParameterEnum {
	activeContactID = 0,
	phoneNumber = 1,
	eMail = 2
}

// [PrintTSTypeDefCode] AsnETAPIDialNumberFormat
// [PrintTSEnumDefCode] AsnETAPIDialNumberFormat
export enum AsnETAPIDialNumberFormat {
	/** Normal formatting, all rules apply */
	eTAPIDIALNUMBERFORMATNORMAL = 0,
	/** Make all numbers upercanoncal (e.g. +49815136856177) */
	eTAPIDIALNUMBERFORMATSUPERCANONIC = 1,
	/** No rules apply, numbers will be dialed like they were entered; only special character will be removed */
	eTAPIDIALNUMBERFORMATPLAIN = 2,
	/** Plain numbers will be dialed like they were entered, supercanonical numbers will be formatted. The PBX will always get dialable numbers. */
	eTAPIDIALNUMBERFORMATDIALABLEPLAIN = 3
}

// [PrintTSTypeDefCode] AsnERemoteTapiNumberFormat
// [PrintTSEnumDefCode] AsnERemoteTapiNumberFormat
export enum AsnERemoteTapiNumberFormat {
	/** Plain, wie von PBX */
	eREMOTETAPINUMBERFORMATPLAIN = 0,
	/** Vorformatiert, aber nicht Superkanonisch */
	eREMOTETAPINUMBERFORMATPREFORMAT = 1,
	/** Superkanonisch */
	eREMOTETAPINUMBERFORMATSUPERCANONIC = 2
}

// [PrintTSTypeDefCode] AsnERemoteTapiDialFormat
// [PrintTSEnumDefCode] AsnERemoteTapiDialFormat
export enum AsnERemoteTapiDialFormat {
	/** Plain wÃ¤hlen, nur PC Wahl Final anwenden */
	eREMOTETAPIDIALFORMATPLAIN = 0,
	/** Komplett Formatieren */
	eREMOTETAPIDIALFORMATALL = 1
}

// [PrintTSTypeDefCode] AsnERemoteTapiLineNames
// [PrintTSEnumDefCode] AsnERemoteTapiLineNames
export enum AsnERemoteTapiLineNames {
	/** Wie vom TAPI-Treiber gemeldet */
	eREMOTETAPILINENAMESORIGINAL = 0,
	/** Mit Standortinformationen erweitert */
	eREMOTETAPILINENAMESLINENUMBER = 1
}

// [PrintTSTypeDefCode] AsnForwardControllerMode
// [PrintTSEnumDefCode] AsnForwardControllerMode
export enum AsnForwardControllerMode {
	/** Die Rufumleitung wird in der PBX gesetzt */
	iCTIFORWARDCONTROLLERPBX = 0,
	/** Die Rufumleitung wird im TapiServer gesetzt (Administrative Umleitung) */
	iCTIFORWARDCONTROLLERTAPISERVERADMIN = 1,
	/** Die Rufumleitung wird im TapiServer gesetzt (User Umleitung) */
	iCTIFORWARDCONTROLLERTAPISERVERUSER = 2
}

// [PrintTSTypeDefCode] AsnForwardMode
// [PrintTSEnumDefCode] AsnForwardMode
export enum AsnForwardMode {
	/** Forward unknown */
	iCTIFORWARDUNKNOWN = 0,
	/** Forward Immediate */
	iCTIFORWARDIMMEDIATE = 1,
	/** Forward on Busy */
	iCTIFORWARDBUSY = 2,
	/** Forward on No Answer */
	iCTIFORWARDNOANSW = 3,
	/** Forward on Busy for internal */
	iCTIFORWARDBUSYINT = 4,
	/** Forward on Busy for external */
	iCTIFORWARDBUSYEXT = 5,
	/** Forward on No answer for int */
	iCTIFORWARDNOANSINT = 6,
	/** Forward on No answer for ext */
	iCTIFORWARDNOANSEXT = 7,
	/** Forward immediate for intern */
	iCTIFORWARDIMMINT = 8,
	/** Forward immediate for extern */
	iCTIFORWARDIMMEXT = 9,
	/** Backend specific forwarding */
	iCTIBACKENDSPECIFIC = 10
}

// [PrintTSTypeDefCode] AsnPresenceStateEnum
// [PrintTSEnumDefCode] AsnPresenceStateEnum
export enum AsnPresenceStateEnum {
	/** Presence is not available, contact is unknown. */
	eUNCONTROLLED = 0,
	/** Contact is available. */
	eAVAILABLE = 1,
	/** Contact is busy. */
	eBUSY = 2,
	/** Contact is absent. */
	eABSENT = 3,
	/** Contact wished to not being disturbed. */
	eDONOTDISTURB = 4,
	/** Something does not work, line is not connected, device is broken etc. Contact may not get into this state. */
	eOUTOFORDER = 7
}

// [PrintTSTypeDefCode] AsnPresenceStateFlags
// [PrintTSEnumDefCode] AsnPresenceStateFlags
export enum AsnPresenceStateFlags {
	/** 0x00010000 - The contact is doing work after a call and should be treated as \"busy\". (added after PCE 5.1) */
	eWORKINGAFTERCALL = 65536,
	/** 0x00020000 - The contact is participating a conference with our conferencing product (added with PCE 7.1) */
	eCONFERENCING = 131072,
	/** 0x01000000 - The contact has a call ringing on one of his phone or A\/V lines. */
	eRINGING = 16777216,
	/** 0x02000000 - The contavct has currently an active appointment in \"absent\", \"busy\" or \"free\" state. */
	eAPPOINTMENT = 33554432,
	/** 0x04000000 - The contact is in a call. */
	eCALL = 67108864,
	/** 0x08000000 - The contact is offline. */
	eOFFLINE = 134217728,
	/** 0x10000000 - The contact has set a note. */
	eNOTESET = 268435456,
	/** 0x20000000 - The contact does not used his mouse or keyboard for a time or is only logged in with a mobile device. */
	eIDLE = 536870912,
	/** 0x40000000 - The contact is most likely reachable on his mobile device. */
	eMOBILEAVAILABLITY = 1073741824
}

// [PrintTSTypeDefCode] AsnPresenceStateMasks
// [PrintTSEnumDefCode] AsnPresenceStateMasks
export enum AsnPresenceStateMasks {
	/** 0x000000ff - Currently the lower byte contains the presence state */
	ePresenceStateMask = 255,
	/** 0x00ff0000 - Additional flags introduced later in the development process of ProCall after Version 5.1. */
	ePresenceStateFlagsMaskAdd = 16711680,
	/** 0x7f000000 - Currently the top most byte of the DWORD contains the presence state flags. Addendum: a technical constraint in the c# library prevents the usage of the top most bit. */
	ePresenceStateFlagsMask = 2130706432
}

// [PrintTSTypeDefCode] EnumCallState
// [PrintTSEnumDefCode] EnumCallState
export enum EnumCallState {
	/** Call is in an unknown state. */
	eCALLSTATEUNKNOWN = 0,
	/** Call was destroyed by dropping it. */
	eCALLSTATEDESTROYED = 1,
	/** Call is ringing inbound. */
	eCALLSTATEOFFERING = 2,
	/** User is dialing. */
	eCALLSTATEDIALING = 4,
	/** Call is ringing outbound (call number is complete) */
	eCALLSTATERINGBACK = 8,
	/** Call outbound is busy. */
	eCALLSTATEBUSY = 16,
	/** Call is connected (same for in- and outbound) */
	eCALLSTATECONNECTED = 32,
	/** Call is part of a call conference. */
	eCALLSTATECONFERENCED = 64,
	/** Call is on hold. */
	eCALLSTATEHOLD = 128,
	/** Call is disconnected by the call partner. The user should drop the call too, then it will get destroyed too. */
	eCALLSTATEDISCONNECTED = 256,
	/** Call is in a prepare state (special for procall, this is not a normal PBX state). */
	eCALLSTATEPREPARECALL = 512
}

// [PrintTSTypeDefCode] EnumCallDisconnectedReason
// [PrintTSEnumDefCode] EnumCallDisconnectedReason
export enum EnumCallDisconnectedReason {
	/** Disconnect reason unknown, even if the call was successful connected and one of the participants has dropped the call. */
	eDISCONNECTEDREASONUNKNOWN = 0,
	/** The call partner did not answer the call. */
	eDISCONNECTEDREASONUNCONNECTED = 1,
	/** The call partner is busy. */
	eDISCONNECTEDREASONBUSY = 2,
	/** Anruf wurde aus einem ECALLSTATE_OFFERING heraus mit redirect oder Pickup weggeholt */
	eDISCONNECTEDREASONREDIRECTEDUNKNOWN = 3,
	/** The call was redirected or picked in the state offering (eCALLSTATEOFFERING) by the line owner. */
	eDISCONNECTEDREASONREDIRECTEDBYLINEOWNER = 4
}

// [PrintTSTypeDefCode] EnumRemoteContactSource
// [PrintTSEnumDefCode] EnumRemoteContactSource
export enum EnumRemoteContactSource {
	/** Data source is unknown\/not set */
	eUNKNOWN = 0,
	/** Server provided the contact data */
	eSERVERPROVIDED = 1,
	/** The client provided the contact data */
	eCLIENTPROVIDED = 2,
	/** Another client provided the contact data (if the same user has more than one client running) */
	eOTHERCLIENTPROVIDED = 3
}

// [PrintTSTypeDefCode] EnumCallType
// [PrintTSEnumDefCode] EnumCallType
export enum EnumCallType {
	/** Just a normal call. */
	eCALLTYPENORMAL = 0,
	/** Consultation call. */
	eCALLTYPECONSULT = 1,
	/** Conference controller call. */
	eCALLTYPECONFERENCECONTROLER = 2,
	/** Callback call (callback while busy or callback was not answered) */
	eCALLTYPECALLBACK = 3,
	/** Conference member call. */
	eCALLTYPECONFERENCEMEMBER = 4,
	/** Conference controller while building up the conference (US conference model) */
	eCALLTYPECONFERENCECONTROLERPENDING = 5,
	/** Conference member while building up the conference (US conference model) */
	eCALLTYPECONFERENCEMEMBERPENDING = 6
}

// [PrintTSTypeDefCode] EnumCallRecordingState
// [PrintTSEnumDefCode] EnumCallRecordingState
export enum EnumCallRecordingState {
	/** Call recording not active. */
	eNOCALLRECODRING = 0,
	/** This is the call recording call to the ISDN\/CAPI device usewd to record the call. */
	eCALLRECORDERCALL = 1,
	/** Call which will be recorded. */
	eCALLRECORDINGMEMBER = 2,
	/** Call which was the **recorder** call. */
	eWASCALLRECORDERCALL = 3,
	/** Call which was the **recorded** call. */
	eWASCALLRECORDINGMEMBER = 4
}

// [PrintTSTypeDefCode] AsnLicensedFeature
// [PrintTSEnumDefCode] AsnLicensedFeature
export enum AsnLicensedFeature {
	asnlicensedfeatureusers = 1,
	asnlicensedfeaturelines = 2,
	asnlicensedfeatureclients = 3,
	asnlicensedfeatureumreplicator = 4,
	asnlicensedfeatureanalytics = 5,
	asnlicensedfeatureinstances = 6,
	asnlicensedfeatureserverlines = 7,
	asnlicensedfeaturechallengepbx = 8,
	asnlicensedfeaturecstaconnections = 9,
	asnlicensedfeaturechannels = 10,
	asnlicensedfeatureucconnectmobileaccess = 11,
	asnlicensedfeaturehoursvideocommunication = 12,
	asnlicensedfeatureuniquerequest = 13,
	asnlicensedfeaturelivechatclients = 14,
	asnlicensedfeaturemeshconnections = 15,
	asnlicensedfeaturev7 = 16,
	asnlicensedfeaturedecouplepbx = 17,
	asnlicensedfeatureanalyticssites = 18,
	asnlicencedfeatureucconnectconferencing = 19,
	asnlicencedfeaturetapiserver = 20,
	asnlicencedfeaturedatacenterservers = 21
}

// [PrintTSTypeDefCode] AsnContactID
// [PrintTSimpleDefCode] AsnContactID
export type AsnContactID = string;

// [PrintTSTypeDefCode] AsnPresenceRightsFlags
// [PrintTSEnumDefCode] AsnPresenceRightsFlags
export enum AsnPresenceRightsFlags {
	eVisible = 1,
	eChangeable = 2,
	/** 0x0000 0010 */
	eAppointmentPrivate = 16,
	/** 0x0000 0020 */
	eAppointmentPublic = 32,
	/** 0x0000 8000 */
	ePatternUsed = 32768,
	eBlocked = 0,
	/** 0x0001 0000 */
	ePublic = 65536,
	/** 0x0002 0000 */
	eCompany = 131072,
	/** 0x0004 0000 */
	eTeam = 262144,
	/** 0x0008 0000 */
	ePersonal = 524288
}

// [PrintTSTypeDefCode] AsnPresenceRightsMasks
// [PrintTSEnumDefCode] AsnPresenceRightsMasks
export enum AsnPresenceRightsMasks {
	/** 0x0000 00F0 */
	eAppointmentMask = 240,
	/** 0x000F 0000 */
	ePatternFlagsMask = 983040
}

// [PrintTSTypeDefCode] AsnPhoneControlRightsFlags
// [PrintTSEnumDefCode] AsnPhoneControlRightsFlags
export enum AsnPhoneControlRightsFlags {
	eBlocked = 0,
	/** 0x0000 0001 */
	eNumbersOutbound = 1,
	/** 0x0000 0002 */
	eNumbersInbound = 2,
	/** 0x0000 0004 */
	eNumbersRedirected = 4,
	/** 0x0000 0100 */
	eSetForwards = 256,
	/** 0x0000 0200 */
	eSetPickupCalls = 512
}

// [PrintTSTypeDefCode] AsnPresenceCommonRights
// [PrintTSEnumDefCode] AsnPresenceCommonRights
export enum AsnPresenceCommonRights {
	/** 0x0000 0000      Blocked \/ not set */
	eBlocked = 0,
	/** 0x0001 8001      UR_PRESENCE_PUBLIC  | UR_PRESENCE_PATTERN_USED                                                                                                  | UR_PRESENCE_VISIBLE */
	ePublic = 98305,
	/** 0x0002 8021      UR_PRESENCE_COMPANY | UR_PRESENCE_PATTERN_USED | UR_PRESENCE_APPOINTMENT_PUBLIC                                                                 | UR_PRESENCE_VISIBLE */
	eCompany = 163873,
	/** 0x0004 8023      UR_PRESENCE_TEAM    | UR_PRESENCE_PATTERN_USED | UR_PRESENCE_APPOINTMENT_PUBLIC                                     | UR_PRESENCE_CHANGEABLE    | UR_PRESENCE_VISIBLE */
	eTeam = 294947,
	/** 0x0008 8033      UR_PRESENCE_PERSONAL| UR_PRESENCE_PATTERN_USED | UR_PRESENCE_APPOINTMENT_PUBLIC | UR_PRESENCE_APPOINTMENT_PRIVATE   | UR_PRESENCE_CHANGEABLE    | UR_PRESENCE_VISIBLE */
	ePersonal = 557107
}

// [PrintTSTypeDefCode] AsnPhoneControlPrimaryCommonRights
// [PrintTSEnumDefCode] AsnPhoneControlPrimaryCommonRights
export enum AsnPhoneControlPrimaryCommonRights {
	/** 0x0000\tBlocked \/ not set */
	eBlocked = 0,
	/** 0x0007  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED */
	eCompany = 7,
	/** 0x0207  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED    | UR_PHONE_PICKUP_CALLS */
	eTeam = 519,
	/** 0x0307  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED    | UR_PHONE_PICKUP_CALLS | UR_PHONE_SET_FORWARDS */
	ePersonal = 775
}

// [PrintTSTypeDefCode] AsnPhoneControlSecondaryCommonRights
// [PrintTSEnumDefCode] AsnPhoneControlSecondaryCommonRights
export enum AsnPhoneControlSecondaryCommonRights {
	/** 0x0000\tBlocked \/ not set */
	eBlocked = 0,
	/** 0x0007  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED */
	eTeam = 7,
	/** 0x0307  UR_PHONE_NUMBERS_OUTBOUND   | UR_PHONE_NUMBERS_INBOUND    | UR_PHONE_NUMBERS_REDIRECTED    | UR_PHONE_PICKUP_CALLS | UR_PHONE_SET_FORWARDS */
	ePersonal = 775
}

// [PrintTSTypeDefCode] AsnSetSettingsDynamicRightsErrorEnum
// [PrintTSEnumDefCode] AsnSetSettingsDynamicRightsErrorEnum
export enum AsnSetSettingsDynamicRightsErrorEnum {
	general = 0,
	permissiondenied = 1,
	writeprofiledenied = 2,
	unknownrights = 3
}

// [PrintTSTypeDefCode] AsnGetSettingsDynamicRightsErrorEnum
// [PrintTSEnumDefCode] AsnGetSettingsDynamicRightsErrorEnum
export enum AsnGetSettingsDynamicRightsErrorEnum {
	general = 0,
	permissiondenied = 1,
	readprofiledenied = 2
}

// [PrintTSTypeDefCode] AsnContactFeatureSetEnum
// [PrintTSEnumDefCode] AsnContactFeatureSetEnum
export enum AsnContactFeatureSetEnum {
	/** Nothing is alllowed on this contact. */
	eCONTACTFEATURENONE = 0,
	/** Dial on the primary line of the other contact. */
	eCONTACTFEATUREDIAL = 1,
	/** Set forward on the first line of the other contact. */
	eCONTACTFEATURESETFORWARDL1 = 2,
	/** Set forward on the second line of the other contact. */
	eCONTACTFEATURESETFORWARDL2 = 4,
	/** Swap Call. */
	eCONTACTFEATURESWAP = 8,
	/** Answer call. */
	eCONTACTFEATUREANSWER = 16,
	/** Hang up. */
	eCONTACTFEATUREHANGUP = 32,
	/** Pickup a ringing call on the first line of the contact. */
	eCONTACTFEATUREPICKUPL1 = 64,
	/** Pickup a ringing call on the second line of the contact. */
	eCONTACTFEATUREPICKUPL2 = 128,
	/** Hold the active call. */
	eCONTACTFEATUREHOLD = 256,
	/** Send a message. */
	eCONTACTFEATUREMESSAGE = 512,
	/** Redirect a ringing call. */
	eCONTACTFEATUREREDIRECT = 1024,
	/** Set absent state of the contact. */
	eCONTACTFEATURESETABSENT = 2048
}

// [PrintTSTypeDefCode] AsnMetaDirectoryPasswordEncryption
// [PrintTSEnumDefCode] AsnMetaDirectoryPasswordEncryption
export enum AsnMetaDirectoryPasswordEncryption {
	/** Passwort verschlÃ¼sselt Ã¼bertragen */
	iEncrypted = 0,
	/** Passwort als Klartext Ã¼bermitteln */
	iPlain = 1
}

// [PrintTSTypeDefCode] EnumEmailImportance
// [PrintTSEnumDefCode] EnumEmailImportance
export enum EnumEmailImportance {
	eNORMAL = 0,
	eHIGH = 1,
	eLOW = 2
}

// [PrintTSTypeDefCode] EnumEMailAttachmentEncoding
// [PrintTSEnumDefCode] EnumEMailAttachmentEncoding
export enum EnumEMailAttachmentEncoding {
	eEncodingUnknown = 0,
	eEncodingTXTUTF8 = 1,
	eEncodingTXTUNICODE = 2,
	eEncodingTXTANSI = 3,
	eEncodingVCF = 4,
	eEncodingWAV = 5,
	eEncodingMP3 = 6,
	eEncodingBinary = 7
}

// [PrintTSTypeDefCode] EnumEMailBodyEncoding
// [PrintTSEnumDefCode] EnumEMailBodyEncoding
export enum EnumEMailBodyEncoding {
	eEncodingUnknown = 0,
	eEncodingTXTUTF8 = 1,
	eEncodingTXTUNICODE = 2,
	eEncodingTXTANSI = 3
}

// [PrintTSTypeDefCode] AsnNetDatabaseFindOptionIFlags
// [PrintTSEnumDefCode] AsnNetDatabaseFindOptionIFlags
export enum AsnNetDatabaseFindOptionIFlags {
	/** Alt: Wird nicht mehr benÃ¶tigt! \/\/ Im Feld \"m_strLastName\" kann sich eine Komination aus Vor- und Nachnamen befinden, \"m_strFirstName\" wird ignoriert */
	eRAWNAMEDATA = 1,
	/** TelefonbÃ¼cher in Suche einbeziehen */
	ePHONEBOOKS = 2,
	/** Restliche, hier bisher nicht genannte Datenbanken in Suche einbeziehen */
	eOTHERDBS = 4,
	/** Wurde der Benutzer bereits Ã¼berprÃ¼ft, ob er die nÃ¶tigen Berechtigungen hat */
	eRIGHTSCHECKED = 8,
	/** Phonetische Suche anschalten */
	ePHONETICAL = 16,
	/** MetaDirectory mit in die Suche einbeziehen */
	eMETADIRECTORY = 32,
	/** Tel-Buch-EintrÃ¤ge mit mehreren Nummern werden nicht zusammengefasst, sondern einzeln gehalten. Die Telefonnummer steht in \"m_strPhoneBusiness\" */
	eSINGLENUMBER = 64,
	/** Die Suche nach Mobilrufnummern bei TVG zulassen, auch wenn kein Ort angegeben wurde (zeitintensiv!!) */
	eFORCEMOBILE = 128,
	/** Der LastName enthÃ¤lt einen Suchstring, der in mehreren Feldern gesucht wird (auch in Company...) */
	eMULTIFIELDNAME = 256,
	/** Nur CtiServer User suchen (betrifft nur NachCache im CtiServer) */
	eONLYCTISERVERUSERS = 512,
	/** Die Suche wurde manuell vom Benutzer gestartet und ist keine Suche, die durch einen Call automatisch angestoÃen wurde */
	eMANUALSEARCH = 1024,
	/** Die Suchergebnisse innerhalb des Suchknotens (!) nach dem Anzeigenamen sortiert zurÃ¼ckgeben */
	eSORTBYDISPLAYNAME = 2048,
	/** Immer neu suchen */
	eUSENOCACHE = 4096,
	/** Suche nach Firmenrufnummern (ignoriere Durchwahlen) */
	eCOMPANYSEARCH = 8192,
	/** TelefonbÃ¼cher NICHT in Suche einbeziehen */
	eNOPHONEBOOKS = 16384,
	/** Suche nach gerufener Nummer (Mandantensuche, Hotline-Funktion) */
	eMANDANTSEARCH = 32768,
	/** Nur im MetaDirectory suchen */
	eONLYMETADIRECTORY = 65536,
	/** Wenn nicht gesetzt, werden die Bild-BinÃ¤rdaten direkt im optionalParam 'jpegPhoto' Ã¼bertragen. Andernfalls wird nur der optionalParam 'jpegPhotoHash' Ã¼bertragen, anhand dem mittels 'asnRequestContactImage' die BinÃ¤rdaten separat abgeholt werden kÃ¶nnen. Funktioniert nur bei UCServer Benutzern. */
	eJPEGPHOTOHASH = 131072
}

// [PrintTSTypeDefCode] AsnAppsRegisterPushTypeFlags
// [PrintTSEnumDefCode] AsnAppsRegisterPushTypeFlags
export enum AsnAppsRegisterPushTypeFlags {
	/** %0000 0000 0000 0000 (0x00)  logoff, no events */
	eLOGOFF = 0,
	/** %0000 0000 0000 0001 (0x01)  Audio Video calls */
	eAVCALLS = 1,
	/** %0000 0000 0000 0010 (0x02)  Chat messages */
	eCHATMESSAGES = 2,
	/** %0000 0000 0000 0100 (0x04)  Journal events */
	eJOURNALEVENTS = 4,
	/** %1111 1111 1111 1111 (0xff)  Register to all events */
	eSUBSCRIBETOALL = 255
}

// [PrintTSTypeDefCode] AsnAppsPushEncryption
// [PrintTSEnumDefCode] AsnAppsPushEncryption
export enum AsnAppsPushEncryption {
	none = 0,
	aes128 = 1
}

// [PrintTSTypeDefCode] AsnSMSStatus
// [PrintTSEnumDefCode] AsnSMSStatus
export enum AsnSMSStatus {
	/** SMS erfolgreich versendet */
	eSMSSTATUSSUCCESS = 0,
	/** SMS konnte nicht Ã¼bermittelt werden */
	eSMSSTATUSERROR = 1,
	/** SMS wird spÃ¤ter verschickt (z.B. retry) */
	eSMSSTATUSDELAYED = 2
}

// [PrintTSTypeDefCode] AsnLineForwardProfileChoice
// [PrintTSChoiceDefCode] AsnLineForwardProfileChoice
export class AsnLineForwardProfileChoice {
	public constructor(that?: AsnLineForwardProfileChoice) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLineForwardProfileChoice {
		return new AsnLineForwardProfileChoice();
	}

	public iAllUserLines?: number = undefined;
	public u8sSingleLineURI?: string = undefined;
}

// [PrintTSTypeDefCode] AsnNumberFormatRegExpression
// [PrintTSSeqDefCode] AsnNumberFormatRegExpression
/**
 * Regular expression to reformat a phone number.
 *
 * @deprecated *
 */
export class AsnNumberFormatRegExpression {
	public constructor(that: AsnNumberFormatRegExpression) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNumberFormatRegExpression {
		return new AsnNumberFormatRegExpression({
			u8sSearch: "",
			u8sReplace: ""
		});
	}

	public u8sSearch!: string;
	public u8sReplace!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnNumberFormatRegExpression2
// [PrintTSSeqDefCode] AsnNumberFormatRegExpression2
/**
 * Regular expression to reformat a phone number. Replaces AsnNumberFormatRegExpression.
 */
export class AsnNumberFormatRegExpression2 {
	public constructor(that: AsnNumberFormatRegExpression2) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNumberFormatRegExpression2 {
		return new AsnNumberFormatRegExpression2({
			u8sSearch: "",
			u8sReplace: "",
			bMatchReplace: false
		});
	}

	public u8sSearch!: string;
	public u8sReplace!: string;
	public bMatchReplace!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnKeepAliveArgument
// [PrintTSSeqDefCode] AsnKeepAliveArgument
/**
 * Argunment for asnKeepAlive.
 */
export class AsnKeepAliveArgument {
	public constructor(that: AsnKeepAliveArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnKeepAliveArgument {
		return new AsnKeepAliveArgument({
			dummy: {}
		});
	}

	/** no parameter */
	public dummy!: object;
	/** No optional parameters yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetLocationInformationArgument
// [PrintTSSeqDefCode] AsnGetLocationInformationArgument
/**
 * Argument for asnGetLocationInformation and asnGetLocationInformation2.
 */
export class AsnGetLocationInformationArgument {
	public constructor(that: AsnGetLocationInformationArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLocationInformationArgument {
		return new AsnGetLocationInformationArgument({
			u8sLocationID: ""
		});
	}

	/** The identifier of a location information. See asnGetLocationIDs as an example how to get a location id. */
	public u8sLocationID!: string;
	/** No optional parameters yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetLocationInformation2Result
// [PrintTSSeqDefCode] AsnSetLocationInformation2Result
export class AsnSetLocationInformation2Result {
	public constructor(that?: AsnSetLocationInformation2Result) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetLocationInformation2Result {
		return new AsnSetLocationInformation2Result();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetLocationIDsArgument
// [PrintTSSeqDefCode] AsnGetLocationIDsArgument
/**
 * Liefert die LocationIDs mit passender MD5 PrÃ¼fsumme.
 * Wird vom Client verwendet um zu PrÃ¼fen ob seine Location Settings noch aktuell sind
 */
export class AsnGetLocationIDsArgument {
	public constructor(that?: AsnGetLocationIDsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLocationIDsArgument {
		return new AsnGetLocationIDsArgument();
	}

	public locationIDs?: ENetUC_Common.UTF8StringList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetLocationIDsResult
// [PrintTSSeqDefCode] AsnGetLocationIDsResult
export class AsnGetLocationIDsResult {
	public constructor(that: AsnGetLocationIDsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLocationIDsResult {
		return new AsnGetLocationIDsResult({
			locationIDMD5ChecksumList: new ENetUC_Common.AsnStringPairList()
		});
	}

	/** first LocationID, last MD5 Checksum */
	public locationIDMD5ChecksumList!: ENetUC_Common.AsnStringPairList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnLocationInformationChangedArgument
// [PrintTSSeqDefCode] AsnLocationInformationChangedArgument
export class AsnLocationInformationChangedArgument {
	public constructor(that: AsnLocationInformationChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLocationInformationChangedArgument {
		return new AsnLocationInformationChangedArgument({
			u8sLocationID: ""
		});
	}

	public u8sLocationID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetLicensedFeatureCountArgument
// [PrintTSSeqDefCode] AsnGetLicensedFeatureCountArgument
export class AsnGetLicensedFeatureCountArgument {
	public constructor(that?: AsnGetLicensedFeatureCountArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLicensedFeatureCountArgument {
		return new AsnGetLicensedFeatureCountArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetLicensedFeatureCountResult
// [PrintTSSeqDefCode] AsnGetLicensedFeatureCountResult
export class AsnGetLicensedFeatureCountResult {
	public constructor(that: AsnGetLicensedFeatureCountResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLicensedFeatureCountResult {
		return new AsnGetLicensedFeatureCountResult({
			licensedFeatures: new ENetUC_Common.AsnIntegerPairList()
		});
	}

	public licensedFeatures!: ENetUC_Common.AsnIntegerPairList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetAdministrativeMonitorContentArgument
// [PrintTSSeqDefCode] AsnGetAdministrativeMonitorContentArgument
export class AsnGetAdministrativeMonitorContentArgument {
	public constructor(that?: AsnGetAdministrativeMonitorContentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetAdministrativeMonitorContentArgument {
		return new AsnGetAdministrativeMonitorContentArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetAdministrativeMonitorContentResult
// [PrintTSSeqDefCode] AsnGetAdministrativeMonitorContentResult
export class AsnGetAdministrativeMonitorContentResult {
	public constructor(that: AsnGetAdministrativeMonitorContentResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetAdministrativeMonitorContentResult {
		return new AsnGetAdministrativeMonitorContentResult({
			iDisableAllMonitorConfiguration: 0,
			listContactIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	public iDisableAllMonitorConfiguration!: number;
	public listContactIDs!: ENetUC_Common.UTF8StringList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnReloadAdministrativeMonitorContentArgument
// [PrintTSSeqDefCode] AsnReloadAdministrativeMonitorContentArgument
export class AsnReloadAdministrativeMonitorContentArgument {
	public constructor(that?: AsnReloadAdministrativeMonitorContentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnReloadAdministrativeMonitorContentArgument {
		return new AsnReloadAdministrativeMonitorContentArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnXMLClientContent
// [PrintTSSeqDefCode] AsnXMLClientContent
export class AsnXMLClientContent {
	public constructor(that: AsnXMLClientContent) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnXMLClientContent {
		return new AsnXMLClientContent({
			osXMLContent: "",
			osXMLContentDescription: ""
		});
	}

	/** Die XML-Daten mit dem Inhalt (codierung laut xml inhalt default utf-8) */
	public osXMLContent!: string;
	/** Future Use: Beschreibung wie der Inhalt zu behandeln ist. Z.B. ob es noch wo anders Daten gibt, ob der Inhalt komplett Readonly ist etc. */
	public osXMLContentDescription!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetClientXMLContentArgument
// [PrintTSSeqDefCode] AsnGetClientXMLContentArgument
export class AsnGetClientXMLContentArgument {
	public constructor(that?: AsnGetClientXMLContentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetClientXMLContentArgument {
		return new AsnGetClientXMLContentArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetClientXMLContentResult
// [PrintTSSeqDefCode] AsnSetClientXMLContentResult
export class AsnSetClientXMLContentResult {
	public constructor(that?: AsnSetClientXMLContentResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientXMLContentResult {
		return new AsnSetClientXMLContentResult();
	}

	/** \"NewClientContentRevision\" (UTF8String): */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnRetriggerFederationConnectionArgument
// [PrintTSSeqDefCode] AsnRetriggerFederationConnectionArgument
export class AsnRetriggerFederationConnectionArgument {
	public constructor(that: AsnRetriggerFederationConnectionArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRetriggerFederationConnectionArgument {
		return new AsnRetriggerFederationConnectionArgument({
			u8sContactID: ""
		});
	}

	public u8sContactID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnActionFilterEntry
// [PrintTSSeqDefCode] AsnActionFilterEntry
export class AsnActionFilterEntry {
	public constructor(that: AsnActionFilterEntry) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnActionFilterEntry {
		return new AsnActionFilterEntry({
			u8sFilterFieldName: "",
			u8sFilterFieldExpression: ""
		});
	}

	public u8sFilterFieldName!: string;
	public u8sFilterFieldExpression!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetAppointmentForwardsResult
// [PrintTSSeqDefCode] AsnSetAppointmentForwardsResult
export class AsnSetAppointmentForwardsResult {
	public constructor(that?: AsnSetAppointmentForwardsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetAppointmentForwardsResult {
		return new AsnSetAppointmentForwardsResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetAppointmentForwardsArgument
// [PrintTSSeqDefCode] AsnGetAppointmentForwardsArgument
export class AsnGetAppointmentForwardsArgument {
	public constructor(that?: AsnGetAppointmentForwardsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetAppointmentForwardsArgument {
		return new AsnGetAppointmentForwardsArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnOperationCompleteArgument
// [PrintTSSeqDefCode] AsnOperationCompleteArgument
export class AsnOperationCompleteArgument {
	public constructor(that: AsnOperationCompleteArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOperationCompleteArgument {
		return new AsnOperationCompleteArgument({
			u8sCompletionID: ""
		});
	}

	/** CompletionID, die der Client als optiona Param im openContacts angegeben hatte */
	public u8sCompletionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnOpenContactsResult
// [PrintTSSeqDefCode] AsnOpenContactsResult
export class AsnOpenContactsResult {
	public constructor(that: AsnOpenContactsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOpenContactsResult {
		return new AsnOpenContactsResult({
			contacts: new ENetUC_Common_AsnContact.AsnContacts()
		});
	}

	public contacts!: ENetUC_Common_AsnContact.AsnContacts;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnOpenContactExArgument
// [PrintTSSeqDefCode] AsnOpenContactExArgument
export class AsnOpenContactExArgument {
	public constructor(that: AsnOpenContactExArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOpenContactExArgument {
		return new AsnOpenContactExArgument({
			asnNetDatabaseContact: ENetUC_Common.AsnNetDatabaseContact.initEmpty()
		});
	}

	/** NetDatabaseContactinformation um einen ActiveContcat damit anlegen zu kÃ¶nnen */
	public asnNetDatabaseContact!: ENetUC_Common.AsnNetDatabaseContact;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnOpenContactExResult
// [PrintTSSeqDefCode] AsnOpenContactExResult
export class AsnOpenContactExResult {
	public constructor(that: AsnOpenContactExResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOpenContactExResult {
		return new AsnOpenContactExResult({
			contact: ENetUC_Common_AsnContact.AsnContact.initEmpty()
		});
	}

	public contact!: ENetUC_Common_AsnContact.AsnContact;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCloseContactsResult
// [PrintTSSeqDefCode] AsnCloseContactsResult
export class AsnCloseContactsResult {
	public constructor(that: AsnCloseContactsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCloseContactsResult {
		return new AsnCloseContactsResult({
			iResult: 0,
			u8sComment: ""
		});
	}

	/** RÃ¼ckgabewert ob das geklappt hat, wenn nicht != 0 */
	public iResult!: number;
	/** Kommentar, wenn es nicht geklappt hat */
	public u8sComment!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetSettingsDynamicRightsArgument
// [PrintTSSeqDefCode] AsnSetSettingsDynamicRightsArgument
export class AsnSetSettingsDynamicRightsArgument {
	public constructor(that: AsnSetSettingsDynamicRightsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetSettingsDynamicRightsArgument {
		return new AsnSetSettingsDynamicRightsArgument({
			u8sContactID: "",
			seqDynamicRightsList: new ENetUC_Common_AsnContact.AsnUserDynamicRightsList()
		});
	}

	/** ContactID des Kontaktes, dessen Rechteliste gestzt werden soll (im Moment darf man nur bei sich selbst Ã¤ndern). */
	public u8sContactID!: string;
	/** Berechtigungsliste der ausschlieÃlich vom Benutzer selbst vergebenen Berechtigungen. Achtung: hier immer die vollstÃ¤ndige Liste setzen; fehlende Elemente werden am Server als \"entfernt\" interpretiert! */
	public seqDynamicRightsList!: ENetUC_Common_AsnContact.AsnUserDynamicRightsList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetSettingsDynamicRightsResult
// [PrintTSSeqDefCode] AsnSetSettingsDynamicRightsResult
export class AsnSetSettingsDynamicRightsResult {
	public constructor(that: AsnSetSettingsDynamicRightsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetSettingsDynamicRightsResult {
		return new AsnSetSettingsDynamicRightsResult({
			iResult: 0
		});
	}

	/** RÃ¼ckgabewert ob das geklappt hat, wenn nicht != 0 */
	public iResult!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetSettingsDynamicRightsArgument
// [PrintTSSeqDefCode] AsnGetSettingsDynamicRightsArgument
export class AsnGetSettingsDynamicRightsArgument {
	public constructor(that: AsnGetSettingsDynamicRightsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetSettingsDynamicRightsArgument {
		return new AsnGetSettingsDynamicRightsArgument({
			u8sContactID: ""
		});
	}

	/** ContactID des Kontaktes dessen Rechteliste gelesen werden soll (im Moment kann man nur die eigene lesen) */
	public u8sContactID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetSettingsDynamicRightsResult
// [PrintTSSeqDefCode] AsnGetSettingsDynamicRightsResult
export class AsnGetSettingsDynamicRightsResult {
	public constructor(that: AsnGetSettingsDynamicRightsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetSettingsDynamicRightsResult {
		return new AsnGetSettingsDynamicRightsResult({
			iResult: 0,
			seqDynamicRightsList: new ENetUC_Common_AsnContact.AsnUserDynamicRightsList()
		});
	}

	/** RÃ¼ckgabewert ob das geklappt hat, wenn nicht != 0 */
	public iResult!: number;
	/** Rechteliste die gelesen wurde */
	public seqDynamicRightsList!: ENetUC_Common_AsnContact.AsnUserDynamicRightsList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSettingsGlobalDynamicRightsChangedArgument
// [PrintTSSeqDefCode] AsnSettingsGlobalDynamicRightsChangedArgument
export class AsnSettingsGlobalDynamicRightsChangedArgument {
	public constructor(that: AsnSettingsGlobalDynamicRightsChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSettingsGlobalDynamicRightsChangedArgument {
		return new AsnSettingsGlobalDynamicRightsChangedArgument({
			globalDynamicRights: ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty()
		});
	}

	/** Globale Berechtigungsstufe, die fÃ¼r alle Benutzer gesetzt wurde. */
	public globalDynamicRights!: ENetUC_Common_AsnContact.AsnUserDynamicRights;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetSettingsGlobalDynamicRightsResult
// [PrintTSSeqDefCode] AsnGetSettingsGlobalDynamicRightsResult
export class AsnGetSettingsGlobalDynamicRightsResult {
	public constructor(that: AsnGetSettingsGlobalDynamicRightsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetSettingsGlobalDynamicRightsResult {
		return new AsnGetSettingsGlobalDynamicRightsResult({
			iResult: 0,
			globalDynamicRights: ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty()
		});
	}

	/** RÃ¼ckgabewert ob das geklappt hat, wenn nicht != 0 */
	public iResult!: number;
	/** Global gesetzte Berechtigungen */
	public globalDynamicRights!: ENetUC_Common_AsnContact.AsnUserDynamicRights;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateMyAppointmentArgument
// [PrintTSSeqDefCode] AsnUpdateMyAppointmentArgument
export class AsnUpdateMyAppointmentArgument {
	public constructor(that: AsnUpdateMyAppointmentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateMyAppointmentArgument {
		return new AsnUpdateMyAppointmentArgument({
			u8sContactID: "",
			seqActualAppointments: new ENetUC_Common_Appointments.SEQAppointmentList(),
			asnNextAppointment: ENetUC_Common_Appointments.AsnAppointmentEntry.initEmpty(),
			iPresenceState: 0
		});
	}

	/** Der Kontakt, fÃ¼r den die Ãnderung gilt */
	public u8sContactID!: string;
	/** Liste der gerade aktiven Termine */
	public seqActualAppointments!: ENetUC_Common_Appointments.SEQAppointmentList;
	/** Der nÃ¤chste Termin der aktiv wird */
	public asnNextAppointment!: ENetUC_Common_Appointments.AsnAppointmentEntry;
	public iPresenceState!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateMyCallInformationArgument
export class  AsnUpdateMyCallInformationArgument extends ENetUC_Common_AsnContact.AsnContactCallInformation {
}


// [PrintTSTypeDefCode] AsnNotifyLineForwardStateArgument
// [PrintTSSeqDefCode] AsnNotifyLineForwardStateArgument
/**
 * update fÃ¼r ME-Contact
 *
 * @private
 */
export class AsnNotifyLineForwardStateArgument {
	public constructor(that: AsnNotifyLineForwardStateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNotifyLineForwardStateArgument {
		return new AsnNotifyLineForwardStateArgument({
			u8sContactID: "",
			iContactFeatureSet: 0,
			iPresenceState: 0,
			u8sLinenumber: "",
			seqLineForwards: new ENetUC_Common_SIPCTI.AsnLineForwards()
		});
	}

	/** ContactID des Kontaktes, dessen Leitung umgeleitet werden soll (Owner) */
	public u8sContactID!: string;
	/** was mit dem Kontakt getan werden darf (wird nur fÃ¼r die Notifies benutzt */
	public iContactFeatureSet!: number;
	/** aktueller PrÃ¤senzzustand beim Versenden der Nachricht */
	public iPresenceState!: number;
	/** Nummer der Leitung */
	public u8sLinenumber!: string;
	/** Forwardzustand der Leitung */
	public seqLineForwards!: ENetUC_Common_SIPCTI.AsnLineForwards;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateMyContactConfigurationArgument
// [PrintTSSeqDefCode] AsnUpdateMyContactConfigurationArgument
export class AsnUpdateMyContactConfigurationArgument {
	public constructor(that: AsnUpdateMyContactConfigurationArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateMyContactConfigurationArgument {
		return new AsnUpdateMyContactConfigurationArgument({
			contactChanged: ENetUC_Common_AsnContact.AsnContact.initEmpty()
		});
	}

	public contactChanged!: ENetUC_Common_AsnContact.AsnContact;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateServerBasedClientSettingsArgument
// [PrintTSSeqDefCode] AsnUpdateServerBasedClientSettingsArgument
export class AsnUpdateServerBasedClientSettingsArgument {
	public constructor(that?: AsnUpdateServerBasedClientSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateServerBasedClientSettingsArgument {
		return new AsnUpdateServerBasedClientSettingsArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnMyPresenceRightsChallengeArgument
// [PrintTSSeqDefCode] AsnMyPresenceRightsChallengeArgument
export class AsnMyPresenceRightsChallengeArgument {
	public constructor(that: AsnMyPresenceRightsChallengeArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMyPresenceRightsChallengeArgument {
		return new AsnMyPresenceRightsChallengeArgument({
			contactsChallenging: new ENetUC_Common.AsnNetDatabaseContactList()
		});
	}

	public contactsChallenging!: ENetUC_Common.AsnNetDatabaseContactList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnRequestMyPresenceRightsChallengeArgument
// [PrintTSSeqDefCode] AsnRequestMyPresenceRightsChallengeArgument
export class AsnRequestMyPresenceRightsChallengeArgument {
	public constructor(that?: AsnRequestMyPresenceRightsChallengeArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestMyPresenceRightsChallengeArgument {
		return new AsnRequestMyPresenceRightsChallengeArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnResetMyPresenceRightsChallengesArgument
// [PrintTSSeqDefCode] AsnResetMyPresenceRightsChallengesArgument
export class AsnResetMyPresenceRightsChallengesArgument {
	public constructor(that?: AsnResetMyPresenceRightsChallengesArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnResetMyPresenceRightsChallengesArgument {
		return new AsnResetMyPresenceRightsChallengesArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnClientInfo
// [PrintTSSeqDefCode] AsnClientInfo
export class AsnClientInfo {
	public constructor(that: AsnClientInfo) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientInfo {
		return new AsnClientInfo({
			iConnectionType: 0,
			bMyConnectionInfo: false,
			stUTCLoginTime: new Date(),
			u8sComputername: "",
			u8sAdditionalInfo: ""
		});
	}

	/** Typ der Verbindung; MÃ¶gliche Konstanten in ApplicationTypes.h. */
	public iConnectionType!: number;
	/** Das ist meine Connection-Information; fÃ¼r TS-Szenarien wichtig */
	public bMyConnectionInfo!: boolean;
	/** Logontime */
	public stUTCLoginTime!: Date;
	/** Computer auf dem der Client lÃ¤uft bzw. auf dem die Terminals-Session lÃ¤uft. */
	public u8sComputername!: string;
	/** MÃ¶gliche Zusatzinformationen (Text) */
	public u8sAdditionalInfo!: string;
	/** Optionale Erweiterungen */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnMyUpdateClientContentArgument
// [PrintTSSeqDefCode] AsnMyUpdateClientContentArgument
export class AsnMyUpdateClientContentArgument {
	public constructor(that: AsnMyUpdateClientContentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMyUpdateClientContentArgument {
		return new AsnMyUpdateClientContentArgument({
			u8sNewRevision: ""
		});
	}

	public u8sNewRevision!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactRemoveContactArgument
// [PrintTSSeqDefCode] AsnContactRemoveContactArgument
export class AsnContactRemoveContactArgument {
	public constructor(that: AsnContactRemoveContactArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactRemoveContactArgument {
		return new AsnContactRemoveContactArgument({
			u8sContactID: ""
		});
	}

	public u8sContactID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAbsentStateSetUserArgument
// [PrintTSSeqDefCode] AsnAbsentStateSetUserArgument
/**
 * Argument for asnAbsentStateSetUser
 * Set change the manual presence of a user, a AsnAbsentState struct has to be sent to the ucserver.
 */
export class AsnAbsentStateSetUserArgument {
	public constructor(that: AsnAbsentStateSetUserArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateSetUserArgument {
		return new AsnAbsentStateSetUserArgument({
			absentstate: ENetUC_Common_AsnContact.AsnAbsentState.initEmpty()
		});
	}

	/** The AsnAbsentState data to change the manual presence of the user. */
	public absentstate!: ENetUC_Common_AsnContact.AsnAbsentState;
	/** reserved */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAbsentStateSetUserResult
// [PrintTSSeqDefCode] AsnAbsentStateSetUserResult
/**
 * Result of asnAbsentStateSetUser if the operation executes successfully.
 * Result of asnAbsentStateSetUser if the operation executes successfully.
 */
export class AsnAbsentStateSetUserResult {
	public constructor(that: AsnAbsentStateSetUserResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateSetUserResult {
		return new AsnAbsentStateSetUserResult({
			bSuccess: false
		});
	}

	/** Should always be true. */
	public bSuccess!: boolean;
	/** reserved */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAbsentStateResetArgument
// [PrintTSSeqDefCode] AsnAbsentStateResetArgument
/**
 * Argument for asnAbsentStateReset
 * The argument for asnAbsentStateReset conatins the ContactID for the contact to reset its absent state to 'none'.
 */
export class AsnAbsentStateResetArgument {
	public constructor(that: AsnAbsentStateResetArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateResetArgument {
		return new AsnAbsentStateResetArgument({
			u8sContactId: ""
		});
	}

	public u8sContactId!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAbsentStateResetResult
// [PrintTSSeqDefCode] AsnAbsentStateResetResult
/**
 * Result of asnAbsentStateReset that indicates, that the oepration was successful.
 */
export class AsnAbsentStateResetResult {
	public constructor(that?: AsnAbsentStateResetResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateResetResult {
		return new AsnAbsentStateResetResult();
	}

	/** reserved */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAbsentStateGetUserResult
// [PrintTSSeqDefCode] AsnAbsentStateGetUserResult
export class AsnAbsentStateGetUserResult {
	public constructor(that: AsnAbsentStateGetUserResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateGetUserResult {
		return new AsnAbsentStateGetUserResult({
			absentstate: ENetUC_Common_AsnContact.AsnAbsentState.initEmpty()
		});
	}

	public absentstate!: ENetUC_Common_AsnContact.AsnAbsentState;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetClientIdleStateArgument
// [PrintTSSeqDefCode] AsnSetClientIdleStateArgument
export class AsnSetClientIdleStateArgument {
	public constructor(that: AsnSetClientIdleStateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientIdleStateArgument {
		return new AsnSetClientIdleStateArgument({
			u8sContactId: "",
			iIdleState: 0
		});
	}

	public u8sContactId!: string;
	/** AsnPresenceStateEnum ohne AsnPresenceStateFlags! */
	public iIdleState!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetClientIdleStateResult
// [PrintTSSeqDefCode] AsnSetClientIdleStateResult
export class AsnSetClientIdleStateResult {
	public constructor(that: AsnSetClientIdleStateResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientIdleStateResult {
		return new AsnSetClientIdleStateResult({
			dummy: {}
		});
	}

	public dummy!: object;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateMyAbsentStateArgument
// [PrintTSSeqDefCode] AsnUpdateMyAbsentStateArgument
export class AsnUpdateMyAbsentStateArgument {
	public constructor(that: AsnUpdateMyAbsentStateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateMyAbsentStateArgument {
		return new AsnUpdateMyAbsentStateArgument({
			absentstate: ENetUC_Common_AsnContact.AsnAbsentState.initEmpty()
		});
	}

	public absentstate!: ENetUC_Common_AsnContact.AsnAbsentState;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCustomNoteSetArgument
// [PrintTSSeqDefCode] AsnCustomNoteSetArgument
export class AsnCustomNoteSetArgument {
	public constructor(that: AsnCustomNoteSetArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCustomNoteSetArgument {
		return new AsnCustomNoteSetArgument({
			customnote: ENetUC_Common_AsnContact.AsnCustomNote.initEmpty()
		});
	}

	public customnote!: ENetUC_Common_AsnContact.AsnCustomNote;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCustomNoteSetResult
// [PrintTSSeqDefCode] AsnCustomNoteSetResult
export class AsnCustomNoteSetResult {
	public constructor(that?: AsnCustomNoteSetResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCustomNoteSetResult {
		return new AsnCustomNoteSetResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateMyCustomNoteArgument
// [PrintTSSeqDefCode] AsnUpdateMyCustomNoteArgument
export class AsnUpdateMyCustomNoteArgument {
	public constructor(that: AsnUpdateMyCustomNoteArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateMyCustomNoteArgument {
		return new AsnUpdateMyCustomNoteArgument({
			customnote: ENetUC_Common_AsnContact.AsnCustomNote.initEmpty()
		});
	}

	public customnote!: ENetUC_Common_AsnContact.AsnCustomNote;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetMetaSettingsResult
// [PrintTSSeqDefCode] AsnSetMetaSettingsResult
export class AsnSetMetaSettingsResult {
	public constructor(that?: AsnSetMetaSettingsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetMetaSettingsResult {
		return new AsnSetMetaSettingsResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnRequestFeatureArgument
// [PrintTSSeqDefCode] AsnRequestFeatureArgument
export class AsnRequestFeatureArgument {
	public constructor(that: AsnRequestFeatureArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestFeatureArgument {
		return new AsnRequestFeatureArgument({
			iFeature: 0
		});
	}

	/** requested feature */
	public iFeature!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnRequestFeatureResult
// [PrintTSSeqDefCode] AsnRequestFeatureResult
export class AsnRequestFeatureResult {
	public constructor(that: AsnRequestFeatureResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestFeatureResult {
		return new AsnRequestFeatureResult({
			bNotForResale: false,
			iFeatureSet: 0
		});
	}

	public bNotForResale!: boolean;
	public iFeatureSet!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnEMailAttachment
// [PrintTSSeqDefCode] AsnEMailAttachment
export class AsnEMailAttachment {
	public constructor(that: AsnEMailAttachment) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnEMailAttachment {
		return new AsnEMailAttachment({
			binAttachmentData: new Uint8Array(0),
			u8sAttachmentFileName: "",
			u8sContentID: "",
			iAttachmentEncoding: 0
		});
	}

	public binAttachmentData!: Uint8Array;
	public u8sAttachmentFileName!: string;
	public u8sContentID!: string;
	/** EnumEMailAttachmentEncoding */
	public iAttachmentEncoding!: number;
}

// [PrintTSTypeDefCode] AsnEMailParty
// [PrintTSSeqDefCode] AsnEMailParty
export class AsnEMailParty {
	public constructor(that: AsnEMailParty) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnEMailParty {
		return new AsnEMailParty({
			u8sAddress: "",
			u8sName: ""
		});
	}

	public u8sAddress!: string;
	public u8sName!: string;
}

// [PrintTSTypeDefCode] AsnEMailBody
// [PrintTSSeqDefCode] AsnEMailBody
export class AsnEMailBody {
	public constructor(that: AsnEMailBody) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnEMailBody {
		return new AsnEMailBody({
			binBodyData: new Uint8Array(0),
			iBodyEncoding: 0
		});
	}

	public binBodyData!: Uint8Array;
	/** EnumEMailBodyEncoding */
	public iBodyEncoding!: number;
}

// [PrintTSTypeDefCode] AsnDatabaseEntryID
// [PrintTSSeqDefCode] AsnDatabaseEntryID
/**
 * ID der Datenbanken (EntryIDDB) Stand 23.09.2015
 * Aktuell immer in \\global\\include\\EDBEntryIDs.h, da ProduktÃ¼bergreifend (z.B. Meta) verwendet
 * DatabaseID der in ProCall Express 2.2 integrierten Datenbank.
 * Wird im Moment in ProCall 3.0 nicht verwendet
 * EntryIDDB: DB01
 * EntryIDStore:
 * EntryID:
 *
 * DatabaseID fÃ¼r Windows Addressbook.
 * Das ist das Addressbuch von Outlook Express.
 * EntryIDDB: DB02
 * EntryIDStore: WAB Store EntryID (wie Exchange)
 * EntryID: EntryID
 *
 * DatabaseID fÃ¼r Exchange (Mapi)
 * EntryIDDB: DB03
 * EntryIDStore: Message Store EntryID
 * EntryID: EntryID
 *
 * DatabaseID fÃ¼r Mapi Addressbook (Personal Adressbook)
 * EntryIDDB: DB04
 * EntryIDStore: Addressbook Store EntryID
 * EntryID: EntryID
 *
 * DatabaseID fÃ¼r Lotus Notes
 * EntryIDDB: DB05
 * EntryIDStore: selbstgebauter String aus \"Server DatabaseName\" z.B. \"avalon\"
 * EntryID: Notes UniversalID des Eintrags
 *
 * DatabaseID fÃ¼r ODBC Datenquellen
 * EntryIDDB: DB06
 * EntryIDStore: selbstgebauter String
 * EntryID: Inhalt des konfigurierten IndexFelds (UUID oder Zahl)
 *
 * DatabaseID fÃ¼r LDAP Datenquellen
 * EntryIDDB: DB07
 * EntryIDStore: selbstgebauter string \"ldap:\/\/server:port\/baseDN\" z.b. \"ldap:\/\/avalon:712\/dc=meta\"
 * EntryID: dn des Eintrags z.B. \"cn=Stefan Eckbauer,dc=estos,dc=de\"
 *
 * DatabaseID fÃ¼r ActiveDirectory (LDAP)
 * EntryIDDB: DB08
 * EntryIDStore: selbstgebauter string \"ldap:\/\/server:port\/baseDN\" z.b. \"ldap:\/\/avalon:712\/dc=meta\"
 * EntryID: dn des Eintrags z.B. \"cn=Stefan Eckbauer,dc=estos,dc=de\"
 *
 * DatabaseID fÃ¼r Tobit David
 * EntryIDDB: DB09
 * EntryIDStore: tobit \/ undefined
 * EntryID: Voller Pfad zum Eintrag \"ArchiveID\\ID\" z.B. \"\\\\WHOOP\\DAVID\\ARCHIVE\\ADDRESS\\1\\3E\\3\"
 *
 * DatabaseID fÃ¼r ADO Datenquellen
 * Momentan nicht verwendet
 * EntryIDDB: DB10
 * EntryIDStore:
 * EntryID:
 *
 * DatabaseID fÃ¼r CtiServer3 interne Daten
 * Zeigt an, dass der Kontakt aus der CtiServer-Benutzerverwaltung kommt.
 * Dabei ist zu beachten, dass die Felder EntryID und EntryIDStore aber
 * unterschiedliche Bedeutung haben wenn unterschiedliche Backends verwendet werden!
 * Leitungen:
 * EntryIDDB: DB11
 * EntryIDStore: \"lines\"
 * EntryID: l:leitungsrufnummer z.b. \"l:+49815136856110\"
 * User:
 * EntryIDDB: DB11
 * EntryIDStore: \"users\"
 * EntryID: u:username z.b. \"u:ste@estos.de\"
 *
 *
 *
 * DatabaseID fÃ¼r Abacus Addressen
 * EntryIDDB: DB12
 * EntryIDStore: mandant (nr des mandanten) z.B. \"5\"
 * EntryID: Datensatz ID z.b. \"588\"
 *
 * DatabaseID fÃ¼r Abacus Kontakte
 * EntryIDDB: DB13
 * EntryIDStore: mandant (nr des mandanten) z.B. \"5\"
 * EntryID: Datensatz ID z.b. \"588\"
 *
 * DatabaseID fÃ¼r Microsoft CRM 1 (SOAP)
 * EntryIDDB: DB14
 * EntryIDStore: \"host:port typ\" z.B. \"avalon:80 ACCOUNT\" ACCOUNT und CONTACT
 * EntryID: Datensatz ID z.b. \"588\"
 *
 * DatabaseID fÃ¼r gefakte Kontakte
 * Wird fÃ¼r URL links verwendet, damit die nicht zu lang werden
 * z.B. phone:
 * Diese dient dazu, in Links den Kontakt dann einfach nochmal zu suchen.
 * EntryIDDB: DB15
 * EntryIDStore: Rufnummer des Kontaktes (kodiert)
 * EntryID: Name des Kontaktes (kodiert)
 *
 * DatabaseID fÃ¼r TelefonbÃ¼cher
 * EntryIDDB: DB16
 * EntryIDStore: ID des Telefonbuchs
 * IID_PCSTOREIDKLICKTEL				_T(\"00000001\")
 * IID_PCSTOREIDDETEMEDIEN				_T(\"00000002\")
 * IID_PCSTOREIDTWIXTEL				_T(\"00000003\")
 * IID_PCSTOREIDHEROLDWHITEPAGES		_T(\"00000004\")
 * IID_PCSTOREIDHEROLDYELLOWPAGES		_T(\"00000005\")
 * IID_PCSTOREIDDETEMEDIENWEBSERVICE	_T(\"00000006\")
 * IID_PCSTOREIDSWISSCOM				_T(\"00000007\")
 * EntryID: Name des Kontaktes (kodiert)
 *
 * DatabaseID fÃ¼r StepAhead
 * EntryIDDB: DB17
 * EntryIDStore: undefined
 * EntryID: Index des Kontaktes
 *
 * DatabaseID fÃ¼r Special DB: Funktionen in einem Client
 * EntryIDDB: DB18
 * EntryIDStore: undefined
 * EntryID: undefined
 *
 * DatabaseID fÃ¼r Datev
 * EntryIDDB: DB19
 * EntryIDStore: undefined
 * EntryID: primÃ¤rschlÃ¼ssel des Eintrags
 *
 * DatabaseID fÃ¼r Datev Eigenorganisation
 * EntryIDDB: DB20
 * EntryIDStore: undefined
 * EntryID: primÃ¤rschlÃ¼ssel des Eintrags
 *
 * DatabaseID fÃ¼r Datev Eigenorganisation Komfort
 * EntryIDDB: DB21
 * EntryIDStore: undefined
 * EntryID: primÃ¤rschlÃ¼ssel des Eintrags
 *
 * DatabaseID fÃ¼r Kontakte die aus der TAPI Information der Telefonanlage generiert wurden
 * EntryIDDB: DB22
 * EntryIDStore: undefined
 * EntryID: PrimÃ¤rschlÃ¼ssel, vollkanonische Nummer des Kontaktes
 *
 * DatabaseID fÃ¼r Kontakte die von einem anderen Server verwaltet werden, der eine Federation-Verbindung hat.
 * EntryIDDB: DB23
 * EntryIDStore: Kann (wenn bekannt) die Verwaltungsdomain sein
 * EntryID: s:&lt;URI&gt;
 *
 * DatabaseID fÃ¼r das SDK. das SDK selbst hat natuerlich KEINE contacte ABER jedes AddIn wird als Store abgebildet
 * EntryIDDB: DB24
 * EntryIDStore: AddInName als DisplayName eines Monikers
 * EntryID: s: bleibt dem implementierer des AddIn ueberlassen
 *
 * DatabaseID fÃ¼r Datev SDD
 * EntryIDDB: DB25
 * EntryIDStore: undefined
 * EntryID: primÃ¤rschlÃ¼ssel des Eintrags
 *
 * DatabaseID fÃ¼r die Favoriten-Kontakte. Dies sind alle Kontakte, die im DataModel vorhanden sind.
 * Diese ID wird nur gebraucht, um den Favoriten-Suchknoten \"sauber\" in die RegisteredSearchNodes
 * einfÃ¼gen zu kÃ¶nnen. Die Kontakte im DataModel haben niemals diese DatenbankID, sondern tragen
 * ausschlieÃlich die EntryIDs der Quelldatenbank (z.B. IID_PCDBEXCHANGE; IID_PCDBTAPISERVER; IID_PCDBREMOTESIP etc).
 * Das Verhalten ist damit vergleichbar zu einem MetaDirectory, das auch immer die Original-EntryIDs zurÃ¼ckgibt,
 * also die EntryIDs der Datenquelle, die vom MetaDirectory repliziert wurde.
 * EntryIDDB: DB26
 * EntryIDStore: undefined
 * EntryID: undefined.
 *
 * DatabaseID fÃ¼r Private\/Lokale SQLite Datenbanken
 * EntryIDDB: DB27
 * EntryIDStore: File Name des Source files.
 * EntryID: id
 *
 *
 * DatabaseID fÃ¼r integrierte Meta4 Datenbank
 * EntryIDDB: DB28
 * EntryIDStore: selbstgebauter string \"ldap:\/\/server:port\/dc=users\"
 * EntryID: dn des Eintrags z.B. \"cn=al,dc=users,dc=de\"
 *
 * DatabaseID fÃ¼r lokales Adressbuch auf MobilePhone-Device
 * EntryIDDB: DB29
 * EntryIDStore: selbstgebauter string z.B. \"mobile phone device address book\"
 * EntryID: id des Eintrags
 *
 * DatabaseID fÃ¼r Kontakte, die aus VCFs generiert wurden
 * EntryIDDB: DB30
 * EntryIDStore: undefined (empty)
 * EntryID: Dateipfad der VCF
 *
 * DatabaseID fÃ¼r Kontakte, die von exact online
 * EntryIDDB: DB31
 * EntryIDStore: host-url
 * EntryID: GUID des Eintrags in der Quelle
 *
 * DatabaseID fÃ¼r alle EComContact-basierten Replikatoren
 * EntryIDDB: DB32
 * EntryIDStore: CLASSID d. IECOMContact-Implementierung
 * EntryID: undefined
 *
 * DatabaseID fÃ¼r Ferrari-WebService.
 * EntryIDDB: DB33
 * EntryIDStore: Postfach
 * EntryID: JobID
 *
 * DatabaseID fÃ¼r Merge-Contacts.
 * EntryIDDB: DB34
 * EntryIDStore: DB34
 * EntryID: rowID
 *
 * DatabaseID fÃ¼r anonyme Kontakte (temporÃ¤r erzeugt).
 * Bildet die anonyme Anrufer aus dem Web ab.
 * Typischerweise: go.estos.de
 * EntryIDDB: DB35
 * EntryIDStore: DomÃ¤ne (bsp. \"go.estos.de\")
 * EntryID: GUID@domain.de
 *
 * DatabaseID fÃ¼r Mobile Devices (Bluetooth)
 * EntryIDDB: DB36
 * EntryIDStore: Bluetooth Address des Devices
 * EntryID: Name des Kontaktes (kodiert)
 *
 * DatabaseID fÃ¼r das EWS AddIn im UCClient
 * EntryIDDB: DB37
 * EntryIDStore: E-Mail
 * EntryID: EWS-ID
 *
 * DatabaseID fÃ¼r Kontakte die aus einem TextFile (Meta) gelesen wurden
 * EntryIDDB: DB99
 * EntryIDStore: File Name des Source files.
 * EntryID: EntryID des Kontaktes (aus Quelle)
 *
 * @private
 */
export class AsnDatabaseEntryID {
	public constructor(that: AsnDatabaseEntryID) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseEntryID {
		return new AsnDatabaseEntryID({
			u8sEntryIDDB: "",
			u8sEntryIDStore: "",
			u8sEntryID: ""
		});
	}

	public u8sEntryIDDB!: string;
	public u8sEntryIDStore!: string;
	public u8sEntryID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnDatabaseFindOption
// [PrintTSSeqDefCode] AsnDatabaseFindOption
export class AsnDatabaseFindOption {
	public constructor(that: AsnDatabaseFindOption) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseFindOption {
		return new AsnDatabaseFindOption({
			u8sFirstName: "",
			u8sLastName: "",
			u8sCompanyName: "",
			u8sCustomerID: "",
			u8sDepartment: "",
			u8sStreetAddress: "",
			u8sAreaCodeOrCity: "",
			u8sPhoneNo: "",
			u8sEMail: "",
			iMaxNumEntries: 0,
			u8sDatabaseName: "",
			u8sUsername: "",
			iFlags: 0
		});
	}

	/** Vorname */
	public u8sFirstName!: string;
	/** Nachname oder Suchbegriff fÃ¼r mehrere Felder */
	public u8sLastName!: string;
	/** Firmenname */
	public u8sCompanyName!: string;
	/** Kundennummer */
	public u8sCustomerID!: string;
	/** Abteilung */
	public u8sDepartment!: string;
	/** Strasse */
	public u8sStreetAddress!: string;
	/** Postleitzahl oder Ortsname */
	public u8sAreaCodeOrCity!: string;
	/** Telefonnummer */
	public u8sPhoneNo!: string;
	/** E-Mail */
	public u8sEMail!: string;
	/** Maximale Anzahl der DatensÃ¤tze */
	public iMaxNumEntries!: number;
	/** Suche nur in dieser Datenbank */
	public u8sDatabaseName!: string;
	/** Benutzer, unter dem die Anfrage ausgefÃ¼hrt wird */
	public u8sUsername!: string;
	/** Flags ENDBF_xxx Konstanten (ENetDatabaseFindOptions.h) */
	public iFlags!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnDBPhoneBook
// [PrintTSSeqDefCode] AsnDBPhoneBook
export class AsnDBPhoneBook {
	public constructor(that: AsnDBPhoneBook) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDBPhoneBook {
		return new AsnDBPhoneBook({
			u8sName: "",
			u8sInfo: "",
			iCapabilities: 0,
			iType: 0,
			errors: new ENetUC_Common.AsnRequestErrorList()
		});
	}

	/** Name des Telefonbuchs */
	public u8sName!: string;
	/** Info about. */
	public u8sInfo!: string;
	/** Capabilities des Telefonbuchs. */
	public iCapabilities!: number;
	/** Typ des Telefonbuchs. */
	public iType!: number;
	public errors!: ENetUC_Common.AsnRequestErrorList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnNetDatabaseFindOptions
// [PrintTSSeqDefCode] AsnNetDatabaseFindOptions
export class AsnNetDatabaseFindOptions {
	public constructor(that: AsnNetDatabaseFindOptions) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNetDatabaseFindOptions {
		return new AsnNetDatabaseFindOptions({
			u8sFirstName: "",
			u8sLastName: "",
			u8sCompanyName: "",
			u8sCustomerID: "",
			u8sDepartment: "",
			u8sStreetAddress: "",
			u8sAreaCodeOrCity: "",
			u8sPhoneNo: "",
			u8sEMail: "",
			u8spairlistCustomFieldsValues: new ENetUC_Common.AsnStringPairList(),
			iMaxNumEntries: 0,
			u8slistDatabaseNames: new ENetUC_Common.UTF8StringList(),
			u8sUsername: "",
			iFlags: 0,
			iDisplayNameFormat: 0
		});
	}

	/** Vorname */
	public u8sFirstName!: string;
	/** Nachname oder Suchbegriff fÃ¼r mehrere Felder */
	public u8sLastName!: string;
	/** Firmenname */
	public u8sCompanyName!: string;
	/** Kundennummer */
	public u8sCustomerID!: string;
	/** Abteilung */
	public u8sDepartment!: string;
	/** Strasse */
	public u8sStreetAddress!: string;
	/** Postleitzahl oder Ortsname */
	public u8sAreaCodeOrCity!: string;
	/** Telefonnummer */
	public u8sPhoneNo!: string;
	/** E-Mail */
	public u8sEMail!: string;
	/** Benutzerdefinierte Suchparameter */
	public u8spairlistCustomFieldsValues!: ENetUC_Common.AsnStringPairList;
	/** Maximale Anzahl der DatensÃ¤tze */
	public iMaxNumEntries!: number;
	/** Suche in diesen Datenbanken */
	public u8slistDatabaseNames!: ENetUC_Common.UTF8StringList;
	/** Benutzer, unter dem die Anfrage ausgefÃ¼hrt wird */
	public u8sUsername!: string;
	/** Flags ENDBF_xxx Konstanten (AsnNetDatabaseFindOptionIFlags aus ENetDatabaseFindOptions.h) */
	public iFlags!: number;
	/** DISPLAYNAME_FIRSTLAST (0) oder DISPLAYNAME_LASTFIRST (1) */
	public iDisplayNameFormat!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetContactByEntryIDArgument
// [PrintTSSeqDefCode] AsnGetContactByEntryIDArgument
export class AsnGetContactByEntryIDArgument {
	public constructor(that: AsnGetContactByEntryIDArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetContactByEntryIDArgument {
		return new AsnGetContactByEntryIDArgument({
			u8sEntryIDDB: "",
			u8sEntryIDStore: "",
			u8sEntryID: ""
		});
	}

	public u8sEntryIDDB!: string;
	public u8sEntryIDStore!: string;
	public u8sEntryID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetContactByEntryIDResult
// [PrintTSSeqDefCode] AsnGetContactByEntryIDResult
export class AsnGetContactByEntryIDResult {
	public constructor(that: AsnGetContactByEntryIDResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetContactByEntryIDResult {
		return new AsnGetContactByEntryIDResult({
			bSuccess: false,
			contact: ENetUC_Common.AsnNetDatabaseContact.initEmpty()
		});
	}

	public bSuccess!: boolean;
	public contact!: ENetUC_Common.AsnNetDatabaseContact;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetPreferredContactRefArgument
// [PrintTSSeqDefCode] AsnGetPreferredContactRefArgument
export class AsnGetPreferredContactRefArgument {
	public constructor(that: AsnGetPreferredContactRefArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetPreferredContactRefArgument {
		return new AsnGetPreferredContactRefArgument({
			u8sContactID: ""
		});
	}

	public u8sContactID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetPreferredContactRefResult
// [PrintTSSeqDefCode] AsnGetPreferredContactRefResult
export class AsnGetPreferredContactRefResult {
	public constructor(that: AsnGetPreferredContactRefResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetPreferredContactRefResult {
		return new AsnGetPreferredContactRefResult({
			bContactRefFound: false,
			u8sEntryID: "",
			u8sStoreID: "",
			u8sDBID: "",
			u8sDisplayName: ""
		});
	}

	public bContactRefFound!: boolean;
	public u8sEntryID!: string;
	public u8sStoreID!: string;
	public u8sDBID!: string;
	/** Anzeigename als zusÃ¤tzliche Information, als Fallback, falls der Kontakt spÃ¤ter mit den EntryID-Triples nicht mehr gefunden werden kann. */
	public u8sDisplayName!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetPreferredContactRefArgument
// [PrintTSSeqDefCode] AsnSetPreferredContactRefArgument
export class AsnSetPreferredContactRefArgument {
	public constructor(that: AsnSetPreferredContactRefArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetPreferredContactRefArgument {
		return new AsnSetPreferredContactRefArgument({
			u8sContactID: "",
			u8sEntryID: "",
			u8sStoreID: "",
			u8sDBID: "",
			u8sDisplayName: ""
		});
	}

	public u8sContactID!: string;
	public u8sEntryID!: string;
	public u8sStoreID!: string;
	public u8sDBID!: string;
	/** Anzeigename als zusÃ¤tzliche Information, als Fallback, falls der Kontakt spÃ¤ter mit den EntryID-Triples nicht mehr gefunden werden kann. */
	public u8sDisplayName!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnDatabaseConnectorLoginArgument
// [PrintTSSeqDefCode] AsnDatabaseConnectorLoginArgument
/**
 * DatabaseConnector:
 * Ein Database Connector verwendet folgende Funktionen
 * asnDatabaseConnectorLogin
 * asnDatabaseConnectorLogoff
 * Er muss folgende Funktionen implementieren
 * asnFindDBContacts
 * asnGetPhoneBooks
 * An und abmeldung eines Datenbank Connectors am Server
 *
 * @private
 */
export class AsnDatabaseConnectorLoginArgument {
	public constructor(that: AsnDatabaseConnectorLoginArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseConnectorLoginArgument {
		return new AsnDatabaseConnectorLoginArgument({
			u8sUserName: "",
			u8sPassword: "",
			iVersion: 0,
			iDatabaseCapabilities: 0,
			iDatabaseType: 0,
			u8sDatabaseName: "",
			u8sDatabaseInfo: ""
		});
	}

	public u8sUserName!: string;
	public u8sPassword!: string;
	public iVersion!: number;
	public iDatabaseCapabilities!: number;
	public iDatabaseType!: number;
	public u8sDatabaseName!: string;
	public u8sDatabaseInfo!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnDatabaseConnectorLoginResult
// [PrintTSSeqDefCode] AsnDatabaseConnectorLoginResult
export class AsnDatabaseConnectorLoginResult {
	public constructor(that: AsnDatabaseConnectorLoginResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseConnectorLoginResult {
		return new AsnDatabaseConnectorLoginResult({
			iConnectorCrossRefID: 0
		});
	}

	public iConnectorCrossRefID!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnDatabaseConnectorLogoffArgument
// [PrintTSSeqDefCode] AsnDatabaseConnectorLogoffArgument
export class AsnDatabaseConnectorLogoffArgument {
	public constructor(that: AsnDatabaseConnectorLogoffArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseConnectorLogoffArgument {
		return new AsnDatabaseConnectorLogoffArgument({
			iConnectorCrossRefID: 0
		});
	}

	public iConnectorCrossRefID!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnDatabaseConnectorLogoffResult
// [PrintTSSeqDefCode] AsnDatabaseConnectorLogoffResult
export class AsnDatabaseConnectorLogoffResult {
	public constructor(that?: AsnDatabaseConnectorLogoffResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDatabaseConnectorLogoffResult {
		return new AsnDatabaseConnectorLogoffResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetPhoneBooksArgument
// [PrintTSSeqDefCode] AsnGetPhoneBooksArgument
export class AsnGetPhoneBooksArgument {
	public constructor(that?: AsnGetPhoneBooksArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetPhoneBooksArgument {
		return new AsnGetPhoneBooksArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetUserPropertyBagArgument
// [PrintTSSeqDefCode] AsnSetUserPropertyBagArgument
/**
 * ***********************************************************--
 * *********** UserPropertyBag Settings speichern ************--
 * ***********************************************************--
 *
 * @private
 */
export class AsnSetUserPropertyBagArgument {
	public constructor(that: AsnSetUserPropertyBagArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetUserPropertyBagArgument {
		return new AsnSetUserPropertyBagArgument({
			asnUserPropertyBag: ENetUC_Common.AsnUserPropertyBag.initEmpty()
		});
	}

	public asnUserPropertyBag!: ENetUC_Common.AsnUserPropertyBag;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetUserPropertyBagResult
// [PrintTSSeqDefCode] AsnSetUserPropertyBagResult
export class AsnSetUserPropertyBagResult {
	public constructor(that: AsnSetUserPropertyBagResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetUserPropertyBagResult {
		return new AsnSetUserPropertyBagResult({
			iResult: 0
		});
	}

	/** 0 = Success (NO_ERROR), sonst Fehlercode (i.d.R. &lt; 0) */
	public iResult!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnServerBasedClientSettings
// [PrintTSSeqDefCode] AsnServerBasedClientSettings
/**
 * ***********************************************************--
 * **************** ServerBasedClientSettings ****************--
 * ***********************************************************--
 *
 * @private
 */
export class AsnServerBasedClientSettings {
	public constructor(that: AsnServerBasedClientSettings) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnServerBasedClientSettings {
		return new AsnServerBasedClientSettings({
			osSettingsData: ""
		});
	}

	/** XML formatierte Settings, formatiert wie diese auch in der Registry stehen wÃ¼rden (codierung im xml - default utf-8) */
	public osSettingsData!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetServerBasedClientSettingsArgument
// [PrintTSSeqDefCode] AsnGetServerBasedClientSettingsArgument
export class AsnGetServerBasedClientSettingsArgument {
	public constructor(that?: AsnGetServerBasedClientSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetServerBasedClientSettingsArgument {
		return new AsnGetServerBasedClientSettingsArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetServerBasedClientSettingsResult
// [PrintTSSeqDefCode] AsnSetServerBasedClientSettingsResult
export class AsnSetServerBasedClientSettingsResult {
	public constructor(that: AsnSetServerBasedClientSettingsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetServerBasedClientSettingsResult {
		return new AsnSetServerBasedClientSettingsResult({
			iResult: 0
		});
	}

	/** 0 = Success (NO_ERROR), sonst Fehlercode (i.d.R. &lt; 0) */
	public iResult!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactStatusResultEntry
// [PrintTSSeqDefCode] AsnContactStatusResultEntry
export class AsnContactStatusResultEntry {
	public constructor(that: AsnContactStatusResultEntry) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusResultEntry {
		return new AsnContactStatusResultEntry({
			iClientSearchID: 0,
			iClientRowID: 0,
			iPresenceState: 0,
			u8sContactID: ""
		});
	}

	/** ID der Suchanfrage um z.B. mehrere, gleichzeitige Suchfenster am Client unterscheiden zu kÃ¶nnen */
	public iClientSearchID!: number;
	/** ID der Zeile in der oben angegebenen Suchanfrage */
	public iClientRowID!: number;
	/** Aktueller Zustand des entsprechenden ActiveContacts am Server */
	public iPresenceState!: number;
	/** ContactID des korrespondierenden Contacts am Server, falls vorhanden */
	public u8sContactID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactSearchCloseArgument
// [PrintTSSeqDefCode] AsnContactSearchCloseArgument
export class AsnContactSearchCloseArgument {
	public constructor(that: AsnContactSearchCloseArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactSearchCloseArgument {
		return new AsnContactSearchCloseArgument({
			iClientSearchID: 0
		});
	}

	public iClientSearchID!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactSearchCloseResult
// [PrintTSSeqDefCode] AsnContactSearchCloseResult
export class AsnContactSearchCloseResult {
	public constructor(that: AsnContactSearchCloseResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactSearchCloseResult {
		return new AsnContactSearchCloseResult({
			iResult: 0
		});
	}

	public iResult!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAppsRegisterPushArgument
// [PrintTSSeqDefCode] AsnAppsRegisterPushArgument
export class AsnAppsRegisterPushArgument {
	public constructor(that: AsnAppsRegisterPushArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAppsRegisterPushArgument {
		return new AsnAppsRegisterPushArgument({
			iRegisterType: 0,
			u8sDeviceID: "",
			u8sPushID: "",
			u8sPushChannelID: ""
		});
	}

	/** Type of Registration */
	public iRegisterType!: number;
	/** mandatory - DeviceID unique ID for the device (Installation Instance)&lt;br \/&gt;mandatory bei Anmeldung, sonst optional  Push ID of the Device (z.B. ios: 0124f138fc90e83bf91b6cc58815ad7a2136d7339acece26e310110713ca928e,&lt;br \/&gt;gcm: APA91bHPmxUoXJ4OXgsvOJq45qCRo8K4UB8iYqU27dR8lzzLWnEJg8NUs3mPXSqXBsjqJ4hHljGNwEjrisIg3vEw7UXHBkTR2s3fK8QVmPS9N42Vf_wagKiiaKmp9FnwFGOJbhXcIQ4O-FkFEx7HjWTQ-oZZx9UqIA */
	public u8sDeviceID!: string;
	/** mandatory bei Anmeldung, sonst optional - Push Channel ID (z.B. ios.de.estos.apps.procallmobile.dev) */
	public u8sPushID!: string;
	public u8sPushChannelID!: string;
	/** Tells the server to create an key for encrypting the push message payload */
	public iEncryption?: number;
	/** optional - Wenn gesetzt, so sendet der Server 'avnewcall' Benachrichtigungen fÃ¼r Softphone Calls Ã¼ber diese ID. */
	public u8sPushIDVoIP?: string;
	/** mandatory wenn u8sPushIDVoIP gesetzt ist, ansonsten optional. */
	public u8sPushChannelIDVoIP?: string;
}

// [PrintTSTypeDefCode] AsnAppsRegisterPushResult
// [PrintTSSeqDefCode] AsnAppsRegisterPushResult
export class AsnAppsRegisterPushResult {
	public constructor(that?: AsnAppsRegisterPushResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAppsRegisterPushResult {
		return new AsnAppsRegisterPushResult();
	}

	/** The key for encrypting\/decrypting the push message payload (sent when 'encyrpted' was requested) */
	public key?: Uint8Array;
}

// [PrintTSTypeDefCode] AsnPublicUserContactData
// [PrintTSSeqDefCode] AsnPublicUserContactData
export class AsnPublicUserContactData {
	public constructor(that: AsnPublicUserContactData) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnPublicUserContactData {
		return new AsnPublicUserContactData({
			contactData: ENetUC_Common.AsnNetDatabaseContact.initEmpty()
		});
	}

	public contactData!: ENetUC_Common.AsnNetDatabaseContact;
}

// [PrintTSTypeDefCode] AsnPublicUserSetContactDataResult
// [PrintTSSeqDefCode] AsnPublicUserSetContactDataResult
export class AsnPublicUserSetContactDataResult {
	public constructor(that: AsnPublicUserSetContactDataResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnPublicUserSetContactDataResult {
		return new AsnPublicUserSetContactDataResult({
			iResult: 0
		});
	}

	public iResult!: number;
}

// [PrintTSTypeDefCode] AsnGetGoogleIntegrationSettingsArgument
// [PrintTSSeqDefCode] AsnGetGoogleIntegrationSettingsArgument
export class AsnGetGoogleIntegrationSettingsArgument {
	public constructor(that?: AsnGetGoogleIntegrationSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetGoogleIntegrationSettingsArgument {
		return new AsnGetGoogleIntegrationSettingsArgument();
	}
}

// [PrintTSTypeDefCode] AsnGetGoogleIntegrationSettingsResult
// [PrintTSSeqDefCode] AsnGetGoogleIntegrationSettingsResult
export class AsnGetGoogleIntegrationSettingsResult {
	public constructor(that: AsnGetGoogleIntegrationSettingsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetGoogleIntegrationSettingsResult {
		return new AsnGetGoogleIntegrationSettingsResult({
			iResult: 0,
			iEnabled: 0,
			u8sClientID: "",
			u8sClientSecret: ""
		});
	}

	public iResult!: number;
	public iEnabled!: number;
	public u8sClientID!: string;
	public u8sClientSecret!: string;
}

// [PrintTSTypeDefCode] AsnSMSMessage
// [PrintTSSeqDefCode] AsnSMSMessage
export class AsnSMSMessage {
	public constructor(that: AsnSMSMessage) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSMSMessage {
		return new AsnSMSMessage({
			u8sRefId: "",
			u8sSenderNumberSC: "",
			u8sReceiverNumberSC: "",
			u8sMessage: "",
			iContentIsPrivate: 0
		});
	}

	/** Referenz ID der Nachricht, wird vom Server vergeben; Beim Versendne einfach leer lassen. (in der Struktur um die jeweilige, lokale Verwaltung zu vereinfachen) */
	public u8sRefId!: string;
	/** (Mobil-)Rufnummer des Absenders (leer lassen, dann ergÃ¤nzt der Server entsprechend Einstellungen anhand des eingeloggten Benutzers) */
	public u8sSenderNumberSC!: string;
	/** Mobil-Rufnummer des EmpfÃ¤ngers (SC!) */
	public u8sReceiverNumberSC!: string;
	/** Die zu versendende Nachricht. */
	public u8sMessage!: string;
	/** 0: Textnachricht wird vollstÃ¤ndig im Log vermerkt; !=0: Text der Nachricht wird im Log nicht mitgespeichert. */
	public iContentIsPrivate!: number;
	/** Optional Kontaktdaten zum EmpfÃ¤nger, um ggf. bessere Ausgaben\/JournaleintrÃ¤ge zu ermÃ¶glichen */
	public contactReceiver?: ENetUC_Common.AsnNetDatabaseContact;
}

// [PrintTSTypeDefCode] AsnSendSMSResult
// [PrintTSSeqDefCode] AsnSendSMSResult
export class AsnSendSMSResult {
	public constructor(that: AsnSendSMSResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendSMSResult {
		return new AsnSendSMSResult({
			u8sCrossRefIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	/** CrossrefIDs fÃ¼r die Nachrichten in der gleichen Reihenfolge */
	public u8sCrossRefIDs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode] AsnUserChangePasswordArgument
// [PrintTSSeqDefCode] AsnUserChangePasswordArgument
export class AsnUserChangePasswordArgument {
	public constructor(that: AsnUserChangePasswordArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUserChangePasswordArgument {
		return new AsnUserChangePasswordArgument({
			u8sOldPassword: "",
			u8sNewPassword: ""
		});
	}

	public u8sOldPassword!: string;
	public u8sNewPassword!: string;
}

// [PrintTSTypeDefCode] AsnUserChangePasswordResult
// [PrintTSSeqDefCode] AsnUserChangePasswordResult
export class AsnUserChangePasswordResult {
	public constructor(that: AsnUserChangePasswordResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUserChangePasswordResult {
		return new AsnUserChangePasswordResult({
			success: false
		});
	}

	public success!: boolean;
}

// [PrintTSTypeDefCode] AsnGetUserdefinedCustomFieldsArgument
// [PrintTSSeqDefCode] AsnGetUserdefinedCustomFieldsArgument
export class AsnGetUserdefinedCustomFieldsArgument {
	public constructor(that?: AsnGetUserdefinedCustomFieldsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetUserdefinedCustomFieldsArgument {
		return new AsnGetUserdefinedCustomFieldsArgument();
	}
}

// [PrintTSTypeDefCode] AsnUserDefinedCustomField
// [PrintTSSeqDefCode] AsnUserDefinedCustomField
export class AsnUserDefinedCustomField {
	public constructor(that: AsnUserDefinedCustomField) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUserDefinedCustomField {
		return new AsnUserDefinedCustomField({
			u8sFieldName: "",
			u8sFieldValue: ""
		});
	}

	public u8sFieldName!: string;
	public u8sFieldValue!: string;
}

// [PrintTSTypeDefCode] AsnSendMailArgument
// [PrintTSSeqDefCode] AsnSendMailArgument
export class AsnSendMailArgument {
	public constructor(that: AsnSendMailArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendMailArgument {
		return new AsnSendMailArgument({
			u8sReceiverSIPURI: "",
			u8sMessage: "",
			u8sSubject: ""
		});
	}

	/** sipuri des EmpfÃ¤ngers\/Gruppe (zum Spamschutz hier keine Mailadresse! Der Server ermittelt zur Ã¼bergebenen sipuri die zugehÃ¶rige Mailadresse(n) selbst) */
	public u8sReceiverSIPURI!: string;
	/** Die zu versendende Nachricht. */
	public u8sMessage!: string;
	/** Der Nachrichten-Betreff. */
	public u8sSubject!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSendMailResult
// [PrintTSSeqDefCode] AsnSendMailResult
export class AsnSendMailResult {
	public constructor(that: AsnSendMailResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendMailResult {
		return new AsnSendMailResult({
			success: false,
			u8sErrReason: ""
		});
	}

	public success!: boolean;
	/** Im Fehlerfall kann hier noch ein Grund stehen (logging) */
	public u8sErrReason!: string;
}

// [PrintTSTypeDefCode] AsnGetServerInterfaceVersionArgument
// [PrintTSSeqDefCode] AsnGetServerInterfaceVersionArgument
export class AsnGetServerInterfaceVersionArgument {
	public constructor(that?: AsnGetServerInterfaceVersionArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetServerInterfaceVersionArgument {
		return new AsnGetServerInterfaceVersionArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetServerInterfaceVersionResult
// [PrintTSSeqDefCode] AsnGetServerInterfaceVersionResult
export class AsnGetServerInterfaceVersionResult {
	public constructor(that: AsnGetServerInterfaceVersionResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetServerInterfaceVersionResult {
		return new AsnGetServerInterfaceVersionResult({
			iServerInterfaceVersion: 0
		});
	}

	/** Interface-Version des UCServers */
	public iServerInterfaceVersion!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnRemoteInvokeCompleteArgument
// [PrintTSSeqDefCode] AsnRemoteInvokeCompleteArgument
export class AsnRemoteInvokeCompleteArgument {
	public constructor(that: AsnRemoteInvokeCompleteArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRemoteInvokeCompleteArgument {
		return new AsnRemoteInvokeCompleteArgument({
			u8sInvokeID: "",
			u8sInvokingConnection: "",
			iResult: 0
		});
	}

	public u8sInvokeID!: string;
	public u8sInvokingConnection!: string;
	public iResult!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnNumberFormatRegExpressList
// [PrintTSSetOfDefCode] AsnNumberFormatRegExpressList
export class AsnNumberFormatRegExpressList extends Array<AsnNumberFormatRegExpression> { }

// [PrintTSTypeDefCode] AsnLocationInformation
// [PrintTSSeqDefCode] AsnLocationInformation
/**
 * Description of formatting and dialing rules for phone numbers.
 * Each PBX is connected to a phone network which defines the rules to apply if number should be dialed or how
 * phone numbers are routed and transmitted.
 *
 * The AsnLocationInformation (and the newer AsnLocationInformation2) contains information how to handle
 * phone numbers to allow comfort functions for the user like formatting phone numbers to display them in a readable manner
 * or to enable the user to enter numbers in a short format etc.
 *
 * @deprecated *
 */
export class AsnLocationInformation {
	public constructor(that: AsnLocationInformation) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLocationInformation {
		return new AsnLocationInformation({
			u8sLocationID: "",
			iCountryID: 0,
			u8sCountryCode: "",
			u8sLocalPrefix: "",
			u8sNationalPrefix: "",
			u8sInternationalPrefix: "",
			u8sLocalDialingRule: "",
			u8sNationalDialingRule: "",
			u8sInternationalDialingRule: "",
			u8sCityCodeNoPrefix: "",
			u8sVanityNumbers: "",
			iUseVanity: 0,
			u8sAddressStreet: "",
			u8sAddressZIPCode: "",
			u8sAddressCity: "",
			iUseTkSystem: 0,
			u8sTKLocalAccessCode: "",
			u8sTKLongDistanceAccessCode: "",
			u8sTKInternationalAccessCode: "",
			u8sTKPrivateAccessCode: "",
			u8sTKAnschlussNr: "",
			iTKMaxLenInternalNumbers: 0,
			u8slistTKAkzListRemoveIn: new ENetUC_Common.UTF8StringList(),
			u8slistTKAkzListRemoveOut: new ENetUC_Common.UTF8StringList(),
			u8slistTKAkzListRemoveForwarding: new ENetUC_Common.UTF8StringList(),
			u8slistTKDetectInternalRules: new ENetUC_Common.UTF8StringList(),
			u8slistTKDetectExternalRules: new ENetUC_Common.UTF8StringList(),
			iUseAdvancedLCR: 0,
			numberFormatRegExpressListIn: new AsnNumberFormatRegExpressList(),
			numberFormatRegExpressListOut: new AsnNumberFormatRegExpressList(),
			numberFormatRegExpressListDial: new AsnNumberFormatRegExpressList(),
			projectPINRules: new ENetUC_Common_SIPCTI.AsnProjectPinRuleList()
		});
	}

	/** ID for this particular set of rules in the UCServer */
	public u8sLocationID!: string;
	/** In which country is the PBX connected to the network. */
	public iCountryID!: number;
	/** The country code. */
	public u8sCountryCode!: string;
	/** The local prefix to dial. */
	public u8sLocalPrefix!: string;
	/** The national prefix to dial. */
	public u8sNationalPrefix!: string;
	/** The international prefix to dial. */
	public u8sInternationalPrefix!: string;
	public u8sLocalDialingRule!: string;
	public u8sNationalDialingRule!: string;
	public u8sInternationalDialingRule!: string;
	public u8sCityCodeNoPrefix!: string;
	public u8sVanityNumbers!: string;
	public iUseVanity!: number;
	/** Address (Street) where the PBX is located. */
	public u8sAddressStreet!: string;
	/** Address (ZIP Code) where the PBX is located. */
	public u8sAddressZIPCode!: string;
	/** Address (City) where the PBX is located. */
	public u8sAddressCity!: string;
	public iUseTkSystem!: number;
	public u8sTKLocalAccessCode!: string;
	public u8sTKLongDistanceAccessCode!: string;
	public u8sTKInternationalAccessCode!: string;
	public u8sTKPrivateAccessCode!: string;
	public u8sTKAnschlussNr!: string;
	public iTKMaxLenInternalNumbers!: number;
	public u8slistTKAkzListRemoveIn!: ENetUC_Common.UTF8StringList;
	public u8slistTKAkzListRemoveOut!: ENetUC_Common.UTF8StringList;
	public u8slistTKAkzListRemoveForwarding!: ENetUC_Common.UTF8StringList;
	public u8slistTKDetectInternalRules!: ENetUC_Common.UTF8StringList;
	public u8slistTKDetectExternalRules!: ENetUC_Common.UTF8StringList;
	public iUseAdvancedLCR!: number;
	public numberFormatRegExpressListIn!: AsnNumberFormatRegExpressList;
	public numberFormatRegExpressListOut!: AsnNumberFormatRegExpressList;
	public numberFormatRegExpressListDial!: AsnNumberFormatRegExpressList;
	public projectPINRules!: ENetUC_Common_SIPCTI.AsnProjectPinRuleList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnNumberFormatRegExpressList2
// [PrintTSSetOfDefCode] AsnNumberFormatRegExpressList2
export class AsnNumberFormatRegExpressList2 extends Array<AsnNumberFormatRegExpression2> { }

// [PrintTSTypeDefCode] AsnNumberFormatRegExpressions2
// [PrintTSSeqDefCode] AsnNumberFormatRegExpressions2
/**
 * Encapsulates AsnNumberFormatRegExpressList2 together with a readonly flag.
 */
export class AsnNumberFormatRegExpressions2 {
	public constructor(that: AsnNumberFormatRegExpressions2) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNumberFormatRegExpressions2 {
		return new AsnNumberFormatRegExpressions2({
			bIsReadOnlyFromFile: false,
			formatRules: new AsnNumberFormatRegExpressList2()
		});
	}

	public bIsReadOnlyFromFile!: boolean;
	public formatRules!: AsnNumberFormatRegExpressList2;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnLocationInformation2
// [PrintTSSeqDefCode] AsnLocationInformation2
/**
 * Description of formatting and dialing rules for phone numbers.
 * Each PBX is connected to a phone network which defines the rules to apply if number should be dialed or how
 * phone numbers are routed and transmitted.
 *
 * The AsnLocationInformation contains information how to handle
 * phone numbers to allow comfort functions for the user like formatting phone numbers to display them in a readable manner
 * or to enable the user to enter numbers in a short format etc.
 *
 * Replaces AsnLocationInformation.
 */
export class AsnLocationInformation2 {
	public constructor(that: AsnLocationInformation2) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLocationInformation2 {
		return new AsnLocationInformation2({
			u8sLocationID: "",
			u8sLocationCheckSum: "",
			bUseVanity: false,
			bShowExtendedSettings: false,
			bUseTkSystem: false,
			bUseAdvancedLCR: false,
			bFixedNumberLenExtensionDTMFDialing: false,
			bMultiLocationSupport: false,
			bAutoCreateOtherLocationRules: false,
			bAllowNumberSearchOtherLocations: false,
			bShortenOtherLocationSCNumbersForDialing: false,
			iCountryID: 0,
			iLanguage: 0,
			u8sCountryCode: "",
			u8sLocalPrefix: "",
			u8sNationalPrefix: "",
			u8sInternationalPrefix: "",
			u8sLocalDialingRule: "",
			u8sNationalDialingRule: "",
			u8sInternationalDialingRule: "",
			u8sCityCodeNoPrefix: "",
			u8sVanityNumbers: "",
			u8sTKLocalAccessCode: "",
			u8sTKLongDistanceAccessCode: "",
			u8sTKInternationalAccessCode: "",
			u8sTKPrivateAccessCode: "",
			u8sTKForwardingAccessCode: "",
			u8sTKAnschlussNr: "",
			iTKMinLenInternalNumbers: 0,
			iTKMaxLenInternalNumbers: 0,
			numberFormatInternal: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatExternal: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatAutomaticOtherLocationsDDItoSC: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatAutomaticOtherLocationsSCtoDDI: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatRegExpressListIn: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatRegExpressListOut: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatRegExpressListDial: AsnNumberFormatRegExpressions2.initEmpty(),
			numberFormatRegExpressListFinalDial: AsnNumberFormatRegExpressions2.initEmpty(),
			projectPINRules: new ENetUC_Common_SIPCTI.AsnProjectPinRuleList(),
			u8sDDIRangeBegin: "",
			u8sDDIRangeEnd: "",
			iDialingNumberFormat: 0,
			bNoLocalDialing: false
		});
	}

	public u8sLocationID!: string;
	/** Checksum to detec changes in the AsnLocationInformation2. */
	public u8sLocationCheckSum!: string;
	public bUseVanity!: boolean;
	public bShowExtendedSettings!: boolean;
	public bUseTkSystem!: boolean;
	public bUseAdvancedLCR!: boolean;
	public bFixedNumberLenExtensionDTMFDialing!: boolean;
	public bMultiLocationSupport!: boolean;
	public bAutoCreateOtherLocationRules!: boolean;
	public bAllowNumberSearchOtherLocations!: boolean;
	public bShortenOtherLocationSCNumbersForDialing!: boolean;
	public iCountryID!: number;
	public iLanguage!: number;
	public u8sCountryCode!: string;
	public u8sLocalPrefix!: string;
	public u8sNationalPrefix!: string;
	public u8sInternationalPrefix!: string;
	public u8sLocalDialingRule!: string;
	public u8sNationalDialingRule!: string;
	public u8sInternationalDialingRule!: string;
	public u8sCityCodeNoPrefix!: string;
	public u8sVanityNumbers!: string;
	public u8sTKLocalAccessCode!: string;
	public u8sTKLongDistanceAccessCode!: string;
	public u8sTKInternationalAccessCode!: string;
	public u8sTKPrivateAccessCode!: string;
	public u8sTKForwardingAccessCode!: string;
	public u8sTKAnschlussNr!: string;
	public iTKMinLenInternalNumbers!: number;
	public iTKMaxLenInternalNumbers!: number;
	public numberFormatInternal!: AsnNumberFormatRegExpressions2;
	public numberFormatExternal!: AsnNumberFormatRegExpressions2;
	public numberFormatAutomaticOtherLocationsDDItoSC!: AsnNumberFormatRegExpressions2;
	public numberFormatAutomaticOtherLocationsSCtoDDI!: AsnNumberFormatRegExpressions2;
	public numberFormatRegExpressListIn!: AsnNumberFormatRegExpressions2;
	public numberFormatRegExpressListOut!: AsnNumberFormatRegExpressions2;
	public numberFormatRegExpressListDial!: AsnNumberFormatRegExpressions2;
	public numberFormatRegExpressListFinalDial!: AsnNumberFormatRegExpressions2;
	public projectPINRules!: ENetUC_Common_SIPCTI.AsnProjectPinRuleList;
	public u8sDDIRangeBegin!: string;
	public u8sDDIRangeEnd!: string;
	/** Dialing Number Format. See AsnETAPIDialNumberFormat for details. */
	public iDialingNumberFormat!: number;
	public bNoLocalDialing!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetLocationInformationResult
export { AsnLocationInformation as AsnGetLocationInformationResult };


// [PrintTSTypeDefCode] AsnGetLocationInformation2Result
// [PrintTSSeqDefCode] AsnGetLocationInformation2Result
/**
 * Result of asnGetLocationInformation2.
 */
export class AsnGetLocationInformation2Result {
	public constructor(that: AsnGetLocationInformation2Result) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLocationInformation2Result {
		return new AsnGetLocationInformation2Result({
			asnLocationData: AsnLocationInformation2.initEmpty()
		});
	}

	/** Location information (v2) about the phone number rules on the line. */
	public asnLocationData!: AsnLocationInformation2;
	/** No optional parameters yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetLocationInformation2Argument
// [PrintTSSeqDefCode] AsnSetLocationInformation2Argument
export class AsnSetLocationInformation2Argument {
	public constructor(that: AsnSetLocationInformation2Argument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetLocationInformation2Argument {
		return new AsnSetLocationInformation2Argument({
			asnLocationData: AsnLocationInformation2.initEmpty()
		});
	}

	public asnLocationData!: AsnLocationInformation2;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetClientXMLContentResult
// [PrintTSSeqDefCode] AsnGetClientXMLContentResult
export class AsnGetClientXMLContentResult {
	public constructor(that: AsnGetClientXMLContentResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetClientXMLContentResult {
		return new AsnGetClientXMLContentResult({
			content: AsnXMLClientContent.initEmpty()
		});
	}

	public content!: AsnXMLClientContent;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetClientXMLContentArgument
// [PrintTSSeqDefCode] AsnSetClientXMLContentArgument
export class AsnSetClientXMLContentArgument {
	public constructor(that: AsnSetClientXMLContentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientXMLContentArgument {
		return new AsnSetClientXMLContentArgument({
			content: AsnXMLClientContent.initEmpty()
		});
	}

	public content!: AsnXMLClientContent;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnActionFilters
// [PrintTSSetOfDefCode] AsnActionFilters
export class AsnActionFilters extends Array<AsnActionFilterEntry> { }

// [PrintTSTypeDefCode] AsnLineForwardProfile
// [PrintTSSeqDefCode] AsnLineForwardProfile
export class AsnLineForwardProfile {
	public constructor(that: AsnLineForwardProfile) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLineForwardProfile {
		return new AsnLineForwardProfile({
			u8sGUID: "",
			u8sDisplayName: "",
			lineIdentification: AsnLineForwardProfileChoice.initEmpty(),
			seqLineForward: new ENetUC_Common_SIPCTI.AsnLineForwards()
		});
	}

	/** Eindeutige ID des Profils */
	public u8sGUID!: string;
	/** Anzeigename fÃ¼r die Umleitung */
	public u8sDisplayName!: string;
	/** Auf welchen Leitungen: */
	public lineIdentification!: AsnLineForwardProfileChoice;
	/** Liste der Forwards die gesetzt werden */
	public seqLineForward!: ENetUC_Common_SIPCTI.AsnLineForwards;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAppointmentForwardEntry
// [PrintTSSeqDefCode] AsnAppointmentForwardEntry
export class AsnAppointmentForwardEntry {
	public constructor(that: AsnAppointmentForwardEntry) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAppointmentForwardEntry {
		return new AsnAppointmentForwardEntry({
			iAppointmentContactState: 0,
			lineForwardProfile: AsnLineForwardProfile.initEmpty()
		});
	}

	/** Zustand bei dem diese Umleitung gesetzt werden soll */
	public iAppointmentContactState!: number;
	/** Umleitungsprofil welches mit dem Termin aktiviert\/deaktiviert werden soll */
	public lineForwardProfile!: AsnLineForwardProfile;
	/** Optionale Liste von FilterausdrÃ¼cken, um den Termin noch weiter eingrenzen zu kÃ¶nnen */
	public seqFilters?: AsnActionFilters;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAppointmentForwardEntries
// [PrintTSSetOfDefCode] AsnAppointmentForwardEntries
export class AsnAppointmentForwardEntries extends Array<AsnAppointmentForwardEntry> { }

// [PrintTSTypeDefCode] AsnSetAppointmentForwardsArgument
// [PrintTSSeqDefCode] AsnSetAppointmentForwardsArgument
export class AsnSetAppointmentForwardsArgument {
	public constructor(that: AsnSetAppointmentForwardsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetAppointmentForwardsArgument {
		return new AsnSetAppointmentForwardsArgument({
			seqAppointmentForwardEntries: new AsnAppointmentForwardEntries()
		});
	}

	public seqAppointmentForwardEntries!: AsnAppointmentForwardEntries;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetAppointmentForwardsResult
// [PrintTSSeqDefCode] AsnGetAppointmentForwardsResult
export class AsnGetAppointmentForwardsResult {
	public constructor(that: AsnGetAppointmentForwardsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetAppointmentForwardsResult {
		return new AsnGetAppointmentForwardsResult({
			seqAppointmentForwardEntries: new AsnAppointmentForwardEntries()
		});
	}

	public seqAppointmentForwardEntries!: AsnAppointmentForwardEntries;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactIDs
// [PrintTSSetOfDefCode] AsnContactIDs
export class AsnContactIDs extends Array<AsnContactID> { }

// [PrintTSTypeDefCode] AsnCloseContactsArgument
// [PrintTSSeqDefCode] AsnCloseContactsArgument
export class AsnCloseContactsArgument {
	public constructor(that: AsnCloseContactsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCloseContactsArgument {
		return new AsnCloseContactsArgument({
			seqContactIDs: new AsnContactIDs()
		});
	}

	/** Contacts welche zu schliessen sind, nur die IDs */
	public seqContactIDs!: AsnContactIDs;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSettingsDynamicRightsChangedArgument
export { AsnSetSettingsDynamicRightsArgument as AsnSettingsDynamicRightsChangedArgument };


// [PrintTSTypeDefCode] AsnSettingsDynamicGroupRightsChangedArgument
export { AsnSetSettingsDynamicRightsArgument as AsnSettingsDynamicGroupRightsChangedArgument };


// [PrintTSTypeDefCode] AsnGetSettingsDynamicGroupRightsArgument
export { AsnGetSettingsDynamicRightsArgument as AsnGetSettingsDynamicGroupRightsArgument };


// [PrintTSTypeDefCode] AsnGetSettingsDynamicGroupRightsResult
export { AsnGetSettingsDynamicRightsResult as AsnGetSettingsDynamicGroupRightsResult };


// [PrintTSTypeDefCode] AsnGetSettingsGlobalDynamicRightsArgument
export { AsnGetSettingsDynamicRightsArgument as AsnGetSettingsGlobalDynamicRightsArgument };


// [PrintTSTypeDefCode] AsnPresenceState
// [PrintTSSeqDefCode] AsnPresenceState
export class AsnPresenceState {
	public constructor(that: AsnPresenceState) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnPresenceState {
		return new AsnPresenceState({
			contactID: "",
			iPresenceState: 0,
			iContactFeatureSet: 0,
			asnRemoteContact: ENetUC_Common.AsnNetDatabaseContact.initEmpty(),
			iContactConnectionState: 0,
			u8sAdditionalConnectionInfo: ""
		});
	}

	public contactID!: string;
	/** PresenceState; defined in EContactConstants.h */
	public iPresenceState!: number;
	/** Set of features the user can access on this Contact */
	public iContactFeatureSet!: number;
	/** RemoteContact */
	public asnRemoteContact!: ENetUC_Common.AsnNetDatabaseContact;
	/** Verbindung zum Kontakt */
	public iContactConnectionState!: number;
	/** MÃ¶gliche, weitere Information zur Verbindung */
	public u8sAdditionalConnectionInfo!: string;
	/** Lines des Contacts */
	public seqPhoneLines?: ENetUC_Common_AsnContact.AsnContactPhoneLines;
	/** AbsentState */
	public utcAbsentStartTime?: Date;
	/** Endtime of the Absentstate in UTC */
	public utcAbsentEndTime?: Date;
	/** Short Text of the Absentreason */
	public u8sAbsentReason?: string;
	/** Message for the Absentstate */
	public u8sAbsentMessage?: string;
	/** Notiztext */
	public u8sNote?: string;
	/** Appointment */
	public appointmentEntryList?: ENetUC_Common_Appointments.SEQAppointmentList;
	public asnNextAppointment?: ENetUC_Common_Appointments.AsnAppointmentEntry;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactAbsentState
// [PrintTSSeqDefCode] AsnContactAbsentState
export class AsnContactAbsentState {
	public constructor(that: AsnContactAbsentState) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactAbsentState {
		return new AsnContactAbsentState({
			contactID: "",
			iPresenceState: 0,
			utcAbsentStartTime: new Date(),
			utcAbsentEndTime: new Date(),
			u8sAbsentReason: "",
			u8sAbsentMessage: ""
		});
	}

	public contactID!: string;
	/** PresenceState; defined in EContactConstats.h */
	public iPresenceState!: number;
	/** AbsentState */
	public utcAbsentStartTime!: Date;
	public utcAbsentEndTime!: Date;
	public u8sAbsentReason!: string;
	public u8sAbsentMessage!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactAppointmentState
// [PrintTSSeqDefCode] AsnContactAppointmentState
export class AsnContactAppointmentState {
	public constructor(that: AsnContactAppointmentState) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactAppointmentState {
		return new AsnContactAppointmentState({
			contactID: "",
			iPresenceState: 0,
			appointmentEntryList: new ENetUC_Common_Appointments.SEQAppointmentList(),
			asnNextAppointment: ENetUC_Common_Appointments.AsnAppointmentEntry.initEmpty()
		});
	}

	public contactID!: string;
	/** PresenceState; defined in EContactConstats.h */
	public iPresenceState!: number;
	/** Appointment */
	public appointmentEntryList!: ENetUC_Common_Appointments.SEQAppointmentList;
	public asnNextAppointment!: ENetUC_Common_Appointments.AsnAppointmentEntry;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactCallInformationChangedArgument
// [PrintTSSeqDefCode] AsnContactCallInformationChangedArgument
export class AsnContactCallInformationChangedArgument {
	public constructor(that: AsnContactCallInformationChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactCallInformationChangedArgument {
		return new AsnContactCallInformationChangedArgument({
			contactID: "",
			iPresenceState: 0,
			callinfo: ENetUC_Common_AsnContact.AsnContactCallInformation.initEmpty()
		});
	}

	/** KontaktID des Kontaktes, der diese Nachricht versendet */
	public contactID!: string;
	/** PresenceState; defined in EContactConstats.h */
	public iPresenceState!: number;
	public callinfo!: ENetUC_Common_AsnContact.AsnContactCallInformation;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateMyPhoneForwardStateArgument
export { AsnNotifyLineForwardStateArgument as AsnUpdateMyPhoneForwardStateArgument };


// [PrintTSTypeDefCode] AsnContactPhoneForwardStateChangedArgument
export { AsnNotifyLineForwardStateArgument as AsnContactPhoneForwardStateChangedArgument };


// [PrintTSTypeDefCode] AsnClientInfoList
// [PrintTSSetOfDefCode] AsnClientInfoList
export class AsnClientInfoList extends Array<AsnClientInfo> { }

// [PrintTSTypeDefCode] AsnMyUpdateConnectedClientListArgument
// [PrintTSSeqDefCode] AsnMyUpdateConnectedClientListArgument
export class AsnMyUpdateConnectedClientListArgument {
	public constructor(that: AsnMyUpdateConnectedClientListArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMyUpdateConnectedClientListArgument {
		return new AsnMyUpdateConnectedClientListArgument({
			listClientInfos: new AsnClientInfoList()
		});
	}

	public listClientInfos!: AsnClientInfoList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAbsentStateGetUserArgument
// [PrintTSSeqDefCode] AsnAbsentStateGetUserArgument
export class AsnAbsentStateGetUserArgument {
	public constructor(that: AsnAbsentStateGetUserArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentStateGetUserArgument {
		return new AsnAbsentStateGetUserArgument({
			contactId: ""
		});
	}

	public contactId!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetMetaSettingsArgument
// [PrintTSSeqDefCode] AsnGetMetaSettingsArgument
export class AsnGetMetaSettingsArgument {
	public constructor(that: AsnGetMetaSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetMetaSettingsArgument {
		return new AsnGetMetaSettingsArgument({
			eSendPlainPassword: AsnMetaDirectoryPasswordEncryption.iEncrypted
		});
	}

	/** Soll das Passwort fÃ¼r das MetaDirectory verschlÃ¼sselt Ã¼bermittelt werden 1: ja, 0(default): nein */
	public eSendPlainPassword!: AsnMetaDirectoryPasswordEncryption;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnMetaDirectorySettings
// [PrintTSSeqDefCode] AsnMetaDirectorySettings
export class AsnMetaDirectorySettings {
	public constructor(that: AsnMetaDirectorySettings) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMetaDirectorySettings {
		return new AsnMetaDirectorySettings({
			iMetaEnabled: 0,
			iMetaPort: 0,
			u8sMetaServer: "",
			u8sBaseDN: "",
			iMetaForceSSL: 0,
			iMetaUseAccount: 0,
			u8sMetaUser: "",
			ePasswordEncryption: AsnMetaDirectoryPasswordEncryption.iEncrypted,
			u8sMetaPassword: "",
			iMetaUseInServer: 0,
			iMetaUseInClient: 0
		});
	}

	/** Meta interface enabled? */
	public iMetaEnabled!: number;
	/** TCP Port */
	public iMetaPort!: number;
	/** Servername */
	public u8sMetaServer!: string;
	/** BaseDN */
	public u8sBaseDN!: string;
	/** Soll eine LDAPS-Verbindung aufgebaut werden */
	public iMetaForceSSL!: number;
	/** Soll User und Passwort fÃ¼r die Metaverbindung verwendet werden */
	public iMetaUseAccount!: number;
	/** Username am MetaDirectory */
	public u8sMetaUser!: string;
	/** Passwort ist verschlÃ¼sselt (0) oder Plaintext (1) */
	public ePasswordEncryption!: AsnMetaDirectoryPasswordEncryption;
	/** Passwort des Users am MetaDirectory */
	public u8sMetaPassword!: string;
	/** Use the meta with the CTI Server */
	public iMetaUseInServer!: number;
	/** Use the meta with the ProCall Client */
	public iMetaUseInClient!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetMetaSettingsArgument
// [PrintTSSeqDefCode] AsnSetMetaSettingsArgument
export class AsnSetMetaSettingsArgument {
	public constructor(that: AsnSetMetaSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetMetaSettingsArgument {
		return new AsnSetMetaSettingsArgument({
			eSendPlainPassword: AsnMetaDirectoryPasswordEncryption.iEncrypted,
			metasettings: AsnMetaDirectorySettings.initEmpty()
		});
	}

	/** Soll das Passwort fÃ¼r das MetaDirectory verschlÃ¼sselt Ã¼bermittelt werden 1: ja, 0(default): nein */
	public eSendPlainPassword!: AsnMetaDirectoryPasswordEncryption;
	public metasettings!: AsnMetaDirectorySettings;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnEMailAttachmentList
// [PrintTSSetOfDefCode] AsnEMailAttachmentList
export class AsnEMailAttachmentList extends Array<AsnEMailAttachment> { }

// [PrintTSTypeDefCode] AsnEMailPartyList
// [PrintTSSetOfDefCode] AsnEMailPartyList
export class AsnEMailPartyList extends Array<AsnEMailParty> { }

// [PrintTSTypeDefCode] AsnEMailBodyList
// [PrintTSSetOfDefCode] AsnEMailBodyList
export class AsnEMailBodyList extends Array<AsnEMailBody> { }

// [PrintTSTypeDefCode] AsnEMail
// [PrintTSSeqDefCode] AsnEMail
export class AsnEMail {
	public constructor(that: AsnEMail) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnEMail {
		return new AsnEMail({
			originatorParty: AsnEMailParty.initEmpty(),
			recipientParties: new AsnEMailPartyList(),
			u8sSubject: "",
			bodyList: new AsnEMailBodyList()
		});
	}

	public originatorParty!: AsnEMailParty;
	public recipientParties!: AsnEMailPartyList;
	public u8sSubject!: string;
	public bodyList!: AsnEMailBodyList;
	public attachmentList?: AsnEMailAttachmentList;
	public returnParties?: AsnEMailPartyList;
	public carbonCopyParties?: AsnEMailPartyList;
	public blindCarbonParties?: AsnEMailPartyList;
	public bSendNotification?: boolean;
	public bReadNotification?: boolean;
	/** EnumEmailImportance */
	public iEmailImportance?: number;
	public u8sMessageID?: string;
	public u8sMapOptionalHeaderElements?: ENetUC_Common.AsnStringPairList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnEMailList
// [PrintTSSetOfDefCode] AsnEMailList
export class AsnEMailList extends Array<AsnEMail> { }

// [PrintTSTypeDefCode] AsnSendClientSideMailArgument
// [PrintTSSeqDefCode] AsnSendClientSideMailArgument
export class AsnSendClientSideMailArgument {
	public constructor(that: AsnSendClientSideMailArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendClientSideMailArgument {
		return new AsnSendClientSideMailArgument({
			mails: new AsnEMailList()
		});
	}

	public mails!: AsnEMailList;
}

// [PrintTSTypeDefCode] AsnDBPhoneBookList
// [PrintTSSetOfDefCode] AsnDBPhoneBookList
export class AsnDBPhoneBookList extends Array<AsnDBPhoneBook> { }

// [PrintTSTypeDefCode] AsnFindDBContactsArgument
// [PrintTSChoiceDefCode] AsnFindDBContactsArgument
export class AsnFindDBContactsArgument {
	public constructor(that?: AsnFindDBContactsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnFindDBContactsArgument {
		return new AsnFindDBContactsArgument();
	}

	public findoptions?: AsnDatabaseFindOption = undefined;
	public entryid?: AsnDatabaseEntryID = undefined;
}

// [PrintTSTypeDefCode] AsnFindDBContactsResult
// [PrintTSSeqDefCode] AsnFindDBContactsResult
export class AsnFindDBContactsResult {
	public constructor(that: AsnFindDBContactsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnFindDBContactsResult {
		return new AsnFindDBContactsResult({
			contactList: new ENetUC_Common.AsnNetDatabaseContactList(),
			phonebookList: new AsnDBPhoneBookList()
		});
	}

	public contactList!: ENetUC_Common.AsnNetDatabaseContactList;
	public phonebookList!: AsnDBPhoneBookList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnFindContactsArgument
// [PrintTSSeqDefCode] AsnFindContactsArgument
export class AsnFindContactsArgument {
	public constructor(that: AsnFindContactsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnFindContactsArgument {
		return new AsnFindContactsArgument({
			findOptions: AsnNetDatabaseFindOptions.initEmpty()
		});
	}

	public findOptions!: AsnNetDatabaseFindOptions;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnFindContactsResult
// [PrintTSSeqDefCode] AsnFindContactsResult
export class AsnFindContactsResult {
	public constructor(that: AsnFindContactsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnFindContactsResult {
		return new AsnFindContactsResult({
			contactList: new ENetUC_Common.AsnNetDatabaseContactList(),
			phonebookList: new AsnDBPhoneBookList()
		});
	}

	public contactList!: ENetUC_Common.AsnNetDatabaseContactList;
	public phonebookList!: AsnDBPhoneBookList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetPhoneBooksResult
// [PrintTSSeqDefCode] AsnGetPhoneBooksResult
export class AsnGetPhoneBooksResult {
	public constructor(that: AsnGetPhoneBooksResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetPhoneBooksResult {
		return new AsnGetPhoneBooksResult({
			phonebooks: new AsnDBPhoneBookList()
		});
	}

	public phonebooks!: AsnDBPhoneBookList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetServerBasedClientSettingsResult
// [PrintTSSeqDefCode] AsnGetServerBasedClientSettingsResult
export class AsnGetServerBasedClientSettingsResult {
	public constructor(that: AsnGetServerBasedClientSettingsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetServerBasedClientSettingsResult {
		return new AsnGetServerBasedClientSettingsResult({
			iResult: 0,
			clientSettings: AsnServerBasedClientSettings.initEmpty()
		});
	}

	/** 0 = Success (NO_ERROR), sonst Fehlercode (i.d.R. &lt; 0) */
	public iResult!: number;
	/** Liste von Settings die der Server liefert */
	public clientSettings!: AsnServerBasedClientSettings;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetServerBasedClientSettingsArgument
// [PrintTSSeqDefCode] AsnSetServerBasedClientSettingsArgument
export class AsnSetServerBasedClientSettingsArgument {
	public constructor(that: AsnSetServerBasedClientSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetServerBasedClientSettingsArgument {
		return new AsnSetServerBasedClientSettingsArgument({
			clientSettings: AsnServerBasedClientSettings.initEmpty()
		});
	}

	/** XML formatierte Settings, formatiert wie diese auch in der Registry stehen wÃ¼rden */
	public clientSettings!: AsnServerBasedClientSettings;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactStatusParameter
// [PrintTSSeqDefCode] AsnContactStatusParameter
/**
 * Diese Schnittstelle dient zur Anforderung und Ã¼bermittlung von Contact-Stati
 * fÃ¼r Suchlisten am Client.
 * **
 * ** Datatype for transport of ContactStatus Messages
 * **
 *
 * @private
 */
export class AsnContactStatusParameter {
	public constructor(that: AsnContactStatusParameter) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusParameter {
		return new AsnContactStatusParameter({
			typeOfValue: AsnContactStatusParameterEnum.activeContactID,
			u8sValue: ""
		});
	}

	public typeOfValue!: AsnContactStatusParameterEnum;
	public u8sValue!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] SEQContactStatusParameter
// [PrintTSSetOfDefCode] SEQContactStatusParameter
export class SEQContactStatusParameter extends Array<AsnContactStatusParameter> { }

// [PrintTSTypeDefCode] AsnContactStatusRequestEntry
// [PrintTSSeqDefCode] AsnContactStatusRequestEntry
export class AsnContactStatusRequestEntry {
	public constructor(that: AsnContactStatusRequestEntry) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusRequestEntry {
		return new AsnContactStatusRequestEntry({
			iClientSearchID: 0,
			iClientRowID: 0,
			listSearchParameter: new SEQContactStatusParameter(),
			bActivateNotify: false,
			listDeactivateNotify: new ENetUC_Common.SEQInteger()
		});
	}

	/** ID der Suchanfrage um z.B. mehrere, gleichzeitige Suchfenster am Client unterscheiden zu kÃ¶nnen */
	public iClientSearchID!: number;
	/** ID der Zeile in der oben angegebenen Suchanfrage sollte bei 1 beginnen um 0 bei listDeactivateNotify verwenden zu kÃ¶nnen */
	public iClientRowID!: number;
	/** Liste der Suchparameter (email\/rufnummer\/entryid) */
	public listSearchParameter!: SEQContactStatusParameter;
	/** Sollen die Ergebnisse als Notifies registriert werden ? true = ja, false = nein (Not Null) */
	public bActivateNotify!: boolean;
	/** Liste von RowIDs, die von der Notifylist am Server gelÃ¶scht werden sollen (Not Null), muss mind. ein ASNInterger beinhalten */
	public listDeactivateNotify!: ENetUC_Common.SEQInteger;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] SEQContactStatusRequest
// [PrintTSSetOfDefCode] SEQContactStatusRequest
export class SEQContactStatusRequest extends Array<AsnContactStatusRequestEntry> { }

// [PrintTSTypeDefCode] SEQContactStatusResult
// [PrintTSSetOfDefCode] SEQContactStatusResult
export class SEQContactStatusResult extends Array<AsnContactStatusResultEntry> { }

// [PrintTSTypeDefCode] AsnContactStatusRequestArgument
// [PrintTSSeqDefCode] AsnContactStatusRequestArgument
export class AsnContactStatusRequestArgument {
	public constructor(that: AsnContactStatusRequestArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusRequestArgument {
		return new AsnContactStatusRequestArgument({
			request: new SEQContactStatusRequest()
		});
	}

	public request!: SEQContactStatusRequest;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnContactStatusRequestResult
// [PrintTSSeqDefCode] AsnContactStatusRequestResult
export class AsnContactStatusRequestResult {
	public constructor(that: AsnContactStatusRequestResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactStatusRequestResult {
		return new AsnContactStatusRequestResult({
			iResult: 0,
			iCount: 0,
			listStatusResult: new SEQContactStatusResult()
		});
	}

	public iResult!: number;
	public iCount!: number;
	public listStatusResult!: SEQContactStatusResult;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnPublicUserSetContactDataArgument
// [PrintTSSeqDefCode] AsnPublicUserSetContactDataArgument
export class AsnPublicUserSetContactDataArgument {
	public constructor(that: AsnPublicUserSetContactDataArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnPublicUserSetContactDataArgument {
		return new AsnPublicUserSetContactDataArgument({
			contactData: AsnPublicUserContactData.initEmpty()
		});
	}

	public contactData!: AsnPublicUserContactData;
}

// [PrintTSTypeDefCode] AsnSMSMessages
// [PrintTSSetOfDefCode] AsnSMSMessages
export class AsnSMSMessages extends Array<AsnSMSMessage> { }

// [PrintTSTypeDefCode] AsnSendSMSArgument
// [PrintTSSeqDefCode] AsnSendSMSArgument
export class AsnSendSMSArgument {
	public constructor(that: AsnSendSMSArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSendSMSArgument {
		return new AsnSendSMSArgument({
			messages: new AsnSMSMessages()
		});
	}

	/** Liste der SMS die verschickt werden sollen */
	public messages!: AsnSMSMessages;
}

// [PrintTSTypeDefCode] AsnSMSStatusNotifyArgument
// [PrintTSSeqDefCode] AsnSMSStatusNotifyArgument
export class AsnSMSStatusNotifyArgument {
	public constructor(that: AsnSMSStatusNotifyArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSMSStatusNotifyArgument {
		return new AsnSMSStatusNotifyArgument({
			u8sMonitorCrossRefID: "",
			status: AsnSMSStatus.eSMSSTATUSSUCCESS,
			u8sErrReason: ""
		});
	}

	public u8sMonitorCrossRefID!: string;
	public status!: AsnSMSStatus;
	/** Bei eSMSSTATUSERROR kann hier noch ein Grund stehen (logging) */
	public u8sErrReason!: string;
}

// [PrintTSTypeDefCode] AsnUserDefinedCustomFields
// [PrintTSSetOfDefCode] AsnUserDefinedCustomFields
export class AsnUserDefinedCustomFields extends Array<AsnUserDefinedCustomField> { }

// [PrintTSTypeDefCode] AsnGetUserdefinedCustomFieldsResult
// [PrintTSSeqDefCode] AsnGetUserdefinedCustomFieldsResult
export class AsnGetUserdefinedCustomFieldsResult {
	public constructor(that: AsnGetUserdefinedCustomFieldsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetUserdefinedCustomFieldsResult {
		return new AsnGetUserdefinedCustomFieldsResult({
			customfields: new AsnUserDefinedCustomFields()
		});
	}

	public customfields!: AsnUserDefinedCustomFields;
}

// [PrintTSTypeDefCode] AsnOpenContactsArgument
// [PrintTSSeqDefCode] AsnOpenContactsArgument
export class AsnOpenContactsArgument {
	public constructor(that: AsnOpenContactsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOpenContactsArgument {
		return new AsnOpenContactsArgument({
			seqContactIDs: new AsnContactIDs()
		});
	}

	/** Contacts welche zu holen sind; wenn liste leer dann alle */
	public seqContactIDs!: AsnContactIDs;
	/** Nur die Kontakte Ã¶ffnen, die der entsprechende Server selbst verwaltet */
	public bOpenOnlyInternalContacts?: boolean;
	/** Keine Berechtigungsanfrage starten */
	public bDontRequestPermissions?: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetMetaSettingsResult
// [PrintTSSeqDefCode] AsnGetMetaSettingsResult
export class AsnGetMetaSettingsResult {
	public constructor(that: AsnGetMetaSettingsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetMetaSettingsResult {
		return new AsnGetMetaSettingsResult({
			metasettings: AsnMetaDirectorySettings.initEmpty()
		});
	}

	public metasettings!: AsnMetaDirectorySettings;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAbsentStates
// [PrintTSSetOfDefCode] AsnAbsentStates
export class AsnAbsentStates extends Array<ENetUC_Common_AsnContact.AsnAbsentState> { }

// [PrintTSTypeDefCode] AsnSetPreferredContactRefResult
// [PrintTSSeqDefCode] AsnSetPreferredContactRefResult
export class AsnSetPreferredContactRefResult {
	public constructor(that?: AsnSetPreferredContactRefResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetPreferredContactRefResult {
		return new AsnSetPreferredContactRefResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnRedisRemoteInvoke
// [PrintTSSeqDefCode] AsnRedisRemoteInvoke
/**
 * wird nur zwischen 2 ucservern ueber redis verschickt
 *
 * @private
 */
export class AsnRedisRemoteInvoke {
	public constructor(that: AsnRedisRemoteInvoke) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRedisRemoteInvoke {
		return new AsnRedisRemoteInvoke({
			u8sInvokeID: "",
			u8sInvoker: "",
			u8sInvokingServer: "",
			u8sInvokingConnection: "",
			u8sOp: "",
			u8sArg: ""
		});
	}

	public u8sInvokeID!: string;
	public u8sInvoker!: string;
	public u8sInvokingServer!: string;
	public u8sInvokingConnection!: string;
	public u8sOp!: string;
	public u8sArg!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}
