// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_Transport.ts
 * "UC-Server-Access-Protocol-Transport" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * Transport layer interface
 * ## Module description
 * The module defines operations on the transport layer of the business logic.
 * This includes asnStartTLS which activates TLS on a already existing TCP connection and additional operations for
 * running multiplexed connections to the UCServer (several sessions of different users on one TCP connection; creating, deleting them and user authentication).
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Auth from "./ENetUC_Auth";

// [PrintTSTypeDefCode] AsnRegisterServerErrorEnum
// [PrintTSEnumDefCode] AsnRegisterServerErrorEnum
export enum AsnRegisterServerErrorEnum {
	general = 0,
	invalidProtocolVersion = 1,
	invalidServerID = 2,
	invalidLogin = 3,
	redirect = 4,
	/** UCSID is already connected */
	duplicateLogon = 5
}

// [PrintTSTypeDefCode] AsnWebServiceLogonErrorEnum
// [PrintTSEnumDefCode] AsnWebServiceLogonErrorEnum
export enum AsnWebServiceLogonErrorEnum {
	general = 0,
	invalidProtocolVersion = 1,
	invalidUser = 2,
	invalidPassword = 3
}

// [PrintTSTypeDefCode] AsnStartTLSArgument
// [PrintTSSeqDefCode] AsnStartTLSArgument
export class AsnStartTLSArgument {
	public constructor(that?: AsnStartTLSArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnStartTLSArgument {
		return new AsnStartTLSArgument();
	}
}

// [PrintTSTypeDefCode] AsnStartTLSResult
// [PrintTSSeqDefCode] AsnStartTLSResult
export class AsnStartTLSResult {
	public constructor(that?: AsnStartTLSResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnStartTLSResult {
		return new AsnStartTLSResult();
	}
}

// [PrintTSTypeDefCode] AsnStartMultiUserModeArgument
// [PrintTSSeqDefCode] AsnStartMultiUserModeArgument
export class AsnStartMultiUserModeArgument {
	public constructor(that?: AsnStartMultiUserModeArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnStartMultiUserModeArgument {
		return new AsnStartMultiUserModeArgument();
	}
}

// [PrintTSTypeDefCode] AsnStartMultiUserModeResult
// [PrintTSSeqDefCode] AsnStartMultiUserModeResult
export class AsnStartMultiUserModeResult {
	public constructor(that?: AsnStartMultiUserModeResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnStartMultiUserModeResult {
		return new AsnStartMultiUserModeResult();
	}
}

// [PrintTSTypeDefCode] AsnCreateUserSessionArgument
// [PrintTSSeqDefCode] AsnCreateUserSessionArgument
export class AsnCreateUserSessionArgument {
	public constructor(that: AsnCreateUserSessionArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCreateUserSessionArgument {
		return new AsnCreateUserSessionArgument({
			negotiate: ENetUC_Auth.AsnNegotiateServerConnectionArgument.initEmpty()
		});
	}

	public negotiate!: ENetUC_Auth.AsnNegotiateServerConnectionArgument;
}

// [PrintTSTypeDefCode] AsnCreateUserSessionResult
// [PrintTSSeqDefCode] AsnCreateUserSessionResult
export class AsnCreateUserSessionResult {
	public constructor(that: AsnCreateUserSessionResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCreateUserSessionResult {
		return new AsnCreateUserSessionResult({
			u8sSessionID: "",
			negotiateresult: ENetUC_Auth.AsnNegotiateServerConnectionResult.initEmpty()
		});
	}

	/** SessionID of the created user session. All further invokes for this user session must include this SessionID. */
	public u8sSessionID!: string;
	public negotiateresult!: ENetUC_Auth.AsnNegotiateServerConnectionResult;
}

// [PrintTSTypeDefCode] AsnCreateUserSessionError
// [PrintTSSeqDefCode] AsnCreateUserSessionError
export class AsnCreateUserSessionError {
	public constructor(that: AsnCreateUserSessionError) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCreateUserSessionError {
		return new AsnCreateUserSessionError({
			iErrorDetail: 0,
			u8sErrorString: "",
			negotiateerror: ENetUC_Common.AsnRequestError.initEmpty()
		});
	}

	public iErrorDetail!: number;
	public u8sErrorString!: string;
	public negotiateerror!: ENetUC_Common.AsnRequestError;
}

// [PrintTSTypeDefCode] AsnDestroyUserSessionArgument
// [PrintTSSeqDefCode] AsnDestroyUserSessionArgument
export class AsnDestroyUserSessionArgument {
	public constructor(that: AsnDestroyUserSessionArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDestroyUserSessionArgument {
		return new AsnDestroyUserSessionArgument({
			u8sSessionID: ""
		});
	}

	public u8sSessionID!: string;
}

// [PrintTSTypeDefCode] AsnDestroyUserSessionResult
// [PrintTSSeqDefCode] AsnDestroyUserSessionResult
export class AsnDestroyUserSessionResult {
	public constructor(that?: AsnDestroyUserSessionResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDestroyUserSessionResult {
		return new AsnDestroyUserSessionResult();
	}
}

// [PrintTSTypeDefCode] AsnUserSessionDestroyedArgument
// [PrintTSSeqDefCode] AsnUserSessionDestroyedArgument
export class AsnUserSessionDestroyedArgument {
	public constructor(that: AsnUserSessionDestroyedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUserSessionDestroyedArgument {
		return new AsnUserSessionDestroyedArgument({
			u8sSessionID: ""
		});
	}

	public u8sSessionID!: string;
}

// [PrintTSTypeDefCode] AsnRegisterExternalServiceResult
// [PrintTSSeqDefCode] AsnRegisterExternalServiceResult
export class AsnRegisterExternalServiceResult {
	public constructor(that?: AsnRegisterExternalServiceResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRegisterExternalServiceResult {
		return new AsnRegisterExternalServiceResult();
	}
}

// [PrintTSTypeDefCode] AsnGetExternalServiceProfileArgument
// [PrintTSSeqDefCode] AsnGetExternalServiceProfileArgument
export class AsnGetExternalServiceProfileArgument {
	public constructor(that: AsnGetExternalServiceProfileArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetExternalServiceProfileArgument {
		return new AsnGetExternalServiceProfileArgument({
			iVersion: 0,
			u8sToken: ""
		});
	}

	/** Version of the protocol */
	public iVersion!: number;
	/** Client token */
	public u8sToken!: string;
	/** for future use */
	public iFlags?: number;
}

// [PrintTSTypeDefCode] AsnGetExternalServiceProfileResult
// [PrintTSSeqDefCode] AsnGetExternalServiceProfileResult
export class AsnGetExternalServiceProfileResult {
	public constructor(that?: AsnGetExternalServiceProfileResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetExternalServiceProfileResult {
		return new AsnGetExternalServiceProfileResult();
	}
}

// [PrintTSTypeDefCode] AsnRegisterServerArgument
// [PrintTSSeqDefCode] AsnRegisterServerArgument
export class AsnRegisterServerArgument {
	public constructor(that: AsnRegisterServerArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRegisterServerArgument {
		return new AsnRegisterServerArgument({
			iVersion: 0,
			u8sVersion: "",
			u8sServerName: "",
			u8sServerID: "",
			u8sServerPassword: "",
			presenceDomains: new ENetUC_Common.UTF8StringList(),
			u8sSystemID: "",
			iInterfaceVersion: 0
		});
	}

	/** version of the protocol */
	public iVersion!: number;
	/** Server Version (z.B. 5.0.0.100) */
	public u8sVersion!: string;
	/** Server Name FQDN */
	public u8sServerName!: string;
	/** Server UUID (UCSID) */
	public u8sServerID!: string;
	/** Server Password */
	public u8sServerPassword!: string;
	/** Liste der Presence Domains */
	public presenceDomains!: ENetUC_Common.UTF8StringList;
	/** Server System Identifier */
	public u8sSystemID!: string;
	/** interface version of ucserver */
	public iInterfaceVersion!: number;
	/** Public key (falls vorhanden) zum validieren des Tokens */
	public u8sJWTPublicKey?: string;
	/** HardwareID for license service */
	public u8sHardwareID?: string;
}

// [PrintTSTypeDefCode] AsnWebServiceCapabilities
// [PrintTSSeqDefCode] AsnWebServiceCapabilities
export class AsnWebServiceCapabilities {
	public constructor(that: AsnWebServiceCapabilities) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnWebServiceCapabilities {
		return new AsnWebServiceCapabilities({
			u8sType: ""
		});
	}

	/** type of Capability (asnWebServiceCapabilityTypeTurn, ...) */
	public u8sType!: string;
	/** additional Information about this Capability */
	public u8sValue?: string;
	/** additional Information about this Capability */
	public iValue?: number;
}

// [PrintTSTypeDefCode] AsnRegisterServerError
// [PrintTSSeqDefCode] AsnRegisterServerError
export class AsnRegisterServerError {
	public constructor(that: AsnRegisterServerError) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRegisterServerError {
		return new AsnRegisterServerError({
			iErrorDetail: 0,
			u8sErrorString: ""
		});
	}

	public iErrorDetail!: number;
	public u8sErrorString!: string;
	/** redirect Addresses for this UCServer - tells which servers are responsible for this ServerID (enetcti:\/\/server:port or enetctis:\/\/server:port) */
	public redirectAddresses?: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode] AsnWebServiceLogonResult
// [PrintTSSeqDefCode] AsnWebServiceLogonResult
export class AsnWebServiceLogonResult {
	public constructor(that: AsnWebServiceLogonResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnWebServiceLogonResult {
		return new AsnWebServiceLogonResult({
			iVersion: 0,
			u8sVersion: "",
			u8sServerName: "",
			presenceDomains: new ENetUC_Common.UTF8StringList(),
			iInterfaceVersion: 0
		});
	}

	/** version of the protocol */
	public iVersion!: number;
	/** Server Version (z.B. 5.0.0.100) */
	public u8sVersion!: string;
	/** Server Name FQDN */
	public u8sServerName!: string;
	/** Liste der Presence Domains */
	public presenceDomains!: ENetUC_Common.UTF8StringList;
	/** interface version of the ucserver */
	public iInterfaceVersion!: number;
}

// [PrintTSTypeDefCode] AsnWebListenInterfaceStatus
// [PrintTSSeqDefCode] AsnWebListenInterfaceStatus
export class AsnWebListenInterfaceStatus {
	public constructor(that: AsnWebListenInterfaceStatus) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnWebListenInterfaceStatus {
		return new AsnWebListenInterfaceStatus({
			u8sInterfaceGUID: "",
			u8sType: "",
			listenStatus: 0
		});
	}

	/** GUID of Listen Interface */
	public u8sInterfaceGUID!: string;
	/** Type of Listen Interface (http or https) */
	public u8sType!: string;
	/** Listen Status from EDataStructuresListeningInterfaceStatus */
	public listenStatus!: number;
}

// [PrintTSTypeDefCode] AsnTransportKeepAliveArgument
// [PrintTSSeqDefCode] AsnTransportKeepAliveArgument
export class AsnTransportKeepAliveArgument {
	public constructor(that: AsnTransportKeepAliveArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnTransportKeepAliveArgument {
		return new AsnTransportKeepAliveArgument({
			dummy: {}
		});
	}

	public dummy!: object;
}

// [PrintTSTypeDefCode] AsnWebGetIceServersArgument
// [PrintTSSeqDefCode] AsnWebGetIceServersArgument
export class AsnWebGetIceServersArgument {
	public constructor(that?: AsnWebGetIceServersArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnWebGetIceServersArgument {
		return new AsnWebGetIceServersArgument();
	}

	/** json config for Turn Provider */
	public sTurnProviderConfig?: string;
}

// [PrintTSTypeDefCode] AsnWebIceServer
// [PrintTSSeqDefCode] AsnWebIceServer
/**
 * WebRtc Browser kompatible iceServers
 *
 * @private
 */
export class AsnWebIceServer {
	public constructor(that: AsnWebIceServer) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnWebIceServer {
		return new AsnWebIceServer({
			urls: new ENetUC_Common.UTF8StringList()
		});
	}

	/** turn username to use */
	public username?: string;
	/** turn password to use */
	public credential?: string;
	/** uri list (Peerconnection supports String or Array, we choose Array here) */
	public urls!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode] AsnTokenVerifyArgument
// [PrintTSSeqDefCode] AsnTokenVerifyArgument
export class AsnTokenVerifyArgument {
	public constructor(that: AsnTokenVerifyArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnTokenVerifyArgument {
		return new AsnTokenVerifyArgument({
			sToken: ""
		});
	}

	/** JWT Token */
	public sToken!: string;
}

// [PrintTSTypeDefCode] AsnTokenVerifyResult
// [PrintTSSeqDefCode] AsnTokenVerifyResult
export class AsnTokenVerifyResult {
	public constructor(that: AsnTokenVerifyResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnTokenVerifyResult {
		return new AsnTokenVerifyResult({
			stValidTo: new Date(),
			iType: 0,
			u8sDNPath: "",
			u8sUserName: "",
			u8sSIPURI: "",
			u8sobjectGUID: "",
			u8sPhoneNumber: "",
			u8sPhoneSecondary: "",
			u8sCallRecordingNumber: "",
			u8sMailboxNumber: "",
			u8sHomeServer: "",
			iUserStaticRights: 0,
			asnUserContact: ENetUC_Common.AsnNetDatabaseContact.initEmpty(),
			asnUserPropertyBag: ENetUC_Common.AsnUserPropertyBag.initEmpty()
		});
	}

	/** GÃ¼ltig bis: AsnSystemTime */
	public stValidTo!: Date;
	/** Type of token (AsnUserTokenType: eUserTokenExternal or eUserTokenLogin) */
	public iType!: number;
	public u8sDNPath!: string;
	public u8sUserName!: string;
	public u8sSIPURI!: string;
	public u8sobjectGUID!: string;
	public u8sPhoneNumber!: string;
	public u8sPhoneSecondary!: string;
	public u8sCallRecordingNumber!: string;
	public u8sMailboxNumber!: string;
	public u8sHomeServer!: string;
	public iUserStaticRights!: number;
	public asnUserContact!: ENetUC_Common.AsnNetDatabaseContact;
	public asnUserPropertyBag!: ENetUC_Common.AsnUserPropertyBag;
}

// [PrintTSTypeDefCode] AsnCheckConnectionArgument
// [PrintTSSeqDefCode] AsnCheckConnectionArgument
export class AsnCheckConnectionArgument {
	public constructor(that?: AsnCheckConnectionArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCheckConnectionArgument {
		return new AsnCheckConnectionArgument();
	}
}

// [PrintTSTypeDefCode] AsnCheckConnectionResult
// [PrintTSSeqDefCode] AsnCheckConnectionResult
export class AsnCheckConnectionResult {
	public constructor(that: AsnCheckConnectionResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCheckConnectionResult {
		return new AsnCheckConnectionResult({
			u8sServerVersion: "",
			u8sProductVersion: "",
			iProtocolVersion: 0,
			iOEMID: 0,
			u8sCookie: ""
		});
	}

	/** Server Version \"5.1.30.35169\" */
	public u8sServerVersion!: string;
	/** Product Version \"5.1.30.35169\" */
	public u8sProductVersion!: string;
	/** Protocol Version (60) */
	public iProtocolVersion!: number;
	/** estos : 0 */
	public iOEMID!: number;
	/** Cookie, das der Server vom Admin bekommt, um zu schauen, ob er das ist. */
	public u8sCookie!: string;
}

// [PrintTSTypeDefCode] AsnRequestCERTokenArgument
// [PrintTSSeqDefCode] AsnRequestCERTokenArgument
export class AsnRequestCERTokenArgument {
	public constructor(that?: AsnRequestCERTokenArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestCERTokenArgument {
		return new AsnRequestCERTokenArgument();
	}
}

// [PrintTSTypeDefCode] AsnRequestCERTokenResult
// [PrintTSSeqDefCode] AsnRequestCERTokenResult
export class AsnRequestCERTokenResult {
	public constructor(that: AsnRequestCERTokenResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestCERTokenResult {
		return new AsnRequestCERTokenResult({
			u8sToken: ""
		});
	}

	/** Token zum upload eines ErrorReports */
	public u8sToken!: string;
}

// [PrintTSTypeDefCode] AsnGetTimeArgument
// [PrintTSSeqDefCode] AsnGetTimeArgument
export class AsnGetTimeArgument {
	public constructor(that?: AsnGetTimeArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetTimeArgument {
		return new AsnGetTimeArgument();
	}
}

// [PrintTSTypeDefCode] AsnGetTimeResult
// [PrintTSSeqDefCode] AsnGetTimeResult
export class AsnGetTimeResult {
	public constructor(that: AsnGetTimeResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetTimeResult {
		return new AsnGetTimeResult({
			stSystemtime: new Date()
		});
	}

	/** Systemzeit auf dem Server */
	public stSystemtime!: Date;
}

// [PrintTSTypeDefCode] AsnWebServiceCapabilitiesList
// [PrintTSSetOfDefCode] AsnWebServiceCapabilitiesList
export class AsnWebServiceCapabilitiesList extends Array<AsnWebServiceCapabilities> { }

// [PrintTSTypeDefCode] AsnRegisterServerResult
// [PrintTSSeqDefCode] AsnRegisterServerResult
export class AsnRegisterServerResult {
	public constructor(that?: AsnRegisterServerResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRegisterServerResult {
		return new AsnRegisterServerResult();
	}

	public capabilities?: AsnWebServiceCapabilitiesList;
}

// [PrintTSTypeDefCode] AsnWebServiceLogonArgument
// [PrintTSSeqDefCode] AsnWebServiceLogonArgument
export class AsnWebServiceLogonArgument {
	public constructor(that: AsnWebServiceLogonArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnWebServiceLogonArgument {
		return new AsnWebServiceLogonArgument({
			iVersion: 0,
			u8sVersion: "",
			u8sUser: "",
			u8sPassword: "",
			capabilities: new AsnWebServiceCapabilitiesList()
		});
	}

	/** version of the protocol */
	public iVersion!: number;
	/** WebService Version (z.B. 5.0.0.100) */
	public u8sVersion!: string;
	/** User */
	public u8sUser!: string;
	/** Password */
	public u8sPassword!: string;
	/** Capabilities */
	public capabilities!: AsnWebServiceCapabilitiesList;
}

// [PrintTSTypeDefCode] AsnWebServiceCapabilitiesChangedArgument
// [PrintTSSeqDefCode] AsnWebServiceCapabilitiesChangedArgument
export class AsnWebServiceCapabilitiesChangedArgument {
	public constructor(that?: AsnWebServiceCapabilitiesChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnWebServiceCapabilitiesChangedArgument {
		return new AsnWebServiceCapabilitiesChangedArgument();
	}

	public capabilities?: AsnWebServiceCapabilitiesList;
}

// [PrintTSTypeDefCode] AsnWebListenInterfaceStatusList
// [PrintTSSetOfDefCode] AsnWebListenInterfaceStatusList
export class AsnWebListenInterfaceStatusList extends Array<AsnWebListenInterfaceStatus> { }

// [PrintTSTypeDefCode] AsnWebServiceStatusArgument
// [PrintTSSeqDefCode] AsnWebServiceStatusArgument
export class AsnWebServiceStatusArgument {
	public constructor(that: AsnWebServiceStatusArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnWebServiceStatusArgument {
		return new AsnWebServiceStatusArgument({
			interfaceList: new AsnWebListenInterfaceStatusList()
		});
	}

	public interfaceList!: AsnWebListenInterfaceStatusList;
}

// [PrintTSTypeDefCode] AsnWebIceServerList
// [PrintTSSetOfDefCode] AsnWebIceServerList
export class AsnWebIceServerList extends Array<AsnWebIceServer> { }

// [PrintTSTypeDefCode] AsnWebGetIceServersResult
// [PrintTSSeqDefCode] AsnWebGetIceServersResult
export class AsnWebGetIceServersResult {
	public constructor(that: AsnWebGetIceServersResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnWebGetIceServersResult {
		return new AsnWebGetIceServersResult({
			iceServers: new AsnWebIceServerList(),
			stValidTo: new Date(),
			iTTL: 0
		});
	}

	public iceServers!: AsnWebIceServerList;
	/** GÃ¼ltig bis: AsnSystemTime */
	public stValidTo!: Date;
	/** TTL in Seconds */
	public iTTL!: number;
}

// [PrintTSTypeDefCode] AsnExternalServiceProperty
// [PrintTSSeqDefCode] AsnExternalServiceProperty
export class AsnExternalServiceProperty {
	public constructor(that: AsnExternalServiceProperty) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnExternalServiceProperty {
		return new AsnExternalServiceProperty({
			u8sName: "",
			u8sType: ""
		});
	}

	/** External service property name */
	public u8sName!: string;
	/** External service property type */
	public u8sType!: string;
	public listExternalServiceProperty?: AsnExternalServicePropertyList;
}

// [PrintTSTypeDefCode] AsnExternalServicePropertyList
// [PrintTSSetOfDefCode] AsnExternalServicePropertyList
export class AsnExternalServicePropertyList extends Array<AsnExternalServiceProperty> { }

// [PrintTSTypeDefCode] AsnRegisterExternalServiceArgument
// [PrintTSSeqDefCode] AsnRegisterExternalServiceArgument
export class AsnRegisterExternalServiceArgument {
	public constructor(that: AsnRegisterExternalServiceArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRegisterExternalServiceArgument {
		return new AsnRegisterExternalServiceArgument({
			iVersion: 0,
			u8sServiceType: "",
			u8sServiceId: "",
			u8sServiceUrl: "",
			u8sToken: ""
		});
	}

	/** Version of the protocol */
	public iVersion!: number;
	/** Service Type name e.g. FAQ, RANKING */
	public u8sServiceType!: string;
	/** UUID generated by the Service */
	public u8sServiceId!: string;
	/** Service URL Path */
	public u8sServiceUrl!: string;
	/** Service Token */
	public u8sToken!: string;
	public listExternalServiceProperty?: AsnExternalServicePropertyList;
}
