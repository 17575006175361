import { Box } from "@mui/material";
import React, { useRef, useState} from "react";

import { AsnAppointmentEntry } from "../../asn1/EUCSrv/stubs/ENetUC_Common_Appointments";
import { IContactContainer } from "../../interfaces/interfaces";
import { TimeoutOrNullT } from "../../interfaces/types";
import { IConfigCD } from "../../interfaces/interfacesConfig";
import { ICombiPic } from "../contactDetailsComponent/models/ModelCombinedContact";
import { ICustomerLabels } from "../contactDetailsComponent/models/ModelContentData";
import { PresenceTooltip } from "../presenceTooltip/PresenceTooltip";
import UserPresenceIcon from "../userPresenceIcon/UserPresenceIcon";

interface IProps {
	contact: IContactContainer | undefined;
	customLabels: ICustomerLabels;
	contactDetails: IConfigCD;
	combiPic: ICombiPic | undefined;
	currentAppointmentTime: string[];
	nextAppointmentTime: string;
	size?: number;
}
export const PresenceIconWithTooltip = (props: IProps): React.ReactElement => {
	const [showPresenceTooltipMenu, setShowPresenceTooltipMenu] = useState<boolean>(false);
	const showTooltipTimeout = useRef<TimeoutOrNullT>(null);

	const anchorEl = useRef<HTMLElement | null>(null);

	const appointmentEntry: AsnAppointmentEntry = {
		u8sSubject: props.contact?.nextAppointment?.u8sSubject || "",
		stStart: props.contact?.nextAppointment?.stStart || new Date(),
		stEnd: props.contact?.nextAppointment?.stEnd	|| new Date(),
		bAllDay: props.contact?.nextAppointment?.bAllDay ?? false,
		bIsValid: props.contact?.nextAppointment?.bIsValid ?? false,
		iDuration: 0,
		iImportance: 0,
		iSensitivity: 0,
		iStatus: 0,
		u8sCategory: "",
		u8sDataSource: "",
		u8sEntryID: "",
		u8sOwner: "",
		u8sText: ""
	};
	const tooltipContact: IContactContainer = {
		reachability: props.contact?.reachability,
		presence: props.contact?.presence,
		asnNetDatabaseContact: props.contact?.asnNetDatabaseContact,
		contactID: props.contact?.asnNetDatabaseContact?.u8sSIPAddress || "",
		nextAppointment: appointmentEntry,
		currentAppointments: props.contact?.currentAppointments,
		customNote: props.contact?.customNote,
		absentState: props.contact?.absentState,
		agentState: props.contact?.agentState,
		isMobileAvailable: props.contact?.isMobileAvailable,
		msTeamsEmail: props.contact?.msTeamsEmail,
		clientCapabilities: props.contact?.clientCapabilities,
		seqPhoneLines: props.contact?.seqPhoneLines,
		seqLineForwards: props.contact?.seqLineForwards,
		seqCalls: props.contact?.seqCalls
	};

	const mouseOverUserPresence = (): void => {
		if (showTooltipTimeout.current)
			clearTimeout(showTooltipTimeout.current);
		showTooltipTimeout.current = setTimeout(() => {
			setShowPresenceTooltipMenu(true);
		}, 500);
	};

	const mouseLeaveUserPresence = (): void => {
		if (showTooltipTimeout.current)
			clearTimeout(showTooltipTimeout.current);
		setShowPresenceTooltipMenu(false);
	};

	return (<>
		<Box
			onMouseOver={() => { mouseOverUserPresence(); }}
			onMouseLeave={() => { mouseLeaveUserPresence(); }}
		>
			<UserPresenceIcon
				pict={props.combiPic}
				pres={props.contact?.presence || 0}
				unknownPresence={props.contact?.presence === undefined}
				size={props.size}
			/>
		</Box>
		{showPresenceTooltipMenu
			? <PresenceTooltip ref={anchorEl}
				contact={tooltipContact}
				customLabels={props.customLabels.CustLabels}
				configCD={props.contactDetails.ContactDetails}
				nextAppointment={props.nextAppointmentTime}
				currentAppointment={props.currentAppointmentTime}
			/>
			: null}

		<span ref={anchorEl}/>
	</>
	);
};
