// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_Common_SIPCTI_Converter.ts
 * "UC-Server-Access-Protocol-SIPCTICommon" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";

// [PrintTSEncoderDecoderCode] AsnProjectPinRule
export class AsnProjectPinRule_Converter {
	public static toJSON(obj?: ENetUC_Common_SIPCTI.AsnProjectPinRule, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnProjectPinRule
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnProjectPinRule", newContext);
		value += TSConverter.addStringParam("u8sProjectName", obj.u8sProjectName, newContext);
		value += TSConverter.addStringParam("u8sProjectPin", obj.u8sProjectPin, newContext);
		value += TSConverter.addNumberParam("iProjectType", obj.iProjectType, newContext);
		value += TSConverter.addBooleanParam("bAssociatedCallIsPrivateCall", obj.bAssociatedCallIsPrivateCall, newContext);
		value += TSConverter.addStringParam("u8sLocationID", obj.u8sLocationID, newContext);
		value += TSConverter.addStringParam("u8sUserEnteredPin", obj.u8sUserEnteredPin, newContext);
		value += TSConverter.addStringParam("u8sUserEnteredUserID", obj.u8sUserEnteredUserID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common_SIPCTI.AsnProjectPinRule, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnProjectPinRule");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnProjectPinRule"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common_SIPCTI.AsnProjectPinRule;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnProjectPinRule
			if (TSConverter.validateParam(data, "u8sProjectName", "string", errors, newContext, false))
				obj.u8sProjectName = data.u8sProjectName;
			if (TSConverter.validateParam(data, "u8sProjectPin", "string", errors, newContext, false))
				obj.u8sProjectPin = data.u8sProjectPin;
			if (TSConverter.validateParam(data, "iProjectType", "number", errors, newContext, false))
				obj.iProjectType = data.iProjectType;
			if (TSConverter.validateParam(data, "bAssociatedCallIsPrivateCall", "boolean", errors, newContext, false))
				obj.bAssociatedCallIsPrivateCall = data.bAssociatedCallIsPrivateCall;
			if (TSConverter.validateParam(data, "u8sLocationID", "string", errors, newContext, false))
				obj.u8sLocationID = data.u8sLocationID;
			if (TSConverter.validateParam(data, "u8sUserEnteredPin", "string", errors, newContext, false))
				obj.u8sUserEnteredPin = data.u8sUserEnteredPin;
			if (TSConverter.validateParam(data, "u8sUserEnteredUserID", "string", errors, newContext, false))
				obj.u8sUserEnteredUserID = data.u8sUserEnteredUserID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnProjectPinRule"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnConfigSTUNandTURN
export class AsnConfigSTUNandTURN_Converter {
	public static toJSON(obj?: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURN, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnConfigSTUNandTURN
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnConfigSTUNandTURN", newContext);
		value += TSConverter.addNumberParam("iType", obj.iType, newContext);
		value += TSConverter.addStringParam("u8sUsername", obj.u8sUsername, newContext);
		value += TSConverter.addStringParam("u8sPassword", obj.u8sPassword, newContext);
		value += TSConverter.addDateParam("stValidTo", obj.stValidTo, newContext);
		value += TSConverter.addObjectAsStringParam("listURIs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.listURIs, newContext), newContext);
		value += TSConverter.addNumberParam("iTTL", obj.iTTL, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURN, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnConfigSTUNandTURN");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigSTUNandTURN"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common_SIPCTI.AsnConfigSTUNandTURN;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnConfigSTUNandTURN
			if (TSConverter.validateParam(data, "iType", "number", errors, newContext, false))
				obj.iType = data.iType;
			if (TSConverter.validateParam(data, "u8sUsername", "string", errors, newContext, false))
				obj.u8sUsername = data.u8sUsername;
			if (TSConverter.validateParam(data, "u8sPassword", "string", errors, newContext, false))
				obj.u8sPassword = data.u8sPassword;
			if (TSConverter.validateParam(data, "stValidTo", "string", errors, newContext, false))
				obj.stValidTo = new Date(data.stValidTo);
			const tscv_listuris = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.listURIs, tscv_listuris, errors, newContext, "listURIs", false))
				obj.listURIs = tscv_listuris;
			if (TSConverter.validateParam(data, "iTTL", "number", errors, newContext, false))
				obj.iTTL = data.iTTL;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigSTUNandTURN"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLineForward
export class AsnLineForward_Converter {
	public static toJSON(obj?: ENetUC_Common_SIPCTI.AsnLineForward, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnLineForward
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLineForward", newContext);
		value += TSConverter.addNumberParam("iInternalID", obj.iInternalID, newContext);
		value += TSConverter.addNumberParam("iForwardController", obj.iForwardController, newContext);
		value += TSConverter.addNumberParam("iForwardMode", obj.iForwardMode, newContext);
		value += TSConverter.addNumberParam("iBackEndSpecific", obj.iBackEndSpecific, newContext);
		value += TSConverter.addNumberParam("iNoAnswerTime", obj.iNoAnswerTime, newContext);
		value += TSConverter.addStringParam("u8sDestination", obj.u8sDestination, newContext);
		value += TSConverter.addObjectAsStringParam("u8sCallerIDList", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sCallerIDList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common_SIPCTI.AsnLineForward, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLineForward");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLineForward"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common_SIPCTI.AsnLineForward;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnLineForward
			if (TSConverter.validateParam(data, "iInternalID", "number", errors, newContext, false))
				obj.iInternalID = data.iInternalID;
			if (TSConverter.validateParam(data, "iForwardController", "number", errors, newContext, false))
				obj.iForwardController = data.iForwardController;
			if (TSConverter.validateParam(data, "iForwardMode", "number", errors, newContext, false))
				obj.iForwardMode = data.iForwardMode;
			if (TSConverter.validateParam(data, "iBackEndSpecific", "number", errors, newContext, false))
				obj.iBackEndSpecific = data.iBackEndSpecific;
			if (TSConverter.validateParam(data, "iNoAnswerTime", "number", errors, newContext, false))
				obj.iNoAnswerTime = data.iNoAnswerTime;
			if (TSConverter.validateParam(data, "u8sDestination", "string", errors, newContext, false))
				obj.u8sDestination = data.u8sDestination;
			const tscv_u8scalleridlist = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sCallerIDList, tscv_u8scalleridlist, errors, newContext, "u8sCallerIDList", false))
				obj.u8sCallerIDList = tscv_u8scalleridlist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLineForward"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnProjectPinRuleList
export class AsnProjectPinRuleList_Converter {
	public static toJSON(obj?: ENetUC_Common_SIPCTI.AsnProjectPinRuleList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnProjectPinRuleList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnProjectPinRule_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common_SIPCTI.AsnProjectPinRuleList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnProjectPinRuleList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnProjectPinRuleList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common_SIPCTI.AsnProjectPinRuleList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnProjectPinRuleList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common_SIPCTI.AsnProjectPinRule.initEmpty();
				if (AsnProjectPinRule_Converter.fromJSON(elem, entry, errors, newContext, "AsnProjectPinRule", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnProjectPinRuleList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCtiNewCallParams
export class AsnCtiNewCallParams_Converter {
	public static toJSON(obj?: ENetUC_Common_SIPCTI.AsnCtiNewCallParams, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCtiNewCallParams
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCtiNewCallParams", newContext);
		value += TSConverter.addObjectAsStringParam("projectPinRule", AsnProjectPinRule_Converter.toJSON(obj.projectPinRule, newContext), newContext);
		value += TSConverter.addBooleanParam("bHideMyCallerID", obj.bHideMyCallerID, newContext);
		value += TSConverter.addObjectAsStringParam("asnRemoteContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.asnRemoteContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common_SIPCTI.AsnCtiNewCallParams, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCtiNewCallParams");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNewCallParams"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common_SIPCTI.AsnCtiNewCallParams;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCtiNewCallParams
			const tscv_projectpinrule = ENetUC_Common_SIPCTI.AsnProjectPinRule.initEmpty();
			if (AsnProjectPinRule_Converter.fromJSON(data.projectPinRule, tscv_projectpinrule, errors, newContext, "projectPinRule", false))
				obj.projectPinRule = tscv_projectpinrule;
			if (TSConverter.validateParam(data, "bHideMyCallerID", "boolean", errors, newContext, false))
				obj.bHideMyCallerID = data.bHideMyCallerID;
			const tscv_asnremotecontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.asnRemoteContact, tscv_asnremotecontact, errors, newContext, "asnRemoteContact", true))
				obj.asnRemoteContact = tscv_asnremotecontact;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCtiNewCallParams"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnConfigSTUNandTURNList
export class AsnConfigSTUNandTURNList_Converter {
	public static toJSON(obj?: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnConfigSTUNandTURNList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnConfigSTUNandTURN_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnConfigSTUNandTURNList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigSTUNandTURNList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnConfigSTUNandTURNList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common_SIPCTI.AsnConfigSTUNandTURN.initEmpty();
				if (AsnConfigSTUNandTURN_Converter.fromJSON(elem, entry, errors, newContext, "AsnConfigSTUNandTURN", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigSTUNandTURNList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLineForwards
export class AsnLineForwards_Converter {
	public static toJSON(obj?: ENetUC_Common_SIPCTI.AsnLineForwards, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnLineForwards
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnLineForward_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common_SIPCTI.AsnLineForwards, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLineForwards");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLineForwards"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common_SIPCTI.AsnLineForwards;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnLineForwards
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common_SIPCTI.AsnLineForward.initEmpty();
				if (AsnLineForward_Converter.fromJSON(elem, entry, errors, newContext, "AsnLineForward", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLineForwards"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
