import jwt_decode from "jwt-decode";

import { EError, ILogCallback, ILogData } from "../../web-shared-components/helpers/logger/ILogger";
import { IBaseSingletons } from "../interfaces/IBaseSingletons";
import BaseSingleton from "../lib/BaseSingleton";
import { b64EncodeUnicode, generateGUID } from "../lib/common";
import { AsnGetUserTokenArgument } from "../ucserver/stubs/ENetUC_Auth";
import { AsnGetUserTokenArgument_Converter } from "../ucserver/stubs/ENetUC_Auth_Converter";
import { AsnRequestError } from "../ucserver/stubs/ENetUC_Common";
import { AsnTokenVerifyArgument } from "../ucserver/stubs/ENetUC_Transport";
import { AsnTokenVerifyArgument_Converter } from "../ucserver/stubs/ENetUC_Transport_Converter";

// Expire time request for the token 2 Weeks
const token_exp_time_sec = 2 * 14 * 24 * 60 * 60;
// Time the token is valid 4min (for the server it's valid for 5min)
const token_valid_time_sec = 4 * 60;

/**
 * Core properties of a user. Those are either part of the token or returned on a
 */
export interface IUserDetails {
	// User Login Name (userID) might change!
	userID: string;
	// the displayName of the user
	userDisplayName: string;
	// is allowed to create a room
	create_room: boolean;
	// is allowed to join a room
	join_room: boolean;
}

/**
 * Contents of the ucconnect token decoded but not validated as the client is not able to validate a token (does not have the keys to do so)
 */
export interface IDecodedUccToken extends IUserDetails {
	// UTC time when the token is expiring
	expires: Date;
	// Domain the user belongs to
	userDomain: string;
	// the ucsID (association id between clients and server through ucconnect)
	ucsID: string;
	// the GUID of the user
	userGUID: string;
	// String that stores a hash that changes every time the user password was changed
	password_version: string;
}

// Answer to a controller/client/ucserverversion?ucsid= request
export interface IUCServerVersion {
	ucserverversion: string | null;
	ucserverinterfaceversion: string | null;
	ucserverprotocolversion: string | null;
}

// Answer to a /controller/client/ucws?ucsid=XXXXXXXXXX&needswcs=1 request
interface IUCWebRequest {
	redirect: string | null;
}

// Answer to a /ws/direct/asnGetUserToken request
export interface IUCGetTokenRequest {
	sToken: string | null;
}

// Answer to a /ws/direct/asnTokenVerify request
interface IUCVerifyTokenRequest {
	stValidTo: string | null;
}

export interface IEstosTokenPayload {
	aud: string;
	exp: number;
	"http://estos.de/context": {
		anon: boolean;
		domain: string;
		meet_capabilities: string;
		pwdver: string;
		ucsid: string;
		user: string;
		user_display_name: string;
		user_guid: string;
	};
	iss: string;
}

export interface ITokenHeader {
	alg: string;
	typ: string;
}

/**
 *
 */
export class LoginController extends BaseSingleton implements ILogCallback {
	// Instance of this class to use as singleton.
	private static instance: LoginController;
	// The UC Connect Controller URL
	private ucControllerURL: string | undefined;
	// UCServer version set once received from UCController. It's public for easy access when we need to store the logs.
	public ucServerVersion?: IUCServerVersion;
	// UCServer URI
	private ucServerURI: string | undefined;

	/**
	 * Constructs LoginController.
	 * Method is private as we follow the Singleton Approach using getInstance
	 *
	 * @param attributes - the constructor attributes
	 */
	private constructor(attributes: IBaseSingletons) {
		super(attributes);
	}

	/**
	 * Gets instance of Config to use as singleton.
	 *
	 * @param attributes - the constructor attributes
	 * @returns - an instance of this class.
	 */
	public static getInstance(attributes: IBaseSingletons): LoginController {
		if (!LoginController.instance) {
			LoginController.instance = new LoginController(attributes);
		}
		return LoginController.instance;
	}

	/**
	 * The Loggers getLogData callback (used in all the log methods called in this class, add the classname to every log entry)
	 *
	 * @returns - an ILogData log data object provided additional data for all the logger calls in this class
	 */
	public getLogData(): ILogData {
		return {
			className: "LoginController"
		};
	}

	/**
	 * Set server URI
	 *
	 * @param uri - the uri
	 */
	public setServerURI(uri: string) {
		this.ucServerURI = uri;
	}

	/**
	 * Set UCController URL
	 *
	 * @param url - the url
	 */
	public setUCControllerURL(url: string) {
		this.ucControllerURL = url;
	}

	/**
	 * Returns a new (refreshed) token if older then 5min
	 *
	 * @param token - current token to refresh
	 * @param expiresAt - current time to expire
	 * @param ucsid - a string represents the ucsid of the UCServer the user is
	 * @returns - a new token
	 */
	public async refreshToken(
		token: string,
		expiresAt: Date,
		ucsid: string
	): Promise<IUCGetTokenRequest | undefined | AsnRequestError> {
		const now = new Date();
		const maxTokenExpireDate = new Date(expiresAt.getTime() - token_exp_time_sec * 1000 + token_valid_time_sec * 1000);
		if (now < maxTokenExpireDate) {
			return undefined;
		}

		return this.getUserToken(token, ucsid);
	}

	/**
	 * Does the fetch, logs the request and hands it over to the fetch method
	 *
	 * @param method - the original method that is calling the fetch
	 * @param url - the url to fetch
	 * @param init - an optional RequestInit object
	 * @returns - the body of the fetch result
	 */
	private async fetch(method: string, url: string, init?: RequestInit): Promise<unknown> {
		const response = await fetch(url, init);
		const result = response.json();
		/* if (response.status !== 200)
			this.logger.error("result", method, this, { response });
		else
			this.logger.debug("result", method, this, { result }); */
		return result;
	}

	/**
	 * Gets the user token
	 *
	 * username and password are only used to force a login while developing
	 * to be able to use a browser instead of the webview inside ProCall client
	 * otherwise, an existing token is used
	 *
	 * @param ucsid - a string containing the ucsid
	 * @param token - a string containing the current token
	 * @param username - username
	 * @param password - password
	 * @returns - a string containing the user token or an instance of Error
	 */
	public async getUserToken(
		ucsid: string,
		token?: string,
		username?: string,
		password?: string
	): Promise<IUCGetTokenRequest | AsnRequestError> {
		if (!this.ucControllerURL) {
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "You need to setup the uccontroller URL" });
		}
		try {
			const resultServer = await fetch(this.ucControllerURL + "/controller/client/ucserverversion?ucsid=" + ucsid);
			const ucserverVersionData = (await resultServer.json()) as unknown as IUCServerVersion;
			if (!ucserverVersionData.ucserverinterfaceversion) {
				this.logger.error("Invalid ucsid", "getUserToken", this, { ucserverVersionData }, EError.InvalidUCSID);
				return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucsid" });
			}
			this.ucServerVersion = ucserverVersionData;
			const version = parseInt(ucserverVersionData.ucserverinterfaceversion);
			if (version < 404) {
				this.logger.error(
					"UCServer interface version must be 404 or higher",
					"getUserToken",
					this,
					{ ucserverVersionData },
					EError.InvalidServerInterface
				);
				return new AsnRequestError({
					iErrorDetail: 0,
					u8sErrorString: "UCServer interface version must be 404 or higher"
				});
			}
			// store.dispatch(webAppInfoSetServerInterfaceVersion(version));
			const resultUC = await fetch(this.ucControllerURL + "/controller/client/ucws?ucsid=" + ucsid + "&needswcs=1");
			const ucwebUrlData = (await resultUC.json()) as unknown as IUCWebRequest;

			if (ucwebUrlData && ucwebUrlData.redirect) {
				// Request token with login capability with 2 weeks till expiration
				const request = new AsnGetUserTokenArgument({
					iType: 1,
					iTTL: 2 * 14 * 24 * 60 * 60
				});
				const body = AsnGetUserTokenArgument_Converter.toJSON(request);

				const headers: Record<string, string> = {
					"x-ucsid": ucsid,
					"Content-Type": "application/json"
				};

				if (token) {
					headers["x-uctoken"] = token;
				} else if (username) {
					// CC-1679 there's an issue in ucconnect with the character "€" when converted into base64
					const loginBase64 = b64EncodeUnicode(username + ":" + (password ? password : ""));
					headers["Authorization"] = "Basic " + loginBase64;
					headers["x-epid"] = generateGUID();
					headers["x-no401"] = "1";
				}

				// When the "€" base64 conversion issue is fixed in ucconnect we should revert it to this logic:
				/*
					if (username)
						headers["x-ucuser"] = username;
					if (password)
						headers["x-ucpass"] = password;
					if (token)
						headers["x-uctoken"] = token;
					 */

				const resultToken = await fetch(ucwebUrlData.redirect + "/ws/direct/asnGetUserToken", {
					method: "POST",
					headers,
					body
				});
				const tokenData = (await resultToken.json()) as unknown as IUCGetTokenRequest;

				if (tokenData && tokenData.sToken) {
					return tokenData;
				} else {
					this.logger.error(
						"Authentication failed",
						"getUserToken",
						this,
						{ tokenData },
						EError.AuthenticationErrorUCServer
					);
					return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Authentication failed" });
				}
			} else {
				this.logger.error("Invalid ucsid", "getUserToken", this, { ucwebUrlData }, EError.InvalidUCSID);
				return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucsid" });
			}
		} catch (err) {
			this.logger.error("Invalid ucsid", "getUserToken", this, { err }, EError.InvalidUCSID);
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucsid" });
		}
	}

	/**
	 * Gets the user token - logic for local ucserver
	 *
	 * username and password are only used to force a login while developing
	 * to be able to use a browser instead of the webview inside ProCall client
	 * otherwise, an existing token is used
	 *
	 * @param token - a string containing the current token
	 * @param username - username
	 * @param password - password
	 * @returns - a string containing the user token or an instance of Error
	 */
	public async getUserTokenLocal(
		token?: string,
		username?: string,
		password?: string
	): Promise<IUCGetTokenRequest | AsnRequestError> {
		if (!this.ucServerURI) {
			this.logger.error("You need to setup the UCServer URI", "getUserToken", this, {}, EError.InvalidUCServerURI);
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "You need to setup the UCServer URI" });
		}
		// Request token with login capability with 2 weeks till expiration
		const request = new AsnGetUserTokenArgument({
			iType: 1,
			iTTL: 2 * 14 * 24 * 60 * 60
		});
		const body = AsnGetUserTokenArgument_Converter.toJSON(request);

		const headers: Record<string, string> = {
			"Content-Type": "application/json"
		};

		if (token) {
			headers["x-uctoken"] = token;
		} else if (username) {
			// CC-1679 there's an issue in ucconnect with the character "€" when converted into base64
			const loginBase64 = b64EncodeUnicode(username + ":" + (password ? password : ""));
			headers["Authorization"] = "Basic " + loginBase64;
			headers["x-epid"] = generateGUID();
			headers["x-no401"] = "1";
		}

		try {
			const resultToken = await fetch(this.ucServerURI + "/ws/direct/asnGetUserToken", {
				method: "POST",
				headers,
				body
			});
			const tokenData = (await resultToken.json()) as unknown as IUCGetTokenRequest;

			if (tokenData && tokenData.sToken) {
				return tokenData;
			} else {
				this.logger.error(
					"Authentication failed",
					"getUserToken",
					this,
					{ tokenData },
					EError.AuthenticationErrorUCServer
				);
				return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Authentication failed" });
			}
		} catch (e) {
			this.logger.error("Authentication failed", "getUserToken", this, { e }, EError.AuthenticationErrorUCServer);
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Authentication failed" });
		}
	}

	/**
	 * Verifies the user token by calling the uccontroller.
	 *
	 * @param token - a string containing the token
	 * @param ucsid - a string containing the ucsid
	 * @returns - a boolean true if the token is still valid, an instance of Error if not
	 */
	public async verifyUserToken(token: string, ucsid: string): Promise<boolean | AsnRequestError> {
		if (!this.ucControllerURL) {
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "You need to setup the uccontroller URL" });
		}
		try {
			const result = await fetch(this.ucControllerURL + "/controller/client/ucws?ucsid=" + ucsid + "&needswcs=1");
			const ucwebUrlData = (await result.json()) as unknown as IUCWebRequest;
			if (ucwebUrlData && ucwebUrlData.redirect) {
				const request = new AsnTokenVerifyArgument({ sToken: token });
				const body = AsnTokenVerifyArgument_Converter.toJSON(request);
				const resultToken = await fetch(ucwebUrlData.redirect + "/ws/direct/asnTokenVerify", {
					method: "POST",
					headers: {
						"x-ucsid": ucsid,
						"Content-Type": "application/json"
					},
					body
				});
				const tokenValidData = (await resultToken.json()) as unknown as IUCVerifyTokenRequest;

				if (tokenValidData && tokenValidData.stValidTo) {
					const validTo = new Date(tokenValidData.stValidTo);
					return validTo > new Date();
				} else {
					return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid token" });
				}
			} else {
				return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucsid" });
			}
		} catch (err) {
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Invalid ucsid" });
		}
	}

	/**
	 * Gets the user details for the user with guid
	 *
	 * @param guid - the guid where we want to get licensing details for
	 * @param ucsid - a string containing the ucsid
	 * @returns - returns an IUserDetails object or an AsnRequestError on error
	 */
	public async getUserDetails(guid: string, ucsid: string): Promise<IUserDetails | AsnRequestError> {
		try {
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Function is currently not implemented" });
		} catch (err) {
			return new AsnRequestError({ iErrorDetail: 0, u8sErrorString: "Function is currently not implemented" });
		}
	}

	/**
	 * Decodes the token and returns the payload in a IDecodedUccToken object
	 *
	 * @param token - the encoded jwToken to decode
	 * @returns - the decoded token data as IDecodedUccToken if all properties are available or undefined if a property is missing
	 */
	public decodeToken(token: string | undefined): IDecodedUccToken | undefined {
		if (!token) {
			return undefined;
		}
		try {
			const decodedHeader = jwt_decode(token, { header: true }) as ITokenHeader;
			// this.logger.debug("result", "decodeToken", this, { decodedHeader });
			if (!decodedHeader) {
				return undefined;
			}
			if (!decodedHeader.typ || !decodedHeader.alg) {
				return undefined;
			}
			if (decodedHeader.typ !== "JWT") {
				return undefined;
			}
			if (decodedHeader.alg !== "RS512") {
				return undefined;
			}
		} catch (e) {
			return undefined;
		}

		try {
			const decoded = jwt_decode(token) as IEstosTokenPayload;
			// this.logger.debug("result", "decodeToken", this, { decoded });
			if (!decoded) {
				return undefined;
			}
			if (!decoded.exp) {
				return undefined;
			}
			const details = decoded["http://estos.de/context"];
			if (
				details &&
				details.domain !== undefined &&
				details.pwdver !== undefined &&
				details.ucsid !== undefined &&
				details.user !== undefined &&
				details.user_guid !== undefined &&
				details.user_display_name !== undefined &&
				details.meet_capabilities !== undefined
			) {
				// Decode the caps
				let create_room = false;
				let join_room = false;
				if (typeof details.meet_capabilities === "string") {
					const caps = details.meet_capabilities.split(",");
					for (const cap of caps) {
						if (cap === "create") {
							create_room = true;
						} else if (cap === "join") {
							join_room = true;
						}
					}
				}
				return {
					expires: new Date(decoded.exp * 1000),
					userDomain: details.domain,
					userID: details.user,
					ucsID: details.ucsid,
					userDisplayName: details.user_display_name,
					userGUID: details.user_guid,
					create_room,
					join_room,
					password_version: details.pwdver
				};
			}
			return undefined;
		} catch (e) {
			return undefined;
		}
	}
}
