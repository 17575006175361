// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_AV.ts
 * "UC-Server-Access-Protocol-AV" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * Audio\/Video interface.
 * ## Audio \/ Video Conversation Modul
 *
 * @startuml
 * \"ProCall A\" -&gt; \"UCServer\"                : asnAVMakeCall(offer) F
 * \"UCServer\" -&gt; \"ProCall B\" : asnNewCall(offer) E
 * \"UCServer\" &lt;- \"ProCall B\" : asnAVMessage(panswer, candidate) E
 * \"ProCall A\" &lt;- \"UCServer\"                : asnAVMessage(panswer, candidate) E
 * \"ProCall A\"       &lt;-&gt;        \"ProCall B\" : ICE - Exchange
 * \"UCServer\" &lt;- \"ProCall B\" : asnAVAlert(panswer) E
 * \"ProCall A\" &lt;- \"UCServer\"                : asnAVAlert(panswer) E
 * \"ProCall A\"       &lt;-&gt;        \"ProCall B\" : RTP - Media transport (pre-connect)
 * \"UCServer\" &lt;- \"ProCall B\" : asnAVConnect(answer) E
 * \"ProCall A\" &lt;- \"UCServer\"                : asnAVConnect(answer) E
 * \"ProCall A\" -&gt; \"UCServer\"                : asnAVCallStateChanged(parties) E
 * \"UCServer\" -&gt; \"ProCall B\" : asnAVCallStateChanged(parties) E
 * \"ProCall A\"       &lt;-&gt;        \"ProCall B\" : RTP - Media Exchange (call)
 * \"ProCall A\" -&gt; \"UCServer\"                :
 * \"UCServer\" -&gt; \"ProCall B\" : asnAVDropCall() E
 * \"UCServer\" &lt;- \"ProCall B\" :
 * \"ProCall A\" &lt;- \"UCServer\"                : asnAVDropCall() E
 * @enduml
 * ## Moduledescription
 * This module defines structures and operations related to audio\/video communication controlling.
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";

// [PrintTSTypeDefCode] AsnAVMakeCallErrorEnum
// [PrintTSEnumDefCode] AsnAVMakeCallErrorEnum
export enum AsnAVMakeCallErrorEnum {
	general = 100
}

// [PrintTSTypeDefCode] EnumCodecType
// [PrintTSEnumDefCode] EnumCodecType
export enum EnumCodecType {
	ePCMU = 0,
	ePCMA = 8,
	eG722 = 9,
	eG729 = 18,
	eOPUS = 96,
	eTELEPHONE_EVENT = 101,
	eG726_16 = 110,
	eG726_24 = 111,
	eG726_32 = 112,
	eG726_40 = 113,
	eVP8 = 200,
	eH264 = 264
}

// [PrintTSTypeDefCode] AsnAVMediaType
// [PrintTSEnumDefCode] AsnAVMediaType
export enum AsnAVMediaType {
	eMEDIATYPEAUDIOVIDEO = 0,
	eMEDIATYPEAUDIO = 1,
	eMEDIATYPEDESKTOPSHARING = 2,
	eMEDIATYPEDESTKOPREQUESTSHARING = 3,
	eMEDIATYPESIP = 65535
}

// [PrintTSTypeDefCode] AsnAVCallAndConnectionID
// [PrintTSSeqDefCode] AsnAVCallAndConnectionID
/**
 * ConnectionID with CallID and physical endpoint (URI \/ EndPointID)
 * -&gt; wird benutzt wenn man keine LogEndpointID hat oder wenn alle Endpunkte einer Verbindung (LogEndpointIDs) an einer speziellen EndpointID erreicht werden sollen
 *
 * @private
 */
export class AsnAVCallAndConnectionID {
	public constructor(that: AsnAVCallAndConnectionID) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVCallAndConnectionID {
		return new AsnAVCallAndConnectionID({
			u8sCallID: "",
			u8sEndpointID: ""
		});
	}

	public u8sCallID!: string;
	public u8sEndpointID!: string;
}

// [PrintTSTypeDefCode] AsnAVMakeCallArgument
// [PrintTSSeqDefCode] AsnAVMakeCallArgument
/**
 * **********************************************************
 * ********************** AVMakeCall ************************
 * **********************************************************
 * * Initiate an AV Connection
 *
 * @private
 */
export class AsnAVMakeCallArgument {
	public constructor(that: AsnAVMakeCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVMakeCallArgument {
		return new AsnAVMakeCallArgument({
			callType: 0,
			u8sUriFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	/** EDataStructuresAVMessages.h */
	public callType!: number;
	/** Eigene SIP-IdentitÃ¤t - wenn nicht definiert oder falsch dann unkorrekte Presenceanzeigen vom Server */
	public u8sUriFrom!: string;
	/** SIP-IdentitÃ¤t des Angerufenen (EmpfÃ¤nger) */
	public u8sUriTo!: string;
	/** derzeit L\"application\/sdp\" */
	public u8sContentType!: string;
	/** Session Description Protocol Daten (SDP Offer) */
	public u8sBody!: string;
	/** ffs */
	public newCallParams?: ENetUC_Common_SIPCTI.AsnCtiNewCallParams;
	public u8sAssociatedTextChatConversationID?: string;
	/** capability strings: HOLD (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAVCallParty
// [PrintTSSeqDefCode] AsnAVCallParty
/**
 * **********************************************************
 * **************** asnAVCallStateChanged *******************
 * **********************************************************
 * * signal call state changes
 *
 * @private
 */
export class AsnAVCallParty {
	public constructor(that: AsnAVCallParty) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVCallParty {
		return new AsnAVCallParty({
			endpointID: "",
			endPointState: 0
		});
	}

	/** endpointID of the party */
	public endpointID!: string;
	/** ffs */
	public endPointState!: number;
}

// [PrintTSTypeDefCode] AsnAVCallMessageICE
// [PrintTSSeqDefCode] AsnAVCallMessageICE
/**
 * **********************************************************
 * ******************* asnAVCallMessage *********************
 * **********************************************************
 *
 * @private
 */
export class AsnAVCallMessageICE {
	public constructor(that: AsnAVCallMessageICE) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVCallMessageICE {
		return new AsnAVCallMessageICE({
			candidate: "",
			sdpMid: "",
			sdpMLineIndex: 0
		});
	}

	public candidate!: string;
	public sdpMid!: string;
	public sdpMLineIndex!: number;
}

// [PrintTSTypeDefCode] AsnAVConnectArgument
// [PrintTSSeqDefCode] AsnAVConnectArgument
/**
 * **********************************************************
 * *********************** asnAVConnect *********************
 * **********************************************************
 * * Connect\/accept an AV Connection and drops forked users
 *
 * @private
 */
export class AsnAVConnectArgument {
	public constructor(that: AsnAVConnectArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVConnectArgument {
		return new AsnAVConnectArgument({
			u8sCallID: "",
			endpointIDTo: "",
			connectedEndPointID: "",
			callType: 0,
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public connectedEndPointID!: string;
	/** EDataStructuresAVMessages.h */
	public callType!: number;
	/** Uri des Message Empfaengers - UriFrom sollte dem Message Empfaenger schon bekannt sein */
	public u8sUriTo!: string;
	/** derzeit L\"application\/sdp\" */
	public u8sContentType!: string;
	/** Session Description Protocol Daten (SDP Answer) */
	public u8sBody!: string;
	/** capability strings: HOLD, EBTRANS, BTRANS , CTRANS , CANNOT_REJECT (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAVAlertArgument
// [PrintTSSeqDefCode] AsnAVAlertArgument
/**
 * ***********************************************************
 * ************************* asnAVAlert **********************
 * ***********************************************************
 * * Indicate a remote ringing device, may enable early media
 * -&gt; sofort nach Erhalt einen neuen Rufes sollte Alert oder Drop geschickt werden
 * -&gt; fuer Early media koennte im Alert ein SDP mitgeschickt werden -&gt; derzeit schicken wirs im Connect
 *
 * @private
 */
export class AsnAVAlertArgument {
	public constructor(that: AsnAVAlertArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVAlertArgument {
		return new AsnAVAlertArgument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public endpointIDFrom!: string;
	/** Uri des Message Empfaengers - UriFrom sollte dem Message Empfaenger schon bekannt sein */
	public u8sUriTo!: string;
	/** derzeit empty String: L\"\" - moeglich: L\"application\/sdp\" */
	public u8sContentType!: string;
	/** derzeit empty String: L\"\" - moeglich: Session Description Protocol Daten (SDP Answer) */
	public u8sBody!: string;
	/** capability strings: HOLD (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnRequestSTUNandTURNArgument
// [PrintTSSeqDefCode] AsnRequestSTUNandTURNArgument
export class AsnRequestSTUNandTURNArgument {
	public constructor(that?: AsnRequestSTUNandTURNArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestSTUNandTURNArgument {
		return new AsnRequestSTUNandTURNArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetSTUNandTURNArgument
// [PrintTSSeqDefCode] AsnGetSTUNandTURNArgument
export class AsnGetSTUNandTURNArgument {
	public constructor(that?: AsnGetSTUNandTURNArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetSTUNandTURNArgument {
		return new AsnGetSTUNandTURNArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetSTUNandTURNResult
// [PrintTSSeqDefCode] AsnGetSTUNandTURNResult
export class AsnGetSTUNandTURNResult {
	public constructor(that: AsnGetSTUNandTURNResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetSTUNandTURNResult {
		return new AsnGetSTUNandTURNResult({
			listConfigSTUNandTURN: new ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList()
		});
	}

	public listConfigSTUNandTURN!: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSTUNandTURNConfigChangedArgument
// [PrintTSSeqDefCode] AsnSTUNandTURNConfigChangedArgument
export class AsnSTUNandTURNConfigChangedArgument {
	public constructor(that: AsnSTUNandTURNConfigChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSTUNandTURNConfigChangedArgument {
		return new AsnSTUNandTURNConfigChangedArgument({
			listConfigSTUNandTURN: new ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList()
		});
	}

	public listConfigSTUNandTURN!: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnIceServer
// [PrintTSSeqDefCode] AsnIceServer
/**
 * WebRtc Browser kompatible iceServers
 *
 * @private
 */
export class AsnIceServer {
	public constructor(that: AsnIceServer) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnIceServer {
		return new AsnIceServer({
			urls: new ENetUC_Common.UTF8StringList()
		});
	}

	/** turn username to use */
	public username?: string;
	/** turn password to use */
	public credential?: string;
	/** uris */
	public urls!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode] AsnAVGetIceServersArgument
// [PrintTSSeqDefCode] AsnAVGetIceServersArgument
export class AsnAVGetIceServersArgument {
	public constructor(that?: AsnAVGetIceServersArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVGetIceServersArgument {
		return new AsnAVGetIceServersArgument();
	}
}

// [PrintTSTypeDefCode] AsnConfigCodec
// [PrintTSSeqDefCode] AsnConfigCodec
export class AsnConfigCodec {
	public constructor(that: AsnConfigCodec) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnConfigCodec {
		return new AsnConfigCodec({
			iType: 0
		});
	}

	/** Codec type */
	public iType!: number;
}

// [PrintTSTypeDefCode] AsnAVMediaChangeArgument
// [PrintTSSeqDefCode] AsnAVMediaChangeArgument
/**
 * **********************************************************
 * *** asnAVMedia to inform server about media changes in ***
 * *** existing call (currently to enable journalling of  ***
 * *** media switching)                                   ***
 * **********************************************************
 * * Connect\/accept an AV Connection and drops forked users
 *
 * @private
 */
export class AsnAVMediaChangeArgument {
	public constructor(that: AsnAVMediaChangeArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVMediaChangeArgument {
		return new AsnAVMediaChangeArgument({
			u8sCallID: "",
			endpointIDTo: "",
			connectedEndPointID: "",
			iMediaType: 0,
			u8sUriFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: new Uint8Array(0),
			u8sMediaID: "",
			iAvFlags: 0,
			iAvDropReason: 0
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers, hier des Peers (MediaChange messages werden im server terminiert) */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public connectedEndPointID!: string;
	/** Media this message relates to */
	public iMediaType!: number;
	/** Uri des Message Sendenden */
	public u8sUriFrom!: string;
	/** Uri des Message Empfaengers */
	public u8sUriTo!: string;
	/** derzeit L\"application\/xx\" */
	public u8sContentType!: string;
	/** Session Description Protocol Daten (SDP Answer) */
	public u8sBody!: Uint8Array;
	/** ID of the different Media Requests is used as the Connection ID in the journal -&gt; generated by the client when a new media is addedbr \/&gt;Note: Tracker Id of the original call is as well the Connection ID */
	public u8sMediaID!: string;
	/** Media related flags, currently only IS_ORIGINATOR 0x0001L */
	public iAvFlags!: number;
	/** EAVCause::dropCause */
	public iAvDropReason!: number;
}

// [PrintTSTypeDefCode] AsnAVTransferArgument
// [PrintTSSeqDefCode] AsnAVTransferArgument
/**
 * **********************************************************
 * ********* asnAVTransferGetID\/asnAVTransferStart **********
 * **********************************************************
 * * transfer message argument
 *
 * @private
 */
export class AsnAVTransferArgument {
	public constructor(that: AsnAVTransferArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVTransferArgument {
		return new AsnAVTransferArgument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			iTransferResult: 0,
			u8sUriTransferTo: "",
			u8sUriTransferFrom: ""
		});
	}

	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Senders */
	public endpointIDFrom!: string;
	/** for result Messages, SIP status, e.g.: '202' accepted, '100' trying, '200' OK */
	public iTransferResult!: number;
	/** Destination of transfer */
	public u8sUriTransferTo!: string;
	/** Transfering Party */
	public u8sUriTransferFrom!: string;
	/** we ask the Consultation call (C-partner) for an ID to identify it in the transfer request to the A-partner */
	public u8sTransferTargetID?: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAVCallOrConnection
// [PrintTSChoiceDefCode] AsnAVCallOrConnection
export class AsnAVCallOrConnection {
	public constructor(that?: AsnAVCallOrConnection) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVCallOrConnection {
		return new AsnAVCallOrConnection();
	}

	public u8sCallID?: string = undefined;
	public connectionID?: AsnAVCallAndConnectionID = undefined;
}

// [PrintTSTypeDefCode] AsnAVMakeCallResult
// [PrintTSSeqDefCode] AsnAVMakeCallResult
export class AsnAVMakeCallResult {
	public constructor(that: AsnAVMakeCallResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVMakeCallResult {
		return new AsnAVMakeCallResult({
			callAndConnectionID: AsnAVCallAndConnectionID.initEmpty(),
			u8sCTIConnectionID: ""
		});
	}

	public callAndConnectionID!: AsnAVCallAndConnectionID;
	/** CID des erzeugten EVirtualSIPAVCall-Objekts im Line-Call-Model. Nach RÃ¼cksprache mit RB leider nicht mit der AV-ConnectionID vereinbar. */
	public u8sCTIConnectionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAVNewCallArgument
// [PrintTSSeqDefCode] AsnAVNewCallArgument
/**
 * **********************************************************
 * ********************* asnAVNewCall ***********************
 * **********************************************************
 * * Invoked (Event) signalling a new (incoming) call
 *
 * @private
 */
export class AsnAVNewCallArgument {
	public constructor(that: AsnAVNewCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVNewCallArgument {
		return new AsnAVNewCallArgument({
			callAndConnectionID: AsnAVCallAndConnectionID.initEmpty(),
			u8sCTIConnectionID: "",
			callType: 0,
			u8sUriFrom: "",
			endpointIDFrom: "",
			u8sUriTo: "",
			u8sContentType: "",
			u8sBody: ""
		});
	}

	/** IDs fuer den Angerufenen (called party) */
	public callAndConnectionID!: AsnAVCallAndConnectionID;
	/** CID des erzeugten EVirtualSIPAVCall-Objekts im Line-Call-Model. Nach RÃ¼cksprache mit RB leider nicht mit der AV-ConnectionID vereinbar. */
	public u8sCTIConnectionID!: string;
	/** Media Type */
	public callType!: number;
	/** SIP-IdentitÃ¤t des Anrufenden */
	public u8sUriFrom!: string;
	/** EndpointIDs des Anrufenden (calling party) */
	public endpointIDFrom!: string;
	/** SIP-IdentitÃ¤t des Angerufenen */
	public u8sUriTo!: string;
	/** siehe AsnAVMakeCallArgument */
	public u8sContentType!: string;
	/** siehe AsnAVMakeCallArgument */
	public u8sBody!: string;
	/** siehe AsnAVMakeCallArgument */
	public newCallParams?: ENetUC_Common_SIPCTI.AsnCtiNewCallParams;
	public u8sAssociatedTextChatConversationID?: string;
	/** capability strings: HOLD, EBTRANS, BTRANS , CTRANS , CANNOT_REJECT (details see EDataStructuresAVMessages.h) */
	public u8sCaps?: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAVCallPartyList
// [PrintTSSetOfDefCode] AsnAVCallPartyList
export class AsnAVCallPartyList extends Array<AsnAVCallParty> { }

// [PrintTSTypeDefCode] AsnAVCallStateChangedArgument
// [PrintTSSeqDefCode] AsnAVCallStateChangedArgument
export class AsnAVCallStateChangedArgument {
	public constructor(that: AsnAVCallStateChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVCallStateChangedArgument {
		return new AsnAVCallStateChangedArgument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			callState: 0,
			u8sUriFrom: "",
			u8sUriTo: "",
			parties: new AsnAVCallPartyList()
		});
	}

	/** Die CallID identifiziert den Call */
	public u8sCallID!: string;
	/** ID des Message Empfaengers */
	public endpointIDTo!: string;
	/** ID des Message Sendenden */
	public endpointIDFrom!: string;
	/** EDataStructuresAVMessages.h */
	public callState!: number;
	/** Uri des Message Sendenden */
	public u8sUriFrom!: string;
	/** Uri des Message Empfaengers */
	public u8sUriTo!: string;
	/** ffs - wird derzeit als empty Sequence geschickt -&gt; EAVCallPartyList als empty list */
	public parties!: AsnAVCallPartyList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAVCallMessageArgument
// [PrintTSSeqDefCode] AsnAVCallMessageArgument
/**
 * * send end to end messages
 *
 * @private
 */
export class AsnAVCallMessageArgument {
	public constructor(that: AsnAVCallMessageArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVCallMessageArgument {
		return new AsnAVCallMessageArgument({
			u8sCallID: "",
			endpointIDTo: "",
			endpointIDFrom: "",
			messageType: 0,
			u8sUriTo: ""
		});
	}

	public u8sCallID!: string;
	public endpointIDTo!: string;
	public endpointIDFrom!: string;
	/** MessageTypes&lt;br \/&gt; */
	public messageType!: number;
	/** Uri des Message Empfaengers - UriFrom sollte dem Message Empfaenger schon bekannt sein */
	public u8sUriTo!: string;
	/** derzeit L\"application\/camcontrol\" oder L\"application\/mediastate\" */
	public u8sContentType?: string;
	/** EAVCallMessageArgument::STR_... */
	public u8sBody?: string;
	public iceCandidate?: AsnAVCallMessageICE;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAVDropCallArgument
// [PrintTSSeqDefCode] AsnAVDropCallArgument
/**
 * **********************************************************
 * *********************** AVDropCall ***********************
 * **********************************************************
 * * Drop an AV Connection
 * to drop\/reject an incoming call it is recommended to use iAvDropReason BUSY (1) or REJECT (16)
 * to hang up an active call it is recommended to use iAvDropReason NORMAL_CALL_CLEARING (0)
 * to hang up an outgoing call pending (e.g. ringback) it is recommended to use iAvDropReason CANCEL (2) or NORMAL_CALL_CLEARING (0)
 * further cause definitions in: http:\/\/svnserver:8080\/svn\/devprocall\/trunk\/estos\/common\/libs\/enetctilib\/include\/EAVCause.h (and the EAVCause.cpp file)
 *
 * @private
 */
export class AsnAVDropCallArgument {
	public constructor(that: AsnAVDropCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVDropCallArgument {
		return new AsnAVDropCallArgument({
			dropTarget: AsnAVCallOrConnection.initEmpty(),
			u8sCause: "",
			iAvDropReason: 0
		});
	}

	/** will man alle Endpoints ausloesen muss man die CallID schicken (ohne  connectionID)&lt;br \/&gt;will man alle LogEndpoints eines Endpoints ausloesen muss man die EndpointID schicken */
	public dropTarget!: AsnAVCallOrConnection;
	/** g_EAVCause.GetASNCauseStrFromEAVCause(iAvDropReason) sollte benutzt werden - String zur besseren Lesbarkeit im Trace */
	public u8sCause!: string;
	/** EAVCause::dropCause */
	public iAvDropReason!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnIceServerList
// [PrintTSSetOfDefCode] AsnIceServerList
export class AsnIceServerList extends Array<AsnIceServer> { }

// [PrintTSTypeDefCode] AsnAVGetIceServersResult
// [PrintTSSeqDefCode] AsnAVGetIceServersResult
export class AsnAVGetIceServersResult {
	public constructor(that: AsnAVGetIceServersResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAVGetIceServersResult {
		return new AsnAVGetIceServersResult({
			iceServers: new AsnIceServerList()
		});
	}

	public iceServers!: AsnIceServerList;
}

// [PrintTSTypeDefCode] AsnConfigCodecList
// [PrintTSSetOfDefCode] AsnConfigCodecList
export class AsnConfigCodecList extends Array<AsnConfigCodec> { }

// [PrintTSTypeDefCode] AsnConfigPorts
// [PrintTSSeqDefCode] AsnConfigPorts
export class AsnConfigPorts {
	public constructor(that: AsnConfigPorts) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnConfigPorts {
		return new AsnConfigPorts({
			minPort: 0,
			maxPort: 0
		});
	}

	/** minimim config port */
	public minPort!: number;
	/** maximum config port */
	public maxPort!: number;
}
