// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_Journal.ts
 * "UC-Server-Access-Protocol-Journal" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * Journal interface
 * ## Module description
 * This Module defines structures and operations for accessing the journal database of the UCServer.
 *
 * ## Features
 * - Get journal entries from the database (asnGetJournalEntries).
 * - Update journal entries in the database.
 * - Notify CRUD interface for getting events from the journal database.
 *
 * New journal entries where only generated by the UCServer if the corresponding events happend (Calls, Chats etc.) and cannot be generated by this client API.
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";

// [PrintTSTypeDefCode] AsnNetDatabaseJournalCallType
// [PrintTSEnumDefCode] AsnNetDatabaseJournalCallType
export enum AsnNetDatabaseJournalCallType {
	/** Calls on PBX lines (TAPI) */
	eJOURNALCALLTYPEPBX = 0,
	/** Video Chat (WebRTC based) (only Video, no Audio; e.g. desktop sharing session) */
	eJOURNALCALLTYPEVIDEOCHAT = 1,
	/** Audio Chat (WebRTC based) (only Audio, no Video) */
	eJOURNALCALLTYPEAUDIOCHAT = 2,
	/** Video Chat (WebRTC based) (Audio and Video) */
	eJOURNALCALLTYPEAUDIOVIDEOCHAT = 3,
	/** Text Chat */
	eJOURNALCALLTYPEIM = 4,
	/** Bluetooth mobile phone call */
	eJOURNALCALLTYPEBLUETOOTHMOBILE = 5,
	/** Bluetooth mobile phone - Call duration of the connected call is (1 sec, offline Journal) */
	eJOURNALCALLTYPEBLUETOOTHMOBILEUNKNOWNDURATION = 6,
	/** Audio chat via SIP connection to PBX. Looks like a normal PBX call, but is technically a SIP softphone call via PBX using WebRTC. */
	eJOURNALCALLTYPESIPAVCALL = 7,
	/** SMS */
	eJOURNALCALLTYPESMS = 8,
	/** Screen\/Desktop sharing (WebRTC based, invite := outgoing) */
	eJOURNALCALLTYPESCREENSHARING = 9,
	/** Screen\/Desktop sharing (WebRTC based, request := incoming) */
	eJOURNALCALLTYPERSCREENSHARING = 10
}

// [PrintTSTypeDefCode] AsnNetDatabaseJournalFilterFlags
// [PrintTSBitstringDefCode] AsnNetDatabaseJournalFilterFlags
export class AsnNetDatabaseJournalFilterFlags {
	public static flagIntern: number = 1;
	public static flagExtern: number = 2;
	public static flagInbound: number = 4;
	public static flagOutbound: number = 8;
	public static flagConnected: number = 16;
	public static flagNotConnected: number = 32;
	public static flagHasMemo: number = 64;
	public static flagHasNoMemo: number = 128;
	public static flagUnreadOnly: number = 256;
	public static flagIncludeAllPublic: number = 1;
};



// [PrintTSTypeDefCode] AsnNetDatabaseJournalFilterEnumeration
// [PrintTSEnumDefCode] AsnNetDatabaseJournalFilterEnumeration
export enum AsnNetDatabaseJournalFilterEnumeration {
	/** 0x00000001 -\tInternal calls */
	eFilterFlagIntern = 1,
	/** 0x00000002 -\tExternal calls */
	eFilterFlagExtern = 2,
	/** 0x00000004 -\tInbound calls */
	eFilterFlagInbound = 4,
	/** 0x00000008 -\tOutbound calls */
	eFilterFlagOutbound = 8,
	/** 0x00000010 -\tConnected calls */
	eFilterFlagConnected = 16,
	/** 0x00000020 -\tNot connected calls */
	eFilterFlagNotConnected = 32,
	/** 0x00000040 -\tThe memo field contains text */
	eFilterFlagHasMemo = 64,
	/** 0x00000080 -\tThe memo field is empty */
	eFilterFlagHasNoMemo = 128,
	/** 0x00000100 -\tOnly entries, which are not marked as \"read\" */
	eFilterFlagUnreadOnly = 256,
	/** 0x00000200 -\tExclude all calls without a caller number (clipped numbers) */
	eFilterFlagNoUnknown = 512,
	/** 0x00000400 -\tInclude text chats\t(default: TBD) */
	eFilterFlagTextChats = 1024,
	/** 0x00000800 -\tInclude audio chats (default: TBD) */
	eFilterFlagAudioChats = 2048,
	/** 0x00001000 -\tInclude video chats (default: TBD) */
	eFilterFlagVideoChats = 4096,
	/** 0x00002000 -\tInclude phone calls (default: set) */
	eFilterFlagPhoneCalls = 8192,
	/** 0x00004000 -\tInclude screen-\/deskshare chats */
	eFilterFlagDeskShare = 16384,
	/** 0x00010000 -\tInclude all entries, which are marked as \"public\" and the user has access rights on. */
	eFilterFlagIncludeAllPublic = 65536
}

// [PrintTSTypeDefCode] AsnJournalSubscribeFlags
// [PrintTSEnumDefCode] AsnJournalSubscribeFlags
export enum AsnJournalSubscribeFlags {
	/** Liefert nur die eigenen JournaleintrÃ¤ge */
	eDEFAULT = 0,
	/** Liefert auch die GruppenjournaleintrÃ¤ge */
	eGROUPJOURNAL = 1
}

// [PrintTSTypeDefCode] AsnJournalPrivateCallOptions
// [PrintTSEnumDefCode] AsnJournalPrivateCallOptions
export enum AsnJournalPrivateCallOptions {
	/** Private GesprÃ¤che nicht gesondert behandeln */
	eNORMAL = 0,
	/** Private GesprÃ¤che mit verkÃ¼rzter Rufnummer speichern */
	eSHORTENNUMBERS = 1,
	/** Private GesprÃ¤che ohne Rufnummer speichern */
	eNONUMBERS = 2
}

// [PrintTSTypeDefCode] AsnGetOfflineJournalArgument
// [PrintTSSeqDefCode] AsnGetOfflineJournalArgument
/**
 * Argument for asnGetOfflineJournal.
 *
 * @private
 */
export class AsnGetOfflineJournalArgument {
	public constructor(that: AsnGetOfflineJournalArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetOfflineJournalArgument {
		return new AsnGetOfflineJournalArgument({
			iMaxDays: 0,
			iMarkRead: 0
		});
	}

	/** Maximum Days to get offline journal entries. (0 = 365 days) */
	public iMaxDays!: number;
	/** 0: Don't change the entries; 1: Mark the offline entries as \"read\", which means delete the entry from the offline database. The corresponding entry in the journal database will not be touched. */
	public iMarkRead!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnNetDatabaseJournal
// [PrintTSSeqDefCode] AsnNetDatabaseJournal
/**
 * The structure of a journal entry of conversations on the UCServer.
 * This sequence describes a call or other conversation when it is stored in the history database called \"journal\".
 *
 * With this structure it it possible to see when an conversation or event has happend and other meta information about it.
 * The API provides operations to alter or add information of that entry.
 * New entries can only be generated by the UCServer.
 *
 * @private
 */
export class AsnNetDatabaseJournal {
	public constructor(that: AsnNetDatabaseJournal) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNetDatabaseJournal {
		return new AsnNetDatabaseJournal({
			u8sConnectionID: "",
			stStartTime: new Date(),
			stStopTime: new Date(),
			iDurationTotal: 0,
			stConnectTime: new Date(),
			stDisconnectTime: new Date(),
			iDurationConnected: 0,
			iOrigin: 0,
			iInternal: 0,
			u8sLineNumber: "",
			u8sLineName: "",
			u8sLineUserName: "",
			iLineUserOffline: 0,
			u8sLocalNumber: "",
			u8sPhoneNumber: "",
			u8sRedirectingNumber: "",
			u8sContactName: "",
			u8sContactCompany: "",
			u8sContactEntryID: "",
			u8sContactEntryStoreID: "",
			u8sContactEntryDBID: "",
			u8sCtiServerUserName: "",
			u8sProviderNumber: "",
			rCharging: 0.0,
			iSucessState: 0,
			u8sSubject: "",
			u8sMemo: "",
			iReadFlag: 0,
			projectPinRule: ENetUC_Common_SIPCTI.AsnProjectPinRule.initEmpty(),
			u8sTrackerID: "",
			u8sAcceptorName: "",
			u8sAcceptorCtiServerUserName: "",
			u8sAcceptorConnectionID: ""
		});
	}

	/** Unique ID of the call\t */
	public u8sConnectionID!: string;
	/** Time stamp start of call (first event). */
	public stStartTime!: Date;
	/** Time stamp end of call (last event). */
	public stStopTime!: Date;
	/** Duration of the call from stStartTime to stStopTime in seconds. */
	public iDurationTotal!: number;
	/** Time stamp when the call was connected (e.g. accepted). */
	public stConnectTime!: Date;
	/** Time stamp when the call was disconnected (e.g. hangup). */
	public stDisconnectTime!: Date;
	/** Duration of the call from stConnectTime to stDisconnectTime in seconds. */
	public iDurationConnected!: number;
	/** 1: outgoing call (outbound), 0: incoming GesprÃ¤ch (inbound) */
	public iOrigin!: number;
	/** 1: internal call (call with internal partner), 0: external call (call with external partner) */
	public iInternal!: number;
	/** Number of the line, the call was active. */
	public u8sLineNumber!: string;
	/** A Name of the Line (can be the owner's name or a name configured in the PBX). */
	public u8sLineName!: string;
	/** The user name of the line owner. */
	public u8sLineUserName!: string;
	/** 1: user was offline during the call was active. */
	public iLineUserOffline!: number;
	/** Number of the line in local format (can be different to u8sLineNumber). */
	public u8sLocalNumber!: string;
	/** Phone number of the partner of the call. */
	public u8sPhoneNumber!: string;
	/** If the call was redirected from another extension, the phone number of that line is given here. */
	public u8sRedirectingNumber!: string;
	/** If available, the name of the call partner. */
	public u8sContactName!: string;
	/** If available, the company of the call partner. */
	public u8sContactCompany!: string;
	/** If available, entry id of the contact data to get it from the source database. */
	public u8sContactEntryID!: string;
	/** If available, store id of the contact data to get it from the source database. */
	public u8sContactEntryStoreID!: string;
	/** If available, entry database id of the contact data to get it from the source database. */
	public u8sContactEntryDBID!: string;
	/** User name of the owner. */
	public u8sCtiServerUserName!: string;
	/** TBD */
	public u8sProviderNumber!: string;
	/** deprecated, unused. */
	public rCharging!: number;
	/** TBD */
	public iSucessState!: number;
	/** Free text, unused. */
	public u8sSubject!: string;
	/** Free text, contains possible comment to the call. */
	public u8sMemo!: string;
	/** 0: call was not marked as read, 1: call was marked as read. */
	public iReadFlag!: number;
	/** Contains further information about possible used project or pin rules. */
	public projectPinRule!: ENetUC_Common_SIPCTI.AsnProjectPinRule;
	/** Unique ID for the call tracker, which contains further information about the call history if the call was forwarded for example. */
	public u8sTrackerID!: string;
	/** If available, the name of the user who accepted the call (e.g. if the call was missed by the owner and a collegue has picked it up instead). */
	public u8sAcceptorName!: string;
	/** If available, the user name of the user who accepted the call (e.g. if the call was missed by the owner and a collegue has picked it up instead). */
	public u8sAcceptorCtiServerUserName!: string;
	/** If available, the unique id of the journal entry, which was the call of the acceptor (e.g. if the call was missed by the owner and a collegue has picked it up instead). */
	public u8sAcceptorConnectionID!: string;
	/** Additional parameters for AsnNetDatabaseJournalFindOptions added here for backward compatibility. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateCallAndJournalContactAndNumberArgument
// [PrintTSSeqDefCode] AsnUpdateCallAndJournalContactAndNumberArgument
/**
 * Argument for asnUpdateCallAndJournalContactAndNumber.
 *
 * @private
 */
export class AsnUpdateCallAndJournalContactAndNumberArgument {
	public constructor(that: AsnUpdateCallAndJournalContactAndNumberArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateCallAndJournalContactAndNumberArgument {
		return new AsnUpdateCallAndJournalContactAndNumberArgument({
			u8sConnectionID: "",
			u8sRemoteNumberSC: "",
			remoteContact: ENetUC_Common.AsnNetDatabaseContact.initEmpty()
		});
	}

	/** Unique ID of the call or journal entry to modify. */
	public u8sConnectionID!: string;
	/** Phone number to set for the contact in long format (e.g. +49815136856177). */
	public u8sRemoteNumberSC!: string;
	/** The contact to set for the journal entry. */
	public remoteContact!: ENetUC_Common.AsnNetDatabaseContact;
	/** No optionals yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateCallAndJournalContactAndNumberResult
// [PrintTSSeqDefCode] AsnUpdateCallAndJournalContactAndNumberResult
/**
 * The result of asnUpdateCallAndJournalContactAndNumber.
 *
 * @private
 */
export class AsnUpdateCallAndJournalContactAndNumberResult {
	public constructor(that: AsnUpdateCallAndJournalContactAndNumberResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateCallAndJournalContactAndNumberResult {
		return new AsnUpdateCallAndJournalContactAndNumberResult({
			bResultCallInfoUpdate: false,
			bResultJournalUpdate: false
		});
	}

	/** If the active call was updated, this will be \"true\". */
	public bResultCallInfoUpdate!: boolean;
	/** If the journal entry was updated, this will be \"true\". */
	public bResultJournalUpdate!: boolean;
	/** No optionals yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateCallLocalContactArgument
// [PrintTSSeqDefCode] AsnUpdateCallLocalContactArgument
export class AsnUpdateCallLocalContactArgument {
	public constructor(that: AsnUpdateCallLocalContactArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateCallLocalContactArgument {
		return new AsnUpdateCallLocalContactArgument({
			u8sConnectionID: "",
			localContact: ENetUC_Common.AsnNetDatabaseContact.initEmpty()
		});
	}

	/** ID, anhand welcher der Journaleintrag identifiziert wird. */
	public u8sConnectionID!: string;
	/** Local Contact = Mandantenkontakt, der vom Client gefunden wurde und jetzt gesetzt wird. */
	public localContact!: ENetUC_Common.AsnNetDatabaseContact;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateJournalMemoArgument
// [PrintTSSeqDefCode] AsnUpdateJournalMemoArgument
export class AsnUpdateJournalMemoArgument {
	public constructor(that: AsnUpdateJournalMemoArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateJournalMemoArgument {
		return new AsnUpdateJournalMemoArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList(),
			u8sMemo: ""
		});
	}

	/** Liste von IDs, anhand welcher der Journaleintrag identifiziert wird. */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
	/** Die Notiz zu einem bestimmten Call */
	public u8sMemo!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateJournalMemoResult
// [PrintTSSeqDefCode] AsnUpdateJournalMemoResult
export class AsnUpdateJournalMemoResult {
	public constructor(that: AsnUpdateJournalMemoResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateJournalMemoResult {
		return new AsnUpdateJournalMemoResult({
			bSuccess: false
		});
	}

	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateJournalProjectArgument
// [PrintTSSeqDefCode] AsnUpdateJournalProjectArgument
export class AsnUpdateJournalProjectArgument {
	public constructor(that: AsnUpdateJournalProjectArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateJournalProjectArgument {
		return new AsnUpdateJournalProjectArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList(),
			projectPinRule: ENetUC_Common_SIPCTI.AsnProjectPinRule.initEmpty()
		});
	}

	/** Liste von IDs, anhand welcher der Journaleintrag identifiziert wird. */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
	/** Projekt informationen */
	public projectPinRule!: ENetUC_Common_SIPCTI.AsnProjectPinRule;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateJournalProjectResult
// [PrintTSSeqDefCode] AsnUpdateJournalProjectResult
export class AsnUpdateJournalProjectResult {
	public constructor(that: AsnUpdateJournalProjectResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateJournalProjectResult {
		return new AsnUpdateJournalProjectResult({
			bSuccess: false
		});
	}

	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateJournalReadFlagArgument
// [PrintTSSeqDefCode] AsnUpdateJournalReadFlagArgument
export class AsnUpdateJournalReadFlagArgument {
	public constructor(that: AsnUpdateJournalReadFlagArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateJournalReadFlagArgument {
		return new AsnUpdateJournalReadFlagArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList(),
			bReadFlag: false
		});
	}

	/** Liste von IDs, anhand welcher der Journaleintrag identifiziert wird. */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
	/** TRUE == Erledigt */
	public bReadFlag!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateJournalReadFlagResult
// [PrintTSSeqDefCode] AsnUpdateJournalReadFlagResult
export class AsnUpdateJournalReadFlagResult {
	public constructor(that: AsnUpdateJournalReadFlagResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateJournalReadFlagResult {
		return new AsnUpdateJournalReadFlagResult({
			bSuccess: false
		});
	}

	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateJournalSubjectArgument
// [PrintTSSeqDefCode] AsnUpdateJournalSubjectArgument
export class AsnUpdateJournalSubjectArgument {
	public constructor(that: AsnUpdateJournalSubjectArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateJournalSubjectArgument {
		return new AsnUpdateJournalSubjectArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList(),
			u8sSubject: ""
		});
	}

	/** Liste von IDs, anhand welcher der Journaleintrag identifiziert wird. */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
	/** Neue Betreffzeile */
	public u8sSubject!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateJournalSubjectResult
// [PrintTSSeqDefCode] AsnUpdateJournalSubjectResult
export class AsnUpdateJournalSubjectResult {
	public constructor(that: AsnUpdateJournalSubjectResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateJournalSubjectResult {
		return new AsnUpdateJournalSubjectResult({
			bSuccess: false
		});
	}

	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetJournalEntriesCountResult
// [PrintTSSeqDefCode] AsnGetJournalEntriesCountResult
export class AsnGetJournalEntriesCountResult {
	public constructor(that: AsnGetJournalEntriesCountResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetJournalEntriesCountResult {
		return new AsnGetJournalEntriesCountResult({
			iCount: 0
		});
	}

	public iCount!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnJournalEntryGetArgument
// [PrintTSSeqDefCode] AsnJournalEntryGetArgument
export class AsnJournalEntryGetArgument {
	public constructor(that: AsnJournalEntryGetArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJournalEntryGetArgument {
		return new AsnJournalEntryGetArgument({
			u8sConnectionID: ""
		});
	}

	/** connection ID zu welcher der Journaleintrag generiert werden soll. */
	public u8sConnectionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnJournalSubscribeEventsArgument
// [PrintTSSeqDefCode] AsnJournalSubscribeEventsArgument
export class AsnJournalSubscribeEventsArgument {
	public constructor(that: AsnJournalSubscribeEventsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJournalSubscribeEventsArgument {
		return new AsnJournalSubscribeEventsArgument({
			bReceiveJournalEvents: false
		});
	}

	/** Journal Events abbonieren */
	public bReceiveJournalEvents!: boolean;
	/** Wenn dieser optionale Parameter angegeben wird, kann man damit ein differentielles Update anfordern. */
	public iLastKnownGlobTransactionID?: number;
	/** Maximale Anzahl der zurÃ¼ckgelieferten JournaleintrÃ¤ge */
	public iMaxEntries?: number;
	/** ZusÃ¤tzliche Subscribe-Optionen, siehe AsnJournalSubscribeFlags */
	public iFlags?: number;
	/** Optionale Parameter */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnJournalSubscribeEventsResult
// [PrintTSSeqDefCode] AsnJournalSubscribeEventsResult
export class AsnJournalSubscribeEventsResult {
	public constructor(that: AsnJournalSubscribeEventsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJournalSubscribeEventsResult {
		return new AsnJournalSubscribeEventsResult({
			iResult: 0
		});
	}

	/** AsnJournalResultEnum */
	public iResult!: number;
	/** Optionale Parameter */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnJournalGetSettingsArgument
// [PrintTSSeqDefCode] AsnJournalGetSettingsArgument
export class AsnJournalGetSettingsArgument {
	public constructor(that?: AsnJournalGetSettingsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJournalGetSettingsArgument {
		return new AsnJournalGetSettingsArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnDeleteJournalEntriesArgument
// [PrintTSSeqDefCode] AsnDeleteJournalEntriesArgument
export class AsnDeleteJournalEntriesArgument {
	public constructor(that: AsnDeleteJournalEntriesArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDeleteJournalEntriesArgument {
		return new AsnDeleteJournalEntriesArgument({
			u8sConnectionIDList: new ENetUC_Common.UTF8StringList()
		});
	}

	/** Liste von IDs, anhand welcher der Journaleintrag identifiziert wird. */
	public u8sConnectionIDList!: ENetUC_Common.UTF8StringList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnDeleteJournalEntriesResult
// [PrintTSSeqDefCode] AsnDeleteJournalEntriesResult
export class AsnDeleteJournalEntriesResult {
	public constructor(that: AsnDeleteJournalEntriesResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnDeleteJournalEntriesResult {
		return new AsnDeleteJournalEntriesResult({
			iResult: 0
		});
	}

	/** Erfolgreich: 0, sonst != 0 */
	public iResult!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnMarkAllJournalEntriesAsReadArgument
// [PrintTSSeqDefCode] AsnMarkAllJournalEntriesAsReadArgument
export class AsnMarkAllJournalEntriesAsReadArgument {
	public constructor(that?: AsnMarkAllJournalEntriesAsReadArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMarkAllJournalEntriesAsReadArgument {
		return new AsnMarkAllJournalEntriesAsReadArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnMarkAllJournalEntriesAsReadResult
// [PrintTSSeqDefCode] AsnMarkAllJournalEntriesAsReadResult
export class AsnMarkAllJournalEntriesAsReadResult {
	public constructor(that: AsnMarkAllJournalEntriesAsReadResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMarkAllJournalEntriesAsReadResult {
		return new AsnMarkAllJournalEntriesAsReadResult({
			iResult: 0
		});
	}

	/** Erfolgreich: 0, sonst != 0 */
	public iResult!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnNetDatabaseJournalList
// [PrintTSSetOfDefCode] AsnNetDatabaseJournalList
export class AsnNetDatabaseJournalList extends Array<AsnNetDatabaseJournal> { }

// [PrintTSTypeDefCode] AsnNetDatabaseJournalFindOptions
// [PrintTSSeqDefCode] AsnNetDatabaseJournalFindOptions
/**
 * Filter options for finding more specific journal entries.
 * The find options are use to give more details about the journal entries which should be in the result list.
 * By default, the search is limited to the journal entries of the logged in user (public and private). Deleted ones are never listed.
 *
 * If there are user names given in u8slistLineUserNames, the search can be expanded on users, where access rights were granted (public only).
 *
 * @private
 */
export class AsnNetDatabaseJournalFindOptions {
	public constructor(that: AsnNetDatabaseJournalFindOptions) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNetDatabaseJournalFindOptions {
		return new AsnNetDatabaseJournalFindOptions({
			stStartTimeFrom: new Date(),
			stStartTimeTo: new Date(),
			u8slistLineUserNames: new ENetUC_Common.UTF8StringList(),
			u8slistPhoneNumber: new ENetUC_Common.UTF8StringList(),
			u8sContactName: "",
			u8sCompanyName: "",
			u8sFilterText: "",
			bsFlags: 0,
			iMaxNumEntries: 0
		});
	}

	/** Filter on \"StartTime\", first time stamp of a desired time window of journal entries. */
	public stStartTimeFrom!: Date;
	/** Filter on \"StartTime\", last time stamp of a desired time window of journal entries. */
	public stStartTimeTo!: Date;
	/** A list of CTIUserNames of which journal entries should be searched. */
	public u8slistLineUserNames!: ENetUC_Common.UTF8StringList;
	/** The own user name of the logged in user is not necessary and should not be given here. */
	public u8slistPhoneNumber!: ENetUC_Common.UTF8StringList;
	/** Name of the conversational partner. */
	public u8sContactName!: string;
	/** Company name of the conversational partner. */
	public u8sCompanyName!: string;
	/** Free text which will be found in the contact name, company name, phone number or memo field. */
	public u8sFilterText!: string;
	/**
	 * Inbound\/Outbound, Intern\/Extern etc.
	 *
	 * @deprecated - this property is deprecated
	 */
	public bsFlags!: number;
	/** Max count of journal entries to return, 0 would be \"all\". Higher values resulting in a increasing perfomance impact on the ucserver. A good value is 100. */
	public iMaxNumEntries!: number;
	/** Additional parameters for AsnNetDatabaseJournalFindOptions added here for backward compatibility. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetJournalEntriesArgument
// [PrintTSSeqDefCode] AsnGetJournalEntriesArgument
/**
 * Arguments for asnGetJournalEntries.
 *
 * @private
 */
export class AsnGetJournalEntriesArgument {
	public constructor(that: AsnGetJournalEntriesArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetJournalEntriesArgument {
		return new AsnGetJournalEntriesArgument({
			findOptions: AsnNetDatabaseJournalFindOptions.initEmpty()
		});
	}

	public findOptions!: AsnNetDatabaseJournalFindOptions;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetJournalEntriesResult
// [PrintTSSeqDefCode] AsnGetJournalEntriesResult
/**
 * Result of asnGetJournalEntries.
 *
 * @private
 */
export class AsnGetJournalEntriesResult {
	public constructor(that: AsnGetJournalEntriesResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetJournalEntriesResult {
		return new AsnGetJournalEntriesResult({
			journalList: new AsnNetDatabaseJournalList()
		});
	}

	public journalList!: AsnNetDatabaseJournalList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetJournalEntriesCountArgument
// [PrintTSSeqDefCode] AsnGetJournalEntriesCountArgument
export class AsnGetJournalEntriesCountArgument {
	public constructor(that: AsnGetJournalEntriesCountArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetJournalEntriesCountArgument {
		return new AsnGetJournalEntriesCountArgument({
			findOptions: AsnNetDatabaseJournalFindOptions.initEmpty()
		});
	}

	public findOptions!: AsnNetDatabaseJournalFindOptions;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnJournalEntryGetResult
// [PrintTSSeqDefCode] AsnJournalEntryGetResult
export class AsnJournalEntryGetResult {
	public constructor(that: AsnJournalEntryGetResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJournalEntryGetResult {
		return new AsnJournalEntryGetResult({
			journalEntry: AsnNetDatabaseJournal.initEmpty()
		});
	}

	/** der Journaleintrag */
	public journalEntry!: AsnNetDatabaseJournal;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnJournalEntryChangedArgument
// [PrintTSSeqDefCode] AsnJournalEntryChangedArgument
export class AsnJournalEntryChangedArgument {
	public constructor(that: AsnJournalEntryChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJournalEntryChangedArgument {
		return new AsnJournalEntryChangedArgument({
			iFlags: 0,
			journalEntry: AsnNetDatabaseJournal.initEmpty()
		});
	}

	/** Journal entry added(1), modified(2), deleted(3), added or modified(4).&lt;br \/&gt; */
	public iFlags!: number;
	/** optional, abhÃ¤ngig von iFlags */
	public journalEntry!: AsnNetDatabaseJournal;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnJournalEntryChangedList
// [PrintTSSetOfDefCode] AsnJournalEntryChangedList
export class AsnJournalEntryChangedList extends Array<AsnJournalEntryChangedArgument> { }

// [PrintTSTypeDefCode] AsnJournalEntryChangedListArgument
// [PrintTSSeqDefCode] AsnJournalEntryChangedListArgument
export class AsnJournalEntryChangedListArgument {
	public constructor(that: AsnJournalEntryChangedListArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJournalEntryChangedListArgument {
		return new AsnJournalEntryChangedListArgument({
			iLastTransactionID: 0,
			journalEntryChangedList: new AsnJournalEntryChangedList()
		});
	}

	/** Counter, der bei jeder Ãnderung der Datenbank (modify\/add) inkrementiert wird. FÃ¼r differentielle Updates. */
	public iLastTransactionID!: number;
	public journalEntryChangedList!: AsnJournalEntryChangedList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnJournalGetSettingsResult
// [PrintTSSeqDefCode] AsnJournalGetSettingsResult
export class AsnJournalGetSettingsResult {
	public constructor(that: AsnJournalGetSettingsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJournalGetSettingsResult {
		return new AsnJournalGetSettingsResult({
			ePrivacyLevel: AsnJournalPrivateCallOptions.eNORMAL,
			bUseOfflineJournal: false,
			bOfflineDeleteOldEntries: false,
			iOfflineDaysNoDelete: 0
		});
	}

	public ePrivacyLevel!: AsnJournalPrivateCallOptions;
	public bUseOfflineJournal!: boolean;
	public bOfflineDeleteOldEntries!: boolean;
	public iOfflineDaysNoDelete!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnUpdateJournalArgument
// [PrintTSSeqDefCode] AsnUpdateJournalArgument
export class AsnUpdateJournalArgument {
	public constructor(that: AsnUpdateJournalArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUpdateJournalArgument {
		return new AsnUpdateJournalArgument({
			journalList: new AsnNetDatabaseJournalList(),
			iFlags: 0
		});
	}

	public journalList!: AsnNetDatabaseJournalList;
	public iFlags!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetOfflineJournalResult
// [PrintTSSeqDefCode] AsnGetOfflineJournalResult
/**
 * Result of asnGetOfflineJournal.
 *
 * @private
 */
export class AsnGetOfflineJournalResult {
	public constructor(that: AsnGetOfflineJournalResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetOfflineJournalResult {
		return new AsnGetOfflineJournalResult({
			journalList: new AsnNetDatabaseJournalList()
		});
	}

	/** List of AsnNetDatabaseJournal objects, containing the offline journal entries found. */
	public journalList!: AsnNetDatabaseJournalList;
	/** No optionals yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}
