import "./licensesDialog.scss";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppBar, Dialog, DialogTitle, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "../../../zustand/store";
import ElevationScroll from "../ElevationScroll";

// Properties for
interface IProps {
	onCloseLicensesDialog: (open: boolean) => void;
}

/**
 * The Dialog which contains all the licenses used by the different libraries
 * included in ProCall Meetings
 *
 * @param props - passed from the parent element
 * @returns - the element itself
 */
export default function LicensesDialog(props: IProps): JSX.Element {
	const { t } = useTranslation();
	const [scroll, setScroll] = React.useState<HTMLElement | null>(null);
	const [, setElevate] = React.useState<boolean>(true);
	const [licenseOutput, setLicenseOutPut] = React.useState<string>("");

	const clientVersion = useStore((state) => state.clientVersion);
	/**
	 * The Loggers getLogData callback (used in all the log methods called in this
	 * class, add the classname to every log entry)
	 *
	 * @returns - an ILogData log data object provided additional data for all the
	 * logger calls in this class
	 */

	const onScroll = () => {
		if (scroll) {
			const { scrollTop, scrollHeight, clientHeight } = scroll;
			// As we move near the lower edge we want to lower the elevation
			if (scrollTop + clientHeight >= scrollHeight - 30) {
				setElevate(false);
			} else {
				setElevate(true);
			}
		}
	};

	/**
	 * Fetches all the Licenses from licenses.json and puts them into a state var
	 * which is then used to display them
	 */

	interface ILicenseDetails {
		licenses: string;
		publisher: string;
		email: string;
		repository: string;
	}

	useEffect(() => {
		const getLicenses = async () => {
			let output = "";
			const info = await fetch("./licenses.json?v=" + clientVersion);
			const indexedObjext = (await info.json()) as { [index: string]: ILicenseDetails };
			for (const key in indexedObjext) {
				const element = indexedObjext[key];
				if (!element) {
					continue;
				}

				output += "<p>" + "<span>" + t<string>("IDS_LICENSES_PACKAGE") + " " + key + "</span><br>";
				output += "<span>" + t<string>("IDS_LICENSES_TYPE") + " " + element.licenses + "</span><br>";
				if (element.publisher != null) {
					output += "<span>" + t<string>("IDS_LICENSES_PUBLISHER") + " " + element.publisher + "</span><br>";
				}
				if (element.email != null) {
					output += "<span>" + t<string>("IDS_LICENSES_EMAIL") + " " + element.email + "</span><br>";
				}
				if (element.repository != null) {
					output +=
						"<span>" +
						t<string>("IDS_LICENSES_REPO") +
						" <a class='link' href='" +
						element.repository +
						"' target='_blank'>";
					output += element.repository + "</a></span><br>";
				}
				output += "</p>";
			}
			setLicenseOutPut(output);
		};
		void getLicenses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog
			open={licenseOutput !== ""}
			onClose={() => {
				props.onCloseLicensesDialog(false);
			}}
			disableEscapeKeyDown={false}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<ElevationScroll target={scroll || undefined} elevation={1}>
				<AppBar position="relative" className="li-appBar">
					<DialogTitle
						id="alert-dialog-title"
						sx={{
							margin: "16px"
						}}
					>
						<span className="li-title">{t<string>("IDS_LICENSES_TITLE")}</span>
						<p className="li-subtitle">{t<string>("IDS_LICENSES_SUBTITLE")}</p>
						<IconButton
							aria-label="close"
							className="li-close"
							onClick={() => {
								props.onCloseLicensesDialog(false);
							}}
						>
							<FontAwesomeIcon size="xs" icon={faTimes} />
						</IconButton>
					</DialogTitle>
				</AppBar>
			</ElevationScroll>

			<div
				className="sd-formGrid flex-row-start"
				onScroll={() => {
					onScroll();
				}}
				ref={(node): void => {
					if (node) {
						setScroll(node);
					}
				}}
				dangerouslySetInnerHTML={{ __html: licenseOutput }}
			></div>
		</Dialog>
	);
}
