import React from "react";
import "../common/sharedButtonsCommon.scss";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-light-svg-icons";
import { IButtonCommonProps } from "../../interfaces/IButtonCommonProps";
import { setFAIconShrinkSize, theSharedBrowser, theSharedTranslationManager } from "../../../../helpers/helpers";

interface IProps extends IButtonCommonProps {
	onClick?: () => void;
}

/**
 * Hang Up Button
 *
 * @param props - props
 * @returns - the component
 */
export default function SharedCallHangUpButton(props: IProps): JSX.Element {
	const width: string = props.width + "px";
	const height: string = props.height + "px";

	return (
		<div className="shared-buttons-common-container">
			<Tooltip title={theSharedTranslationManager.getTranslation("IDS_AV_HANGUP")} placement="bottom">
				<IconButton
					data-cy="webrtc-hang-up-call"
					id="shared-buttons-common-rotated-phone-container-id"
					className="shared-buttons-common-rotated-phone-container"
					{...(!theSharedBrowser.is.touchScreen ? { onClick: () => { props.onClick && props.onClick(); } } : {})}
					{...(theSharedBrowser.is.touchScreen ? { onTouchStart: () => { props.onClick && props.onClick(); } } : {})}
					sx={{
						padding: 0,
						margin: 0,
						width: width,
						height: height
					}}
				>
					<FontAwesomeIcon  transform={setFAIconShrinkSize(5)} icon={faPhone} className="shared-buttons-common-rotated-phone" />
				</IconButton>
			</Tooltip>
		</div>
	);
}
