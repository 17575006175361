import { faHeadphones, faVideo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IKeyValuePair } from "../../../web-shared-components/interfaces/IKeyValuePair";
import { getPhoneNumbersFromAsnContact } from "../../../web-shared-components/react-components/contactDetailsComponent/processing/ContactDetailsHelper";
import { addSipPrefixIfNotExists, checkUserHasVideoChat, startCall } from "../../lib/commonHelper";
import { AsnNetDatabaseContact } from "../../ucserver/stubs/ENetUC_Common";
import { useStore } from "../../zustand/store";

interface ICallOptionsMenuProps {
	anchorEl: HTMLElement | null;
	onClose: () => void;
	showVideoOption?: boolean;
	contact: AsnNetDatabaseContact;
}

export const CallOptionsMenu = ({ contact, anchorEl, onClose, showVideoOption }: ICallOptionsMenuProps) => {
	const { t } = useTranslation();
	const open = Boolean(anchorEl);
	const contactDetails = useStore((state) => state.contactDetails);
	const customLabels = useStore((state) => state.customLabels);
	const contacts = useStore((state) => state.contactsPresence);
	const statusInfos = contacts.get(contact.u8sSIPAddress ?? "");

	const phoneNumbers = useMemo((): IKeyValuePair[] => {
		return getPhoneNumbersFromAsnContact(contact, customLabels.CustLabels, contactDetails.ContactDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contact]);

	const prefixedSipAddress = addSipPrefixIfNotExists(contact.u8sSIPAddress ?? "");

	return (
		<Menu
			id="call-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			MenuListProps={{
				"aria-labelledby": "call-button"
			}}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
			}}
		>
			<MenuItem
				onClick={async (e) => {
					e.stopPropagation();
					startCall(prefixedSipAddress, "audio");
				}}
				disabled={!contact.u8sSIPAddress}
			>
				<ListItemIcon>
					<FontAwesomeIcon icon={faHeadphones} className={"ch-audio-icon"} />
				</ListItemIcon>
				<ListItemText>{t<string>("IDS_CHAT_CONTEXT_CHATAUDIO")}</ListItemText>
			</MenuItem>
			{phoneNumbers.length > 0 ? <Divider sx={{ mx: 1 }} /> : null}
			{phoneNumbers.map((number: IKeyValuePair, index: number) => (
				<MenuItem
					key={index}
					onClick={() => {
						window.open(`tel:${number.value}`, "_self");
					}}
				>
					{t<string>(number.key)}
					:&nbsp;
					{number.value}
				</MenuItem>
			))}
			{showVideoOption
				? [
						<Divider key={"divider"} sx={{ mx: 1 }} />,
						<MenuItem
							key="video-call"
							disabled={!checkUserHasVideoChat(statusInfos)}
							onClick={async (e) => {
								e.stopPropagation();
								startCall(prefixedSipAddress, "video");
							}}
						>
							<Stack flex={1} justifyContent={"center"}>
								<Stack flexDirection={"row"}>
									<ListItemIcon sx={{ alignItems: "center" }}>
										<FontAwesomeIcon icon={faVideo} />
									</ListItemIcon>
									<ListItemText>{t("IDS_CONTACT_DETAILS_QUICK_VIDEO")}</ListItemText>
								</Stack>
							</Stack>
						</MenuItem>
				  ]
				: []}
		</Menu>
	);
};
