// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_CTI.ts
 * "UC-Server-Access-Protocol-CTI" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * CTI interface
 * ## CTI Modul
 *
 *
 * ## Module description
 * This module contains structures and operations for CTI (remote call control) on TAPI or Bluetooth phone lines in the server.
 *
 * A client program has access to all lines owned by the logged in user and to additional lines it has dedicated access.&lt;br \/&gt;
 * It is possible to monitor all actions on these lines and to call operations to initiate new calls, set forwards, change states and so on.
 *
 * ## Prerequisites
 * In order to use the operations described in this document you have to make sure:
 * - The server has lines configured and activated. (Install an appropriate TAPI-driver for your PBX system.)
 * - There is at least one user activated on the server, who has lines configured to use.
 * - The client program is connected to the server via UCServer Web Services using a websocket or directly to the server on the client port (default port 7222).
 * - The client program encodes the data structures as estos JSON or ASN.1 BER.
 * - The client program is logged in to the server with a user who has access to lines.
 *
 * Additional prerequisites for a operation on the PBX and or the TAPI driver may be necessary.
 *
 * ### Description of the Line-Call-Model ###
 * The remote call control and monitoring of the server is based on a line-call-model. This means, that users, lines and calls are organized in a tree like hierarchy.
 * Each node in this tree contains configuration data and dynamic data, which can be accessed and observed.
 *
 * The Nodes are:
 * - The user. This a more a kind of \"meta\" node, it just holds the list of lines, a client has access to if it is logged in as this user.
 * - The line(s). Defined with the logged in user, a client can retrieve a list of lines, the user has access to. With these lines, the client can (one time) pull information or can attach itself on to get initial data and events if these data changes over time (get-and-subscribe mechanism).
 * The line information contains configuration information and dynamic data about the lines status, including current calls.
 * *Note:* A line is identified by its URI tel:number@pbxsystem. The parameter is often named as \"u8sLinePhoneNumber\".&lt;br \/&gt;The \"u8sPhoneNumber\" is the phone number of a line, but for technical reasons that is not a distinct property of a line. It is possible to have multiple lines with the same phone number!
 * - The call(s). A line has a list of current calls, which are currently \"on\" the line. If the client is attached to a line for events, new calls will be announced via events. Changes to these calls are also announced via events.
 * There is no need to attach to calls explicitly, this is already done by attaching to the line. Call changing events are then sent to the client too.
 * *Note:* A call is identified by its connection identifier often named as \"ConnectionID\".
 *
 * ### Remarks ###
 * Some operations return an empty result object if they are executed successfully. So in this cases, the business logic should wait for result\/error before do additional actions.
 * Phone numbers are normally used and provided in a so called supercanonic notation containing a full qualified number including country, city, main and extension number: +49815136856177
 * For operations affecting multiple calls be sure to check that all calls affected have the corresponding feature set. To merge two calls both calls have to have the feature eCFEATUREMERGECALLS set.
 *
 * ### Example: Monitoring lines ###
 * 1. Get the lines the client has access to with asnCtiEnumPhoneLines. If you wish to get events if the line list changes afterwards, set the parameter \"attachChangeNotify\" to 1.
 * 2. Use the lines URI to start monitoring for initial data and events on it. asnCtiLineMonitorStart will result in a monitor cross reference id to distinguish between events for different line monitors easier.
 * This is useful, if the client has the requirement to monitor the same line multiple times for different use cases.
 * 3. With the line URI you can call functions e.g. asnCtiMakeCall, asnCtiAnswerCall a.s.o. With the line monitoring on, you will get the according changes on the line and its calls as events back.
 * 4. To stop monitoring a line, call asnCtiLineMonitorStop with the corresponding monitor cross reference id.
 *
 * ### Example: Call data transfer ###
 *
 * ### Phone number formats ###
 * The whole system tries to use supercanonic numbers (+country city main and extension number \/ +49815136856177) whereever possible.
 * As long as the number is used in supercanonic notation all components can format the number as required (for dialing, for display, etc.)
 * You likely wonÂ´t need to format numbers as the system always provides supercanonic numbers.
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";

// [PrintTSTypeDefCode] AsnCtiNotifyLineAddRemoveArgumentEnum
// [PrintTSEnumDefCode] AsnCtiNotifyLineAddRemoveArgumentEnum
export enum AsnCtiNotifyLineAddRemoveArgumentEnum {
	/** Line was added. */
	added = 0,
	/** Line was removed. */
	removed = 1
}

// [PrintTSTypeDefCode] AsnCtiEnumPhoneLinesArgumentEnum
// [PrintTSEnumDefCode] AsnCtiEnumPhoneLinesArgumentEnum
export enum AsnCtiEnumPhoneLinesArgumentEnum {
	/** Own lines which the logged in user is owner of. */
	userOwnLines = 0,
	/** Own lines plus the lines, the user has special access rights for. */
	userMonitorLines = 1,
	/** All lines that are local on the system. */
	systemLocalLines = 2,
	/** All local lines plus all global known lines; for future use, current its the same as systemLocalLines. */
	systemGlobalLines = 3
}

// [PrintTSTypeDefCode] AsnCtiLineOpenStatus
// [PrintTSEnumDefCode] AsnCtiLineOpenStatus
export enum AsnCtiLineOpenStatus {
	/** line ready */
	lineStatusDisconnected = 0,
	/** line is closed */
	lineStatusClosed = 1,
	/** line is opened and ready for usage */
	lineStatusOpen = 2,
	/** line is opened but out of service; e.g. no phone connected */
	lineStatusOpenNoPhoneConnected = 3,
	/** line is opened but the connection to the PBX is broken */
	lineStatusOpenNoPBXConnection = 4,
	/** line is opened but the login on the PBX has failed */
	lineStatusOpenPBXLoginFailed = 5,
	/** line is opened but there is a license missing on the PBX */
	lineStatusOpenPBXNoLicense = 6,
	/** line is opened but the CSTA session counter on the PBX exceeded */
	lineStatusOpenPBXSessionCountExceeded = 7,
	/** line cannot be opened because of missing license on the UCServer */
	lineStatusFailedNoLicense = 8,
	/** line cannot be opened because of missing license on the ECSTA driver */
	lineStatusFailedNoECSTALicense = 9
}

// [PrintTSTypeDefCode] EnumCallFeatures
// [PrintTSEnumDefCode] EnumCallFeatures
export enum EnumCallFeatures {
	/** no features\/function possible */
	eCFEATURENONE = 0,
	/** Answer a call (state eCALLSTATEOFFERING) */
	eCFEATUREANSWER = 1,
	/** Make call is allowed. You can call MakeCall also while having a call to setup a consultation. */
	eCFEATUREMAKECALL = 2,
	/** Complete a busy or an unanswered call for automated callback (if supported by PBX system) */
	eCFEATURECOMPLETECALL = 4,
	/** Merge the active call (state eCALLSTATECONNECTED) with an on hold call (state eCALLSTATEHOLD). This will disconnect the executing client from the call. */
	eCFEATUREMERGECALLS = 16,
	/** Terminate an active call (state eCALLSTATECONNECTED) or reject an incoming offering call (state eCALLSTATEOFFERING). */
	eCFEATUREHANGUP = 32,
	/** Blind transfer an active call (state eCALLSTATECONNECTED) to another extension. */
	eCFEATUREBLINDTRANSFER = 256,
	/** Redirect an incoming offering call (state eCALLSTATEOFFERING) to another extension. */
	eCFEATUREREDIRECT = 512,
	/** The call may be picked by another line (state eCALLSTATEOFFERING). */
	eCFEATUREMAYBEPICKUPED = 1024,
	/** The active call will be swapped with a call on hold on the same line. Both calls must have set this feature in order to be able to swaphold. */
	eCFEATURESWAPHOLD = 4096,
	/** Put the active call (state eCALLSTATECONNECTED) on hold (state eCALLSTATEHOLD). */
	eCFEATUREHOLD = 8192,
	/** Retrieve the call currently on hold (state eCALLSTATEHOLD). */
	eCFEATUREUNHOLD = 16384,
	/** Start a conference (european model; you have an active call (state eCALLSTATECONNECTED) and a hold call (state eCALLSTATEHOLD)). */
	eCFEATURECONFERENCESTARTEU = 65536,
	/** Call can be added to an existing conference. */
	eCFEATURECONFERENCEADDPARTY = 131072,
	/** Start a conference (american model; you have an active call and you decide you want to setup a conference) */
	eCFEATURECONFERENCESTARTUSA = 262144,
	/** A way of generating tones on the call (currently not supported by UCServer) */
	eCFEATUREGENERATETONE = 1048576,
	/** Dial DTMF tones is possible */
	eCFEATUREDIALDIGITS = 2097152,
	/** Start call recording (see UCServer Administration for details how the UCServer is supporting call recording). */
	eCFEATURESTARTCALLRECORD = 16777216,
	/** Stop call recording (see UCServer Administration for details how the UCServer is supporting call recording). */
	eCFEATURESTOPCALLRECORD = 33554432
}

// [PrintTSTypeDefCode] EnumLineFeatures
// [PrintTSEnumDefCode] EnumLineFeatures
export enum EnumLineFeatures {
	/** No actions are available. The client can only show the call states, but no other actions will be completed successfully. */
	eCTILINEFEATURENONE = 0,
	/** Call can be initiated. */
	eCTILINEFEATUREMAKECALL = 1,
	/** \"Do not disturb\" can be set\/modified. */
	eCTILINEFEATUREDONOTDISTURB = 2,
	/** Calls from this line can be picked to other lines. */
	eCTILINEFEATUREPICKUP = 4,
	/** While \"MakeCall\" the CALLERID may be suppressed. */
	eCTILINEFEATUREBLOCKID = 8,
	/** This flag is set, if it is not possible to execute \"MakeCall\" while another call is already in active state (no automatic \"put on hold\" available). */
	eCTILINEFEATURENOIMMEDIATEINQUIRY = 16,
	/** Remote Office - Nomadic Mode - is possible */
	eCTILINEFEATUREREMOTEOFFICE = 32,
	/** Invalid Password (currently only for BroadWorks lines) */
	eCTILINEFEATUREINVALIDPASSWORD = 64,
	/** This flag can be set additionally with ECTILINEFEATURE_REMOTEOFFICE, if the remote office feature will be emulated by the UCServer (call association). */
	eCTILINEFEATUREREMOTEOFFICEEMULATION = 128
}

// [PrintTSTypeDefCode] EnumForwardModes
// [PrintTSEnumDefCode] EnumForwardModes
export enum EnumForwardModes {
	/** Forward unknown */
	eFORWARDMODEUNKNOWN = 0,
	/** Forward Immediate */
	eFORWARDMODEIMMEDIATE = 1,
	/** Forward on Busy */
	eFORWARDMODEBUSY = 2,
	/** Forward on No Answer */
	eFORWARDMODENOANSW = 4,
	/** Forward on Busy for internal callers */
	eFORWARDMODEBUSYINT = 8,
	/** Forward on Busy for external callers */
	eFORWARDMODEBUSYEXT = 16,
	/** Forward on No answer for internal callers */
	eFORWARDMODENOANSINT = 32,
	/** Forward on No answer for external callers */
	eFORWARDMODENOANSEXT = 64,
	/** Forward immediate for internal callers */
	eFORWARDMODEIMMINT = 128,
	/** Forward immediate for external callers */
	eFORWARDMODEIMMEXT = 256
}

// [PrintTSTypeDefCode] EnumForwardController
// [PrintTSEnumDefCode] EnumForwardController
export enum EnumForwardController {
	/** Forwardings take place in the PBX */
	eFORWARDCONTROLLERPBX = 1,
	/** Forwardings take place in the UCServer on an administrative basis */
	eFORWARDCONTROLLERTAPISERVERADMIN = 2,
	/** Forwardings take place in the UCServer on an user basis */
	eFORWARDCONTROLLERTAPISERVERUSER = 4
}

// [PrintTSTypeDefCode] AsnCtiEnumPhoneLinesErrorEnum
// [PrintTSEnumDefCode] AsnCtiEnumPhoneLinesErrorEnum
export enum AsnCtiEnumPhoneLinesErrorEnum {
	/** There was no error; for completeness only. If the call was successful, AsnCtiEnumPhoneLinesResult will be returned. */
	success = 0,
	/** The monitor point for getting events couldn't been set. */
	generalerror = 1,
	/** The logged in user has no access rights to enumerate the lines requested. */
	accessdenied = 2
}

// [PrintTSTypeDefCode] AsnCtiSnapshotPhoneLineErrorEnum
// [PrintTSEnumDefCode] AsnCtiSnapshotPhoneLineErrorEnum
export enum AsnCtiSnapshotPhoneLineErrorEnum {
	/** There was no error; for completeness only. If the call was successful, AsnCtiEnumPhoneLinesResult will be returned. */
	success = 0,
	/** General error; not used in this case. */
	generalerror = 1,
	/** The user has no access rights to perform asnCtiSnapshotPhoneLine. */
	accessdenied = 2,
	/** The line given in the argument is not known. */
	invalidline = 3
}

// [PrintTSTypeDefCode] AsnCtiLineMonitorStartErrorEnum
// [PrintTSEnumDefCode] AsnCtiLineMonitorStartErrorEnum
export enum AsnCtiLineMonitorStartErrorEnum {
	/** There was no error; for completeness only. If the call was successful, AsnCtiLineMonitorStartResult will be returned. */
	success = 0,
	/** General error; not used in this case. */
	generalerror = 1,
	/** The user has no access rights to perform asnCtiLineMonitorStart. */
	accessdenied = 2,
	/** The line given in the argument is not known. */
	invalidline = 3
}

// [PrintTSTypeDefCode] AsnCtiLineMonitorStopErrorEnum
// [PrintTSEnumDefCode] AsnCtiLineMonitorStopErrorEnum
export enum AsnCtiLineMonitorStopErrorEnum {
	/** There was no error; for completeness only. If the call was successful, AsnCtiLineMonitorStopResult will be returned. */
	success = 0,
	/** General error; not used in this case. */
	generalerror = 1,
	/** The user has no access rights to perform asnCtiLineMonitorStop. */
	accessdenied = 2,
	/** The line given in the argument is not known. */
	invalidline = 3
}

// [PrintTSTypeDefCode] AsnCtiLineSetDoNotDisturbErrorEnum
// [PrintTSEnumDefCode] AsnCtiLineSetDoNotDisturbErrorEnum
export enum AsnCtiLineSetDoNotDisturbErrorEnum {
	/** There was no error; for completeness only. If the call was successful, AsnCtiLineSetDoNotDisturbResult will be returned. */
	success = 0,
	/** General error; not used in this case. */
	generalerror = 1,
	/** The user has no access rights to perform asnCtiLineSetDoNotDisturb. */
	accessdenied = 2,
	/** The line given in the argument is not known. */
	invalidline = 3
}

// [PrintTSTypeDefCode] AsnCtiLineSetRemoteOfficeErrorEnum
// [PrintTSEnumDefCode] AsnCtiLineSetRemoteOfficeErrorEnum
export enum AsnCtiLineSetRemoteOfficeErrorEnum {
	/** There was no error; for completeness only. If the call was successful, AsnCtiLineSetRemoteOfficeResult will be returned. */
	success = 0,
	/** General error; not used in this case. */
	generalerror = 1,
	/** The user has no access rights to perform asnCtiLineSetRemoteOffice. */
	accessdenied = 2,
	/** The line given in the argument is not known. */
	invalidline = 3
}

// [PrintTSTypeDefCode] AsnCtiQueryPBXLineErrorEnum
// [PrintTSEnumDefCode] AsnCtiQueryPBXLineErrorEnum
export enum AsnCtiQueryPBXLineErrorEnum {
	/** There was no error; for completeness only. If the call was successful, AsnCtiQueryPBXLineResult will be returned. */
	success = 0,
	/** General error; not used in this case. */
	generalerror = 1,
	/** The user has no access rights to perform asnCtiQueryPBXLine. */
	accessdenied = 2
}

// [PrintTSTypeDefCode] AsnCtiCallInfoChangedReason
// [PrintTSEnumDefCode] AsnCtiCallInfoChangedReason
export enum AsnCtiCallInfoChangedReason {
	/** New incoming call. */
	reasonCallOffering = 0,
	/** New outgoing call, dialing. */
	reasonCallDialing = 1,
	/** Outgoing call is now ringing. */
	reasonCallRingback = 2,
	/** Call is now connected. */
	reasonCallConnected = 3,
	/** Call is now member of a conference. */
	reasonCallConferenced = 4,
	/** Call is now on hold. */
	reasonCallHold = 5,
	/** The call's remote party has changed. */
	reasonRemotePartyChanged = 6,
	/** The call's remote party contact data has changed. */
	reasonRemoteContactChanged = 7,
	/** The call's project settings or private state has changed. */
	reasonProjectOrPrivateChanged = 8,
	/** The call's note has changed. */
	reasonCallMemoChanged = 9,
	/** The remote party of an outgoing call is busy. */
	reasonCallBusy = 10,
	/** The call has been disconnected on the other side. */
	reasonCallDisconnect = 11,
	/** The call has been destroyed - local line is now idle. */
	reasonCallDestroyed = 12,
	/** The call's hold contact data has been changed. */
	reasonHoldContactChanged = 13,
	/** Other reasons, not specified. */
	reasonOther = 14,
	/** The call information has been updated as a whole. No specified reason. */
	reasonCallDataChanged = 15,
	/** The connection to the phone has been destroyed, therefore the active call has been updated. (Bluetooth disconnected) */
	reasonConnectionToPhoneDestroyed = 16
}

// [PrintTSTypeDefCode] AsnCtiMakeCallErrorEnum
// [PrintTSEnumDefCode] AsnCtiMakeCallErrorEnum
export enum AsnCtiMakeCallErrorEnum {
	/** There was no error; for completeness only. If the call was successful, AsnCtiLineSetRemoteOfficeResult will be returned. */
	success = 0,
	/** General error; this error code will be sent of the call cannot be made on the line or the project settings in newCallParams are wrong (if given). */
	generalerror = 1,
	/** The user has no access rights to perform asnCtiLineSetRemoteOffice. */
	accessdenied = 2,
	/** The line given in the argument is not known. */
	invalidline = 3
}

// [PrintTSTypeDefCode] EnumCtiDigitMode
// [PrintTSEnumDefCode] EnumCtiDigitMode
export enum EnumCtiDigitMode {
	/** Server decides which dialing mode is used. */
	eLINEDIGITMODESERVERDECIDE = 0,
	/** Uses rotary pulse sequences to signal digits. Valid digits are 0 through 9. */
	eLINEDIGITMODEPULSE = 1,
	/** Uses DTMF tones to signal digits. Valid digits are 0 through 9, '*', '#', 'A', 'B', 'C', and 'D'. */
	eLINEDIGITMODEDTMF = 2,
	/** Uses DTMF tones to signal digits and detect the down edges. Valid digits are 0 through 9, '*', '#', 'A', 'B', 'C', and 'D'. */
	eLINEDIGITMODEDTMFEND = 4
}

// [PrintTSTypeDefCode] AsnMobileRegisterDeviceErrorEnum
// [PrintTSEnumDefCode] AsnMobileRegisterDeviceErrorEnum
export enum AsnMobileRegisterDeviceErrorEnum {
	general = 0
}

// [PrintTSTypeDefCode] AsnCtiCallData
// [PrintTSSeqDefCode] AsnCtiCallData
/**
 * Data structure (key value pair) to associate additional information with call objects.
 * Data structure to associate additional information with call objects, which can be
 * transferred together with a call to another client.&lt;br \/&gt;
 * The data consists of key\/value pairs. The keys can be used for different use cases and should be named in such a way,
 * so that they do not conflict with other uses.&lt;br \/&gt;
 *
 * The AsnCtiCallDataList is part of the following OptionalParams of these data structs:
 * - AsnCtiCallInformation
 * - AsnCtiNewCallParams
 * The Key of the AsnCtiCallDataList is \"calldata\".
 */
export class AsnCtiCallData {
	public constructor(that: AsnCtiCallData) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiCallData {
		return new AsnCtiCallData({
			key: "",
			value: ""
		});
	}

	public key!: string;
	/** the string length of 'value' should be limited to a reasonable number because callData is a part of the call information and */
	public value!: string;
}

// [PrintTSTypeDefCode] AsnCtiLineInfo
// [PrintTSSeqDefCode] AsnCtiLineInfo
/**
 * Description of a line with its state, phone number etc.
 * This structure contains the complete description of a line. With this information a client can determine the current state of the line,
 * its callable number (u8sPhoneNumber), its unique identification number (u8sLinePhoneNumber, not necessary callable), its current available features and so on.
 *
 * A client can obtain this information with asnCtiEnumPhoneLines, asnCtiSnapshotPhoneLine and asnCtiLineMonitorStart.
 *
 * For visualization of phone numbers in various formats, it is necessary to get the location information of a line.
 * The contents of the u8sLineLocationID field is used to fetch the necessary data from the server. At the moment there is no
 * JavaScript implementation of the needed business logic to execute the complete call number formatting rules.
 */
export class AsnCtiLineInfo {
	public constructor(that: AsnCtiLineInfo) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineInfo {
		return new AsnCtiLineInfo({
			iType: 0,
			iLineOpenStatus: 0,
			u8sLinePhoneNumber: "",
			u8sDescriptor: "",
			u8sPhoneNumber: "",
			u8sLineLocationID: "",
			iLineFeatures: 0,
			iForwardFeaturesPBX: 0,
			iForwardFeaturesSERVER: 0,
			iForwardFeaturesUSER: 0
		});
	}

	/** Indicates if this is the primary (first) line of the user or not. */
	public iType!: number;
	/** The line open status of the line. */
	public iLineOpenStatus!: number;
	/** Contains the line identifier. This can be a phone number or (if the line is monitored by the UCServer) a LineURI beginning with 'tel:'. */
	public u8sLinePhoneNumber!: string;
	/** Descriptive text if available. */
	public u8sDescriptor!: string;
	/** This is the phone number of the line in a dialable format, if possible beginning with \"+\", e.g. \"+49815136856177\" */
	public u8sPhoneNumber!: string;
	/** ID of the PBX\/Line location information to fetch it from the server. With this information, it is possible to */
	public u8sLineLocationID!: string;
	/** Current features\/function available on the line. */
	public iLineFeatures!: number;
	/** Available line forwarding PBX features\/modes on this line */
	public iForwardFeaturesPBX!: number;
	/** Available line forwarding UCServer features\/modes on this line.&lt;br \/&gt; */
	public iForwardFeaturesSERVER!: number;
	/** Available user defined line forwarding features\/modes on this line.&lt;br \/&gt; */
	public iForwardFeaturesUSER!: number;
	/** Additionally line fields added later in the key-value-field for backward compatibility. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnRemoteOfficeState
// [PrintTSSeqDefCode] AsnRemoteOfficeState
/**
 * Remove office information object
 */
export class AsnRemoteOfficeState {
	public constructor(that: AsnRemoteOfficeState) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRemoteOfficeState {
		return new AsnRemoteOfficeState({
			u8sController: "",
			iEnabled: 0,
			u8sDestination: ""
		});
	}

	/** Remote Office Controller (currently not used) */
	public u8sController!: string;
	/** remote office enabled (1) \/ disabled (0) */
	public iEnabled!: number;
	/** Destination number */
	public u8sDestination!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSnapshotPhoneLineArgument
// [PrintTSSeqDefCode] AsnCtiSnapshotPhoneLineArgument
/**
 * Argument for asnCtiSnapshotPhoneLine
 */
export class AsnCtiSnapshotPhoneLineArgument {
	public constructor(that: AsnCtiSnapshotPhoneLineArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSnapshotPhoneLineArgument {
		return new AsnCtiSnapshotPhoneLineArgument({
			u8sLinePhoneNumber: ""
		});
	}

	/** Identifier of the line */
	public u8sLinePhoneNumber!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineMonitorStartArgument
// [PrintTSSeqDefCode] AsnCtiLineMonitorStartArgument
/**
 * Arguments for asnCtiLineMonitorStart
 */
export class AsnCtiLineMonitorStartArgument {
	public constructor(that: AsnCtiLineMonitorStartArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineMonitorStartArgument {
		return new AsnCtiLineMonitorStartArgument({
			u8sLinePhoneNumber: ""
		});
	}

	/** Identifier of the line */
	public u8sLinePhoneNumber!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineMonitorStopArgument
// [PrintTSSeqDefCode] AsnCtiLineMonitorStopArgument
/**
 * Arguments for asnCtiLineMonitorStop
 */
export class AsnCtiLineMonitorStopArgument {
	public constructor(that: AsnCtiLineMonitorStopArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineMonitorStopArgument {
		return new AsnCtiLineMonitorStopArgument({
			u8sMonitorCrossRefID: ""
		});
	}

	/** CrossRefID the client got from the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineMonitorStopResult
// [PrintTSSeqDefCode] AsnCtiLineMonitorStopResult
/**
 * Result of asnCtiLineMonitorStop
 */
export class AsnCtiLineMonitorStopResult {
	public constructor(that?: AsnCtiLineMonitorStopResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineMonitorStopResult {
		return new AsnCtiLineMonitorStopResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineSetDoNotDisturbArgument
// [PrintTSSeqDefCode] AsnCtiLineSetDoNotDisturbArgument
/**
 * Argument for asnCtiLineSetDoNotDisturb (DnD)
 */
export class AsnCtiLineSetDoNotDisturbArgument {
	public constructor(that: AsnCtiLineSetDoNotDisturbArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineSetDoNotDisturbArgument {
		return new AsnCtiLineSetDoNotDisturbArgument({
			u8sLinePhoneNumber: "",
			bDoNotDisturb: false
		});
	}

	/** Identifier of the line */
	public u8sLinePhoneNumber!: string;
	/** Set DnD (1, true) or remove it (0, false) */
	public bDoNotDisturb!: boolean;
	/** No optionals yet */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineSetDoNotDisturbResult
// [PrintTSSeqDefCode] AsnCtiLineSetDoNotDisturbResult
/**
 * Result of asnCtiLineSetDoNotDisturb
 */
export class AsnCtiLineSetDoNotDisturbResult {
	public constructor(that?: AsnCtiLineSetDoNotDisturbResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineSetDoNotDisturbResult {
		return new AsnCtiLineSetDoNotDisturbResult();
	}

	/** No optionals yet */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineSetRemoteOfficeResult
// [PrintTSSeqDefCode] AsnCtiLineSetRemoteOfficeResult
/**
 * Result of asnCtiLineSetRemoteOffice&lt;br \/&gt;
 * If the client receives the Result, the operation was successful.
 */
export class AsnCtiLineSetRemoteOfficeResult {
	public constructor(that?: AsnCtiLineSetRemoteOfficeResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineSetRemoteOfficeResult {
		return new AsnCtiLineSetRemoteOfficeResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineSetNewPasswordArgument
// [PrintTSSeqDefCode] AsnCtiLineSetNewPasswordArgument
/**
 * Argument for asnCtiLineSetNewPassword
 *
 * @private
 */
export class AsnCtiLineSetNewPasswordArgument {
	public constructor(that: AsnCtiLineSetNewPasswordArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineSetNewPasswordArgument {
		return new AsnCtiLineSetNewPasswordArgument({
			u8sLinePhoneNumber: "",
			u8sPassword: ""
		});
	}

	/** Identifier of the line */
	public u8sLinePhoneNumber!: string;
	/** The password to be set. */
	public u8sPassword!: string;
	/** No optionals yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineSetNewPasswordResult
// [PrintTSSeqDefCode] AsnCtiLineSetNewPasswordResult
/**
 * Result of asnCtiLineSetNewPassword&lt;br \/&gt;
 * If the client receives the Result, the operation was successful.
 *
 * @private
 */
export class AsnCtiLineSetNewPasswordResult {
	public constructor(that?: AsnCtiLineSetNewPasswordResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineSetNewPasswordResult {
		return new AsnCtiLineSetNewPasswordResult();
	}

	/** No optionals yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiQueryPBXLineArgument
// [PrintTSSeqDefCode] AsnCtiQueryPBXLineArgument
/**
 * Argument for asnCtiQueryPBXLine
 *
 * @private
 */
export class AsnCtiQueryPBXLineArgument {
	public constructor(that: AsnCtiQueryPBXLineArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiQueryPBXLineArgument {
		return new AsnCtiQueryPBXLineArgument({
			u8sLinePhoneNumber: ""
		});
	}

	/** Identifier of the line */
	public u8sLinePhoneNumber!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiQueryPBXLineResult
// [PrintTSSeqDefCode] AsnCtiQueryPBXLineResult
/**
 * Result of asnCtiQueryPBXLine
 *
 * @private
 */
export class AsnCtiQueryPBXLineResult {
	public constructor(that: AsnCtiQueryPBXLineResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiQueryPBXLineResult {
		return new AsnCtiQueryPBXLineResult({
			seqLinesFound: new ENetUC_Common.UTF8StringList()
		});
	}

	/** Liste von gefundenen Leitungen */
	public seqLinesFound!: ENetUC_Common.UTF8StringList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineDoNotDisturbChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineDoNotDisturbChangedArgument
/**
 * Event arguments of asnCtiNotifyLineDoNotDisturbChanged
 */
export class AsnCtiNotifyLineDoNotDisturbChangedArgument {
	public constructor(that: AsnCtiNotifyLineDoNotDisturbChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineDoNotDisturbChangedArgument {
		return new AsnCtiNotifyLineDoNotDisturbChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			iDoNotDisturb: 0
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t */
	public u8sLinePhoneNumber!: string;
	/** Current status of DnD - (1) set, (0) not set. */
	public iDoNotDisturb!: number;
	/** - Key \"BusyOnBusy\" Value AsnInt (1) oder (0) */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineInvalidPasswordChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineInvalidPasswordChangedArgument
/**
 * Event arguments of asnCtiNotifyLineInvalidPasswordChanged.
 *
 * @private
 */
export class AsnCtiNotifyLineInvalidPasswordChangedArgument {
	public constructor(that: AsnCtiNotifyLineInvalidPasswordChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineInvalidPasswordChangedArgument {
		return new AsnCtiNotifyLineInvalidPasswordChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			invalidPasswordState: 0,
			u8sUserName: ""
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t */
	public u8sLinePhoneNumber!: string;
	/** Indicates that the current password on the line is invalid (1) or is valid (0). */
	public invalidPasswordState!: number;
	/** The corresponding user name. */
	public u8sUserName!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyMonitorStoppedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyMonitorStoppedArgument
/**
 * Event arguments of asnCtiNotifyMonitorStopped
 */
export class AsnCtiNotifyMonitorStoppedArgument {
	public constructor(that: AsnCtiNotifyMonitorStoppedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyMonitorStoppedArgument {
		return new AsnCtiNotifyMonitorStoppedArgument({
			u8sMonitorCrossRefID: ""
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineSortOrderChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineSortOrderChangedArgument
/**
 * Event arguments of asnCtiNotifyLineSortOrderChanged
 */
export class AsnCtiNotifyLineSortOrderChangedArgument {
	public constructor(that: AsnCtiNotifyLineSortOrderChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineSortOrderChangedArgument {
		return new AsnCtiNotifyLineSortOrderChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sLineURISortOrder: new ENetUC_Common.UTF8StringList()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Line sort order based on the user configuration, CTI\/SIP LineURIs, AV LineURI, additional lines (not server configured line uris like mobile lines) */
	public u8sLineURISortOrder!: ENetUC_Common.UTF8StringList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineForwardingChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineForwardingChangedArgument
/**
 * Event arguments of asnCtiNotifyLineForwardingChanged
 */
export class AsnCtiNotifyLineForwardingChangedArgument {
	public constructor(that: AsnCtiNotifyLineForwardingChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineForwardingChangedArgument {
		return new AsnCtiNotifyLineForwardingChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			seqLineForwards: new ENetUC_Common_SIPCTI.AsnLineForwards()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t */
	public u8sLinePhoneNumber!: string;
	/** New forwarding settings on the line. */
	public seqLineForwards!: ENetUC_Common_SIPCTI.AsnLineForwards;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyMakeCallResultArgument
// [PrintTSSeqDefCode] AsnCtiNotifyMakeCallResultArgument
/**
 * Event arguments of asnCtiNotifyMakeCallResult
 */
export class AsnCtiNotifyMakeCallResultArgument {
	public constructor(that: AsnCtiNotifyMakeCallResultArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyMakeCallResultArgument {
		return new AsnCtiNotifyMakeCallResultArgument({
			u8sLinePhoneNumber: "",
			u8sConnectionID: "",
			success: 0
		});
	}

	/** Line identifier on which the make call was performed. */
	public u8sLinePhoneNumber!: string;
	/** Unique identifier of the call (ConnectionID). */
	public u8sConnectionID!: string;
	/** If make call was successfully executed on TAPI, this is (1), (0) otherwise. */
	public success!: number;
	/** No optionals yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiCallFeature
// [PrintTSSeqDefCode] AsnCtiCallFeature
/**
 * Helper to combine ConnectionID and call features of a call. See asnCtiNotifyLineCallFeaturesChanged.
 */
export class AsnCtiCallFeature {
	public constructor(that: AsnCtiCallFeature) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiCallFeature {
		return new AsnCtiCallFeature({
			u8sConnectionID: "",
			iFeatures: 0
		});
	}

	/** Call identifier of the call which features have changed. */
	public u8sConnectionID!: string;
	/** Bit field of the current features of the call. */
	public iFeatures!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineProjectSettingsChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineProjectSettingsChangedArgument
/**
 * Event arguments for asnCtiNotifyLineProjectSettingsChanged
 */
export class AsnCtiNotifyLineProjectSettingsChangedArgument {
	public constructor(that: AsnCtiNotifyLineProjectSettingsChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineProjectSettingsChangedArgument {
		return new AsnCtiNotifyLineProjectSettingsChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			u8sLocationID: "",
			projectList: new ENetUC_Common_SIPCTI.AsnProjectPinRuleList()
		});
	}

	/** CrossRefID the client got from the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t\t */
	public u8sLinePhoneNumber!: string;
	/** The identifier for the current line location settings. */
	public u8sLocationID!: string;
	/** The current list of project settings of the line. */
	public projectList!: ENetUC_Common_SIPCTI.AsnProjectPinRuleList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiMakeCallArgument
// [PrintTSSeqDefCode] AsnCtiMakeCallArgument
/**
 * Arguments for asnCtiMakeCall
 */
export class AsnCtiMakeCallArgument {
	public constructor(that: AsnCtiMakeCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiMakeCallArgument {
		return new AsnCtiMakeCallArgument({
			u8sPhoneNumberFrom: "",
			u8sPhoneNumberTo: "",
			iNumberFormat: 0
		});
	}

	/** Phone number (supercanonic notation, starts with '+', e.g. '+49815136856177') or line URI of the line where the call will be initiated. */
	public u8sPhoneNumberFrom!: string;
	/** Number to dial. */
	public u8sPhoneNumberTo!: string;
	/** The u8sPhoneNumberTo is the number to dial and normally the server would reformat the number into a dialable form. This behavior can be altered: */
	public iNumberFormat!: number;
	/** Allows to set additional information (Project\/Pin rules, hide caller id, set remote contact details etc.). These information are completely optional and in the most cases you can omit this parameter. */
	public newCallParams?: ENetUC_Common_SIPCTI.AsnCtiNewCallParams;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiMakeCallResult
// [PrintTSSeqDefCode] AsnCtiMakeCallResult
/**
 * Result of asnCtiMakeCall.
 * Remark: The result will be sent if asnCtiMakeCall was successfully accepted by the server. It does NOT reflect, if the call was successfully made on the PBX! The result is sent as asnCtiNotifyMakeCallResult event asynchronously.
 */
export class AsnCtiMakeCallResult {
	public constructor(that: AsnCtiMakeCallResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiMakeCallResult {
		return new AsnCtiMakeCallResult({
			u8sConnectionID: ""
		});
	}

	/** Connection identifier of the call */
	public u8sConnectionID!: string;
	/** Additional parameters for asnCtiSetCallData added here for backward compatibility. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineSetForwardStateArgument
// [PrintTSSeqDefCode] AsnCtiLineSetForwardStateArgument
/**
 * Arguments for asnCtiLineSetForwardState
 *
 * @private
 */
export class AsnCtiLineSetForwardStateArgument {
	public constructor(that: AsnCtiLineSetForwardStateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineSetForwardStateArgument {
		return new AsnCtiLineSetForwardStateArgument({
			u8sLinePhoneNumber: "",
			seqLineForwards: new ENetUC_Common_SIPCTI.AsnLineForwards()
		});
	}

	/** Line identifier of the line to set forwards on. */
	public u8sLinePhoneNumber!: string;
	/** New line forward state to set */
	public seqLineForwards!: ENetUC_Common_SIPCTI.AsnLineForwards;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineSetForwardStateResult
// [PrintTSSeqDefCode] AsnCtiLineSetForwardStateResult
/**
 * Result of asnCtiLineSetForwardState
 * If an error occured, AsnRequestError will be set.
 *
 * @private
 */
export class AsnCtiLineSetForwardStateResult {
	public constructor(that?: AsnCtiLineSetForwardStateResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineSetForwardStateResult {
		return new AsnCtiLineSetForwardStateResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiDropCallArgument
// [PrintTSSeqDefCode] AsnCtiDropCallArgument
/**
 * Arguments for asnCtiDropCall.
 */
export class AsnCtiDropCallArgument {
	public constructor(that: AsnCtiDropCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiDropCallArgument {
		return new AsnCtiDropCallArgument({
			u8sConnectionID: ""
		});
	}

	/** The connection identifier of the call to drop. */
	public u8sConnectionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiDropCallResult
// [PrintTSSeqDefCode] AsnCtiDropCallResult
/**
 * Result of asnCtiDropCall.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiDropCallResult {
	public constructor(that: AsnCtiDropCallResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiDropCallResult {
		return new AsnCtiDropCallResult({
			bSuccess: false
		});
	}

	/** true\/1 if the call was dropped, false\/0 otherwise. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineDropCallArgument
// [PrintTSSeqDefCode] AsnCtiLineDropCallArgument
/**
 * Arguments for asnCtiLineDropCall.
 */
export class AsnCtiLineDropCallArgument {
	public constructor(that: AsnCtiLineDropCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineDropCallArgument {
		return new AsnCtiLineDropCallArgument({
			u8sLineDescriptor: ""
		});
	}

	/** The line number (e.g. +498151368562177) or the line URI of the line. */
	public u8sLineDescriptor!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineDropCallResult
// [PrintTSSeqDefCode] AsnCtiLineDropCallResult
/**
 * Result of asnCtiLineDropCall.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiLineDropCallResult {
	public constructor(that: AsnCtiLineDropCallResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineDropCallResult {
		return new AsnCtiLineDropCallResult({
			bSuccess: false
		});
	}

	/** true\/1 if the call was dropped, false\/0 otherwise. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiAnswerCallArgument
// [PrintTSSeqDefCode] AsnCtiAnswerCallArgument
/**
 * Arguments for asnCtiAnswerCall
 */
export class AsnCtiAnswerCallArgument {
	public constructor(that: AsnCtiAnswerCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiAnswerCallArgument {
		return new AsnCtiAnswerCallArgument({
			u8sConnectionID: ""
		});
	}

	/** Call identifier of the call to accept. */
	public u8sConnectionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiAnswerCallResult
// [PrintTSSeqDefCode] AsnCtiAnswerCallResult
/**
 * Result of asnCtiAnswerCall.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiAnswerCallResult {
	public constructor(that: AsnCtiAnswerCallResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiAnswerCallResult {
		return new AsnCtiAnswerCallResult({
			bSuccess: false
		});
	}

	/** true\/1 if the call was accepted, false\/0 otherwise. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiRedirectCallArgument
// [PrintTSSeqDefCode] AsnCtiRedirectCallArgument
/**
 * Arguments for asnCtiRedirectCall.
 */
export class AsnCtiRedirectCallArgument {
	public constructor(that: AsnCtiRedirectCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiRedirectCallArgument {
		return new AsnCtiRedirectCallArgument({
			u8sContactID: "",
			u8sConnectionID: "",
			u8sPhoneNumber: "",
			iNumberFormat: 0
		});
	}

	/** ContactID of the contact to redirect a call from his line. Leave it empty if you just want to redirect a call by its identifier. */
	public u8sContactID!: string;
	/** Connection identifier of the call to redirect. */
	public u8sConnectionID!: string;
	/** The line number to redirect to. This number can be in short or long format if you set iNumberFormat to 1. */
	public u8sPhoneNumber!: string;
	/** The u8sPhoneNumber is the number to redirect to and normally the server would reformat the number into a dialable form. This behavior can be altered: */
	public iNumberFormat!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiRedirectCallResult
// [PrintTSSeqDefCode] AsnCtiRedirectCallResult
/**
 * Result of asnCtiRedirectCall.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiRedirectCallResult {
	public constructor(that: AsnCtiRedirectCallResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiRedirectCallResult {
		return new AsnCtiRedirectCallResult({
			bSuccess: false
		});
	}

	/** true\/1 if the call was redirectd, false\/0 otherwise. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiPickupCallArgument
// [PrintTSSeqDefCode] AsnCtiPickupCallArgument
/**
 * Arguments for asnCtiPickupCall.
 */
export class AsnCtiPickupCallArgument {
	public constructor(that: AsnCtiPickupCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiPickupCallArgument {
		return new AsnCtiPickupCallArgument({
			u8sContactID: "",
			u8sConnectionID: "",
			u8sPhoneNumber: "",
			iNumberFormat: 0
		});
	}

	/** ContactID of the contact to pickup a call from his line. Leave it empty if you just want to pickup a call by its identifier. */
	public u8sContactID!: string;
	/** Connection identifier of the call to redirect. */
	public u8sConnectionID!: string;
	/** The line number to pickup to. This number can be in short or long format if you set iNumberFormat to 1. */
	public u8sPhoneNumber!: string;
	/** The u8sPhoneNumber is the number to pickup to and normally the server would reformat the number into a dialable form. This behavior can be altered: */
	public iNumberFormat!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiPickupCallResult
// [PrintTSSeqDefCode] AsnCtiPickupCallResult
/**
 * Result of asnCtiPickupCall
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiPickupCallResult {
	public constructor(that: AsnCtiPickupCallResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiPickupCallResult {
		return new AsnCtiPickupCallResult({
			bSuccess: false
		});
	}

	/** true\/1 if the call was picked, false\/0 otherwise. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiStartCallRecordingArgument
// [PrintTSSeqDefCode] AsnCtiStartCallRecordingArgument
/**
 * Arguments for asnCtiStartCallRecording.
 */
export class AsnCtiStartCallRecordingArgument {
	public constructor(that: AsnCtiStartCallRecordingArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiStartCallRecordingArgument {
		return new AsnCtiStartCallRecordingArgument({
			u8sConnectionID: ""
		});
	}

	/** Connection identifier of the call to record. */
	public u8sConnectionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiStartCallRecordingResult
// [PrintTSSeqDefCode] AsnCtiStartCallRecordingResult
/**
 * Result of asnCtiStartCallRecording
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiStartCallRecordingResult {
	public constructor(that: AsnCtiStartCallRecordingResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiStartCallRecordingResult {
		return new AsnCtiStartCallRecordingResult({
			bSuccess: false
		});
	}

	/** true\/1 if the record starts successful, false\/0 otherwise. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiStopCallRecordingArgument
// [PrintTSSeqDefCode] AsnCtiStopCallRecordingArgument
/**
 * Arguments for asnCtiStopCallRecording.
 */
export class AsnCtiStopCallRecordingArgument {
	public constructor(that: AsnCtiStopCallRecordingArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiStopCallRecordingArgument {
		return new AsnCtiStopCallRecordingArgument({
			u8sConnectionID: ""
		});
	}

	/** Connection identifier of the call to stop recording on. */
	public u8sConnectionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiStopCallRecordingResult
// [PrintTSSeqDefCode] AsnCtiStopCallRecordingResult
/**
 * Result of asnCtiStopCallRecording.
 * If there was no error executing this operation, the result will be returned; otherwise AsnRequestError is set.
 */
export class AsnCtiStopCallRecordingResult {
	public constructor(that?: AsnCtiStopCallRecordingResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiStopCallRecordingResult {
		return new AsnCtiStopCallRecordingResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSetCallDataResult
// [PrintTSSeqDefCode] AsnCtiSetCallDataResult
/**
 * Ergebnis fÃ¼r asnCtiSetCallData
 */
export class AsnCtiSetCallDataResult {
	public constructor(that: AsnCtiSetCallDataResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSetCallDataResult {
		return new AsnCtiSetCallDataResult({
			bSuccess: false
		});
	}

	/** Data was accepted */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiMergeCallsArgument
// [PrintTSSeqDefCode] AsnCtiMergeCallsArgument
/**
 * Arguments for asnCtiMergeCalls.
 */
export class AsnCtiMergeCallsArgument {
	public constructor(that: AsnCtiMergeCallsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiMergeCallsArgument {
		return new AsnCtiMergeCallsArgument({
			u8sConnectionID1: "",
			u8sConnectionID2: ""
		});
	}

	/** Connection identifier of the first call to merge. */
	public u8sConnectionID1!: string;
	/** Connection identifier of the second call to merge. */
	public u8sConnectionID2!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiMergeCallsResult
// [PrintTSSeqDefCode] AsnCtiMergeCallsResult
/**
 * Result of asnCtiMergeCalls.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiMergeCallsResult {
	public constructor(that: AsnCtiMergeCallsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiMergeCallsResult {
		return new AsnCtiMergeCallsResult({
			bSuccess: false
		});
	}

	/** true\/1 if the merge was successful, false\/0 otherwise. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSwapHoldArgument
// [PrintTSSeqDefCode] AsnCtiSwapHoldArgument
/**
 * Arguments for asnCtiSwapHold.
 */
export class AsnCtiSwapHoldArgument {
	public constructor(that: AsnCtiSwapHoldArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSwapHoldArgument {
		return new AsnCtiSwapHoldArgument({
			u8sConnectionID: ""
		});
	}

	/** Connection identifier of the held call. */
	public u8sConnectionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSwapHoldResult
// [PrintTSSeqDefCode] AsnCtiSwapHoldResult
/**
 * Result of asnCtiSwapHold.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiSwapHoldResult {
	public constructor(that: AsnCtiSwapHoldResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSwapHoldResult {
		return new AsnCtiSwapHoldResult({
			bSuccess: false
		});
	}

	/** true\/1 if swap was successful, false\/0 otherwise. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiHoldArgument
// [PrintTSSeqDefCode] AsnCtiHoldArgument
/**
 * Arguments for asnCtiHold.
 */
export class AsnCtiHoldArgument {
	public constructor(that: AsnCtiHoldArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiHoldArgument {
		return new AsnCtiHoldArgument({
			bHold: false,
			u8sConnectionID: ""
		});
	}

	/** true\/1 if the call shall be set on hold, false\/0 otherwise. */
	public bHold!: boolean;
	/** Connection identifier of the call to change hold state. */
	public u8sConnectionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiHoldResult
// [PrintTSSeqDefCode] AsnCtiHoldResult
/**
 * Result of asnCtiHold.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiHoldResult {
	public constructor(that: AsnCtiHoldResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiHoldResult {
		return new AsnCtiHoldResult({
			bSuccess: false
		});
	}

	/** true\/1 if execution was successful, false\/0 otherwise. If an error occured, AsnRequestError will be set. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSetForwardArgument
// [PrintTSSeqDefCode] AsnCtiSetForwardArgument
/**
 * Arguments for asnCtiSetForward.
 */
export class AsnCtiSetForwardArgument {
	public constructor(that: AsnCtiSetForwardArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSetForwardArgument {
		return new AsnCtiSetForwardArgument({
			u8sPhoneNumberFrom: "",
			u8sPhoneNumberTo: ""
		});
	}

	/** Number of the line to forward from. Format should be like '+49815136856177' or it can be a line URI. */
	public u8sPhoneNumberFrom!: string;
	/** Number of the line to forward to. Format must be long format, like '+49815136856177'. */
	public u8sPhoneNumberTo!: string;
	/** Additional parameter added for backward compatibility here. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSetForwardResult
// [PrintTSSeqDefCode] AsnCtiSetForwardResult
/**
 * Result of asnCtiSetForward.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiSetForwardResult {
	public constructor(that: AsnCtiSetForwardResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSetForwardResult {
		return new AsnCtiSetForwardResult({
			bSuccess: false,
			bExistingForwardingChanged: false
		});
	}

	/** true\/1 if the forward is set, false\/0 otherwise. */
	public bSuccess!: boolean;
	/** true\/1 if this is the very first forward or if your PBX does not support multiple forwards. A preexisting forward was replaced in this case. false\/0 if your forward is added to the list of forwards. */
	public bExistingForwardingChanged!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiRemoveForwardArgument
// [PrintTSSeqDefCode] AsnCtiRemoveForwardArgument
/**
 * Arguments for asnCtiRemoveForward.
 */
export class AsnCtiRemoveForwardArgument {
	public constructor(that: AsnCtiRemoveForwardArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiRemoveForwardArgument {
		return new AsnCtiRemoveForwardArgument({
			u8sPhoneNumberFrom: ""
		});
	}

	/** Number of the line to remove the forward from. Format should be like '+49815136856177' or it can be a line URI. */
	public u8sPhoneNumberFrom!: string;
	/** Additional parameters added for backward compatibility here. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiRemoveForwardResult
// [PrintTSSeqDefCode] AsnCtiRemoveForwardResult
/**
 * Result of asnCtiRemoveForward
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiRemoveForwardResult {
	public constructor(that: AsnCtiRemoveForwardResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiRemoveForwardResult {
		return new AsnCtiRemoveForwardResult({
			bSuccess: false
		});
	}

	/** true\/1 if the forward(s) has been removed, false\/0 otherwise. If an error occured  AsnRequestError will be set. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiDialDigitsArgument
// [PrintTSSeqDefCode] AsnCtiDialDigitsArgument
/**
 * Arguments for asnCtiDialDigits.
 */
export class AsnCtiDialDigitsArgument {
	public constructor(that: AsnCtiDialDigitsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiDialDigitsArgument {
		return new AsnCtiDialDigitsArgument({
			u8sConnectionID: "",
			iDigitMode: 0,
			iDigitDuration: 0,
			u8sDigitsToDial: ""
		});
	}

	public u8sConnectionID!: string;
	/** Dialing mode for the digits. */
	public iDigitMode!: number;
	/** Both the duration in milliseconds of DTMF digits and pulse and DTMF inter-digit spacing. A value of 0 uses a default value. */
	public iDigitDuration!: number;
	/** The digits to dial. See EnumCtiDigitMode for valid characters. */
	public u8sDigitsToDial!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiDialDigitsResult
// [PrintTSSeqDefCode] AsnCtiDialDigitsResult
/**
 * Result of asnCtiDialDigits.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiDialDigitsResult {
	public constructor(that: AsnCtiDialDigitsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiDialDigitsResult {
		return new AsnCtiDialDigitsResult({
			bSuccess: false
		});
	}

	/** true\/1 if operation was successful, false\/0 otherwise. If an error occured AsnRequestError will be set. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiCompleteCallArgument
// [PrintTSSeqDefCode] AsnCtiCompleteCallArgument
/**
 * Arguments for asnCtiCompleteCall.
 */
export class AsnCtiCompleteCallArgument {
	public constructor(that: AsnCtiCompleteCallArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiCompleteCallArgument {
		return new AsnCtiCompleteCallArgument({
			u8sConnectionID: ""
		});
	}

	/** Connection identifier of the call to be completed automatically. */
	public u8sConnectionID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiCompleteCallResult
// [PrintTSSeqDefCode] AsnCtiCompleteCallResult
/**
 * Result of asnCtiCompleteCall.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiCompleteCallResult {
	public constructor(that: AsnCtiCompleteCallResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiCompleteCallResult {
		return new AsnCtiCompleteCallResult({
			bSuccess: false
		});
	}

	/** true\/1 if the call completion was set successful, false\/0 otherwise. */
	public bSuccess!: boolean;
	/** No optionals yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiConferenceArgument
// [PrintTSSeqDefCode] AsnCtiConferenceArgument
/**
 * Arguments for asnCtiConference.
 */
export class AsnCtiConferenceArgument {
	public constructor(that: AsnCtiConferenceArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiConferenceArgument {
		return new AsnCtiConferenceArgument({
			u8sConnectionID1: "",
			u8sConnectionID2: ""
		});
	}

	/** Connection identifier of the active call. */
	public u8sConnectionID1!: string;
	/** Connection identifier of the held call. */
	public u8sConnectionID2!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiConferenceResult
// [PrintTSSeqDefCode] AsnCtiConferenceResult
/**
 * Result of asnCtiConference.
 * If an error occured, AsnRequestError will be set.
 */
export class AsnCtiConferenceResult {
	public constructor(that: AsnCtiConferenceResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiConferenceResult {
		return new AsnCtiConferenceResult({
			bSuccess: false
		});
	}

	/** true\/1 if the conference was initiated successful, false\/0 otherwise. */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSetupConferenceArgument
// [PrintTSSeqDefCode] AsnCtiSetupConferenceArgument
/**
 * Arguments for asnCtiSetupConference.
 *
 * @private
 */
export class AsnCtiSetupConferenceArgument {
	public constructor(that: AsnCtiSetupConferenceArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSetupConferenceArgument {
		return new AsnCtiSetupConferenceArgument({
			u8sConnectionID: "",
			u8sPhoneNumberTo: "",
			iNumberFormat: 0
		});
	}

	/** Connection identifier of the active call. */
	public u8sConnectionID!: string;
	/** Number to dial. */
	public u8sPhoneNumberTo!: string;
	/** The u8sPhoneNumberTo is the number to dial and normally the server would reformat the number into a dialable form. This behavior can be altered: */
	public iNumberFormat!: number;
	/** Allows to set additional information (Project\/Pin rules, hide caller id, set remote contact details etc.). These information are completely optional and in the most cases you can omit this parameter. */
	public newCallParams?: ENetUC_Common_SIPCTI.AsnCtiNewCallParams;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSetupConferenceResult
// [PrintTSSeqDefCode] AsnCtiSetupConferenceResult
/**
 * Result of asnCtiSetupConference.
 * If an error occured, AsnRequestError will be set.
 *
 * @private
 */
export class AsnCtiSetupConferenceResult {
	public constructor(that: AsnCtiSetupConferenceResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSetupConferenceResult {
		return new AsnCtiSetupConferenceResult({
			bSuccess: false
		});
	}

	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSetActiveLineAddressArgument
// [PrintTSSeqDefCode] AsnCtiSetActiveLineAddressArgument
/**
 * Arguments for asnCtiSetActiveLineAddress.
 *
 * @private
 */
export class AsnCtiSetActiveLineAddressArgument {
	public constructor(that: AsnCtiSetActiveLineAddressArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSetActiveLineAddressArgument {
		return new AsnCtiSetActiveLineAddressArgument({
			u8sLinePhoneNumber: "",
			iAddressID: 0
		});
	}

	/** Identifier of the line */
	public u8sLinePhoneNumber!: string;
	/** Address ID */
	public iAddressID!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSetActiveLineAddressResult
// [PrintTSSeqDefCode] AsnCtiSetActiveLineAddressResult
/**
 * Result of asnCtiSetActiveLineAddress.
 * If an error occured, AsnRequestError will be set.
 *
 * @private
 */
export class AsnCtiSetActiveLineAddressResult {
	public constructor(that: AsnCtiSetActiveLineAddressResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSetActiveLineAddressResult {
		return new AsnCtiSetActiveLineAddressResult({
			bSuccess: false
		});
	}

	/** (1) if successfull, (0) on error */
	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineActiveAddressChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineActiveAddressChangedArgument
/**
 * Event arguments of asnCtiNotifyLineActiveAddressChanged
 *
 * @private
 */
export class AsnCtiNotifyLineActiveAddressChangedArgument {
	public constructor(that: AsnCtiNotifyLineActiveAddressChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineActiveAddressChangedArgument {
		return new AsnCtiNotifyLineActiveAddressChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			iAddressID: 0
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Identifier of the line */
	public u8sLinePhoneNumber!: string;
	/** New Address ID */
	public iAddressID!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnMobileRegisterDeviceArgument
// [PrintTSSeqDefCode] AsnMobileRegisterDeviceArgument
/**
 * Arguments for asnMobileRegisterDevice.
 *
 * @private
 */
export class AsnMobileRegisterDeviceArgument {
	public constructor(that: AsnMobileRegisterDeviceArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMobileRegisterDeviceArgument {
		return new AsnMobileRegisterDeviceArgument({
			iRegisterType: 0,
			u8sDeviceIMEI: "",
			u8sUserIMSI: "",
			u8sDevicePhoneNumber: "",
			u8sGroupName: "",
			u8sNetworkAvailable: "",
			iLineFeatures: 0,
			iForwardFeaturesPBX: 0
		});
	}

	/** Was ist das fÃ¼r ne message (0 anmeldung, 1 update der daten) */
	public iRegisterType!: number;
	/** SerialNumber of the Device */
	public u8sDeviceIMEI!: string;
	/** Benutzer Identifikationsnummer (land, operator, subscriber) nicht die Telefonnummer */
	public u8sUserIMSI!: string;
	/** Rufnummer */
	public u8sDevicePhoneNumber!: string;
	/** Leitungsgruppe */
	public u8sGroupName!: string;
	/** verfÃ¼gbares netzwerk am device gprs umts wlan pc */
	public u8sNetworkAvailable!: string;
	/** Features der Leitung (makecall, donotdisturb) */
	public iLineFeatures!: number;
	/** Rufumleitungs Features */
	public iForwardFeaturesPBX!: number;
	/** Falls die MonitorCrossRefID mitgegeben wird, ist der Monitor gestartet */
	public u8sMonitorCrossRefID?: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnMobileRegisterDeviceResult
// [PrintTSSeqDefCode] AsnMobileRegisterDeviceResult
/**
 * Result of asnMobileRegisterDevice.
 *
 * @private
 */
export class AsnMobileRegisterDeviceResult {
	public constructor(that?: AsnMobileRegisterDeviceResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnMobileRegisterDeviceResult {
		return new AsnMobileRegisterDeviceResult();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiCallDataList
// [PrintTSSetOfDefCode] AsnCtiCallDataList
export class AsnCtiCallDataList extends Array<AsnCtiCallData> { }

// [PrintTSTypeDefCode] AsnCtiLineInfoList
// [PrintTSSetOfDefCode] AsnCtiLineInfoList
export class AsnCtiLineInfoList extends Array<AsnCtiLineInfo> { }

// [PrintTSTypeDefCode] AsnCtiCallInformation
// [PrintTSSeqDefCode] AsnCtiCallInformation
/**
 * In depth information about a call
 */
export class AsnCtiCallInformation {
	public constructor(that: AsnCtiCallInformation) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiCallInformation {
		return new AsnCtiCallInformation({
			u8sLinePhoneNumber: "",
			u8sConnectionID: "",
			iCallState: 0,
			iCallType: 0,
			iDisconnectedReason: 0,
			iFeatures: 0,
			bOutbound: false,
			bInternal: false,
			bCallerIDBlocked: false,
			iCallRecordingState: 0,
			bConferenceCtlr: false,
			u8sConferenceCtlrID: "",
			u8sLocalNumberSC: "",
			u8sRemoteNumberSC: "",
			iRemoteContactSource: 0,
			projectPinRule: ENetUC_Common_SIPCTI.AsnProjectPinRule.initEmpty(),
			u8sNote: "",
			u8sTrackerID: "",
			utcCallCreated: new Date(),
			utcCallConnected: new Date(),
			utcCallDisconnected: new Date(),
			bIsUnknownHoldContact: false
		});
	}

	/** Contains the line identifier of the line the call is attached to. */
	public u8sLinePhoneNumber!: string;
	/** A GUID of the connection the call is assigned to. This is used to identify the call throughout the API (Journal, Search etc.). */
	public u8sConnectionID!: string;
	/** The call state described in EnumCallState. */
	public iCallState!: number;
	/** Type of the call described in EnumCallType (normal, consult, callback etc.). */
	public iCallType!: number;
	/** Disconnect reason described in EnumCallDisconnectedReason. */
	public iDisconnectedReason!: number;
	/** The call features available, described in EnumCallFeatures. These features are dynamic and will change with the call state. */
	public iFeatures!: number;
	/** The call is outbound (1, true) or inbound (0, false) */
	public bOutbound!: boolean;
	/** The call is an internal call (1, true) or an external call (0, false). */
	public bInternal!: boolean;
	/** The caller id is blocked on this call. */
	public bCallerIDBlocked!: boolean;
	/** The recording state of this call described in EnumCallRecordingState. */
	public iCallRecordingState!: number;
	/** If this is true, this call is the conference controller call */
	public bConferenceCtlr!: boolean;
	/** If the call is part of a conference, this field contains the conference controller ID */
	public u8sConferenceCtlrID!: string;
	/** Number of local line */
	public u8sLocalNumberSC!: string;
	/** Number of remote party */
	public u8sRemoteNumberSC!: string;
	/** The contact data source as described in EnumRemoteContactSource. Only valid if the optional field pRemoteContact is set. */
	public iRemoteContactSource!: number;
	/** Description of the current project\/pin rules applied to the call */
	public projectPinRule!: ENetUC_Common_SIPCTI.AsnProjectPinRule;
	/** Free text field (memo) for the call */
	public u8sNote!: string;
	/** The ID of the CallTracker, this call is part of. CallTracker gathers all calls which belong to the same call scenario. (e.g. All calls in a group call carry the same TrackerID) */
	public u8sTrackerID!: string;
	/** Creation time stamp of the call. */
	public utcCallCreated!: Date;
	/** Time stamp of the call when it is connected. (0 if it was never connected) */
	public utcCallConnected!: Date;
	/** Time stamp of the call when it is disconnected. (0 if the call is not yet discsonnected) */
	public utcCallDisconnected!: Date;
	/** This is true, if a hold contact has no known number. */
	public bIsUnknownHoldContact!: boolean;
	/** The contact data of the owner of the call. */
	public pLocalContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** If it is available, this contains the contact data of the remote party. */
	public pRemoteContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** If there is a call on hold, this contains its number (if the number is unknown bIsUnknownHoldContact is set) */
	public pu8sHoldNumberSC?: string;
	/** If there is a call on hold and if data is available, this contains contact data of the contact on hold. */
	public pHoldContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** If the call was redirected, this number indicate who has redirected it. */
	public pu8sRedirectingNumber?: string;
	/** If available and applicable, this contains the contact data of the redirecting party. */
	public pRedirectingContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** Additional information which were added later (for backward compatibility): */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
	/** If available this is the list of key\/value pairs with additional information provided by clients for special use cases (so called CallData). */
	public callDataList?: AsnCtiCallDataList;
}

// [PrintTSTypeDefCode] AsnCtiCallInformationList
// [PrintTSSetOfDefCode] AsnCtiCallInformationList
export class AsnCtiCallInformationList extends Array<AsnCtiCallInformation> { }

// [PrintTSTypeDefCode] AsnCtiEnumPhoneLinesArgument
// [PrintTSSeqDefCode] AsnCtiEnumPhoneLinesArgument
/**
 * Arguments for asnCtiEnumPhoneLines.
 */
export class AsnCtiEnumPhoneLinesArgument {
	public constructor(that: AsnCtiEnumPhoneLinesArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiEnumPhoneLinesArgument {
		return new AsnCtiEnumPhoneLinesArgument({
			typeofLines: AsnCtiEnumPhoneLinesArgumentEnum.userOwnLines,
			attachChangeNotify: 0
		});
	}

	/** Which lines shall be listed for the user (own, additional lines, all). */
	public typeofLines!: AsnCtiEnumPhoneLinesArgumentEnum;
	/** Request events for line add\/remove changes (1) or not (0). */
	public attachChangeNotify!: number;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiEnumPhoneLinesResult
// [PrintTSSeqDefCode] AsnCtiEnumPhoneLinesResult
/**
 * If successful this result will be returned by asnCtiEnumPhoneLines.
 */
export class AsnCtiEnumPhoneLinesResult {
	public constructor(that: AsnCtiEnumPhoneLinesResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiEnumPhoneLinesResult {
		return new AsnCtiEnumPhoneLinesResult({
			seqLines: new AsnCtiLineInfoList()
		});
	}

	/** The list with line information requested. */
	public seqLines!: AsnCtiLineInfoList;
	/** LineMonitorCrossrefID for add\/remove event from the server. */
	public u8sLineMonitorCrossRefID?: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSnapshotPhoneLineResult
// [PrintTSSeqDefCode] AsnCtiSnapshotPhoneLineResult
/**
 * If asnCtiSnapshotPhoneLine is executed successful, AsnCtiSnapshotPhoneLineResult will be returned.
 */
export class AsnCtiSnapshotPhoneLineResult {
	public constructor(that: AsnCtiSnapshotPhoneLineResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSnapshotPhoneLineResult {
		return new AsnCtiSnapshotPhoneLineResult({
			lineInfo: AsnCtiLineInfo.initEmpty(),
			seqCalls: new AsnCtiCallInformationList(),
			iDoNotDisturb: 0,
			seqLineForwards: new ENetUC_Common_SIPCTI.AsnLineForwards(),
			remoteOfficeState: AsnRemoteOfficeState.initEmpty()
		});
	}

	/** Current line information */
	public lineInfo!: AsnCtiLineInfo;
	/** Current calls on the line */
	public seqCalls!: AsnCtiCallInformationList;
	/** Do Not disturb is set (1) oder not (0) */
	public iDoNotDisturb!: number;
	/** Current list of forwardings set on the line */
	public seqLineForwards!: ENetUC_Common_SIPCTI.AsnLineForwards;
	/** Current state of remote office feature */
	public remoteOfficeState!: AsnRemoteOfficeState;
	/** Optionals */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineMonitorStartResult
// [PrintTSSeqDefCode] AsnCtiLineMonitorStartResult
/**
 * If asnCtiLineMonitorStart is executed successful, AsnCtiLineMonitorStartResult will be returned.
 */
export class AsnCtiLineMonitorStartResult {
	public constructor(that: AsnCtiLineMonitorStartResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineMonitorStartResult {
		return new AsnCtiLineMonitorStartResult({
			u8sMonitorCrossRefID: "",
			lineInfo: AsnCtiLineInfo.initEmpty(),
			seqCalls: new AsnCtiCallInformationList(),
			iDoNotDisturb: 0,
			seqLineForwards: new ENetUC_Common_SIPCTI.AsnLineForwards(),
			remoteOfficeState: AsnRemoteOfficeState.initEmpty()
		});
	}

	/** CrossrefID to identify the corresponding events after calling asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Current line state. */
	public lineInfo!: AsnCtiLineInfo;
	/** Current calls on the line. */
	public seqCalls!: AsnCtiCallInformationList;
	/** Current state of DoNotDisturb is set (1) or not (0). */
	public iDoNotDisturb!: number;
	/** Current list of forwarding settings on the line. */
	public seqLineForwards!: ENetUC_Common_SIPCTI.AsnLineForwards;
	/** Current state of remote office feature. */
	public remoteOfficeState!: AsnRemoteOfficeState;
	/** Optionals */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiLineSetRemoteOfficeArgument
// [PrintTSSeqDefCode] AsnCtiLineSetRemoteOfficeArgument
/**
 * Argument for asnCtiLineSetRemoteOffice
 */
export class AsnCtiLineSetRemoteOfficeArgument {
	public constructor(that: AsnCtiLineSetRemoteOfficeArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiLineSetRemoteOfficeArgument {
		return new AsnCtiLineSetRemoteOfficeArgument({
			u8sLinePhoneNumber: "",
			remoteOfficeState: AsnRemoteOfficeState.initEmpty()
		});
	}

	/** Identifier of the line */
	public u8sLinePhoneNumber!: string;
	/** The state to set. See AsnRemoteOfficeState for more details. */
	public remoteOfficeState!: AsnRemoteOfficeState;
	/** No optionals yet. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineInfoChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineInfoChangedArgument
/**
 * Event arguments of asnCtiNotifyLineInfoChanged.
 */
export class AsnCtiNotifyLineInfoChangedArgument {
	public constructor(that: AsnCtiNotifyLineInfoChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineInfoChangedArgument {
		return new AsnCtiNotifyLineInfoChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			lineInfo: AsnCtiLineInfo.initEmpty()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t */
	public u8sLinePhoneNumber!: string;
	/** The new line information. */
	public lineInfo!: AsnCtiLineInfo;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineRemoteOfficeChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineRemoteOfficeChangedArgument
/**
 * Event arguments of asnCtiNotifyLineRemoteOfficeChanged
 */
export class AsnCtiNotifyLineRemoteOfficeChangedArgument {
	public constructor(that: AsnCtiNotifyLineRemoteOfficeChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineRemoteOfficeChangedArgument {
		return new AsnCtiNotifyLineRemoteOfficeChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			remoteOfficeState: AsnRemoteOfficeState.initEmpty()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t */
	public u8sLinePhoneNumber!: string;
	/** The new remote office state of the line. */
	public remoteOfficeState!: AsnRemoteOfficeState;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineCallInformationChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineCallInformationChangedArgument
/**
 * Event arguments of asnCtiNotifyLineCallInformationChanged.
 */
export class AsnCtiNotifyLineCallInformationChangedArgument {
	public constructor(that: AsnCtiNotifyLineCallInformationChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineCallInformationChangedArgument {
		return new AsnCtiNotifyLineCallInformationChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			reason: 0,
			callinformation: AsnCtiCallInformation.initEmpty()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened. */
	public u8sLinePhoneNumber!: string;
	/** Reason for the event. See AsnCtiCallInfoChangedReason fro details. */
	public reason!: number;
	/** (Possible) reduced version a call information according to the reason. */
	public callinformation!: AsnCtiCallInformation;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyLineAddRemoveArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineAddRemoveArgument
/**
 * Event arguments of asnCtiNotifyLineAddRemove.
 */
export class AsnCtiNotifyLineAddRemoveArgument {
	public constructor(that: AsnCtiNotifyLineAddRemoveArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineAddRemoveArgument {
		return new AsnCtiNotifyLineAddRemoveArgument({
			u8sLineMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			addremove: AsnCtiNotifyLineAddRemoveArgumentEnum.added,
			lineInfo: AsnCtiLineInfo.initEmpty()
		});
	}

	/** CrossRefID the client got from the corresponding asnCtiLineMonitorStart. */
	public u8sLineMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t */
	public u8sLinePhoneNumber!: string;
	/** Line was added (0) or removed (1). */
	public addremove!: AsnCtiNotifyLineAddRemoveArgumentEnum;
	/** The current line information set. */
	public lineInfo!: AsnCtiLineInfo;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyConferenceCreatedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyConferenceCreatedArgument
/**
 * Event arguments of asnCtiNotifyConferenceCreated
 */
export class AsnCtiNotifyConferenceCreatedArgument {
	public constructor(that: AsnCtiNotifyConferenceCreatedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyConferenceCreatedArgument {
		return new AsnCtiNotifyConferenceCreatedArgument({
			u8sLineMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			conferenceControllerCallInfo: AsnCtiCallInformation.initEmpty(),
			conferenceMemberCallInfoList: new AsnCtiCallInformationList()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart */
	public u8sLineMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t */
	public u8sLinePhoneNumber!: string;
	/** Information about the conference controller call. See AsnCtiCallInformation for details. */
	public conferenceControllerCallInfo!: AsnCtiCallInformation;
	/** Information about the current participating calls in this conference. */
	public conferenceMemberCallInfoList!: AsnCtiCallInformationList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyConferenceDestroyedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyConferenceDestroyedArgument
/**
 * Event arguments of asnCtiNotifyConferenceDestroyed
 */
export class AsnCtiNotifyConferenceDestroyedArgument {
	public constructor(that: AsnCtiNotifyConferenceDestroyedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyConferenceDestroyedArgument {
		return new AsnCtiNotifyConferenceDestroyedArgument({
			u8sLineMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			conferenceControllerCallInfo: AsnCtiCallInformation.initEmpty(),
			conferenceMemberCallInfoList: new AsnCtiCallInformationList()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart */
	public u8sLineMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t */
	public u8sLinePhoneNumber!: string;
	/** The call information of the conference controller. */
	public conferenceControllerCallInfo!: AsnCtiCallInformation;
	/** The call information of the other participants. */
	public conferenceMemberCallInfoList!: AsnCtiCallInformationList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyConferencePartyAddedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyConferencePartyAddedArgument
/**
 * Event arguments of asnCtiNotifyConferencePartyAdded
 */
export class AsnCtiNotifyConferencePartyAddedArgument {
	public constructor(that: AsnCtiNotifyConferencePartyAddedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyConferencePartyAddedArgument {
		return new AsnCtiNotifyConferencePartyAddedArgument({
			u8sLineMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			conferenceControllerCallInfo: AsnCtiCallInformation.initEmpty(),
			conferenceMemberCallInfo: AsnCtiCallInformation.initEmpty()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sLineMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t\t */
	public u8sLinePhoneNumber!: string;
	/** Call information of the conference controller. */
	public conferenceControllerCallInfo!: AsnCtiCallInformation;
	/** Call information of the added new conference participant. */
	public conferenceMemberCallInfo!: AsnCtiCallInformation;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiNotifyConferencePartyRemovedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyConferencePartyRemovedArgument
/**
 * Event arguments of asnCtiNotifyConferencePartyRemoved
 */
export class AsnCtiNotifyConferencePartyRemovedArgument {
	public constructor(that: AsnCtiNotifyConferencePartyRemovedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyConferencePartyRemovedArgument {
		return new AsnCtiNotifyConferencePartyRemovedArgument({
			u8sLineMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			conferenceControllerCallInfo: AsnCtiCallInformation.initEmpty(),
			conferenceMemberCallInfo: AsnCtiCallInformation.initEmpty()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sLineMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t\t */
	public u8sLinePhoneNumber!: string;
	/** Call information of the conference controller. */
	public conferenceControllerCallInfo!: AsnCtiCallInformation;
	/** Call information of the removed conference participant. */
	public conferenceMemberCallInfo!: AsnCtiCallInformation;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiCallFeaturesList
// [PrintTSSetOfDefCode] AsnCtiCallFeaturesList
export class AsnCtiCallFeaturesList extends Array<AsnCtiCallFeature> { }

// [PrintTSTypeDefCode] AsnCtiNotifyLineCallFeaturesChangedArgument
// [PrintTSSeqDefCode] AsnCtiNotifyLineCallFeaturesChangedArgument
/**
 * Event arguments of asnCtiNotifyLineCallFeaturesChanged.
 */
export class AsnCtiNotifyLineCallFeaturesChangedArgument {
	public constructor(that: AsnCtiNotifyLineCallFeaturesChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNotifyLineCallFeaturesChangedArgument {
		return new AsnCtiNotifyLineCallFeaturesChangedArgument({
			u8sLineMonitorCrossRefID: "",
			u8sLinePhoneNumber: "",
			callFeaturesList: new AsnCtiCallFeaturesList()
		});
	}

	/** CrossrefID for the corresponding asnCtiLineMonitorStart. */
	public u8sLineMonitorCrossRefID!: string;
	/** Line identifier for the line where the changes happened.\t\t */
	public u8sLinePhoneNumber!: string;
	/** List of call with their current features which have changed. */
	public callFeaturesList!: AsnCtiCallFeaturesList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnCtiSetCallDataArgument
// [PrintTSSeqDefCode] AsnCtiSetCallDataArgument
/**
 * Arguments for asnCtiSetCallData
 */
export class AsnCtiSetCallDataArgument {
	public constructor(that: AsnCtiSetCallDataArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiSetCallDataArgument {
		return new AsnCtiSetCallDataArgument({
			u8sConnectionID: "",
			callDataList: new AsnCtiCallDataList()
		});
	}

	/** Connection identifier of the call to set data for. If this is unknown (empty), the additional parameters in optionalParams are needed. */
	public u8sConnectionID!: string;
	/** Call data key value map */
	public callDataList!: AsnCtiCallDataList;
	/** Additional parameters for asnCtiSetCallData added here for backward compatibility. */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}
