// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * SNACCROSE_Converter.ts
 * "SNACC-ROSE" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as SNACCROSE from "./SNACCROSE";

// [PrintTSEncoderDecoderCode] ROSERejectChoice
export class ROSERejectChoice_Converter {
	public static toJSON(obj?: SNACCROSE.ROSERejectChoice, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderChoiceDefCode] ROSERejectChoice
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSERejectChoice", newContext);
		if (obj.invokedID != null)
			value += TSConverter.addNumberParam("invokedID", obj.invokedID, newContext);
		else if (obj.invokednull != null)
			value += TSConverter.addObjectAsStringParam("invokednull", JSON.stringify(obj.invokednull), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSERejectChoice, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSERejectChoice");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSERejectChoice"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSERejectChoice;
		if (data !== undefined) {
			// [PrintTSDecoderChoiceDefCode] ROSERejectChoice
			newContext.bLaxParsing = false;
			if (TSConverter.validateChoiceParam(data, "invokedID", "number", undefined, newContext))
				obj.invokedID = data.invokedID;
			else if (!(optional === true))
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSERejectChoice"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEResultSeq
export class ROSEResultSeq_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEResultSeq, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] ROSEResultSeq
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSEResultSeq", newContext);
		value += TSConverter.addNumberParam("resultValue", obj.resultValue, newContext);
		value += TSConverter.addAnyParam("result", obj.result, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEResultSeq, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEResultSeq");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEResultSeq"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEResultSeq;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] ROSEResultSeq
			if (TSConverter.validateParam(data, "resultValue", "number", errors, newContext, false))
				obj.resultValue = data.resultValue;
			if (TSConverter.validateAnyParam(data, "result", errors, newContext, false))
				obj.result = data.result;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEResultSeq"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEAuth
export class ROSEAuth_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEAuth, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] ROSEAuth
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSEAuth", newContext);
		value += TSConverter.addStringParam("method", obj.method, newContext);
		value += TSConverter.addBinaryParam("authdata", obj.authdata, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEAuth, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEAuth");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEAuth"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEAuth;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] ROSEAuth
			if (TSConverter.validateParam(data, "method", "string", errors, newContext, false))
				obj.method = data.method;
			if (TSConverter.validateParam(data, "authdata", "string", errors, newContext, false))
				obj.authdata = TSConverter.decode64(data.authdata.toString());
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEAuth"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEError
export class ROSEError_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEError, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] ROSEError
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSEError", newContext);
		value += TSConverter.addStringParam("sessionID", obj.sessionID, newContext);
		value += TSConverter.addNumberParam("invokedID", obj.invokedID, newContext);
		value += TSConverter.addNumberParam("error_value", obj.error_value, newContext);
		value += TSConverter.addAnyParam("error", obj.error, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEError, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEError");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEError"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEError;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] ROSEError
			if (TSConverter.validateParam(data, "sessionID", "string", errors, newContext, true))
				obj.sessionID = data.sessionID;
			if (TSConverter.validateParam(data, "invokedID", "number", errors, newContext, false))
				obj.invokedID = data.invokedID;
			if (TSConverter.validateParam(data, "error_value", "number", errors, newContext, false))
				obj.error_value = data.error_value;
			if (TSConverter.validateAnyParam(data, "error", errors, newContext, true))
				obj.error = data.error;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEError"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEAuthList
export class ROSEAuthList_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEAuthList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] ROSEAuthList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += ROSEAuth_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEAuthList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEAuthList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEAuthList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEAuthList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] ROSEAuthList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = SNACCROSE.ROSEAuth.initEmpty();
				if (ROSEAuth_Converter.fromJSON(elem, entry, errors, newContext, "ROSEAuth", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEAuthList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEAuthRequest
export class ROSEAuthRequest_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEAuthRequest, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] ROSEAuthRequest
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSEAuthRequest", newContext);
		value += TSConverter.addObjectAsStringParam("auth", ROSEAuth_Converter.toJSON(obj.auth, newContext), newContext);
		value += TSConverter.addStringParam("context", obj.context, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEAuthRequest, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEAuthRequest");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEAuthRequest"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEAuthRequest;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] ROSEAuthRequest
			const tscv_auth = SNACCROSE.ROSEAuth.initEmpty();
			if (ROSEAuth_Converter.fromJSON(data.auth, tscv_auth, errors, newContext, "auth", false))
				obj.auth = tscv_auth;
			if (TSConverter.validateParam(data, "context", "string", errors, newContext, true))
				obj.context = data.context;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEAuthRequest"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEAuthResult
export class ROSEAuthResult_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEAuthResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] ROSEAuthResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSEAuthResult", newContext);
		value += TSConverter.addObjectAsStringParam("authList", ROSEAuthList_Converter.toJSON(obj.authList, newContext), newContext);
		value += TSConverter.addStringParam("context", obj.context, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEAuthResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEAuthResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEAuthResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEAuthResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] ROSEAuthResult
			const tscv_authlist = new SNACCROSE.ROSEAuthList();
			if (ROSEAuthList_Converter.fromJSON(data.authList, tscv_authlist, errors, newContext, "authList", false))
				obj.authList = tscv_authlist;
			if (TSConverter.validateParam(data, "context", "string", errors, newContext, true))
				obj.context = data.context;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEAuthResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEInvoke
export class ROSEInvoke_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEInvoke, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] ROSEInvoke
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSEInvoke", newContext);
		value += TSConverter.addStringParam("sessionID", obj.sessionID, newContext);
		value += TSConverter.addNumberParam("invokeID", obj.invokeID, newContext);
		value += TSConverter.addNumberParam("linked_ID", obj.linked_ID, newContext);
		value += TSConverter.addStringParam("operationName", obj.operationName, newContext);
		value += TSConverter.addObjectAsStringParam("authentication", ROSEAuthRequest_Converter.toJSON(obj.authentication, newContext), newContext);
		value += TSConverter.addNumberParam("operationID", obj.operationID, newContext);
		value += TSConverter.addAnyParam("argument", obj.argument, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEInvoke, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEInvoke");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEInvoke"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEInvoke;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] ROSEInvoke
			if (TSConverter.validateParam(data, "sessionID", "string", errors, newContext, true))
				obj.sessionID = data.sessionID;
			if (TSConverter.validateParam(data, "invokeID", "number", errors, newContext, false))
				obj.invokeID = data.invokeID;
			if (TSConverter.validateParam(data, "linked_ID", "number", errors, newContext, true))
				obj.linked_ID = data.linked_ID;
			if (TSConverter.validateParam(data, "operationName", "string", errors, newContext, true))
				obj.operationName = data.operationName;
			const tscv_authentication = SNACCROSE.ROSEAuthRequest.initEmpty();
			if (ROSEAuthRequest_Converter.fromJSON(data.authentication, tscv_authentication, errors, newContext, "authentication", true))
				obj.authentication = tscv_authentication;
			if (TSConverter.validateParam(data, "operationID", "number", errors, newContext, false))
				obj.operationID = data.operationID;
			if (TSConverter.validateAnyParam(data, "argument", errors, newContext, true))
				obj.argument = data.argument;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEInvoke"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEResult
export class ROSEResult_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] ROSEResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSEResult", newContext);
		value += TSConverter.addStringParam("sessionID", obj.sessionID, newContext);
		value += TSConverter.addNumberParam("invokeID", obj.invokeID, newContext);
		value += TSConverter.addObjectAsStringParam("result", ROSEResultSeq_Converter.toJSON(obj.result, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] ROSEResult
			if (TSConverter.validateParam(data, "sessionID", "string", errors, newContext, true))
				obj.sessionID = data.sessionID;
			if (TSConverter.validateParam(data, "invokeID", "number", errors, newContext, false))
				obj.invokeID = data.invokeID;
			const tscv_result = SNACCROSE.ROSEResultSeq.initEmpty();
			if (ROSEResultSeq_Converter.fromJSON(data.result, tscv_result, errors, newContext, "result", true))
				obj.result = tscv_result;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] RejectProblem
export class RejectProblem_Converter {
	public static toJSON(obj?: SNACCROSE.RejectProblem, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderChoiceDefCode] RejectProblem
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "RejectProblem", newContext);
		if (obj.generalProblem != null)
			value += TSConverter.addNumberParam("generalProblem", obj.generalProblem, newContext);
		else if (obj.invokeProblem != null)
			value += TSConverter.addNumberParam("invokeProblem", obj.invokeProblem, newContext);
		else if (obj.returnResultProblem != null)
			value += TSConverter.addNumberParam("returnResultProblem", obj.returnResultProblem, newContext);
		else if (obj.returnErrorProblem != null)
			value += TSConverter.addNumberParam("returnErrorProblem", obj.returnErrorProblem, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.RejectProblem, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "RejectProblem");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing RejectProblem"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.RejectProblem;
		if (data !== undefined) {
			// [PrintTSDecoderChoiceDefCode] RejectProblem
			newContext.bLaxParsing = false;
			if (TSConverter.validateChoiceParam(data, "generalProblem", "number", undefined, newContext))
				obj.generalProblem = data.generalProblem;
			else if (TSConverter.validateChoiceParam(data, "invokeProblem", "number", undefined, newContext))
				obj.invokeProblem = data.invokeProblem;
			else if (TSConverter.validateChoiceParam(data, "returnResultProblem", "number", undefined, newContext))
				obj.returnResultProblem = data.returnResultProblem;
			else if (TSConverter.validateChoiceParam(data, "returnErrorProblem", "number", undefined, newContext))
				obj.returnErrorProblem = data.returnErrorProblem;
			else if (!(optional === true))
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing RejectProblem"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEReject
export class ROSEReject_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEReject, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] ROSEReject
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSEReject", newContext);
		value += TSConverter.addStringParam("sessionID", obj.sessionID, newContext);
		value += TSConverter.addObjectAsStringParam("invokedID", ROSERejectChoice_Converter.toJSON(obj.invokedID, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("reject", RejectProblem_Converter.toJSON(obj.reject, newContext), newContext);
		value += TSConverter.addStringParam("details", obj.details, newContext);
		value += TSConverter.addObjectAsStringParam("authentication", ROSEAuthResult_Converter.toJSON(obj.authentication, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEReject, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEReject");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEReject"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEReject;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] ROSEReject
			if (TSConverter.validateParam(data, "sessionID", "string", errors, newContext, true))
				obj.sessionID = data.sessionID;
			const tscv_invokedid = SNACCROSE.ROSERejectChoice.initEmpty();
			if (ROSERejectChoice_Converter.fromJSON(data.invokedID, tscv_invokedid, errors, newContext, "invokedID", false))
				obj.invokedID = tscv_invokedid;
			const tscv_reject = SNACCROSE.RejectProblem.initEmpty();
			if (RejectProblem_Converter.fromJSON(data.reject, tscv_reject, errors, newContext, "reject", true))
				obj.reject = tscv_reject;
			if (TSConverter.validateParam(data, "details", "string", errors, newContext, true))
				obj.details = data.details;
			const tscv_authentication = SNACCROSE.ROSEAuthResult.initEmpty();
			if (ROSEAuthResult_Converter.fromJSON(data.authentication, tscv_authentication, errors, newContext, "authentication", true))
				obj.authentication = tscv_authentication;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEReject"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] ROSEMessage
export class ROSEMessage_Converter {
	public static toJSON(obj?: SNACCROSE.ROSEMessage, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderChoiceDefCode] ROSEMessage
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "ROSEMessage", newContext);
		if (obj.invoke != null)
			value += TSConverter.addObjectAsStringParam("invoke", ROSEInvoke_Converter.toJSON(obj.invoke, newContext), newContext);
		else if (obj.result != null)
			value += TSConverter.addObjectAsStringParam("result", ROSEResult_Converter.toJSON(obj.result, newContext), newContext);
		else if (obj.error != null)
			value += TSConverter.addObjectAsStringParam("error", ROSEError_Converter.toJSON(obj.error, newContext), newContext);
		else if (obj.reject != null)
			value += TSConverter.addObjectAsStringParam("reject", ROSEReject_Converter.toJSON(obj.reject, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: SNACCROSE.ROSEMessage, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "ROSEMessage");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing ROSEMessage"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as SNACCROSE.ROSEMessage;
		if (data !== undefined) {
			// [PrintTSDecoderChoiceDefCode] ROSEMessage
			newContext.bLaxParsing = false;
			const tscv_invoke = SNACCROSE.ROSEInvoke.initEmpty();
			if (ROSEInvoke_Converter.fromJSON(data.invoke, tscv_invoke, undefined, newContext, "invoke", optional))
				obj.invoke = tscv_invoke;
			else {
				const tscv_result = SNACCROSE.ROSEResult.initEmpty();
				if (ROSEResult_Converter.fromJSON(data.result, tscv_result, undefined, newContext, "result", optional))
					obj.result = tscv_result;
				else {
					const tscv_error = SNACCROSE.ROSEError.initEmpty();
					if (ROSEError_Converter.fromJSON(data.error, tscv_error, undefined, newContext, "error", optional))
						obj.error = tscv_error;
					else {
						const tscv_reject = SNACCROSE.ROSEReject.initEmpty();
						if (ROSEReject_Converter.fromJSON(data.reject, tscv_reject, undefined, newContext, "reject", optional))
							obj.reject = tscv_reject;
						else if (!(optional === true))
							errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
					}
				}
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing ROSEMessage"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
