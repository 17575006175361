// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_Agent.ts
 * "UC-Server-Access-Protocol-Agent" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * Agent\/AgentGroup\/ACD interface.
 * ## Module description
 * This module describes the functions for agents for ACD and contact portal functionalities.
 *
 * A client represents a user who can be an member in any number of agent groups.&lt;br \/&gt;
 * An agent is described by the ContactURI of the user (SIP address incl. \"sip:\" before) or a LineURI (Cti line, format \"tel:xyz@pbx text\").
 * To login to a group, for example, you usually need the AgencyURI and the GroupURI. This allows the UCServer to identify exactly which agent should be used in which group.&lt;br \/&gt;
 * The client receives the necessary values when it gets them back as a result of asnAgentMonitorStart.
 *
 * ### Requirements for using the interface
 * A user gets access to the agent interface:
 * - If the user has activated the service \"Business card for anonymous users\" via his own or global profile in the UCServer administration.
 * - If he is a member of one or more groups that have been activated in the UCServer administration as an \"agent group\".
 * - If (identified by line ID) he has a line known to be part of an ACD group at the corresponding ECSTA.
 *
 * ### Functions
 * A user can set the following status on the interface:
 * - \"login\"\/\"logout\"
 * - \"Working after call\"
 * - \"ready\"\/\"not ready\" (still a \"work in progress\")
 *
 * ProCall 5.1 supports ACD functionality only for Panasonic telephone systems.&lt;br \/&gt;
 * As of ProCall 6, the agent interface is used for the contact portal and business card. Further PBX's are in development.
 *
 * ## Simple procedure if the agent groups are to be used ##
 * 1. The user must only call asnAgentMonitorStart; this registers the agent's events and supplies the list of groups in which the user is agent.
 * 2. From this point on, the UCServer sends events if the status of the user in these groups changes.
 * 3. Change the state of the agent:
 * 3.1. asnAgentLogin - Sets the status of the user to \"login\". Note: replaces asnAgentSetState(not ready).
 * More parameters may be required for the login. These can be found in the AgentGroupInfos
 * 3.2. asnAgentLogoff - Sets the status of the user to \"logoff\". Note: replaces asAgentSetState(noagent).
 * 3.3. asnAgentSetState - With this you can set the status to \"ready\", \"not ready\" and \"working after call\". Which states can be set is provided by the server in the result of asnAgentMonitorStart. See also AsnAgentGroupInfo.
 * 4. Log off events with asnAgentMonitorStop.
 *
 * With these five functions it is possible to operate the ACD\/presence groups on the UCServer.
 *
 * ## AsnAgentGroupInfo
 * AsnAgentGroupInfo structures are usually exchanged in the analysis and results. This structure contains the description of an agent\/ACD group.&lt;br \/&gt;
 * AsnAgentGroupInfo contains the data about an agent group. The following are important:
 * - iGroupCapabilities: This shows which status changes are supported. See also AsnAgentGroupCapabilitiesEnum. If the highest bit is set (0x80000000), this group is a PBX\/ACD group. If the bit is not set, the group is managed by the UCServer.
 * - iAgentState: The current status of the agent, i.e. the user in this group.
 * - optionalParams,key \"AgentLoginRequiredParameters\": In the case of a PBX\/ACD group, this integer contains information about which parameters are required for logging on to the ACD group. The values (bit field) are described in AsnAgentLoginRequiredParametersEnum.
 *
 *
 * ## Events
 * For any changes to the agent groups and agent status, two events are sent by the UCServer to the registered clients:
 * - asnAgentNotifyGroupsChanged - There have been some changes in the user's available groups
 * - asnAgentNotifyStateChanged - The user's status in a group has changed.
 *
 * ## Comment
 * The API is user centered. This means if the server is asked for the AgentGroupInfos,
 * the server displays the information concerning its group membership in a simple list.
 * The most simple for the normal user.
 *
 * ## Supervisor Interface
 * Not implemented yet.
 *
 * ## Anonymous users
 * Anonymous users can query which groups are available, including users, and then display the corresponding presence and login\/logoff status based on the use case.
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";

// [PrintTSTypeDefCode] AsnAgentLoginRequiredParametersEnum
// [PrintTSEnumDefCode] AsnAgentLoginRequiredParametersEnum
export enum AsnAgentLoginRequiredParametersEnum {
	/** AgentID Required (for example, the PIN) */
	eAGENTLOGINREQUIRESAGENTID = 1,
	/** GroupID Required */
	eAGENTLOGINREQUIRESGROUPID = 2,
	/** Password Required */
	eAGENTLOGINREQUIRESPASSWORD = 4,
	/** DeviceID Required */
	eAGENTLOGINREQUIRESDEVICEID = 8
}

// [PrintTSTypeDefCode] AsnSupportedAgentStatesEnum
// [PrintTSEnumDefCode] AsnSupportedAgentStatesEnum
export enum AsnSupportedAgentStatesEnum {
	/** \"logout\" \/ \"no agent\" */
	eAGENTSTATELOGGEDOUT = 1,
	/** \"not ready\" */
	eAGENTSTATELOGGEDINNOTREADY = 2,
	/** \"ready\" */
	eAGENTSTATELOGGEDINREADY = 4,
	/** \"busy\" */
	eAGENTSTATELOGGEDINBUSY = 8,
	/** \"working after call\" */
	eAGENTSTATELOGGEDINWORKINGAFTERCALL = 16
}

// [PrintTSTypeDefCode] AsnAgentStateEnum
// [PrintTSEnumDefCode] AsnAgentStateEnum
export enum AsnAgentStateEnum {
	/** Agent logged out from group - (is set implicitly at AgentLogoff() by the server) */
	eAGENTSTATENOAGENT = 0,
	/** Agent unavailable */
	eAGENTSTATENOTREADY = 1,
	/** Agent available in group - (is initially set at AgentLogin() by the server) */
	eAGENTSTATEREADY = 2,
	/** Agent is busy */
	eAGENTSTATEBUSY = 3,
	/** Agent is working after a call */
	eAGENTSTATEWORKINGAFTERCALL = 4
}

// [PrintTSTypeDefCode] AsnAgentGroupCapabilitiesEnum
// [PrintTSEnumDefCode] AsnAgentGroupCapabilitiesEnum
export enum AsnAgentGroupCapabilitiesEnum {
	/** (0x01) \"login\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSLOGIN = 1,
	/** (0x02) \"logout\" is supported \/ same as state eAGENTSTATENOAGENT */
	eAGENTCAPSUPPORTEDAGENTREQUESTSLOGOUT = 2,
	/** (0x04) \"not ready\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSNOTREADY = 4,
	/** (0x08) \"ready\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSREADY = 8,
	/** (0x10) \"busy\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSBUSY = 16,
	/** (0x20) \"working after call\" is supported */
	eAGENTCAPSUPPORTEDAGENTREQUESTSWORKINGAFTERCALL = 32,
	/** (0xff) (currently not supported) */
	eAGENTCAPSUPPORTEDAGENTREQUESTSMASK = 255,
	/** (0x20000000) The group is a (Contact)Portal Group */
	eAGENTCAPISPORTALGROUP = 536870912,
	/** (0x40000000) The agents in this group can handle PUBLIC-CHATs */
	eAGENTCAPISPUBLICCHATGROUP = 1073741824
}

// [PrintTSTypeDefCode] AsnAgentBroadcastTypeEnum
// [PrintTSEnumDefCode] AsnAgentBroadcastTypeEnum
export enum AsnAgentBroadcastTypeEnum {
	general = 1,
	requestoverdue = 2
}

// [PrintTSTypeDefCode] AsnAgentGroupInfo
// [PrintTSSeqDefCode] AsnAgentGroupInfo
/**
 * AsnAgentGroupInfo encapsulates the data for an agent (ACD) group from the agent's point of view.
 * An AsnAgentGroupInfo can only be uniquely identified with the combination of u8sAgentURI and u8sGroupURI.
 *
 * ## OptionalParameters - Subsequent Enhancements to the data structure ##
 * - \"PhoneNumber\" String phone number to be displayed in the client; only server to client.
 * - \"AgentLoginRequiredParameters\" DWORD bit flags that indicate which data the PBX requires for the user\/line logon. Only sent server to client. Not filled for contact portal\/business card. See AsnAgentLoginRequiredParametersEnum.
 * - \"SupportedAgentStates\" DWORD bit flags indicating which agents status values can be used in this group. Only sent server to client. See also AsnSupportedAgentStatesEnum.
 *
 * @private
 */
export class AsnAgentGroupInfo {
	public constructor(that: AsnAgentGroupInfo) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentGroupInfo {
		return new AsnAgentGroupInfo({
			u8sAgentURI: "",
			u8sGroupURI: "",
			u8sDisplayName: "",
			iGroupCapabilities: 0,
			iAgentState: 0
		});
	}

	/** URI of the group agent (LineURI or ContactURI) e.g. \"tel:+4912345678;phys=678@ECSTA for OpenScape Business\" or \"sip:test.tester@test.de\" */
	public u8sAgentURI!: string;
	/** URI of the group (e.g. \"pbxg:601@ECSTA for Panasonic\" or \"sip:mygroup@estos.de\"). */
	public u8sGroupURI!: string;
	/** Display name of the group. */
	public u8sDisplayName!: string;
	/** This shows which status changes are supported. See also AsnAgentGroupCapabilitiesEnum. */
	public iGroupCapabilities!: number;
	/** Agent status of the agent within the group as defined in AsnAgentStateEnum. */
	public iAgentState!: number;
	/** OptionalParameters - Subsequent extensions to the data structure */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAgentMonitorStartArgument
// [PrintTSSeqDefCode] AsnAgentMonitorStartArgument
export class AsnAgentMonitorStartArgument {
	public constructor(that: AsnAgentMonitorStartArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentMonitorStartArgument {
		return new AsnAgentMonitorStartArgument({
			u8sAgentURI: ""
		});
	}

	/** Agent URI e.g. \"sip:xyz@domain.de\". An empty string is supplemented by the UCServer with the ID of the logged on user. */
	public u8sAgentURI!: string;
}

// [PrintTSTypeDefCode] AsnAgentMonitorStopArgument
// [PrintTSSeqDefCode] AsnAgentMonitorStopArgument
/**
 * The CrossRefID that was returned by the server at asnAgentMonitorStart.
 *
 * @private
 */
export class AsnAgentMonitorStopArgument {
	public constructor(that: AsnAgentMonitorStopArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentMonitorStopArgument {
		return new AsnAgentMonitorStopArgument({
			u8sMonitorCrossRefID: ""
		});
	}

	public u8sMonitorCrossRefID!: string;
}

// [PrintTSTypeDefCode] AsnAgentMonitorStopResult
// [PrintTSSeqDefCode] AsnAgentMonitorStopResult
/**
 * This structure is only restored if successful. If the call is unsuccessful, it will return an AsnRequestError.
 *
 * @private
 */
export class AsnAgentMonitorStopResult {
	public constructor(that?: AsnAgentMonitorStopResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentMonitorStopResult {
		return new AsnAgentMonitorStopResult();
	}
}

// [PrintTSTypeDefCode] AsnAgentACDLoginData
// [PrintTSSeqDefCode] AsnAgentACDLoginData
/**
 * Additional data that may be required to login\/monitor ACD groups of a PBX.
 *
 * @private
 */
export class AsnAgentACDLoginData {
	public constructor(that: AsnAgentACDLoginData) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentACDLoginData {
		return new AsnAgentACDLoginData({
			u8sAgentID: "",
			u8sGroupID: "",
			u8sPassword: "",
			u8sDeviceID: ""
		});
	}

	/** The agent ID on the PBX (PIN); this probably has to be requested by the user */
	public u8sAgentID!: string;
	/** The group ID on the PBX */
	public u8sGroupID!: string;
	/** A password may also be required to log in */
	public u8sPassword!: string;
	/** [TODO: what is \"DeviceID\" for? It is possible as a parameter in the system] */
	public u8sDeviceID!: string;
}

// [PrintTSTypeDefCode] AsnAgentLoginResult
// [PrintTSSeqDefCode] AsnAgentLoginResult
/**
 * Result for agent login
 * If the server returns the result, the login was successful.
 * If the login fails, the AsnRequestError is returned as the result.
 * This contains more details about the error.&lt;br \/&gt;
 * This structure is only returned if successful. If the call is unsuccessful, AsnRequestError is returned.
 *
 * @private
 */
export class AsnAgentLoginResult {
	public constructor(that?: AsnAgentLoginResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentLoginResult {
		return new AsnAgentLoginResult();
	}
}

// [PrintTSTypeDefCode] AsnAgentLogoffArgument
// [PrintTSSeqDefCode] AsnAgentLogoffArgument
/**
 * Argument for agent logoff(Automatic Call Distribution)
 *
 * @private
 */
export class AsnAgentLogoffArgument {
	public constructor(that: AsnAgentLogoffArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentLogoffArgument {
		return new AsnAgentLogoffArgument({
			u8sAgentURI: "",
			u8sGroupURI: ""
		});
	}

	/** Group agent URI (LineURI or ContactURI) e.g. \"tel:\" or \"sip:\" */
	public u8sAgentURI!: string;
	/** Group URI (e.g. \"pbxg:601@ECSTA for Panasonic\" or \"sip:mygroup@estos.de\"). */
	public u8sGroupURI!: string;
}

// [PrintTSTypeDefCode] AsnAgentLogoffResult
// [PrintTSSeqDefCode] AsnAgentLogoffResult
/**
 * Result for agent logoff
 * This structure is only returned if successful. If the call is unsuccessful, an AsnRequestError is returned.
 *
 * @private
 */
export class AsnAgentLogoffResult {
	public constructor(that?: AsnAgentLogoffResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentLogoffResult {
		return new AsnAgentLogoffResult();
	}
}

// [PrintTSTypeDefCode] AsnAgentSetStateArgument
// [PrintTSSeqDefCode] AsnAgentSetStateArgument
/**
 * Set new Agent State
 *
 * @private
 */
export class AsnAgentSetStateArgument {
	public constructor(that: AsnAgentSetStateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentSetStateArgument {
		return new AsnAgentSetStateArgument({
			u8sAgentURI: "",
			iAgentState: 0
		});
	}

	/** Group agent URI (LineURI or ContactURI) e.g. \"tel:\" or \"sip:\" */
	public u8sAgentURI!: string;
	/** Group URI (e.g. \"pbxg:601@ECSTA for Panasonic\" or \"sip:mygroup@estos.de\"). */
	public u8sGroupURI?: string;
	/** Agent status of the agent to be set as defined in AsnAgentStateEnum. */
	public iAgentState!: number;
}

// [PrintTSTypeDefCode] AsnAgentSetStateResult
// [PrintTSSeqDefCode] AsnAgentSetStateResult
/**
 * Result for AsnAgentSetState
 * This structure is only returned if successful. If the call is unsuccessful, an AsnRequestError is returned.
 *
 * @private
 */
export class AsnAgentSetStateResult {
	public constructor(that?: AsnAgentSetStateResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentSetStateResult {
		return new AsnAgentSetStateResult();
	}
}

// [PrintTSTypeDefCode] AsnAgentSupervisorMonitorStartArgument
// [PrintTSSeqDefCode] AsnAgentSupervisorMonitorStartArgument
export class AsnAgentSupervisorMonitorStartArgument {
	public constructor(that?: AsnAgentSupervisorMonitorStartArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentSupervisorMonitorStartArgument {
		return new AsnAgentSupervisorMonitorStartArgument();
	}
}

// [PrintTSTypeDefCode] AsnAgentSupervisorMonitorStopArgument
// [PrintTSSeqDefCode] AsnAgentSupervisorMonitorStopArgument
export class AsnAgentSupervisorMonitorStopArgument {
	public constructor(that: AsnAgentSupervisorMonitorStopArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentSupervisorMonitorStopArgument {
		return new AsnAgentSupervisorMonitorStopArgument({
			u8sMonitorCrossRefID: ""
		});
	}

	/** CrossrefID for associated events */
	public u8sMonitorCrossRefID!: string;
}

// [PrintTSTypeDefCode] AsnAgentSupervisorMonitorStopResult
// [PrintTSSeqDefCode] AsnAgentSupervisorMonitorStopResult
export class AsnAgentSupervisorMonitorStopResult {
	public constructor(that?: AsnAgentSupervisorMonitorStopResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentSupervisorMonitorStopResult {
		return new AsnAgentSupervisorMonitorStopResult();
	}
}

// [PrintTSTypeDefCode] AsnAgentBroadcastItem
// [PrintTSSeqDefCode] AsnAgentBroadcastItem
/**
 * Defines a broadcast element
 * Broadcasts are used to notify or exchange user data between agent clients.
 * A broadcast can be sent to the members of different agent groups.
 * As soon as an agent confirms this broadcast, it is considered completed for the system (UCServer).
 * The broadcast functionality is only available for agents of the PC server (business card, contact portal) - not for PBX\/ACD\/telephone systems.
 * All broadcast information is temporary! When the server is restarted, all broadcast elements that are still open are discarded.
 *
 * @private
 */
export class AsnAgentBroadcastItem {
	public constructor(that: AsnAgentBroadcastItem) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastItem {
		return new AsnAgentBroadcastItem({
			u8sBroadcastID: "",
			listu8sGroupURIs: new ENetUC_Common.UTF8StringList(),
			iBroadcastType: 0,
			data: ""
		});
	}

	public u8sBroadcastID!: string;
	/** Broadcast item recipient list */
	public listu8sGroupURIs!: ENetUC_Common.UTF8StringList;
	/** Broadcast type, see AsnAgentBroadcastTypeEnum. */
	public iBroadcastType!: number;
	/** User data (not interpreted by the server) */
	public data!: string;
}

// [PrintTSTypeDefCode] AsnAgentBroadcastGetItemsForGroupsArgument
// [PrintTSSeqDefCode] AsnAgentBroadcastGetItemsForGroupsArgument
export class AsnAgentBroadcastGetItemsForGroupsArgument {
	public constructor(that: AsnAgentBroadcastGetItemsForGroupsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastGetItemsForGroupsArgument {
		return new AsnAgentBroadcastGetItemsForGroupsArgument({
			listu8sGroupURIs: new ENetUC_Common.UTF8StringList()
		});
	}

	public listu8sGroupURIs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode] AsnAgentBroadcastCreateArgument
// [PrintTSSeqDefCode] AsnAgentBroadcastCreateArgument
/**
 * Restricted access: None
 *
 * @private
 */
export class AsnAgentBroadcastCreateArgument {
	public constructor(that: AsnAgentBroadcastCreateArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastCreateArgument {
		return new AsnAgentBroadcastCreateArgument({
			listu8sGroupIDs: new ENetUC_Common.UTF8StringList(),
			iBroadcastType: 0,
			data: ""
		});
	}

	/** Recipient list (do not have to be URIs, because the anonymous agent does not necessarily know them - it also works with the name of the group) */
	public listu8sGroupIDs!: ENetUC_Common.UTF8StringList;
	/** Broadcast type, see AsnAgentBroadcastTypeEnum. */
	public iBroadcastType!: number;
	/** User data (not evaluated by the server, only passed on to the client) */
	public data!: string;
	/** Time To Live (Seconds) */
	public iTTL?: number;
}

// [PrintTSTypeDefCode] AsnAgentBroadcastCreateResult
// [PrintTSSeqDefCode] AsnAgentBroadcastCreateResult
export class AsnAgentBroadcastCreateResult {
	public constructor(that: AsnAgentBroadcastCreateResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastCreateResult {
		return new AsnAgentBroadcastCreateResult({
			u8sBroadcastID: ""
		});
	}

	/** Unique ID of the broadcast generated by the server */
	public u8sBroadcastID!: string;
}

// [PrintTSTypeDefCode] AsnAgentBroadcastConfirmArgument
// [PrintTSSeqDefCode] AsnAgentBroadcastConfirmArgument
/**
 * Restricted access: None
 *
 * @private
 */
export class AsnAgentBroadcastConfirmArgument {
	public constructor(that: AsnAgentBroadcastConfirmArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastConfirmArgument {
		return new AsnAgentBroadcastConfirmArgument({
			u8sBroadcastID: ""
		});
	}

	/** The BroadcastID generated by the server. The server ignores duplicate confirmations! See AsnAgentBroadcastCreateArgument. */
	public u8sBroadcastID!: string;
}

// [PrintTSTypeDefCode] AsnAgentBroadcastConfirmResult
// [PrintTSSeqDefCode] AsnAgentBroadcastConfirmResult
export class AsnAgentBroadcastConfirmResult {
	public constructor(that?: AsnAgentBroadcastConfirmResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastConfirmResult {
		return new AsnAgentBroadcastConfirmResult();
	}
}

// [PrintTSTypeDefCode] AsnAgentBroadcastCheckArgument
// [PrintTSSeqDefCode] AsnAgentBroadcastCheckArgument
/**
 * Restricted access: None
 *
 * @private
 */
export class AsnAgentBroadcastCheckArgument {
	public constructor(that: AsnAgentBroadcastCheckArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastCheckArgument {
		return new AsnAgentBroadcastCheckArgument({
			u8sBroadcastID: ""
		});
	}

	/** The BroadcastID generated by the server. See AsnAgentBroadcastCreateArgument. */
	public u8sBroadcastID!: string;
}

// [PrintTSTypeDefCode] AsnAgentBroadcastCheckResult
// [PrintTSSeqDefCode] AsnAgentBroadcastCheckResult
export class AsnAgentBroadcastCheckResult {
	public constructor(that: AsnAgentBroadcastCheckResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastCheckResult {
		return new AsnAgentBroadcastCheckResult({
			bExists: false
		});
	}

	public bExists!: boolean;
}

// [PrintTSTypeDefCode] AsnAgentNotifyStateChangedArgument
// [PrintTSSeqDefCode] AsnAgentNotifyStateChangedArgument
/**
 * Argument for AgentNotifyStateChanged. Included is the CrossRefID for which the event is transmitted, the AgencyURI and GroupURI (unique agent group assignment) and the new agent state.
 *
 * @private
 */
export class AsnAgentNotifyStateChangedArgument {
	public constructor(that: AsnAgentNotifyStateChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentNotifyStateChangedArgument {
		return new AsnAgentNotifyStateChangedArgument({
			u8sMonitorCrossRefID: "",
			u8sAgentURI: "",
			u8sGroupURI: "",
			iAgentState: 0
		});
	}

	/** CrossrefID for associated events */
	public u8sMonitorCrossRefID!: string;
	/** Agent group URI (LineURI or ContactURI) */
	public u8sAgentURI!: string;
	/** Group URI- must be unique in the UCServer */
	public u8sGroupURI!: string;
	/** AgentState of the agent in the group */
	public iAgentState!: number;
}

// [PrintTSTypeDefCode] AsnAgentBlockedRTCGetUsersArgument
// [PrintTSSeqDefCode] AsnAgentBlockedRTCGetUsersArgument
export class AsnAgentBlockedRTCGetUsersArgument {
	public constructor(that?: AsnAgentBlockedRTCGetUsersArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBlockedRTCGetUsersArgument {
		return new AsnAgentBlockedRTCGetUsersArgument();
	}
}

// [PrintTSTypeDefCode] AsnAgentBlockedRTCGetUsersResult
// [PrintTSSeqDefCode] AsnAgentBlockedRTCGetUsersResult
export class AsnAgentBlockedRTCGetUsersResult {
	public constructor(that: AsnAgentBlockedRTCGetUsersResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBlockedRTCGetUsersResult {
		return new AsnAgentBlockedRTCGetUsersResult({
			listu8sBlockedUserURIs: new ENetUC_Common.UTF8StringList()
		});
	}

	/** list of all blocked users */
	public listu8sBlockedUserURIs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode] AsnAgentNotifyBlockedRTCChangedArgument
// [PrintTSSeqDefCode] AsnAgentNotifyBlockedRTCChangedArgument
/**
 * Argument for AgentNotifyAnonymousBlockedChanged.
 *
 * @private
 */
export class AsnAgentNotifyBlockedRTCChangedArgument {
	public constructor(that: AsnAgentNotifyBlockedRTCChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentNotifyBlockedRTCChangedArgument {
		return new AsnAgentNotifyBlockedRTCChangedArgument({
			listu8sBlockedUserURIs: new ENetUC_Common.UTF8StringList()
		});
	}

	/** list of all blocked users */
	public listu8sBlockedUserURIs!: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode] AsnAgentAnonymousBlockRTCArgument
// [PrintTSSeqDefCode] AsnAgentAnonymousBlockRTCArgument
export class AsnAgentAnonymousBlockRTCArgument {
	public constructor(that: AsnAgentAnonymousBlockRTCArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentAnonymousBlockRTCArgument {
		return new AsnAgentAnonymousBlockRTCArgument({
			u8sAnonymousUserURI: ""
		});
	}

	/** anonymous user URI - which has to be blocked */
	public u8sAnonymousUserURI!: string;
	/** (optional) List of internal users who are affected by blocking */
	public listu8sAffectedURIs?: ENetUC_Common.UTF8StringList;
	/** Time To Block (in hours - default: 24) */
	public iExpireTime?: number;
}

// [PrintTSTypeDefCode] AsnAgentAnonymousBlockRTCResult
// [PrintTSSeqDefCode] AsnAgentAnonymousBlockRTCResult
export class AsnAgentAnonymousBlockRTCResult {
	public constructor(that: AsnAgentAnonymousBlockRTCResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentAnonymousBlockRTCResult {
		return new AsnAgentAnonymousBlockRTCResult({
			u8sAnonymousUserURI: "",
			bBlockedRTC: false
		});
	}

	/** affected anonymous user URI */
	public u8sAnonymousUserURI!: string;
	/** BlockedState of anonymous User */
	public bBlockedRTC!: boolean;
}

// [PrintTSTypeDefCode] AsnAgentAnonymousUnblockRTCArgument
// [PrintTSSeqDefCode] AsnAgentAnonymousUnblockRTCArgument
export class AsnAgentAnonymousUnblockRTCArgument {
	public constructor(that: AsnAgentAnonymousUnblockRTCArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentAnonymousUnblockRTCArgument {
		return new AsnAgentAnonymousUnblockRTCArgument({
			u8sAnonymousUserURI: ""
		});
	}

	/** anonymous user URI - which will be unblocked again */
	public u8sAnonymousUserURI!: string;
	/** (optional) List of internal users who are affected by unblocking */
	public listu8sAffectedURIs?: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode] AsnAgentAnonymousUnblockRTCResult
// [PrintTSSeqDefCode] AsnAgentAnonymousUnblockRTCResult
export class AsnAgentAnonymousUnblockRTCResult {
	public constructor(that: AsnAgentAnonymousUnblockRTCResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentAnonymousUnblockRTCResult {
		return new AsnAgentAnonymousUnblockRTCResult({
			u8sAnonymousUserURI: "",
			bBlockedRTC: false
		});
	}

	/** affected anonymous user URI */
	public u8sAnonymousUserURI!: string;
	/** BlockedState of anonymous User */
	public bBlockedRTC!: boolean;
}

// [PrintTSTypeDefCode] AsnAgentAnonymousIsBlockedRTCForAgentArgument
// [PrintTSSeqDefCode] AsnAgentAnonymousIsBlockedRTCForAgentArgument
export class AsnAgentAnonymousIsBlockedRTCForAgentArgument {
	public constructor(that: AsnAgentAnonymousIsBlockedRTCForAgentArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentAnonymousIsBlockedRTCForAgentArgument {
		return new AsnAgentAnonymousIsBlockedRTCForAgentArgument({
			u8sAnonymousUserURI: "",
			u8sAgentURI: ""
		});
	}

	/** affected anonymous user URI */
	public u8sAnonymousUserURI!: string;
	/** affected agent user URI */
	public u8sAgentURI!: string;
}

// [PrintTSTypeDefCode] AsnAgentAnonymousIsBlockedRTCForAgentResult
// [PrintTSSeqDefCode] AsnAgentAnonymousIsBlockedRTCForAgentResult
export class AsnAgentAnonymousIsBlockedRTCForAgentResult {
	public constructor(that: AsnAgentAnonymousIsBlockedRTCForAgentResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentAnonymousIsBlockedRTCForAgentResult {
		return new AsnAgentAnonymousIsBlockedRTCForAgentResult({
			bBlockedRTC: false
		});
	}

	/** BlockedState of anonymous User */
	public bBlockedRTC!: boolean;
}

// [PrintTSTypeDefCode] AsnAgentGetAgentGroupsForAnonymousArgument
// [PrintTSSeqDefCode] AsnAgentGetAgentGroupsForAnonymousArgument
export class AsnAgentGetAgentGroupsForAnonymousArgument {
	public constructor(that: AsnAgentGetAgentGroupsForAnonymousArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentGetAgentGroupsForAnonymousArgument {
		return new AsnAgentGetAgentGroupsForAnonymousArgument({
			u8sAnonymousUserURI: ""
		});
	}

	/** anonymous user URI */
	public u8sAnonymousUserURI!: string;
}

// [PrintTSTypeDefCode] AsnAgentAnonymousGetAgentsArgument
// [PrintTSSeqDefCode] AsnAgentAnonymousGetAgentsArgument
export class AsnAgentAnonymousGetAgentsArgument {
	public constructor(that?: AsnAgentAnonymousGetAgentsArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentAnonymousGetAgentsArgument {
		return new AsnAgentAnonymousGetAgentsArgument();
	}
}

// [PrintTSTypeDefCode] AsnAgentGroupInfoList
// [PrintTSSetOfDefCode] AsnAgentGroupInfoList
export class AsnAgentGroupInfoList extends Array<AsnAgentGroupInfo> { }

// [PrintTSTypeDefCode] AsnAgentMonitorStartResult
// [PrintTSSeqDefCode] AsnAgentMonitorStartResult
export class AsnAgentMonitorStartResult {
	public constructor(that: AsnAgentMonitorStartResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentMonitorStartResult {
		return new AsnAgentMonitorStartResult({
			u8sMonitorCrossRefID: "",
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	/** CrossrefID for the associated events */
	public u8sMonitorCrossRefID!: string;
	public listGroups!: AsnAgentGroupInfoList;
}

// [PrintTSTypeDefCode] AsnAgentLoginArgument
// [PrintTSSeqDefCode] AsnAgentLoginArgument
/**
 * Parameters for Agent Login
 * Parameters for logging in to the agent\/ACD functionality of the PC server (business card, contact portal) or the telephone system.
 *
 * @private
 */
export class AsnAgentLoginArgument {
	public constructor(that: AsnAgentLoginArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentLoginArgument {
		return new AsnAgentLoginArgument({
			u8sAgentURI: "",
			u8sGroupURI: ""
		});
	}

	/** Group agent URI (LineURI or ContactURI) e.g. \"tel:\" or \"sip:\". */
	public u8sAgentURI!: string;
	/** Group URI (e.g. \"pbxg:601@ECSTA for Panasonic\" or \"sip:mygroup@estos.de\"). */
	public u8sGroupURI!: string;
	/** For PBX ACD, additional data may be required for login. See AsnAgentACDLoginData for details. */
	public acdLoginData?: AsnAgentACDLoginData;
}

// [PrintTSTypeDefCode] AsnAgentSupervisorMonitorStartResult
// [PrintTSSeqDefCode] AsnAgentSupervisorMonitorStartResult
export class AsnAgentSupervisorMonitorStartResult {
	public constructor(that: AsnAgentSupervisorMonitorStartResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentSupervisorMonitorStartResult {
		return new AsnAgentSupervisorMonitorStartResult({
			u8sMonitorCrossRefID: "",
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	/** CrossrefID for associated events */
	public u8sMonitorCrossRefID!: string;
	public listGroups!: AsnAgentGroupInfoList;
}

// [PrintTSTypeDefCode] AsnAgentBroadcastItemList
// [PrintTSSetOfDefCode] AsnAgentBroadcastItemList
export class AsnAgentBroadcastItemList extends Array<AsnAgentBroadcastItem> { }

// [PrintTSTypeDefCode] AsnAgentBroadcastGetItemsForGroupsResult
// [PrintTSSeqDefCode] AsnAgentBroadcastGetItemsForGroupsResult
export class AsnAgentBroadcastGetItemsForGroupsResult {
	public constructor(that: AsnAgentBroadcastGetItemsForGroupsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastGetItemsForGroupsResult {
		return new AsnAgentBroadcastGetItemsForGroupsResult({
			items: new AsnAgentBroadcastItemList()
		});
	}

	public items!: AsnAgentBroadcastItemList;
}

// [PrintTSTypeDefCode] AsnAgentBroadcastEventArgument
// [PrintTSSeqDefCode] AsnAgentBroadcastEventArgument
export class AsnAgentBroadcastEventArgument {
	public constructor(that: AsnAgentBroadcastEventArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentBroadcastEventArgument {
		return new AsnAgentBroadcastEventArgument({
			items: new AsnAgentBroadcastItemList()
		});
	}

	public items!: AsnAgentBroadcastItemList;
}

// [PrintTSTypeDefCode] AsnAgentNotifyGroupsChangedArgument
// [PrintTSSeqDefCode] AsnAgentNotifyGroupsChangedArgument
/**
 * Argument contains the CrossRefID for which the event is sent and the list of the user's agent groups.
 *
 * @private
 */
export class AsnAgentNotifyGroupsChangedArgument {
	public constructor(that: AsnAgentNotifyGroupsChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentNotifyGroupsChangedArgument {
		return new AsnAgentNotifyGroupsChangedArgument({
			u8sMonitorCrossRefID: "",
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	public u8sMonitorCrossRefID!: string;
	/** complete List of groups */
	public listGroups!: AsnAgentGroupInfoList;
}

// [PrintTSTypeDefCode] AsnAgentGetAgentGroupsForAnonymousResult
// [PrintTSSeqDefCode] AsnAgentGetAgentGroupsForAnonymousResult
export class AsnAgentGetAgentGroupsForAnonymousResult {
	public constructor(that: AsnAgentGetAgentGroupsForAnonymousResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentGetAgentGroupsForAnonymousResult {
		return new AsnAgentGetAgentGroupsForAnonymousResult({
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	/** list of agentGroups the anonymous has contacted the agent */
	public listGroups!: AsnAgentGroupInfoList;
}

// [PrintTSTypeDefCode] AsnAgentAnonymousGetAgentsResult
// [PrintTSSeqDefCode] AsnAgentAnonymousGetAgentsResult
export class AsnAgentAnonymousGetAgentsResult {
	public constructor(that: AsnAgentAnonymousGetAgentsResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAgentAnonymousGetAgentsResult {
		return new AsnAgentAnonymousGetAgentsResult({
			listGroups: new AsnAgentGroupInfoList()
		});
	}

	public listGroups!: AsnAgentGroupInfoList;
}
