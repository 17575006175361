// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_ClientPersistence_Converter.ts
 * "UC-Server-Access-Protocol-ClientPersistence" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_ClientPersistence from "./ENetUC_ClientPersistence";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";

// [PrintTSEncoderDecoderCode] AsnClientPersistenceItem
export class AsnClientPersistenceItem_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceItem, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceItem
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceItem", newContext);
		value += TSConverter.addStringParam("u8sItemStore", obj.u8sItemStore, newContext);
		value += TSConverter.addStringParam("u8sItemStoreID", obj.u8sItemStoreID, newContext);
		value += TSConverter.addStringParam("data", obj.data, newContext);
		value += TSConverter.addNumberParam("iRevision", obj.iRevision, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceItem, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceItem");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceItem"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceItem;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceItem
			if (TSConverter.validateParam(data, "u8sItemStore", "string", errors, newContext, false))
				obj.u8sItemStore = data.u8sItemStore;
			if (TSConverter.validateParam(data, "u8sItemStoreID", "string", errors, newContext, false))
				obj.u8sItemStoreID = data.u8sItemStoreID;
			if (TSConverter.validateParam(data, "data", "string", errors, newContext, false))
				obj.data = data.data;
			if (TSConverter.validateParam(data, "iRevision", "number", errors, newContext, true))
				obj.iRevision = data.iRevision;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceItem"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceCreateArgument
export class AsnClientPersistenceCreateArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceCreateArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceCreateArgument", newContext);
		value += TSConverter.addStringParam("u8sCrossRefID", obj.u8sCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sItemStore", obj.u8sItemStore, newContext);
		value += TSConverter.addStringParam("u8sItemStoreID", obj.u8sItemStoreID, newContext);
		value += TSConverter.addStringParam("data", obj.data, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceCreateArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceCreateArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceCreateArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceCreateArgument
			if (TSConverter.validateParam(data, "u8sCrossRefID", "string", errors, newContext, false))
				obj.u8sCrossRefID = data.u8sCrossRefID;
			if (TSConverter.validateParam(data, "u8sItemStore", "string", errors, newContext, false))
				obj.u8sItemStore = data.u8sItemStore;
			if (TSConverter.validateParam(data, "u8sItemStoreID", "string", errors, newContext, true))
				obj.u8sItemStoreID = data.u8sItemStoreID;
			if (TSConverter.validateParam(data, "data", "string", errors, newContext, false))
				obj.data = data.data;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceCreateArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceCreateResult
export class AsnClientPersistenceCreateResult_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceCreateResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceCreateResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceCreateResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceCreateResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceCreateResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceCreateResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceCreateResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceCreateResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceCreateResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceReadArgument
export class AsnClientPersistenceReadArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceReadArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceReadArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceReadArgument", newContext);
		value += TSConverter.addStringParam("u8sCrossRefID", obj.u8sCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sItemStore", obj.u8sItemStore, newContext);
		value += TSConverter.addStringParam("u8sItemStoreID", obj.u8sItemStoreID, newContext);
		value += TSConverter.addNumberParam("iLastKnownGlobalRevision", obj.iLastKnownGlobalRevision, newContext);
		value += TSConverter.addNumberParam("iItemKeyWildcardType", obj.iItemKeyWildcardType, newContext);
		value += TSConverter.addBooleanParam("bCountAvailableItemsOnly", obj.bCountAvailableItemsOnly, newContext);
		value += TSConverter.addNumberParam("iItemLimit", obj.iItemLimit, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceReadArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceReadArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceReadArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceReadArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceReadArgument
			if (TSConverter.validateParam(data, "u8sCrossRefID", "string", errors, newContext, false))
				obj.u8sCrossRefID = data.u8sCrossRefID;
			if (TSConverter.validateParam(data, "u8sItemStore", "string", errors, newContext, false))
				obj.u8sItemStore = data.u8sItemStore;
			if (TSConverter.validateParam(data, "u8sItemStoreID", "string", errors, newContext, true))
				obj.u8sItemStoreID = data.u8sItemStoreID;
			if (TSConverter.validateParam(data, "iLastKnownGlobalRevision", "number", errors, newContext, true))
				obj.iLastKnownGlobalRevision = data.iLastKnownGlobalRevision;
			if (TSConverter.validateParam(data, "iItemKeyWildcardType", "number", errors, newContext, true))
				obj.iItemKeyWildcardType = data.iItemKeyWildcardType;
			if (TSConverter.validateParam(data, "bCountAvailableItemsOnly", "boolean", errors, newContext, true))
				obj.bCountAvailableItemsOnly = data.bCountAvailableItemsOnly;
			if (TSConverter.validateParam(data, "iItemLimit", "number", errors, newContext, true))
				obj.iItemLimit = data.iItemLimit;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceReadArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceReadResult
export class AsnClientPersistenceReadResult_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceReadResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceReadResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceReadResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceReadResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceReadResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceReadResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceReadResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceReadResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceReadResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceUpdateArgument
export class AsnClientPersistenceUpdateArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceUpdateArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceUpdateArgument", newContext);
		value += TSConverter.addStringParam("u8sCrossRefID", obj.u8sCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sItemStore", obj.u8sItemStore, newContext);
		value += TSConverter.addStringParam("u8sItemStoreID", obj.u8sItemStoreID, newContext);
		value += TSConverter.addStringParam("data", obj.data, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceUpdateArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceUpdateArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceUpdateArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceUpdateArgument
			if (TSConverter.validateParam(data, "u8sCrossRefID", "string", errors, newContext, false))
				obj.u8sCrossRefID = data.u8sCrossRefID;
			if (TSConverter.validateParam(data, "u8sItemStore", "string", errors, newContext, false))
				obj.u8sItemStore = data.u8sItemStore;
			if (TSConverter.validateParam(data, "u8sItemStoreID", "string", errors, newContext, false))
				obj.u8sItemStoreID = data.u8sItemStoreID;
			if (TSConverter.validateParam(data, "data", "string", errors, newContext, false))
				obj.data = data.data;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceUpdateArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceUpdateResult
export class AsnClientPersistenceUpdateResult_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceUpdateResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceUpdateResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceUpdateResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceUpdateResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceUpdateResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceUpdateResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceUpdateResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceDeleteArgument
export class AsnClientPersistenceDeleteArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceDeleteArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceDeleteArgument", newContext);
		value += TSConverter.addStringParam("u8sCrossRefID", obj.u8sCrossRefID, newContext);
		value += TSConverter.addStringParam("u8sItemStore", obj.u8sItemStore, newContext);
		value += TSConverter.addStringParam("u8sItemStoreID", obj.u8sItemStoreID, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceDeleteArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceDeleteArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceDeleteArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceDeleteArgument
			if (TSConverter.validateParam(data, "u8sCrossRefID", "string", errors, newContext, false))
				obj.u8sCrossRefID = data.u8sCrossRefID;
			if (TSConverter.validateParam(data, "u8sItemStore", "string", errors, newContext, false))
				obj.u8sItemStore = data.u8sItemStore;
			if (TSConverter.validateParam(data, "u8sItemStoreID", "string", errors, newContext, false))
				obj.u8sItemStoreID = data.u8sItemStoreID;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceDeleteArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceDeleteResult
export class AsnClientPersistenceDeleteResult_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceDeleteResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceDeleteResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceDeleteResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceDeleteResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceDeleteResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceDeleteResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceDeleteResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceSubscribeArgument
export class AsnClientPersistenceSubscribeArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceSubscribeArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceSubscribeArgument", newContext);
		value += TSConverter.addStringParam("u8sCrossRefID", obj.u8sCrossRefID, newContext);
		value += TSConverter.addNumberParam("iSubscribeType", obj.iSubscribeType, newContext);
		value += TSConverter.addStringParam("u8sItemStore", obj.u8sItemStore, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceSubscribeArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceSubscribeArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceSubscribeArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceSubscribeArgument
			if (TSConverter.validateParam(data, "u8sCrossRefID", "string", errors, newContext, false))
				obj.u8sCrossRefID = data.u8sCrossRefID;
			if (TSConverter.validateParam(data, "iSubscribeType", "number", errors, newContext, false))
				obj.iSubscribeType = data.iSubscribeType;
			if (TSConverter.validateParam(data, "u8sItemStore", "string", errors, newContext, false))
				obj.u8sItemStore = data.u8sItemStore;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceSubscribeArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceSubscribeResult
export class AsnClientPersistenceSubscribeResult_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceSubscribeResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceSubscribeResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceSubscribeResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceSubscribeResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceSubscribeResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceSubscribeResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceSubscribeResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceGetDatabaseIDArgument
export class AsnClientPersistenceGetDatabaseIDArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceGetDatabaseIDArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceGetDatabaseIDArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceGetDatabaseIDArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceGetDatabaseIDArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceGetDatabaseIDArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceGetDatabaseIDArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceGetDatabaseIDResult
export class AsnClientPersistenceGetDatabaseIDResult_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceGetDatabaseIDResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceGetDatabaseIDResult", newContext);
		value += TSConverter.addStringParam("u8sDatabaseID", obj.u8sDatabaseID, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceGetDatabaseIDResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceGetDatabaseIDResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceGetDatabaseIDResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceGetDatabaseIDResult
			if (TSConverter.validateParam(data, "u8sDatabaseID", "string", errors, newContext, false))
				obj.u8sDatabaseID = data.u8sDatabaseID;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceGetDatabaseIDResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceItemList
export class AsnClientPersistenceItemList_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceItemList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnClientPersistenceItemList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnClientPersistenceItem_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceItemList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceItemList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceItemList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceItemList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnClientPersistenceItemList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientPersistence.AsnClientPersistenceItem.initEmpty();
				if (AsnClientPersistenceItem_Converter.fromJSON(elem, entry, errors, newContext, "AsnClientPersistenceItem", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceItemList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientPersistenceEventArgument
export class AsnClientPersistenceEventArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientPersistence.AsnClientPersistenceEventArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientPersistenceEventArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientPersistenceEventArgument", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addStringParam("u8sCrossRefID", obj.u8sCrossRefID, newContext);
		value += TSConverter.addNumberParam("iGlobalRevision", obj.iGlobalRevision, newContext);
		value += TSConverter.addObjectAsStringParam("changedItems", AsnClientPersistenceItemList_Converter.toJSON(obj.changedItems, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("deletedItems", AsnClientPersistenceItemList_Converter.toJSON(obj.deletedItems, newContext), newContext);
		value += TSConverter.addStringParam("u8sDatabaseID", obj.u8sDatabaseID, newContext);
		value += TSConverter.addNumberParam("iAvailableItemsCount", obj.iAvailableItemsCount, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientPersistence.AsnClientPersistenceEventArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientPersistenceEventArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceEventArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientPersistence.AsnClientPersistenceEventArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientPersistenceEventArgument
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			if (TSConverter.validateParam(data, "u8sCrossRefID", "string", errors, newContext, false))
				obj.u8sCrossRefID = data.u8sCrossRefID;
			if (TSConverter.validateParam(data, "iGlobalRevision", "number", errors, newContext, true))
				obj.iGlobalRevision = data.iGlobalRevision;
			const tscv_changeditems = new ENetUC_ClientPersistence.AsnClientPersistenceItemList();
			if (AsnClientPersistenceItemList_Converter.fromJSON(data.changedItems, tscv_changeditems, errors, newContext, "changedItems", true))
				obj.changedItems = tscv_changeditems;
			const tscv_deleteditems = new ENetUC_ClientPersistence.AsnClientPersistenceItemList();
			if (AsnClientPersistenceItemList_Converter.fromJSON(data.deletedItems, tscv_deleteditems, errors, newContext, "deletedItems", true))
				obj.deletedItems = tscv_deleteditems;
			if (TSConverter.validateParam(data, "u8sDatabaseID", "string", errors, newContext, true))
				obj.u8sDatabaseID = data.u8sDatabaseID;
			if (TSConverter.validateParam(data, "iAvailableItemsCount", "number", errors, newContext, true))
				obj.iAvailableItemsCount = data.iAvailableItemsCount;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientPersistenceEventArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
