// The idea is to have the generic basic singletons available in the web-shared-components.
// The singletons must be initialized by the main application.
// To avoid confusion, we call them here "theSharedSomeSingleton" and in the main application "theSomeSingleton".
// Other helpers that don't need initialization should work directly.
// We can put here all the helper / singletons that have no relations with a specific application,
// and can be used in common (e.g.: we cannot have here a wrapper for a state manager).
import { Logger } from "./logger/Logger";
import { BrowserDetector } from "./browser-detector/BrowserDetector";
import { TranslationManager } from "../managers/translationManager";

export const theSharedLogger = Logger.getInstance();
export const theSharedBrowser = BrowserDetector.getInstance();
export const theSharedTranslationManager = TranslationManager.getInstance();



export const browserIsFirefoxOrSafari = (): boolean => {
	return theSharedBrowser.isFirefox() || theSharedBrowser.isSafari();
};

/**
 * Adjust the size of a FontAwesomeIcon depending on the type of the browser
 *
 * @param chromeSize - the FontAwesomeIcon transform value for chrome
 * @returns a string for a FontAwesomeIcon transform value ("shrink-...") adapted to the different browsers.
 */
export const setFAIconShrinkSize = (chromeSize: number): string => {
	let shrink = "shrink-";

	if (browserIsFirefoxOrSafari()) {
		if (chromeSize === 5)
			shrink += "2";
		else
			shrink += chromeSize.toString();
	} else
		shrink += chromeSize.toString();

	return shrink;
};
