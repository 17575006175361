// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_ClientCapabilities.ts
 * "UC-Server-Access-Protocol-ClientCapabilities" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * Data structures and methods to transport and notify client capabilities between clients and the server
 * Each client has certain capabilities he is able to handle. They are used to tell other clients the kind of interactions each client is able to process.
 * If a client wants to start an audio call to another client both clients have to be able to process audio (speakers and microphone present).
 * As soon as a client is capable to handle audio it tells the server and thus other clients that the client is now ready to process audio requests.
 * Beside well known capabilities clients may exchange customs capabilities. (e.g. client is capable for some sort of sharing and the appropriate sharing resource id)
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";

// [PrintTSTypeDefCode] AsnClientCapabilityEnum
// [PrintTSEnumDefCode] AsnClientCapabilityEnum
export enum AsnClientCapabilityEnum {
	/** nothing, dummy value to set an empty list */
	bNothing = 0,
	/** client\/connection is capable to handle audio */
	bAudio = 1,
	/** client\/connection is capable to handle video */
	bVideo = 2,
	/** client\/connection is capable to handle desktop\/screen sharing */
	bScreenShare = 3,
	/** client\/connection is capable to replicate exchange calendar data */
	bCalendarExchange = 4,
	/** client\/connection is capable to replicate notes calendar data */
	bCalendarNotes = 5,
	/** client\/connection is capable to control a camera for video (movement, zoom, ...) */
	bCameraControl = 6,
	/** client\/connection is capable to handle public group chats via LiveChat */
	bPublicChat = 7,
	/** client\/connection is capable to handle public audio\/video calls via LiveChat */
	bPublicCall = 8,
	/** client\/connection is capable to handle conferencing */
	bConferencing = 9
}

// [PrintTSTypeDefCode] AsnClientCapabilitiesV2
// [PrintTSSeqDefCode] AsnClientCapabilitiesV2
/**
 * Data structure to transport capabilities a client\/connection is capable to handle
 * This data structure is used to transport client capabilities between the clients and the server
 * In an invoke towards the server the client tells what the client is capable to handle. This list
 * contains well known properties as well as custom properties a client wants to exchange with other clients.
 * Well known capabilites are an integer list which contains enums values of the AsnClientCapabilityEnum.
 * Additionaly clients may specify additional information they need to exchange with other clients using the customCapabilities
 * The serve builds a sum over all connected clients an notifies clients via the PresenceV2 implementation or changed events
 * within this interface.
 */
export class AsnClientCapabilitiesV2 {
	public constructor(that: AsnClientCapabilitiesV2) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientCapabilitiesV2 {
		return new AsnClientCapabilitiesV2({
			eClientCapabilities: new ENetUC_Common.SEQInteger(),
			customCapabilities: new ENetUC_Common.AsnStringPairList()
		});
	}

	/** List of capabilities the client or connection is capable to handle */
	public eClientCapabilities!: ENetUC_Common.SEQInteger;
	/** Custom capabilites clients share with each other as a name:value list */
	public customCapabilities!: ENetUC_Common.AsnStringPairList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetClientCapabilitiesV2Result
// [PrintTSSeqDefCode] AsnSetClientCapabilitiesV2Result
export class AsnSetClientCapabilitiesV2Result {
	public constructor(that?: AsnSetClientCapabilitiesV2Result) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientCapabilitiesV2Result {
		return new AsnSetClientCapabilitiesV2Result();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetClientCapabilitiesV2Argument
// [PrintTSSeqDefCode] AsnGetClientCapabilitiesV2Argument
/**
 * Argument for the asnGetClientCapabilitiesV2 event
 */
export class AsnGetClientCapabilitiesV2Argument {
	public constructor(that: AsnGetClientCapabilitiesV2Argument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetClientCapabilitiesV2Argument {
		return new AsnGetClientCapabilitiesV2Argument({
			u8slistContactIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	/** ContactIDs we want to have the capabilities for */
	public u8slistContactIDs!: ENetUC_Common.UTF8StringList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetClientCapabilitiesArgument
// [PrintTSSeqDefCode] AsnSetClientCapabilitiesArgument
export class AsnSetClientCapabilitiesArgument {
	public constructor(that: AsnSetClientCapabilitiesArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientCapabilitiesArgument {
		return new AsnSetClientCapabilitiesArgument({
			osXMLContent: ""
		});
	}

	/** Die XML-Daten mit den Client Capabilities (Codierung laut XML-Inhalt Default UTF-8) */
	public osXMLContent!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnClientCapabilities
// [PrintTSSeqDefCode] AsnClientCapabilities
export class AsnClientCapabilities {
	public constructor(that: AsnClientCapabilities) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientCapabilities {
		return new AsnClientCapabilities({
			u8sContactID: "",
			osXMLContent: ""
		});
	}

	/** Die ContactID des Kontakts, dessen Capabilities sich geÃ¤ndert haben. */
	public u8sContactID!: string;
	/** Die XML-Daten mit den Client Capabilities (Codierung laut XML-Inhalt Default UTF-8) */
	public osXMLContent!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetClientCapabilitiesArgument
// [PrintTSSeqDefCode] AsnGetClientCapabilitiesArgument
export class AsnGetClientCapabilitiesArgument {
	public constructor(that: AsnGetClientCapabilitiesArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetClientCapabilitiesArgument {
		return new AsnGetClientCapabilitiesArgument({
			u8slistContactIDs: new ENetUC_Common.UTF8StringList()
		});
	}

	/** Die ContactIDs der Benutzer deren ClientCapabilities abgeholt werden sollen. Ein angemeldeter Admin kann mit einer ContactID '*' als ersten Eintrag alle CLientProfiles abholen. */
	public u8slistContactIDs!: ENetUC_Common.UTF8StringList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnClientCapabilitiesEntry
// [PrintTSSeqDefCode] AsnClientCapabilitiesEntry
export class AsnClientCapabilitiesEntry {
	public constructor(that: AsnClientCapabilitiesEntry) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientCapabilitiesEntry {
		return new AsnClientCapabilitiesEntry({
			u8sContactID: "",
			osXMLContent: ""
		});
	}

	/** ContactID des Benutzers, fÃÂ¼r den die folgenden ClientCapabilities gelten */
	public u8sContactID!: string;
	/** Die XML-Daten mit den Client Capabilities (Codierung laut XML-Inhalt Default UTF-8) */
	public osXMLContent!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnClientCapabilitiesV2ByID
// [PrintTSSeqDefCode] AsnClientCapabilitiesV2ByID
/**
 * Client Capabilities with the user identifier to whom they belong
 */
export class AsnClientCapabilitiesV2ByID {
	public constructor(that: AsnClientCapabilitiesV2ByID) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientCapabilitiesV2ByID {
		return new AsnClientCapabilitiesV2ByID({
			u8sID: "",
			capabilities: AsnClientCapabilitiesV2.initEmpty()
		});
	}

	/** ContactID or Connection GUI the clientCaps belong to */
	public u8sID!: string;
	/** The client capabilities */
	public capabilities!: AsnClientCapabilitiesV2;
}

// [PrintTSTypeDefCode] AsnClientCapabilitiesV2ByIDList
// [PrintTSSetOfDefCode] AsnClientCapabilitiesV2ByIDList
export class AsnClientCapabilitiesV2ByIDList extends Array<AsnClientCapabilitiesV2ByID> { }

// [PrintTSTypeDefCode] AsnSetClientCapabilitiesV2Argument
// [PrintTSSeqDefCode] AsnSetClientCapabilitiesV2Argument
/**
 * Argument for the asnSetClientCapabilitiesV2 event
 */
export class AsnSetClientCapabilitiesV2Argument {
	public constructor(that: AsnSetClientCapabilitiesV2Argument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientCapabilitiesV2Argument {
		return new AsnSetClientCapabilitiesV2Argument({
			capabilities: AsnClientCapabilitiesV2.initEmpty()
		});
	}

	public capabilities!: AsnClientCapabilitiesV2;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetClientCapabilitiesV2Result
// [PrintTSSeqDefCode] AsnGetClientCapabilitiesV2Result
export class AsnGetClientCapabilitiesV2Result {
	public constructor(that: AsnGetClientCapabilitiesV2Result) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetClientCapabilitiesV2Result {
		return new AsnGetClientCapabilitiesV2Result({
			capabilities: new AsnClientCapabilitiesV2ByIDList()
		});
	}

	/** List of capabilities */
	public capabilities!: AsnClientCapabilitiesV2ByIDList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnClientCapabilitiesV2ChangedArgument
// [PrintTSSeqDefCode] AsnClientCapabilitiesV2ChangedArgument
/**
 * Argument for the asnClientCapabilitiesV2Changed method
 */
export class AsnClientCapabilitiesV2ChangedArgument {
	public constructor(that: AsnClientCapabilitiesV2ChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientCapabilitiesV2ChangedArgument {
		return new AsnClientCapabilitiesV2ChangedArgument({
			capabilities: new AsnClientCapabilitiesV2ByIDList()
		});
	}

	/** Client capabilities by user list */
	public capabilities!: AsnClientCapabilitiesV2ByIDList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnClientCapabilitiesList
// [PrintTSSetOfDefCode] AsnClientCapabilitiesList
export class AsnClientCapabilitiesList extends Array<AsnClientCapabilities> { }

// [PrintTSTypeDefCode] AsnClientCapabilitiesChangedArgument
// [PrintTSSeqDefCode] AsnClientCapabilitiesChangedArgument
export class AsnClientCapabilitiesChangedArgument {
	public constructor(that: AsnClientCapabilitiesChangedArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnClientCapabilitiesChangedArgument {
		return new AsnClientCapabilitiesChangedArgument({
			listCapabilities: new AsnClientCapabilitiesList()
		});
	}

	public listCapabilities!: AsnClientCapabilitiesList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnClientCapabilitiesEntryList
// [PrintTSSetOfDefCode] AsnClientCapabilitiesEntryList
export class AsnClientCapabilitiesEntryList extends Array<AsnClientCapabilitiesEntry> { }

// [PrintTSTypeDefCode] AsnGetClientCapabilitiesResult
// [PrintTSSeqDefCode] AsnGetClientCapabilitiesResult
export class AsnGetClientCapabilitiesResult {
	public constructor(that: AsnGetClientCapabilitiesResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetClientCapabilitiesResult {
		return new AsnGetClientCapabilitiesResult({
			listClientCapabilities: new AsnClientCapabilitiesEntryList()
		});
	}

	/** Liste der angeforderten ClientCapabilities; */
	public listClientCapabilities!: AsnClientCapabilitiesEntryList;
	/** IDs fÃÂ¼r die die Benutzer keine Capabilities haben (offline oder haben eben keine) */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}
