import {
	faArrowRightLong,
	faEnvelope,
	faHeadphones,
	faMobile,
	faPhone,
	faVideo
} from "@fortawesome/pro-light-svg-icons";

import { AsnNetDatabaseJournalCallType } from "../ucserver/stubs/ENetUC_Journal";

const callTypeToIcon = {
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEPBX]: faPhone,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEVIDEOCHAT]: faPhone,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOCHAT]: faHeadphones,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEAUDIOVIDEOCHAT]: faVideo,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEIM]: faEnvelope,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEBLUETOOTHMOBILE]: faMobile,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPEBLUETOOTHMOBILEUNKNOWNDURATION]: faArrowRightLong,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPESIPAVCALL]: faPhone,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPESMS]: faArrowRightLong,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPESCREENSHARING]: faArrowRightLong,
	[AsnNetDatabaseJournalCallType.eJOURNALCALLTYPERSCREENSHARING]: faArrowRightLong
};

export const mapCallTypeToIcon = (callType: AsnNetDatabaseJournalCallType) => {
	return callTypeToIcon[callType];
};
