// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetROSEInterface_Converter.ts
 * "UC-Server-Access-Protocol-Main" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetROSEInterface from "./ENetROSEInterface";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
import * as ENetUC_Common_Appointments from "./ENetUC_Common_Appointments";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";
import * as ENetUC_Common_AsnContact_Converter from "./ENetUC_Common_AsnContact_Converter";
import * as ENetUC_Common_Appointments_Converter from "./ENetUC_Common_Appointments_Converter";

// [PrintTSEncoderDecoderCode] AsnLineForwardProfileChoice
export class AsnLineForwardProfileChoice_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnLineForwardProfileChoice, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderChoiceDefCode] AsnLineForwardProfileChoice
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLineForwardProfileChoice", newContext);
		if (obj.iAllUserLines != null)
			value += TSConverter.addNumberParam("iAllUserLines", obj.iAllUserLines, newContext);
		else if (obj.u8sSingleLineURI != null)
			value += TSConverter.addStringParam("u8sSingleLineURI", obj.u8sSingleLineURI, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnLineForwardProfileChoice, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLineForwardProfileChoice");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLineForwardProfileChoice"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnLineForwardProfileChoice;
		if (data !== undefined) {
			// [PrintTSDecoderChoiceDefCode] AsnLineForwardProfileChoice
			newContext.bLaxParsing = false;
			if (TSConverter.validateChoiceParam(data, "iAllUserLines", "number", undefined, newContext))
				obj.iAllUserLines = data.iAllUserLines;
			else if (TSConverter.validateChoiceParam(data, "u8sSingleLineURI", "string", undefined, newContext))
				obj.u8sSingleLineURI = data.u8sSingleLineURI;
			else if (!(optional === true))
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLineForwardProfileChoice"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNumberFormatRegExpression
export class AsnNumberFormatRegExpression_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnNumberFormatRegExpression, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNumberFormatRegExpression
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNumberFormatRegExpression", newContext);
		value += TSConverter.addStringParam("u8sSearch", obj.u8sSearch, newContext);
		value += TSConverter.addStringParam("u8sReplace", obj.u8sReplace, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnNumberFormatRegExpression, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNumberFormatRegExpression");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpression"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnNumberFormatRegExpression;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNumberFormatRegExpression
			if (TSConverter.validateParam(data, "u8sSearch", "string", errors, newContext, false))
				obj.u8sSearch = data.u8sSearch;
			if (TSConverter.validateParam(data, "u8sReplace", "string", errors, newContext, false))
				obj.u8sReplace = data.u8sReplace;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpression"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNumberFormatRegExpression2
export class AsnNumberFormatRegExpression2_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnNumberFormatRegExpression2, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNumberFormatRegExpression2
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNumberFormatRegExpression2", newContext);
		value += TSConverter.addStringParam("u8sSearch", obj.u8sSearch, newContext);
		value += TSConverter.addStringParam("u8sReplace", obj.u8sReplace, newContext);
		value += TSConverter.addBooleanParam("bMatchReplace", obj.bMatchReplace, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnNumberFormatRegExpression2, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNumberFormatRegExpression2");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpression2"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnNumberFormatRegExpression2;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNumberFormatRegExpression2
			if (TSConverter.validateParam(data, "u8sSearch", "string", errors, newContext, false))
				obj.u8sSearch = data.u8sSearch;
			if (TSConverter.validateParam(data, "u8sReplace", "string", errors, newContext, false))
				obj.u8sReplace = data.u8sReplace;
			if (TSConverter.validateParam(data, "bMatchReplace", "boolean", errors, newContext, false))
				obj.bMatchReplace = data.bMatchReplace;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpression2"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnKeepAliveArgument
export class AsnKeepAliveArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnKeepAliveArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnKeepAliveArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnKeepAliveArgument", newContext);
		value += TSConverter.addObjectAsStringParam("dummy", JSON.stringify(obj.dummy), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnKeepAliveArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnKeepAliveArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnKeepAliveArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnKeepAliveArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnKeepAliveArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnKeepAliveArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetLocationInformationArgument
export class AsnGetLocationInformationArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetLocationInformationArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetLocationInformationArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetLocationInformationArgument", newContext);
		value += TSConverter.addStringParam("u8sLocationID", obj.u8sLocationID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetLocationInformationArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetLocationInformationArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationInformationArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetLocationInformationArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetLocationInformationArgument
			if (TSConverter.validateParam(data, "u8sLocationID", "string", errors, newContext, false))
				obj.u8sLocationID = data.u8sLocationID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationInformationArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetLocationInformation2Result
export class AsnSetLocationInformation2Result_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetLocationInformation2Result, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetLocationInformation2Result
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetLocationInformation2Result", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetLocationInformation2Result, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetLocationInformation2Result");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetLocationInformation2Result"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetLocationInformation2Result;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetLocationInformation2Result
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetLocationInformation2Result"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetLocationIDsArgument
export class AsnGetLocationIDsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetLocationIDsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetLocationIDsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetLocationIDsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("locationIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.locationIDs, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetLocationIDsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetLocationIDsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationIDsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetLocationIDsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetLocationIDsArgument
			const tscv_locationids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.locationIDs, tscv_locationids, errors, newContext, "locationIDs", true))
				obj.locationIDs = tscv_locationids;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationIDsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetLocationIDsResult
export class AsnGetLocationIDsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetLocationIDsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetLocationIDsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetLocationIDsResult", newContext);
		value += TSConverter.addObjectAsStringParam("locationIDMD5ChecksumList", ENetUC_Common_Converter.AsnStringPairList_Converter.toJSON(obj.locationIDMD5ChecksumList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetLocationIDsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetLocationIDsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationIDsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetLocationIDsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetLocationIDsResult
			const tscv_locationidmd5checksumlist = new ENetUC_Common.AsnStringPairList();
			if (ENetUC_Common_Converter.AsnStringPairList_Converter.fromJSON(data.locationIDMD5ChecksumList, tscv_locationidmd5checksumlist, errors, newContext, "locationIDMD5ChecksumList", false))
				obj.locationIDMD5ChecksumList = tscv_locationidmd5checksumlist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationIDsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLocationInformationChangedArgument
export class AsnLocationInformationChangedArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnLocationInformationChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnLocationInformationChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLocationInformationChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sLocationID", obj.u8sLocationID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnLocationInformationChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLocationInformationChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLocationInformationChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnLocationInformationChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnLocationInformationChangedArgument
			if (TSConverter.validateParam(data, "u8sLocationID", "string", errors, newContext, false))
				obj.u8sLocationID = data.u8sLocationID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLocationInformationChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetLicensedFeatureCountArgument
export class AsnGetLicensedFeatureCountArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetLicensedFeatureCountArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetLicensedFeatureCountArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetLicensedFeatureCountArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetLicensedFeatureCountArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetLicensedFeatureCountArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLicensedFeatureCountArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetLicensedFeatureCountArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetLicensedFeatureCountArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLicensedFeatureCountArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetLicensedFeatureCountResult
export class AsnGetLicensedFeatureCountResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetLicensedFeatureCountResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetLicensedFeatureCountResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetLicensedFeatureCountResult", newContext);
		value += TSConverter.addObjectAsStringParam("licensedFeatures", ENetUC_Common_Converter.AsnIntegerPairList_Converter.toJSON(obj.licensedFeatures, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetLicensedFeatureCountResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetLicensedFeatureCountResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLicensedFeatureCountResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetLicensedFeatureCountResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetLicensedFeatureCountResult
			const tscv_licensedfeatures = new ENetUC_Common.AsnIntegerPairList();
			if (ENetUC_Common_Converter.AsnIntegerPairList_Converter.fromJSON(data.licensedFeatures, tscv_licensedfeatures, errors, newContext, "licensedFeatures", false))
				obj.licensedFeatures = tscv_licensedfeatures;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLicensedFeatureCountResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetAdministrativeMonitorContentArgument
export class AsnGetAdministrativeMonitorContentArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetAdministrativeMonitorContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetAdministrativeMonitorContentArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetAdministrativeMonitorContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAdministrativeMonitorContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetAdministrativeMonitorContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetAdministrativeMonitorContentArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAdministrativeMonitorContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetAdministrativeMonitorContentResult
export class AsnGetAdministrativeMonitorContentResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetAdministrativeMonitorContentResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetAdministrativeMonitorContentResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetAdministrativeMonitorContentResult", newContext);
		value += TSConverter.addNumberParam("iDisableAllMonitorConfiguration", obj.iDisableAllMonitorConfiguration, newContext);
		value += TSConverter.addObjectAsStringParam("listContactIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.listContactIDs, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetAdministrativeMonitorContentResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetAdministrativeMonitorContentResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAdministrativeMonitorContentResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetAdministrativeMonitorContentResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetAdministrativeMonitorContentResult
			if (TSConverter.validateParam(data, "iDisableAllMonitorConfiguration", "number", errors, newContext, false))
				obj.iDisableAllMonitorConfiguration = data.iDisableAllMonitorConfiguration;
			const tscv_listcontactids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.listContactIDs, tscv_listcontactids, errors, newContext, "listContactIDs", false))
				obj.listContactIDs = tscv_listcontactids;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAdministrativeMonitorContentResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnReloadAdministrativeMonitorContentArgument
export class AsnReloadAdministrativeMonitorContentArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnReloadAdministrativeMonitorContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnReloadAdministrativeMonitorContentArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnReloadAdministrativeMonitorContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnReloadAdministrativeMonitorContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnReloadAdministrativeMonitorContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnReloadAdministrativeMonitorContentArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnReloadAdministrativeMonitorContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnXMLClientContent
export class AsnXMLClientContent_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnXMLClientContent, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnXMLClientContent
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnXMLClientContent", newContext);
		value += TSConverter.addStringParam("osXMLContent", obj.osXMLContent, newContext);
		value += TSConverter.addStringParam("osXMLContentDescription", obj.osXMLContentDescription, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnXMLClientContent, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnXMLClientContent");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnXMLClientContent"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnXMLClientContent;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnXMLClientContent
			if (TSConverter.validateParam(data, "osXMLContent", "string", errors, newContext, false))
				obj.osXMLContent = data.osXMLContent;
			if (TSConverter.validateParam(data, "osXMLContentDescription", "string", errors, newContext, false))
				obj.osXMLContentDescription = data.osXMLContentDescription;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnXMLClientContent"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetClientXMLContentArgument
export class AsnGetClientXMLContentArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetClientXMLContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetClientXMLContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetClientXMLContentArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetClientXMLContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetClientXMLContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientXMLContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetClientXMLContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetClientXMLContentArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientXMLContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetClientXMLContentResult
export class AsnSetClientXMLContentResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetClientXMLContentResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetClientXMLContentResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetClientXMLContentResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetClientXMLContentResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetClientXMLContentResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientXMLContentResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetClientXMLContentResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetClientXMLContentResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientXMLContentResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRetriggerFederationConnectionArgument
export class AsnRetriggerFederationConnectionArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnRetriggerFederationConnectionArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRetriggerFederationConnectionArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRetriggerFederationConnectionArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnRetriggerFederationConnectionArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRetriggerFederationConnectionArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRetriggerFederationConnectionArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnRetriggerFederationConnectionArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRetriggerFederationConnectionArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRetriggerFederationConnectionArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnActionFilterEntry
export class AsnActionFilterEntry_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnActionFilterEntry, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnActionFilterEntry
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnActionFilterEntry", newContext);
		value += TSConverter.addStringParam("u8sFilterFieldName", obj.u8sFilterFieldName, newContext);
		value += TSConverter.addStringParam("u8sFilterFieldExpression", obj.u8sFilterFieldExpression, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnActionFilterEntry, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnActionFilterEntry");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnActionFilterEntry"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnActionFilterEntry;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnActionFilterEntry
			if (TSConverter.validateParam(data, "u8sFilterFieldName", "string", errors, newContext, false))
				obj.u8sFilterFieldName = data.u8sFilterFieldName;
			if (TSConverter.validateParam(data, "u8sFilterFieldExpression", "string", errors, newContext, false))
				obj.u8sFilterFieldExpression = data.u8sFilterFieldExpression;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnActionFilterEntry"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetAppointmentForwardsResult
export class AsnSetAppointmentForwardsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetAppointmentForwardsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetAppointmentForwardsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetAppointmentForwardsResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetAppointmentForwardsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetAppointmentForwardsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetAppointmentForwardsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetAppointmentForwardsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetAppointmentForwardsResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetAppointmentForwardsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetAppointmentForwardsArgument
export class AsnGetAppointmentForwardsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetAppointmentForwardsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetAppointmentForwardsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetAppointmentForwardsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetAppointmentForwardsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetAppointmentForwardsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAppointmentForwardsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetAppointmentForwardsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetAppointmentForwardsArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAppointmentForwardsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnOperationCompleteArgument
export class AsnOperationCompleteArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnOperationCompleteArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnOperationCompleteArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnOperationCompleteArgument", newContext);
		value += TSConverter.addStringParam("u8sCompletionID", obj.u8sCompletionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnOperationCompleteArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnOperationCompleteArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnOperationCompleteArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnOperationCompleteArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnOperationCompleteArgument
			if (TSConverter.validateParam(data, "u8sCompletionID", "string", errors, newContext, false))
				obj.u8sCompletionID = data.u8sCompletionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnOperationCompleteArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnOpenContactsResult
export class AsnOpenContactsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnOpenContactsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnOpenContactsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnOpenContactsResult", newContext);
		value += TSConverter.addObjectAsStringParam("contacts", ENetUC_Common_AsnContact_Converter.AsnContacts_Converter.toJSON(obj.contacts, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnOpenContactsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnOpenContactsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnOpenContactsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnOpenContactsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnOpenContactsResult
			const tscv_contacts = new ENetUC_Common_AsnContact.AsnContacts();
			if (ENetUC_Common_AsnContact_Converter.AsnContacts_Converter.fromJSON(data.contacts, tscv_contacts, errors, newContext, "contacts", false))
				obj.contacts = tscv_contacts;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnOpenContactsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnOpenContactExArgument
export class AsnOpenContactExArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnOpenContactExArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnOpenContactExArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnOpenContactExArgument", newContext);
		value += TSConverter.addObjectAsStringParam("asnNetDatabaseContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.asnNetDatabaseContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnOpenContactExArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnOpenContactExArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnOpenContactExArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnOpenContactExArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnOpenContactExArgument
			const tscv_asnnetdatabasecontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.asnNetDatabaseContact, tscv_asnnetdatabasecontact, errors, newContext, "asnNetDatabaseContact", false))
				obj.asnNetDatabaseContact = tscv_asnnetdatabasecontact;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnOpenContactExArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnOpenContactExResult
export class AsnOpenContactExResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnOpenContactExResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnOpenContactExResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnOpenContactExResult", newContext);
		value += TSConverter.addObjectAsStringParam("contact", ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toJSON(obj.contact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnOpenContactExResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnOpenContactExResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnOpenContactExResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnOpenContactExResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnOpenContactExResult
			const tscv_contact = ENetUC_Common_AsnContact.AsnContact.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromJSON(data.contact, tscv_contact, errors, newContext, "contact", false))
				obj.contact = tscv_contact;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnOpenContactExResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCloseContactsResult
export class AsnCloseContactsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnCloseContactsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCloseContactsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCloseContactsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addStringParam("u8sComment", obj.u8sComment, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnCloseContactsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCloseContactsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCloseContactsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnCloseContactsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCloseContactsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			if (TSConverter.validateParam(data, "u8sComment", "string", errors, newContext, false))
				obj.u8sComment = data.u8sComment;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCloseContactsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetSettingsDynamicRightsArgument
export class AsnSetSettingsDynamicRightsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetSettingsDynamicRightsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetSettingsDynamicRightsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetSettingsDynamicRightsArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addObjectAsStringParam("seqDynamicRightsList", ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.toJSON(obj.seqDynamicRightsList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetSettingsDynamicRightsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetSettingsDynamicRightsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetSettingsDynamicRightsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetSettingsDynamicRightsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetSettingsDynamicRightsArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			const tscv_seqdynamicrightslist = new ENetUC_Common_AsnContact.AsnUserDynamicRightsList();
			if (ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.fromJSON(data.seqDynamicRightsList, tscv_seqdynamicrightslist, errors, newContext, "seqDynamicRightsList", false))
				obj.seqDynamicRightsList = tscv_seqdynamicrightslist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetSettingsDynamicRightsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetSettingsDynamicRightsResult
export class AsnSetSettingsDynamicRightsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetSettingsDynamicRightsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetSettingsDynamicRightsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetSettingsDynamicRightsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetSettingsDynamicRightsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetSettingsDynamicRightsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetSettingsDynamicRightsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetSettingsDynamicRightsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetSettingsDynamicRightsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetSettingsDynamicRightsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetSettingsDynamicRightsArgument
export class AsnGetSettingsDynamicRightsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetSettingsDynamicRightsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetSettingsDynamicRightsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetSettingsDynamicRightsArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetSettingsDynamicRightsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetSettingsDynamicRightsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsDynamicRightsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetSettingsDynamicRightsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetSettingsDynamicRightsArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsDynamicRightsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetSettingsDynamicRightsResult
export class AsnGetSettingsDynamicRightsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetSettingsDynamicRightsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetSettingsDynamicRightsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetSettingsDynamicRightsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("seqDynamicRightsList", ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.toJSON(obj.seqDynamicRightsList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetSettingsDynamicRightsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetSettingsDynamicRightsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsDynamicRightsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetSettingsDynamicRightsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetSettingsDynamicRightsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_seqdynamicrightslist = new ENetUC_Common_AsnContact.AsnUserDynamicRightsList();
			if (ENetUC_Common_AsnContact_Converter.AsnUserDynamicRightsList_Converter.fromJSON(data.seqDynamicRightsList, tscv_seqdynamicrightslist, errors, newContext, "seqDynamicRightsList", false))
				obj.seqDynamicRightsList = tscv_seqdynamicrightslist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsDynamicRightsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSettingsGlobalDynamicRightsChangedArgument
export class AsnSettingsGlobalDynamicRightsChangedArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSettingsGlobalDynamicRightsChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSettingsGlobalDynamicRightsChangedArgument", newContext);
		value += TSConverter.addObjectAsStringParam("globalDynamicRights", ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(obj.globalDynamicRights, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSettingsGlobalDynamicRightsChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSettingsGlobalDynamicRightsChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSettingsGlobalDynamicRightsChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSettingsGlobalDynamicRightsChangedArgument
			const tscv_globaldynamicrights = ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(data.globalDynamicRights, tscv_globaldynamicrights, errors, newContext, "globalDynamicRights", false))
				obj.globalDynamicRights = tscv_globaldynamicrights;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSettingsGlobalDynamicRightsChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetSettingsGlobalDynamicRightsResult
export class AsnGetSettingsGlobalDynamicRightsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetSettingsGlobalDynamicRightsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetSettingsGlobalDynamicRightsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("globalDynamicRights", ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.toJSON(obj.globalDynamicRights, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetSettingsGlobalDynamicRightsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsGlobalDynamicRightsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetSettingsGlobalDynamicRightsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_globaldynamicrights = ENetUC_Common_AsnContact.AsnUserDynamicRights.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnUserDynamicRights_Converter.fromJSON(data.globalDynamicRights, tscv_globaldynamicrights, errors, newContext, "globalDynamicRights", false))
				obj.globalDynamicRights = tscv_globaldynamicrights;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsGlobalDynamicRightsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateMyAppointmentArgument
export class AsnUpdateMyAppointmentArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUpdateMyAppointmentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateMyAppointmentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateMyAppointmentArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addObjectAsStringParam("seqActualAppointments", ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(obj.seqActualAppointments, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnNextAppointment", ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toJSON(obj.asnNextAppointment, newContext), newContext);
		value += TSConverter.addNumberParam("iPresenceState", obj.iPresenceState, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUpdateMyAppointmentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateMyAppointmentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyAppointmentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUpdateMyAppointmentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateMyAppointmentArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			const tscv_seqactualappointments = new ENetUC_Common_Appointments.SEQAppointmentList();
			if (ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(data.seqActualAppointments, tscv_seqactualappointments, errors, newContext, "seqActualAppointments", false))
				obj.seqActualAppointments = tscv_seqactualappointments;
			const tscv_asnnextappointment = ENetUC_Common_Appointments.AsnAppointmentEntry.initEmpty();
			if (ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromJSON(data.asnNextAppointment, tscv_asnnextappointment, errors, newContext, "asnNextAppointment", false))
				obj.asnNextAppointment = tscv_asnnextappointment;
			if (TSConverter.validateParam(data, "iPresenceState", "number", errors, newContext, false))
				obj.iPresenceState = data.iPresenceState;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyAppointmentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateMyCallInformationArgument
export class AsnUpdateMyCallInformationArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUpdateMyCallInformationArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderImportTypeRef] AsnUpdateMyCallInformationArgument
		value += ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.toJSON(obj, context)

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUpdateMyCallInformationArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateMyCallInformationArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyCallInformationArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUpdateMyCallInformationArgument;
		if (data !== undefined) {
			// [PrintTSDecoderImportTypeRef] AsnUpdateMyCallInformationArgument
			ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.fromJSON(data, obj, errors, newContext, "", optional)
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyCallInformationArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNotifyLineForwardStateArgument
export class AsnNotifyLineForwardStateArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnNotifyLineForwardStateArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNotifyLineForwardStateArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNotifyLineForwardStateArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addNumberParam("iContactFeatureSet", obj.iContactFeatureSet, newContext);
		value += TSConverter.addNumberParam("iPresenceState", obj.iPresenceState, newContext);
		value += TSConverter.addStringParam("u8sLinenumber", obj.u8sLinenumber, newContext);
		value += TSConverter.addObjectAsStringParam("seqLineForwards", ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(obj.seqLineForwards, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnNotifyLineForwardStateArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNotifyLineForwardStateArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNotifyLineForwardStateArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnNotifyLineForwardStateArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNotifyLineForwardStateArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			if (TSConverter.validateParam(data, "iContactFeatureSet", "number", errors, newContext, false))
				obj.iContactFeatureSet = data.iContactFeatureSet;
			if (TSConverter.validateParam(data, "iPresenceState", "number", errors, newContext, false))
				obj.iPresenceState = data.iPresenceState;
			if (TSConverter.validateParam(data, "u8sLinenumber", "string", errors, newContext, false))
				obj.u8sLinenumber = data.u8sLinenumber;
			const tscv_seqlineforwards = new ENetUC_Common_SIPCTI.AsnLineForwards();
			if (ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(data.seqLineForwards, tscv_seqlineforwards, errors, newContext, "seqLineForwards", false))
				obj.seqLineForwards = tscv_seqlineforwards;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNotifyLineForwardStateArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateMyContactConfigurationArgument
export class AsnUpdateMyContactConfigurationArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUpdateMyContactConfigurationArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateMyContactConfigurationArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateMyContactConfigurationArgument", newContext);
		value += TSConverter.addObjectAsStringParam("contactChanged", ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toJSON(obj.contactChanged, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUpdateMyContactConfigurationArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateMyContactConfigurationArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyContactConfigurationArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUpdateMyContactConfigurationArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateMyContactConfigurationArgument
			const tscv_contactchanged = ENetUC_Common_AsnContact.AsnContact.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromJSON(data.contactChanged, tscv_contactchanged, errors, newContext, "contactChanged", false))
				obj.contactChanged = tscv_contactchanged;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyContactConfigurationArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateServerBasedClientSettingsArgument
export class AsnUpdateServerBasedClientSettingsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateServerBasedClientSettingsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateServerBasedClientSettingsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateServerBasedClientSettingsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateServerBasedClientSettingsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUpdateServerBasedClientSettingsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateServerBasedClientSettingsArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateServerBasedClientSettingsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnMyPresenceRightsChallengeArgument
export class AsnMyPresenceRightsChallengeArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnMyPresenceRightsChallengeArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnMyPresenceRightsChallengeArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnMyPresenceRightsChallengeArgument", newContext);
		value += TSConverter.addObjectAsStringParam("contactsChallenging", ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(obj.contactsChallenging, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnMyPresenceRightsChallengeArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnMyPresenceRightsChallengeArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnMyPresenceRightsChallengeArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnMyPresenceRightsChallengeArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnMyPresenceRightsChallengeArgument
			const tscv_contactschallenging = new ENetUC_Common.AsnNetDatabaseContactList();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(data.contactsChallenging, tscv_contactschallenging, errors, newContext, "contactsChallenging", false))
				obj.contactsChallenging = tscv_contactschallenging;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnMyPresenceRightsChallengeArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestMyPresenceRightsChallengeArgument
export class AsnRequestMyPresenceRightsChallengeArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestMyPresenceRightsChallengeArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestMyPresenceRightsChallengeArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestMyPresenceRightsChallengeArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestMyPresenceRightsChallengeArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnRequestMyPresenceRightsChallengeArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestMyPresenceRightsChallengeArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestMyPresenceRightsChallengeArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnResetMyPresenceRightsChallengesArgument
export class AsnResetMyPresenceRightsChallengesArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnResetMyPresenceRightsChallengesArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnResetMyPresenceRightsChallengesArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnResetMyPresenceRightsChallengesArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnResetMyPresenceRightsChallengesArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnResetMyPresenceRightsChallengesArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnResetMyPresenceRightsChallengesArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnResetMyPresenceRightsChallengesArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientInfo
export class AsnClientInfo_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnClientInfo, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientInfo
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientInfo", newContext);
		value += TSConverter.addNumberParam("iConnectionType", obj.iConnectionType, newContext);
		value += TSConverter.addBooleanParam("bMyConnectionInfo", obj.bMyConnectionInfo, newContext);
		value += TSConverter.addDateParam("stUTCLoginTime", obj.stUTCLoginTime, newContext);
		value += TSConverter.addStringParam("u8sComputername", obj.u8sComputername, newContext);
		value += TSConverter.addStringParam("u8sAdditionalInfo", obj.u8sAdditionalInfo, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnClientInfo, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientInfo");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientInfo"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnClientInfo;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientInfo
			if (TSConverter.validateParam(data, "iConnectionType", "number", errors, newContext, false))
				obj.iConnectionType = data.iConnectionType;
			if (TSConverter.validateParam(data, "bMyConnectionInfo", "boolean", errors, newContext, false))
				obj.bMyConnectionInfo = data.bMyConnectionInfo;
			if (TSConverter.validateParam(data, "stUTCLoginTime", "string", errors, newContext, false))
				obj.stUTCLoginTime = new Date(data.stUTCLoginTime);
			if (TSConverter.validateParam(data, "u8sComputername", "string", errors, newContext, false))
				obj.u8sComputername = data.u8sComputername;
			if (TSConverter.validateParam(data, "u8sAdditionalInfo", "string", errors, newContext, false))
				obj.u8sAdditionalInfo = data.u8sAdditionalInfo;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientInfo"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnMyUpdateClientContentArgument
export class AsnMyUpdateClientContentArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnMyUpdateClientContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnMyUpdateClientContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnMyUpdateClientContentArgument", newContext);
		value += TSConverter.addStringParam("u8sNewRevision", obj.u8sNewRevision, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnMyUpdateClientContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnMyUpdateClientContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnMyUpdateClientContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnMyUpdateClientContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnMyUpdateClientContentArgument
			if (TSConverter.validateParam(data, "u8sNewRevision", "string", errors, newContext, false))
				obj.u8sNewRevision = data.u8sNewRevision;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnMyUpdateClientContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactRemoveContactArgument
export class AsnContactRemoveContactArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactRemoveContactArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactRemoveContactArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactRemoveContactArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactRemoveContactArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactRemoveContactArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactRemoveContactArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactRemoveContactArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactRemoveContactArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactRemoveContactArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAbsentStateSetUserArgument
export class AsnAbsentStateSetUserArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAbsentStateSetUserArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAbsentStateSetUserArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAbsentStateSetUserArgument", newContext);
		value += TSConverter.addObjectAsStringParam("absentstate", ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(obj.absentstate, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAbsentStateSetUserArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAbsentStateSetUserArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateSetUserArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAbsentStateSetUserArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAbsentStateSetUserArgument
			const tscv_absentstate = ENetUC_Common_AsnContact.AsnAbsentState.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(data.absentstate, tscv_absentstate, errors, newContext, "absentstate", false))
				obj.absentstate = tscv_absentstate;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateSetUserArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAbsentStateSetUserResult
export class AsnAbsentStateSetUserResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAbsentStateSetUserResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAbsentStateSetUserResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAbsentStateSetUserResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAbsentStateSetUserResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAbsentStateSetUserResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateSetUserResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAbsentStateSetUserResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAbsentStateSetUserResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateSetUserResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAbsentStateResetArgument
export class AsnAbsentStateResetArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAbsentStateResetArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAbsentStateResetArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAbsentStateResetArgument", newContext);
		value += TSConverter.addStringParam("u8sContactId", obj.u8sContactId, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAbsentStateResetArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAbsentStateResetArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateResetArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAbsentStateResetArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAbsentStateResetArgument
			if (TSConverter.validateParam(data, "u8sContactId", "string", errors, newContext, false))
				obj.u8sContactId = data.u8sContactId;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateResetArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAbsentStateResetResult
export class AsnAbsentStateResetResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAbsentStateResetResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAbsentStateResetResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAbsentStateResetResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAbsentStateResetResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAbsentStateResetResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateResetResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAbsentStateResetResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAbsentStateResetResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateResetResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAbsentStateGetUserResult
export class AsnAbsentStateGetUserResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAbsentStateGetUserResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAbsentStateGetUserResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAbsentStateGetUserResult", newContext);
		value += TSConverter.addObjectAsStringParam("absentstate", ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(obj.absentstate, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAbsentStateGetUserResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAbsentStateGetUserResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateGetUserResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAbsentStateGetUserResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAbsentStateGetUserResult
			const tscv_absentstate = ENetUC_Common_AsnContact.AsnAbsentState.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(data.absentstate, tscv_absentstate, errors, newContext, "absentstate", false))
				obj.absentstate = tscv_absentstate;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateGetUserResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetClientIdleStateArgument
export class AsnSetClientIdleStateArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetClientIdleStateArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetClientIdleStateArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetClientIdleStateArgument", newContext);
		value += TSConverter.addStringParam("u8sContactId", obj.u8sContactId, newContext);
		value += TSConverter.addNumberParam("iIdleState", obj.iIdleState, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetClientIdleStateArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetClientIdleStateArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientIdleStateArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetClientIdleStateArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetClientIdleStateArgument
			if (TSConverter.validateParam(data, "u8sContactId", "string", errors, newContext, false))
				obj.u8sContactId = data.u8sContactId;
			if (TSConverter.validateParam(data, "iIdleState", "number", errors, newContext, false))
				obj.iIdleState = data.iIdleState;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientIdleStateArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetClientIdleStateResult
export class AsnSetClientIdleStateResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetClientIdleStateResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetClientIdleStateResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetClientIdleStateResult", newContext);
		value += TSConverter.addObjectAsStringParam("dummy", JSON.stringify(obj.dummy), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetClientIdleStateResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetClientIdleStateResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientIdleStateResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetClientIdleStateResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetClientIdleStateResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientIdleStateResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateMyAbsentStateArgument
export class AsnUpdateMyAbsentStateArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUpdateMyAbsentStateArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateMyAbsentStateArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateMyAbsentStateArgument", newContext);
		value += TSConverter.addObjectAsStringParam("absentstate", ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(obj.absentstate, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUpdateMyAbsentStateArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateMyAbsentStateArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyAbsentStateArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUpdateMyAbsentStateArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateMyAbsentStateArgument
			const tscv_absentstate = ENetUC_Common_AsnContact.AsnAbsentState.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(data.absentstate, tscv_absentstate, errors, newContext, "absentstate", false))
				obj.absentstate = tscv_absentstate;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyAbsentStateArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCustomNoteSetArgument
export class AsnCustomNoteSetArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnCustomNoteSetArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCustomNoteSetArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCustomNoteSetArgument", newContext);
		value += TSConverter.addObjectAsStringParam("customnote", ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toJSON(obj.customnote, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnCustomNoteSetArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCustomNoteSetArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCustomNoteSetArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnCustomNoteSetArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCustomNoteSetArgument
			const tscv_customnote = ENetUC_Common_AsnContact.AsnCustomNote.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromJSON(data.customnote, tscv_customnote, errors, newContext, "customnote", false))
				obj.customnote = tscv_customnote;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCustomNoteSetArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCustomNoteSetResult
export class AsnCustomNoteSetResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnCustomNoteSetResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCustomNoteSetResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCustomNoteSetResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnCustomNoteSetResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCustomNoteSetResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCustomNoteSetResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnCustomNoteSetResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCustomNoteSetResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCustomNoteSetResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateMyCustomNoteArgument
export class AsnUpdateMyCustomNoteArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUpdateMyCustomNoteArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUpdateMyCustomNoteArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUpdateMyCustomNoteArgument", newContext);
		value += TSConverter.addObjectAsStringParam("customnote", ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.toJSON(obj.customnote, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUpdateMyCustomNoteArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateMyCustomNoteArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyCustomNoteArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUpdateMyCustomNoteArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUpdateMyCustomNoteArgument
			const tscv_customnote = ENetUC_Common_AsnContact.AsnCustomNote.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnCustomNote_Converter.fromJSON(data.customnote, tscv_customnote, errors, newContext, "customnote", false))
				obj.customnote = tscv_customnote;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyCustomNoteArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetMetaSettingsResult
export class AsnSetMetaSettingsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetMetaSettingsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetMetaSettingsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetMetaSettingsResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetMetaSettingsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetMetaSettingsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetMetaSettingsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetMetaSettingsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetMetaSettingsResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetMetaSettingsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestFeatureArgument
export class AsnRequestFeatureArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnRequestFeatureArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestFeatureArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestFeatureArgument", newContext);
		value += TSConverter.addNumberParam("iFeature", obj.iFeature, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnRequestFeatureArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestFeatureArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestFeatureArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnRequestFeatureArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestFeatureArgument
			if (TSConverter.validateParam(data, "iFeature", "number", errors, newContext, false))
				obj.iFeature = data.iFeature;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestFeatureArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestFeatureResult
export class AsnRequestFeatureResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnRequestFeatureResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestFeatureResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestFeatureResult", newContext);
		value += TSConverter.addBooleanParam("bNotForResale", obj.bNotForResale, newContext);
		value += TSConverter.addNumberParam("iFeatureSet", obj.iFeatureSet, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnRequestFeatureResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestFeatureResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestFeatureResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnRequestFeatureResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestFeatureResult
			if (TSConverter.validateParam(data, "bNotForResale", "boolean", errors, newContext, false))
				obj.bNotForResale = data.bNotForResale;
			if (TSConverter.validateParam(data, "iFeatureSet", "number", errors, newContext, false))
				obj.iFeatureSet = data.iFeatureSet;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestFeatureResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnEMailAttachment
export class AsnEMailAttachment_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnEMailAttachment, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnEMailAttachment
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnEMailAttachment", newContext);
		value += TSConverter.addBinaryParam("binAttachmentData", obj.binAttachmentData, newContext);
		value += TSConverter.addStringParam("u8sAttachmentFileName", obj.u8sAttachmentFileName, newContext);
		value += TSConverter.addStringParam("u8sContentID", obj.u8sContentID, newContext);
		value += TSConverter.addNumberParam("iAttachmentEncoding", obj.iAttachmentEncoding, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnEMailAttachment, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnEMailAttachment");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailAttachment"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnEMailAttachment;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnEMailAttachment
			if (TSConverter.validateParam(data, "binAttachmentData", "string", errors, newContext, false))
				obj.binAttachmentData = TSConverter.decode64(data.binAttachmentData.toString());
			if (TSConverter.validateParam(data, "u8sAttachmentFileName", "string", errors, newContext, false))
				obj.u8sAttachmentFileName = data.u8sAttachmentFileName;
			if (TSConverter.validateParam(data, "u8sContentID", "string", errors, newContext, false))
				obj.u8sContentID = data.u8sContentID;
			if (TSConverter.validateParam(data, "iAttachmentEncoding", "number", errors, newContext, false))
				obj.iAttachmentEncoding = data.iAttachmentEncoding;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailAttachment"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnEMailParty
export class AsnEMailParty_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnEMailParty, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnEMailParty
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnEMailParty", newContext);
		value += TSConverter.addStringParam("u8sAddress", obj.u8sAddress, newContext);
		value += TSConverter.addStringParam("u8sName", obj.u8sName, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnEMailParty, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnEMailParty");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailParty"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnEMailParty;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnEMailParty
			if (TSConverter.validateParam(data, "u8sAddress", "string", errors, newContext, false))
				obj.u8sAddress = data.u8sAddress;
			if (TSConverter.validateParam(data, "u8sName", "string", errors, newContext, false))
				obj.u8sName = data.u8sName;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailParty"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnEMailBody
export class AsnEMailBody_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnEMailBody, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnEMailBody
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnEMailBody", newContext);
		value += TSConverter.addBinaryParam("binBodyData", obj.binBodyData, newContext);
		value += TSConverter.addNumberParam("iBodyEncoding", obj.iBodyEncoding, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnEMailBody, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnEMailBody");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailBody"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnEMailBody;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnEMailBody
			if (TSConverter.validateParam(data, "binBodyData", "string", errors, newContext, false))
				obj.binBodyData = TSConverter.decode64(data.binBodyData.toString());
			if (TSConverter.validateParam(data, "iBodyEncoding", "number", errors, newContext, false))
				obj.iBodyEncoding = data.iBodyEncoding;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailBody"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDatabaseEntryID
export class AsnDatabaseEntryID_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnDatabaseEntryID, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDatabaseEntryID
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDatabaseEntryID", newContext);
		value += TSConverter.addStringParam("u8sEntryIDDB", obj.u8sEntryIDDB, newContext);
		value += TSConverter.addStringParam("u8sEntryIDStore", obj.u8sEntryIDStore, newContext);
		value += TSConverter.addStringParam("u8sEntryID", obj.u8sEntryID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnDatabaseEntryID, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDatabaseEntryID");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseEntryID"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnDatabaseEntryID;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDatabaseEntryID
			if (TSConverter.validateParam(data, "u8sEntryIDDB", "string", errors, newContext, false))
				obj.u8sEntryIDDB = data.u8sEntryIDDB;
			if (TSConverter.validateParam(data, "u8sEntryIDStore", "string", errors, newContext, false))
				obj.u8sEntryIDStore = data.u8sEntryIDStore;
			if (TSConverter.validateParam(data, "u8sEntryID", "string", errors, newContext, false))
				obj.u8sEntryID = data.u8sEntryID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseEntryID"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDatabaseFindOption
export class AsnDatabaseFindOption_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnDatabaseFindOption, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDatabaseFindOption
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDatabaseFindOption", newContext);
		value += TSConverter.addStringParam("u8sFirstName", obj.u8sFirstName, newContext);
		value += TSConverter.addStringParam("u8sLastName", obj.u8sLastName, newContext);
		value += TSConverter.addStringParam("u8sCompanyName", obj.u8sCompanyName, newContext);
		value += TSConverter.addStringParam("u8sCustomerID", obj.u8sCustomerID, newContext);
		value += TSConverter.addStringParam("u8sDepartment", obj.u8sDepartment, newContext);
		value += TSConverter.addStringParam("u8sStreetAddress", obj.u8sStreetAddress, newContext);
		value += TSConverter.addStringParam("u8sAreaCodeOrCity", obj.u8sAreaCodeOrCity, newContext);
		value += TSConverter.addStringParam("u8sPhoneNo", obj.u8sPhoneNo, newContext);
		value += TSConverter.addStringParam("u8sEMail", obj.u8sEMail, newContext);
		value += TSConverter.addNumberParam("iMaxNumEntries", obj.iMaxNumEntries, newContext);
		value += TSConverter.addStringParam("u8sDatabaseName", obj.u8sDatabaseName, newContext);
		value += TSConverter.addStringParam("u8sUsername", obj.u8sUsername, newContext);
		value += TSConverter.addNumberParam("iFlags", obj.iFlags, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnDatabaseFindOption, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDatabaseFindOption");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseFindOption"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnDatabaseFindOption;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDatabaseFindOption
			if (TSConverter.validateParam(data, "u8sFirstName", "string", errors, newContext, false))
				obj.u8sFirstName = data.u8sFirstName;
			if (TSConverter.validateParam(data, "u8sLastName", "string", errors, newContext, false))
				obj.u8sLastName = data.u8sLastName;
			if (TSConverter.validateParam(data, "u8sCompanyName", "string", errors, newContext, false))
				obj.u8sCompanyName = data.u8sCompanyName;
			if (TSConverter.validateParam(data, "u8sCustomerID", "string", errors, newContext, false))
				obj.u8sCustomerID = data.u8sCustomerID;
			if (TSConverter.validateParam(data, "u8sDepartment", "string", errors, newContext, false))
				obj.u8sDepartment = data.u8sDepartment;
			if (TSConverter.validateParam(data, "u8sStreetAddress", "string", errors, newContext, false))
				obj.u8sStreetAddress = data.u8sStreetAddress;
			if (TSConverter.validateParam(data, "u8sAreaCodeOrCity", "string", errors, newContext, false))
				obj.u8sAreaCodeOrCity = data.u8sAreaCodeOrCity;
			if (TSConverter.validateParam(data, "u8sPhoneNo", "string", errors, newContext, false))
				obj.u8sPhoneNo = data.u8sPhoneNo;
			if (TSConverter.validateParam(data, "u8sEMail", "string", errors, newContext, false))
				obj.u8sEMail = data.u8sEMail;
			if (TSConverter.validateParam(data, "iMaxNumEntries", "number", errors, newContext, false))
				obj.iMaxNumEntries = data.iMaxNumEntries;
			if (TSConverter.validateParam(data, "u8sDatabaseName", "string", errors, newContext, false))
				obj.u8sDatabaseName = data.u8sDatabaseName;
			if (TSConverter.validateParam(data, "u8sUsername", "string", errors, newContext, false))
				obj.u8sUsername = data.u8sUsername;
			if (TSConverter.validateParam(data, "iFlags", "number", errors, newContext, false))
				obj.iFlags = data.iFlags;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseFindOption"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDBPhoneBook
export class AsnDBPhoneBook_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnDBPhoneBook, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDBPhoneBook
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDBPhoneBook", newContext);
		value += TSConverter.addStringParam("u8sName", obj.u8sName, newContext);
		value += TSConverter.addStringParam("u8sInfo", obj.u8sInfo, newContext);
		value += TSConverter.addNumberParam("iCapabilities", obj.iCapabilities, newContext);
		value += TSConverter.addNumberParam("iType", obj.iType, newContext);
		value += TSConverter.addObjectAsStringParam("errors", ENetUC_Common_Converter.AsnRequestErrorList_Converter.toJSON(obj.errors, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnDBPhoneBook, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDBPhoneBook");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDBPhoneBook"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnDBPhoneBook;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDBPhoneBook
			if (TSConverter.validateParam(data, "u8sName", "string", errors, newContext, false))
				obj.u8sName = data.u8sName;
			if (TSConverter.validateParam(data, "u8sInfo", "string", errors, newContext, false))
				obj.u8sInfo = data.u8sInfo;
			if (TSConverter.validateParam(data, "iCapabilities", "number", errors, newContext, false))
				obj.iCapabilities = data.iCapabilities;
			if (TSConverter.validateParam(data, "iType", "number", errors, newContext, false))
				obj.iType = data.iType;
			const tscv_errors = new ENetUC_Common.AsnRequestErrorList();
			if (ENetUC_Common_Converter.AsnRequestErrorList_Converter.fromJSON(data.errors, tscv_errors, errors, newContext, "errors", false))
				obj.errors = tscv_errors;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDBPhoneBook"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNetDatabaseFindOptions
export class AsnNetDatabaseFindOptions_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnNetDatabaseFindOptions, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNetDatabaseFindOptions
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNetDatabaseFindOptions", newContext);
		value += TSConverter.addStringParam("u8sFirstName", obj.u8sFirstName, newContext);
		value += TSConverter.addStringParam("u8sLastName", obj.u8sLastName, newContext);
		value += TSConverter.addStringParam("u8sCompanyName", obj.u8sCompanyName, newContext);
		value += TSConverter.addStringParam("u8sCustomerID", obj.u8sCustomerID, newContext);
		value += TSConverter.addStringParam("u8sDepartment", obj.u8sDepartment, newContext);
		value += TSConverter.addStringParam("u8sStreetAddress", obj.u8sStreetAddress, newContext);
		value += TSConverter.addStringParam("u8sAreaCodeOrCity", obj.u8sAreaCodeOrCity, newContext);
		value += TSConverter.addStringParam("u8sPhoneNo", obj.u8sPhoneNo, newContext);
		value += TSConverter.addStringParam("u8sEMail", obj.u8sEMail, newContext);
		value += TSConverter.addObjectAsStringParam("u8spairlistCustomFieldsValues", ENetUC_Common_Converter.AsnStringPairList_Converter.toJSON(obj.u8spairlistCustomFieldsValues, newContext), newContext);
		value += TSConverter.addNumberParam("iMaxNumEntries", obj.iMaxNumEntries, newContext);
		value += TSConverter.addObjectAsStringParam("u8slistDatabaseNames", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistDatabaseNames, newContext), newContext);
		value += TSConverter.addStringParam("u8sUsername", obj.u8sUsername, newContext);
		value += TSConverter.addNumberParam("iFlags", obj.iFlags, newContext);
		value += TSConverter.addNumberParam("iDisplayNameFormat", obj.iDisplayNameFormat, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnNetDatabaseFindOptions, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNetDatabaseFindOptions");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseFindOptions"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnNetDatabaseFindOptions;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNetDatabaseFindOptions
			if (TSConverter.validateParam(data, "u8sFirstName", "string", errors, newContext, false))
				obj.u8sFirstName = data.u8sFirstName;
			if (TSConverter.validateParam(data, "u8sLastName", "string", errors, newContext, false))
				obj.u8sLastName = data.u8sLastName;
			if (TSConverter.validateParam(data, "u8sCompanyName", "string", errors, newContext, false))
				obj.u8sCompanyName = data.u8sCompanyName;
			if (TSConverter.validateParam(data, "u8sCustomerID", "string", errors, newContext, false))
				obj.u8sCustomerID = data.u8sCustomerID;
			if (TSConverter.validateParam(data, "u8sDepartment", "string", errors, newContext, false))
				obj.u8sDepartment = data.u8sDepartment;
			if (TSConverter.validateParam(data, "u8sStreetAddress", "string", errors, newContext, false))
				obj.u8sStreetAddress = data.u8sStreetAddress;
			if (TSConverter.validateParam(data, "u8sAreaCodeOrCity", "string", errors, newContext, false))
				obj.u8sAreaCodeOrCity = data.u8sAreaCodeOrCity;
			if (TSConverter.validateParam(data, "u8sPhoneNo", "string", errors, newContext, false))
				obj.u8sPhoneNo = data.u8sPhoneNo;
			if (TSConverter.validateParam(data, "u8sEMail", "string", errors, newContext, false))
				obj.u8sEMail = data.u8sEMail;
			const tscv_u8spairlistcustomfieldsvalues = new ENetUC_Common.AsnStringPairList();
			if (ENetUC_Common_Converter.AsnStringPairList_Converter.fromJSON(data.u8spairlistCustomFieldsValues, tscv_u8spairlistcustomfieldsvalues, errors, newContext, "u8spairlistCustomFieldsValues", false))
				obj.u8spairlistCustomFieldsValues = tscv_u8spairlistcustomfieldsvalues;
			if (TSConverter.validateParam(data, "iMaxNumEntries", "number", errors, newContext, false))
				obj.iMaxNumEntries = data.iMaxNumEntries;
			const tscv_u8slistdatabasenames = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistDatabaseNames, tscv_u8slistdatabasenames, errors, newContext, "u8slistDatabaseNames", false))
				obj.u8slistDatabaseNames = tscv_u8slistdatabasenames;
			if (TSConverter.validateParam(data, "u8sUsername", "string", errors, newContext, false))
				obj.u8sUsername = data.u8sUsername;
			if (TSConverter.validateParam(data, "iFlags", "number", errors, newContext, false))
				obj.iFlags = data.iFlags;
			if (TSConverter.validateParam(data, "iDisplayNameFormat", "number", errors, newContext, false))
				obj.iDisplayNameFormat = data.iDisplayNameFormat;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseFindOptions"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetContactByEntryIDArgument
export class AsnGetContactByEntryIDArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetContactByEntryIDArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetContactByEntryIDArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetContactByEntryIDArgument", newContext);
		value += TSConverter.addStringParam("u8sEntryIDDB", obj.u8sEntryIDDB, newContext);
		value += TSConverter.addStringParam("u8sEntryIDStore", obj.u8sEntryIDStore, newContext);
		value += TSConverter.addStringParam("u8sEntryID", obj.u8sEntryID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetContactByEntryIDArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetContactByEntryIDArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetContactByEntryIDArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetContactByEntryIDArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetContactByEntryIDArgument
			if (TSConverter.validateParam(data, "u8sEntryIDDB", "string", errors, newContext, false))
				obj.u8sEntryIDDB = data.u8sEntryIDDB;
			if (TSConverter.validateParam(data, "u8sEntryIDStore", "string", errors, newContext, false))
				obj.u8sEntryIDStore = data.u8sEntryIDStore;
			if (TSConverter.validateParam(data, "u8sEntryID", "string", errors, newContext, false))
				obj.u8sEntryID = data.u8sEntryID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetContactByEntryIDArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetContactByEntryIDResult
export class AsnGetContactByEntryIDResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetContactByEntryIDResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetContactByEntryIDResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetContactByEntryIDResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("contact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.contact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetContactByEntryIDResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetContactByEntryIDResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetContactByEntryIDResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetContactByEntryIDResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetContactByEntryIDResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_contact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.contact, tscv_contact, errors, newContext, "contact", false))
				obj.contact = tscv_contact;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetContactByEntryIDResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetPreferredContactRefArgument
export class AsnGetPreferredContactRefArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetPreferredContactRefArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetPreferredContactRefArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetPreferredContactRefArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetPreferredContactRefArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetPreferredContactRefArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetPreferredContactRefArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetPreferredContactRefArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetPreferredContactRefArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetPreferredContactRefArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetPreferredContactRefResult
export class AsnGetPreferredContactRefResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetPreferredContactRefResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetPreferredContactRefResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetPreferredContactRefResult", newContext);
		value += TSConverter.addBooleanParam("bContactRefFound", obj.bContactRefFound, newContext);
		value += TSConverter.addStringParam("u8sEntryID", obj.u8sEntryID, newContext);
		value += TSConverter.addStringParam("u8sStoreID", obj.u8sStoreID, newContext);
		value += TSConverter.addStringParam("u8sDBID", obj.u8sDBID, newContext);
		value += TSConverter.addStringParam("u8sDisplayName", obj.u8sDisplayName, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetPreferredContactRefResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetPreferredContactRefResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetPreferredContactRefResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetPreferredContactRefResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetPreferredContactRefResult
			if (TSConverter.validateParam(data, "bContactRefFound", "boolean", errors, newContext, false))
				obj.bContactRefFound = data.bContactRefFound;
			if (TSConverter.validateParam(data, "u8sEntryID", "string", errors, newContext, false))
				obj.u8sEntryID = data.u8sEntryID;
			if (TSConverter.validateParam(data, "u8sStoreID", "string", errors, newContext, false))
				obj.u8sStoreID = data.u8sStoreID;
			if (TSConverter.validateParam(data, "u8sDBID", "string", errors, newContext, false))
				obj.u8sDBID = data.u8sDBID;
			if (TSConverter.validateParam(data, "u8sDisplayName", "string", errors, newContext, false))
				obj.u8sDisplayName = data.u8sDisplayName;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetPreferredContactRefResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetPreferredContactRefArgument
export class AsnSetPreferredContactRefArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetPreferredContactRefArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetPreferredContactRefArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetPreferredContactRefArgument", newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addStringParam("u8sEntryID", obj.u8sEntryID, newContext);
		value += TSConverter.addStringParam("u8sStoreID", obj.u8sStoreID, newContext);
		value += TSConverter.addStringParam("u8sDBID", obj.u8sDBID, newContext);
		value += TSConverter.addStringParam("u8sDisplayName", obj.u8sDisplayName, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetPreferredContactRefArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetPreferredContactRefArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetPreferredContactRefArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetPreferredContactRefArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetPreferredContactRefArgument
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			if (TSConverter.validateParam(data, "u8sEntryID", "string", errors, newContext, false))
				obj.u8sEntryID = data.u8sEntryID;
			if (TSConverter.validateParam(data, "u8sStoreID", "string", errors, newContext, false))
				obj.u8sStoreID = data.u8sStoreID;
			if (TSConverter.validateParam(data, "u8sDBID", "string", errors, newContext, false))
				obj.u8sDBID = data.u8sDBID;
			if (TSConverter.validateParam(data, "u8sDisplayName", "string", errors, newContext, false))
				obj.u8sDisplayName = data.u8sDisplayName;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetPreferredContactRefArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDatabaseConnectorLoginArgument
export class AsnDatabaseConnectorLoginArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnDatabaseConnectorLoginArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDatabaseConnectorLoginArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDatabaseConnectorLoginArgument", newContext);
		value += TSConverter.addStringParam("u8sUserName", obj.u8sUserName, newContext);
		value += TSConverter.addStringParam("u8sPassword", obj.u8sPassword, newContext);
		value += TSConverter.addNumberParam("iVersion", obj.iVersion, newContext);
		value += TSConverter.addNumberParam("iDatabaseCapabilities", obj.iDatabaseCapabilities, newContext);
		value += TSConverter.addNumberParam("iDatabaseType", obj.iDatabaseType, newContext);
		value += TSConverter.addStringParam("u8sDatabaseName", obj.u8sDatabaseName, newContext);
		value += TSConverter.addStringParam("u8sDatabaseInfo", obj.u8sDatabaseInfo, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnDatabaseConnectorLoginArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDatabaseConnectorLoginArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseConnectorLoginArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnDatabaseConnectorLoginArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDatabaseConnectorLoginArgument
			if (TSConverter.validateParam(data, "u8sUserName", "string", errors, newContext, false))
				obj.u8sUserName = data.u8sUserName;
			if (TSConverter.validateParam(data, "u8sPassword", "string", errors, newContext, false))
				obj.u8sPassword = data.u8sPassword;
			if (TSConverter.validateParam(data, "iVersion", "number", errors, newContext, false))
				obj.iVersion = data.iVersion;
			if (TSConverter.validateParam(data, "iDatabaseCapabilities", "number", errors, newContext, false))
				obj.iDatabaseCapabilities = data.iDatabaseCapabilities;
			if (TSConverter.validateParam(data, "iDatabaseType", "number", errors, newContext, false))
				obj.iDatabaseType = data.iDatabaseType;
			if (TSConverter.validateParam(data, "u8sDatabaseName", "string", errors, newContext, false))
				obj.u8sDatabaseName = data.u8sDatabaseName;
			if (TSConverter.validateParam(data, "u8sDatabaseInfo", "string", errors, newContext, false))
				obj.u8sDatabaseInfo = data.u8sDatabaseInfo;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseConnectorLoginArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDatabaseConnectorLoginResult
export class AsnDatabaseConnectorLoginResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnDatabaseConnectorLoginResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDatabaseConnectorLoginResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDatabaseConnectorLoginResult", newContext);
		value += TSConverter.addNumberParam("iConnectorCrossRefID", obj.iConnectorCrossRefID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnDatabaseConnectorLoginResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDatabaseConnectorLoginResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseConnectorLoginResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnDatabaseConnectorLoginResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDatabaseConnectorLoginResult
			if (TSConverter.validateParam(data, "iConnectorCrossRefID", "number", errors, newContext, false))
				obj.iConnectorCrossRefID = data.iConnectorCrossRefID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseConnectorLoginResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDatabaseConnectorLogoffArgument
export class AsnDatabaseConnectorLogoffArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnDatabaseConnectorLogoffArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDatabaseConnectorLogoffArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDatabaseConnectorLogoffArgument", newContext);
		value += TSConverter.addNumberParam("iConnectorCrossRefID", obj.iConnectorCrossRefID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnDatabaseConnectorLogoffArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDatabaseConnectorLogoffArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseConnectorLogoffArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnDatabaseConnectorLogoffArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDatabaseConnectorLogoffArgument
			if (TSConverter.validateParam(data, "iConnectorCrossRefID", "number", errors, newContext, false))
				obj.iConnectorCrossRefID = data.iConnectorCrossRefID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseConnectorLogoffArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDatabaseConnectorLogoffResult
export class AsnDatabaseConnectorLogoffResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnDatabaseConnectorLogoffResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDatabaseConnectorLogoffResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDatabaseConnectorLogoffResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnDatabaseConnectorLogoffResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDatabaseConnectorLogoffResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseConnectorLogoffResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnDatabaseConnectorLogoffResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDatabaseConnectorLogoffResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDatabaseConnectorLogoffResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetPhoneBooksArgument
export class AsnGetPhoneBooksArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetPhoneBooksArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetPhoneBooksArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetPhoneBooksArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetPhoneBooksArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetPhoneBooksArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetPhoneBooksArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetPhoneBooksArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetPhoneBooksArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetPhoneBooksArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetUserPropertyBagArgument
export class AsnSetUserPropertyBagArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetUserPropertyBagArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetUserPropertyBagArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetUserPropertyBagArgument", newContext);
		value += TSConverter.addObjectAsStringParam("asnUserPropertyBag", ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toJSON(obj.asnUserPropertyBag, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetUserPropertyBagArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetUserPropertyBagArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetUserPropertyBagArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetUserPropertyBagArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetUserPropertyBagArgument
			const tscv_asnuserpropertybag = ENetUC_Common.AsnUserPropertyBag.initEmpty();
			if (ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromJSON(data.asnUserPropertyBag, tscv_asnuserpropertybag, errors, newContext, "asnUserPropertyBag", false))
				obj.asnUserPropertyBag = tscv_asnuserpropertybag;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetUserPropertyBagArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetUserPropertyBagResult
export class AsnSetUserPropertyBagResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetUserPropertyBagResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetUserPropertyBagResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetUserPropertyBagResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetUserPropertyBagResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetUserPropertyBagResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetUserPropertyBagResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetUserPropertyBagResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetUserPropertyBagResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetUserPropertyBagResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnServerBasedClientSettings
export class AsnServerBasedClientSettings_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnServerBasedClientSettings, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnServerBasedClientSettings
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnServerBasedClientSettings", newContext);
		value += TSConverter.addStringParam("osSettingsData", obj.osSettingsData, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnServerBasedClientSettings, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnServerBasedClientSettings");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnServerBasedClientSettings"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnServerBasedClientSettings;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnServerBasedClientSettings
			if (TSConverter.validateParam(data, "osSettingsData", "string", errors, newContext, false))
				obj.osSettingsData = data.osSettingsData;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnServerBasedClientSettings"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetServerBasedClientSettingsArgument
export class AsnGetServerBasedClientSettingsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetServerBasedClientSettingsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetServerBasedClientSettingsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetServerBasedClientSettingsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetServerBasedClientSettingsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetServerBasedClientSettingsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetServerBasedClientSettingsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetServerBasedClientSettingsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetServerBasedClientSettingsArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetServerBasedClientSettingsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetServerBasedClientSettingsResult
export class AsnSetServerBasedClientSettingsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetServerBasedClientSettingsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetServerBasedClientSettingsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetServerBasedClientSettingsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetServerBasedClientSettingsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetServerBasedClientSettingsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetServerBasedClientSettingsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetServerBasedClientSettingsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetServerBasedClientSettingsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetServerBasedClientSettingsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactStatusResultEntry
export class AsnContactStatusResultEntry_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactStatusResultEntry, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactStatusResultEntry
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactStatusResultEntry", newContext);
		value += TSConverter.addNumberParam("iClientSearchID", obj.iClientSearchID, newContext);
		value += TSConverter.addNumberParam("iClientRowID", obj.iClientRowID, newContext);
		value += TSConverter.addNumberParam("iPresenceState", obj.iPresenceState, newContext);
		value += TSConverter.addStringParam("u8sContactID", obj.u8sContactID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactStatusResultEntry, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactStatusResultEntry");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusResultEntry"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactStatusResultEntry;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactStatusResultEntry
			if (TSConverter.validateParam(data, "iClientSearchID", "number", errors, newContext, false))
				obj.iClientSearchID = data.iClientSearchID;
			if (TSConverter.validateParam(data, "iClientRowID", "number", errors, newContext, false))
				obj.iClientRowID = data.iClientRowID;
			if (TSConverter.validateParam(data, "iPresenceState", "number", errors, newContext, false))
				obj.iPresenceState = data.iPresenceState;
			if (TSConverter.validateParam(data, "u8sContactID", "string", errors, newContext, false))
				obj.u8sContactID = data.u8sContactID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusResultEntry"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactSearchCloseArgument
export class AsnContactSearchCloseArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactSearchCloseArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactSearchCloseArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactSearchCloseArgument", newContext);
		value += TSConverter.addNumberParam("iClientSearchID", obj.iClientSearchID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactSearchCloseArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactSearchCloseArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactSearchCloseArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactSearchCloseArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactSearchCloseArgument
			if (TSConverter.validateParam(data, "iClientSearchID", "number", errors, newContext, false))
				obj.iClientSearchID = data.iClientSearchID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactSearchCloseArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactSearchCloseResult
export class AsnContactSearchCloseResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactSearchCloseResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactSearchCloseResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactSearchCloseResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactSearchCloseResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactSearchCloseResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactSearchCloseResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactSearchCloseResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactSearchCloseResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactSearchCloseResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAppsRegisterPushArgument
export class AsnAppsRegisterPushArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAppsRegisterPushArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAppsRegisterPushArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAppsRegisterPushArgument", newContext);
		value += TSConverter.addNumberParam("iRegisterType", obj.iRegisterType, newContext);
		value += TSConverter.addStringParam("u8sDeviceID", obj.u8sDeviceID, newContext);
		value += TSConverter.addStringParam("u8sPushID", obj.u8sPushID, newContext);
		value += TSConverter.addStringParam("u8sPushChannelID", obj.u8sPushChannelID, newContext);
		value += TSConverter.addNumberParam("iEncryption", obj.iEncryption, newContext);
		value += TSConverter.addStringParam("u8sPushIDVoIP", obj.u8sPushIDVoIP, newContext);
		value += TSConverter.addStringParam("u8sPushChannelIDVoIP", obj.u8sPushChannelIDVoIP, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAppsRegisterPushArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAppsRegisterPushArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAppsRegisterPushArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAppsRegisterPushArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAppsRegisterPushArgument
			if (TSConverter.validateParam(data, "iRegisterType", "number", errors, newContext, false))
				obj.iRegisterType = data.iRegisterType;
			if (TSConverter.validateParam(data, "u8sDeviceID", "string", errors, newContext, false))
				obj.u8sDeviceID = data.u8sDeviceID;
			if (TSConverter.validateParam(data, "u8sPushID", "string", errors, newContext, false))
				obj.u8sPushID = data.u8sPushID;
			if (TSConverter.validateParam(data, "u8sPushChannelID", "string", errors, newContext, false))
				obj.u8sPushChannelID = data.u8sPushChannelID;
			if (TSConverter.validateParam(data, "iEncryption", "number", errors, newContext, true))
				obj.iEncryption = data.iEncryption;
			if (TSConverter.validateParam(data, "u8sPushIDVoIP", "string", errors, newContext, true))
				obj.u8sPushIDVoIP = data.u8sPushIDVoIP;
			if (TSConverter.validateParam(data, "u8sPushChannelIDVoIP", "string", errors, newContext, true))
				obj.u8sPushChannelIDVoIP = data.u8sPushChannelIDVoIP;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAppsRegisterPushArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAppsRegisterPushResult
export class AsnAppsRegisterPushResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAppsRegisterPushResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAppsRegisterPushResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAppsRegisterPushResult", newContext);
		value += TSConverter.addBinaryParam("key", obj.key, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAppsRegisterPushResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAppsRegisterPushResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAppsRegisterPushResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAppsRegisterPushResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAppsRegisterPushResult
			if (TSConverter.validateParam(data, "key", "string", errors, newContext, true) && data.key)
				obj.key = TSConverter.decode64(data.key.toString());
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAppsRegisterPushResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPublicUserContactData
export class AsnPublicUserContactData_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnPublicUserContactData, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnPublicUserContactData
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnPublicUserContactData", newContext);
		value += TSConverter.addObjectAsStringParam("contactData", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.contactData, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnPublicUserContactData, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPublicUserContactData");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPublicUserContactData"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnPublicUserContactData;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnPublicUserContactData
			const tscv_contactdata = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.contactData, tscv_contactdata, errors, newContext, "contactData", false))
				obj.contactData = tscv_contactdata;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPublicUserContactData"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPublicUserSetContactDataResult
export class AsnPublicUserSetContactDataResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnPublicUserSetContactDataResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnPublicUserSetContactDataResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnPublicUserSetContactDataResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnPublicUserSetContactDataResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPublicUserSetContactDataResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPublicUserSetContactDataResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnPublicUserSetContactDataResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnPublicUserSetContactDataResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPublicUserSetContactDataResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetGoogleIntegrationSettingsArgument
export class AsnGetGoogleIntegrationSettingsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetGoogleIntegrationSettingsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetGoogleIntegrationSettingsArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetGoogleIntegrationSettingsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetGoogleIntegrationSettingsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetGoogleIntegrationSettingsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetGoogleIntegrationSettingsArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetGoogleIntegrationSettingsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetGoogleIntegrationSettingsResult
export class AsnGetGoogleIntegrationSettingsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetGoogleIntegrationSettingsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetGoogleIntegrationSettingsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addNumberParam("iEnabled", obj.iEnabled, newContext);
		value += TSConverter.addStringParam("u8sClientID", obj.u8sClientID, newContext);
		value += TSConverter.addStringParam("u8sClientSecret", obj.u8sClientSecret, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetGoogleIntegrationSettingsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetGoogleIntegrationSettingsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetGoogleIntegrationSettingsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetGoogleIntegrationSettingsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			if (TSConverter.validateParam(data, "iEnabled", "number", errors, newContext, false))
				obj.iEnabled = data.iEnabled;
			if (TSConverter.validateParam(data, "u8sClientID", "string", errors, newContext, false))
				obj.u8sClientID = data.u8sClientID;
			if (TSConverter.validateParam(data, "u8sClientSecret", "string", errors, newContext, false))
				obj.u8sClientSecret = data.u8sClientSecret;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetGoogleIntegrationSettingsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSMSMessage
export class AsnSMSMessage_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSMSMessage, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSMSMessage
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSMSMessage", newContext);
		value += TSConverter.addStringParam("u8sRefId", obj.u8sRefId, newContext);
		value += TSConverter.addStringParam("u8sSenderNumberSC", obj.u8sSenderNumberSC, newContext);
		value += TSConverter.addStringParam("u8sReceiverNumberSC", obj.u8sReceiverNumberSC, newContext);
		value += TSConverter.addStringParam("u8sMessage", obj.u8sMessage, newContext);
		value += TSConverter.addNumberParam("iContentIsPrivate", obj.iContentIsPrivate, newContext);
		value += TSConverter.addObjectAsStringParam("contactReceiver", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.contactReceiver, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSMSMessage, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSMSMessage");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSMSMessage"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSMSMessage;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSMSMessage
			if (TSConverter.validateParam(data, "u8sRefId", "string", errors, newContext, false))
				obj.u8sRefId = data.u8sRefId;
			if (TSConverter.validateParam(data, "u8sSenderNumberSC", "string", errors, newContext, false))
				obj.u8sSenderNumberSC = data.u8sSenderNumberSC;
			if (TSConverter.validateParam(data, "u8sReceiverNumberSC", "string", errors, newContext, false))
				obj.u8sReceiverNumberSC = data.u8sReceiverNumberSC;
			if (TSConverter.validateParam(data, "u8sMessage", "string", errors, newContext, false))
				obj.u8sMessage = data.u8sMessage;
			if (TSConverter.validateParam(data, "iContentIsPrivate", "number", errors, newContext, false))
				obj.iContentIsPrivate = data.iContentIsPrivate;
			const tscv_contactreceiver = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.contactReceiver, tscv_contactreceiver, errors, newContext, "contactReceiver", true))
				obj.contactReceiver = tscv_contactreceiver;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSMSMessage"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSendSMSResult
export class AsnSendSMSResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSendSMSResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSendSMSResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSendSMSResult", newContext);
		value += TSConverter.addObjectAsStringParam("u8sCrossRefIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sCrossRefIDs, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSendSMSResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSendSMSResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSendSMSResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSendSMSResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSendSMSResult
			const tscv_u8scrossrefids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sCrossRefIDs, tscv_u8scrossrefids, errors, newContext, "u8sCrossRefIDs", false))
				obj.u8sCrossRefIDs = tscv_u8scrossrefids;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSendSMSResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUserChangePasswordArgument
export class AsnUserChangePasswordArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUserChangePasswordArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUserChangePasswordArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUserChangePasswordArgument", newContext);
		value += TSConverter.addStringParam("u8sOldPassword", obj.u8sOldPassword, newContext);
		value += TSConverter.addStringParam("u8sNewPassword", obj.u8sNewPassword, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUserChangePasswordArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUserChangePasswordArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUserChangePasswordArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUserChangePasswordArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUserChangePasswordArgument
			if (TSConverter.validateParam(data, "u8sOldPassword", "string", errors, newContext, false))
				obj.u8sOldPassword = data.u8sOldPassword;
			if (TSConverter.validateParam(data, "u8sNewPassword", "string", errors, newContext, false))
				obj.u8sNewPassword = data.u8sNewPassword;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUserChangePasswordArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUserChangePasswordResult
export class AsnUserChangePasswordResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUserChangePasswordResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUserChangePasswordResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUserChangePasswordResult", newContext);
		value += TSConverter.addBooleanParam("success", obj.success, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUserChangePasswordResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUserChangePasswordResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUserChangePasswordResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUserChangePasswordResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUserChangePasswordResult
			if (TSConverter.validateParam(data, "success", "boolean", errors, newContext, false))
				obj.success = data.success;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUserChangePasswordResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetUserdefinedCustomFieldsArgument
export class AsnGetUserdefinedCustomFieldsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetUserdefinedCustomFieldsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetUserdefinedCustomFieldsArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetUserdefinedCustomFieldsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetUserdefinedCustomFieldsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetUserdefinedCustomFieldsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetUserdefinedCustomFieldsArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetUserdefinedCustomFieldsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUserDefinedCustomField
export class AsnUserDefinedCustomField_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUserDefinedCustomField, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUserDefinedCustomField
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUserDefinedCustomField", newContext);
		value += TSConverter.addStringParam("u8sFieldName", obj.u8sFieldName, newContext);
		value += TSConverter.addStringParam("u8sFieldValue", obj.u8sFieldValue, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUserDefinedCustomField, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUserDefinedCustomField");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUserDefinedCustomField"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUserDefinedCustomField;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUserDefinedCustomField
			if (TSConverter.validateParam(data, "u8sFieldName", "string", errors, newContext, false))
				obj.u8sFieldName = data.u8sFieldName;
			if (TSConverter.validateParam(data, "u8sFieldValue", "string", errors, newContext, false))
				obj.u8sFieldValue = data.u8sFieldValue;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUserDefinedCustomField"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSendMailArgument
export class AsnSendMailArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSendMailArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSendMailArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSendMailArgument", newContext);
		value += TSConverter.addStringParam("u8sReceiverSIPURI", obj.u8sReceiverSIPURI, newContext);
		value += TSConverter.addStringParam("u8sMessage", obj.u8sMessage, newContext);
		value += TSConverter.addStringParam("u8sSubject", obj.u8sSubject, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSendMailArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSendMailArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSendMailArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSendMailArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSendMailArgument
			if (TSConverter.validateParam(data, "u8sReceiverSIPURI", "string", errors, newContext, false))
				obj.u8sReceiverSIPURI = data.u8sReceiverSIPURI;
			if (TSConverter.validateParam(data, "u8sMessage", "string", errors, newContext, false))
				obj.u8sMessage = data.u8sMessage;
			if (TSConverter.validateParam(data, "u8sSubject", "string", errors, newContext, false))
				obj.u8sSubject = data.u8sSubject;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSendMailArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSendMailResult
export class AsnSendMailResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSendMailResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSendMailResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSendMailResult", newContext);
		value += TSConverter.addBooleanParam("success", obj.success, newContext);
		value += TSConverter.addStringParam("u8sErrReason", obj.u8sErrReason, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSendMailResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSendMailResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSendMailResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSendMailResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSendMailResult
			if (TSConverter.validateParam(data, "success", "boolean", errors, newContext, false))
				obj.success = data.success;
			if (TSConverter.validateParam(data, "u8sErrReason", "string", errors, newContext, false))
				obj.u8sErrReason = data.u8sErrReason;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSendMailResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetServerInterfaceVersionArgument
export class AsnGetServerInterfaceVersionArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetServerInterfaceVersionArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetServerInterfaceVersionArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetServerInterfaceVersionArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetServerInterfaceVersionArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetServerInterfaceVersionArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetServerInterfaceVersionArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetServerInterfaceVersionArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetServerInterfaceVersionArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetServerInterfaceVersionArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetServerInterfaceVersionResult
export class AsnGetServerInterfaceVersionResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetServerInterfaceVersionResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetServerInterfaceVersionResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetServerInterfaceVersionResult", newContext);
		value += TSConverter.addNumberParam("iServerInterfaceVersion", obj.iServerInterfaceVersion, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetServerInterfaceVersionResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetServerInterfaceVersionResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetServerInterfaceVersionResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetServerInterfaceVersionResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetServerInterfaceVersionResult
			if (TSConverter.validateParam(data, "iServerInterfaceVersion", "number", errors, newContext, false))
				obj.iServerInterfaceVersion = data.iServerInterfaceVersion;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetServerInterfaceVersionResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRemoteInvokeCompleteArgument
export class AsnRemoteInvokeCompleteArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnRemoteInvokeCompleteArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRemoteInvokeCompleteArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRemoteInvokeCompleteArgument", newContext);
		value += TSConverter.addStringParam("u8sInvokeID", obj.u8sInvokeID, newContext);
		value += TSConverter.addStringParam("u8sInvokingConnection", obj.u8sInvokingConnection, newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnRemoteInvokeCompleteArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRemoteInvokeCompleteArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRemoteInvokeCompleteArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnRemoteInvokeCompleteArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRemoteInvokeCompleteArgument
			if (TSConverter.validateParam(data, "u8sInvokeID", "string", errors, newContext, false))
				obj.u8sInvokeID = data.u8sInvokeID;
			if (TSConverter.validateParam(data, "u8sInvokingConnection", "string", errors, newContext, false))
				obj.u8sInvokingConnection = data.u8sInvokingConnection;
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRemoteInvokeCompleteArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNumberFormatRegExpressList
export class AsnNumberFormatRegExpressList_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnNumberFormatRegExpressList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnNumberFormatRegExpressList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnNumberFormatRegExpression_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnNumberFormatRegExpressList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNumberFormatRegExpressList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpressList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnNumberFormatRegExpressList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnNumberFormatRegExpressList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnNumberFormatRegExpression.initEmpty();
				if (AsnNumberFormatRegExpression_Converter.fromJSON(elem, entry, errors, newContext, "AsnNumberFormatRegExpression", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpressList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLocationInformation
export class AsnLocationInformation_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnLocationInformation, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnLocationInformation
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLocationInformation", newContext);
		value += TSConverter.addStringParam("u8sLocationID", obj.u8sLocationID, newContext);
		value += TSConverter.addNumberParam("iCountryID", obj.iCountryID, newContext);
		value += TSConverter.addStringParam("u8sCountryCode", obj.u8sCountryCode, newContext);
		value += TSConverter.addStringParam("u8sLocalPrefix", obj.u8sLocalPrefix, newContext);
		value += TSConverter.addStringParam("u8sNationalPrefix", obj.u8sNationalPrefix, newContext);
		value += TSConverter.addStringParam("u8sInternationalPrefix", obj.u8sInternationalPrefix, newContext);
		value += TSConverter.addStringParam("u8sLocalDialingRule", obj.u8sLocalDialingRule, newContext);
		value += TSConverter.addStringParam("u8sNationalDialingRule", obj.u8sNationalDialingRule, newContext);
		value += TSConverter.addStringParam("u8sInternationalDialingRule", obj.u8sInternationalDialingRule, newContext);
		value += TSConverter.addStringParam("u8sCityCodeNoPrefix", obj.u8sCityCodeNoPrefix, newContext);
		value += TSConverter.addStringParam("u8sVanityNumbers", obj.u8sVanityNumbers, newContext);
		value += TSConverter.addNumberParam("iUseVanity", obj.iUseVanity, newContext);
		value += TSConverter.addStringParam("u8sAddressStreet", obj.u8sAddressStreet, newContext);
		value += TSConverter.addStringParam("u8sAddressZIPCode", obj.u8sAddressZIPCode, newContext);
		value += TSConverter.addStringParam("u8sAddressCity", obj.u8sAddressCity, newContext);
		value += TSConverter.addNumberParam("iUseTkSystem", obj.iUseTkSystem, newContext);
		value += TSConverter.addStringParam("u8sTKLocalAccessCode", obj.u8sTKLocalAccessCode, newContext);
		value += TSConverter.addStringParam("u8sTKLongDistanceAccessCode", obj.u8sTKLongDistanceAccessCode, newContext);
		value += TSConverter.addStringParam("u8sTKInternationalAccessCode", obj.u8sTKInternationalAccessCode, newContext);
		value += TSConverter.addStringParam("u8sTKPrivateAccessCode", obj.u8sTKPrivateAccessCode, newContext);
		value += TSConverter.addStringParam("u8sTKAnschlussNr", obj.u8sTKAnschlussNr, newContext);
		value += TSConverter.addNumberParam("iTKMaxLenInternalNumbers", obj.iTKMaxLenInternalNumbers, newContext);
		value += TSConverter.addObjectAsStringParam("u8slistTKAkzListRemoveIn", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistTKAkzListRemoveIn, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("u8slistTKAkzListRemoveOut", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistTKAkzListRemoveOut, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("u8slistTKAkzListRemoveForwarding", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistTKAkzListRemoveForwarding, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("u8slistTKDetectInternalRules", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistTKDetectInternalRules, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("u8slistTKDetectExternalRules", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8slistTKDetectExternalRules, newContext), newContext);
		value += TSConverter.addNumberParam("iUseAdvancedLCR", obj.iUseAdvancedLCR, newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatRegExpressListIn", AsnNumberFormatRegExpressList_Converter.toJSON(obj.numberFormatRegExpressListIn, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatRegExpressListOut", AsnNumberFormatRegExpressList_Converter.toJSON(obj.numberFormatRegExpressListOut, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatRegExpressListDial", AsnNumberFormatRegExpressList_Converter.toJSON(obj.numberFormatRegExpressListDial, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("projectPINRules", ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.toJSON(obj.projectPINRules, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnLocationInformation, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLocationInformation");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLocationInformation"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnLocationInformation;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnLocationInformation
			if (TSConverter.validateParam(data, "u8sLocationID", "string", errors, newContext, false))
				obj.u8sLocationID = data.u8sLocationID;
			if (TSConverter.validateParam(data, "iCountryID", "number", errors, newContext, false))
				obj.iCountryID = data.iCountryID;
			if (TSConverter.validateParam(data, "u8sCountryCode", "string", errors, newContext, false))
				obj.u8sCountryCode = data.u8sCountryCode;
			if (TSConverter.validateParam(data, "u8sLocalPrefix", "string", errors, newContext, false))
				obj.u8sLocalPrefix = data.u8sLocalPrefix;
			if (TSConverter.validateParam(data, "u8sNationalPrefix", "string", errors, newContext, false))
				obj.u8sNationalPrefix = data.u8sNationalPrefix;
			if (TSConverter.validateParam(data, "u8sInternationalPrefix", "string", errors, newContext, false))
				obj.u8sInternationalPrefix = data.u8sInternationalPrefix;
			if (TSConverter.validateParam(data, "u8sLocalDialingRule", "string", errors, newContext, false))
				obj.u8sLocalDialingRule = data.u8sLocalDialingRule;
			if (TSConverter.validateParam(data, "u8sNationalDialingRule", "string", errors, newContext, false))
				obj.u8sNationalDialingRule = data.u8sNationalDialingRule;
			if (TSConverter.validateParam(data, "u8sInternationalDialingRule", "string", errors, newContext, false))
				obj.u8sInternationalDialingRule = data.u8sInternationalDialingRule;
			if (TSConverter.validateParam(data, "u8sCityCodeNoPrefix", "string", errors, newContext, false))
				obj.u8sCityCodeNoPrefix = data.u8sCityCodeNoPrefix;
			if (TSConverter.validateParam(data, "u8sVanityNumbers", "string", errors, newContext, false))
				obj.u8sVanityNumbers = data.u8sVanityNumbers;
			if (TSConverter.validateParam(data, "iUseVanity", "number", errors, newContext, false))
				obj.iUseVanity = data.iUseVanity;
			if (TSConverter.validateParam(data, "u8sAddressStreet", "string", errors, newContext, false))
				obj.u8sAddressStreet = data.u8sAddressStreet;
			if (TSConverter.validateParam(data, "u8sAddressZIPCode", "string", errors, newContext, false))
				obj.u8sAddressZIPCode = data.u8sAddressZIPCode;
			if (TSConverter.validateParam(data, "u8sAddressCity", "string", errors, newContext, false))
				obj.u8sAddressCity = data.u8sAddressCity;
			if (TSConverter.validateParam(data, "iUseTkSystem", "number", errors, newContext, false))
				obj.iUseTkSystem = data.iUseTkSystem;
			if (TSConverter.validateParam(data, "u8sTKLocalAccessCode", "string", errors, newContext, false))
				obj.u8sTKLocalAccessCode = data.u8sTKLocalAccessCode;
			if (TSConverter.validateParam(data, "u8sTKLongDistanceAccessCode", "string", errors, newContext, false))
				obj.u8sTKLongDistanceAccessCode = data.u8sTKLongDistanceAccessCode;
			if (TSConverter.validateParam(data, "u8sTKInternationalAccessCode", "string", errors, newContext, false))
				obj.u8sTKInternationalAccessCode = data.u8sTKInternationalAccessCode;
			if (TSConverter.validateParam(data, "u8sTKPrivateAccessCode", "string", errors, newContext, false))
				obj.u8sTKPrivateAccessCode = data.u8sTKPrivateAccessCode;
			if (TSConverter.validateParam(data, "u8sTKAnschlussNr", "string", errors, newContext, false))
				obj.u8sTKAnschlussNr = data.u8sTKAnschlussNr;
			if (TSConverter.validateParam(data, "iTKMaxLenInternalNumbers", "number", errors, newContext, false))
				obj.iTKMaxLenInternalNumbers = data.iTKMaxLenInternalNumbers;
			const tscv_u8slisttkakzlistremovein = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistTKAkzListRemoveIn, tscv_u8slisttkakzlistremovein, errors, newContext, "u8slistTKAkzListRemoveIn", false))
				obj.u8slistTKAkzListRemoveIn = tscv_u8slisttkakzlistremovein;
			const tscv_u8slisttkakzlistremoveout = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistTKAkzListRemoveOut, tscv_u8slisttkakzlistremoveout, errors, newContext, "u8slistTKAkzListRemoveOut", false))
				obj.u8slistTKAkzListRemoveOut = tscv_u8slisttkakzlistremoveout;
			const tscv_u8slisttkakzlistremoveforwarding = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistTKAkzListRemoveForwarding, tscv_u8slisttkakzlistremoveforwarding, errors, newContext, "u8slistTKAkzListRemoveForwarding", false))
				obj.u8slistTKAkzListRemoveForwarding = tscv_u8slisttkakzlistremoveforwarding;
			const tscv_u8slisttkdetectinternalrules = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistTKDetectInternalRules, tscv_u8slisttkdetectinternalrules, errors, newContext, "u8slistTKDetectInternalRules", false))
				obj.u8slistTKDetectInternalRules = tscv_u8slisttkdetectinternalrules;
			const tscv_u8slisttkdetectexternalrules = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8slistTKDetectExternalRules, tscv_u8slisttkdetectexternalrules, errors, newContext, "u8slistTKDetectExternalRules", false))
				obj.u8slistTKDetectExternalRules = tscv_u8slisttkdetectexternalrules;
			if (TSConverter.validateParam(data, "iUseAdvancedLCR", "number", errors, newContext, false))
				obj.iUseAdvancedLCR = data.iUseAdvancedLCR;
			const tscv_numberformatregexpresslistin = new ENetROSEInterface.AsnNumberFormatRegExpressList();
			if (AsnNumberFormatRegExpressList_Converter.fromJSON(data.numberFormatRegExpressListIn, tscv_numberformatregexpresslistin, errors, newContext, "numberFormatRegExpressListIn", false))
				obj.numberFormatRegExpressListIn = tscv_numberformatregexpresslistin;
			const tscv_numberformatregexpresslistout = new ENetROSEInterface.AsnNumberFormatRegExpressList();
			if (AsnNumberFormatRegExpressList_Converter.fromJSON(data.numberFormatRegExpressListOut, tscv_numberformatregexpresslistout, errors, newContext, "numberFormatRegExpressListOut", false))
				obj.numberFormatRegExpressListOut = tscv_numberformatregexpresslistout;
			const tscv_numberformatregexpresslistdial = new ENetROSEInterface.AsnNumberFormatRegExpressList();
			if (AsnNumberFormatRegExpressList_Converter.fromJSON(data.numberFormatRegExpressListDial, tscv_numberformatregexpresslistdial, errors, newContext, "numberFormatRegExpressListDial", false))
				obj.numberFormatRegExpressListDial = tscv_numberformatregexpresslistdial;
			const tscv_projectpinrules = new ENetUC_Common_SIPCTI.AsnProjectPinRuleList();
			if (ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.fromJSON(data.projectPINRules, tscv_projectpinrules, errors, newContext, "projectPINRules", false))
				obj.projectPINRules = tscv_projectpinrules;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLocationInformation"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNumberFormatRegExpressList2
export class AsnNumberFormatRegExpressList2_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnNumberFormatRegExpressList2, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnNumberFormatRegExpressList2
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnNumberFormatRegExpression2_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnNumberFormatRegExpressList2, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNumberFormatRegExpressList2");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpressList2"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnNumberFormatRegExpressList2;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnNumberFormatRegExpressList2
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnNumberFormatRegExpression2.initEmpty();
				if (AsnNumberFormatRegExpression2_Converter.fromJSON(elem, entry, errors, newContext, "AsnNumberFormatRegExpression2", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpressList2"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNumberFormatRegExpressions2
export class AsnNumberFormatRegExpressions2_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnNumberFormatRegExpressions2, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNumberFormatRegExpressions2
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNumberFormatRegExpressions2", newContext);
		value += TSConverter.addBooleanParam("bIsReadOnlyFromFile", obj.bIsReadOnlyFromFile, newContext);
		value += TSConverter.addObjectAsStringParam("formatRules", AsnNumberFormatRegExpressList2_Converter.toJSON(obj.formatRules, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnNumberFormatRegExpressions2, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNumberFormatRegExpressions2");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpressions2"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnNumberFormatRegExpressions2;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNumberFormatRegExpressions2
			if (TSConverter.validateParam(data, "bIsReadOnlyFromFile", "boolean", errors, newContext, false))
				obj.bIsReadOnlyFromFile = data.bIsReadOnlyFromFile;
			const tscv_formatrules = new ENetROSEInterface.AsnNumberFormatRegExpressList2();
			if (AsnNumberFormatRegExpressList2_Converter.fromJSON(data.formatRules, tscv_formatrules, errors, newContext, "formatRules", false))
				obj.formatRules = tscv_formatrules;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNumberFormatRegExpressions2"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLocationInformation2
export class AsnLocationInformation2_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnLocationInformation2, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnLocationInformation2
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLocationInformation2", newContext);
		value += TSConverter.addStringParam("u8sLocationID", obj.u8sLocationID, newContext);
		value += TSConverter.addStringParam("u8sLocationCheckSum", obj.u8sLocationCheckSum, newContext);
		value += TSConverter.addBooleanParam("bUseVanity", obj.bUseVanity, newContext);
		value += TSConverter.addBooleanParam("bShowExtendedSettings", obj.bShowExtendedSettings, newContext);
		value += TSConverter.addBooleanParam("bUseTkSystem", obj.bUseTkSystem, newContext);
		value += TSConverter.addBooleanParam("bUseAdvancedLCR", obj.bUseAdvancedLCR, newContext);
		value += TSConverter.addBooleanParam("bFixedNumberLenExtensionDTMFDialing", obj.bFixedNumberLenExtensionDTMFDialing, newContext);
		value += TSConverter.addBooleanParam("bMultiLocationSupport", obj.bMultiLocationSupport, newContext);
		value += TSConverter.addBooleanParam("bAutoCreateOtherLocationRules", obj.bAutoCreateOtherLocationRules, newContext);
		value += TSConverter.addBooleanParam("bAllowNumberSearchOtherLocations", obj.bAllowNumberSearchOtherLocations, newContext);
		value += TSConverter.addBooleanParam("bShortenOtherLocationSCNumbersForDialing", obj.bShortenOtherLocationSCNumbersForDialing, newContext);
		value += TSConverter.addNumberParam("iCountryID", obj.iCountryID, newContext);
		value += TSConverter.addNumberParam("iLanguage", obj.iLanguage, newContext);
		value += TSConverter.addStringParam("u8sCountryCode", obj.u8sCountryCode, newContext);
		value += TSConverter.addStringParam("u8sLocalPrefix", obj.u8sLocalPrefix, newContext);
		value += TSConverter.addStringParam("u8sNationalPrefix", obj.u8sNationalPrefix, newContext);
		value += TSConverter.addStringParam("u8sInternationalPrefix", obj.u8sInternationalPrefix, newContext);
		value += TSConverter.addStringParam("u8sLocalDialingRule", obj.u8sLocalDialingRule, newContext);
		value += TSConverter.addStringParam("u8sNationalDialingRule", obj.u8sNationalDialingRule, newContext);
		value += TSConverter.addStringParam("u8sInternationalDialingRule", obj.u8sInternationalDialingRule, newContext);
		value += TSConverter.addStringParam("u8sCityCodeNoPrefix", obj.u8sCityCodeNoPrefix, newContext);
		value += TSConverter.addStringParam("u8sVanityNumbers", obj.u8sVanityNumbers, newContext);
		value += TSConverter.addStringParam("u8sTKLocalAccessCode", obj.u8sTKLocalAccessCode, newContext);
		value += TSConverter.addStringParam("u8sTKLongDistanceAccessCode", obj.u8sTKLongDistanceAccessCode, newContext);
		value += TSConverter.addStringParam("u8sTKInternationalAccessCode", obj.u8sTKInternationalAccessCode, newContext);
		value += TSConverter.addStringParam("u8sTKPrivateAccessCode", obj.u8sTKPrivateAccessCode, newContext);
		value += TSConverter.addStringParam("u8sTKForwardingAccessCode", obj.u8sTKForwardingAccessCode, newContext);
		value += TSConverter.addStringParam("u8sTKAnschlussNr", obj.u8sTKAnschlussNr, newContext);
		value += TSConverter.addNumberParam("iTKMinLenInternalNumbers", obj.iTKMinLenInternalNumbers, newContext);
		value += TSConverter.addNumberParam("iTKMaxLenInternalNumbers", obj.iTKMaxLenInternalNumbers, newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatInternal", AsnNumberFormatRegExpressions2_Converter.toJSON(obj.numberFormatInternal, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatExternal", AsnNumberFormatRegExpressions2_Converter.toJSON(obj.numberFormatExternal, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatAutomaticOtherLocationsDDItoSC", AsnNumberFormatRegExpressions2_Converter.toJSON(obj.numberFormatAutomaticOtherLocationsDDItoSC, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatAutomaticOtherLocationsSCtoDDI", AsnNumberFormatRegExpressions2_Converter.toJSON(obj.numberFormatAutomaticOtherLocationsSCtoDDI, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatRegExpressListIn", AsnNumberFormatRegExpressions2_Converter.toJSON(obj.numberFormatRegExpressListIn, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatRegExpressListOut", AsnNumberFormatRegExpressions2_Converter.toJSON(obj.numberFormatRegExpressListOut, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatRegExpressListDial", AsnNumberFormatRegExpressions2_Converter.toJSON(obj.numberFormatRegExpressListDial, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("numberFormatRegExpressListFinalDial", AsnNumberFormatRegExpressions2_Converter.toJSON(obj.numberFormatRegExpressListFinalDial, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("projectPINRules", ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.toJSON(obj.projectPINRules, newContext), newContext);
		value += TSConverter.addStringParam("u8sDDIRangeBegin", obj.u8sDDIRangeBegin, newContext);
		value += TSConverter.addStringParam("u8sDDIRangeEnd", obj.u8sDDIRangeEnd, newContext);
		value += TSConverter.addNumberParam("iDialingNumberFormat", obj.iDialingNumberFormat, newContext);
		value += TSConverter.addBooleanParam("bNoLocalDialing", obj.bNoLocalDialing, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnLocationInformation2, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLocationInformation2");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLocationInformation2"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnLocationInformation2;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnLocationInformation2
			if (TSConverter.validateParam(data, "u8sLocationID", "string", errors, newContext, false))
				obj.u8sLocationID = data.u8sLocationID;
			if (TSConverter.validateParam(data, "u8sLocationCheckSum", "string", errors, newContext, false))
				obj.u8sLocationCheckSum = data.u8sLocationCheckSum;
			if (TSConverter.validateParam(data, "bUseVanity", "boolean", errors, newContext, false))
				obj.bUseVanity = data.bUseVanity;
			if (TSConverter.validateParam(data, "bShowExtendedSettings", "boolean", errors, newContext, false))
				obj.bShowExtendedSettings = data.bShowExtendedSettings;
			if (TSConverter.validateParam(data, "bUseTkSystem", "boolean", errors, newContext, false))
				obj.bUseTkSystem = data.bUseTkSystem;
			if (TSConverter.validateParam(data, "bUseAdvancedLCR", "boolean", errors, newContext, false))
				obj.bUseAdvancedLCR = data.bUseAdvancedLCR;
			if (TSConverter.validateParam(data, "bFixedNumberLenExtensionDTMFDialing", "boolean", errors, newContext, false))
				obj.bFixedNumberLenExtensionDTMFDialing = data.bFixedNumberLenExtensionDTMFDialing;
			if (TSConverter.validateParam(data, "bMultiLocationSupport", "boolean", errors, newContext, false))
				obj.bMultiLocationSupport = data.bMultiLocationSupport;
			if (TSConverter.validateParam(data, "bAutoCreateOtherLocationRules", "boolean", errors, newContext, false))
				obj.bAutoCreateOtherLocationRules = data.bAutoCreateOtherLocationRules;
			if (TSConverter.validateParam(data, "bAllowNumberSearchOtherLocations", "boolean", errors, newContext, false))
				obj.bAllowNumberSearchOtherLocations = data.bAllowNumberSearchOtherLocations;
			if (TSConverter.validateParam(data, "bShortenOtherLocationSCNumbersForDialing", "boolean", errors, newContext, false))
				obj.bShortenOtherLocationSCNumbersForDialing = data.bShortenOtherLocationSCNumbersForDialing;
			if (TSConverter.validateParam(data, "iCountryID", "number", errors, newContext, false))
				obj.iCountryID = data.iCountryID;
			if (TSConverter.validateParam(data, "iLanguage", "number", errors, newContext, false))
				obj.iLanguage = data.iLanguage;
			if (TSConverter.validateParam(data, "u8sCountryCode", "string", errors, newContext, false))
				obj.u8sCountryCode = data.u8sCountryCode;
			if (TSConverter.validateParam(data, "u8sLocalPrefix", "string", errors, newContext, false))
				obj.u8sLocalPrefix = data.u8sLocalPrefix;
			if (TSConverter.validateParam(data, "u8sNationalPrefix", "string", errors, newContext, false))
				obj.u8sNationalPrefix = data.u8sNationalPrefix;
			if (TSConverter.validateParam(data, "u8sInternationalPrefix", "string", errors, newContext, false))
				obj.u8sInternationalPrefix = data.u8sInternationalPrefix;
			if (TSConverter.validateParam(data, "u8sLocalDialingRule", "string", errors, newContext, false))
				obj.u8sLocalDialingRule = data.u8sLocalDialingRule;
			if (TSConverter.validateParam(data, "u8sNationalDialingRule", "string", errors, newContext, false))
				obj.u8sNationalDialingRule = data.u8sNationalDialingRule;
			if (TSConverter.validateParam(data, "u8sInternationalDialingRule", "string", errors, newContext, false))
				obj.u8sInternationalDialingRule = data.u8sInternationalDialingRule;
			if (TSConverter.validateParam(data, "u8sCityCodeNoPrefix", "string", errors, newContext, false))
				obj.u8sCityCodeNoPrefix = data.u8sCityCodeNoPrefix;
			if (TSConverter.validateParam(data, "u8sVanityNumbers", "string", errors, newContext, false))
				obj.u8sVanityNumbers = data.u8sVanityNumbers;
			if (TSConverter.validateParam(data, "u8sTKLocalAccessCode", "string", errors, newContext, false))
				obj.u8sTKLocalAccessCode = data.u8sTKLocalAccessCode;
			if (TSConverter.validateParam(data, "u8sTKLongDistanceAccessCode", "string", errors, newContext, false))
				obj.u8sTKLongDistanceAccessCode = data.u8sTKLongDistanceAccessCode;
			if (TSConverter.validateParam(data, "u8sTKInternationalAccessCode", "string", errors, newContext, false))
				obj.u8sTKInternationalAccessCode = data.u8sTKInternationalAccessCode;
			if (TSConverter.validateParam(data, "u8sTKPrivateAccessCode", "string", errors, newContext, false))
				obj.u8sTKPrivateAccessCode = data.u8sTKPrivateAccessCode;
			if (TSConverter.validateParam(data, "u8sTKForwardingAccessCode", "string", errors, newContext, false))
				obj.u8sTKForwardingAccessCode = data.u8sTKForwardingAccessCode;
			if (TSConverter.validateParam(data, "u8sTKAnschlussNr", "string", errors, newContext, false))
				obj.u8sTKAnschlussNr = data.u8sTKAnschlussNr;
			if (TSConverter.validateParam(data, "iTKMinLenInternalNumbers", "number", errors, newContext, false))
				obj.iTKMinLenInternalNumbers = data.iTKMinLenInternalNumbers;
			if (TSConverter.validateParam(data, "iTKMaxLenInternalNumbers", "number", errors, newContext, false))
				obj.iTKMaxLenInternalNumbers = data.iTKMaxLenInternalNumbers;
			const tscv_numberformatinternal = ENetROSEInterface.AsnNumberFormatRegExpressions2.initEmpty();
			if (AsnNumberFormatRegExpressions2_Converter.fromJSON(data.numberFormatInternal, tscv_numberformatinternal, errors, newContext, "numberFormatInternal", false))
				obj.numberFormatInternal = tscv_numberformatinternal;
			const tscv_numberformatexternal = ENetROSEInterface.AsnNumberFormatRegExpressions2.initEmpty();
			if (AsnNumberFormatRegExpressions2_Converter.fromJSON(data.numberFormatExternal, tscv_numberformatexternal, errors, newContext, "numberFormatExternal", false))
				obj.numberFormatExternal = tscv_numberformatexternal;
			const tscv_numberformatautomaticotherlocationsdditosc = ENetROSEInterface.AsnNumberFormatRegExpressions2.initEmpty();
			if (AsnNumberFormatRegExpressions2_Converter.fromJSON(data.numberFormatAutomaticOtherLocationsDDItoSC, tscv_numberformatautomaticotherlocationsdditosc, errors, newContext, "numberFormatAutomaticOtherLocationsDDItoSC", false))
				obj.numberFormatAutomaticOtherLocationsDDItoSC = tscv_numberformatautomaticotherlocationsdditosc;
			const tscv_numberformatautomaticotherlocationssctoddi = ENetROSEInterface.AsnNumberFormatRegExpressions2.initEmpty();
			if (AsnNumberFormatRegExpressions2_Converter.fromJSON(data.numberFormatAutomaticOtherLocationsSCtoDDI, tscv_numberformatautomaticotherlocationssctoddi, errors, newContext, "numberFormatAutomaticOtherLocationsSCtoDDI", false))
				obj.numberFormatAutomaticOtherLocationsSCtoDDI = tscv_numberformatautomaticotherlocationssctoddi;
			const tscv_numberformatregexpresslistin = ENetROSEInterface.AsnNumberFormatRegExpressions2.initEmpty();
			if (AsnNumberFormatRegExpressions2_Converter.fromJSON(data.numberFormatRegExpressListIn, tscv_numberformatregexpresslistin, errors, newContext, "numberFormatRegExpressListIn", false))
				obj.numberFormatRegExpressListIn = tscv_numberformatregexpresslistin;
			const tscv_numberformatregexpresslistout = ENetROSEInterface.AsnNumberFormatRegExpressions2.initEmpty();
			if (AsnNumberFormatRegExpressions2_Converter.fromJSON(data.numberFormatRegExpressListOut, tscv_numberformatregexpresslistout, errors, newContext, "numberFormatRegExpressListOut", false))
				obj.numberFormatRegExpressListOut = tscv_numberformatregexpresslistout;
			const tscv_numberformatregexpresslistdial = ENetROSEInterface.AsnNumberFormatRegExpressions2.initEmpty();
			if (AsnNumberFormatRegExpressions2_Converter.fromJSON(data.numberFormatRegExpressListDial, tscv_numberformatregexpresslistdial, errors, newContext, "numberFormatRegExpressListDial", false))
				obj.numberFormatRegExpressListDial = tscv_numberformatregexpresslistdial;
			const tscv_numberformatregexpresslistfinaldial = ENetROSEInterface.AsnNumberFormatRegExpressions2.initEmpty();
			if (AsnNumberFormatRegExpressions2_Converter.fromJSON(data.numberFormatRegExpressListFinalDial, tscv_numberformatregexpresslistfinaldial, errors, newContext, "numberFormatRegExpressListFinalDial", false))
				obj.numberFormatRegExpressListFinalDial = tscv_numberformatregexpresslistfinaldial;
			const tscv_projectpinrules = new ENetUC_Common_SIPCTI.AsnProjectPinRuleList();
			if (ENetUC_Common_SIPCTI_Converter.AsnProjectPinRuleList_Converter.fromJSON(data.projectPINRules, tscv_projectpinrules, errors, newContext, "projectPINRules", false))
				obj.projectPINRules = tscv_projectpinrules;
			if (TSConverter.validateParam(data, "u8sDDIRangeBegin", "string", errors, newContext, false))
				obj.u8sDDIRangeBegin = data.u8sDDIRangeBegin;
			if (TSConverter.validateParam(data, "u8sDDIRangeEnd", "string", errors, newContext, false))
				obj.u8sDDIRangeEnd = data.u8sDDIRangeEnd;
			if (TSConverter.validateParam(data, "iDialingNumberFormat", "number", errors, newContext, false))
				obj.iDialingNumberFormat = data.iDialingNumberFormat;
			if (TSConverter.validateParam(data, "bNoLocalDialing", "boolean", errors, newContext, false))
				obj.bNoLocalDialing = data.bNoLocalDialing;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLocationInformation2"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetLocationInformationResult
export class AsnGetLocationInformationResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetLocationInformationResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderLocalTypeRef] AsnGetLocationInformationResult
		value += AsnLocationInformation_Converter.toJSON(obj, context)

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetLocationInformationResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetLocationInformationResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationInformationResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetLocationInformationResult;
		if (data !== undefined) {
			// [PrintTSDecoderLocalTypeRef] AsnGetLocationInformationResult
			AsnLocationInformation_Converter.fromJSON(data, obj, errors, newContext, "", optional)
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationInformationResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetLocationInformation2Result
export class AsnGetLocationInformation2Result_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetLocationInformation2Result, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetLocationInformation2Result
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetLocationInformation2Result", newContext);
		value += TSConverter.addObjectAsStringParam("asnLocationData", AsnLocationInformation2_Converter.toJSON(obj.asnLocationData, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetLocationInformation2Result, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetLocationInformation2Result");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationInformation2Result"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetLocationInformation2Result;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetLocationInformation2Result
			const tscv_asnlocationdata = ENetROSEInterface.AsnLocationInformation2.initEmpty();
			if (AsnLocationInformation2_Converter.fromJSON(data.asnLocationData, tscv_asnlocationdata, errors, newContext, "asnLocationData", false))
				obj.asnLocationData = tscv_asnlocationdata;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLocationInformation2Result"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetLocationInformation2Argument
export class AsnSetLocationInformation2Argument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetLocationInformation2Argument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetLocationInformation2Argument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetLocationInformation2Argument", newContext);
		value += TSConverter.addObjectAsStringParam("asnLocationData", AsnLocationInformation2_Converter.toJSON(obj.asnLocationData, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetLocationInformation2Argument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetLocationInformation2Argument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetLocationInformation2Argument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetLocationInformation2Argument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetLocationInformation2Argument
			const tscv_asnlocationdata = ENetROSEInterface.AsnLocationInformation2.initEmpty();
			if (AsnLocationInformation2_Converter.fromJSON(data.asnLocationData, tscv_asnlocationdata, errors, newContext, "asnLocationData", false))
				obj.asnLocationData = tscv_asnlocationdata;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetLocationInformation2Argument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetClientXMLContentResult
export class AsnGetClientXMLContentResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetClientXMLContentResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetClientXMLContentResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetClientXMLContentResult", newContext);
		value += TSConverter.addObjectAsStringParam("content", AsnXMLClientContent_Converter.toJSON(obj.content, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetClientXMLContentResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetClientXMLContentResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientXMLContentResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetClientXMLContentResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetClientXMLContentResult
			const tscv_content = ENetROSEInterface.AsnXMLClientContent.initEmpty();
			if (AsnXMLClientContent_Converter.fromJSON(data.content, tscv_content, errors, newContext, "content", false))
				obj.content = tscv_content;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientXMLContentResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetClientXMLContentArgument
export class AsnSetClientXMLContentArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetClientXMLContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetClientXMLContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetClientXMLContentArgument", newContext);
		value += TSConverter.addObjectAsStringParam("content", AsnXMLClientContent_Converter.toJSON(obj.content, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetClientXMLContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetClientXMLContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientXMLContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetClientXMLContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetClientXMLContentArgument
			const tscv_content = ENetROSEInterface.AsnXMLClientContent.initEmpty();
			if (AsnXMLClientContent_Converter.fromJSON(data.content, tscv_content, errors, newContext, "content", false))
				obj.content = tscv_content;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientXMLContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnActionFilters
export class AsnActionFilters_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnActionFilters, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnActionFilters
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnActionFilterEntry_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnActionFilters, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnActionFilters");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnActionFilters"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnActionFilters;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnActionFilters
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnActionFilterEntry.initEmpty();
				if (AsnActionFilterEntry_Converter.fromJSON(elem, entry, errors, newContext, "AsnActionFilterEntry", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnActionFilters"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLineForwardProfile
export class AsnLineForwardProfile_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnLineForwardProfile, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnLineForwardProfile
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLineForwardProfile", newContext);
		value += TSConverter.addStringParam("u8sGUID", obj.u8sGUID, newContext);
		value += TSConverter.addStringParam("u8sDisplayName", obj.u8sDisplayName, newContext);
		value += TSConverter.addObjectAsStringParam("lineIdentification", AsnLineForwardProfileChoice_Converter.toJSON(obj.lineIdentification, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("seqLineForward", ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.toJSON(obj.seqLineForward, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnLineForwardProfile, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLineForwardProfile");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLineForwardProfile"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnLineForwardProfile;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnLineForwardProfile
			if (TSConverter.validateParam(data, "u8sGUID", "string", errors, newContext, false))
				obj.u8sGUID = data.u8sGUID;
			if (TSConverter.validateParam(data, "u8sDisplayName", "string", errors, newContext, false))
				obj.u8sDisplayName = data.u8sDisplayName;
			const tscv_lineidentification = ENetROSEInterface.AsnLineForwardProfileChoice.initEmpty();
			if (AsnLineForwardProfileChoice_Converter.fromJSON(data.lineIdentification, tscv_lineidentification, errors, newContext, "lineIdentification", false))
				obj.lineIdentification = tscv_lineidentification;
			const tscv_seqlineforward = new ENetUC_Common_SIPCTI.AsnLineForwards();
			if (ENetUC_Common_SIPCTI_Converter.AsnLineForwards_Converter.fromJSON(data.seqLineForward, tscv_seqlineforward, errors, newContext, "seqLineForward", false))
				obj.seqLineForward = tscv_seqlineforward;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLineForwardProfile"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAppointmentForwardEntry
export class AsnAppointmentForwardEntry_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAppointmentForwardEntry, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAppointmentForwardEntry
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAppointmentForwardEntry", newContext);
		value += TSConverter.addNumberParam("iAppointmentContactState", obj.iAppointmentContactState, newContext);
		value += TSConverter.addObjectAsStringParam("lineForwardProfile", AsnLineForwardProfile_Converter.toJSON(obj.lineForwardProfile, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("seqFilters", AsnActionFilters_Converter.toJSON(obj.seqFilters, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAppointmentForwardEntry, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAppointmentForwardEntry");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAppointmentForwardEntry"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAppointmentForwardEntry;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAppointmentForwardEntry
			if (TSConverter.validateParam(data, "iAppointmentContactState", "number", errors, newContext, false))
				obj.iAppointmentContactState = data.iAppointmentContactState;
			const tscv_lineforwardprofile = ENetROSEInterface.AsnLineForwardProfile.initEmpty();
			if (AsnLineForwardProfile_Converter.fromJSON(data.lineForwardProfile, tscv_lineforwardprofile, errors, newContext, "lineForwardProfile", false))
				obj.lineForwardProfile = tscv_lineforwardprofile;
			const tscv_seqfilters = new ENetROSEInterface.AsnActionFilters();
			if (AsnActionFilters_Converter.fromJSON(data.seqFilters, tscv_seqfilters, errors, newContext, "seqFilters", true))
				obj.seqFilters = tscv_seqfilters;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAppointmentForwardEntry"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAppointmentForwardEntries
export class AsnAppointmentForwardEntries_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAppointmentForwardEntries, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnAppointmentForwardEntries
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnAppointmentForwardEntry_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAppointmentForwardEntries, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAppointmentForwardEntries");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAppointmentForwardEntries"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAppointmentForwardEntries;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnAppointmentForwardEntries
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnAppointmentForwardEntry.initEmpty();
				if (AsnAppointmentForwardEntry_Converter.fromJSON(elem, entry, errors, newContext, "AsnAppointmentForwardEntry", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAppointmentForwardEntries"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetAppointmentForwardsArgument
export class AsnSetAppointmentForwardsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetAppointmentForwardsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetAppointmentForwardsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetAppointmentForwardsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("seqAppointmentForwardEntries", AsnAppointmentForwardEntries_Converter.toJSON(obj.seqAppointmentForwardEntries, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetAppointmentForwardsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetAppointmentForwardsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetAppointmentForwardsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetAppointmentForwardsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetAppointmentForwardsArgument
			const tscv_seqappointmentforwardentries = new ENetROSEInterface.AsnAppointmentForwardEntries();
			if (AsnAppointmentForwardEntries_Converter.fromJSON(data.seqAppointmentForwardEntries, tscv_seqappointmentforwardentries, errors, newContext, "seqAppointmentForwardEntries", false))
				obj.seqAppointmentForwardEntries = tscv_seqappointmentforwardentries;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetAppointmentForwardsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetAppointmentForwardsResult
export class AsnGetAppointmentForwardsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetAppointmentForwardsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetAppointmentForwardsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetAppointmentForwardsResult", newContext);
		value += TSConverter.addObjectAsStringParam("seqAppointmentForwardEntries", AsnAppointmentForwardEntries_Converter.toJSON(obj.seqAppointmentForwardEntries, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetAppointmentForwardsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetAppointmentForwardsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAppointmentForwardsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetAppointmentForwardsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetAppointmentForwardsResult
			const tscv_seqappointmentforwardentries = new ENetROSEInterface.AsnAppointmentForwardEntries();
			if (AsnAppointmentForwardEntries_Converter.fromJSON(data.seqAppointmentForwardEntries, tscv_seqappointmentforwardentries, errors, newContext, "seqAppointmentForwardEntries", false))
				obj.seqAppointmentForwardEntries = tscv_seqappointmentforwardentries;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAppointmentForwardsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactIDs
export class AsnContactIDs_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactIDs, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnContactIDs
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				value += TSConverter.addStringParam("", obj[id], newContext);
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactIDs, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactIDs");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactIDs"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactIDs;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnContactIDs
			for (const id in data) {
				const elem = data[id];
				if (typeof elem === "string")
					obj.push(elem);
				else
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_TYPEMISMATCH, newContext.context + "string", "expected type was AsnContactID effective type is " + typeof data[id]));
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactIDs"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCloseContactsArgument
export class AsnCloseContactsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnCloseContactsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCloseContactsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCloseContactsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("seqContactIDs", AsnContactIDs_Converter.toJSON(obj.seqContactIDs, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnCloseContactsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCloseContactsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCloseContactsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnCloseContactsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCloseContactsArgument
			const tscv_seqcontactids = new ENetROSEInterface.AsnContactIDs();
			if (AsnContactIDs_Converter.fromJSON(data.seqContactIDs, tscv_seqcontactids, errors, newContext, "seqContactIDs", false))
				obj.seqContactIDs = tscv_seqcontactids;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCloseContactsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSettingsDynamicRightsChangedArgument
export class AsnSettingsDynamicRightsChangedArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderLocalTypeRef] AsnSettingsDynamicRightsChangedArgument
		value += AsnSetSettingsDynamicRightsArgument_Converter.toJSON(obj, context)

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSettingsDynamicRightsChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSettingsDynamicRightsChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSettingsDynamicRightsChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderLocalTypeRef] AsnSettingsDynamicRightsChangedArgument
			AsnSetSettingsDynamicRightsArgument_Converter.fromJSON(data, obj, errors, newContext, "", optional)
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSettingsDynamicRightsChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSettingsDynamicGroupRightsChangedArgument
export class AsnSettingsDynamicGroupRightsChangedArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderLocalTypeRef] AsnSettingsDynamicGroupRightsChangedArgument
		value += AsnSetSettingsDynamicRightsArgument_Converter.toJSON(obj, context)

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSettingsDynamicGroupRightsChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSettingsDynamicGroupRightsChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSettingsDynamicGroupRightsChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderLocalTypeRef] AsnSettingsDynamicGroupRightsChangedArgument
			AsnSetSettingsDynamicRightsArgument_Converter.fromJSON(data, obj, errors, newContext, "", optional)
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSettingsDynamicGroupRightsChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetSettingsDynamicGroupRightsArgument
export class AsnGetSettingsDynamicGroupRightsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderLocalTypeRef] AsnGetSettingsDynamicGroupRightsArgument
		value += AsnGetSettingsDynamicRightsArgument_Converter.toJSON(obj, context)

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetSettingsDynamicGroupRightsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsDynamicGroupRightsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetSettingsDynamicGroupRightsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderLocalTypeRef] AsnGetSettingsDynamicGroupRightsArgument
			AsnGetSettingsDynamicRightsArgument_Converter.fromJSON(data, obj, errors, newContext, "", optional)
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsDynamicGroupRightsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetSettingsDynamicGroupRightsResult
export class AsnGetSettingsDynamicGroupRightsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderLocalTypeRef] AsnGetSettingsDynamicGroupRightsResult
		value += AsnGetSettingsDynamicRightsResult_Converter.toJSON(obj, context)

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetSettingsDynamicGroupRightsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsDynamicGroupRightsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetSettingsDynamicGroupRightsResult;
		if (data !== undefined) {
			// [PrintTSDecoderLocalTypeRef] AsnGetSettingsDynamicGroupRightsResult
			AsnGetSettingsDynamicRightsResult_Converter.fromJSON(data, obj, errors, newContext, "", optional)
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsDynamicGroupRightsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetSettingsGlobalDynamicRightsArgument
export class AsnGetSettingsGlobalDynamicRightsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderLocalTypeRef] AsnGetSettingsGlobalDynamicRightsArgument
		value += AsnGetSettingsDynamicRightsArgument_Converter.toJSON(obj, context)

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetSettingsGlobalDynamicRightsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsGlobalDynamicRightsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetSettingsGlobalDynamicRightsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderLocalTypeRef] AsnGetSettingsGlobalDynamicRightsArgument
			AsnGetSettingsDynamicRightsArgument_Converter.fromJSON(data, obj, errors, newContext, "", optional)
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSettingsGlobalDynamicRightsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPresenceState
export class AsnPresenceState_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnPresenceState, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnPresenceState
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnPresenceState", newContext);
		value += TSConverter.addStringParam("contactID", obj.contactID, newContext);
		value += TSConverter.addNumberParam("iPresenceState", obj.iPresenceState, newContext);
		value += TSConverter.addNumberParam("iContactFeatureSet", obj.iContactFeatureSet, newContext);
		value += TSConverter.addObjectAsStringParam("asnRemoteContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.asnRemoteContact, newContext), newContext);
		value += TSConverter.addNumberParam("iContactConnectionState", obj.iContactConnectionState, newContext);
		value += TSConverter.addStringParam("u8sAdditionalConnectionInfo", obj.u8sAdditionalConnectionInfo, newContext);
		value += TSConverter.addObjectAsStringParam("seqPhoneLines", ENetUC_Common_AsnContact_Converter.AsnContactPhoneLines_Converter.toJSON(obj.seqPhoneLines, newContext), newContext);
		value += TSConverter.addDateParam("utcAbsentStartTime", obj.utcAbsentStartTime, newContext);
		value += TSConverter.addDateParam("utcAbsentEndTime", obj.utcAbsentEndTime, newContext);
		value += TSConverter.addStringParam("u8sAbsentReason", obj.u8sAbsentReason, newContext);
		value += TSConverter.addStringParam("u8sAbsentMessage", obj.u8sAbsentMessage, newContext);
		value += TSConverter.addStringParam("u8sNote", obj.u8sNote, newContext);
		value += TSConverter.addObjectAsStringParam("appointmentEntryList", ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(obj.appointmentEntryList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnNextAppointment", ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toJSON(obj.asnNextAppointment, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnPresenceState, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPresenceState");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceState"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnPresenceState;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnPresenceState
			if (TSConverter.validateParam(data, "contactID", "string", errors, newContext, false))
				obj.contactID = data.contactID;
			if (TSConverter.validateParam(data, "iPresenceState", "number", errors, newContext, false))
				obj.iPresenceState = data.iPresenceState;
			if (TSConverter.validateParam(data, "iContactFeatureSet", "number", errors, newContext, false))
				obj.iContactFeatureSet = data.iContactFeatureSet;
			const tscv_asnremotecontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.asnRemoteContact, tscv_asnremotecontact, errors, newContext, "asnRemoteContact", false))
				obj.asnRemoteContact = tscv_asnremotecontact;
			if (TSConverter.validateParam(data, "iContactConnectionState", "number", errors, newContext, false))
				obj.iContactConnectionState = data.iContactConnectionState;
			if (TSConverter.validateParam(data, "u8sAdditionalConnectionInfo", "string", errors, newContext, false))
				obj.u8sAdditionalConnectionInfo = data.u8sAdditionalConnectionInfo;
			const tscv_seqphonelines = new ENetUC_Common_AsnContact.AsnContactPhoneLines();
			if (ENetUC_Common_AsnContact_Converter.AsnContactPhoneLines_Converter.fromJSON(data.seqPhoneLines, tscv_seqphonelines, errors, newContext, "seqPhoneLines", true))
				obj.seqPhoneLines = tscv_seqphonelines;
			if (TSConverter.validateParam(data, "utcAbsentStartTime", "string", errors, newContext, true) && data.utcAbsentStartTime)
				obj.utcAbsentStartTime = new Date(data.utcAbsentStartTime);
			if (TSConverter.validateParam(data, "utcAbsentEndTime", "string", errors, newContext, true) && data.utcAbsentEndTime)
				obj.utcAbsentEndTime = new Date(data.utcAbsentEndTime);
			if (TSConverter.validateParam(data, "u8sAbsentReason", "string", errors, newContext, true))
				obj.u8sAbsentReason = data.u8sAbsentReason;
			if (TSConverter.validateParam(data, "u8sAbsentMessage", "string", errors, newContext, true))
				obj.u8sAbsentMessage = data.u8sAbsentMessage;
			if (TSConverter.validateParam(data, "u8sNote", "string", errors, newContext, true))
				obj.u8sNote = data.u8sNote;
			const tscv_appointmententrylist = new ENetUC_Common_Appointments.SEQAppointmentList();
			if (ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(data.appointmentEntryList, tscv_appointmententrylist, errors, newContext, "appointmentEntryList", true))
				obj.appointmentEntryList = tscv_appointmententrylist;
			const tscv_asnnextappointment = ENetUC_Common_Appointments.AsnAppointmentEntry.initEmpty();
			if (ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromJSON(data.asnNextAppointment, tscv_asnnextappointment, errors, newContext, "asnNextAppointment", true))
				obj.asnNextAppointment = tscv_asnnextappointment;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPresenceState"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactAbsentState
export class AsnContactAbsentState_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactAbsentState, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactAbsentState
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactAbsentState", newContext);
		value += TSConverter.addStringParam("contactID", obj.contactID, newContext);
		value += TSConverter.addNumberParam("iPresenceState", obj.iPresenceState, newContext);
		value += TSConverter.addDateParam("utcAbsentStartTime", obj.utcAbsentStartTime, newContext);
		value += TSConverter.addDateParam("utcAbsentEndTime", obj.utcAbsentEndTime, newContext);
		value += TSConverter.addStringParam("u8sAbsentReason", obj.u8sAbsentReason, newContext);
		value += TSConverter.addStringParam("u8sAbsentMessage", obj.u8sAbsentMessage, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactAbsentState, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactAbsentState");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactAbsentState"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactAbsentState;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactAbsentState
			if (TSConverter.validateParam(data, "contactID", "string", errors, newContext, false))
				obj.contactID = data.contactID;
			if (TSConverter.validateParam(data, "iPresenceState", "number", errors, newContext, false))
				obj.iPresenceState = data.iPresenceState;
			if (TSConverter.validateParam(data, "utcAbsentStartTime", "string", errors, newContext, false))
				obj.utcAbsentStartTime = new Date(data.utcAbsentStartTime);
			if (TSConverter.validateParam(data, "utcAbsentEndTime", "string", errors, newContext, false))
				obj.utcAbsentEndTime = new Date(data.utcAbsentEndTime);
			if (TSConverter.validateParam(data, "u8sAbsentReason", "string", errors, newContext, false))
				obj.u8sAbsentReason = data.u8sAbsentReason;
			if (TSConverter.validateParam(data, "u8sAbsentMessage", "string", errors, newContext, false))
				obj.u8sAbsentMessage = data.u8sAbsentMessage;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactAbsentState"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactAppointmentState
export class AsnContactAppointmentState_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactAppointmentState, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactAppointmentState
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactAppointmentState", newContext);
		value += TSConverter.addStringParam("contactID", obj.contactID, newContext);
		value += TSConverter.addNumberParam("iPresenceState", obj.iPresenceState, newContext);
		value += TSConverter.addObjectAsStringParam("appointmentEntryList", ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.toJSON(obj.appointmentEntryList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnNextAppointment", ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.toJSON(obj.asnNextAppointment, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactAppointmentState, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactAppointmentState");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactAppointmentState"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactAppointmentState;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactAppointmentState
			if (TSConverter.validateParam(data, "contactID", "string", errors, newContext, false))
				obj.contactID = data.contactID;
			if (TSConverter.validateParam(data, "iPresenceState", "number", errors, newContext, false))
				obj.iPresenceState = data.iPresenceState;
			const tscv_appointmententrylist = new ENetUC_Common_Appointments.SEQAppointmentList();
			if (ENetUC_Common_Appointments_Converter.SEQAppointmentList_Converter.fromJSON(data.appointmentEntryList, tscv_appointmententrylist, errors, newContext, "appointmentEntryList", false))
				obj.appointmentEntryList = tscv_appointmententrylist;
			const tscv_asnnextappointment = ENetUC_Common_Appointments.AsnAppointmentEntry.initEmpty();
			if (ENetUC_Common_Appointments_Converter.AsnAppointmentEntry_Converter.fromJSON(data.asnNextAppointment, tscv_asnnextappointment, errors, newContext, "asnNextAppointment", false))
				obj.asnNextAppointment = tscv_asnnextappointment;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactAppointmentState"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactCallInformationChangedArgument
export class AsnContactCallInformationChangedArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactCallInformationChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactCallInformationChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactCallInformationChangedArgument", newContext);
		value += TSConverter.addStringParam("contactID", obj.contactID, newContext);
		value += TSConverter.addNumberParam("iPresenceState", obj.iPresenceState, newContext);
		value += TSConverter.addObjectAsStringParam("callinfo", ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.toJSON(obj.callinfo, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactCallInformationChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactCallInformationChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactCallInformationChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactCallInformationChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactCallInformationChangedArgument
			if (TSConverter.validateParam(data, "contactID", "string", errors, newContext, false))
				obj.contactID = data.contactID;
			if (TSConverter.validateParam(data, "iPresenceState", "number", errors, newContext, false))
				obj.iPresenceState = data.iPresenceState;
			const tscv_callinfo = ENetUC_Common_AsnContact.AsnContactCallInformation.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnContactCallInformation_Converter.fromJSON(data.callinfo, tscv_callinfo, errors, newContext, "callinfo", false))
				obj.callinfo = tscv_callinfo;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactCallInformationChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUpdateMyPhoneForwardStateArgument
export class AsnUpdateMyPhoneForwardStateArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderLocalTypeRef] AsnUpdateMyPhoneForwardStateArgument
		value += AsnNotifyLineForwardStateArgument_Converter.toJSON(obj, context)

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUpdateMyPhoneForwardStateArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyPhoneForwardStateArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUpdateMyPhoneForwardStateArgument;
		if (data !== undefined) {
			// [PrintTSDecoderLocalTypeRef] AsnUpdateMyPhoneForwardStateArgument
			AsnNotifyLineForwardStateArgument_Converter.fromJSON(data, obj, errors, newContext, "", optional)
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUpdateMyPhoneForwardStateArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactPhoneForwardStateChangedArgument
export class AsnContactPhoneForwardStateChangedArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderLocalTypeRef] AsnContactPhoneForwardStateChangedArgument
		value += AsnNotifyLineForwardStateArgument_Converter.toJSON(obj, context)

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactPhoneForwardStateChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactPhoneForwardStateChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactPhoneForwardStateChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderLocalTypeRef] AsnContactPhoneForwardStateChangedArgument
			AsnNotifyLineForwardStateArgument_Converter.fromJSON(data, obj, errors, newContext, "", optional)
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactPhoneForwardStateChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientInfoList
export class AsnClientInfoList_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnClientInfoList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnClientInfoList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnClientInfo_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnClientInfoList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientInfoList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientInfoList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnClientInfoList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnClientInfoList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnClientInfo.initEmpty();
				if (AsnClientInfo_Converter.fromJSON(elem, entry, errors, newContext, "AsnClientInfo", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientInfoList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnMyUpdateConnectedClientListArgument
export class AsnMyUpdateConnectedClientListArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnMyUpdateConnectedClientListArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnMyUpdateConnectedClientListArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnMyUpdateConnectedClientListArgument", newContext);
		value += TSConverter.addObjectAsStringParam("listClientInfos", AsnClientInfoList_Converter.toJSON(obj.listClientInfos, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnMyUpdateConnectedClientListArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnMyUpdateConnectedClientListArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnMyUpdateConnectedClientListArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnMyUpdateConnectedClientListArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnMyUpdateConnectedClientListArgument
			const tscv_listclientinfos = new ENetROSEInterface.AsnClientInfoList();
			if (AsnClientInfoList_Converter.fromJSON(data.listClientInfos, tscv_listclientinfos, errors, newContext, "listClientInfos", false))
				obj.listClientInfos = tscv_listclientinfos;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnMyUpdateConnectedClientListArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAbsentStateGetUserArgument
export class AsnAbsentStateGetUserArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAbsentStateGetUserArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAbsentStateGetUserArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAbsentStateGetUserArgument", newContext);
		value += TSConverter.addStringParam("contactId", obj.contactId, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAbsentStateGetUserArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAbsentStateGetUserArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateGetUserArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAbsentStateGetUserArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAbsentStateGetUserArgument
			if (TSConverter.validateParam(data, "contactId", "string", errors, newContext, false))
				obj.contactId = data.contactId;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStateGetUserArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetMetaSettingsArgument
export class AsnGetMetaSettingsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetMetaSettingsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetMetaSettingsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetMetaSettingsArgument", newContext);
		value += TSConverter.addNumberParam("eSendPlainPassword", obj.eSendPlainPassword, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetMetaSettingsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetMetaSettingsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetMetaSettingsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetMetaSettingsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetMetaSettingsArgument
			if (TSConverter.validateParam(data, "eSendPlainPassword", "number", errors, newContext, false))
				obj.eSendPlainPassword = data.eSendPlainPassword;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetMetaSettingsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnMetaDirectorySettings
export class AsnMetaDirectorySettings_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnMetaDirectorySettings, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnMetaDirectorySettings
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnMetaDirectorySettings", newContext);
		value += TSConverter.addNumberParam("iMetaEnabled", obj.iMetaEnabled, newContext);
		value += TSConverter.addNumberParam("iMetaPort", obj.iMetaPort, newContext);
		value += TSConverter.addStringParam("u8sMetaServer", obj.u8sMetaServer, newContext);
		value += TSConverter.addStringParam("u8sBaseDN", obj.u8sBaseDN, newContext);
		value += TSConverter.addNumberParam("iMetaForceSSL", obj.iMetaForceSSL, newContext);
		value += TSConverter.addNumberParam("iMetaUseAccount", obj.iMetaUseAccount, newContext);
		value += TSConverter.addStringParam("u8sMetaUser", obj.u8sMetaUser, newContext);
		value += TSConverter.addNumberParam("ePasswordEncryption", obj.ePasswordEncryption, newContext);
		value += TSConverter.addStringParam("u8sMetaPassword", obj.u8sMetaPassword, newContext);
		value += TSConverter.addNumberParam("iMetaUseInServer", obj.iMetaUseInServer, newContext);
		value += TSConverter.addNumberParam("iMetaUseInClient", obj.iMetaUseInClient, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnMetaDirectorySettings, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnMetaDirectorySettings");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnMetaDirectorySettings"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnMetaDirectorySettings;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnMetaDirectorySettings
			if (TSConverter.validateParam(data, "iMetaEnabled", "number", errors, newContext, false))
				obj.iMetaEnabled = data.iMetaEnabled;
			if (TSConverter.validateParam(data, "iMetaPort", "number", errors, newContext, false))
				obj.iMetaPort = data.iMetaPort;
			if (TSConverter.validateParam(data, "u8sMetaServer", "string", errors, newContext, false))
				obj.u8sMetaServer = data.u8sMetaServer;
			if (TSConverter.validateParam(data, "u8sBaseDN", "string", errors, newContext, false))
				obj.u8sBaseDN = data.u8sBaseDN;
			if (TSConverter.validateParam(data, "iMetaForceSSL", "number", errors, newContext, false))
				obj.iMetaForceSSL = data.iMetaForceSSL;
			if (TSConverter.validateParam(data, "iMetaUseAccount", "number", errors, newContext, false))
				obj.iMetaUseAccount = data.iMetaUseAccount;
			if (TSConverter.validateParam(data, "u8sMetaUser", "string", errors, newContext, false))
				obj.u8sMetaUser = data.u8sMetaUser;
			if (TSConverter.validateParam(data, "ePasswordEncryption", "number", errors, newContext, false))
				obj.ePasswordEncryption = data.ePasswordEncryption;
			if (TSConverter.validateParam(data, "u8sMetaPassword", "string", errors, newContext, false))
				obj.u8sMetaPassword = data.u8sMetaPassword;
			if (TSConverter.validateParam(data, "iMetaUseInServer", "number", errors, newContext, false))
				obj.iMetaUseInServer = data.iMetaUseInServer;
			if (TSConverter.validateParam(data, "iMetaUseInClient", "number", errors, newContext, false))
				obj.iMetaUseInClient = data.iMetaUseInClient;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnMetaDirectorySettings"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetMetaSettingsArgument
export class AsnSetMetaSettingsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetMetaSettingsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetMetaSettingsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetMetaSettingsArgument", newContext);
		value += TSConverter.addNumberParam("eSendPlainPassword", obj.eSendPlainPassword, newContext);
		value += TSConverter.addObjectAsStringParam("metasettings", AsnMetaDirectorySettings_Converter.toJSON(obj.metasettings, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetMetaSettingsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetMetaSettingsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetMetaSettingsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetMetaSettingsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetMetaSettingsArgument
			if (TSConverter.validateParam(data, "eSendPlainPassword", "number", errors, newContext, false))
				obj.eSendPlainPassword = data.eSendPlainPassword;
			const tscv_metasettings = ENetROSEInterface.AsnMetaDirectorySettings.initEmpty();
			if (AsnMetaDirectorySettings_Converter.fromJSON(data.metasettings, tscv_metasettings, errors, newContext, "metasettings", false))
				obj.metasettings = tscv_metasettings;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetMetaSettingsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnEMailAttachmentList
export class AsnEMailAttachmentList_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnEMailAttachmentList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnEMailAttachmentList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnEMailAttachment_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnEMailAttachmentList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnEMailAttachmentList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailAttachmentList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnEMailAttachmentList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnEMailAttachmentList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnEMailAttachment.initEmpty();
				if (AsnEMailAttachment_Converter.fromJSON(elem, entry, errors, newContext, "AsnEMailAttachment", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailAttachmentList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnEMailPartyList
export class AsnEMailPartyList_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnEMailPartyList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnEMailPartyList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnEMailParty_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnEMailPartyList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnEMailPartyList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailPartyList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnEMailPartyList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnEMailPartyList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnEMailParty.initEmpty();
				if (AsnEMailParty_Converter.fromJSON(elem, entry, errors, newContext, "AsnEMailParty", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailPartyList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnEMailBodyList
export class AsnEMailBodyList_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnEMailBodyList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnEMailBodyList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnEMailBody_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnEMailBodyList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnEMailBodyList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailBodyList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnEMailBodyList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnEMailBodyList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnEMailBody.initEmpty();
				if (AsnEMailBody_Converter.fromJSON(elem, entry, errors, newContext, "AsnEMailBody", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailBodyList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnEMail
export class AsnEMail_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnEMail, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnEMail
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnEMail", newContext);
		value += TSConverter.addObjectAsStringParam("originatorParty", AsnEMailParty_Converter.toJSON(obj.originatorParty, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("recipientParties", AsnEMailPartyList_Converter.toJSON(obj.recipientParties, newContext), newContext);
		value += TSConverter.addStringParam("u8sSubject", obj.u8sSubject, newContext);
		value += TSConverter.addObjectAsStringParam("bodyList", AsnEMailBodyList_Converter.toJSON(obj.bodyList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("attachmentList", AsnEMailAttachmentList_Converter.toJSON(obj.attachmentList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("returnParties", AsnEMailPartyList_Converter.toJSON(obj.returnParties, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("carbonCopyParties", AsnEMailPartyList_Converter.toJSON(obj.carbonCopyParties, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("blindCarbonParties", AsnEMailPartyList_Converter.toJSON(obj.blindCarbonParties, newContext), newContext);
		value += TSConverter.addBooleanParam("bSendNotification", obj.bSendNotification, newContext);
		value += TSConverter.addBooleanParam("bReadNotification", obj.bReadNotification, newContext);
		value += TSConverter.addNumberParam("iEmailImportance", obj.iEmailImportance, newContext);
		value += TSConverter.addStringParam("u8sMessageID", obj.u8sMessageID, newContext);
		value += TSConverter.addObjectAsStringParam("u8sMapOptionalHeaderElements", ENetUC_Common_Converter.AsnStringPairList_Converter.toJSON(obj.u8sMapOptionalHeaderElements, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnEMail, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnEMail");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnEMail"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnEMail;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnEMail
			const tscv_originatorparty = ENetROSEInterface.AsnEMailParty.initEmpty();
			if (AsnEMailParty_Converter.fromJSON(data.originatorParty, tscv_originatorparty, errors, newContext, "originatorParty", false))
				obj.originatorParty = tscv_originatorparty;
			const tscv_recipientparties = new ENetROSEInterface.AsnEMailPartyList();
			if (AsnEMailPartyList_Converter.fromJSON(data.recipientParties, tscv_recipientparties, errors, newContext, "recipientParties", false))
				obj.recipientParties = tscv_recipientparties;
			if (TSConverter.validateParam(data, "u8sSubject", "string", errors, newContext, false))
				obj.u8sSubject = data.u8sSubject;
			const tscv_bodylist = new ENetROSEInterface.AsnEMailBodyList();
			if (AsnEMailBodyList_Converter.fromJSON(data.bodyList, tscv_bodylist, errors, newContext, "bodyList", false))
				obj.bodyList = tscv_bodylist;
			const tscv_attachmentlist = new ENetROSEInterface.AsnEMailAttachmentList();
			if (AsnEMailAttachmentList_Converter.fromJSON(data.attachmentList, tscv_attachmentlist, errors, newContext, "attachmentList", true))
				obj.attachmentList = tscv_attachmentlist;
			const tscv_returnparties = new ENetROSEInterface.AsnEMailPartyList();
			if (AsnEMailPartyList_Converter.fromJSON(data.returnParties, tscv_returnparties, errors, newContext, "returnParties", true))
				obj.returnParties = tscv_returnparties;
			const tscv_carboncopyparties = new ENetROSEInterface.AsnEMailPartyList();
			if (AsnEMailPartyList_Converter.fromJSON(data.carbonCopyParties, tscv_carboncopyparties, errors, newContext, "carbonCopyParties", true))
				obj.carbonCopyParties = tscv_carboncopyparties;
			const tscv_blindcarbonparties = new ENetROSEInterface.AsnEMailPartyList();
			if (AsnEMailPartyList_Converter.fromJSON(data.blindCarbonParties, tscv_blindcarbonparties, errors, newContext, "blindCarbonParties", true))
				obj.blindCarbonParties = tscv_blindcarbonparties;
			if (TSConverter.validateParam(data, "bSendNotification", "boolean", errors, newContext, true))
				obj.bSendNotification = data.bSendNotification;
			if (TSConverter.validateParam(data, "bReadNotification", "boolean", errors, newContext, true))
				obj.bReadNotification = data.bReadNotification;
			if (TSConverter.validateParam(data, "iEmailImportance", "number", errors, newContext, true))
				obj.iEmailImportance = data.iEmailImportance;
			if (TSConverter.validateParam(data, "u8sMessageID", "string", errors, newContext, true))
				obj.u8sMessageID = data.u8sMessageID;
			const tscv_u8smapoptionalheaderelements = new ENetUC_Common.AsnStringPairList();
			if (ENetUC_Common_Converter.AsnStringPairList_Converter.fromJSON(data.u8sMapOptionalHeaderElements, tscv_u8smapoptionalheaderelements, errors, newContext, "u8sMapOptionalHeaderElements", true))
				obj.u8sMapOptionalHeaderElements = tscv_u8smapoptionalheaderelements;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnEMail"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnEMailList
export class AsnEMailList_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnEMailList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnEMailList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnEMail_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnEMailList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnEMailList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnEMailList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnEMailList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnEMail.initEmpty();
				if (AsnEMail_Converter.fromJSON(elem, entry, errors, newContext, "AsnEMail", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnEMailList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSendClientSideMailArgument
export class AsnSendClientSideMailArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSendClientSideMailArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSendClientSideMailArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSendClientSideMailArgument", newContext);
		value += TSConverter.addObjectAsStringParam("mails", AsnEMailList_Converter.toJSON(obj.mails, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSendClientSideMailArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSendClientSideMailArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSendClientSideMailArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSendClientSideMailArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSendClientSideMailArgument
			const tscv_mails = new ENetROSEInterface.AsnEMailList();
			if (AsnEMailList_Converter.fromJSON(data.mails, tscv_mails, errors, newContext, "mails", false))
				obj.mails = tscv_mails;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSendClientSideMailArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDBPhoneBookList
export class AsnDBPhoneBookList_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnDBPhoneBookList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnDBPhoneBookList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnDBPhoneBook_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnDBPhoneBookList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDBPhoneBookList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDBPhoneBookList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnDBPhoneBookList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnDBPhoneBookList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnDBPhoneBook.initEmpty();
				if (AsnDBPhoneBook_Converter.fromJSON(elem, entry, errors, newContext, "AsnDBPhoneBook", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDBPhoneBookList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnFindDBContactsArgument
export class AsnFindDBContactsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnFindDBContactsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderChoiceDefCode] AsnFindDBContactsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnFindDBContactsArgument", newContext);
		if (obj.findoptions != null)
			value += TSConverter.addObjectAsStringParam("findoptions", AsnDatabaseFindOption_Converter.toJSON(obj.findoptions, newContext), newContext);
		else if (obj.entryid != null)
			value += TSConverter.addObjectAsStringParam("entryid", AsnDatabaseEntryID_Converter.toJSON(obj.entryid, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnFindDBContactsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnFindDBContactsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnFindDBContactsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnFindDBContactsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderChoiceDefCode] AsnFindDBContactsArgument
			newContext.bLaxParsing = false;
			const tscv_findoptions = ENetROSEInterface.AsnDatabaseFindOption.initEmpty();
			if (AsnDatabaseFindOption_Converter.fromJSON(data.findoptions, tscv_findoptions, undefined, newContext, "findoptions", optional))
				obj.findoptions = tscv_findoptions;
			else {
				const tscv_entryid = ENetROSEInterface.AsnDatabaseEntryID.initEmpty();
				if (AsnDatabaseEntryID_Converter.fromJSON(data.entryid, tscv_entryid, undefined, newContext, "entryid", optional))
					obj.entryid = tscv_entryid;
				else if (!(optional === true))
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnFindDBContactsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnFindDBContactsResult
export class AsnFindDBContactsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnFindDBContactsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnFindDBContactsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnFindDBContactsResult", newContext);
		value += TSConverter.addObjectAsStringParam("contactList", ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(obj.contactList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("phonebookList", AsnDBPhoneBookList_Converter.toJSON(obj.phonebookList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnFindDBContactsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnFindDBContactsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnFindDBContactsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnFindDBContactsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnFindDBContactsResult
			const tscv_contactlist = new ENetUC_Common.AsnNetDatabaseContactList();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(data.contactList, tscv_contactlist, errors, newContext, "contactList", false))
				obj.contactList = tscv_contactlist;
			const tscv_phonebooklist = new ENetROSEInterface.AsnDBPhoneBookList();
			if (AsnDBPhoneBookList_Converter.fromJSON(data.phonebookList, tscv_phonebooklist, errors, newContext, "phonebookList", false))
				obj.phonebookList = tscv_phonebooklist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnFindDBContactsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnFindContactsArgument
export class AsnFindContactsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnFindContactsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnFindContactsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnFindContactsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("findOptions", AsnNetDatabaseFindOptions_Converter.toJSON(obj.findOptions, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnFindContactsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnFindContactsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnFindContactsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnFindContactsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnFindContactsArgument
			const tscv_findoptions = ENetROSEInterface.AsnNetDatabaseFindOptions.initEmpty();
			if (AsnNetDatabaseFindOptions_Converter.fromJSON(data.findOptions, tscv_findoptions, errors, newContext, "findOptions", false))
				obj.findOptions = tscv_findoptions;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnFindContactsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnFindContactsResult
export class AsnFindContactsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnFindContactsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnFindContactsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnFindContactsResult", newContext);
		value += TSConverter.addObjectAsStringParam("contactList", ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(obj.contactList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("phonebookList", AsnDBPhoneBookList_Converter.toJSON(obj.phonebookList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnFindContactsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnFindContactsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnFindContactsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnFindContactsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnFindContactsResult
			const tscv_contactlist = new ENetUC_Common.AsnNetDatabaseContactList();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(data.contactList, tscv_contactlist, errors, newContext, "contactList", false))
				obj.contactList = tscv_contactlist;
			const tscv_phonebooklist = new ENetROSEInterface.AsnDBPhoneBookList();
			if (AsnDBPhoneBookList_Converter.fromJSON(data.phonebookList, tscv_phonebooklist, errors, newContext, "phonebookList", false))
				obj.phonebookList = tscv_phonebooklist;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnFindContactsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetPhoneBooksResult
export class AsnGetPhoneBooksResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetPhoneBooksResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetPhoneBooksResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetPhoneBooksResult", newContext);
		value += TSConverter.addObjectAsStringParam("phonebooks", AsnDBPhoneBookList_Converter.toJSON(obj.phonebooks, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetPhoneBooksResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetPhoneBooksResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetPhoneBooksResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetPhoneBooksResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetPhoneBooksResult
			const tscv_phonebooks = new ENetROSEInterface.AsnDBPhoneBookList();
			if (AsnDBPhoneBookList_Converter.fromJSON(data.phonebooks, tscv_phonebooks, errors, newContext, "phonebooks", false))
				obj.phonebooks = tscv_phonebooks;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetPhoneBooksResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetServerBasedClientSettingsResult
export class AsnGetServerBasedClientSettingsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetServerBasedClientSettingsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetServerBasedClientSettingsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetServerBasedClientSettingsResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addObjectAsStringParam("clientSettings", AsnServerBasedClientSettings_Converter.toJSON(obj.clientSettings, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetServerBasedClientSettingsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetServerBasedClientSettingsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetServerBasedClientSettingsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetServerBasedClientSettingsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetServerBasedClientSettingsResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			const tscv_clientsettings = ENetROSEInterface.AsnServerBasedClientSettings.initEmpty();
			if (AsnServerBasedClientSettings_Converter.fromJSON(data.clientSettings, tscv_clientsettings, errors, newContext, "clientSettings", false))
				obj.clientSettings = tscv_clientsettings;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetServerBasedClientSettingsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetServerBasedClientSettingsArgument
export class AsnSetServerBasedClientSettingsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetServerBasedClientSettingsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetServerBasedClientSettingsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetServerBasedClientSettingsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("clientSettings", AsnServerBasedClientSettings_Converter.toJSON(obj.clientSettings, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetServerBasedClientSettingsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetServerBasedClientSettingsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetServerBasedClientSettingsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetServerBasedClientSettingsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetServerBasedClientSettingsArgument
			const tscv_clientsettings = ENetROSEInterface.AsnServerBasedClientSettings.initEmpty();
			if (AsnServerBasedClientSettings_Converter.fromJSON(data.clientSettings, tscv_clientsettings, errors, newContext, "clientSettings", false))
				obj.clientSettings = tscv_clientsettings;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetServerBasedClientSettingsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactStatusParameter
export class AsnContactStatusParameter_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactStatusParameter, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactStatusParameter
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactStatusParameter", newContext);
		value += TSConverter.addNumberParam("typeOfValue", obj.typeOfValue, newContext);
		value += TSConverter.addStringParam("u8sValue", obj.u8sValue, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactStatusParameter, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactStatusParameter");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusParameter"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactStatusParameter;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactStatusParameter
			if (TSConverter.validateParam(data, "typeOfValue", "number", errors, newContext, false))
				obj.typeOfValue = data.typeOfValue;
			if (TSConverter.validateParam(data, "u8sValue", "string", errors, newContext, false))
				obj.u8sValue = data.u8sValue;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusParameter"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] SEQContactStatusParameter
export class SEQContactStatusParameter_Converter {
	public static toJSON(obj?: ENetROSEInterface.SEQContactStatusParameter, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] SEQContactStatusParameter
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnContactStatusParameter_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.SEQContactStatusParameter, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "SEQContactStatusParameter");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing SEQContactStatusParameter"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.SEQContactStatusParameter;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] SEQContactStatusParameter
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnContactStatusParameter.initEmpty();
				if (AsnContactStatusParameter_Converter.fromJSON(elem, entry, errors, newContext, "AsnContactStatusParameter", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing SEQContactStatusParameter"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactStatusRequestEntry
export class AsnContactStatusRequestEntry_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactStatusRequestEntry, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactStatusRequestEntry
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactStatusRequestEntry", newContext);
		value += TSConverter.addNumberParam("iClientSearchID", obj.iClientSearchID, newContext);
		value += TSConverter.addNumberParam("iClientRowID", obj.iClientRowID, newContext);
		value += TSConverter.addObjectAsStringParam("listSearchParameter", SEQContactStatusParameter_Converter.toJSON(obj.listSearchParameter, newContext), newContext);
		value += TSConverter.addBooleanParam("bActivateNotify", obj.bActivateNotify, newContext);
		value += TSConverter.addObjectAsStringParam("listDeactivateNotify", ENetUC_Common_Converter.SEQInteger_Converter.toJSON(obj.listDeactivateNotify, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactStatusRequestEntry, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactStatusRequestEntry");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusRequestEntry"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactStatusRequestEntry;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactStatusRequestEntry
			if (TSConverter.validateParam(data, "iClientSearchID", "number", errors, newContext, false))
				obj.iClientSearchID = data.iClientSearchID;
			if (TSConverter.validateParam(data, "iClientRowID", "number", errors, newContext, false))
				obj.iClientRowID = data.iClientRowID;
			const tscv_listsearchparameter = new ENetROSEInterface.SEQContactStatusParameter();
			if (SEQContactStatusParameter_Converter.fromJSON(data.listSearchParameter, tscv_listsearchparameter, errors, newContext, "listSearchParameter", false))
				obj.listSearchParameter = tscv_listsearchparameter;
			if (TSConverter.validateParam(data, "bActivateNotify", "boolean", errors, newContext, false))
				obj.bActivateNotify = data.bActivateNotify;
			const tscv_listdeactivatenotify = new ENetUC_Common.SEQInteger();
			if (ENetUC_Common_Converter.SEQInteger_Converter.fromJSON(data.listDeactivateNotify, tscv_listdeactivatenotify, errors, newContext, "listDeactivateNotify", false))
				obj.listDeactivateNotify = tscv_listdeactivatenotify;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusRequestEntry"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] SEQContactStatusRequest
export class SEQContactStatusRequest_Converter {
	public static toJSON(obj?: ENetROSEInterface.SEQContactStatusRequest, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] SEQContactStatusRequest
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnContactStatusRequestEntry_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.SEQContactStatusRequest, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "SEQContactStatusRequest");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing SEQContactStatusRequest"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.SEQContactStatusRequest;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] SEQContactStatusRequest
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnContactStatusRequestEntry.initEmpty();
				if (AsnContactStatusRequestEntry_Converter.fromJSON(elem, entry, errors, newContext, "AsnContactStatusRequestEntry", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing SEQContactStatusRequest"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] SEQContactStatusResult
export class SEQContactStatusResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.SEQContactStatusResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] SEQContactStatusResult
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnContactStatusResultEntry_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.SEQContactStatusResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "SEQContactStatusResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing SEQContactStatusResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.SEQContactStatusResult;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] SEQContactStatusResult
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnContactStatusResultEntry.initEmpty();
				if (AsnContactStatusResultEntry_Converter.fromJSON(elem, entry, errors, newContext, "AsnContactStatusResultEntry", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing SEQContactStatusResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactStatusRequestArgument
export class AsnContactStatusRequestArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactStatusRequestArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactStatusRequestArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactStatusRequestArgument", newContext);
		value += TSConverter.addObjectAsStringParam("request", SEQContactStatusRequest_Converter.toJSON(obj.request, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactStatusRequestArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactStatusRequestArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusRequestArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactStatusRequestArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactStatusRequestArgument
			const tscv_request = new ENetROSEInterface.SEQContactStatusRequest();
			if (SEQContactStatusRequest_Converter.fromJSON(data.request, tscv_request, errors, newContext, "request", false))
				obj.request = tscv_request;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusRequestArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnContactStatusRequestResult
export class AsnContactStatusRequestResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnContactStatusRequestResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnContactStatusRequestResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnContactStatusRequestResult", newContext);
		value += TSConverter.addNumberParam("iResult", obj.iResult, newContext);
		value += TSConverter.addNumberParam("iCount", obj.iCount, newContext);
		value += TSConverter.addObjectAsStringParam("listStatusResult", SEQContactStatusResult_Converter.toJSON(obj.listStatusResult, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnContactStatusRequestResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnContactStatusRequestResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusRequestResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnContactStatusRequestResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnContactStatusRequestResult
			if (TSConverter.validateParam(data, "iResult", "number", errors, newContext, false))
				obj.iResult = data.iResult;
			if (TSConverter.validateParam(data, "iCount", "number", errors, newContext, false))
				obj.iCount = data.iCount;
			const tscv_liststatusresult = new ENetROSEInterface.SEQContactStatusResult();
			if (SEQContactStatusResult_Converter.fromJSON(data.listStatusResult, tscv_liststatusresult, errors, newContext, "listStatusResult", false))
				obj.listStatusResult = tscv_liststatusresult;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnContactStatusRequestResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnPublicUserSetContactDataArgument
export class AsnPublicUserSetContactDataArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnPublicUserSetContactDataArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnPublicUserSetContactDataArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnPublicUserSetContactDataArgument", newContext);
		value += TSConverter.addObjectAsStringParam("contactData", AsnPublicUserContactData_Converter.toJSON(obj.contactData, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnPublicUserSetContactDataArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnPublicUserSetContactDataArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnPublicUserSetContactDataArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnPublicUserSetContactDataArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnPublicUserSetContactDataArgument
			const tscv_contactdata = ENetROSEInterface.AsnPublicUserContactData.initEmpty();
			if (AsnPublicUserContactData_Converter.fromJSON(data.contactData, tscv_contactdata, errors, newContext, "contactData", false))
				obj.contactData = tscv_contactdata;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnPublicUserSetContactDataArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSMSMessages
export class AsnSMSMessages_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSMSMessages, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnSMSMessages
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnSMSMessage_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSMSMessages, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSMSMessages");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSMSMessages"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSMSMessages;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnSMSMessages
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnSMSMessage.initEmpty();
				if (AsnSMSMessage_Converter.fromJSON(elem, entry, errors, newContext, "AsnSMSMessage", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSMSMessages"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSendSMSArgument
export class AsnSendSMSArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSendSMSArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSendSMSArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSendSMSArgument", newContext);
		value += TSConverter.addObjectAsStringParam("messages", AsnSMSMessages_Converter.toJSON(obj.messages, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSendSMSArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSendSMSArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSendSMSArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSendSMSArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSendSMSArgument
			const tscv_messages = new ENetROSEInterface.AsnSMSMessages();
			if (AsnSMSMessages_Converter.fromJSON(data.messages, tscv_messages, errors, newContext, "messages", false))
				obj.messages = tscv_messages;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSendSMSArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSMSStatusNotifyArgument
export class AsnSMSStatusNotifyArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSMSStatusNotifyArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSMSStatusNotifyArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSMSStatusNotifyArgument", newContext);
		value += TSConverter.addStringParam("u8sMonitorCrossRefID", obj.u8sMonitorCrossRefID, newContext);
		value += TSConverter.addNumberParam("status", obj.status, newContext);
		value += TSConverter.addStringParam("u8sErrReason", obj.u8sErrReason, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSMSStatusNotifyArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSMSStatusNotifyArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSMSStatusNotifyArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSMSStatusNotifyArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSMSStatusNotifyArgument
			if (TSConverter.validateParam(data, "u8sMonitorCrossRefID", "string", errors, newContext, false))
				obj.u8sMonitorCrossRefID = data.u8sMonitorCrossRefID;
			if (TSConverter.validateParam(data, "status", "number", errors, newContext, false))
				obj.status = data.status;
			if (TSConverter.validateParam(data, "u8sErrReason", "string", errors, newContext, false))
				obj.u8sErrReason = data.u8sErrReason;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSMSStatusNotifyArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUserDefinedCustomFields
export class AsnUserDefinedCustomFields_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnUserDefinedCustomFields, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnUserDefinedCustomFields
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnUserDefinedCustomField_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnUserDefinedCustomFields, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUserDefinedCustomFields");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUserDefinedCustomFields"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnUserDefinedCustomFields;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnUserDefinedCustomFields
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetROSEInterface.AsnUserDefinedCustomField.initEmpty();
				if (AsnUserDefinedCustomField_Converter.fromJSON(elem, entry, errors, newContext, "AsnUserDefinedCustomField", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUserDefinedCustomFields"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetUserdefinedCustomFieldsResult
export class AsnGetUserdefinedCustomFieldsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetUserdefinedCustomFieldsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetUserdefinedCustomFieldsResult", newContext);
		value += TSConverter.addObjectAsStringParam("customfields", AsnUserDefinedCustomFields_Converter.toJSON(obj.customfields, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetUserdefinedCustomFieldsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetUserdefinedCustomFieldsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetUserdefinedCustomFieldsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetUserdefinedCustomFieldsResult
			const tscv_customfields = new ENetROSEInterface.AsnUserDefinedCustomFields();
			if (AsnUserDefinedCustomFields_Converter.fromJSON(data.customfields, tscv_customfields, errors, newContext, "customfields", false))
				obj.customfields = tscv_customfields;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetUserdefinedCustomFieldsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnOpenContactsArgument
export class AsnOpenContactsArgument_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnOpenContactsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnOpenContactsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnOpenContactsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("seqContactIDs", AsnContactIDs_Converter.toJSON(obj.seqContactIDs, newContext), newContext);
		value += TSConverter.addBooleanParam("bOpenOnlyInternalContacts", obj.bOpenOnlyInternalContacts, newContext);
		value += TSConverter.addBooleanParam("bDontRequestPermissions", obj.bDontRequestPermissions, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnOpenContactsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnOpenContactsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnOpenContactsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnOpenContactsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnOpenContactsArgument
			const tscv_seqcontactids = new ENetROSEInterface.AsnContactIDs();
			if (AsnContactIDs_Converter.fromJSON(data.seqContactIDs, tscv_seqcontactids, errors, newContext, "seqContactIDs", false))
				obj.seqContactIDs = tscv_seqcontactids;
			if (TSConverter.validateParam(data, "bOpenOnlyInternalContacts", "boolean", errors, newContext, true))
				obj.bOpenOnlyInternalContacts = data.bOpenOnlyInternalContacts;
			if (TSConverter.validateParam(data, "bDontRequestPermissions", "boolean", errors, newContext, true))
				obj.bDontRequestPermissions = data.bDontRequestPermissions;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnOpenContactsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetMetaSettingsResult
export class AsnGetMetaSettingsResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnGetMetaSettingsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetMetaSettingsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetMetaSettingsResult", newContext);
		value += TSConverter.addObjectAsStringParam("metasettings", AsnMetaDirectorySettings_Converter.toJSON(obj.metasettings, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnGetMetaSettingsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetMetaSettingsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetMetaSettingsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnGetMetaSettingsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetMetaSettingsResult
			const tscv_metasettings = ENetROSEInterface.AsnMetaDirectorySettings.initEmpty();
			if (AsnMetaDirectorySettings_Converter.fromJSON(data.metasettings, tscv_metasettings, errors, newContext, "metasettings", false))
				obj.metasettings = tscv_metasettings;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetMetaSettingsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAbsentStates
export class AsnAbsentStates_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnAbsentStates, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnAbsentStates
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnAbsentStates, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAbsentStates");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStates"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnAbsentStates;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnAbsentStates
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common_AsnContact.AsnAbsentState.initEmpty();
				if (ENetUC_Common_AsnContact_Converter.AsnAbsentState_Converter.fromJSON(elem, entry, errors, newContext, "AsnAbsentState", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAbsentStates"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetPreferredContactRefResult
export class AsnSetPreferredContactRefResult_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnSetPreferredContactRefResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetPreferredContactRefResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetPreferredContactRefResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnSetPreferredContactRefResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetPreferredContactRefResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetPreferredContactRefResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnSetPreferredContactRefResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetPreferredContactRefResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetPreferredContactRefResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRedisRemoteInvoke
export class AsnRedisRemoteInvoke_Converter {
	public static toJSON(obj?: ENetROSEInterface.AsnRedisRemoteInvoke, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRedisRemoteInvoke
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRedisRemoteInvoke", newContext);
		value += TSConverter.addStringParam("u8sInvokeID", obj.u8sInvokeID, newContext);
		value += TSConverter.addStringParam("u8sInvoker", obj.u8sInvoker, newContext);
		value += TSConverter.addStringParam("u8sInvokingServer", obj.u8sInvokingServer, newContext);
		value += TSConverter.addStringParam("u8sInvokingConnection", obj.u8sInvokingConnection, newContext);
		value += TSConverter.addStringParam("u8sOp", obj.u8sOp, newContext);
		value += TSConverter.addStringParam("u8sArg", obj.u8sArg, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetROSEInterface.AsnRedisRemoteInvoke, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRedisRemoteInvoke");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRedisRemoteInvoke"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetROSEInterface.AsnRedisRemoteInvoke;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRedisRemoteInvoke
			if (TSConverter.validateParam(data, "u8sInvokeID", "string", errors, newContext, false))
				obj.u8sInvokeID = data.u8sInvokeID;
			if (TSConverter.validateParam(data, "u8sInvoker", "string", errors, newContext, false))
				obj.u8sInvoker = data.u8sInvoker;
			if (TSConverter.validateParam(data, "u8sInvokingServer", "string", errors, newContext, false))
				obj.u8sInvokingServer = data.u8sInvokingServer;
			if (TSConverter.validateParam(data, "u8sInvokingConnection", "string", errors, newContext, false))
				obj.u8sInvokingConnection = data.u8sInvokingConnection;
			if (TSConverter.validateParam(data, "u8sOp", "string", errors, newContext, false))
				obj.u8sOp = data.u8sOp;
			if (TSConverter.validateParam(data, "u8sArg", "string", errors, newContext, false))
				obj.u8sArg = data.u8sArg;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRedisRemoteInvoke"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
