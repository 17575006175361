// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_Common_Converter.ts
 * "UC-Server-Access-Protocol-Common" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_Common from "./ENetUC_Common";
import { EAsnOptionalParameters_Converter } from "./TSOptionalParamConverter";

// [PrintTSEncoderDecoderCode] AsnOptionalParamChoice
export class AsnOptionalParamChoice_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnOptionalParamChoice, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderChoiceDefCode] AsnOptionalParamChoice
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnOptionalParamChoice", newContext);
		if (obj.stringdata != null)
			value += TSConverter.addStringParam("stringdata", obj.stringdata, newContext);
		else if (obj.binarydata != null)
			value += TSConverter.addBinaryParam("binarydata", obj.binarydata, newContext);
		else if (obj.integerdata != null)
			value += TSConverter.addNumberParam("integerdata", obj.integerdata, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnOptionalParamChoice, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnOptionalParamChoice");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnOptionalParamChoice"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnOptionalParamChoice;
		if (data !== undefined) {
			// [PrintTSDecoderChoiceDefCode] AsnOptionalParamChoice
			newContext.bLaxParsing = false;
			if (TSConverter.validateChoiceParam(data, "stringdata", "string", undefined, newContext))
				obj.stringdata = data.stringdata;
			else if (TSConverter.validateChoiceParam(data, "binarydata", "string", undefined, newContext))
				obj.binarydata = TSConverter.decode64(data.binarydata as unknown as string);
			else if (TSConverter.validateChoiceParam(data, "integerdata", "number", undefined, newContext))
				obj.integerdata = data.integerdata;
			else if (!(optional === true))
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnOptionalParamChoice"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnStringPair
export class AsnStringPair_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnStringPair, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnStringPair
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnStringPair", newContext);
		value += TSConverter.addStringParam("key", obj.key, newContext);
		value += TSConverter.addStringParam("value", obj.value, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnStringPair, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStringPair");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStringPair"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnStringPair;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnStringPair
			if (TSConverter.validateParam(data, "key", "string", errors, newContext, false))
				obj.key = data.key;
			if (TSConverter.validateParam(data, "value", "string", errors, newContext, false))
				obj.value = data.value;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStringPair"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnIntegerPair
export class AsnIntegerPair_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnIntegerPair, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnIntegerPair
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnIntegerPair", newContext);
		value += TSConverter.addNumberParam("key", obj.key, newContext);
		value += TSConverter.addNumberParam("value", obj.value, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnIntegerPair, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnIntegerPair");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnIntegerPair"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnIntegerPair;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnIntegerPair
			if (TSConverter.validateParam(data, "key", "number", errors, newContext, false))
				obj.key = data.key;
			if (TSConverter.validateParam(data, "value", "number", errors, newContext, false))
				obj.value = data.value;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnIntegerPair"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnStringIntegerPair
export class AsnStringIntegerPair_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnStringIntegerPair, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnStringIntegerPair
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnStringIntegerPair", newContext);
		value += TSConverter.addStringParam("u8sStr", obj.u8sStr, newContext);
		value += TSConverter.addNumberParam("iInt", obj.iInt, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnStringIntegerPair, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStringIntegerPair");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStringIntegerPair"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnStringIntegerPair;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnStringIntegerPair
			if (TSConverter.validateParam(data, "u8sStr", "string", errors, newContext, false))
				obj.u8sStr = data.u8sStr;
			if (TSConverter.validateParam(data, "iInt", "number", errors, newContext, false))
				obj.iInt = data.iInt;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStringIntegerPair"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnOptionalParam
export class AsnOptionalParam_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnOptionalParam, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		if (context?.bUCServerOptionalParams) {
			debugger;
			// It is not possible to encode a single AsnOptionalParam into the ucserver notation. Needs the AsnOptionalParameters envelop!
		}

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnOptionalParam
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnOptionalParam", newContext);
		value += TSConverter.addStringParam("key", obj.key, newContext);
		value += TSConverter.addObjectAsStringParam("value", AsnOptionalParamChoice_Converter.toJSON(obj.value, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnOptionalParam, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnOptionalParam");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnOptionalParam"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnOptionalParam;
		if (data !== undefined) {
			if (data.key === undefined) {
				debugger;
				// It is not possible to decode a single AsnOptionalParam from the ucserver notation. Needs the AsnOptionalParameters envelop!
			} else {
				// [PrintTSDecoderSeqDefCode] AsnOptionalParam
				if (TSConverter.validateParam(data, "key", "string", errors, newContext, false))
					obj.key = data.key;
				const tscv_value = ENetUC_Common.AsnOptionalParamChoice.initEmpty();
				if (AsnOptionalParamChoice_Converter.fromJSON(data.value, tscv_value, errors, newContext, "value", false))
					obj.value = tscv_value;
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnOptionalParam"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnStringPairList
export class AsnStringPairList_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnStringPairList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnStringPairList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnStringPair_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnStringPairList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStringPairList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStringPairList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnStringPairList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnStringPairList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common.AsnStringPair.initEmpty();
				if (AsnStringPair_Converter.fromJSON(elem, entry, errors, newContext, "AsnStringPair", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStringPairList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJSONWebTokenHeader
export class AsnJSONWebTokenHeader_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnJSONWebTokenHeader, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJSONWebTokenHeader
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJSONWebTokenHeader", newContext);
		value += TSConverter.addStringParam("u8sTYP", obj.u8sTYP, newContext);
		value += TSConverter.addStringParam("u8sALG", obj.u8sALG, newContext);
		value += TSConverter.addStringParam("u8sCTY", obj.u8sCTY, newContext);
		value += TSConverter.addObjectAsStringParam("optionals", AsnStringPairList_Converter.toJSON(obj.optionals, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnJSONWebTokenHeader, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJSONWebTokenHeader");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJSONWebTokenHeader"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnJSONWebTokenHeader;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJSONWebTokenHeader
			if (TSConverter.validateParam(data, "u8sTYP", "string", errors, newContext, false))
				obj.u8sTYP = data.u8sTYP;
			if (TSConverter.validateParam(data, "u8sALG", "string", errors, newContext, false))
				obj.u8sALG = data.u8sALG;
			if (TSConverter.validateParam(data, "u8sCTY", "string", errors, newContext, true))
				obj.u8sCTY = data.u8sCTY;
			const tscv_optionals = new ENetUC_Common.AsnStringPairList();
			if (AsnStringPairList_Converter.fromJSON(data.optionals, tscv_optionals, errors, newContext, "optionals", true))
				obj.optionals = tscv_optionals;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJSONWebTokenHeader"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJSONWebTokenPayLoad
export class AsnJSONWebTokenPayLoad_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnJSONWebTokenPayLoad, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJSONWebTokenPayLoad
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJSONWebTokenPayLoad", newContext);
		value += TSConverter.addStringParam("u8sISS", obj.u8sISS, newContext);
		value += TSConverter.addStringParam("u8sSUB", obj.u8sSUB, newContext);
		value += TSConverter.addStringParam("u8sAUD", obj.u8sAUD, newContext);
		value += TSConverter.addDateParam("utcEXP", obj.utcEXP, newContext);
		value += TSConverter.addDateParam("utcNBF", obj.utcNBF, newContext);
		value += TSConverter.addDateParam("utcIAT", obj.utcIAT, newContext);
		value += TSConverter.addStringParam("u8sJTI", obj.u8sJTI, newContext);
		value += TSConverter.addNumberParam("iUserType", obj.iUserType, newContext);
		value += TSConverter.addObjectAsStringParam("optionals", AsnStringPairList_Converter.toJSON(obj.optionals, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnJSONWebTokenPayLoad, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJSONWebTokenPayLoad");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJSONWebTokenPayLoad"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnJSONWebTokenPayLoad;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJSONWebTokenPayLoad
			if (TSConverter.validateParam(data, "u8sISS", "string", errors, newContext, true))
				obj.u8sISS = data.u8sISS;
			if (TSConverter.validateParam(data, "u8sSUB", "string", errors, newContext, true))
				obj.u8sSUB = data.u8sSUB;
			if (TSConverter.validateParam(data, "u8sAUD", "string", errors, newContext, true))
				obj.u8sAUD = data.u8sAUD;
			if (TSConverter.validateParam(data, "utcEXP", "string", errors, newContext, true) && data.utcEXP)
				obj.utcEXP = new Date(data.utcEXP);
			if (TSConverter.validateParam(data, "utcNBF", "string", errors, newContext, true) && data.utcNBF)
				obj.utcNBF = new Date(data.utcNBF);
			if (TSConverter.validateParam(data, "utcIAT", "string", errors, newContext, true) && data.utcIAT)
				obj.utcIAT = new Date(data.utcIAT);
			if (TSConverter.validateParam(data, "u8sJTI", "string", errors, newContext, false))
				obj.u8sJTI = data.u8sJTI;
			if (TSConverter.validateParam(data, "iUserType", "number", errors, newContext, true))
				obj.iUserType = data.iUserType;
			const tscv_optionals = new ENetUC_Common.AsnStringPairList();
			if (AsnStringPairList_Converter.fromJSON(data.optionals, tscv_optionals, errors, newContext, "optionals", true))
				obj.optionals = tscv_optionals;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJSONWebTokenPayLoad"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnOptionalParameters
export class AsnOptionalParameters_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnOptionalParameters, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		if (context?.bUCServerOptionalParams)
			return EAsnOptionalParameters_Converter.toJSON(obj, context);

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnOptionalParameters
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnOptionalParam_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnOptionalParameters, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnOptionalParameters");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnOptionalParameters"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnOptionalParameters;
		if (data !== undefined) {
			if (data.length === undefined) {
				// Proprietary UCServer AsnOptionalParameters decoding
				EAsnOptionalParameters_Converter.fromJSON(data, obj, errors, context, parametername, optional);
			} else {

				// [PrintTSDecoderSetOfDefCode] AsnOptionalParameters
				for (const id in data) {
					const elem = data[id];
					if (elem === undefined)
						continue;
					const entry = ENetUC_Common.AsnOptionalParam.initEmpty();
					if (AsnOptionalParam_Converter.fromJSON(elem, entry, errors, newContext, "AsnOptionalParam", optional))
						obj.push(entry);
				}
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnOptionalParameters"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestError
export class AsnRequestError_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnRequestError, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestError
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestError", newContext);
		value += TSConverter.addNumberParam("iErrorDetail", obj.iErrorDetail, newContext);
		value += TSConverter.addStringParam("u8sErrorString", obj.u8sErrorString, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnRequestError, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestError");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestError"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnRequestError;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestError
			if (TSConverter.validateParam(data, "iErrorDetail", "number", errors, newContext, false))
				obj.iErrorDetail = data.iErrorDetail;
			if (TSConverter.validateParam(data, "u8sErrorString", "string", errors, newContext, false))
				obj.u8sErrorString = data.u8sErrorString;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestError"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNetDatabaseContact
export class AsnNetDatabaseContact_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnNetDatabaseContact, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNetDatabaseContact
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNetDatabaseContact", newContext);
		value += TSConverter.addStringParam("u8sFound", obj.u8sFound, newContext);
		value += TSConverter.addStringParam("u8sDatabaseName", obj.u8sDatabaseName, newContext);
		value += TSConverter.addStringParam("u8sEntryIDDB", obj.u8sEntryIDDB, newContext);
		value += TSConverter.addStringParam("u8sEntryIDStore", obj.u8sEntryIDStore, newContext);
		value += TSConverter.addStringParam("u8sEntryID", obj.u8sEntryID, newContext);
		value += TSConverter.addStringParam("u8sCtiServerUserName", obj.u8sCtiServerUserName, newContext);
		value += TSConverter.addStringParam("u8sDisplayName", obj.u8sDisplayName, newContext);
		value += TSConverter.addStringParam("u8sFirstName", obj.u8sFirstName, newContext);
		value += TSConverter.addStringParam("u8sLastName", obj.u8sLastName, newContext);
		value += TSConverter.addStringParam("u8sJobTitle", obj.u8sJobTitle, newContext);
		value += TSConverter.addStringParam("u8sCompany", obj.u8sCompany, newContext);
		value += TSConverter.addStringParam("u8sDepartment", obj.u8sDepartment, newContext);
		value += TSConverter.addStringParam("u8sOfficeRoomNumber", obj.u8sOfficeRoomNumber, newContext);
		value += TSConverter.addStringParam("u8sCustomerID", obj.u8sCustomerID, newContext);
		value += TSConverter.addStringParam("u8sBusinessAddressStreet", obj.u8sBusinessAddressStreet, newContext);
		value += TSConverter.addStringParam("u8sBusinessAddressPostalCode", obj.u8sBusinessAddressPostalCode, newContext);
		value += TSConverter.addStringParam("u8sBusinessAddressCity", obj.u8sBusinessAddressCity, newContext);
		value += TSConverter.addStringParam("u8sBusinessAddressState", obj.u8sBusinessAddressState, newContext);
		value += TSConverter.addStringParam("u8sBusinessAddressCountry", obj.u8sBusinessAddressCountry, newContext);
		value += TSConverter.addStringParam("u8sPrivateAddressStreet", obj.u8sPrivateAddressStreet, newContext);
		value += TSConverter.addStringParam("u8sPrivateAddressPostalCode", obj.u8sPrivateAddressPostalCode, newContext);
		value += TSConverter.addStringParam("u8sPrivateAddressCity", obj.u8sPrivateAddressCity, newContext);
		value += TSConverter.addStringParam("u8sPrivateAddressState", obj.u8sPrivateAddressState, newContext);
		value += TSConverter.addStringParam("u8sPrivateAddressCountry", obj.u8sPrivateAddressCountry, newContext);
		value += TSConverter.addStringParam("u8sOtherAddressStreet", obj.u8sOtherAddressStreet, newContext);
		value += TSConverter.addStringParam("u8sOtherAddressPostalCode", obj.u8sOtherAddressPostalCode, newContext);
		value += TSConverter.addStringParam("u8sOtherAddressCity", obj.u8sOtherAddressCity, newContext);
		value += TSConverter.addStringParam("u8sOtherAddressState", obj.u8sOtherAddressState, newContext);
		value += TSConverter.addStringParam("u8sOtherAddressCountry", obj.u8sOtherAddressCountry, newContext);
		value += TSConverter.addStringParam("u8sEMail", obj.u8sEMail, newContext);
		value += TSConverter.addStringParam("u8sEMail2", obj.u8sEMail2, newContext);
		value += TSConverter.addStringParam("u8sEMail3", obj.u8sEMail3, newContext);
		value += TSConverter.addStringParam("u8sSIPAddress", obj.u8sSIPAddress, newContext);
		value += TSConverter.addStringParam("u8sWebPageURL", obj.u8sWebPageURL, newContext);
		value += TSConverter.addStringParam("u8sPhoneBusiness", obj.u8sPhoneBusiness, newContext);
		value += TSConverter.addStringParam("u8sPhoneBusiness2", obj.u8sPhoneBusiness2, newContext);
		value += TSConverter.addStringParam("u8sCompanyMainTelephoneNumber", obj.u8sCompanyMainTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sAssistantTelephoneNumber", obj.u8sAssistantTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sPhoneHome", obj.u8sPhoneHome, newContext);
		value += TSConverter.addStringParam("u8sPhoneHome2", obj.u8sPhoneHome2, newContext);
		value += TSConverter.addStringParam("u8sPrimaryTelephoneNumber", obj.u8sPrimaryTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sPhoneMobile", obj.u8sPhoneMobile, newContext);
		value += TSConverter.addStringParam("u8sCarTelephoneNumber", obj.u8sCarTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sRadioTelephoneNumber", obj.u8sRadioTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sPagerTelephoneNumber", obj.u8sPagerTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sOtherTelephoneNumber", obj.u8sOtherTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sCallbackTelephoneNumber", obj.u8sCallbackTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sISDNTelephoneNumber", obj.u8sISDNTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sTTYTTDTelephoneNumber", obj.u8sTTYTTDTelephoneNumber, newContext);
		value += TSConverter.addStringParam("u8sFaxBusiness", obj.u8sFaxBusiness, newContext);
		value += TSConverter.addStringParam("u8sFaxHome", obj.u8sFaxHome, newContext);
		value += TSConverter.addStringParam("u8sBody", obj.u8sBody, newContext);
		value += TSConverter.addStringParam("u8sDirectWebLink", obj.u8sDirectWebLink, newContext);
		value += TSConverter.addObjectAsStringParam("customFields", AsnStringPairList_Converter.toJSON(obj.customFields, newContext), newContext);
		value += TSConverter.addBooleanParam("bValid", obj.bValid, newContext);
		value += TSConverter.addNumberParam("iPrivateContact", obj.iPrivateContact, newContext);
		value += TSConverter.addNumberParam("iCtiServerUser", obj.iCtiServerUser, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnNetDatabaseContact, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNetDatabaseContact");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseContact"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnNetDatabaseContact;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNetDatabaseContact
			if (TSConverter.validateParam(data, "u8sFound", "string", errors, newContext, true))
				obj.u8sFound = data.u8sFound;
			if (TSConverter.validateParam(data, "u8sDatabaseName", "string", errors, newContext, true))
				obj.u8sDatabaseName = data.u8sDatabaseName;
			if (TSConverter.validateParam(data, "u8sEntryIDDB", "string", errors, newContext, true))
				obj.u8sEntryIDDB = data.u8sEntryIDDB;
			if (TSConverter.validateParam(data, "u8sEntryIDStore", "string", errors, newContext, true))
				obj.u8sEntryIDStore = data.u8sEntryIDStore;
			if (TSConverter.validateParam(data, "u8sEntryID", "string", errors, newContext, true))
				obj.u8sEntryID = data.u8sEntryID;
			if (TSConverter.validateParam(data, "u8sCtiServerUserName", "string", errors, newContext, true))
				obj.u8sCtiServerUserName = data.u8sCtiServerUserName;
			if (TSConverter.validateParam(data, "u8sDisplayName", "string", errors, newContext, true))
				obj.u8sDisplayName = data.u8sDisplayName;
			if (TSConverter.validateParam(data, "u8sFirstName", "string", errors, newContext, true))
				obj.u8sFirstName = data.u8sFirstName;
			if (TSConverter.validateParam(data, "u8sLastName", "string", errors, newContext, true))
				obj.u8sLastName = data.u8sLastName;
			if (TSConverter.validateParam(data, "u8sJobTitle", "string", errors, newContext, true))
				obj.u8sJobTitle = data.u8sJobTitle;
			if (TSConverter.validateParam(data, "u8sCompany", "string", errors, newContext, true))
				obj.u8sCompany = data.u8sCompany;
			if (TSConverter.validateParam(data, "u8sDepartment", "string", errors, newContext, true))
				obj.u8sDepartment = data.u8sDepartment;
			if (TSConverter.validateParam(data, "u8sOfficeRoomNumber", "string", errors, newContext, true))
				obj.u8sOfficeRoomNumber = data.u8sOfficeRoomNumber;
			if (TSConverter.validateParam(data, "u8sCustomerID", "string", errors, newContext, true))
				obj.u8sCustomerID = data.u8sCustomerID;
			if (TSConverter.validateParam(data, "u8sBusinessAddressStreet", "string", errors, newContext, true))
				obj.u8sBusinessAddressStreet = data.u8sBusinessAddressStreet;
			if (TSConverter.validateParam(data, "u8sBusinessAddressPostalCode", "string", errors, newContext, true))
				obj.u8sBusinessAddressPostalCode = data.u8sBusinessAddressPostalCode;
			if (TSConverter.validateParam(data, "u8sBusinessAddressCity", "string", errors, newContext, true))
				obj.u8sBusinessAddressCity = data.u8sBusinessAddressCity;
			if (TSConverter.validateParam(data, "u8sBusinessAddressState", "string", errors, newContext, true))
				obj.u8sBusinessAddressState = data.u8sBusinessAddressState;
			if (TSConverter.validateParam(data, "u8sBusinessAddressCountry", "string", errors, newContext, true))
				obj.u8sBusinessAddressCountry = data.u8sBusinessAddressCountry;
			if (TSConverter.validateParam(data, "u8sPrivateAddressStreet", "string", errors, newContext, true))
				obj.u8sPrivateAddressStreet = data.u8sPrivateAddressStreet;
			if (TSConverter.validateParam(data, "u8sPrivateAddressPostalCode", "string", errors, newContext, true))
				obj.u8sPrivateAddressPostalCode = data.u8sPrivateAddressPostalCode;
			if (TSConverter.validateParam(data, "u8sPrivateAddressCity", "string", errors, newContext, true))
				obj.u8sPrivateAddressCity = data.u8sPrivateAddressCity;
			if (TSConverter.validateParam(data, "u8sPrivateAddressState", "string", errors, newContext, true))
				obj.u8sPrivateAddressState = data.u8sPrivateAddressState;
			if (TSConverter.validateParam(data, "u8sPrivateAddressCountry", "string", errors, newContext, true))
				obj.u8sPrivateAddressCountry = data.u8sPrivateAddressCountry;
			if (TSConverter.validateParam(data, "u8sOtherAddressStreet", "string", errors, newContext, true))
				obj.u8sOtherAddressStreet = data.u8sOtherAddressStreet;
			if (TSConverter.validateParam(data, "u8sOtherAddressPostalCode", "string", errors, newContext, true))
				obj.u8sOtherAddressPostalCode = data.u8sOtherAddressPostalCode;
			if (TSConverter.validateParam(data, "u8sOtherAddressCity", "string", errors, newContext, true))
				obj.u8sOtherAddressCity = data.u8sOtherAddressCity;
			if (TSConverter.validateParam(data, "u8sOtherAddressState", "string", errors, newContext, true))
				obj.u8sOtherAddressState = data.u8sOtherAddressState;
			if (TSConverter.validateParam(data, "u8sOtherAddressCountry", "string", errors, newContext, true))
				obj.u8sOtherAddressCountry = data.u8sOtherAddressCountry;
			if (TSConverter.validateParam(data, "u8sEMail", "string", errors, newContext, true))
				obj.u8sEMail = data.u8sEMail;
			if (TSConverter.validateParam(data, "u8sEMail2", "string", errors, newContext, true))
				obj.u8sEMail2 = data.u8sEMail2;
			if (TSConverter.validateParam(data, "u8sEMail3", "string", errors, newContext, true))
				obj.u8sEMail3 = data.u8sEMail3;
			if (TSConverter.validateParam(data, "u8sSIPAddress", "string", errors, newContext, true))
				obj.u8sSIPAddress = data.u8sSIPAddress;
			if (TSConverter.validateParam(data, "u8sWebPageURL", "string", errors, newContext, true))
				obj.u8sWebPageURL = data.u8sWebPageURL;
			if (TSConverter.validateParam(data, "u8sPhoneBusiness", "string", errors, newContext, true))
				obj.u8sPhoneBusiness = data.u8sPhoneBusiness;
			if (TSConverter.validateParam(data, "u8sPhoneBusiness2", "string", errors, newContext, true))
				obj.u8sPhoneBusiness2 = data.u8sPhoneBusiness2;
			if (TSConverter.validateParam(data, "u8sCompanyMainTelephoneNumber", "string", errors, newContext, true))
				obj.u8sCompanyMainTelephoneNumber = data.u8sCompanyMainTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sAssistantTelephoneNumber", "string", errors, newContext, true))
				obj.u8sAssistantTelephoneNumber = data.u8sAssistantTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sPhoneHome", "string", errors, newContext, true))
				obj.u8sPhoneHome = data.u8sPhoneHome;
			if (TSConverter.validateParam(data, "u8sPhoneHome2", "string", errors, newContext, true))
				obj.u8sPhoneHome2 = data.u8sPhoneHome2;
			if (TSConverter.validateParam(data, "u8sPrimaryTelephoneNumber", "string", errors, newContext, true))
				obj.u8sPrimaryTelephoneNumber = data.u8sPrimaryTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sPhoneMobile", "string", errors, newContext, true))
				obj.u8sPhoneMobile = data.u8sPhoneMobile;
			if (TSConverter.validateParam(data, "u8sCarTelephoneNumber", "string", errors, newContext, true))
				obj.u8sCarTelephoneNumber = data.u8sCarTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sRadioTelephoneNumber", "string", errors, newContext, true))
				obj.u8sRadioTelephoneNumber = data.u8sRadioTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sPagerTelephoneNumber", "string", errors, newContext, true))
				obj.u8sPagerTelephoneNumber = data.u8sPagerTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sOtherTelephoneNumber", "string", errors, newContext, true))
				obj.u8sOtherTelephoneNumber = data.u8sOtherTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sCallbackTelephoneNumber", "string", errors, newContext, true))
				obj.u8sCallbackTelephoneNumber = data.u8sCallbackTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sISDNTelephoneNumber", "string", errors, newContext, true))
				obj.u8sISDNTelephoneNumber = data.u8sISDNTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sTTYTTDTelephoneNumber", "string", errors, newContext, true))
				obj.u8sTTYTTDTelephoneNumber = data.u8sTTYTTDTelephoneNumber;
			if (TSConverter.validateParam(data, "u8sFaxBusiness", "string", errors, newContext, true))
				obj.u8sFaxBusiness = data.u8sFaxBusiness;
			if (TSConverter.validateParam(data, "u8sFaxHome", "string", errors, newContext, true))
				obj.u8sFaxHome = data.u8sFaxHome;
			if (TSConverter.validateParam(data, "u8sBody", "string", errors, newContext, true))
				obj.u8sBody = data.u8sBody;
			if (TSConverter.validateParam(data, "u8sDirectWebLink", "string", errors, newContext, true))
				obj.u8sDirectWebLink = data.u8sDirectWebLink;
			const tscv_customfields = new ENetUC_Common.AsnStringPairList();
			if (AsnStringPairList_Converter.fromJSON(data.customFields, tscv_customfields, errors, newContext, "customFields", true))
				obj.customFields = tscv_customfields;
			if (TSConverter.validateParam(data, "bValid", "boolean", errors, newContext, true))
				obj.bValid = data.bValid;
			if (TSConverter.validateParam(data, "iPrivateContact", "number", errors, newContext, true))
				obj.iPrivateContact = data.iPrivateContact;
			if (TSConverter.validateParam(data, "iCtiServerUser", "number", errors, newContext, true))
				obj.iCtiServerUser = data.iCtiServerUser;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseContact"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNetDatabaseContactID
export class AsnNetDatabaseContactID_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnNetDatabaseContactID, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNetDatabaseContactID
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNetDatabaseContactID", newContext);
		value += TSConverter.addStringParam("u8sEntryID", obj.u8sEntryID, newContext);
		value += TSConverter.addStringParam("u8sEntryIDDB", obj.u8sEntryIDDB, newContext);
		value += TSConverter.addStringParam("u8sEntryIDStore", obj.u8sEntryIDStore, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnNetDatabaseContactID, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNetDatabaseContactID");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseContactID"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnNetDatabaseContactID;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNetDatabaseContactID
			if (TSConverter.validateParam(data, "u8sEntryID", "string", errors, newContext, false))
				obj.u8sEntryID = data.u8sEntryID;
			if (TSConverter.validateParam(data, "u8sEntryIDDB", "string", errors, newContext, false))
				obj.u8sEntryIDDB = data.u8sEntryIDDB;
			if (TSConverter.validateParam(data, "u8sEntryIDStore", "string", errors, newContext, false))
				obj.u8sEntryIDStore = data.u8sEntryIDStore;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseContactID"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnIntegerPairList
export class AsnIntegerPairList_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnIntegerPairList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnIntegerPairList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnIntegerPair_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnIntegerPairList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnIntegerPairList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnIntegerPairList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnIntegerPairList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnIntegerPairList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common.AsnIntegerPair.initEmpty();
				if (AsnIntegerPair_Converter.fromJSON(elem, entry, errors, newContext, "AsnIntegerPair", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnIntegerPairList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnStringIntegerPairList
export class AsnStringIntegerPairList_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnStringIntegerPairList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnStringIntegerPairList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnStringIntegerPair_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnStringIntegerPairList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStringIntegerPairList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStringIntegerPairList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnStringIntegerPairList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnStringIntegerPairList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common.AsnStringIntegerPair.initEmpty();
				if (AsnStringIntegerPair_Converter.fromJSON(elem, entry, errors, newContext, "AsnStringIntegerPair", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStringIntegerPairList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestErrorList
export class AsnRequestErrorList_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnRequestErrorList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnRequestErrorList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnRequestError_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnRequestErrorList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestErrorList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestErrorList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnRequestErrorList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnRequestErrorList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common.AsnRequestError.initEmpty();
				if (AsnRequestError_Converter.fromJSON(elem, entry, errors, newContext, "AsnRequestError", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestErrorList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] SEQInteger
export class SEQInteger_Converter {
	public static toJSON(obj?: ENetUC_Common.SEQInteger, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] SEQInteger
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				value += TSConverter.addNumberParam("", obj[id], newContext);
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.SEQInteger, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "SEQInteger");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing SEQInteger"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.SEQInteger;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] SEQInteger
			for (const id in data) {
				const elem = data[id];
				if (typeof elem === "number")
					obj.push(elem);
				else
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_TYPEMISMATCH, newContext.context + "number", "expected type was AsnInt effective type is " + typeof data[id]));
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing SEQInteger"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] UTF8StringList
export class UTF8StringList_Converter {
	public static toJSON(obj?: ENetUC_Common.UTF8StringList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] UTF8StringList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				value += TSConverter.addStringParam("", obj[id], newContext);
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.UTF8StringList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "UTF8StringList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing UTF8StringList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.UTF8StringList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] UTF8StringList
			for (const id in data) {
				const elem = data[id];
				if (typeof elem === "string")
					obj.push(elem);
				else
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_TYPEMISMATCH, newContext.context + "string", "expected type was UTF8String effective type is " + typeof data[id]));
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing UTF8StringList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNetDatabaseContactList
export class AsnNetDatabaseContactList_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnNetDatabaseContactList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnNetDatabaseContactList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnNetDatabaseContact_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnNetDatabaseContactList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNetDatabaseContactList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseContactList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnNetDatabaseContactList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnNetDatabaseContactList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
				if (AsnNetDatabaseContact_Converter.fromJSON(elem, entry, errors, newContext, "AsnNetDatabaseContact", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseContactList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNetDatabaseContactIDList
export class AsnNetDatabaseContactIDList_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnNetDatabaseContactIDList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnNetDatabaseContactIDList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnNetDatabaseContactID_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnNetDatabaseContactIDList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNetDatabaseContactIDList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseContactIDList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnNetDatabaseContactIDList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnNetDatabaseContactIDList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Common.AsnNetDatabaseContactID.initEmpty();
				if (AsnNetDatabaseContactID_Converter.fromJSON(elem, entry, errors, newContext, "AsnNetDatabaseContactID", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNetDatabaseContactIDList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUserPropertyBag
export class AsnUserPropertyBag_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnUserPropertyBag, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUserPropertyBag
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUserPropertyBag", newContext);
		value += TSConverter.addObjectAsStringParam("asnProperties", AsnStringPairList_Converter.toJSON(obj.asnProperties, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnUserPropertyBag, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUserPropertyBag");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUserPropertyBag"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnUserPropertyBag;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUserPropertyBag
			const tscv_asnproperties = new ENetUC_Common.AsnStringPairList();
			if (AsnStringPairList_Converter.fromJSON(data.asnProperties, tscv_asnproperties, errors, newContext, "asnProperties", false))
				obj.asnProperties = tscv_asnproperties;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUserPropertyBag"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnJSONWebToken
export class AsnJSONWebToken_Converter {
	public static toJSON(obj?: ENetUC_Common.AsnJSONWebToken, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnJSONWebToken
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnJSONWebToken", newContext);
		value += TSConverter.addObjectAsStringParam("header", AsnJSONWebTokenHeader_Converter.toJSON(obj.header, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("payload", AsnJSONWebTokenPayLoad_Converter.toJSON(obj.payload, newContext), newContext);
		value += TSConverter.addStringParam("signature", obj.signature, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnJSONWebToken, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnJSONWebToken");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnJSONWebToken"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Common.AsnJSONWebToken;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnJSONWebToken
			const tscv_header = ENetUC_Common.AsnJSONWebTokenHeader.initEmpty();
			if (AsnJSONWebTokenHeader_Converter.fromJSON(data.header, tscv_header, errors, newContext, "header", false))
				obj.header = tscv_header;
			const tscv_payload = ENetUC_Common.AsnJSONWebTokenPayLoad.initEmpty();
			if (AsnJSONWebTokenPayLoad_Converter.fromJSON(data.payload, tscv_payload, errors, newContext, "payload", false))
				obj.payload = tscv_payload;
			if (TSConverter.validateParam(data, "signature", "string", errors, newContext, false))
				obj.signature = data.signature;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnJSONWebToken"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
