// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_Common.ts
 * "UC-Server-Access-Protocol-Common" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * Common interface definitions
 * ## Module description
 * This module contains common usable strutures for the other interfaces.
 * There should not be any function\/operation defintions in this module.
 */

// [PrintTSTypeDefCode] AsnSystemTime
// [PrintTSimpleDefCode] AsnSystemTime
export type AsnSystemTime = number;

// [PrintTSTypeDefCode] AsnOptionalParamChoice
// [PrintTSChoiceDefCode] AsnOptionalParamChoice
export class AsnOptionalParamChoice {
	public constructor(that?: AsnOptionalParamChoice) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOptionalParamChoice {
		return new AsnOptionalParamChoice();
	}

	public stringdata?: string = undefined;
	public binarydata?: Uint8Array = undefined;
	public integerdata?: number = undefined;
}

// [PrintTSTypeDefCode] AsnStringPair
// [PrintTSSeqDefCode] AsnStringPair
/**
 * Key value pair of strings.
 */
export class AsnStringPair {
	public constructor(that: AsnStringPair) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnStringPair {
		return new AsnStringPair({
			key: "",
			value: ""
		});
	}

	public key!: string;
	public value!: string;
}

// [PrintTSTypeDefCode] AsnIntegerPair
// [PrintTSSeqDefCode] AsnIntegerPair
/**
 * Key value pair of integer
 */
export class AsnIntegerPair {
	public constructor(that: AsnIntegerPair) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnIntegerPair {
		return new AsnIntegerPair({
			key: 0,
			value: 0
		});
	}

	public key!: number;
	public value!: number;
}

// [PrintTSTypeDefCode] AsnStringIntegerPair
// [PrintTSSeqDefCode] AsnStringIntegerPair
/**
 * Key value pair of &lt;string, integer&gt;
 */
export class AsnStringIntegerPair {
	public constructor(that: AsnStringIntegerPair) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnStringIntegerPair {
		return new AsnStringIntegerPair({
			u8sStr: "",
			iInt: 0
		});
	}

	public u8sStr!: string;
	public iInt!: number;
}

// [PrintTSTypeDefCode] AsnOptionalParam
// [PrintTSSeqDefCode] AsnOptionalParam
/**
 * Encapsulated key value pair, including a definition of the value type.
 * Sequences can change over time of the development of the product. To ensure, that possible breaking changes can be avoided
 * within the life cycle of a major version, most sequences contain a list of AsnOptionalParam.
 *
 * Because of the generic nature of the this sequence, the documention of the content is defined in the sections
 * using the AsnOptionalParam.
 */
export class AsnOptionalParam {
	public constructor(that: AsnOptionalParam) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnOptionalParam {
		return new AsnOptionalParam({
			key: "",
			value: AsnOptionalParamChoice.initEmpty()
		});
	}

	public key!: string;
	public value!: AsnOptionalParamChoice;
}

// [PrintTSTypeDefCode] AsnStringPairList
// [PrintTSSetOfDefCode] AsnStringPairList
export class AsnStringPairList extends Array<AsnStringPair> { }

// [PrintTSTypeDefCode] AsnJSONWebTokenHeader
// [PrintTSSeqDefCode] AsnJSONWebTokenHeader
/**
 * Header data for a RFC-7519 JSON Web Token
 */
export class AsnJSONWebTokenHeader {
	public constructor(that: AsnJSONWebTokenHeader) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJSONWebTokenHeader {
		return new AsnJSONWebTokenHeader({
			u8sTYP: "",
			u8sALG: ""
		});
	}

	/** Defines the type of the token. This is currently by default JWT to specify it as RFC-7519 JSON Web Token */
	public u8sTYP!: string;
	/** Identifies which algorithm is used to generate the signature, HS256 indicates that this token is signed using HMAC-SHA256. */
	public u8sALG!: string;
	/** Defines the content type if the typ is not JWT (JSON Web Token) */
	public u8sCTY?: string;
	public optionals?: AsnStringPairList;
}

// [PrintTSTypeDefCode] AsnJSONWebTokenPayLoad
// [PrintTSSeqDefCode] AsnJSONWebTokenPayLoad
/**
 * Payload data for a RFC-7519 JSON Web Token
 */
export class AsnJSONWebTokenPayLoad {
	public constructor(that: AsnJSONWebTokenPayLoad) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJSONWebTokenPayLoad {
		return new AsnJSONWebTokenPayLoad({
			u8sJTI: ""
		});
	}

	/** Identifies principal that issued the JWT. */
	public u8sISS?: string;
	/** Identifies the subject of the JWT. */
	public u8sSUB?: string;
	/** Identifies the recipients that the JWT is intended for. Each principal intended to process the JWT must identify itself with a value in the audience claim. If the principal processing the claim does not identify itself with a value in the aud claim when this claim is present, then the JWT must be rejected. */
	public u8sAUD?: string;
	/** Identifies the expiration time on and after which the JWT must not be accepted for processing. The value must be a NumericDate[10]: either an integer or decimal, representing seconds past 1970-01-01 00:00:00Z. */
	public utcEXP?: Date;
	/** Identifies the time on which the JWT will start to be accepted for processing. The value must be a NumericDate. */
	public utcNBF?: Date;
	/** Identifies the time at which the JWT was issued. The value must be a NumericDate. */
	public utcIAT?: Date;
	/** Case sensitive unique identifier of the token even among different issuers. */
	public u8sJTI!: string;
	/** Defines a user type in the context of using the token. The implementer has to define a enum value list this value belongs to */
	public iUserType?: number;
	public optionals?: AsnStringPairList;
}

// [PrintTSTypeDefCode] AsnOptionalParameters
// [PrintTSSetOfDefCode] AsnOptionalParameters
export class AsnOptionalParameters extends Array<AsnOptionalParam> { }

// [PrintTSTypeDefCode] AsnRequestError
// [PrintTSSeqDefCode] AsnRequestError
/**
 * Generic error sequence. See the documentation of the operations specific descriptions.
 */
export class AsnRequestError {
	public constructor(that: AsnRequestError) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnRequestError {
		return new AsnRequestError({
			iErrorDetail: 0,
			u8sErrorString: ""
		});
	}

	/** A number representing the error */
	public iErrorDetail!: number;
	/** A string representing the error */
	public u8sErrorString!: string;
	/** No optional params yet */
	public optionalParams?: AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnNetDatabaseContact
// [PrintTSSeqDefCode] AsnNetDatabaseContact
/**
 * Sequence\/object containing contact data.
 * AsnNetDatabaseContact is widly used almost everywhere in the API. It contains the contact data of a contact and from which databse it comes from.
 * All fields in this sequence are optional, because not every field must have content, but the sequence is broadly used, so every field which is
 * not transmitted saves bandwidth.
 */
export class AsnNetDatabaseContact {
	public constructor(that?: AsnNetDatabaseContact) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNetDatabaseContact {
		return new AsnNetDatabaseContact();
	}

	public u8sFound?: string;
	public u8sDatabaseName?: string;
	public u8sEntryIDDB?: string;
	public u8sEntryIDStore?: string;
	public u8sEntryID?: string;
	public u8sCtiServerUserName?: string;
	public u8sDisplayName?: string;
	public u8sFirstName?: string;
	public u8sLastName?: string;
	public u8sJobTitle?: string;
	public u8sCompany?: string;
	public u8sDepartment?: string;
	public u8sOfficeRoomNumber?: string;
	public u8sCustomerID?: string;
	public u8sBusinessAddressStreet?: string;
	public u8sBusinessAddressPostalCode?: string;
	public u8sBusinessAddressCity?: string;
	public u8sBusinessAddressState?: string;
	public u8sBusinessAddressCountry?: string;
	public u8sPrivateAddressStreet?: string;
	public u8sPrivateAddressPostalCode?: string;
	public u8sPrivateAddressCity?: string;
	public u8sPrivateAddressState?: string;
	public u8sPrivateAddressCountry?: string;
	public u8sOtherAddressStreet?: string;
	public u8sOtherAddressPostalCode?: string;
	public u8sOtherAddressCity?: string;
	public u8sOtherAddressState?: string;
	public u8sOtherAddressCountry?: string;
	public u8sEMail?: string;
	public u8sEMail2?: string;
	public u8sEMail3?: string;
	public u8sSIPAddress?: string;
	public u8sWebPageURL?: string;
	public u8sPhoneBusiness?: string;
	public u8sPhoneBusiness2?: string;
	public u8sCompanyMainTelephoneNumber?: string;
	public u8sAssistantTelephoneNumber?: string;
	public u8sPhoneHome?: string;
	public u8sPhoneHome2?: string;
	public u8sPrimaryTelephoneNumber?: string;
	public u8sPhoneMobile?: string;
	public u8sCarTelephoneNumber?: string;
	public u8sRadioTelephoneNumber?: string;
	public u8sPagerTelephoneNumber?: string;
	public u8sOtherTelephoneNumber?: string;
	public u8sCallbackTelephoneNumber?: string;
	public u8sISDNTelephoneNumber?: string;
	public u8sTTYTTDTelephoneNumber?: string;
	public u8sFaxBusiness?: string;
	public u8sFaxHome?: string;
	/** thats also called \"note\" */
	public u8sBody?: string;
	public u8sDirectWebLink?: string;
	/** additional fields, mostly filled with runtime information */
	public customFields?: AsnStringPairList;
	public bValid?: boolean;
	public iPrivateContact?: number;
	public iCtiServerUser?: number;
	/** Additionally fields added later in the key-value-field for backward compatibility.
	/** - key \"jpegPhoto\", Type byte[], a jpeg photo of the contact; Note: it is planned to not transmit the photo with */
	public optionalParams?: AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnNetDatabaseContactID
// [PrintTSSeqDefCode] AsnNetDatabaseContactID
/**
 * A three tuple to identify contact data.
 * The three tuple EntryID, EntryIDDB and EntryIDStore identifies contact data including its
 * source database, so that contact data from different data sources can be differentiated from each other.
 * It is an own sequence, so it can be tranferred without any concrete contact data to save bandwidth.
 *
 * The AsnNetDatabaseContact does not contain a AsnNetDatabaseContactID object, it contains the three
 * members by itself.
 */
export class AsnNetDatabaseContactID {
	public constructor(that: AsnNetDatabaseContactID) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNetDatabaseContactID {
		return new AsnNetDatabaseContactID({
			u8sEntryID: "",
			u8sEntryIDDB: "",
			u8sEntryIDStore: ""
		});
	}

	/** ID of the contact data in the source database. */
	public u8sEntryID!: string;
	/** ID of the database (type). */
	public u8sEntryIDDB!: string;
	/** Additional ID of a store in the database (if needed). */
	public u8sEntryIDStore!: string;
	public optionalParams?: AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnIntegerPairList
// [PrintTSSetOfDefCode] AsnIntegerPairList
export class AsnIntegerPairList extends Array<AsnIntegerPair> { }

// [PrintTSTypeDefCode] AsnStringIntegerPairList
// [PrintTSSetOfDefCode] AsnStringIntegerPairList
export class AsnStringIntegerPairList extends Array<AsnStringIntegerPair> { }

// [PrintTSTypeDefCode] AsnRequestErrorList
// [PrintTSSetOfDefCode] AsnRequestErrorList
export class AsnRequestErrorList extends Array<AsnRequestError> { }

// [PrintTSTypeDefCode] SEQInteger
// [PrintTSSetOfDefCode] SEQInteger
export class SEQInteger extends Array<number> { }

// [PrintTSTypeDefCode] UTF8StringList
// [PrintTSSetOfDefCode] UTF8StringList
export class UTF8StringList extends Array<string> { }

// [PrintTSTypeDefCode] AsnNetDatabaseContactList
// [PrintTSSetOfDefCode] AsnNetDatabaseContactList
export class AsnNetDatabaseContactList extends Array<AsnNetDatabaseContact> { }

// [PrintTSTypeDefCode] AsnNetDatabaseContactIDList
// [PrintTSSetOfDefCode] AsnNetDatabaseContactIDList
export class AsnNetDatabaseContactIDList extends Array<AsnNetDatabaseContactID> { }

// [PrintTSTypeDefCode] AsnUserPropertyBag
// [PrintTSSeqDefCode] AsnUserPropertyBag
/**
 * Property bag definition for storing runtime defined content for objects (like users, computers, groups etc.).
 * To make it possible to transport and store runtime defined content in some objects, these object contain
 * a property bag, which is simply a key value store. Which keys (and therefor which data) is available
 * is depending on the object and the business logic, which uses it. So see the according sequence (object) documentation
 * for more details.
 */
export class AsnUserPropertyBag {
	public constructor(that: AsnUserPropertyBag) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUserPropertyBag {
		return new AsnUserPropertyBag({
			asnProperties: new AsnStringPairList()
		});
	}

	public asnProperties!: AsnStringPairList;
}

// [PrintTSTypeDefCode] AsnJSONWebToken
// [PrintTSSeqDefCode] AsnJSONWebToken
/**
 * Data for a RFC-7519 JSON Web Token
 */
export class AsnJSONWebToken {
	public constructor(that: AsnJSONWebToken) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnJSONWebToken {
		return new AsnJSONWebToken({
			header: AsnJSONWebTokenHeader.initEmpty(),
			payload: AsnJSONWebTokenPayLoad.initEmpty(),
			signature: ""
		});
	}

	/** The header content of the JWT */
	public header!: AsnJSONWebTokenHeader;
	/** The body content of the JWT */
	public payload!: AsnJSONWebTokenPayLoad;
	/** The signature of the base64 encoded header and payload */
	public signature!: string;
}
