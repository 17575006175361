import { faPhone, faPhoneRotary } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Fade, Paper, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

import { Button } from "../../components/common/Button/Button";
import { Header } from "../../components/common/Header/Header";
import { Typography } from "../../components/common/Typography/Typography";
import { theBrowser, theContactManager } from "../../globals";
import { checkIsPhoneNumber } from "../../lib/commonHelper";
import { ContactSearchLimit, ContactsPerPage } from "../../managers/ContactManager/ContactManager";
import { AsnNetDatabaseContactList } from "../../ucserver/stubs/ENetUC_Common";
import { IClientContent } from "../../zustand/contactSlice";
import { getState, useStore } from "../../zustand/store";
import { LeftColumnE } from "../Dashboard";
import { FavoriteContacts } from "../FavoriteContacts/FavoriteContacts";
import { SearchResultContacts } from "../SearchResultContacts/SearchResultContacts";
import { ContactsSearchForm } from "./ContactsSearchForm";
import { SettingsItems } from "./SettingsItems";

interface IContactsProps {
	setLeftCol: (leftCol: LeftColumnE) => void;
	onSelectContact: (sipAddress?: string) => void;
	selectedContactId: string | null;
}

export const Contacts = ({ setLeftCol, onSelectContact, selectedContactId }: IContactsProps) => {
	const { t } = useTranslation();

	const journalContacts = useStore((state) => state.journalContacts());
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [foundContacts, setFoundContacts] = useState<AsnNetDatabaseContactList>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");

	const handleContactsFromSearch = (contactList: AsnNetDatabaseContactList): void => {
		const correctDisplayNameContactList: AsnNetDatabaseContactList = [];
		for (const contact of contactList) {
			let displayName = contact.u8sDisplayName;
			if (contact.u8sFirstName && contact.u8sLastName) {
				displayName = contact.u8sFirstName + " " + contact.u8sLastName;
			}
			const correctContact = { ...contact, u8sDisplayName: displayName };
			correctDisplayNameContactList.push(correctContact);
		}
		setFoundContacts(correctDisplayNameContactList);

		const contactDetailsToStore: IClientContent[] = correctDisplayNameContactList
			.map((item) => {
				return { ...item, u8sEntryID: item.u8sEntryID || "" };
			})
			.filter((item) => item.u8sEntryID !== "");

		getState().setContactsDetails(contactDetailsToStore);
	};

	const debouncedSearch = useDebouncedCallback(async (searchText: string) => {
		try {
			const result = await theContactManager.searchInServer(searchText, 0);
			if (result instanceof Error) {
				console.log("Error while fetching contacts: ", result);
				return;
			}
			handleContactsFromSearch(result.contactList);
		} catch (e) {
			console.log(e);
		}
		!debouncedSearch?.isPending() && setIsLoading(false);
	}, 500);

	const startSearch = async (searchText: string) => {
		setIsLoading(true);
		await debouncedSearch(searchText);
	};

	const onEndReached = async () => {
		// max contacts loaded
		if (foundContacts.length % ContactsPerPage !== 0 || foundContacts.length >= ContactSearchLimit) {
			return;
		}
		try {
			const result = await theContactManager.searchInServer(searchTerm, foundContacts.length);
			if (result instanceof Error) {
				console.log("Error while fetching contacts: ", result);
				return;
			}
			handleContactsFromSearch([...foundContacts, ...result.contactList]);
		} catch (e) {
			console.log(e);
		}
	};

	const onCallNumber = (numberToCall: string) => {
		window.open(`tel:${numberToCall}`, "_self");
	};

	return (
		<Paper sx={{ height: "100%", width: theBrowser.is.mobile ? "calc(100vw - 32px)" : "unset" }}>
			<Stack height={"100%"}>
				<Header title={t("IDS_CONTACT_PAGE_TITLE")}></Header>
				<ContactsSearchForm
					onSearch={startSearch}
					searchTerm={searchTerm}
					isLoading={isLoading}
					setSearchTerm={setSearchTerm}
					onCallNumber={onCallNumber}
					sx={{ mx: 2, mb: 1 }}
				/>
				{checkIsPhoneNumber(searchTerm) && foundContacts.length === 0 ? (
					<Fade in>
						<Stack flex={1} overflow={"hidden"} justifyContent={"center"} position="relative">
							<Stack overflow={"hidden"} px={6} pt={12} pb={6}>
								<img style={{ height: "100%" }} src="./assets/images/illustration_calls.svg" />
							</Stack>
							<Stack sx={{ position: "absolute", top: 0, width: "100%" }}>
								<Stack alignItems={"center"} my={1}>
									<Button
										startIcon={<FontAwesomeIcon color={"white"} icon={faPhone} />}
										color={"success"}
										sx={{ color: "white" }}
										onClick={() => {
											onCallNumber(searchTerm);
										}}
									>
										{t("IDS_CONTACT_CALL_NUMBER_BUTTON")}
									</Button>
								</Stack>
								<FontAwesomeIcon icon={faPhoneRotary} size="3x" style={{ paddingTop: "8px" }} />
								<Typography variant="h2" sx={{ textAlign: "center", my: 1 }}>
									{t("IDS_CONTACT_CALL_NUMBER_TITLE")}
								</Typography>
								<Typography variant="body1" sx={{ textAlign: "center", whiteSpace: "pre-line" }}>
									{t("IDS_CONTACT_CALL_NUMBER_INFO")}
								</Typography>
							</Stack>
						</Stack>
					</Fade>
				) : searchTerm.length > 0 ? (
					<SearchResultContacts
						selectedContactId={selectedContactId}
						searchedContacts={foundContacts}
						isLoading={isLoading}
						endReached={onEndReached}
						onClick={(contact) => {
							onSelectContact(contact.u8sEntryID);
						}}
						searchTerm={searchTerm}
					/>
				) : (
					<FavoriteContacts
						selectedContactId={selectedContactId}
						favoriteContacts={[...journalContacts].map(([, clientContent]) => clientContent)}
						onClick={(contact) => {
							onSelectContact(contact.u8sSIPAddress);
						}}
					/>
				)}
				<Divider sx={{ mx: 2 }} />
				<SettingsItems setLeftCol={setLeftCol} />
			</Stack>
		</Paper>
	);
};
