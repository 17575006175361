// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_AV_Converter.ts
 * "UC-Server-Access-Protocol-AV" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_AV from "./ENetUC_AV";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";

// [PrintTSEncoderDecoderCode] AsnAVCallAndConnectionID
export class AsnAVCallAndConnectionID_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVCallAndConnectionID, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVCallAndConnectionID
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVCallAndConnectionID", newContext);
		value += TSConverter.addStringParam("u8sCallID", obj.u8sCallID, newContext);
		value += TSConverter.addStringParam("u8sEndpointID", obj.u8sEndpointID, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVCallAndConnectionID, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVCallAndConnectionID");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallAndConnectionID"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVCallAndConnectionID;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVCallAndConnectionID
			if (TSConverter.validateParam(data, "u8sCallID", "string", errors, newContext, false))
				obj.u8sCallID = data.u8sCallID;
			if (TSConverter.validateParam(data, "u8sEndpointID", "string", errors, newContext, false))
				obj.u8sEndpointID = data.u8sEndpointID;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallAndConnectionID"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVMakeCallArgument
export class AsnAVMakeCallArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVMakeCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVMakeCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVMakeCallArgument", newContext);
		value += TSConverter.addNumberParam("callType", obj.callType, newContext);
		value += TSConverter.addStringParam("u8sUriFrom", obj.u8sUriFrom, newContext);
		value += TSConverter.addStringParam("u8sUriTo", obj.u8sUriTo, newContext);
		value += TSConverter.addStringParam("u8sContentType", obj.u8sContentType, newContext);
		value += TSConverter.addStringParam("u8sBody", obj.u8sBody, newContext);
		value += TSConverter.addObjectAsStringParam("newCallParams", ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(obj.newCallParams, newContext), newContext);
		value += TSConverter.addStringParam("u8sAssociatedTextChatConversationID", obj.u8sAssociatedTextChatConversationID, newContext);
		value += TSConverter.addStringParam("u8sCaps", obj.u8sCaps, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVMakeCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVMakeCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVMakeCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVMakeCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVMakeCallArgument
			if (TSConverter.validateParam(data, "callType", "number", errors, newContext, false))
				obj.callType = data.callType;
			if (TSConverter.validateParam(data, "u8sUriFrom", "string", errors, newContext, false))
				obj.u8sUriFrom = data.u8sUriFrom;
			if (TSConverter.validateParam(data, "u8sUriTo", "string", errors, newContext, false))
				obj.u8sUriTo = data.u8sUriTo;
			if (TSConverter.validateParam(data, "u8sContentType", "string", errors, newContext, false))
				obj.u8sContentType = data.u8sContentType;
			if (TSConverter.validateParam(data, "u8sBody", "string", errors, newContext, false))
				obj.u8sBody = data.u8sBody;
			const tscv_newcallparams = ENetUC_Common_SIPCTI.AsnCtiNewCallParams.initEmpty();
			if (ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(data.newCallParams, tscv_newcallparams, errors, newContext, "newCallParams", true))
				obj.newCallParams = tscv_newcallparams;
			if (TSConverter.validateParam(data, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true))
				obj.u8sAssociatedTextChatConversationID = data.u8sAssociatedTextChatConversationID;
			if (TSConverter.validateParam(data, "u8sCaps", "string", errors, newContext, true))
				obj.u8sCaps = data.u8sCaps;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVMakeCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVCallParty
export class AsnAVCallParty_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVCallParty, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVCallParty
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVCallParty", newContext);
		value += TSConverter.addStringParam("endpointID", obj.endpointID, newContext);
		value += TSConverter.addNumberParam("endPointState", obj.endPointState, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVCallParty, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVCallParty");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallParty"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVCallParty;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVCallParty
			if (TSConverter.validateParam(data, "endpointID", "string", errors, newContext, false))
				obj.endpointID = data.endpointID;
			if (TSConverter.validateParam(data, "endPointState", "number", errors, newContext, false))
				obj.endPointState = data.endPointState;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallParty"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVCallMessageICE
export class AsnAVCallMessageICE_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVCallMessageICE, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVCallMessageICE
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVCallMessageICE", newContext);
		value += TSConverter.addStringParam("candidate", obj.candidate, newContext);
		value += TSConverter.addStringParam("sdpMid", obj.sdpMid, newContext);
		value += TSConverter.addNumberParam("sdpMLineIndex", obj.sdpMLineIndex, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVCallMessageICE, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVCallMessageICE");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallMessageICE"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVCallMessageICE;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVCallMessageICE
			if (TSConverter.validateParam(data, "candidate", "string", errors, newContext, false))
				obj.candidate = data.candidate;
			if (TSConverter.validateParam(data, "sdpMid", "string", errors, newContext, false))
				obj.sdpMid = data.sdpMid;
			if (TSConverter.validateParam(data, "sdpMLineIndex", "number", errors, newContext, false))
				obj.sdpMLineIndex = data.sdpMLineIndex;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallMessageICE"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVConnectArgument
export class AsnAVConnectArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVConnectArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVConnectArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVConnectArgument", newContext);
		value += TSConverter.addStringParam("u8sCallID", obj.u8sCallID, newContext);
		value += TSConverter.addStringParam("endpointIDTo", obj.endpointIDTo, newContext);
		value += TSConverter.addStringParam("connectedEndPointID", obj.connectedEndPointID, newContext);
		value += TSConverter.addNumberParam("callType", obj.callType, newContext);
		value += TSConverter.addStringParam("u8sUriTo", obj.u8sUriTo, newContext);
		value += TSConverter.addStringParam("u8sContentType", obj.u8sContentType, newContext);
		value += TSConverter.addStringParam("u8sBody", obj.u8sBody, newContext);
		value += TSConverter.addStringParam("u8sCaps", obj.u8sCaps, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVConnectArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVConnectArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVConnectArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVConnectArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVConnectArgument
			if (TSConverter.validateParam(data, "u8sCallID", "string", errors, newContext, false))
				obj.u8sCallID = data.u8sCallID;
			if (TSConverter.validateParam(data, "endpointIDTo", "string", errors, newContext, false))
				obj.endpointIDTo = data.endpointIDTo;
			if (TSConverter.validateParam(data, "connectedEndPointID", "string", errors, newContext, false))
				obj.connectedEndPointID = data.connectedEndPointID;
			if (TSConverter.validateParam(data, "callType", "number", errors, newContext, false))
				obj.callType = data.callType;
			if (TSConverter.validateParam(data, "u8sUriTo", "string", errors, newContext, false))
				obj.u8sUriTo = data.u8sUriTo;
			if (TSConverter.validateParam(data, "u8sContentType", "string", errors, newContext, false))
				obj.u8sContentType = data.u8sContentType;
			if (TSConverter.validateParam(data, "u8sBody", "string", errors, newContext, false))
				obj.u8sBody = data.u8sBody;
			if (TSConverter.validateParam(data, "u8sCaps", "string", errors, newContext, true))
				obj.u8sCaps = data.u8sCaps;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVConnectArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVAlertArgument
export class AsnAVAlertArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVAlertArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVAlertArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVAlertArgument", newContext);
		value += TSConverter.addStringParam("u8sCallID", obj.u8sCallID, newContext);
		value += TSConverter.addStringParam("endpointIDTo", obj.endpointIDTo, newContext);
		value += TSConverter.addStringParam("endpointIDFrom", obj.endpointIDFrom, newContext);
		value += TSConverter.addStringParam("u8sUriTo", obj.u8sUriTo, newContext);
		value += TSConverter.addStringParam("u8sContentType", obj.u8sContentType, newContext);
		value += TSConverter.addStringParam("u8sBody", obj.u8sBody, newContext);
		value += TSConverter.addStringParam("u8sCaps", obj.u8sCaps, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVAlertArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVAlertArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVAlertArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVAlertArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVAlertArgument
			if (TSConverter.validateParam(data, "u8sCallID", "string", errors, newContext, false))
				obj.u8sCallID = data.u8sCallID;
			if (TSConverter.validateParam(data, "endpointIDTo", "string", errors, newContext, false))
				obj.endpointIDTo = data.endpointIDTo;
			if (TSConverter.validateParam(data, "endpointIDFrom", "string", errors, newContext, false))
				obj.endpointIDFrom = data.endpointIDFrom;
			if (TSConverter.validateParam(data, "u8sUriTo", "string", errors, newContext, false))
				obj.u8sUriTo = data.u8sUriTo;
			if (TSConverter.validateParam(data, "u8sContentType", "string", errors, newContext, false))
				obj.u8sContentType = data.u8sContentType;
			if (TSConverter.validateParam(data, "u8sBody", "string", errors, newContext, false))
				obj.u8sBody = data.u8sBody;
			if (TSConverter.validateParam(data, "u8sCaps", "string", errors, newContext, true))
				obj.u8sCaps = data.u8sCaps;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVAlertArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestSTUNandTURNArgument
export class AsnRequestSTUNandTURNArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnRequestSTUNandTURNArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestSTUNandTURNArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestSTUNandTURNArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnRequestSTUNandTURNArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestSTUNandTURNArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestSTUNandTURNArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnRequestSTUNandTURNArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestSTUNandTURNArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestSTUNandTURNArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetSTUNandTURNArgument
export class AsnGetSTUNandTURNArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnGetSTUNandTURNArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetSTUNandTURNArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetSTUNandTURNArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnGetSTUNandTURNArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetSTUNandTURNArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSTUNandTURNArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnGetSTUNandTURNArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetSTUNandTURNArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSTUNandTURNArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetSTUNandTURNResult
export class AsnGetSTUNandTURNResult_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnGetSTUNandTURNResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetSTUNandTURNResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetSTUNandTURNResult", newContext);
		value += TSConverter.addObjectAsStringParam("listConfigSTUNandTURN", ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toJSON(obj.listConfigSTUNandTURN, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnGetSTUNandTURNResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetSTUNandTURNResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSTUNandTURNResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnGetSTUNandTURNResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetSTUNandTURNResult
			const tscv_listconfigstunandturn = new ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList();
			if (ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromJSON(data.listConfigSTUNandTURN, tscv_listconfigstunandturn, errors, newContext, "listConfigSTUNandTURN", false))
				obj.listConfigSTUNandTURN = tscv_listconfigstunandturn;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetSTUNandTURNResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSTUNandTURNConfigChangedArgument
export class AsnSTUNandTURNConfigChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnSTUNandTURNConfigChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSTUNandTURNConfigChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSTUNandTURNConfigChangedArgument", newContext);
		value += TSConverter.addObjectAsStringParam("listConfigSTUNandTURN", ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toJSON(obj.listConfigSTUNandTURN, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnSTUNandTURNConfigChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSTUNandTURNConfigChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSTUNandTURNConfigChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnSTUNandTURNConfigChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSTUNandTURNConfigChangedArgument
			const tscv_listconfigstunandturn = new ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList();
			if (ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromJSON(data.listConfigSTUNandTURN, tscv_listconfigstunandturn, errors, newContext, "listConfigSTUNandTURN", false))
				obj.listConfigSTUNandTURN = tscv_listconfigstunandturn;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSTUNandTURNConfigChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnIceServer
export class AsnIceServer_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnIceServer, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnIceServer
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnIceServer", newContext);
		value += TSConverter.addStringParam("username", obj.username, newContext);
		value += TSConverter.addStringParam("credential", obj.credential, newContext);
		value += TSConverter.addObjectAsStringParam("urls", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.urls, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnIceServer, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnIceServer");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnIceServer"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnIceServer;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnIceServer
			if (TSConverter.validateParam(data, "username", "string", errors, newContext, true))
				obj.username = data.username;
			if (TSConverter.validateParam(data, "credential", "string", errors, newContext, true))
				obj.credential = data.credential;
			const tscv_urls = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.urls, tscv_urls, errors, newContext, "urls", false))
				obj.urls = tscv_urls;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnIceServer"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVGetIceServersArgument
export class AsnAVGetIceServersArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVGetIceServersArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVGetIceServersArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVGetIceServersArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVGetIceServersArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVGetIceServersArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVGetIceServersArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVGetIceServersArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVGetIceServersArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVGetIceServersArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnConfigCodec
export class AsnConfigCodec_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnConfigCodec, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnConfigCodec
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnConfigCodec", newContext);
		value += TSConverter.addNumberParam("iType", obj.iType, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnConfigCodec, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnConfigCodec");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigCodec"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnConfigCodec;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnConfigCodec
			if (TSConverter.validateParam(data, "iType", "number", errors, newContext, false))
				obj.iType = data.iType;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigCodec"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVMediaChangeArgument
export class AsnAVMediaChangeArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVMediaChangeArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVMediaChangeArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVMediaChangeArgument", newContext);
		value += TSConverter.addStringParam("u8sCallID", obj.u8sCallID, newContext);
		value += TSConverter.addStringParam("endpointIDTo", obj.endpointIDTo, newContext);
		value += TSConverter.addStringParam("connectedEndPointID", obj.connectedEndPointID, newContext);
		value += TSConverter.addNumberParam("iMediaType", obj.iMediaType, newContext);
		value += TSConverter.addStringParam("u8sUriFrom", obj.u8sUriFrom, newContext);
		value += TSConverter.addStringParam("u8sUriTo", obj.u8sUriTo, newContext);
		value += TSConverter.addStringParam("u8sContentType", obj.u8sContentType, newContext);
		value += TSConverter.addBinaryParam("u8sBody", obj.u8sBody, newContext);
		value += TSConverter.addStringParam("u8sMediaID", obj.u8sMediaID, newContext);
		value += TSConverter.addNumberParam("iAvFlags", obj.iAvFlags, newContext);
		value += TSConverter.addNumberParam("iAvDropReason", obj.iAvDropReason, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVMediaChangeArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVMediaChangeArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVMediaChangeArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVMediaChangeArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVMediaChangeArgument
			if (TSConverter.validateParam(data, "u8sCallID", "string", errors, newContext, false))
				obj.u8sCallID = data.u8sCallID;
			if (TSConverter.validateParam(data, "endpointIDTo", "string", errors, newContext, false))
				obj.endpointIDTo = data.endpointIDTo;
			if (TSConverter.validateParam(data, "connectedEndPointID", "string", errors, newContext, false))
				obj.connectedEndPointID = data.connectedEndPointID;
			if (TSConverter.validateParam(data, "iMediaType", "number", errors, newContext, false))
				obj.iMediaType = data.iMediaType;
			if (TSConverter.validateParam(data, "u8sUriFrom", "string", errors, newContext, false))
				obj.u8sUriFrom = data.u8sUriFrom;
			if (TSConverter.validateParam(data, "u8sUriTo", "string", errors, newContext, false))
				obj.u8sUriTo = data.u8sUriTo;
			if (TSConverter.validateParam(data, "u8sContentType", "string", errors, newContext, false))
				obj.u8sContentType = data.u8sContentType;
			if (TSConverter.validateParam(data, "u8sBody", "string", errors, newContext, false))
				obj.u8sBody = TSConverter.decode64(data.u8sBody.toString());
			if (TSConverter.validateParam(data, "u8sMediaID", "string", errors, newContext, false))
				obj.u8sMediaID = data.u8sMediaID;
			if (TSConverter.validateParam(data, "iAvFlags", "number", errors, newContext, false))
				obj.iAvFlags = data.iAvFlags;
			if (TSConverter.validateParam(data, "iAvDropReason", "number", errors, newContext, false))
				obj.iAvDropReason = data.iAvDropReason;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVMediaChangeArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVTransferArgument
export class AsnAVTransferArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVTransferArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVTransferArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVTransferArgument", newContext);
		value += TSConverter.addStringParam("u8sCallID", obj.u8sCallID, newContext);
		value += TSConverter.addStringParam("endpointIDTo", obj.endpointIDTo, newContext);
		value += TSConverter.addStringParam("endpointIDFrom", obj.endpointIDFrom, newContext);
		value += TSConverter.addNumberParam("iTransferResult", obj.iTransferResult, newContext);
		value += TSConverter.addStringParam("u8sUriTransferTo", obj.u8sUriTransferTo, newContext);
		value += TSConverter.addStringParam("u8sUriTransferFrom", obj.u8sUriTransferFrom, newContext);
		value += TSConverter.addStringParam("u8sTransferTargetID", obj.u8sTransferTargetID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVTransferArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVTransferArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVTransferArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVTransferArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVTransferArgument
			if (TSConverter.validateParam(data, "u8sCallID", "string", errors, newContext, false))
				obj.u8sCallID = data.u8sCallID;
			if (TSConverter.validateParam(data, "endpointIDTo", "string", errors, newContext, false))
				obj.endpointIDTo = data.endpointIDTo;
			if (TSConverter.validateParam(data, "endpointIDFrom", "string", errors, newContext, false))
				obj.endpointIDFrom = data.endpointIDFrom;
			if (TSConverter.validateParam(data, "iTransferResult", "number", errors, newContext, false))
				obj.iTransferResult = data.iTransferResult;
			if (TSConverter.validateParam(data, "u8sUriTransferTo", "string", errors, newContext, false))
				obj.u8sUriTransferTo = data.u8sUriTransferTo;
			if (TSConverter.validateParam(data, "u8sUriTransferFrom", "string", errors, newContext, false))
				obj.u8sUriTransferFrom = data.u8sUriTransferFrom;
			if (TSConverter.validateParam(data, "u8sTransferTargetID", "string", errors, newContext, true))
				obj.u8sTransferTargetID = data.u8sTransferTargetID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVTransferArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVCallOrConnection
export class AsnAVCallOrConnection_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVCallOrConnection, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderChoiceDefCode] AsnAVCallOrConnection
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVCallOrConnection", newContext);
		if (obj.u8sCallID != null)
			value += TSConverter.addStringParam("u8sCallID", obj.u8sCallID, newContext);
		else if (obj.connectionID != null)
			value += TSConverter.addObjectAsStringParam("connectionID", AsnAVCallAndConnectionID_Converter.toJSON(obj.connectionID, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVCallOrConnection, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVCallOrConnection");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallOrConnection"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVCallOrConnection;
		if (data !== undefined) {
			// [PrintTSDecoderChoiceDefCode] AsnAVCallOrConnection
			newContext.bLaxParsing = false;
			if (TSConverter.validateChoiceParam(data, "u8sCallID", "string", undefined, newContext))
				obj.u8sCallID = data.u8sCallID;
			else {
				const tscv_connectionid = ENetUC_AV.AsnAVCallAndConnectionID.initEmpty();
				if (AsnAVCallAndConnectionID_Converter.fromJSON(data.connectionID, tscv_connectionid, undefined, newContext, "connectionid", optional))
					obj.connectionID = tscv_connectionid;
				else if (!(optional === true))
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallOrConnection"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVMakeCallResult
export class AsnAVMakeCallResult_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVMakeCallResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVMakeCallResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVMakeCallResult", newContext);
		value += TSConverter.addObjectAsStringParam("callAndConnectionID", AsnAVCallAndConnectionID_Converter.toJSON(obj.callAndConnectionID, newContext), newContext);
		value += TSConverter.addStringParam("u8sCTIConnectionID", obj.u8sCTIConnectionID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVMakeCallResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVMakeCallResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVMakeCallResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVMakeCallResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVMakeCallResult
			const tscv_callandconnectionid = ENetUC_AV.AsnAVCallAndConnectionID.initEmpty();
			if (AsnAVCallAndConnectionID_Converter.fromJSON(data.callAndConnectionID, tscv_callandconnectionid, errors, newContext, "callAndConnectionID", false))
				obj.callAndConnectionID = tscv_callandconnectionid;
			if (TSConverter.validateParam(data, "u8sCTIConnectionID", "string", errors, newContext, false))
				obj.u8sCTIConnectionID = data.u8sCTIConnectionID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVMakeCallResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVNewCallArgument
export class AsnAVNewCallArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVNewCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVNewCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVNewCallArgument", newContext);
		value += TSConverter.addObjectAsStringParam("callAndConnectionID", AsnAVCallAndConnectionID_Converter.toJSON(obj.callAndConnectionID, newContext), newContext);
		value += TSConverter.addStringParam("u8sCTIConnectionID", obj.u8sCTIConnectionID, newContext);
		value += TSConverter.addNumberParam("callType", obj.callType, newContext);
		value += TSConverter.addStringParam("u8sUriFrom", obj.u8sUriFrom, newContext);
		value += TSConverter.addStringParam("endpointIDFrom", obj.endpointIDFrom, newContext);
		value += TSConverter.addStringParam("u8sUriTo", obj.u8sUriTo, newContext);
		value += TSConverter.addStringParam("u8sContentType", obj.u8sContentType, newContext);
		value += TSConverter.addStringParam("u8sBody", obj.u8sBody, newContext);
		value += TSConverter.addObjectAsStringParam("newCallParams", ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.toJSON(obj.newCallParams, newContext), newContext);
		value += TSConverter.addStringParam("u8sAssociatedTextChatConversationID", obj.u8sAssociatedTextChatConversationID, newContext);
		value += TSConverter.addStringParam("u8sCaps", obj.u8sCaps, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVNewCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVNewCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVNewCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVNewCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVNewCallArgument
			const tscv_callandconnectionid = ENetUC_AV.AsnAVCallAndConnectionID.initEmpty();
			if (AsnAVCallAndConnectionID_Converter.fromJSON(data.callAndConnectionID, tscv_callandconnectionid, errors, newContext, "callAndConnectionID", false))
				obj.callAndConnectionID = tscv_callandconnectionid;
			if (TSConverter.validateParam(data, "u8sCTIConnectionID", "string", errors, newContext, false))
				obj.u8sCTIConnectionID = data.u8sCTIConnectionID;
			if (TSConverter.validateParam(data, "callType", "number", errors, newContext, false))
				obj.callType = data.callType;
			if (TSConverter.validateParam(data, "u8sUriFrom", "string", errors, newContext, false))
				obj.u8sUriFrom = data.u8sUriFrom;
			if (TSConverter.validateParam(data, "endpointIDFrom", "string", errors, newContext, false))
				obj.endpointIDFrom = data.endpointIDFrom;
			if (TSConverter.validateParam(data, "u8sUriTo", "string", errors, newContext, false))
				obj.u8sUriTo = data.u8sUriTo;
			if (TSConverter.validateParam(data, "u8sContentType", "string", errors, newContext, false))
				obj.u8sContentType = data.u8sContentType;
			if (TSConverter.validateParam(data, "u8sBody", "string", errors, newContext, false))
				obj.u8sBody = data.u8sBody;
			const tscv_newcallparams = ENetUC_Common_SIPCTI.AsnCtiNewCallParams.initEmpty();
			if (ENetUC_Common_SIPCTI_Converter.AsnCtiNewCallParams_Converter.fromJSON(data.newCallParams, tscv_newcallparams, errors, newContext, "newCallParams", true))
				obj.newCallParams = tscv_newcallparams;
			if (TSConverter.validateParam(data, "u8sAssociatedTextChatConversationID", "string", errors, newContext, true))
				obj.u8sAssociatedTextChatConversationID = data.u8sAssociatedTextChatConversationID;
			if (TSConverter.validateParam(data, "u8sCaps", "string", errors, newContext, true))
				obj.u8sCaps = data.u8sCaps;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVNewCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVCallPartyList
export class AsnAVCallPartyList_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVCallPartyList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnAVCallPartyList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnAVCallParty_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVCallPartyList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVCallPartyList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallPartyList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVCallPartyList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnAVCallPartyList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_AV.AsnAVCallParty.initEmpty();
				if (AsnAVCallParty_Converter.fromJSON(elem, entry, errors, newContext, "AsnAVCallParty", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallPartyList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVCallStateChangedArgument
export class AsnAVCallStateChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVCallStateChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVCallStateChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVCallStateChangedArgument", newContext);
		value += TSConverter.addStringParam("u8sCallID", obj.u8sCallID, newContext);
		value += TSConverter.addStringParam("endpointIDTo", obj.endpointIDTo, newContext);
		value += TSConverter.addStringParam("endpointIDFrom", obj.endpointIDFrom, newContext);
		value += TSConverter.addNumberParam("callState", obj.callState, newContext);
		value += TSConverter.addStringParam("u8sUriFrom", obj.u8sUriFrom, newContext);
		value += TSConverter.addStringParam("u8sUriTo", obj.u8sUriTo, newContext);
		value += TSConverter.addObjectAsStringParam("parties", AsnAVCallPartyList_Converter.toJSON(obj.parties, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVCallStateChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVCallStateChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallStateChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVCallStateChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVCallStateChangedArgument
			if (TSConverter.validateParam(data, "u8sCallID", "string", errors, newContext, false))
				obj.u8sCallID = data.u8sCallID;
			if (TSConverter.validateParam(data, "endpointIDTo", "string", errors, newContext, false))
				obj.endpointIDTo = data.endpointIDTo;
			if (TSConverter.validateParam(data, "endpointIDFrom", "string", errors, newContext, false))
				obj.endpointIDFrom = data.endpointIDFrom;
			if (TSConverter.validateParam(data, "callState", "number", errors, newContext, false))
				obj.callState = data.callState;
			if (TSConverter.validateParam(data, "u8sUriFrom", "string", errors, newContext, false))
				obj.u8sUriFrom = data.u8sUriFrom;
			if (TSConverter.validateParam(data, "u8sUriTo", "string", errors, newContext, false))
				obj.u8sUriTo = data.u8sUriTo;
			const tscv_parties = new ENetUC_AV.AsnAVCallPartyList();
			if (AsnAVCallPartyList_Converter.fromJSON(data.parties, tscv_parties, errors, newContext, "parties", false))
				obj.parties = tscv_parties;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallStateChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVCallMessageArgument
export class AsnAVCallMessageArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVCallMessageArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVCallMessageArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVCallMessageArgument", newContext);
		value += TSConverter.addStringParam("u8sCallID", obj.u8sCallID, newContext);
		value += TSConverter.addStringParam("endpointIDTo", obj.endpointIDTo, newContext);
		value += TSConverter.addStringParam("endpointIDFrom", obj.endpointIDFrom, newContext);
		value += TSConverter.addNumberParam("messageType", obj.messageType, newContext);
		value += TSConverter.addStringParam("u8sUriTo", obj.u8sUriTo, newContext);
		value += TSConverter.addStringParam("u8sContentType", obj.u8sContentType, newContext);
		value += TSConverter.addStringParam("u8sBody", obj.u8sBody, newContext);
		value += TSConverter.addObjectAsStringParam("iceCandidate", AsnAVCallMessageICE_Converter.toJSON(obj.iceCandidate, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVCallMessageArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVCallMessageArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallMessageArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVCallMessageArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVCallMessageArgument
			if (TSConverter.validateParam(data, "u8sCallID", "string", errors, newContext, false))
				obj.u8sCallID = data.u8sCallID;
			if (TSConverter.validateParam(data, "endpointIDTo", "string", errors, newContext, false))
				obj.endpointIDTo = data.endpointIDTo;
			if (TSConverter.validateParam(data, "endpointIDFrom", "string", errors, newContext, false))
				obj.endpointIDFrom = data.endpointIDFrom;
			if (TSConverter.validateParam(data, "messageType", "number", errors, newContext, false))
				obj.messageType = data.messageType;
			if (TSConverter.validateParam(data, "u8sUriTo", "string", errors, newContext, false))
				obj.u8sUriTo = data.u8sUriTo;
			if (TSConverter.validateParam(data, "u8sContentType", "string", errors, newContext, true))
				obj.u8sContentType = data.u8sContentType;
			if (TSConverter.validateParam(data, "u8sBody", "string", errors, newContext, true))
				obj.u8sBody = data.u8sBody;
			const tscv_icecandidate = ENetUC_AV.AsnAVCallMessageICE.initEmpty();
			if (AsnAVCallMessageICE_Converter.fromJSON(data.iceCandidate, tscv_icecandidate, errors, newContext, "iceCandidate", true))
				obj.iceCandidate = tscv_icecandidate;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVCallMessageArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVDropCallArgument
export class AsnAVDropCallArgument_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVDropCallArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVDropCallArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVDropCallArgument", newContext);
		value += TSConverter.addObjectAsStringParam("dropTarget", AsnAVCallOrConnection_Converter.toJSON(obj.dropTarget, newContext), newContext);
		value += TSConverter.addStringParam("u8sCause", obj.u8sCause, newContext);
		value += TSConverter.addNumberParam("iAvDropReason", obj.iAvDropReason, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVDropCallArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVDropCallArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVDropCallArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVDropCallArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVDropCallArgument
			const tscv_droptarget = ENetUC_AV.AsnAVCallOrConnection.initEmpty();
			if (AsnAVCallOrConnection_Converter.fromJSON(data.dropTarget, tscv_droptarget, errors, newContext, "dropTarget", false))
				obj.dropTarget = tscv_droptarget;
			if (TSConverter.validateParam(data, "u8sCause", "string", errors, newContext, false))
				obj.u8sCause = data.u8sCause;
			if (TSConverter.validateParam(data, "iAvDropReason", "number", errors, newContext, false))
				obj.iAvDropReason = data.iAvDropReason;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVDropCallArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnIceServerList
export class AsnIceServerList_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnIceServerList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnIceServerList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnIceServer_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnIceServerList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnIceServerList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnIceServerList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnIceServerList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnIceServerList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_AV.AsnIceServer.initEmpty();
				if (AsnIceServer_Converter.fromJSON(elem, entry, errors, newContext, "AsnIceServer", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnIceServerList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAVGetIceServersResult
export class AsnAVGetIceServersResult_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnAVGetIceServersResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAVGetIceServersResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAVGetIceServersResult", newContext);
		value += TSConverter.addObjectAsStringParam("iceServers", AsnIceServerList_Converter.toJSON(obj.iceServers, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnAVGetIceServersResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAVGetIceServersResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAVGetIceServersResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnAVGetIceServersResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAVGetIceServersResult
			const tscv_iceservers = new ENetUC_AV.AsnIceServerList();
			if (AsnIceServerList_Converter.fromJSON(data.iceServers, tscv_iceservers, errors, newContext, "iceServers", false))
				obj.iceServers = tscv_iceservers;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAVGetIceServersResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnConfigCodecList
export class AsnConfigCodecList_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnConfigCodecList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnConfigCodecList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnConfigCodec_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnConfigCodecList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnConfigCodecList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigCodecList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnConfigCodecList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnConfigCodecList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_AV.AsnConfigCodec.initEmpty();
				if (AsnConfigCodec_Converter.fromJSON(elem, entry, errors, newContext, "AsnConfigCodec", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigCodecList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnConfigPorts
export class AsnConfigPorts_Converter {
	public static toJSON(obj?: ENetUC_AV.AsnConfigPorts, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnConfigPorts
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnConfigPorts", newContext);
		value += TSConverter.addNumberParam("minPort", obj.minPort, newContext);
		value += TSConverter.addNumberParam("maxPort", obj.maxPort, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_AV.AsnConfigPorts, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnConfigPorts");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigPorts"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_AV.AsnConfigPorts;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnConfigPorts
			if (TSConverter.validateParam(data, "minPort", "number", errors, newContext, false))
				obj.minPort = data.minPort;
			if (TSConverter.validateParam(data, "maxPort", "number", errors, newContext, false))
				obj.maxPort = data.maxPort;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnConfigPorts"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
