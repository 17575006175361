import { TSConverter, FromJSONContext, ToJSONContext, ParsingErrors, ParsingError, ParsingErrorType } from "./TSConverterBase";
import * as ENetUC_Common from "./ENetUC_Common";

/* eslint-disable @typescript-eslint/naming-convention */

/**
 * Sadly someone added custom written encoders decoders for the custom parameters in the UCServer
 * So we cannot use the asn1 stub definitions for them, we need to encode decode them in the same notation
 * This is how an optional parameter looks like the UCServer is aware of
 */
type IUCServerOptionalParam = number | string | { binarydata: string };

/**
 * Sadly someone added custom written encoders decoders for the custom parameters in the UCServer
 * So we cannot use the asn1 stub definitions for them, we need to encode decode them in the same notation
 * This is how the array of optional parameters in asn1 is encoded into, or decoded from a json object
 */
type IUCServerOptionalParameters = Record<string, IUCServerOptionalParam>

/**
 * Helper class that contains the code to do the custom conversion the UCServer is doing for the AsnOptionalParameters
 */
export class EAsnOptionalParameters_Converter {
	/**
	 * Encodes an AsnOptionalParameters object to JSON string encoding in the proprietary Notation the UCServer is using
	 *
	 * @param obj - the optional parameters to encode
	 * @param context - the context for the encoding
	 * @returns the encoded object
	 */
	public static toJSON(obj?: ENetUC_Common.AsnOptionalParameters, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		if (!context?.bUCServerOptionalParams)
			debugger;

		const result: Record<string, IUCServerOptionalParam> = {};
		if (context?.bAddTypes)
			result["_type"] = "IUCServerOptionalParameters";

		for (const id in obj) {
			if (!Object.prototype.hasOwnProperty.call(obj, id))
				continue;
			const element = obj[id];
			if (element) {
				if (element.value.integerdata !== undefined)
					result[element.key] = element.value.integerdata;
				else if (element.value.stringdata !== undefined)
					result[element.key] = element.value.stringdata;
				else if (element.value.binarydata !== undefined)
					result[element.key] = { binarydata: TSConverter.encode64(element.value.binarydata) };
				else
					debugger;
			}
		}

		return JSON.stringify(result, null, context?.bPrettyPrint ? "\t" : undefined);
	}

	/**
	 * Encodes an AsnOptionalParam from the dataarg that holds the params in the proprietary Notation the UCServer is using
	 *
	 * @param dataarg - the argument to decode
	 * @param obj - the object that is returned
	 * @param errors - errors that occured while parsing
	 * @param context - the decoding context
	 * @param parametername - the parent parameter this object is held in
	 * @param optional - true if this is an optional object (no error on not existing)
	 * @returns true on success
	 */
	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Common.AsnOptionalParameters, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnOptionalParameters");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnOptionalParameters"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as IUCServerOptionalParameters;
		if (data) {
			for (const key in data) {
				if (!Object.prototype.hasOwnProperty.call(data, key))
					continue;
				if (key === "_type")
					continue;
				const value = data[key];
				if (typeof value === "number")
					obj.push(new ENetUC_Common.AsnOptionalParam({ key, value: { integerdata: value } }));
				else if (typeof value === "string")
					obj.push(new ENetUC_Common.AsnOptionalParam({ key, value: { stringdata: value } }));
				else if (value?.binarydata)
					obj.push(new ENetUC_Common.AsnOptionalParam({ key, value: { binarydata: TSConverter.decode64(value.binarydata) } }));
				else
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_TYPEMISMATCH, newContext.context, `Error while parsing AsnOptionalParameter ${key}`));
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnOptionalParameters"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
