import { AsnAgentStateEnum } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Agent";
import { AsnReachabilityEnum } from "../../web-shared-components/asn1/EUCSrv/stubs/ENetUC_Common_AsnContact";
import i18n from "../i18n/i18n";
import { DetailTypeE, IConfigBlock } from "../models/ModelConfig";
import { ICombiBlock, ICombiDetail, ICombiPic, IContact, WindowTypeT } from "../models/ModelContact";
import { IContentDetails, IContentPic, IDynamicStatusInfosCD, IKeyValuePair } from "../models/ModelContent";
import { AsnPresenceStateFlags } from "../ucserver/stubs/ENetROSEInterface";

/**
 * Compares configuration and incoming contact details
 * and creates an appropriately customized Contact object
 * with all available and desired informations.
 *
 * @param contentDetails - incoming content details for the contact
 * @param customLabels - incoming customer labels as array of IKeyValuePair
 * @param configCD - incoming configuration for the display of the values
 * @param contactType - type of contact
 * @returns a IContact object.
 */
export function ConstrContactFromModel(
	contentDetails: IContentDetails,
	customLabels: IKeyValuePair[],
	configCD: IConfigBlock[],
	contactType: WindowTypeT
): IContact {
	const contentPic: IContentPic = contentDetails.contentPic;
	const u8sDetailsContent: Map<string, string> = contentDetails.u8sDetails;
	const customFieldsContent: IKeyValuePair[] = contentDetails.customFields;
	const contactURLsContent: IKeyValuePair[] = contentDetails.contactURLs;
	const dynamicStatusInfo: IDynamicStatusInfosCD | null = contentDetails.statusInfos;

	const getAdditionalStatusInfo = (
		iPresenceState: number | null | undefined,
		statusNote: string | null | undefined
	): string | null | undefined => {
		const contact_state_call = 0x04000000;
		const contact_state_conferencing = 0x00020000;

		let statusText = statusNote !== undefined ? statusNote : "";

		if (iPresenceState) {
			if (contact_state_call & iPresenceState) {
				statusText = "IDS_CALL_CONNECTED";
			} else if (contact_state_conferencing & iPresenceState) {
				statusText = "IDS_HAS_MEETING";
			}
		}
		return statusText;
	};

	const getMobileAvailability = (iPresenceState: number | null | undefined): boolean => {
		if (!iPresenceState) {
			return false;
		}

		if (iPresenceState & AsnPresenceStateFlags.eMOBILEAVAILABLITY) {
			return true;
		}

		return false;
	};

	const statusInfos: IDynamicStatusInfosCD = {
		presence: dynamicStatusInfo && dynamicStatusInfo.presence !== undefined ? dynamicStatusInfo.presence : 0,
		isMobileAvailable: getMobileAvailability(dynamicStatusInfo?.presence),
		reachability: dynamicStatusInfo?.reachability ?? AsnReachabilityEnum.eREACHABILITYNORMAL,
		agentState: dynamicStatusInfo?.agentState ?? AsnAgentStateEnum.eAGENTSTATENOAGENT,
		u8sNote: dynamicStatusInfo ? getAdditionalStatusInfo(dynamicStatusInfo.presence, dynamicStatusInfo.u8sNote) : "",
		remoteContact: dynamicStatusInfo && dynamicStatusInfo.remoteContact ? dynamicStatusInfo.remoteContact : null,
		nextAppointment: dynamicStatusInfo?.nextAppointment,
		currentAppointments: dynamicStatusInfo?.currentAppointments,
		absentState: dynamicStatusInfo?.absentState,
		msTeamsEmail: dynamicStatusInfo?.msTeamsEmail,
		clientCapabilities: dynamicStatusInfo?.clientCapabilities,
		seqCalls: dynamicStatusInfo?.seqCalls,
		seqPhoneLines: dynamicStatusInfo?.seqPhoneLines,
		seqLineForwards: dynamicStatusInfo?.seqLineForwards
	};

	const custLabelsMap: Map<string, string> = new Map<string, string>();
	if (customLabels) {
		for (let i = 0; i < customLabels.length; i++) {
			custLabelsMap.set(customLabels[i].key, customLabels[i].value);
		}
	}

	const fnGetDisplayLabel = (local: string, name: string, title: string): string => {
		const custLabel: string | undefined = custLabelsMap.get(name);

		const displayLabel: string =
			custLabel !== undefined && custLabel !== null && custLabel !== ""
				? custLabel
				: title !== "" && title !== null
				? title
				: local;

		return displayLabel;
	};

	const fnGetAppointment = (): string => {
		const language = i18n.language;

		const appointment = statusInfos.nextAppointment
			? statusInfos.nextAppointment
			: { u8sSubject: "", stStart: "", stEnd: "" };
		const startDate: Date = new Date(appointment.stStart);
		const endDate: Date = new Date(appointment.stEnd);
		const optionsDate: Intl.DateTimeFormatOptions | undefined = {
			weekday: "long",
			year: "numeric",
			month: "numeric",
			day: "numeric"
		};
		const optionsTime: Intl.DateTimeFormatOptions | undefined = {
			hour: "numeric",
			minute: "numeric"
		};

		const dateStart: string = startDate.toLocaleDateString(language, optionsDate);
		const timeStart: string = startDate.toLocaleTimeString(language, optionsTime);
		const dateEnd: string =
			endDate.toLocaleDateString(language, optionsDate) !== startDate.toLocaleDateString(language, optionsDate)
				? endDate.toLocaleDateString(language, optionsDate)
				: "";
		const timeEnd: string = endDate.toLocaleTimeString(language, optionsTime);

		const appointmentAsString: string =
			appointment.u8sSubject !== "" && appointment.stStart !== "" && appointment.stEnd !== ""
				? appointment.u8sSubject + " | " + dateStart + " " + timeStart + " - " + dateEnd + " " + timeEnd
				: "";

		return appointmentAsString;
	};

	const fnGetU8sContent = (name: string): string => {
		const contentAtKey: string | undefined = name === "Appointment" ? fnGetAppointment() : u8sDetailsContent.get(name);

		const content: string = contentAtKey === undefined ? "" : contentAtKey;

		return content;
	};

	const fnGetCustomFieldContent = (name: string): IKeyValuePair => {
		let value = "";

		if (customFieldsContent) {
			customFieldsContent.filter((cf: IKeyValuePair) => {
				if (cf.key && cf.key === name) {
					value = cf.value;
				}
				return cf.key === name;
			});
		}
		const customFieldContent: IKeyValuePair = {
			key: name,
			value
		};

		return customFieldContent;
	};

	const fnGetContactURLContent = (name: string): IKeyValuePair => {
		let value = "";

		if (contactURLsContent) {
			contactURLsContent.filter((cu: IKeyValuePair) => {
				if (cu.key && cu.key === name) {
					value = cu.value;
				}
				return cu.key === name;
			});
		}

		const contactURLLink: IKeyValuePair = {
			key: name,
			value
		};

		return contactURLLink;
	};

	const fnGetContactUrlLabel = (name: string): string => {
		let label = "";
		const searchKey: string = name.substring(0, name.length - 1) + "Description" + name.charAt(name.length - 1);

		if (contactURLsContent) {
			contactURLsContent.filter((cu: IKeyValuePair) => {
				if (cu.key && cu.key === searchKey) {
					label = cu.value;
				}
				return cu.key === name;
			});
		}

		return label;
	};

	const fnGetInitials = (): string => {
		const firstName: string | undefined = u8sDetailsContent.get("u8sFirstName");
		const lastName: string | undefined = u8sDetailsContent.get("u8sLastName");
		const displayName: string | undefined = u8sDetailsContent.get("u8sDisplayName");

		let initial = "";

		const firstLetter: string = firstName === undefined ? "" : firstName.charAt(0).toUpperCase();

		const secondLetter: string = lastName === undefined ? "" : lastName.charAt(0).toUpperCase();

		const alternativLetter: string = displayName === undefined ? "" : displayName.charAt(0).toUpperCase();

		initial = firstLetter + secondLetter !== "" ? firstLetter + secondLetter : alternativLetter;

		return initial;
	};

	const fnGetCombiPic = (): ICombiPic => {
		const combiPic: ICombiPic = {
			initials: fnGetInitials(),
			jpegPhoto: contentPic.jpegPhoto ? contentPic.jpegPhoto : "",
			visible: contentPic.jpegPhoto && contentPic.jpegPhoto !== "" ? true : false
		};
		return combiPic;
	};
	const fnBuildCombiBlock = (configBlock: IConfigBlock): ICombiBlock => {
		const displayLabel: string = configBlock.visible
			? fnGetDisplayLabel(configBlock.local, configBlock.name, configBlock.title)
			: "";

		const combiDetails: ICombiDetail[] = [];

		for (const detail of configBlock.details) {
			if (detail.visible) {
				if (detail.type === DetailTypeE.ADR) {
					const addressDetails: ICombiDetail[] = [];
					if (detail.details) {
						for (const addrDetail of detail.details) {
							const content: string = fnGetU8sContent(addrDetail.name);

							if (content !== "" && content !== null && addrDetail.visible) {
								const addressDetail: ICombiDetail = {
									displayLabel: fnGetDisplayLabel(addrDetail.local, addrDetail.name, addrDetail.title),
									type: addrDetail.type,
									subtype: addrDetail.subtype,
									style: addrDetail.style ? addrDetail.style : [],
									content,
									combiDetails: null
								};

								addressDetails.push(addressDetail);
							}
						}
					}
					if (addressDetails.length > 0) {
						const combiDetail: ICombiDetail = {
							displayLabel: fnGetDisplayLabel(detail.local, detail.name, detail.title),
							type: detail.type,
							subtype: detail.subtype,
							style: detail.style ? detail.style : [],
							content: "",
							combiDetails: addressDetails
						};

						combiDetails.push(combiDetail);
					}
				} else if (detail.type === DetailTypeE.CFDTL) {
					const cfContent: IKeyValuePair = fnGetCustomFieldContent(detail.name);

					if (cfContent && cfContent.value !== "" && cfContent.value !== null) {
						const custLabel: string | undefined = custLabelsMap.get(detail.name);

						const combiDetail: ICombiDetail = {
							displayLabel:
								custLabel !== undefined && custLabel !== null && custLabel !== "" ? custLabel : cfContent.key,
							type: detail.type,
							subtype: detail.subtype,
							style: detail.style ? detail.style : [],
							content: cfContent.value,
							combiDetails: null
						};
						combiDetails.push(combiDetail);
					}
				} else if (detail.type === DetailTypeE.CUDTL) {
					const cuContent: IKeyValuePair = fnGetContactURLContent(detail.name);
					const cuLabel: string = fnGetContactUrlLabel(cuContent.key);

					if (cuContent && cuContent.value !== "") {
						const combiDetail: ICombiDetail = {
							displayLabel: cuLabel,
							type: detail.type,
							subtype: detail.subtype,
							style: detail.style ? detail.style : [],
							content: cuContent.value,
							combiDetails: null
						};
						combiDetails.push(combiDetail);
					}
				} else if (Object.values(DetailTypeE).find((type) => type === detail.type)) {
					const content: string = fnGetU8sContent(detail.name);

					if (content !== undefined && content !== "") {
						const combiDetail: ICombiDetail = {
							displayLabel: fnGetDisplayLabel(detail.local, detail.name, detail.title),
							type: detail.type,
							subtype: detail.subtype,
							style: detail.style ? detail.style : [],
							content,
							combiDetails: []
						};

						combiDetails.push(combiDetail);
					}
				}
			}
		}

		const combiBlock: ICombiBlock = {
			displayLabel,
			type: configBlock.type,
			style: configBlock.style,
			combiDetails
		};

		return combiBlock;
	};

	const fnBuildCombiBlocks = (configBlocks: IConfigBlock[]): ICombiBlock[] => {
		const CombiBlocks: ICombiBlock[] = [];

		if (configBlocks) {
			for (let i = 0; i < configBlocks.length; i++) {
				if (configBlocks[i].details && configBlocks[i].details.length > 0) {
					const CombiBlock: ICombiBlock = fnBuildCombiBlock(configBlocks[i]);

					if (CombiBlock.combiDetails.length > 0) {
						CombiBlocks.push(CombiBlock);
					}
				}
			}
		}
		return CombiBlocks;
	};

	const contact: IContact = {
		displayName: fnGetU8sContent("u8sDisplayName"),
		type: contactType,
		pict: fnGetCombiPic(),
		statusInfos,
		combiBlocks: fnBuildCombiBlocks(configCD)
	};

	return contact;
}
