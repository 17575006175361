// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_ClientContent_Converter.ts
 * "UC-Server-Access-Protocol-ClientContent" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_ClientContent from "./ENetUC_ClientContent";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";

// [PrintTSEncoderDecoderCode] AsnGetClientContentArgument
export class AsnGetClientContentArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnGetClientContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetClientContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetClientContentArgument", newContext);
		value += TSConverter.addNumberParam("iContactFilter", obj.iContactFilter, newContext);
		value += TSConverter.addObjectAsStringParam("optionsParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionsParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnGetClientContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetClientContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnGetClientContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetClientContentArgument
			if (TSConverter.validateParam(data, "iContactFilter", "number", errors, newContext, false))
				obj.iContactFilter = data.iContactFilter;
			const tscv_optionsparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionsParams, tscv_optionsparams, errors, newContext, "optionsParams", true))
				obj.optionsParams = tscv_optionsparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSubscribeClientContentArgument
export class AsnSubscribeClientContentArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnSubscribeClientContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSubscribeClientContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSubscribeClientContentArgument", newContext);
		value += TSConverter.addNumberParam("iRevision", obj.iRevision, newContext);
		value += TSConverter.addNumberParam("iEventFlagsToSubscribe", obj.iEventFlagsToSubscribe, newContext);
		value += TSConverter.addObjectAsStringParam("u8sGroupClusterIDRestriction", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sGroupClusterIDRestriction, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnSubscribeClientContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSubscribeClientContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSubscribeClientContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnSubscribeClientContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSubscribeClientContentArgument
			if (TSConverter.validateParam(data, "iRevision", "number", errors, newContext, true))
				obj.iRevision = data.iRevision;
			if (TSConverter.validateParam(data, "iEventFlagsToSubscribe", "number", errors, newContext, true))
				obj.iEventFlagsToSubscribe = data.iEventFlagsToSubscribe;
			const tscv_u8sgroupclusteridrestriction = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sGroupClusterIDRestriction, tscv_u8sgroupclusteridrestriction, errors, newContext, "u8sGroupClusterIDRestriction", true))
				obj.u8sGroupClusterIDRestriction = tscv_u8sgroupclusteridrestriction;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSubscribeClientContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSubscribeClientContentResult
export class AsnSubscribeClientContentResult_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnSubscribeClientContentResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSubscribeClientContentResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSubscribeClientContentResult", newContext);
		value += TSConverter.addNumberParam("iRevision", obj.iRevision, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnSubscribeClientContentResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSubscribeClientContentResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSubscribeClientContentResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnSubscribeClientContentResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSubscribeClientContentResult
			if (TSConverter.validateParam(data, "iRevision", "number", errors, newContext, false))
				obj.iRevision = data.iRevision;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSubscribeClientContentResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUnsubscribeClientContentArgument
export class AsnUnsubscribeClientContentArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnUnsubscribeClientContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUnsubscribeClientContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUnsubscribeClientContentArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnUnsubscribeClientContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUnsubscribeClientContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribeClientContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnUnsubscribeClientContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUnsubscribeClientContentArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribeClientContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUnsubscribeClientContentResult
export class AsnUnsubscribeClientContentResult_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnUnsubscribeClientContentResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUnsubscribeClientContentResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUnsubscribeClientContentResult", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnUnsubscribeClientContentResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUnsubscribeClientContentResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribeClientContentResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnUnsubscribeClientContentResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUnsubscribeClientContentResult
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribeClientContentResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnChangeClientContentResult
export class AsnChangeClientContentResult_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnChangeClientContentResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnChangeClientContentResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnChangeClientContentResult", newContext);
		value += TSConverter.addNumberParam("iRevision", obj.iRevision, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnChangeClientContentResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnChangeClientContentResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnChangeClientContentResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnChangeClientContentResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnChangeClientContentResult
			if (TSConverter.validateParam(data, "iRevision", "number", errors, newContext, false))
				obj.iRevision = data.iRevision;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnChangeClientContentResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentItem
export class AsnClientContentItem_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentItem, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentItem
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentItem", newContext);
		value += TSConverter.addStringParam("u8sID", obj.u8sID, newContext);
		value += TSConverter.addStringParam("u8sPresenceID", obj.u8sPresenceID, newContext);
		value += TSConverter.addNumberParam("iIsEditable", obj.iIsEditable, newContext);
		value += TSConverter.addObjectAsStringParam("listNetDatabaseContactIDs", ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.toJSON(obj.listNetDatabaseContactIDs, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("selectedNetDatabaseContactID", ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toJSON(obj.selectedNetDatabaseContactID, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentItem, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentItem");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentItem"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentItem;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentItem
			if (TSConverter.validateParam(data, "u8sID", "string", errors, newContext, false))
				obj.u8sID = data.u8sID;
			if (TSConverter.validateParam(data, "u8sPresenceID", "string", errors, newContext, false))
				obj.u8sPresenceID = data.u8sPresenceID;
			if (TSConverter.validateParam(data, "iIsEditable", "number", errors, newContext, false))
				obj.iIsEditable = data.iIsEditable;
			const tscv_listnetdatabasecontactids = new ENetUC_Common.AsnNetDatabaseContactIDList();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.fromJSON(data.listNetDatabaseContactIDs, tscv_listnetdatabasecontactids, errors, newContext, "listNetDatabaseContactIDs", false))
				obj.listNetDatabaseContactIDs = tscv_listnetdatabasecontactids;
			const tscv_selectednetdatabasecontactid = ENetUC_Common.AsnNetDatabaseContactID.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromJSON(data.selectedNetDatabaseContactID, tscv_selectednetdatabasecontactid, errors, newContext, "selectedNetDatabaseContactID", false))
				obj.selectedNetDatabaseContactID = tscv_selectednetdatabasecontactid;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentItem"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentDeleted
export class AsnClientContentDeleted_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentDeleted, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentDeleted
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentDeleted", newContext);
		value += TSConverter.addStringParam("u8sType", obj.u8sType, newContext);
		value += TSConverter.addStringParam("u8sRevision", obj.u8sRevision, newContext);
		value += TSConverter.addObjectAsStringParam("contactID", ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toJSON(obj.contactID, newContext), newContext);
		value += TSConverter.addStringParam("u8sItemID", obj.u8sItemID, newContext);
		value += TSConverter.addStringParam("u8sGroupID", obj.u8sGroupID, newContext);
		value += TSConverter.addStringParam("u8sGroupclusterID", obj.u8sGroupclusterID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentDeleted, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentDeleted");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentDeleted"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentDeleted;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentDeleted
			if (TSConverter.validateParam(data, "u8sType", "string", errors, newContext, false))
				obj.u8sType = data.u8sType;
			if (TSConverter.validateParam(data, "u8sRevision", "string", errors, newContext, false))
				obj.u8sRevision = data.u8sRevision;
			const tscv_contactid = ENetUC_Common.AsnNetDatabaseContactID.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromJSON(data.contactID, tscv_contactid, errors, newContext, "contactID", true))
				obj.contactID = tscv_contactid;
			if (TSConverter.validateParam(data, "u8sItemID", "string", errors, newContext, true))
				obj.u8sItemID = data.u8sItemID;
			if (TSConverter.validateParam(data, "u8sGroupID", "string", errors, newContext, true))
				obj.u8sGroupID = data.u8sGroupID;
			if (TSConverter.validateParam(data, "u8sGroupclusterID", "string", errors, newContext, true))
				obj.u8sGroupclusterID = data.u8sGroupclusterID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentDeleted"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnStructureAttribute
export class AsnStructureAttribute_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnStructureAttribute, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnStructureAttribute
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnStructureAttribute", newContext);
		value += TSConverter.addStringParam("u8sAttributeName", obj.u8sAttributeName, newContext);
		value += TSConverter.addStringParam("u8sAttributeValue", obj.u8sAttributeValue, newContext);
		value += TSConverter.addStringParam("u8sGroupclusterID", obj.u8sGroupclusterID, newContext);
		value += TSConverter.addStringParam("u8sGroupID", obj.u8sGroupID, newContext);
		value += TSConverter.addStringParam("u8sItemID", obj.u8sItemID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnStructureAttribute, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStructureAttribute");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStructureAttribute"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnStructureAttribute;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnStructureAttribute
			if (TSConverter.validateParam(data, "u8sAttributeName", "string", errors, newContext, false))
				obj.u8sAttributeName = data.u8sAttributeName;
			if (TSConverter.validateParam(data, "u8sAttributeValue", "string", errors, newContext, true))
				obj.u8sAttributeValue = data.u8sAttributeValue;
			if (TSConverter.validateParam(data, "u8sGroupclusterID", "string", errors, newContext, false))
				obj.u8sGroupclusterID = data.u8sGroupclusterID;
			if (TSConverter.validateParam(data, "u8sGroupID", "string", errors, newContext, true))
				obj.u8sGroupID = data.u8sGroupID;
			if (TSConverter.validateParam(data, "u8sItemID", "string", errors, newContext, true))
				obj.u8sItemID = data.u8sItemID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStructureAttribute"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentSubscribeToTagsArgument
export class AsnClientContentSubscribeToTagsArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentSubscribeToTagsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentSubscribeToTagsArgument", newContext);
		value += TSConverter.addNumberParam("iSubscribe", obj.iSubscribe, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentSubscribeToTagsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentSubscribeToTagsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentSubscribeToTagsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentSubscribeToTagsArgument
			if (TSConverter.validateParam(data, "iSubscribe", "number", errors, newContext, false))
				obj.iSubscribe = data.iSubscribe;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentSubscribeToTagsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentSubscribeToTagsResult
export class AsnClientContentSubscribeToTagsResult_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentSubscribeToTagsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentSubscribeToTagsResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentSubscribeToTagsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentSubscribeToTagsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentSubscribeToTagsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentSubscribeToTagsResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentSubscribeToTagsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentTaggedIDsChangedArgument
export class AsnClientContentTaggedIDsChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentTaggedIDsChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentTaggedIDsChangedArgument", newContext);
		value += TSConverter.addObjectAsStringParam("u8sTaggedIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sTaggedIDs, newContext), newContext);
		value += TSConverter.addNumberParam("iRevision", obj.iRevision, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentTaggedIDsChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentTaggedIDsChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentTaggedIDsChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentTaggedIDsChangedArgument
			const tscv_u8staggedids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sTaggedIDs, tscv_u8staggedids, errors, newContext, "u8sTaggedIDs", false))
				obj.u8sTaggedIDs = tscv_u8staggedids;
			if (TSConverter.validateParam(data, "iRevision", "number", errors, newContext, false))
				obj.iRevision = data.iRevision;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentTaggedIDsChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentSaveTagsArgument
export class AsnClientContentSaveTagsArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentSaveTagsArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentSaveTagsArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentSaveTagsArgument", newContext);
		value += TSConverter.addObjectAsStringParam("u8sTaggedIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.u8sTaggedIDs, newContext), newContext);
		value += TSConverter.addNumberParam("iRevision", obj.iRevision, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentSaveTagsArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentSaveTagsArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentSaveTagsArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentSaveTagsArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentSaveTagsArgument
			const tscv_u8staggedids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.u8sTaggedIDs, tscv_u8staggedids, errors, newContext, "u8sTaggedIDs", false))
				obj.u8sTaggedIDs = tscv_u8staggedids;
			if (TSConverter.validateParam(data, "iRevision", "number", errors, newContext, false))
				obj.iRevision = data.iRevision;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentSaveTagsArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentSaveTagsResult
export class AsnClientContentSaveTagsResult_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentSaveTagsResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentSaveTagsResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentSaveTagsResult", newContext);
		value += TSConverter.addNumberParam("iRevision", obj.iRevision, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentSaveTagsResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentSaveTagsResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentSaveTagsResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentSaveTagsResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentSaveTagsResult
			if (TSConverter.validateParam(data, "iRevision", "number", errors, newContext, false))
				obj.iRevision = data.iRevision;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentSaveTagsResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAdministrativeFunctionKey
export class AsnAdministrativeFunctionKey_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnAdministrativeFunctionKey, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAdministrativeFunctionKey
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAdministrativeFunctionKey", newContext);
		value += TSConverter.addNumberParam("iType", obj.iType, newContext);
		value += TSConverter.addStringParam("u8sValue", obj.u8sValue, newContext);
		value += TSConverter.addStringParam("u8sName", obj.u8sName, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnAdministrativeFunctionKey, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAdministrativeFunctionKey");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeFunctionKey"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnAdministrativeFunctionKey;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAdministrativeFunctionKey
			if (TSConverter.validateParam(data, "iType", "number", errors, newContext, false))
				obj.iType = data.iType;
			if (TSConverter.validateParam(data, "u8sValue", "string", errors, newContext, false))
				obj.u8sValue = data.u8sValue;
			if (TSConverter.validateParam(data, "u8sName", "string", errors, newContext, false))
				obj.u8sName = data.u8sName;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeFunctionKey"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetAndSubscribeAdministrativeMonitorContentArgument
export class AsnGetAndSubscribeAdministrativeMonitorContentArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetAndSubscribeAdministrativeMonitorContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetAndSubscribeAdministrativeMonitorContentArgument", newContext);
		value += TSConverter.addNumberParam("iEventFlagsToSubscribe", obj.iEventFlagsToSubscribe, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetAndSubscribeAdministrativeMonitorContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAndSubscribeAdministrativeMonitorContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetAndSubscribeAdministrativeMonitorContentArgument
			if (TSConverter.validateParam(data, "iEventFlagsToSubscribe", "number", errors, newContext, true))
				obj.iEventFlagsToSubscribe = data.iEventFlagsToSubscribe;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAndSubscribeAdministrativeMonitorContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUnsubscribeAdministrativeMonitorContentArgument
export class AsnUnsubscribeAdministrativeMonitorContentArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUnsubscribeAdministrativeMonitorContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUnsubscribeAdministrativeMonitorContentArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUnsubscribeAdministrativeMonitorContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribeAdministrativeMonitorContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUnsubscribeAdministrativeMonitorContentArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribeAdministrativeMonitorContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUnsubscribeAdministrativeMonitorContentResult
export class AsnUnsubscribeAdministrativeMonitorContentResult_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUnsubscribeAdministrativeMonitorContentResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUnsubscribeAdministrativeMonitorContentResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUnsubscribeAdministrativeMonitorContentResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribeAdministrativeMonitorContentResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnUnsubscribeAdministrativeMonitorContentResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUnsubscribeAdministrativeMonitorContentResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUnsubscribeAdministrativeMonitorContentResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentItemList
export class AsnClientContentItemList_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentItemList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnClientContentItemList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnClientContentItem_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentItemList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentItemList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentItemList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentItemList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnClientContentItemList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientContent.AsnClientContentItem.initEmpty();
				if (AsnClientContentItem_Converter.fromJSON(elem, entry, errors, newContext, "AsnClientContentItem", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentItemList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnStructureAttributeList
export class AsnStructureAttributeList_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnStructureAttributeList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnStructureAttributeList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnStructureAttribute_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnStructureAttributeList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStructureAttributeList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStructureAttributeList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnStructureAttributeList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnStructureAttributeList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientContent.AsnStructureAttribute.initEmpty();
				if (AsnStructureAttribute_Converter.fromJSON(elem, entry, errors, newContext, "AsnStructureAttribute", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStructureAttributeList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAdministrativeFunctionKeyList
export class AsnAdministrativeFunctionKeyList_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnAdministrativeFunctionKeyList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnAdministrativeFunctionKeyList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnAdministrativeFunctionKey_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnAdministrativeFunctionKeyList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAdministrativeFunctionKeyList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeFunctionKeyList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnAdministrativeFunctionKeyList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnAdministrativeFunctionKeyList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientContent.AsnAdministrativeFunctionKey.initEmpty();
				if (AsnAdministrativeFunctionKey_Converter.fromJSON(elem, entry, errors, newContext, "AsnAdministrativeFunctionKey", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeFunctionKeyList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentGroup
export class AsnClientContentGroup_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentGroup, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentGroup
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentGroup", newContext);
		value += TSConverter.addStringParam("u8sID", obj.u8sID, newContext);
		value += TSConverter.addStringParam("u8sName", obj.u8sName, newContext);
		value += TSConverter.addStringParam("u8sOrderBy", obj.u8sOrderBy, newContext);
		value += TSConverter.addNumberParam("iAutorefresh", obj.iAutorefresh, newContext);
		value += TSConverter.addNumberParam("iExpanded", obj.iExpanded, newContext);
		value += TSConverter.addNumberParam("iIsEditable", obj.iIsEditable, newContext);
		value += TSConverter.addNumberParam("iIsPersistent", obj.iIsPersistent, newContext);
		value += TSConverter.addObjectAsStringParam("listItems", AsnClientContentItemList_Converter.toJSON(obj.listItems, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentGroup, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentGroup");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentGroup"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentGroup;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentGroup
			if (TSConverter.validateParam(data, "u8sID", "string", errors, newContext, false))
				obj.u8sID = data.u8sID;
			if (TSConverter.validateParam(data, "u8sName", "string", errors, newContext, false))
				obj.u8sName = data.u8sName;
			if (TSConverter.validateParam(data, "u8sOrderBy", "string", errors, newContext, false))
				obj.u8sOrderBy = data.u8sOrderBy;
			if (TSConverter.validateParam(data, "iAutorefresh", "number", errors, newContext, false))
				obj.iAutorefresh = data.iAutorefresh;
			if (TSConverter.validateParam(data, "iExpanded", "number", errors, newContext, false))
				obj.iExpanded = data.iExpanded;
			if (TSConverter.validateParam(data, "iIsEditable", "number", errors, newContext, false))
				obj.iIsEditable = data.iIsEditable;
			if (TSConverter.validateParam(data, "iIsPersistent", "number", errors, newContext, false))
				obj.iIsPersistent = data.iIsPersistent;
			const tscv_listitems = new ENetUC_ClientContent.AsnClientContentItemList();
			if (AsnClientContentItemList_Converter.fromJSON(data.listItems, tscv_listitems, errors, newContext, "listItems", false))
				obj.listItems = tscv_listitems;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentGroup"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentGroupList
export class AsnClientContentGroupList_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentGroupList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnClientContentGroupList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnClientContentGroup_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentGroupList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentGroupList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentGroupList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentGroupList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnClientContentGroupList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientContent.AsnClientContentGroup.initEmpty();
				if (AsnClientContentGroup_Converter.fromJSON(elem, entry, errors, newContext, "AsnClientContentGroup", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentGroupList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAdministrativeMonitorGroup
export class AsnAdministrativeMonitorGroup_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnAdministrativeMonitorGroup, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAdministrativeMonitorGroup
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAdministrativeMonitorGroup", newContext);
		value += TSConverter.addStringParam("u8sGroupURI", obj.u8sGroupURI, newContext);
		value += TSConverter.addStringParam("u8sGroupName", obj.u8sGroupName, newContext);
		value += TSConverter.addObjectAsStringParam("listContactIDs", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.listContactIDs, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("listFunctionKeys", AsnAdministrativeFunctionKeyList_Converter.toJSON(obj.listFunctionKeys, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnAdministrativeMonitorGroup, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAdministrativeMonitorGroup");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorGroup"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnAdministrativeMonitorGroup;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAdministrativeMonitorGroup
			if (TSConverter.validateParam(data, "u8sGroupURI", "string", errors, newContext, false))
				obj.u8sGroupURI = data.u8sGroupURI;
			if (TSConverter.validateParam(data, "u8sGroupName", "string", errors, newContext, false))
				obj.u8sGroupName = data.u8sGroupName;
			const tscv_listcontactids = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.listContactIDs, tscv_listcontactids, errors, newContext, "listContactIDs", false))
				obj.listContactIDs = tscv_listcontactids;
			const tscv_listfunctionkeys = new ENetUC_ClientContent.AsnAdministrativeFunctionKeyList();
			if (AsnAdministrativeFunctionKeyList_Converter.fromJSON(data.listFunctionKeys, tscv_listfunctionkeys, errors, newContext, "listFunctionKeys", true))
				obj.listFunctionKeys = tscv_listfunctionkeys;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorGroup"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAdministrativeMonitorGroupList
export class AsnAdministrativeMonitorGroupList_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnAdministrativeMonitorGroupList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnAdministrativeMonitorGroupList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnAdministrativeMonitorGroup_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnAdministrativeMonitorGroupList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAdministrativeMonitorGroupList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorGroupList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnAdministrativeMonitorGroupList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnAdministrativeMonitorGroupList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientContent.AsnAdministrativeMonitorGroup.initEmpty();
				if (AsnAdministrativeMonitorGroup_Converter.fromJSON(elem, entry, errors, newContext, "AsnAdministrativeMonitorGroup", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorGroupList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetAndSubscribeAdministrativeMonitorContentResult
export class AsnGetAndSubscribeAdministrativeMonitorContentResult_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetAndSubscribeAdministrativeMonitorContentResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetAndSubscribeAdministrativeMonitorContentResult", newContext);
		value += TSConverter.addObjectAsStringParam("listAdministrativeMonitorGroups", AsnAdministrativeMonitorGroupList_Converter.toJSON(obj.listAdministrativeMonitorGroups, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetAndSubscribeAdministrativeMonitorContentResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAndSubscribeAdministrativeMonitorContentResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnGetAndSubscribeAdministrativeMonitorContentResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetAndSubscribeAdministrativeMonitorContentResult
			const tscv_listadministrativemonitorgroups = new ENetUC_ClientContent.AsnAdministrativeMonitorGroupList();
			if (AsnAdministrativeMonitorGroupList_Converter.fromJSON(data.listAdministrativeMonitorGroups, tscv_listadministrativemonitorgroups, errors, newContext, "listAdministrativeMonitorGroups", false))
				obj.listAdministrativeMonitorGroups = tscv_listadministrativemonitorgroups;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetAndSubscribeAdministrativeMonitorContentResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAdministrativeMonitorContentGroupAddedArgument
export class AsnAdministrativeMonitorContentGroupAddedArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAdministrativeMonitorContentGroupAddedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAdministrativeMonitorContentGroupAddedArgument", newContext);
		value += TSConverter.addObjectAsStringParam("adminMonitorGroupAdded", AsnAdministrativeMonitorGroup_Converter.toJSON(obj.adminMonitorGroupAdded, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAdministrativeMonitorContentGroupAddedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorContentGroupAddedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupAddedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAdministrativeMonitorContentGroupAddedArgument
			const tscv_adminmonitorgroupadded = ENetUC_ClientContent.AsnAdministrativeMonitorGroup.initEmpty();
			if (AsnAdministrativeMonitorGroup_Converter.fromJSON(data.adminMonitorGroupAdded, tscv_adminmonitorgroupadded, errors, newContext, "adminMonitorGroupAdded", false))
				obj.adminMonitorGroupAdded = tscv_adminmonitorgroupadded;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorContentGroupAddedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAdministrativeMonitorContentGroupChangedArgument
export class AsnAdministrativeMonitorContentGroupChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAdministrativeMonitorContentGroupChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAdministrativeMonitorContentGroupChangedArgument", newContext);
		value += TSConverter.addObjectAsStringParam("adminMonitorGroupChanged", AsnAdministrativeMonitorGroup_Converter.toJSON(obj.adminMonitorGroupChanged, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAdministrativeMonitorContentGroupChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorContentGroupChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAdministrativeMonitorContentGroupChangedArgument
			const tscv_adminmonitorgroupchanged = ENetUC_ClientContent.AsnAdministrativeMonitorGroup.initEmpty();
			if (AsnAdministrativeMonitorGroup_Converter.fromJSON(data.adminMonitorGroupChanged, tscv_adminmonitorgroupchanged, errors, newContext, "adminMonitorGroupChanged", false))
				obj.adminMonitorGroupChanged = tscv_adminmonitorgroupchanged;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorContentGroupChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAdministrativeMonitorContentGroupRemovedArgument
export class AsnAdministrativeMonitorContentGroupRemovedArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAdministrativeMonitorContentGroupRemovedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAdministrativeMonitorContentGroupRemovedArgument", newContext);
		value += TSConverter.addObjectAsStringParam("adminMonitorGroupRemoved", AsnAdministrativeMonitorGroup_Converter.toJSON(obj.adminMonitorGroupRemoved, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAdministrativeMonitorContentGroupRemovedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorContentGroupRemovedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnAdministrativeMonitorContentGroupRemovedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAdministrativeMonitorContentGroupRemovedArgument
			const tscv_adminmonitorgroupremoved = ENetUC_ClientContent.AsnAdministrativeMonitorGroup.initEmpty();
			if (AsnAdministrativeMonitorGroup_Converter.fromJSON(data.adminMonitorGroupRemoved, tscv_adminmonitorgroupremoved, errors, newContext, "adminMonitorGroupRemoved", false))
				obj.adminMonitorGroupRemoved = tscv_adminmonitorgroupremoved;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAdministrativeMonitorContentGroupRemovedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentGroupCluster
export class AsnClientContentGroupCluster_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentGroupCluster, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentGroupCluster
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentGroupCluster", newContext);
		value += TSConverter.addStringParam("u8sID", obj.u8sID, newContext);
		value += TSConverter.addStringParam("u8sName", obj.u8sName, newContext);
		value += TSConverter.addNumberParam("iIsEditable", obj.iIsEditable, newContext);
		value += TSConverter.addNumberParam("iIsPersistent", obj.iIsPersistent, newContext);
		value += TSConverter.addNumberParam("iIsTabbed", obj.iIsTabbed, newContext);
		value += TSConverter.addStringParam("u8sNeighbourClusterID", obj.u8sNeighbourClusterID, newContext);
		value += TSConverter.addObjectAsStringParam("listGroups", AsnClientContentGroupList_Converter.toJSON(obj.listGroups, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentGroupCluster, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentGroupCluster");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentGroupCluster"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentGroupCluster;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentGroupCluster
			if (TSConverter.validateParam(data, "u8sID", "string", errors, newContext, false))
				obj.u8sID = data.u8sID;
			if (TSConverter.validateParam(data, "u8sName", "string", errors, newContext, false))
				obj.u8sName = data.u8sName;
			if (TSConverter.validateParam(data, "iIsEditable", "number", errors, newContext, false))
				obj.iIsEditable = data.iIsEditable;
			if (TSConverter.validateParam(data, "iIsPersistent", "number", errors, newContext, false))
				obj.iIsPersistent = data.iIsPersistent;
			if (TSConverter.validateParam(data, "iIsTabbed", "number", errors, newContext, false))
				obj.iIsTabbed = data.iIsTabbed;
			if (TSConverter.validateParam(data, "u8sNeighbourClusterID", "string", errors, newContext, false))
				obj.u8sNeighbourClusterID = data.u8sNeighbourClusterID;
			const tscv_listgroups = new ENetUC_ClientContent.AsnClientContentGroupList();
			if (AsnClientContentGroupList_Converter.fromJSON(data.listGroups, tscv_listgroups, errors, newContext, "listGroups", false))
				obj.listGroups = tscv_listgroups;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentGroupCluster"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentGroupClusterList
export class AsnClientContentGroupClusterList_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentGroupClusterList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnClientContentGroupClusterList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnClientContentGroupCluster_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentGroupClusterList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentGroupClusterList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentGroupClusterList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentGroupClusterList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnClientContentGroupClusterList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientContent.AsnClientContentGroupCluster.initEmpty();
				if (AsnClientContentGroupCluster_Converter.fromJSON(elem, entry, errors, newContext, "AsnClientContentGroupCluster", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentGroupClusterList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentStructure
export class AsnClientContentStructure_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentStructure, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentStructure
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentStructure", newContext);
		value += TSConverter.addNumberParam("iIsEditable", obj.iIsEditable, newContext);
		value += TSConverter.addStringParam("u8sVersion", obj.u8sVersion, newContext);
		value += TSConverter.addStringParam("u8sRevision", obj.u8sRevision, newContext);
		value += TSConverter.addStringParam("u8sLastWriter", obj.u8sLastWriter, newContext);
		value += TSConverter.addObjectAsStringParam("listGroupClusters", AsnClientContentGroupClusterList_Converter.toJSON(obj.listGroupClusters, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentStructure, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentStructure");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentStructure"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentStructure;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentStructure
			if (TSConverter.validateParam(data, "iIsEditable", "number", errors, newContext, false))
				obj.iIsEditable = data.iIsEditable;
			if (TSConverter.validateParam(data, "u8sVersion", "string", errors, newContext, false))
				obj.u8sVersion = data.u8sVersion;
			if (TSConverter.validateParam(data, "u8sRevision", "string", errors, newContext, false))
				obj.u8sRevision = data.u8sRevision;
			if (TSConverter.validateParam(data, "u8sLastWriter", "string", errors, newContext, false))
				obj.u8sLastWriter = data.u8sLastWriter;
			const tscv_listgroupclusters = new ENetUC_ClientContent.AsnClientContentGroupClusterList();
			if (AsnClientContentGroupClusterList_Converter.fromJSON(data.listGroupClusters, tscv_listgroupclusters, errors, newContext, "listGroupClusters", false))
				obj.listGroupClusters = tscv_listgroupclusters;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentStructure"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetClientContentResult
export class AsnGetClientContentResult_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnGetClientContentResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetClientContentResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetClientContentResult", newContext);
		value += TSConverter.addStringParam("u8sContentRevision", obj.u8sContentRevision, newContext);
		value += TSConverter.addObjectAsStringParam("contactList", ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(obj.contactList, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("contentstructure", AsnClientContentStructure_Converter.toJSON(obj.contentstructure, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnGetClientContentResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetClientContentResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientContentResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnGetClientContentResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetClientContentResult
			if (TSConverter.validateParam(data, "u8sContentRevision", "string", errors, newContext, false))
				obj.u8sContentRevision = data.u8sContentRevision;
			const tscv_contactlist = new ENetUC_Common.AsnNetDatabaseContactList();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(data.contactList, tscv_contactlist, errors, newContext, "contactList", false))
				obj.contactList = tscv_contactlist;
			const tscv_contentstructure = ENetUC_ClientContent.AsnClientContentStructure.initEmpty();
			if (AsnClientContentStructure_Converter.fromJSON(data.contentstructure, tscv_contentstructure, errors, newContext, "contentstructure", false))
				obj.contentstructure = tscv_contentstructure;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetClientContentResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnChangeClientContentArgument
export class AsnChangeClientContentArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnChangeClientContentArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnChangeClientContentArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnChangeClientContentArgument", newContext);
		value += TSConverter.addNumberParam("iRevision", obj.iRevision, newContext);
		value += TSConverter.addStringParam("u8sVersion", obj.u8sVersion, newContext);
		value += TSConverter.addNumberParam("iIsEditable", obj.iIsEditable, newContext);
		value += TSConverter.addObjectAsStringParam("addedOrChangedContacts", ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(obj.addedOrChangedContacts, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("removedContacts", ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.toJSON(obj.removedContacts, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("listContentstructure", AsnClientContentGroupClusterList_Converter.toJSON(obj.listContentstructure, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("listStructureAttributes", AsnStructureAttributeList_Converter.toJSON(obj.listStructureAttributes, newContext), newContext);
		value += TSConverter.addNumberParam("iFlags", obj.iFlags, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnChangeClientContentArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnChangeClientContentArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnChangeClientContentArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnChangeClientContentArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnChangeClientContentArgument
			if (TSConverter.validateParam(data, "iRevision", "number", errors, newContext, false))
				obj.iRevision = data.iRevision;
			if (TSConverter.validateParam(data, "u8sVersion", "string", errors, newContext, false))
				obj.u8sVersion = data.u8sVersion;
			if (TSConverter.validateParam(data, "iIsEditable", "number", errors, newContext, false))
				obj.iIsEditable = data.iIsEditable;
			const tscv_addedorchangedcontacts = new ENetUC_Common.AsnNetDatabaseContactList();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(data.addedOrChangedContacts, tscv_addedorchangedcontacts, errors, newContext, "addedOrChangedContacts", true))
				obj.addedOrChangedContacts = tscv_addedorchangedcontacts;
			const tscv_removedcontacts = new ENetUC_Common.AsnNetDatabaseContactIDList();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.fromJSON(data.removedContacts, tscv_removedcontacts, errors, newContext, "removedContacts", true))
				obj.removedContacts = tscv_removedcontacts;
			const tscv_listcontentstructure = new ENetUC_ClientContent.AsnClientContentGroupClusterList();
			if (AsnClientContentGroupClusterList_Converter.fromJSON(data.listContentstructure, tscv_listcontentstructure, errors, newContext, "listContentstructure", true))
				obj.listContentstructure = tscv_listcontentstructure;
			const tscv_liststructureattributes = new ENetUC_ClientContent.AsnStructureAttributeList();
			if (AsnStructureAttributeList_Converter.fromJSON(data.listStructureAttributes, tscv_liststructureattributes, errors, newContext, "listStructureAttributes", true))
				obj.listStructureAttributes = tscv_liststructureattributes;
			if (TSConverter.validateParam(data, "iFlags", "number", errors, newContext, false))
				obj.iFlags = data.iFlags;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnChangeClientContentArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentChangedArgument
export class AsnClientContentChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnClientContentChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnClientContentChangedArgument", newContext);
		value += TSConverter.addNumberParam("iRevision", obj.iRevision, newContext);
		value += TSConverter.addObjectAsStringParam("addedOrChangedContacts", ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.toJSON(obj.addedOrChangedContacts, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("removedContacts", ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.toJSON(obj.removedContacts, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("listContentstructure", AsnClientContentGroupClusterList_Converter.toJSON(obj.listContentstructure, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("listChangedStructureAttributes", AsnStructureAttributeList_Converter.toJSON(obj.listChangedStructureAttributes, newContext), newContext);
		value += TSConverter.addNumberParam("iFlags", obj.iFlags, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnClientContentChangedArgument
			if (TSConverter.validateParam(data, "iRevision", "number", errors, newContext, false))
				obj.iRevision = data.iRevision;
			const tscv_addedorchangedcontacts = new ENetUC_Common.AsnNetDatabaseContactList();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactList_Converter.fromJSON(data.addedOrChangedContacts, tscv_addedorchangedcontacts, errors, newContext, "addedOrChangedContacts", true))
				obj.addedOrChangedContacts = tscv_addedorchangedcontacts;
			const tscv_removedcontacts = new ENetUC_Common.AsnNetDatabaseContactIDList();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactIDList_Converter.fromJSON(data.removedContacts, tscv_removedcontacts, errors, newContext, "removedContacts", true))
				obj.removedContacts = tscv_removedcontacts;
			const tscv_listcontentstructure = new ENetUC_ClientContent.AsnClientContentGroupClusterList();
			if (AsnClientContentGroupClusterList_Converter.fromJSON(data.listContentstructure, tscv_listcontentstructure, errors, newContext, "listContentstructure", true))
				obj.listContentstructure = tscv_listcontentstructure;
			const tscv_listchangedstructureattributes = new ENetUC_ClientContent.AsnStructureAttributeList();
			if (AsnStructureAttributeList_Converter.fromJSON(data.listChangedStructureAttributes, tscv_listchangedstructureattributes, errors, newContext, "listChangedStructureAttributes", true))
				obj.listChangedStructureAttributes = tscv_listchangedstructureattributes;
			if (TSConverter.validateParam(data, "iFlags", "number", errors, newContext, false))
				obj.iFlags = data.iFlags;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDeletedItem
export class AsnDeletedItem_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnDeletedItem, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDeletedItem
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDeletedItem", newContext);
		value += TSConverter.addObjectAsStringParam("contactID", ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.toJSON(obj.contactID, newContext), newContext);
		value += TSConverter.addStringParam("u8sItemID", obj.u8sItemID, newContext);
		value += TSConverter.addStringParam("u8sGroupID", obj.u8sGroupID, newContext);
		value += TSConverter.addStringParam("u8sGroupclusterID", obj.u8sGroupclusterID, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnDeletedItem, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDeletedItem");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDeletedItem"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnDeletedItem;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDeletedItem
			const tscv_contactid = ENetUC_Common.AsnNetDatabaseContactID.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContactID_Converter.fromJSON(data.contactID, tscv_contactid, errors, newContext, "contactID", true))
				obj.contactID = tscv_contactid;
			if (TSConverter.validateParam(data, "u8sItemID", "string", errors, newContext, true))
				obj.u8sItemID = data.u8sItemID;
			if (TSConverter.validateParam(data, "u8sGroupID", "string", errors, newContext, true))
				obj.u8sGroupID = data.u8sGroupID;
			if (TSConverter.validateParam(data, "u8sGroupclusterID", "string", errors, newContext, true))
				obj.u8sGroupclusterID = data.u8sGroupclusterID;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDeletedItem"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnClientContentDeletedList
export class AsnClientContentDeletedList_Converter {
	public static toJSON(obj?: ENetUC_ClientContent.AsnClientContentDeletedList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnClientContentDeletedList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnClientContentDeleted_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_ClientContent.AsnClientContentDeletedList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnClientContentDeletedList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentDeletedList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_ClientContent.AsnClientContentDeletedList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnClientContentDeletedList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_ClientContent.AsnClientContentDeleted.initEmpty();
				if (AsnClientContentDeleted_Converter.fromJSON(elem, entry, errors, newContext, "AsnClientContentDeleted", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnClientContentDeletedList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
