// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_Transport_Converter.ts
 * "UC-Server-Access-Protocol-Transport" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_Transport from "./ENetUC_Transport";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Auth from "./ENetUC_Auth";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Auth_Converter from "./ENetUC_Auth_Converter";

// [PrintTSEncoderDecoderCode] AsnStartTLSArgument
export class AsnStartTLSArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnStartTLSArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnStartTLSArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnStartTLSArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnStartTLSArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStartTLSArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStartTLSArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnStartTLSArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnStartTLSArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStartTLSArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnStartTLSResult
export class AsnStartTLSResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnStartTLSResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnStartTLSResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnStartTLSResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnStartTLSResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStartTLSResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStartTLSResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnStartTLSResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnStartTLSResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStartTLSResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnStartMultiUserModeArgument
export class AsnStartMultiUserModeArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnStartMultiUserModeArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnStartMultiUserModeArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnStartMultiUserModeArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnStartMultiUserModeArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStartMultiUserModeArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStartMultiUserModeArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnStartMultiUserModeArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnStartMultiUserModeArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStartMultiUserModeArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnStartMultiUserModeResult
export class AsnStartMultiUserModeResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnStartMultiUserModeResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnStartMultiUserModeResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnStartMultiUserModeResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnStartMultiUserModeResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnStartMultiUserModeResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnStartMultiUserModeResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnStartMultiUserModeResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnStartMultiUserModeResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnStartMultiUserModeResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCreateUserSessionArgument
export class AsnCreateUserSessionArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnCreateUserSessionArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCreateUserSessionArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCreateUserSessionArgument", newContext);
		value += TSConverter.addObjectAsStringParam("negotiate", ENetUC_Auth_Converter.AsnNegotiateServerConnectionArgument_Converter.toJSON(obj.negotiate, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnCreateUserSessionArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCreateUserSessionArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCreateUserSessionArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnCreateUserSessionArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCreateUserSessionArgument
			const tscv_negotiate = ENetUC_Auth.AsnNegotiateServerConnectionArgument.initEmpty();
			if (ENetUC_Auth_Converter.AsnNegotiateServerConnectionArgument_Converter.fromJSON(data.negotiate, tscv_negotiate, errors, newContext, "negotiate", false))
				obj.negotiate = tscv_negotiate;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCreateUserSessionArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCreateUserSessionResult
export class AsnCreateUserSessionResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnCreateUserSessionResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCreateUserSessionResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCreateUserSessionResult", newContext);
		value += TSConverter.addStringParam("u8sSessionID", obj.u8sSessionID, newContext);
		value += TSConverter.addObjectAsStringParam("negotiateresult", ENetUC_Auth_Converter.AsnNegotiateServerConnectionResult_Converter.toJSON(obj.negotiateresult, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnCreateUserSessionResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCreateUserSessionResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCreateUserSessionResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnCreateUserSessionResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCreateUserSessionResult
			if (TSConverter.validateParam(data, "u8sSessionID", "string", errors, newContext, false))
				obj.u8sSessionID = data.u8sSessionID;
			const tscv_negotiateresult = ENetUC_Auth.AsnNegotiateServerConnectionResult.initEmpty();
			if (ENetUC_Auth_Converter.AsnNegotiateServerConnectionResult_Converter.fromJSON(data.negotiateresult, tscv_negotiateresult, errors, newContext, "negotiateresult", false))
				obj.negotiateresult = tscv_negotiateresult;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCreateUserSessionResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCreateUserSessionError
export class AsnCreateUserSessionError_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnCreateUserSessionError, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCreateUserSessionError
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCreateUserSessionError", newContext);
		value += TSConverter.addNumberParam("iErrorDetail", obj.iErrorDetail, newContext);
		value += TSConverter.addStringParam("u8sErrorString", obj.u8sErrorString, newContext);
		value += TSConverter.addObjectAsStringParam("negotiateerror", ENetUC_Common_Converter.AsnRequestError_Converter.toJSON(obj.negotiateerror, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnCreateUserSessionError, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCreateUserSessionError");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCreateUserSessionError"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnCreateUserSessionError;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCreateUserSessionError
			if (TSConverter.validateParam(data, "iErrorDetail", "number", errors, newContext, false))
				obj.iErrorDetail = data.iErrorDetail;
			if (TSConverter.validateParam(data, "u8sErrorString", "string", errors, newContext, false))
				obj.u8sErrorString = data.u8sErrorString;
			const tscv_negotiateerror = ENetUC_Common.AsnRequestError.initEmpty();
			if (ENetUC_Common_Converter.AsnRequestError_Converter.fromJSON(data.negotiateerror, tscv_negotiateerror, errors, newContext, "negotiateerror", false))
				obj.negotiateerror = tscv_negotiateerror;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCreateUserSessionError"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDestroyUserSessionArgument
export class AsnDestroyUserSessionArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnDestroyUserSessionArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDestroyUserSessionArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDestroyUserSessionArgument", newContext);
		value += TSConverter.addStringParam("u8sSessionID", obj.u8sSessionID, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnDestroyUserSessionArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDestroyUserSessionArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDestroyUserSessionArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnDestroyUserSessionArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDestroyUserSessionArgument
			if (TSConverter.validateParam(data, "u8sSessionID", "string", errors, newContext, false))
				obj.u8sSessionID = data.u8sSessionID;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDestroyUserSessionArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnDestroyUserSessionResult
export class AsnDestroyUserSessionResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnDestroyUserSessionResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnDestroyUserSessionResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnDestroyUserSessionResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnDestroyUserSessionResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnDestroyUserSessionResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnDestroyUserSessionResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnDestroyUserSessionResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnDestroyUserSessionResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnDestroyUserSessionResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnUserSessionDestroyedArgument
export class AsnUserSessionDestroyedArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnUserSessionDestroyedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnUserSessionDestroyedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnUserSessionDestroyedArgument", newContext);
		value += TSConverter.addStringParam("u8sSessionID", obj.u8sSessionID, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnUserSessionDestroyedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnUserSessionDestroyedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnUserSessionDestroyedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnUserSessionDestroyedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnUserSessionDestroyedArgument
			if (TSConverter.validateParam(data, "u8sSessionID", "string", errors, newContext, false))
				obj.u8sSessionID = data.u8sSessionID;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnUserSessionDestroyedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRegisterExternalServiceResult
export class AsnRegisterExternalServiceResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnRegisterExternalServiceResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRegisterExternalServiceResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRegisterExternalServiceResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnRegisterExternalServiceResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRegisterExternalServiceResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterExternalServiceResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnRegisterExternalServiceResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRegisterExternalServiceResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterExternalServiceResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetExternalServiceProfileArgument
export class AsnGetExternalServiceProfileArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnGetExternalServiceProfileArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetExternalServiceProfileArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetExternalServiceProfileArgument", newContext);
		value += TSConverter.addNumberParam("iVersion", obj.iVersion, newContext);
		value += TSConverter.addStringParam("u8sToken", obj.u8sToken, newContext);
		value += TSConverter.addNumberParam("iFlags", obj.iFlags, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnGetExternalServiceProfileArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetExternalServiceProfileArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetExternalServiceProfileArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnGetExternalServiceProfileArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetExternalServiceProfileArgument
			if (TSConverter.validateParam(data, "iVersion", "number", errors, newContext, false))
				obj.iVersion = data.iVersion;
			if (TSConverter.validateParam(data, "u8sToken", "string", errors, newContext, false))
				obj.u8sToken = data.u8sToken;
			if (TSConverter.validateParam(data, "iFlags", "number", errors, newContext, true))
				obj.iFlags = data.iFlags;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetExternalServiceProfileArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetExternalServiceProfileResult
export class AsnGetExternalServiceProfileResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnGetExternalServiceProfileResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetExternalServiceProfileResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetExternalServiceProfileResult", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnGetExternalServiceProfileResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetExternalServiceProfileResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetExternalServiceProfileResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnGetExternalServiceProfileResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetExternalServiceProfileResult
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetExternalServiceProfileResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRegisterServerArgument
export class AsnRegisterServerArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnRegisterServerArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRegisterServerArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRegisterServerArgument", newContext);
		value += TSConverter.addNumberParam("iVersion", obj.iVersion, newContext);
		value += TSConverter.addStringParam("u8sVersion", obj.u8sVersion, newContext);
		value += TSConverter.addStringParam("u8sServerName", obj.u8sServerName, newContext);
		value += TSConverter.addStringParam("u8sServerID", obj.u8sServerID, newContext);
		value += TSConverter.addStringParam("u8sServerPassword", obj.u8sServerPassword, newContext);
		value += TSConverter.addObjectAsStringParam("presenceDomains", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.presenceDomains, newContext), newContext);
		value += TSConverter.addStringParam("u8sSystemID", obj.u8sSystemID, newContext);
		value += TSConverter.addNumberParam("iInterfaceVersion", obj.iInterfaceVersion, newContext);
		value += TSConverter.addStringParam("u8sJWTPublicKey", obj.u8sJWTPublicKey, newContext);
		value += TSConverter.addStringParam("u8sHardwareID", obj.u8sHardwareID, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnRegisterServerArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRegisterServerArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterServerArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnRegisterServerArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRegisterServerArgument
			if (TSConverter.validateParam(data, "iVersion", "number", errors, newContext, false))
				obj.iVersion = data.iVersion;
			if (TSConverter.validateParam(data, "u8sVersion", "string", errors, newContext, false))
				obj.u8sVersion = data.u8sVersion;
			if (TSConverter.validateParam(data, "u8sServerName", "string", errors, newContext, false))
				obj.u8sServerName = data.u8sServerName;
			if (TSConverter.validateParam(data, "u8sServerID", "string", errors, newContext, false))
				obj.u8sServerID = data.u8sServerID;
			if (TSConverter.validateParam(data, "u8sServerPassword", "string", errors, newContext, false))
				obj.u8sServerPassword = data.u8sServerPassword;
			const tscv_presencedomains = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.presenceDomains, tscv_presencedomains, errors, newContext, "presenceDomains", false))
				obj.presenceDomains = tscv_presencedomains;
			if (TSConverter.validateParam(data, "u8sSystemID", "string", errors, newContext, false))
				obj.u8sSystemID = data.u8sSystemID;
			if (TSConverter.validateParam(data, "iInterfaceVersion", "number", errors, newContext, false))
				obj.iInterfaceVersion = data.iInterfaceVersion;
			if (TSConverter.validateParam(data, "u8sJWTPublicKey", "string", errors, newContext, true))
				obj.u8sJWTPublicKey = data.u8sJWTPublicKey;
			if (TSConverter.validateParam(data, "u8sHardwareID", "string", errors, newContext, true))
				obj.u8sHardwareID = data.u8sHardwareID;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterServerArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebServiceCapabilities
export class AsnWebServiceCapabilities_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebServiceCapabilities, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnWebServiceCapabilities
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnWebServiceCapabilities", newContext);
		value += TSConverter.addStringParam("u8sType", obj.u8sType, newContext);
		value += TSConverter.addStringParam("u8sValue", obj.u8sValue, newContext);
		value += TSConverter.addNumberParam("iValue", obj.iValue, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebServiceCapabilities, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebServiceCapabilities");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceCapabilities"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebServiceCapabilities;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnWebServiceCapabilities
			if (TSConverter.validateParam(data, "u8sType", "string", errors, newContext, false))
				obj.u8sType = data.u8sType;
			if (TSConverter.validateParam(data, "u8sValue", "string", errors, newContext, true))
				obj.u8sValue = data.u8sValue;
			if (TSConverter.validateParam(data, "iValue", "number", errors, newContext, true))
				obj.iValue = data.iValue;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceCapabilities"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRegisterServerError
export class AsnRegisterServerError_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnRegisterServerError, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRegisterServerError
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRegisterServerError", newContext);
		value += TSConverter.addNumberParam("iErrorDetail", obj.iErrorDetail, newContext);
		value += TSConverter.addStringParam("u8sErrorString", obj.u8sErrorString, newContext);
		value += TSConverter.addObjectAsStringParam("redirectAddresses", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.redirectAddresses, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnRegisterServerError, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRegisterServerError");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterServerError"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnRegisterServerError;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRegisterServerError
			if (TSConverter.validateParam(data, "iErrorDetail", "number", errors, newContext, false))
				obj.iErrorDetail = data.iErrorDetail;
			if (TSConverter.validateParam(data, "u8sErrorString", "string", errors, newContext, false))
				obj.u8sErrorString = data.u8sErrorString;
			const tscv_redirectaddresses = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.redirectAddresses, tscv_redirectaddresses, errors, newContext, "redirectAddresses", true))
				obj.redirectAddresses = tscv_redirectaddresses;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterServerError"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebServiceLogonResult
export class AsnWebServiceLogonResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebServiceLogonResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnWebServiceLogonResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnWebServiceLogonResult", newContext);
		value += TSConverter.addNumberParam("iVersion", obj.iVersion, newContext);
		value += TSConverter.addStringParam("u8sVersion", obj.u8sVersion, newContext);
		value += TSConverter.addStringParam("u8sServerName", obj.u8sServerName, newContext);
		value += TSConverter.addObjectAsStringParam("presenceDomains", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.presenceDomains, newContext), newContext);
		value += TSConverter.addNumberParam("iInterfaceVersion", obj.iInterfaceVersion, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebServiceLogonResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebServiceLogonResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceLogonResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebServiceLogonResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnWebServiceLogonResult
			if (TSConverter.validateParam(data, "iVersion", "number", errors, newContext, false))
				obj.iVersion = data.iVersion;
			if (TSConverter.validateParam(data, "u8sVersion", "string", errors, newContext, false))
				obj.u8sVersion = data.u8sVersion;
			if (TSConverter.validateParam(data, "u8sServerName", "string", errors, newContext, false))
				obj.u8sServerName = data.u8sServerName;
			const tscv_presencedomains = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.presenceDomains, tscv_presencedomains, errors, newContext, "presenceDomains", false))
				obj.presenceDomains = tscv_presencedomains;
			if (TSConverter.validateParam(data, "iInterfaceVersion", "number", errors, newContext, false))
				obj.iInterfaceVersion = data.iInterfaceVersion;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceLogonResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebListenInterfaceStatus
export class AsnWebListenInterfaceStatus_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebListenInterfaceStatus, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnWebListenInterfaceStatus
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnWebListenInterfaceStatus", newContext);
		value += TSConverter.addStringParam("u8sInterfaceGUID", obj.u8sInterfaceGUID, newContext);
		value += TSConverter.addStringParam("u8sType", obj.u8sType, newContext);
		value += TSConverter.addNumberParam("listenStatus", obj.listenStatus, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebListenInterfaceStatus, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebListenInterfaceStatus");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebListenInterfaceStatus"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebListenInterfaceStatus;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnWebListenInterfaceStatus
			if (TSConverter.validateParam(data, "u8sInterfaceGUID", "string", errors, newContext, false))
				obj.u8sInterfaceGUID = data.u8sInterfaceGUID;
			if (TSConverter.validateParam(data, "u8sType", "string", errors, newContext, false))
				obj.u8sType = data.u8sType;
			if (TSConverter.validateParam(data, "listenStatus", "number", errors, newContext, false))
				obj.listenStatus = data.listenStatus;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebListenInterfaceStatus"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnTransportKeepAliveArgument
export class AsnTransportKeepAliveArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnTransportKeepAliveArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnTransportKeepAliveArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnTransportKeepAliveArgument", newContext);
		value += TSConverter.addObjectAsStringParam("dummy", JSON.stringify(obj.dummy), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnTransportKeepAliveArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnTransportKeepAliveArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnTransportKeepAliveArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnTransportKeepAliveArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnTransportKeepAliveArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnTransportKeepAliveArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebGetIceServersArgument
export class AsnWebGetIceServersArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebGetIceServersArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnWebGetIceServersArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnWebGetIceServersArgument", newContext);
		value += TSConverter.addStringParam("sTurnProviderConfig", obj.sTurnProviderConfig, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebGetIceServersArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebGetIceServersArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebGetIceServersArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebGetIceServersArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnWebGetIceServersArgument
			if (TSConverter.validateParam(data, "sTurnProviderConfig", "string", errors, newContext, true))
				obj.sTurnProviderConfig = data.sTurnProviderConfig;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebGetIceServersArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebIceServer
export class AsnWebIceServer_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebIceServer, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnWebIceServer
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnWebIceServer", newContext);
		value += TSConverter.addStringParam("username", obj.username, newContext);
		value += TSConverter.addStringParam("credential", obj.credential, newContext);
		value += TSConverter.addObjectAsStringParam("urls", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.urls, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebIceServer, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebIceServer");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebIceServer"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebIceServer;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnWebIceServer
			if (TSConverter.validateParam(data, "username", "string", errors, newContext, true))
				obj.username = data.username;
			if (TSConverter.validateParam(data, "credential", "string", errors, newContext, true))
				obj.credential = data.credential;
			const tscv_urls = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.urls, tscv_urls, errors, newContext, "urls", false))
				obj.urls = tscv_urls;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebIceServer"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnTokenVerifyArgument
export class AsnTokenVerifyArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnTokenVerifyArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnTokenVerifyArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnTokenVerifyArgument", newContext);
		value += TSConverter.addStringParam("sToken", obj.sToken, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnTokenVerifyArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnTokenVerifyArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnTokenVerifyArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnTokenVerifyArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnTokenVerifyArgument
			if (TSConverter.validateParam(data, "sToken", "string", errors, newContext, false))
				obj.sToken = data.sToken;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnTokenVerifyArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnTokenVerifyResult
export class AsnTokenVerifyResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnTokenVerifyResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnTokenVerifyResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnTokenVerifyResult", newContext);
		value += TSConverter.addDateParam("stValidTo", obj.stValidTo, newContext);
		value += TSConverter.addNumberParam("iType", obj.iType, newContext);
		value += TSConverter.addStringParam("u8sDNPath", obj.u8sDNPath, newContext);
		value += TSConverter.addStringParam("u8sUserName", obj.u8sUserName, newContext);
		value += TSConverter.addStringParam("u8sSIPURI", obj.u8sSIPURI, newContext);
		value += TSConverter.addStringParam("u8sobjectGUID", obj.u8sobjectGUID, newContext);
		value += TSConverter.addStringParam("u8sPhoneNumber", obj.u8sPhoneNumber, newContext);
		value += TSConverter.addStringParam("u8sPhoneSecondary", obj.u8sPhoneSecondary, newContext);
		value += TSConverter.addStringParam("u8sCallRecordingNumber", obj.u8sCallRecordingNumber, newContext);
		value += TSConverter.addStringParam("u8sMailboxNumber", obj.u8sMailboxNumber, newContext);
		value += TSConverter.addStringParam("u8sHomeServer", obj.u8sHomeServer, newContext);
		value += TSConverter.addNumberParam("iUserStaticRights", obj.iUserStaticRights, newContext);
		value += TSConverter.addObjectAsStringParam("asnUserContact", ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.toJSON(obj.asnUserContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("asnUserPropertyBag", ENetUC_Common_Converter.AsnUserPropertyBag_Converter.toJSON(obj.asnUserPropertyBag, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnTokenVerifyResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnTokenVerifyResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnTokenVerifyResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnTokenVerifyResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnTokenVerifyResult
			if (TSConverter.validateParam(data, "stValidTo", "string", errors, newContext, false))
				obj.stValidTo = new Date(data.stValidTo);
			if (TSConverter.validateParam(data, "iType", "number", errors, newContext, false))
				obj.iType = data.iType;
			if (TSConverter.validateParam(data, "u8sDNPath", "string", errors, newContext, false))
				obj.u8sDNPath = data.u8sDNPath;
			if (TSConverter.validateParam(data, "u8sUserName", "string", errors, newContext, false))
				obj.u8sUserName = data.u8sUserName;
			if (TSConverter.validateParam(data, "u8sSIPURI", "string", errors, newContext, false))
				obj.u8sSIPURI = data.u8sSIPURI;
			if (TSConverter.validateParam(data, "u8sobjectGUID", "string", errors, newContext, false))
				obj.u8sobjectGUID = data.u8sobjectGUID;
			if (TSConverter.validateParam(data, "u8sPhoneNumber", "string", errors, newContext, false))
				obj.u8sPhoneNumber = data.u8sPhoneNumber;
			if (TSConverter.validateParam(data, "u8sPhoneSecondary", "string", errors, newContext, false))
				obj.u8sPhoneSecondary = data.u8sPhoneSecondary;
			if (TSConverter.validateParam(data, "u8sCallRecordingNumber", "string", errors, newContext, false))
				obj.u8sCallRecordingNumber = data.u8sCallRecordingNumber;
			if (TSConverter.validateParam(data, "u8sMailboxNumber", "string", errors, newContext, false))
				obj.u8sMailboxNumber = data.u8sMailboxNumber;
			if (TSConverter.validateParam(data, "u8sHomeServer", "string", errors, newContext, false))
				obj.u8sHomeServer = data.u8sHomeServer;
			if (TSConverter.validateParam(data, "iUserStaticRights", "number", errors, newContext, false))
				obj.iUserStaticRights = data.iUserStaticRights;
			const tscv_asnusercontact = ENetUC_Common.AsnNetDatabaseContact.initEmpty();
			if (ENetUC_Common_Converter.AsnNetDatabaseContact_Converter.fromJSON(data.asnUserContact, tscv_asnusercontact, errors, newContext, "asnUserContact", false))
				obj.asnUserContact = tscv_asnusercontact;
			const tscv_asnuserpropertybag = ENetUC_Common.AsnUserPropertyBag.initEmpty();
			if (ENetUC_Common_Converter.AsnUserPropertyBag_Converter.fromJSON(data.asnUserPropertyBag, tscv_asnuserpropertybag, errors, newContext, "asnUserPropertyBag", false))
				obj.asnUserPropertyBag = tscv_asnuserpropertybag;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnTokenVerifyResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCheckConnectionArgument
export class AsnCheckConnectionArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnCheckConnectionArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCheckConnectionArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCheckConnectionArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnCheckConnectionArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCheckConnectionArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCheckConnectionArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnCheckConnectionArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCheckConnectionArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCheckConnectionArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnCheckConnectionResult
export class AsnCheckConnectionResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnCheckConnectionResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnCheckConnectionResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnCheckConnectionResult", newContext);
		value += TSConverter.addStringParam("u8sServerVersion", obj.u8sServerVersion, newContext);
		value += TSConverter.addStringParam("u8sProductVersion", obj.u8sProductVersion, newContext);
		value += TSConverter.addNumberParam("iProtocolVersion", obj.iProtocolVersion, newContext);
		value += TSConverter.addNumberParam("iOEMID", obj.iOEMID, newContext);
		value += TSConverter.addStringParam("u8sCookie", obj.u8sCookie, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnCheckConnectionResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnCheckConnectionResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnCheckConnectionResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnCheckConnectionResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnCheckConnectionResult
			if (TSConverter.validateParam(data, "u8sServerVersion", "string", errors, newContext, false))
				obj.u8sServerVersion = data.u8sServerVersion;
			if (TSConverter.validateParam(data, "u8sProductVersion", "string", errors, newContext, false))
				obj.u8sProductVersion = data.u8sProductVersion;
			if (TSConverter.validateParam(data, "iProtocolVersion", "number", errors, newContext, false))
				obj.iProtocolVersion = data.iProtocolVersion;
			if (TSConverter.validateParam(data, "iOEMID", "number", errors, newContext, false))
				obj.iOEMID = data.iOEMID;
			if (TSConverter.validateParam(data, "u8sCookie", "string", errors, newContext, false))
				obj.u8sCookie = data.u8sCookie;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnCheckConnectionResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestCERTokenArgument
export class AsnRequestCERTokenArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnRequestCERTokenArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestCERTokenArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestCERTokenArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnRequestCERTokenArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestCERTokenArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestCERTokenArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnRequestCERTokenArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestCERTokenArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestCERTokenArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRequestCERTokenResult
export class AsnRequestCERTokenResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnRequestCERTokenResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRequestCERTokenResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRequestCERTokenResult", newContext);
		value += TSConverter.addStringParam("u8sToken", obj.u8sToken, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnRequestCERTokenResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRequestCERTokenResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestCERTokenResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnRequestCERTokenResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRequestCERTokenResult
			if (TSConverter.validateParam(data, "u8sToken", "string", errors, newContext, false))
				obj.u8sToken = data.u8sToken;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRequestCERTokenResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetTimeArgument
export class AsnGetTimeArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnGetTimeArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetTimeArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetTimeArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnGetTimeArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetTimeArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetTimeArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnGetTimeArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetTimeArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetTimeArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetTimeResult
export class AsnGetTimeResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnGetTimeResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetTimeResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetTimeResult", newContext);
		value += TSConverter.addDateParam("stSystemtime", obj.stSystemtime, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnGetTimeResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetTimeResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetTimeResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnGetTimeResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetTimeResult
			if (TSConverter.validateParam(data, "stSystemtime", "string", errors, newContext, false))
				obj.stSystemtime = new Date(data.stSystemtime);
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetTimeResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebServiceCapabilitiesList
export class AsnWebServiceCapabilitiesList_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebServiceCapabilitiesList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnWebServiceCapabilitiesList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnWebServiceCapabilities_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebServiceCapabilitiesList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebServiceCapabilitiesList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceCapabilitiesList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebServiceCapabilitiesList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnWebServiceCapabilitiesList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Transport.AsnWebServiceCapabilities.initEmpty();
				if (AsnWebServiceCapabilities_Converter.fromJSON(elem, entry, errors, newContext, "AsnWebServiceCapabilities", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceCapabilitiesList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRegisterServerResult
export class AsnRegisterServerResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnRegisterServerResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRegisterServerResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRegisterServerResult", newContext);
		value += TSConverter.addObjectAsStringParam("capabilities", AsnWebServiceCapabilitiesList_Converter.toJSON(obj.capabilities, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnRegisterServerResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRegisterServerResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterServerResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnRegisterServerResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRegisterServerResult
			const tscv_capabilities = new ENetUC_Transport.AsnWebServiceCapabilitiesList();
			if (AsnWebServiceCapabilitiesList_Converter.fromJSON(data.capabilities, tscv_capabilities, errors, newContext, "capabilities", true))
				obj.capabilities = tscv_capabilities;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterServerResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebServiceLogonArgument
export class AsnWebServiceLogonArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebServiceLogonArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnWebServiceLogonArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnWebServiceLogonArgument", newContext);
		value += TSConverter.addNumberParam("iVersion", obj.iVersion, newContext);
		value += TSConverter.addStringParam("u8sVersion", obj.u8sVersion, newContext);
		value += TSConverter.addStringParam("u8sUser", obj.u8sUser, newContext);
		value += TSConverter.addStringParam("u8sPassword", obj.u8sPassword, newContext);
		value += TSConverter.addObjectAsStringParam("capabilities", AsnWebServiceCapabilitiesList_Converter.toJSON(obj.capabilities, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebServiceLogonArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebServiceLogonArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceLogonArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebServiceLogonArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnWebServiceLogonArgument
			if (TSConverter.validateParam(data, "iVersion", "number", errors, newContext, false))
				obj.iVersion = data.iVersion;
			if (TSConverter.validateParam(data, "u8sVersion", "string", errors, newContext, false))
				obj.u8sVersion = data.u8sVersion;
			if (TSConverter.validateParam(data, "u8sUser", "string", errors, newContext, false))
				obj.u8sUser = data.u8sUser;
			if (TSConverter.validateParam(data, "u8sPassword", "string", errors, newContext, false))
				obj.u8sPassword = data.u8sPassword;
			const tscv_capabilities = new ENetUC_Transport.AsnWebServiceCapabilitiesList();
			if (AsnWebServiceCapabilitiesList_Converter.fromJSON(data.capabilities, tscv_capabilities, errors, newContext, "capabilities", false))
				obj.capabilities = tscv_capabilities;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceLogonArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebServiceCapabilitiesChangedArgument
export class AsnWebServiceCapabilitiesChangedArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnWebServiceCapabilitiesChangedArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnWebServiceCapabilitiesChangedArgument", newContext);
		value += TSConverter.addObjectAsStringParam("capabilities", AsnWebServiceCapabilitiesList_Converter.toJSON(obj.capabilities, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebServiceCapabilitiesChangedArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceCapabilitiesChangedArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebServiceCapabilitiesChangedArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnWebServiceCapabilitiesChangedArgument
			const tscv_capabilities = new ENetUC_Transport.AsnWebServiceCapabilitiesList();
			if (AsnWebServiceCapabilitiesList_Converter.fromJSON(data.capabilities, tscv_capabilities, errors, newContext, "capabilities", true))
				obj.capabilities = tscv_capabilities;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceCapabilitiesChangedArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebListenInterfaceStatusList
export class AsnWebListenInterfaceStatusList_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebListenInterfaceStatusList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnWebListenInterfaceStatusList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnWebListenInterfaceStatus_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebListenInterfaceStatusList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebListenInterfaceStatusList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebListenInterfaceStatusList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebListenInterfaceStatusList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnWebListenInterfaceStatusList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Transport.AsnWebListenInterfaceStatus.initEmpty();
				if (AsnWebListenInterfaceStatus_Converter.fromJSON(elem, entry, errors, newContext, "AsnWebListenInterfaceStatus", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebListenInterfaceStatusList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebServiceStatusArgument
export class AsnWebServiceStatusArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebServiceStatusArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnWebServiceStatusArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnWebServiceStatusArgument", newContext);
		value += TSConverter.addObjectAsStringParam("interfaceList", AsnWebListenInterfaceStatusList_Converter.toJSON(obj.interfaceList, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebServiceStatusArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebServiceStatusArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceStatusArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebServiceStatusArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnWebServiceStatusArgument
			const tscv_interfacelist = new ENetUC_Transport.AsnWebListenInterfaceStatusList();
			if (AsnWebListenInterfaceStatusList_Converter.fromJSON(data.interfaceList, tscv_interfacelist, errors, newContext, "interfaceList", false))
				obj.interfaceList = tscv_interfacelist;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebServiceStatusArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebIceServerList
export class AsnWebIceServerList_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebIceServerList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnWebIceServerList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnWebIceServer_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebIceServerList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebIceServerList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebIceServerList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebIceServerList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnWebIceServerList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Transport.AsnWebIceServer.initEmpty();
				if (AsnWebIceServer_Converter.fromJSON(elem, entry, errors, newContext, "AsnWebIceServer", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebIceServerList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnWebGetIceServersResult
export class AsnWebGetIceServersResult_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnWebGetIceServersResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnWebGetIceServersResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnWebGetIceServersResult", newContext);
		value += TSConverter.addObjectAsStringParam("iceServers", AsnWebIceServerList_Converter.toJSON(obj.iceServers, newContext), newContext);
		value += TSConverter.addDateParam("stValidTo", obj.stValidTo, newContext);
		value += TSConverter.addNumberParam("iTTL", obj.iTTL, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnWebGetIceServersResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnWebGetIceServersResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnWebGetIceServersResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnWebGetIceServersResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnWebGetIceServersResult
			const tscv_iceservers = new ENetUC_Transport.AsnWebIceServerList();
			if (AsnWebIceServerList_Converter.fromJSON(data.iceServers, tscv_iceservers, errors, newContext, "iceServers", false))
				obj.iceServers = tscv_iceservers;
			if (TSConverter.validateParam(data, "stValidTo", "string", errors, newContext, false))
				obj.stValidTo = new Date(data.stValidTo);
			if (TSConverter.validateParam(data, "iTTL", "number", errors, newContext, false))
				obj.iTTL = data.iTTL;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnWebGetIceServersResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnExternalServiceProperty
export class AsnExternalServiceProperty_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnExternalServiceProperty, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnExternalServiceProperty
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnExternalServiceProperty", newContext);
		value += TSConverter.addStringParam("u8sName", obj.u8sName, newContext);
		value += TSConverter.addStringParam("u8sType", obj.u8sType, newContext);
		value += TSConverter.addObjectAsStringParam("listExternalServiceProperty", AsnExternalServicePropertyList_Converter.toJSON(obj.listExternalServiceProperty, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnExternalServiceProperty, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnExternalServiceProperty");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnExternalServiceProperty"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnExternalServiceProperty;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnExternalServiceProperty
			if (TSConverter.validateParam(data, "u8sName", "string", errors, newContext, false))
				obj.u8sName = data.u8sName;
			if (TSConverter.validateParam(data, "u8sType", "string", errors, newContext, false))
				obj.u8sType = data.u8sType;
			const tscv_listexternalserviceproperty = new ENetUC_Transport.AsnExternalServicePropertyList();
			if (AsnExternalServicePropertyList_Converter.fromJSON(data.listExternalServiceProperty, tscv_listexternalserviceproperty, errors, newContext, "listExternalServiceProperty", true))
				obj.listExternalServiceProperty = tscv_listexternalserviceproperty;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnExternalServiceProperty"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnExternalServicePropertyList
export class AsnExternalServicePropertyList_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnExternalServicePropertyList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnExternalServicePropertyList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnExternalServiceProperty_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnExternalServicePropertyList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnExternalServicePropertyList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnExternalServicePropertyList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnExternalServicePropertyList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnExternalServicePropertyList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Transport.AsnExternalServiceProperty.initEmpty();
				if (AsnExternalServiceProperty_Converter.fromJSON(elem, entry, errors, newContext, "AsnExternalServiceProperty", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnExternalServicePropertyList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnRegisterExternalServiceArgument
export class AsnRegisterExternalServiceArgument_Converter {
	public static toJSON(obj?: ENetUC_Transport.AsnRegisterExternalServiceArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnRegisterExternalServiceArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnRegisterExternalServiceArgument", newContext);
		value += TSConverter.addNumberParam("iVersion", obj.iVersion, newContext);
		value += TSConverter.addStringParam("u8sServiceType", obj.u8sServiceType, newContext);
		value += TSConverter.addStringParam("u8sServiceId", obj.u8sServiceId, newContext);
		value += TSConverter.addStringParam("u8sServiceUrl", obj.u8sServiceUrl, newContext);
		value += TSConverter.addStringParam("u8sToken", obj.u8sToken, newContext);
		value += TSConverter.addObjectAsStringParam("listExternalServiceProperty", AsnExternalServicePropertyList_Converter.toJSON(obj.listExternalServiceProperty, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Transport.AsnRegisterExternalServiceArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnRegisterExternalServiceArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterExternalServiceArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Transport.AsnRegisterExternalServiceArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnRegisterExternalServiceArgument
			if (TSConverter.validateParam(data, "iVersion", "number", errors, newContext, false))
				obj.iVersion = data.iVersion;
			if (TSConverter.validateParam(data, "u8sServiceType", "string", errors, newContext, false))
				obj.u8sServiceType = data.u8sServiceType;
			if (TSConverter.validateParam(data, "u8sServiceId", "string", errors, newContext, false))
				obj.u8sServiceId = data.u8sServiceId;
			if (TSConverter.validateParam(data, "u8sServiceUrl", "string", errors, newContext, false))
				obj.u8sServiceUrl = data.u8sServiceUrl;
			if (TSConverter.validateParam(data, "u8sToken", "string", errors, newContext, false))
				obj.u8sToken = data.u8sToken;
			const tscv_listexternalserviceproperty = new ENetUC_Transport.AsnExternalServicePropertyList();
			if (AsnExternalServicePropertyList_Converter.fromJSON(data.listExternalServiceProperty, tscv_listexternalserviceproperty, errors, newContext, "listExternalServiceProperty", true))
				obj.listExternalServiceProperty = tscv_listexternalserviceproperty;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnRegisterExternalServiceArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
