import custLabelInitialData from "../../files/CustomLabelsInitial.json";
import { ICustomerLabels } from "../../models/ModelContent";
import { AsnClientPersistenceEventArgument } from "../../ucserver/stubs/ENetUC_ClientPersistence";

export interface IContentDetails {
	optionalParams: {};
	statusInfos: {};
	u8sDatabaseName: string;
	u8sDisplayName: string;
	u8sFirstName: string;
	u8sLastName: string;
	u8sJobTitle: string;
	u8sCompany: string;
	u8sDepartment: string;
	u8sOfficeRoomNumber: string;
	u8sCustomerID: string;
	u8sBusinessAddressStreet: string;
	u8sBusinessAddressPostalCode: string;
	u8sBusinessAddressCity: string;
	u8sBusinessAddressState: string;
	u8sBusinessAddressCountry: string;
	u8sPrivateAddressStreet: string;
	u8sPrivateAddressPostalCode: string;
	u8sPrivateAddressCity: string;
	u8sPrivateAddressState: string;
	u8sPrivateAddressCountry: string;
	u8sOtherAddressStreet: string;
	u8sOtherAddressPostalCode: string;
	u8sOtherAddressCity: string;
	u8sOtherAddressState: string;
	u8sOtherAddressCountry: string;
	u8sEMail: string;
	u8sEMail2: string;
	u8sEMail3: string;
	u8sSIPAddress: string;
	u8sWebPageURL: string;
	u8sDirectWebLink: string;
	u8sBody: string;
	u8sPhoneBusiness: string;
	u8sPhoneBusiness2: string;
	u8sCompanyMainTelephoneNumber: string;
	u8sAssistantTelephoneNumber: string;
	u8sPhoneHome: string;
	u8sPhoneHome2: string;
	u8sPrimaryTelephoneNumber: string;
	u8sPhoneMobile: string;
	u8sCarTelephoneNumber: string;
	u8sRadioTelephoneNumber: string;
	u8sPagerTelephoneNumber: string;
	u8sOtherTelephoneNumber: string;
	u8sCallbackTelephoneNumber: string;
	u8sISDNTelephoneNumber: string;
	u8sTTYTTDTelephoneNumber: string;
	u8sFaxBusiness: string;
	u8sFaxHome: string;
	customFields: [];
}

export const GetCustomLabels = (
	asnClientPersistenceEventArgument: AsnClientPersistenceEventArgument
): ICustomerLabels | undefined => {
	let custLabels: ICustomerLabels = custLabelInitialData as unknown as ICustomerLabels;
	if (
		asnClientPersistenceEventArgument &&
		asnClientPersistenceEventArgument.changedItems &&
		asnClientPersistenceEventArgument.changedItems[0] &&
		asnClientPersistenceEventArgument.changedItems[0].data
	) {
		const requestData: ICustomerLabels = JSON.parse(
			asnClientPersistenceEventArgument.changedItems[0].data
		) as ICustomerLabels;

		if (
			requestData &&
			Object.prototype.hasOwnProperty.call(requestData, "CustLabels") &&
			requestData.CustLabels.length !== undefined &&
			requestData.CustLabels.length > 0
		) {
			custLabels = requestData;
			console.log("Downloading Customer Label Configurations.");
		}
	} else {
		console.log("No configurations available for customer label, using initial configurations.");
	}

	return custLabels;
};
