// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_Auth.ts
 * "UC-Server-Access-Protocol-Auth" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * Authentication interface.
 * ## Module description
 * This module defines structures and functions for negotiation and authentication on the UCServer.
 *
 * Clients connecting to the UCServer Webservices don't need to negotiate and login by themselfes, they should use \"createsession\" instead.
 * See the \"Web Service Overview\" section of this documentation for more details.
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";

// [PrintTSTypeDefCode] AsnNegotiateServerConnectionServerType
// [PrintTSEnumDefCode] AsnNegotiateServerConnectionServerType
export enum AsnNegotiateServerConnectionServerType {
	/** A standard UCServer; same value as for the old 2.2 ETAPIServer */
	eCTISERVERPROCALL = 0,
	eCTISERVERCALLCONTROLGATEWAY = 1,
	eCTISERVERGO = 2,
	eCTISERVEROEMCTI = 3,
	/** Licenseable TAPI Server with Version 7 and later. It only accepts TAPI Clients (Remote-TSPs) and the */
	eCTISERVERTAPISERVER7 = 4,
	/** client setup tool for User Configuration while asnLogon */
	eCTISERVERPROCALLBUSINESS = 5,
	/** UCServer ProCall DataCenter */
	eCTISERVERPROCALLDATACENTER = 6
}

// [PrintTSTypeDefCode] AsnNegotiateServerConnectionErrorEnum
// [PrintTSEnumDefCode] AsnNegotiateServerConnectionErrorEnum
export enum AsnNegotiateServerConnectionErrorEnum {
	general = 0,
	invalidProtocolVersion = 1
}

// [PrintTSTypeDefCode] AsnAttachToContactOptions
// [PrintTSEnumDefCode] AsnAttachToContactOptions
export enum AsnAttachToContactOptions {
	/** Commandline tools: dont attach to anything, just get rights to call something */
	eAttachToNothing = 0,
	/** Normal Client Login with attach to the 'old' Eventing Sessions, register for Journal etc. */
	eAttachToAll = 1,
	/** Normal Client Login, but don't register for events or other Services; this will be done explicitly */
	eAttachOnlyMinimum = 2
}

// [PrintTSTypeDefCode] AsnLogonErrorEnum
// [PrintTSEnumDefCode] AsnLogonErrorEnum
export enum AsnLogonErrorEnum {
	/** An internal Server error occured. */
	eNETLOGININTERNALSERVERERROR = 100,
	/** User name not found or password wrong (since V6.3). Description text in error message tells now, that the user cannot be authenticated. */
	eNETLOGININVALUSERNAME = 101,
	eNETLOGININVALIDPASS = 102,
	/** No license free for user. */
	eNETLOGINNOMOREUSERS = 103,
	/** UCServer and UCClient version are not compatible. */
	eNETLOGININVALIDVERSION = 104,
	/** This is not really an error, but the server requests the next step in authentication from the client with this \"error\". */
	eNETLOGINNEGOTIATIONREQUIRED = 105,
	/** The requested authntication method is not supported by the usermanager. */
	eNETLOGININVALIDAUTHENTICATIONMETHOD = 106,
	/** The user is not enabled on this ucserver. */
	eNETLOGINUSERNOTENABLED = 107,
	/** Computer login only: the computer name is not found. */
	eNETLOGININVALIDCOMPUTERNAME = 108,
	/** Domain authentication failed. */
	eNETLOGINDOMAINAUTHFAILED = 109,
	/** The user has not enough access rights to login on the server. At the moment this occures only with a mobile client without the access right for mobiles. */
	eNETLOGINMISSINGRIGHTS = 110,
	/** Reserved. */
	eNETLOGINMISSINGRIGHTSTS = 111,
	/** The requested application type (given by negotiate) is not compatible with this server. */
	eNETLOGINSERVERNOTCOMPATIBLE = 112,
	/** User is already logged in. */
	eNETLOGINALREADYAUTHENTICATED = 113,
	/** The has to use a TLS connection to this server. */
	eNETLOGINTLSREQUIRED = 114,
	/** User account has been locked due to too many failed login attempts. */
	eNETLOGINACCOUNTPASSWORDLOCKED = 129,
	/** The given token has been expired. */
	eNETLOGINTOKENEXIRED = 130,
	/** Too many failed login attempts, the user has to wait some time to try again. */
	eNETLOGINTOOMANYATTEMPTS = 131,
	/** Reserved. */
	eNETLOGINWRONGPACMODE = 133
}

// [PrintTSTypeDefCode] AsnUserTokenType
// [PrintTSEnumDefCode] AsnUserTokenType
export enum AsnUserTokenType {
	/** Token for authenticating to an external Server - does not allow to login to UCServer */
	eUserTokenExternal = 0,
	/** Token that allows Login To UCServer */
	eUserTokenLogin = 1
}

// [PrintTSTypeDefCode] AsnClientAppID
// [PrintTSEnumDefCode] AsnClientAppID
export enum AsnClientAppID {
	/** Unbekannt */
	eCLIENTAPPIDUNKNOWN = 0,
	/** Der Windows Client */
	eCLIENTAPPIDPROCALL = 1,
	/** Das Arbeitsplatzsetup */
	eCLIENTAPPIDCLNINST = 2,
	/** ENetSN aus der SDK */
	eCLIENTAPPIDENETSN = 3,
	/** ENetTSC aus der SDK */
	eCLIENTAPPIDENETTSC = 4,
	/** IVR DLL */
	eCLIENTAPPIDIVRDLL = 5,
	/** Kommandozeile (aus der SDK) */
	eCLIENTAPPIDECTISRVCMD = 6,
	/** Mobile Phones */
	eCLIENTAPPIDMOBILE = 7,
	/** Mobile iPad */
	eCLIENTAPPIDMOBILEPAD = 8,
	/** Ein anderer Dienst Ã¼ber den WebService */
	eCLIENTAPPIDWEBSERVICESDK = 9,
	/** MAC-Client */
	eCLIENTAPPIDMAC = 10,
	/** Web Clients - FIVE-1411 */
	eCLIENTAPPIDPROCALLWEB = 15,
	/** SIP-Clients */
	eCLIENTAPPIDPROCALLSIP = 16,
	/** XMPP-Clients */
	eCLIENTAPPIDPROCALLXMPP = 17,
	/** Der ProCall One Client */
	eCLIENTAPPIDPROCALLONE = 18,
	/** der PushServiceManager im Server selbst */
	eCLIENTAPPIDPUSHSERVICE = 19,
	/** ProCall Analytics */
	eCLIENTAPPIDPROCALLANALYTICS = 20,
	/** LiveChat WebClient (=Agentenarbeitsplatz LC-288) */
	eCLIENTAPPIDWEBLIVECHAT = 21,
	/** Der EWS Calendar Replication Service (vormals enumClientAppID::MOBILE_SDK bzw. enumClientAppID::WEBSERVICE_SDK) */
	eCLIENTAPPIDEXCHANGECALENDARREPLICATOR = 22,
	/** Der PAC Client (gleiches Executable wie eCLIENTAPPIDPROCALL) */
	eCLIENTAPPIDPROCALLPAC = 23,
	/** Der PAC Client (gleiches Executable wie eCLIENTAPPIDPROCALL) */
	eCLIENTAPPIDPROCALLPCB = 24,
	/** ProCall Meetings Client */
	eCLIENTAPPIDWEBCONFERENCING = 25
}

// [PrintTSTypeDefCode] AsnAdminLoginChoice
// [PrintTSChoiceDefCode] AsnAdminLoginChoice
export class AsnAdminLoginChoice {
	public constructor(that?: AsnAdminLoginChoice) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAdminLoginChoice {
		return new AsnAdminLoginChoice();
	}

	public u8sAdminPassword?: string = undefined;
	public u8sAdminPasswordCrypted?: string = undefined;
}

// [PrintTSTypeDefCode] AsnNegotiateServerConnectionArgument
// [PrintTSSeqDefCode] AsnNegotiateServerConnectionArgument
export class AsnNegotiateServerConnectionArgument {
	public constructor(that: AsnNegotiateServerConnectionArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNegotiateServerConnectionArgument {
		return new AsnNegotiateServerConnectionArgument({
			iClientProtocolVersion: 0
		});
	}

	/** Angeforderte Protokoll Version des Clients */
	public iClientProtocolVersion!: number;
	/** *Liste der optionalen Parameter:* */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnNegotiateServerConnectionResult
// [PrintTSSeqDefCode] AsnNegotiateServerConnectionResult
export class AsnNegotiateServerConnectionResult {
	public constructor(that: AsnNegotiateServerConnectionResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnNegotiateServerConnectionResult {
		return new AsnNegotiateServerConnectionResult({
			iServerProtocolVersion: 0,
			iServerUMSecurityOption: 0,
			iServerTLSSecurityOption: 0,
			sServerAuthentication: new Uint8Array(0)
		});
	}

	/** Protokoll Version des Servers */
	public iServerProtocolVersion!: number;
	/** VerfÃ¼gbare Login Option */
	public iServerUMSecurityOption!: number;
	/** TLS nie verwenden, ermÃ¶glichen oder voraussetzen. */
	public iServerTLSSecurityOption!: number;
	/** Timestamp des Servers fÃ¼r VerschlÃ¼sselung am Client */
	public sServerAuthentication!: Uint8Array;
	/** *Liste der mÃ¶glichen optionalen Parameter:* */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
	/** Timestamp zum Abgleich der Uhrzeit zwischen Client und Server. ErmÃ¶glicht z.B. korrekte Anzeige von Call-Duration bei abweichender Serverzeit. */
	public stServerTime?: Date;
}

// [PrintTSTypeDefCode] AsnAuthenticationSimple
// [PrintTSSeqDefCode] AsnAuthenticationSimple
export class AsnAuthenticationSimple {
	public constructor(that: AsnAuthenticationSimple) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAuthenticationSimple {
		return new AsnAuthenticationSimple({
			u8sClientUsername: "",
			u8sClientUserPassword: ""
		});
	}

	/** user login daten */
	public u8sClientUsername!: string;
	public u8sClientUserPassword!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAuthenticationSimpleCrypt
// [PrintTSSeqDefCode] AsnAuthenticationSimpleCrypt
export class AsnAuthenticationSimpleCrypt {
	public constructor(that: AsnAuthenticationSimpleCrypt) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAuthenticationSimpleCrypt {
		return new AsnAuthenticationSimpleCrypt({
			u8sClientUsername: "",
			u8sClientUserPasswordCrypted: ""
		});
	}

	/** user login daten */
	public u8sClientUsername!: string;
	public u8sClientUserPasswordCrypted!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAuthenticationWinuser
// [PrintTSSeqDefCode] AsnAuthenticationWinuser
export class AsnAuthenticationWinuser {
	public constructor(that: AsnAuthenticationWinuser) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAuthenticationWinuser {
		return new AsnAuthenticationWinuser({
			u8sClientUsername: "",
			u8sClientUsernameAuthorisation: ""
		});
	}

	/** windows username only */
	public u8sClientUsername!: string;
	public u8sClientUsernameAuthorisation!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnAuthenticationEnhanced
// [PrintTSSeqDefCode] AsnAuthenticationEnhanced
export class AsnAuthenticationEnhanced {
	public constructor(that: AsnAuthenticationEnhanced) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAuthenticationEnhanced {
		return new AsnAuthenticationEnhanced({
			u8sClientUsername: "",
			u8sClientUsernameAuthorisation: "",
			u8sAuthenticationMethod: "",
			sAuthCredetials: new Uint8Array(0)
		});
	}

	/** user login daten */
	public u8sClientUsername!: string;
	public u8sClientUsernameAuthorisation!: string;
	/** AuthenticationMethod z.B. Basic, Digest, NTLM, Negotiate, JWT */
	public u8sAuthenticationMethod!: string;
	/** Authentication credentials */
	public sAuthCredetials!: Uint8Array;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnLogonResult
// [PrintTSSeqDefCode] AsnLogonResult
/**
 * bei erfolgreichem login bekommt der user seine Kontaktdaten zurÃ¼ck
 */
export class AsnLogonResult {
	public constructor(that: AsnLogonResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLogonResult {
		return new AsnLogonResult({
			u8sLoggedInUser: "",
			iValidContact: 0,
			ownContact: ENetUC_Common_AsnContact.AsnContact.initEmpty()
		});
	}

	/** Der CtiUserName der eingeloggt wurde. */
	public u8sLoggedInUser!: string;
	/** Wenn erfolgreich ist dieser Wert immer 1 und der ownContact gefÃ¼llt. */
	public iValidContact!: number;
	/** Die eigenen ActiveContact daten */
	public ownContact!: ENetUC_Common_AsnContact.AsnContact;
	/** Optionale Parameter */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
	/** Die STUN\/TURN Konfiguration fuer SIP-Softphone und Audio\/Video call. */
	public listConfigSTUNandTURN?: ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList;
	/** Die Server die zu dem Rechnerverbund gehÃ¶ren, an den man sich gerade gÃ¼ltg angemeldet hat. */
	public listDataCenterServers?: ENetUC_Common.UTF8StringList;
}

// [PrintTSTypeDefCode] AsnLogonError
// [PrintTSSeqDefCode] AsnLogonError
export class AsnLogonError {
	public constructor(that: AsnLogonError) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLogonError {
		return new AsnLogonError({
			iErrorDetail: 0,
			u8sErrorString: "",
			sAuthCredetials: new Uint8Array(0),
			u8sFailedUser: ""
		});
	}

	/** Logon error detail, see AsnLogonErrorEnum. */
	public iErrorDetail!: number;
	public u8sErrorString!: string;
	/** Server Authentication credentials for SSPI */
	public sAuthCredetials!: Uint8Array;
	/** This field contains the CtiUserName that was not able to log in. (This is only required for clninst to detect when a user is not enabled) */
	public u8sFailedUser!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnLogoffArgument
// [PrintTSSeqDefCode] AsnLogoffArgument
export class AsnLogoffArgument {
	public constructor(that?: AsnLogoffArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLogoffArgument {
		return new AsnLogoffArgument();
	}

	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetUserTokenResult
// [PrintTSSeqDefCode] AsnGetUserTokenResult
/**
 * Result of asnGetUserToken.
 */
export class AsnGetUserTokenResult {
	public constructor(that: AsnGetUserTokenResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetUserTokenResult {
		return new AsnGetUserTokenResult({
			sToken: ""
		});
	}

	/** JWT Token */
	public sToken!: string;
}

// [PrintTSTypeDefCode] AsnSetClientAuthenticationArgument
// [PrintTSSeqDefCode] AsnSetClientAuthenticationArgument
export class AsnSetClientAuthenticationArgument {
	public constructor(that: AsnSetClientAuthenticationArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientAuthenticationArgument {
		return new AsnSetClientAuthenticationArgument({
			iCtiClientAppID: 0,
			sClientAuthentication: new Uint8Array(0)
		});
	}

	/** ApplicationID des Clients (enumClientAppID::ECLIENTAPPID_ Konstanten) in applicationtypes.h */
	public iCtiClientAppID!: number;
	/** sClientAuthenticate timestamp, */
	public sClientAuthentication!: Uint8Array;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnSetClientAuthenticationResult
// [PrintTSSeqDefCode] AsnSetClientAuthenticationResult
export class AsnSetClientAuthenticationResult {
	public constructor(that: AsnSetClientAuthenticationResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnSetClientAuthenticationResult {
		return new AsnSetClientAuthenticationResult({
			bSuccess: false
		});
	}

	public bSuccess!: boolean;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnProfileAttribute
// [PrintTSSeqDefCode] AsnProfileAttribute
/**
 * Profile values added as public claim as [Service_type]\/[Profile elementname] : [Profile elementvalue] used by asnGetUserToken.
 */
export class AsnProfileAttribute {
	public constructor(that: AsnProfileAttribute) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnProfileAttribute {
		return new AsnProfileAttribute({
			u8sServiceType: "",
			u8seName: ""
		});
	}

	/** Service Type used as a namespace for a specific profile attribute */
	public u8sServiceType!: string;
	public u8seName!: string;
	/** Can be used as placeholder is the profile attribute does not exits */
	public u8sValue?: string;
}

// [PrintTSTypeDefCode] AsnGetLoggedInContactArgument
// [PrintTSSeqDefCode] AsnGetLoggedInContactArgument
/**
 * Argument for asnGetLoggedInContact.
 * This opertaion does not need any parameter since the logged in contact is alread well known on the server.
 */
export class AsnGetLoggedInContactArgument {
	public constructor(that?: AsnGetLoggedInContactArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLoggedInContactArgument {
		return new AsnGetLoggedInContactArgument();
	}
}

// [PrintTSTypeDefCode] AsnGetLoggedInContactResult
// [PrintTSSeqDefCode] AsnGetLoggedInContactResult
/**
 * Result of asnGetLoggedInContact.
 */
export class AsnGetLoggedInContactResult {
	public constructor(that: AsnGetLoggedInContactResult) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetLoggedInContactResult {
		return new AsnGetLoggedInContactResult({
			u8sLoggedInUser: "",
			ownContact: ENetUC_Common_AsnContact.AsnContact.initEmpty()
		});
	}

	/** The Login User Name */
	public u8sLoggedInUser!: string;
	/** Own Contact Data */
	public ownContact!: ENetUC_Common_AsnContact.AsnContact;
}

// [PrintTSTypeDefCode] AsnAuthenticationChoice
// [PrintTSChoiceDefCode] AsnAuthenticationChoice
export class AsnAuthenticationChoice {
	public constructor(that?: AsnAuthenticationChoice) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAuthenticationChoice {
		return new AsnAuthenticationChoice();
	}

	public simple?: AsnAuthenticationSimple = undefined;
	public simplecrypt?: AsnAuthenticationSimpleCrypt = undefined;
	public winuser?: AsnAuthenticationWinuser = undefined;
	public enhanced?: AsnAuthenticationEnhanced = undefined;
}

// [PrintTSTypeDefCode] AsnAdminLogin
// [PrintTSSeqDefCode] AsnAdminLogin
/**
 * UCServer administrator login credentials used as optional by AsnGetUserTokenArgument
 */
export class AsnAdminLogin {
	public constructor(that: AsnAdminLogin) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAdminLogin {
		return new AsnAdminLogin({
			u8sAdminUsername: "",
			adminPassword: AsnAdminLoginChoice.initEmpty()
		});
	}

	/** Adminstrator user name */
	public u8sAdminUsername!: string;
	/** Administrator password */
	public adminPassword!: AsnAdminLoginChoice;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnProfileAttributeList
// [PrintTSSetOfDefCode] AsnProfileAttributeList
export class AsnProfileAttributeList extends Array<AsnProfileAttribute> { }

// [PrintTSTypeDefCode] AsnLogonArgument
// [PrintTSSeqDefCode] AsnLogonArgument
export class AsnLogonArgument {
	public constructor(that: AsnLogonArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLogonArgument {
		return new AsnLogonArgument({
			iVersion: 0,
			u8sVersion: "",
			iAttachToContact: 0,
			u8sClientComputerName: "",
			authentication: AsnAuthenticationChoice.initEmpty()
		});
	}

	/** version of the protocol */
	public iVersion!: number;
	/** Client Version (z.B. 3.0.0.100) */
	public u8sVersion!: string;
	/** Event notification Option */
	public iAttachToContact!: number;
	/** Computer Name - for Display Purposes */
	public u8sClientComputerName!: string;
	/** Authentication Options */
	public authentication!: AsnAuthenticationChoice;
	/** optionals */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnGetUserTokenArgument
// [PrintTSSeqDefCode] AsnGetUserTokenArgument
/**
 * Argument for asnGetUserToken.
 */
export class AsnGetUserTokenArgument {
	public constructor(that?: AsnGetUserTokenArgument) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnGetUserTokenArgument {
		return new AsnGetUserTokenArgument();
	}

	/** Type of token (default eUserTokenExternal) */
	public iType?: number;
	/** Desired Time to live in seconds (default 24 hours) */
	public iTTL?: number;
	/** List of Service Types e.g. FAQ, RANKING */
	public lstServiceType?: ENetUC_Common.UTF8StringList;
	/** List from profile values added as public claim as [Service_type]\/[Profile elementname] : [Profile elementvalue] */
	public lstProfileAttributes?: AsnProfileAttributeList;
	/** Admin login credentials to create token with admin rights */
	public adminLogin?: AsnAdminLogin;
}
