// [PrintTSConverterCode]
// [PrintTSConverterComments]
/*
 * ENetUC_Auth_Converter.ts
 * "UC-Server-Access-Protocol-Auth" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

// [PrintTSConverterImports]
import { FromJSONContext, ParsingError, ParsingErrors, ParsingErrorType, ToJSONContext, TSConverter } from "./TSConverterBase";
import * as ENetUC_Auth from "./ENetUC_Auth";

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_AsnContact from "./ENetUC_Common_AsnContact";
import * as ENetUC_Common_Converter from "./ENetUC_Common_Converter";
import * as ENetUC_Common_SIPCTI_Converter from "./ENetUC_Common_SIPCTI_Converter";
import * as ENetUC_Common_AsnContact_Converter from "./ENetUC_Common_AsnContact_Converter";

// [PrintTSEncoderDecoderCode] AsnAdminLoginChoice
export class AsnAdminLoginChoice_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnAdminLoginChoice, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderChoiceDefCode] AsnAdminLoginChoice
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAdminLoginChoice", newContext);
		if (obj.u8sAdminPassword != null)
			value += TSConverter.addStringParam("u8sAdminPassword", obj.u8sAdminPassword, newContext);
		else if (obj.u8sAdminPasswordCrypted != null)
			value += TSConverter.addStringParam("u8sAdminPasswordCrypted", obj.u8sAdminPasswordCrypted, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnAdminLoginChoice, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAdminLoginChoice");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAdminLoginChoice"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnAdminLoginChoice;
		if (data !== undefined) {
			// [PrintTSDecoderChoiceDefCode] AsnAdminLoginChoice
			newContext.bLaxParsing = false;
			if (TSConverter.validateChoiceParam(data, "u8sAdminPassword", "string", undefined, newContext))
				obj.u8sAdminPassword = data.u8sAdminPassword;
			else if (TSConverter.validateChoiceParam(data, "u8sAdminPasswordCrypted", "string", undefined, newContext))
				obj.u8sAdminPasswordCrypted = data.u8sAdminPasswordCrypted;
			else if (!(optional === true))
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAdminLoginChoice"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNegotiateServerConnectionArgument
export class AsnNegotiateServerConnectionArgument_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnNegotiateServerConnectionArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNegotiateServerConnectionArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNegotiateServerConnectionArgument", newContext);
		value += TSConverter.addNumberParam("iClientProtocolVersion", obj.iClientProtocolVersion, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnNegotiateServerConnectionArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNegotiateServerConnectionArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNegotiateServerConnectionArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnNegotiateServerConnectionArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNegotiateServerConnectionArgument
			if (TSConverter.validateParam(data, "iClientProtocolVersion", "number", errors, newContext, false))
				obj.iClientProtocolVersion = data.iClientProtocolVersion;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNegotiateServerConnectionArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnNegotiateServerConnectionResult
export class AsnNegotiateServerConnectionResult_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnNegotiateServerConnectionResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnNegotiateServerConnectionResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnNegotiateServerConnectionResult", newContext);
		value += TSConverter.addNumberParam("iServerProtocolVersion", obj.iServerProtocolVersion, newContext);
		value += TSConverter.addNumberParam("iServerUMSecurityOption", obj.iServerUMSecurityOption, newContext);
		value += TSConverter.addNumberParam("iServerTLSSecurityOption", obj.iServerTLSSecurityOption, newContext);
		value += TSConverter.addBinaryParam("sServerAuthentication", obj.sServerAuthentication, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addDateParam("stServerTime", obj.stServerTime, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnNegotiateServerConnectionResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnNegotiateServerConnectionResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnNegotiateServerConnectionResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnNegotiateServerConnectionResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnNegotiateServerConnectionResult
			if (TSConverter.validateParam(data, "iServerProtocolVersion", "number", errors, newContext, false))
				obj.iServerProtocolVersion = data.iServerProtocolVersion;
			if (TSConverter.validateParam(data, "iServerUMSecurityOption", "number", errors, newContext, false))
				obj.iServerUMSecurityOption = data.iServerUMSecurityOption;
			if (TSConverter.validateParam(data, "iServerTLSSecurityOption", "number", errors, newContext, false))
				obj.iServerTLSSecurityOption = data.iServerTLSSecurityOption;
			if (TSConverter.validateParam(data, "sServerAuthentication", "string", errors, newContext, false))
				obj.sServerAuthentication = TSConverter.decode64(data.sServerAuthentication.toString());
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
			if (TSConverter.validateParam(data, "stServerTime", "string", errors, newContext, true) && data.stServerTime)
				obj.stServerTime = new Date(data.stServerTime);
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnNegotiateServerConnectionResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAuthenticationSimple
export class AsnAuthenticationSimple_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnAuthenticationSimple, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAuthenticationSimple
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAuthenticationSimple", newContext);
		value += TSConverter.addStringParam("u8sClientUsername", obj.u8sClientUsername, newContext);
		value += TSConverter.addStringParam("u8sClientUserPassword", obj.u8sClientUserPassword, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnAuthenticationSimple, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAuthenticationSimple");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationSimple"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnAuthenticationSimple;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAuthenticationSimple
			if (TSConverter.validateParam(data, "u8sClientUsername", "string", errors, newContext, false))
				obj.u8sClientUsername = data.u8sClientUsername;
			if (TSConverter.validateParam(data, "u8sClientUserPassword", "string", errors, newContext, false))
				obj.u8sClientUserPassword = data.u8sClientUserPassword;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationSimple"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAuthenticationSimpleCrypt
export class AsnAuthenticationSimpleCrypt_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnAuthenticationSimpleCrypt, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAuthenticationSimpleCrypt
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAuthenticationSimpleCrypt", newContext);
		value += TSConverter.addStringParam("u8sClientUsername", obj.u8sClientUsername, newContext);
		value += TSConverter.addStringParam("u8sClientUserPasswordCrypted", obj.u8sClientUserPasswordCrypted, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnAuthenticationSimpleCrypt, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAuthenticationSimpleCrypt");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationSimpleCrypt"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnAuthenticationSimpleCrypt;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAuthenticationSimpleCrypt
			if (TSConverter.validateParam(data, "u8sClientUsername", "string", errors, newContext, false))
				obj.u8sClientUsername = data.u8sClientUsername;
			if (TSConverter.validateParam(data, "u8sClientUserPasswordCrypted", "string", errors, newContext, false))
				obj.u8sClientUserPasswordCrypted = data.u8sClientUserPasswordCrypted;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationSimpleCrypt"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAuthenticationWinuser
export class AsnAuthenticationWinuser_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnAuthenticationWinuser, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAuthenticationWinuser
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAuthenticationWinuser", newContext);
		value += TSConverter.addStringParam("u8sClientUsername", obj.u8sClientUsername, newContext);
		value += TSConverter.addStringParam("u8sClientUsernameAuthorisation", obj.u8sClientUsernameAuthorisation, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnAuthenticationWinuser, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAuthenticationWinuser");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationWinuser"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnAuthenticationWinuser;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAuthenticationWinuser
			if (TSConverter.validateParam(data, "u8sClientUsername", "string", errors, newContext, false))
				obj.u8sClientUsername = data.u8sClientUsername;
			if (TSConverter.validateParam(data, "u8sClientUsernameAuthorisation", "string", errors, newContext, false))
				obj.u8sClientUsernameAuthorisation = data.u8sClientUsernameAuthorisation;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationWinuser"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAuthenticationEnhanced
export class AsnAuthenticationEnhanced_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnAuthenticationEnhanced, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAuthenticationEnhanced
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAuthenticationEnhanced", newContext);
		value += TSConverter.addStringParam("u8sClientUsername", obj.u8sClientUsername, newContext);
		value += TSConverter.addStringParam("u8sClientUsernameAuthorisation", obj.u8sClientUsernameAuthorisation, newContext);
		value += TSConverter.addStringParam("u8sAuthenticationMethod", obj.u8sAuthenticationMethod, newContext);
		value += TSConverter.addBinaryParam("sAuthCredetials", obj.sAuthCredetials, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnAuthenticationEnhanced, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAuthenticationEnhanced");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationEnhanced"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnAuthenticationEnhanced;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAuthenticationEnhanced
			if (TSConverter.validateParam(data, "u8sClientUsername", "string", errors, newContext, false))
				obj.u8sClientUsername = data.u8sClientUsername;
			if (TSConverter.validateParam(data, "u8sClientUsernameAuthorisation", "string", errors, newContext, false))
				obj.u8sClientUsernameAuthorisation = data.u8sClientUsernameAuthorisation;
			if (TSConverter.validateParam(data, "u8sAuthenticationMethod", "string", errors, newContext, false))
				obj.u8sAuthenticationMethod = data.u8sAuthenticationMethod;
			if (TSConverter.validateParam(data, "sAuthCredetials", "string", errors, newContext, false))
				obj.sAuthCredetials = TSConverter.decode64(data.sAuthCredetials.toString());
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationEnhanced"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLogonResult
export class AsnLogonResult_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnLogonResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnLogonResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLogonResult", newContext);
		value += TSConverter.addStringParam("u8sLoggedInUser", obj.u8sLoggedInUser, newContext);
		value += TSConverter.addNumberParam("iValidContact", obj.iValidContact, newContext);
		value += TSConverter.addObjectAsStringParam("ownContact", ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toJSON(obj.ownContact, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("listConfigSTUNandTURN", ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.toJSON(obj.listConfigSTUNandTURN, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("listDataCenterServers", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.listDataCenterServers, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnLogonResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLogonResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLogonResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnLogonResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnLogonResult
			if (TSConverter.validateParam(data, "u8sLoggedInUser", "string", errors, newContext, false))
				obj.u8sLoggedInUser = data.u8sLoggedInUser;
			if (TSConverter.validateParam(data, "iValidContact", "number", errors, newContext, false))
				obj.iValidContact = data.iValidContact;
			const tscv_owncontact = ENetUC_Common_AsnContact.AsnContact.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromJSON(data.ownContact, tscv_owncontact, errors, newContext, "ownContact", false))
				obj.ownContact = tscv_owncontact;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
			const tscv_listconfigstunandturn = new ENetUC_Common_SIPCTI.AsnConfigSTUNandTURNList();
			if (ENetUC_Common_SIPCTI_Converter.AsnConfigSTUNandTURNList_Converter.fromJSON(data.listConfigSTUNandTURN, tscv_listconfigstunandturn, errors, newContext, "listConfigSTUNandTURN", true))
				obj.listConfigSTUNandTURN = tscv_listconfigstunandturn;
			const tscv_listdatacenterservers = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.listDataCenterServers, tscv_listdatacenterservers, errors, newContext, "listDataCenterServers", true))
				obj.listDataCenterServers = tscv_listdatacenterservers;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLogonResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLogonError
export class AsnLogonError_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnLogonError, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnLogonError
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLogonError", newContext);
		value += TSConverter.addNumberParam("iErrorDetail", obj.iErrorDetail, newContext);
		value += TSConverter.addStringParam("u8sErrorString", obj.u8sErrorString, newContext);
		value += TSConverter.addBinaryParam("sAuthCredetials", obj.sAuthCredetials, newContext);
		value += TSConverter.addStringParam("u8sFailedUser", obj.u8sFailedUser, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnLogonError, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLogonError");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLogonError"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnLogonError;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnLogonError
			if (TSConverter.validateParam(data, "iErrorDetail", "number", errors, newContext, false))
				obj.iErrorDetail = data.iErrorDetail;
			if (TSConverter.validateParam(data, "u8sErrorString", "string", errors, newContext, false))
				obj.u8sErrorString = data.u8sErrorString;
			if (TSConverter.validateParam(data, "sAuthCredetials", "string", errors, newContext, false))
				obj.sAuthCredetials = TSConverter.decode64(data.sAuthCredetials.toString());
			if (TSConverter.validateParam(data, "u8sFailedUser", "string", errors, newContext, false))
				obj.u8sFailedUser = data.u8sFailedUser;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLogonError"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLogoffArgument
export class AsnLogoffArgument_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnLogoffArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnLogoffArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLogoffArgument", newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnLogoffArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLogoffArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLogoffArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnLogoffArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnLogoffArgument
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLogoffArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetUserTokenResult
export class AsnGetUserTokenResult_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnGetUserTokenResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetUserTokenResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetUserTokenResult", newContext);
		value += TSConverter.addStringParam("sToken", obj.sToken, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnGetUserTokenResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetUserTokenResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetUserTokenResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnGetUserTokenResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetUserTokenResult
			if (TSConverter.validateParam(data, "sToken", "string", errors, newContext, false))
				obj.sToken = data.sToken;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetUserTokenResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetClientAuthenticationArgument
export class AsnSetClientAuthenticationArgument_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnSetClientAuthenticationArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetClientAuthenticationArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetClientAuthenticationArgument", newContext);
		value += TSConverter.addNumberParam("iCtiClientAppID", obj.iCtiClientAppID, newContext);
		value += TSConverter.addBinaryParam("sClientAuthentication", obj.sClientAuthentication, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnSetClientAuthenticationArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetClientAuthenticationArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientAuthenticationArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnSetClientAuthenticationArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetClientAuthenticationArgument
			if (TSConverter.validateParam(data, "iCtiClientAppID", "number", errors, newContext, false))
				obj.iCtiClientAppID = data.iCtiClientAppID;
			if (TSConverter.validateParam(data, "sClientAuthentication", "string", errors, newContext, false))
				obj.sClientAuthentication = TSConverter.decode64(data.sClientAuthentication.toString());
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientAuthenticationArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnSetClientAuthenticationResult
export class AsnSetClientAuthenticationResult_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnSetClientAuthenticationResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnSetClientAuthenticationResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnSetClientAuthenticationResult", newContext);
		value += TSConverter.addBooleanParam("bSuccess", obj.bSuccess, newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnSetClientAuthenticationResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnSetClientAuthenticationResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientAuthenticationResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnSetClientAuthenticationResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnSetClientAuthenticationResult
			if (TSConverter.validateParam(data, "bSuccess", "boolean", errors, newContext, false))
				obj.bSuccess = data.bSuccess;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnSetClientAuthenticationResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnProfileAttribute
export class AsnProfileAttribute_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnProfileAttribute, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnProfileAttribute
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnProfileAttribute", newContext);
		value += TSConverter.addStringParam("u8sServiceType", obj.u8sServiceType, newContext);
		value += TSConverter.addStringParam("u8seName", obj.u8seName, newContext);
		value += TSConverter.addStringParam("u8sValue", obj.u8sValue, newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnProfileAttribute, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnProfileAttribute");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnProfileAttribute"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnProfileAttribute;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnProfileAttribute
			if (TSConverter.validateParam(data, "u8sServiceType", "string", errors, newContext, false))
				obj.u8sServiceType = data.u8sServiceType;
			if (TSConverter.validateParam(data, "u8seName", "string", errors, newContext, false))
				obj.u8seName = data.u8seName;
			if (TSConverter.validateParam(data, "u8sValue", "string", errors, newContext, true))
				obj.u8sValue = data.u8sValue;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnProfileAttribute"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetLoggedInContactArgument
export class AsnGetLoggedInContactArgument_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnGetLoggedInContactArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetLoggedInContactArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetLoggedInContactArgument", newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnGetLoggedInContactArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetLoggedInContactArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLoggedInContactArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnGetLoggedInContactArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetLoggedInContactArgument
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLoggedInContactArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetLoggedInContactResult
export class AsnGetLoggedInContactResult_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnGetLoggedInContactResult, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetLoggedInContactResult
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetLoggedInContactResult", newContext);
		value += TSConverter.addStringParam("u8sLoggedInUser", obj.u8sLoggedInUser, newContext);
		value += TSConverter.addObjectAsStringParam("ownContact", ENetUC_Common_AsnContact_Converter.AsnContact_Converter.toJSON(obj.ownContact, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnGetLoggedInContactResult, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetLoggedInContactResult");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLoggedInContactResult"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnGetLoggedInContactResult;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetLoggedInContactResult
			if (TSConverter.validateParam(data, "u8sLoggedInUser", "string", errors, newContext, false))
				obj.u8sLoggedInUser = data.u8sLoggedInUser;
			const tscv_owncontact = ENetUC_Common_AsnContact.AsnContact.initEmpty();
			if (ENetUC_Common_AsnContact_Converter.AsnContact_Converter.fromJSON(data.ownContact, tscv_owncontact, errors, newContext, "ownContact", false))
				obj.ownContact = tscv_owncontact;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetLoggedInContactResult"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAuthenticationChoice
export class AsnAuthenticationChoice_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnAuthenticationChoice, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderChoiceDefCode] AsnAuthenticationChoice
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAuthenticationChoice", newContext);
		if (obj.simple != null)
			value += TSConverter.addObjectAsStringParam("simple", AsnAuthenticationSimple_Converter.toJSON(obj.simple, newContext), newContext);
		else if (obj.simplecrypt != null)
			value += TSConverter.addObjectAsStringParam("simplecrypt", AsnAuthenticationSimpleCrypt_Converter.toJSON(obj.simplecrypt, newContext), newContext);
		else if (obj.winuser != null)
			value += TSConverter.addObjectAsStringParam("winuser", AsnAuthenticationWinuser_Converter.toJSON(obj.winuser, newContext), newContext);
		else if (obj.enhanced != null)
			value += TSConverter.addObjectAsStringParam("enhanced", AsnAuthenticationEnhanced_Converter.toJSON(obj.enhanced, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnAuthenticationChoice, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAuthenticationChoice");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationChoice"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnAuthenticationChoice;
		if (data !== undefined) {
			// [PrintTSDecoderChoiceDefCode] AsnAuthenticationChoice
			newContext.bLaxParsing = false;
			const tscv_simple = ENetUC_Auth.AsnAuthenticationSimple.initEmpty();
			if (AsnAuthenticationSimple_Converter.fromJSON(data.simple, tscv_simple, undefined, newContext, "simple", optional))
				obj.simple = tscv_simple;
			else {
				const tscv_simplecrypt = ENetUC_Auth.AsnAuthenticationSimpleCrypt.initEmpty();
				if (AsnAuthenticationSimpleCrypt_Converter.fromJSON(data.simplecrypt, tscv_simplecrypt, undefined, newContext, "simplecrypt", optional))
					obj.simplecrypt = tscv_simplecrypt;
				else {
					const tscv_winuser = ENetUC_Auth.AsnAuthenticationWinuser.initEmpty();
					if (AsnAuthenticationWinuser_Converter.fromJSON(data.winuser, tscv_winuser, undefined, newContext, "winuser", optional))
						obj.winuser = tscv_winuser;
					else {
						const tscv_enhanced = ENetUC_Auth.AsnAuthenticationEnhanced.initEmpty();
						if (AsnAuthenticationEnhanced_Converter.fromJSON(data.enhanced, tscv_enhanced, undefined, newContext, "enhanced", optional))
							obj.enhanced = tscv_enhanced;
						else if (!(optional === true))
							errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "Property has not been filled"));
					}
				}
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAuthenticationChoice"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnAdminLogin
export class AsnAdminLogin_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnAdminLogin, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnAdminLogin
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnAdminLogin", newContext);
		value += TSConverter.addStringParam("u8sAdminUsername", obj.u8sAdminUsername, newContext);
		value += TSConverter.addObjectAsStringParam("adminPassword", AsnAdminLoginChoice_Converter.toJSON(obj.adminPassword, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnAdminLogin, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnAdminLogin");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnAdminLogin"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnAdminLogin;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnAdminLogin
			if (TSConverter.validateParam(data, "u8sAdminUsername", "string", errors, newContext, false))
				obj.u8sAdminUsername = data.u8sAdminUsername;
			const tscv_adminpassword = ENetUC_Auth.AsnAdminLoginChoice.initEmpty();
			if (AsnAdminLoginChoice_Converter.fromJSON(data.adminPassword, tscv_adminpassword, errors, newContext, "adminPassword", false))
				obj.adminPassword = tscv_adminpassword;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnAdminLogin"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnProfileAttributeList
export class AsnProfileAttributeList_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnProfileAttributeList, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSetOfDefCode] AsnProfileAttributeList
		const newContext = TSConverter.increaseIndent(context);
		if (obj.length) {
			value += TSConverter.addIndented("", context, "[\n");
			let bFirst = true;
			for (const id in obj) {
				if (!Object.prototype.hasOwnProperty.call(obj, id))
					continue;
				if (!bFirst) {
					value += ",";
					if (context?.bPrettyPrint)
						value += "\n";
				}
				value += AsnProfileAttribute_Converter.toJSON(obj[id], newContext);
				bFirst = false;
			}
			value += "\n";
			value += TSConverter.addIndented("", context, "]");
		} else
			value += TSConverter.addIndented("", context, "[]");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnProfileAttributeList, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnProfileAttributeList");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnProfileAttributeList"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnProfileAttributeList;
		if (data !== undefined) {
			// [PrintTSDecoderSetOfDefCode] AsnProfileAttributeList
			for (const id in data) {
				const elem = data[id];
				if (elem === undefined)
					continue;
				const entry = ENetUC_Auth.AsnProfileAttribute.initEmpty();
				if (AsnProfileAttribute_Converter.fromJSON(elem, entry, errors, newContext, "AsnProfileAttribute", optional))
					obj.push(entry);
			}
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnProfileAttributeList"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnLogonArgument
export class AsnLogonArgument_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnLogonArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnLogonArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnLogonArgument", newContext);
		value += TSConverter.addNumberParam("iVersion", obj.iVersion, newContext);
		value += TSConverter.addStringParam("u8sVersion", obj.u8sVersion, newContext);
		value += TSConverter.addNumberParam("iAttachToContact", obj.iAttachToContact, newContext);
		value += TSConverter.addStringParam("u8sClientComputerName", obj.u8sClientComputerName, newContext);
		value += TSConverter.addObjectAsStringParam("authentication", AsnAuthenticationChoice_Converter.toJSON(obj.authentication, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("optionalParams", ENetUC_Common_Converter.AsnOptionalParameters_Converter.toJSON(obj.optionalParams, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnLogonArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnLogonArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnLogonArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnLogonArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnLogonArgument
			if (TSConverter.validateParam(data, "iVersion", "number", errors, newContext, false))
				obj.iVersion = data.iVersion;
			if (TSConverter.validateParam(data, "u8sVersion", "string", errors, newContext, false))
				obj.u8sVersion = data.u8sVersion;
			if (TSConverter.validateParam(data, "iAttachToContact", "number", errors, newContext, false))
				obj.iAttachToContact = data.iAttachToContact;
			if (TSConverter.validateParam(data, "u8sClientComputerName", "string", errors, newContext, false))
				obj.u8sClientComputerName = data.u8sClientComputerName;
			const tscv_authentication = ENetUC_Auth.AsnAuthenticationChoice.initEmpty();
			if (AsnAuthenticationChoice_Converter.fromJSON(data.authentication, tscv_authentication, errors, newContext, "authentication", false))
				obj.authentication = tscv_authentication;
			const tscv_optionalparams = new ENetUC_Common.AsnOptionalParameters();
			if (ENetUC_Common_Converter.AsnOptionalParameters_Converter.fromJSON(data.optionalParams, tscv_optionalparams, errors, newContext, "optionalParams", true))
				obj.optionalParams = tscv_optionalparams;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnLogonArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}

// [PrintTSEncoderDecoderCode] AsnGetUserTokenArgument
export class AsnGetUserTokenArgument_Converter {
	public static toJSON(obj?: ENetUC_Auth.AsnGetUserTokenArgument, context?: ToJSONContext): string {
		if (obj == null)
			return "";

		let value = "";

		// [PrintTSEncoderSeqDefCode] AsnGetUserTokenArgument
		const newContext = TSConverter.increaseIndent(context);
		value += TSConverter.addIndented("", context, "{\n");
		if (newContext.bAddTypes)
			value += TSConverter.addStringParam("_type", "AsnGetUserTokenArgument", newContext);
		value += TSConverter.addNumberParam("iType", obj.iType, newContext);
		value += TSConverter.addNumberParam("iTTL", obj.iTTL, newContext);
		value += TSConverter.addObjectAsStringParam("lstServiceType", ENetUC_Common_Converter.UTF8StringList_Converter.toJSON(obj.lstServiceType, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("lstProfileAttributes", AsnProfileAttributeList_Converter.toJSON(obj.lstProfileAttributes, newContext), newContext);
		value += TSConverter.addObjectAsStringParam("adminLogin", AsnAdminLogin_Converter.toJSON(obj.adminLogin, newContext), newContext);
		value += TSConverter.addIndented("\n", context, "}");

		return value;
	}

	public static fromJSON(dataarg: object | string | undefined, obj: ENetUC_Auth.AsnGetUserTokenArgument, errors?: ParsingErrors, context?: FromJSONContext, parametername?: string, optional?: boolean): boolean {
		const bFirstCaller = context ? context.context === "" : false;
		if (errors == null)
			errors = new ParsingErrors();
		const newContext = TSConverter.addContext(context, parametername, "AsnGetUserTokenArgument");

		if (dataarg == null) {
			if (!(optional === true)) {
				if (bFirstCaller)
					errors.push(new ParsingError(undefined, undefined, "Errors while parsing AsnGetUserTokenArgument"));
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, newContext.context, "property missing"));
			}
			return false;
		}
		const data = TSConverter.prepareData(dataarg, errors, newContext) as ENetUC_Auth.AsnGetUserTokenArgument;
		if (data !== undefined) {
			// [PrintTSDecoderSeqDefCode] AsnGetUserTokenArgument
			if (TSConverter.validateParam(data, "iType", "number", errors, newContext, true))
				obj.iType = data.iType;
			if (TSConverter.validateParam(data, "iTTL", "number", errors, newContext, true))
				obj.iTTL = data.iTTL;
			const tscv_lstservicetype = new ENetUC_Common.UTF8StringList();
			if (ENetUC_Common_Converter.UTF8StringList_Converter.fromJSON(data.lstServiceType, tscv_lstservicetype, errors, newContext, "lstServiceType", true))
				obj.lstServiceType = tscv_lstservicetype;
			const tscv_lstprofileattributes = new ENetUC_Auth.AsnProfileAttributeList();
			if (AsnProfileAttributeList_Converter.fromJSON(data.lstProfileAttributes, tscv_lstprofileattributes, errors, newContext, "lstProfileAttributes", true))
				obj.lstProfileAttributes = tscv_lstprofileattributes;
			const tscv_adminlogin = ENetUC_Auth.AsnAdminLogin.initEmpty();
			if (AsnAdminLogin_Converter.fromJSON(data.adminLogin, tscv_adminlogin, errors, newContext, "adminLogin", true))
				obj.adminLogin = tscv_adminlogin;
		}

		if (bFirstCaller && errors.length)
			errors.unshift(new ParsingError(undefined, undefined, "Errors while parsing AsnGetUserTokenArgument"));

		return newContext.bLaxParsing ? true : errors.length === 0;
	}
}
