import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Collapse, IconButton, Stack, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "../../zustand/store";

interface IWithErrorBannerProps {
	icon?: IconProp;
	spinner?: boolean;
	showCloseIcon?: boolean;
}

export const ErrorBanner = ({ spinner, icon, showCloseIcon }: IWithErrorBannerProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const smSetBanner = useStore((state) => state.smSetBanner);
	const banner = useStore((state) => state.banner);

	const message = useMemo(() => {
		switch (banner) {
			case "noServerConnection":
				return t<string>("IDS_ERROR_NOSERVER");
			default:
				return "";
		}
	}, [banner, t]);

	return (
		<Collapse in={!!banner}>
			<Stack
				sx={{
					width: "100%",
					height: "52px",
					justifyContent: "center",
					backgroundColor: theme.palette.error.main,
					color: "white"
				}}
			>
				<Stack flexDirection={"row"} alignItems="center" justifyContent={"center"}>
					{spinner ? <CircularProgress size={25} variant="indeterminate" sx={{ color: "white", mr: 1 }} /> : null}
					{icon ? <FontAwesomeIcon id="errb-icon" icon={icon} /> : null}
					<Stack>{message}</Stack>
					{showCloseIcon ? (
						<Tooltip title={t<string>("IDS_CLOSE")} placement="bottom">
							<IconButton
								sx={{ position: "absolute", right: "10px", color: "white" }}
								onClick={() => {
									smSetBanner(undefined);
								}}
							>
								<FontAwesomeIcon transform={"shrink-1"} icon={faClose} />
							</IconButton>
						</Tooltip>
					) : null}
				</Stack>
			</Stack>
		</Collapse>
	);
};
