// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_Common_SIPCTI.ts
 * "UC-Server-Access-Protocol-SIPCTICommon" ASN.1 stubs.
 * This file was generated by estos esnacc 4.x
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */

/**
 * Common interface definitions for SIP and CTI functionality
 * ## Module description
 * This module contains common usable strutures for the sip and cti interfaces.
 *
 * THERE SHOULD NOT BE ANY FUNCTION\/OPERATION DEFINTIONS IN THIS MODULE.
 */

// [printTSImports]
import * as ENetUC_Common from "./ENetUC_Common";

// [PrintTSTypeDefCode] EnumProjectType
// [PrintTSEnumDefCode] EnumProjectType
export enum EnumProjectType {
	ePROJECTTYPEUNKNOWN = 0,
	ePROJECTTYPEPBX = 1,
	ePROJECTTYPEJOURNAL = 2,
	ePROJECTTYPEJOURNALPBX = 3,
	ePROJECTTYPEPBXPRIVATE = 4
}

// [PrintTSTypeDefCode] AsnContactPhoneLineType
// [PrintTSEnumDefCode] AsnContactPhoneLineType
export enum AsnContactPhoneLineType {
	iPHONENUMBERTYPEALL = -1,
	iPHONENUMBERTYPEUNKNOWN = 0,
	iPHONENUMBERTYPEPRIMARY = 1,
	iPHONENUMBERTYPESECONDARY = 2,
	iPHONENUMBERTYPEOTHEROWNNUMBER = 3
}

// [PrintTSTypeDefCode] EnumSTUNandTURNType
// [PrintTSEnumDefCode] EnumSTUNandTURNType
export enum EnumSTUNandTURNType {
	eSTUN = 1,
	eTURN = 2
}

// [PrintTSTypeDefCode] AsnProjectPinRule
// [PrintTSSeqDefCode] AsnProjectPinRule
/**
 * Description of a project or pin used in the PBX.
 * Some PBX support the use of special numbers as prefix\/postfix to dialing numbers to implement project
 * features. This sequence\/object conatins the neccassary information to complete or format phone numbers according these rules.
 */
export class AsnProjectPinRule {
	public constructor(that: AsnProjectPinRule) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnProjectPinRule {
		return new AsnProjectPinRule({
			u8sProjectName: "",
			u8sProjectPin: "",
			iProjectType: 0,
			bAssociatedCallIsPrivateCall: false,
			u8sLocationID: "",
			u8sUserEnteredPin: "",
			u8sUserEnteredUserID: ""
		});
	}

	public u8sProjectName!: string;
	public u8sProjectPin!: string;
	public iProjectType!: number;
	public bAssociatedCallIsPrivateCall!: boolean;
	public u8sLocationID!: string;
	public u8sUserEnteredPin!: string;
	public u8sUserEnteredUserID!: string;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnConfigSTUNandTURN
// [PrintTSSeqDefCode] AsnConfigSTUNandTURN
/**
 * Sequence describes credentials and\/or addresses of STUN\/TURN Servers.
 * See http:\/\/tools.ietf.org\/html\/draft-uberti-behave-turn-rest-00, mainly section 2.2 Response.
 * This sequence is used for both, STUN and TURN configurations.
 */
export class AsnConfigSTUNandTURN {
	public constructor(that: AsnConfigSTUNandTURN) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnConfigSTUNandTURN {
		return new AsnConfigSTUNandTURN({
			iType: 0,
			u8sUsername: "",
			u8sPassword: "",
			stValidTo: new Date(),
			listURIs: new ENetUC_Common.UTF8StringList(),
			iTTL: 0
		});
	}

	/** STUN or TURN Config; see EnumSTUNandTURNType. */
	public iType!: number;
	/** TURN username to use (STUN dont need this) */
	public u8sUsername!: string;
	/** TURN password to use (STUN dont need this) */
	public u8sPassword!: string;
	/** valid to timetamp in UTC */
	public stValidTo!: Date;
	/** URIs of STUN\/TURN services */
	public listURIs!: ENetUC_Common.UTF8StringList;
	/** Time To Live in seconds */
	public iTTL!: number;
}

// [PrintTSTypeDefCode] AsnLineForward
// [PrintTSSeqDefCode] AsnLineForward
/**
 * Line forward information object
 */
export class AsnLineForward {
	public constructor(that: AsnLineForward) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnLineForward {
		return new AsnLineForward({
			iInternalID: 0,
			iForwardController: 0,
			iForwardMode: 0,
			iBackEndSpecific: 0,
			iNoAnswerTime: 0,
			u8sDestination: "",
			u8sCallerIDList: new ENetUC_Common.UTF8StringList()
		});
	}

	/** Internal ID of this entry */
	public iInternalID!: number;
	/** Defines where the forwarding is taking place */
	public iForwardController!: number;
	/** Defines the forward mode */
	public iForwardMode!: number;
	/** In case of iForwardMode == eBACKENDSPECIFIC this contains the backend specific forward mode (e.g. TAPI LINEFORWARDMODE_ constants) */
	public iBackEndSpecific!: number;
	/** Time in seconds for a forward in case of no answer. 0 if the default time is used. */
	public iNoAnswerTime!: number;
	/** Destination number */
	public u8sDestination!: string;
	/** List of caller ids (supercanonic) if the forward is based on caller ids */
	public u8sCallerIDList!: ENetUC_Common.UTF8StringList;
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnProjectPinRuleList
// [PrintTSSetOfDefCode] AsnProjectPinRuleList
export class AsnProjectPinRuleList extends Array<AsnProjectPinRule> { }

// [PrintTSTypeDefCode] AsnCtiNewCallParams
// [PrintTSSeqDefCode] AsnCtiNewCallParams
/**
 * Additional information about a call, especial for initiate a call.
 * asnCtiMakeCall can be used to give the UCServer more information\/hints about how to handle a make call with AsnCtiNewCallParams.
 * It is possible to give data of a project or pin to use to dial the number, hide the own callerid, attach contact data to the call a.s.o.
 */
export class AsnCtiNewCallParams {
	public constructor(that: AsnCtiNewCallParams) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCtiNewCallParams {
		return new AsnCtiNewCallParams({
			projectPinRule: AsnProjectPinRule.initEmpty(),
			bHideMyCallerID: false
		});
	}

	/** Projekt informationen */
	public projectPinRule!: AsnProjectPinRule;
	/** Rufnummer unterdrÃ¼cken (true) oder Ã¼bertragen (false\/DEFAULT) */
	public bHideMyCallerID!: boolean;
	/** Optional NetDatabaseContact, wenn der Client gleich einen mitÃ¼bergeben will */
	public asnRemoteContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** Additionally fields added later in the key-value-field for backward compatibility.
	/** Additionally fields added later in the key-value-field for backward compatibility.
	/** - key \"Address\", Type DWORD, TAPI Adresse auf der gewÃ¤hlt werden soll
	/** - key \"FeatureCodeDialing\", Type bool, Diese Wahl ist fÃ¼r einen FeatureCode, die Wahl soll nicht sichtbar sein
	/** - key \"FindAndValidateProject\", Type UTF8String, Projekt anhand des Ã¼bergebenen Namens suchen
	/** - key \"ProjectErrorHandling\", Type Integer, Fehlerbehandlung wenn der Ã¼bergebene Projektname nicht gefunden wird
	/** - key \"DialRemoteOffice\", Type Integer, Diese Wahl soll als Remote Office Wahl durchgefÃ¼hrt werden (assozierte Wahl, RemoteOffice Nummer, Vermitteln auf Zielrufnummer) */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode] AsnConfigSTUNandTURNList
// [PrintTSSetOfDefCode] AsnConfigSTUNandTURNList
export class AsnConfigSTUNandTURNList extends Array<AsnConfigSTUNandTURN> { }

// [PrintTSTypeDefCode] AsnLineForwards
// [PrintTSSetOfDefCode] AsnLineForwards
export class AsnLineForwards extends Array<AsnLineForward> { }
