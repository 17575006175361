import type { OutlinedTextFieldProps as MuiOutlinedTextFieldProps } from "@mui/material";
import { InputAdornment, InputLabel, Stack, TextField as MuiTextField } from "@mui/material";
import React, { FC } from "react";
import { FieldError } from "react-hook-form";

import { InfoIcon } from "../Icon/InfoIcon";

interface ITextFieldProps extends Omit<MuiOutlinedTextFieldProps, "variant"> {
	fieldError?: FieldError;
	unit?: string;
	infoText?: string;
	fieldWarning?: string;
	disableHelperText?: boolean;
}

export const TextField: FC<ITextFieldProps> = React.forwardRef(
	({ fieldError, unit, InputProps, infoText, fieldWarning, sx, disableHelperText, ...props }, ref) => {
		const helperText = fieldError?.message ?? fieldWarning ?? " "; // need to keep space for error message to show
		return (
			<Stack direction="row" alignItems="top" ref={ref} sx={sx}>
				<Stack direction="column" width={"100%"}>
					<MuiTextField
						variant="outlined"
						margin="none"
						size="small"
						fullWidth
						helperText={disableHelperText ? undefined : helperText}
						InputProps={{
							...(unit ? { endAdornment: <InputAdornment position="end">{unit}</InputAdornment> } : {}),
							...InputProps
						}}
						{...props}
						sx={{
							"& label": {
								color: fieldWarning ? "orange" : undefined
							},
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: fieldError ? "red" : fieldWarning ? "orange" : "primary"
								},
								"&:hover fieldset": {
									borderColor: fieldError ? "red" : fieldWarning ? "orange" : "primary"
								}
							},
							"& .MuiFormHelperText-root": {
								color: fieldError ? "red" : fieldWarning ? "orange" : undefined
							}
						}}
						error={!!fieldError}
						color={fieldError ? "error" : fieldWarning ? "warning" : "primary"}
					/>
				</Stack>
				{infoText && (
					<InfoIcon
						tooltip={infoText}
						style={{ marginTop: 28, marginLeft: 20 }}
						color={"blue"}
						tooltipPlacement="right"
					/>
				)}
			</Stack>
		);
	}
);
