// Centralised code for the TypeScript converters.
// This file is embedded as resource file in the esnacc.exe ASN1 Compiler
// Do not directly edit or modify the code as it is machine generated and will be overwritten with every compilation

// prettier-ignore
/* eslint-disable no-debugger */

export enum ParsingErrorType {
	NO_ERROR = 0,
	PROPERTY_MISSING = 2,
	PROPERTY_NULLORUNDEFINED = 3,
	PROPERTY_TYPEMISMATCH = 4
}

/**
 * A parsing error entry with details about the error and context
 */
export class ParsingError {
	public errortype?: ParsingErrorType;
	public propertyname?: string;
	public debuginfo?: string;

	/**
	 * Constructs a parsing error object, simply stores the handed over arguments in the class
	 *
	 * @param errortype - The error type that let to the creation of the ParsingError
	 * @param propertyname - The name that was parsed
	 * @param debuginfo - Additional debug info
	 */
	public constructor(errortype?: ParsingErrorType, propertyname?: string, debuginfo?: string) {
		this.errortype = errortype;
		this.propertyname = propertyname;
		this.debuginfo = debuginfo;
	}

	/**
	 * Helper method that provides the parsing error as text for logging and debugging
	 *
	 * @param type - The error the method should provide as string
	 * @returns - the parsingerror as text or unknown if an unknown type was provided
	 */
	public static getErrorTypeString(type: ParsingErrorType): string {
		switch (type) {
			case ParsingErrorType.NO_ERROR:
				return "NO_ERROR";
			case ParsingErrorType.PROPERTY_MISSING:
				return "PROPERTY_MISSING";
			case ParsingErrorType.PROPERTY_NULLORUNDEFINED:
				return "PROPERTY_NULLORUNDEFINED";
			case ParsingErrorType.PROPERTY_TYPEMISMATCH:
				return "PROPERTY_TYPEMISMATCH";
			default:
				debugger;
				return "unknown";
		}
	}

	/**
	 * Helper method that provides an error description based on properties of the class
	 *
	 * @returns - A combined parsing error as text
	 */
	public getErrorDescription(): string {
		let errorDescription = "";
		if (this.errortype != null)
			errorDescription += "ERROR: " + ParsingError.getErrorTypeString(this.errortype);
		if (this.propertyname != null)
			errorDescription += " - PROPERTY: " + this.propertyname;
		if (this.debuginfo != null)
			errorDescription += " - " + this.debuginfo;
		return errorDescription;
	}
}

/**
 * An array of parsing errors
 */
export class ParsingErrors extends Array<ParsingError> {
	/**
	 * Helper method that provides all parsing errors as text
	 *
	 * @returns - All errors in the array as combined text \\n delimited
	 */
	public getDiagnostic(): string {
		let debuginfo = "";
		for (const error of this) {
			if (debuginfo.length)
				debuginfo += "\n";
			debuginfo += error.getErrorDescription();
		}
		return debuginfo;
	}
}

export interface IToJSONContext {
	// to get naked code (not pretty printed with newlines, and tabs) set this to false
	bPrettyPrint?: boolean;
	// Adds the type name to the generated output as _type="Typename"
	bAddTypes?: boolean;
	// Encodes optional params in the hand coded UCServer notation
	bUCServerOptionalParams?: boolean;
}

/**
 * Config how ToJSON should generate the JSON blob
 */
export class ToJSONContext implements IToJSONContext {
	// The current identiation in the tree
	public indentation = "";
	// Flag to see if we the currently added element is the first in a structure (requires no , newline infront)
	public bFirstElement = true;
	// to get naked code (not pretty printed with newlines, and tabs) set this to false
	public bPrettyPrint = false;
	// Adds the type name to the generated output as _type="Typename"
	public bAddTypes = true;
	// Encodes optional params in the hand coded UCServer notation
	public bUCServerOptionalParams = false;

	/**
	 * Constructs a ToJSONContext object, simply stores the handed over arguments in the class if provided
	 *
	 * @param args - Arguments on how to initialize the ToJSONContext
	 */
	public constructor(args?: IToJSONContext) {
		if (args?.bPrettyPrint !== undefined)
			this.bPrettyPrint = args.bPrettyPrint;
		if (args?.bAddTypes !== undefined)
			this.bAddTypes = args.bAddTypes;
		if (args?.bUCServerOptionalParams !== undefined)
			this.bUCServerOptionalParams = args.bUCServerOptionalParams;
	}
}

/**
 * Config how FromJSON should parse the JSON blob
 */
export class FromJSONContext {
	// Give the diagnostic elements the context of the element we are currently pasring to ease understanding where an error occured...
	public context = "";
	// If parsing lax the parser will sum up errors but will not stop parsing, errors are collected but the function always returns true
	public bLaxParsing = true;

	/**
	 * Constructs a FromJSONContext object, simply stores the handed over arguments in the class if provided
	 *
	 * @param bLaxParsing - Shall the decoder ignore errors and do best it can
	 */
	public constructor(bLaxParsing?: boolean) {
		if (bLaxParsing !== undefined)
			this.bLaxParsing = bLaxParsing;
	}
}

/**
 * The TypeScript JSON Converter class
 */
export class TSConverter {
	private static base64chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

	/**
	 * Type guard that ensures to work with a JSON object no matter if we provide a string or an object
	 *
	 * @param data - String or a JSON object
	 * @param errors - List of parsing errors
	 * @param context - context that is provided along with all the decoding operation
	 * @returns - a JSON object or undefined if an exception was catched (errors contains the error in that case)
	 */
	public static prepareData(data: string | object, errors: ParsingErrors, context: FromJSONContext): object | undefined {
		if (typeof data === "string") {
			try {
				return JSON.parse(data) as object;
			} catch (error) {
				let message = "Could not json parse - ";
				if (error instanceof SyntaxError)
					message += error.message;
				errors.push(new ParsingError(ParsingErrorType.PROPERTY_NULLORUNDEFINED, context.context, message));
				return undefined;
			}
		}
		return data;
	}

	/**
	 * Validate a param in a JSON object to meet an expected type and beeing existent
	 *
	 * @param data - A JSON object we are inspecting
	 * @param propertyName - Name of the property we are looking for
	 * @param expectedType - Type of object we expect
	 * @param errors - List of parsing errors
	 * @param context - context that is provided along with all the decoding operation
	 * @param optional - set to true if the parameter is optional (if optional, the parameter might be missing)
	 * @returns - true if the parameter in object data meets the expectations, or false other cases
	 */
	public static validateParam(data: object, propertyName: string, expectedType: "boolean" | "number" | "object" | "string", errors?: ParsingErrors, context?: FromJSONContext, optional?: boolean): boolean {
		if (!Object.prototype.hasOwnProperty.call(data, propertyName)) {
			if (errors != null && context != null) {
				if (!(optional === true))
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, context.context + "::" + propertyName, "property missing"));
			}
		} else {
			const property = (data as never)[propertyName];
			if (property == null) {
				if (errors != null && context != null)
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_NULLORUNDEFINED, context.context + "::" + propertyName, "property null or undefined"));
			} else if (typeof property !== expectedType) {
				if (errors != null && context != null)
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_TYPEMISMATCH, context.context + "::" + propertyName, "expected type was " + expectedType + ", effective type is " + typeof property));
			} else
				return true;
		}
		return false;
	}

	/**
	 * Validate a param in a JSON object for existence
	 *
	 * @param data - A JSON object we are inspecting
	 * @param propertyName - Name of the property we are looking for
	 * @param errors - List of parsing errors
	 * @param context - context that is provided along with all the decoding operation
	 * @param optional - set to true if the parameter is optional (if optional, the parameter might be missing)
	 * @returns - true if the parameter in object data meets the expectations, or false other cases
	 */
	public static validateAnyParam(data: object, propertyName: string, errors?: ParsingErrors, context?: FromJSONContext, optional?: boolean): boolean {
		if (!Object.prototype.hasOwnProperty.call(data, propertyName)) {
			if (errors != null && context != null) {
				if (!(optional === true))
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_MISSING, context.context + "::" + propertyName, "property missing"));
			}
		} else {
			const property = (data as never)[propertyName];
			if (property == null) {
				if (errors != null && context != null)
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_NULLORUNDEFINED, context.context + "::" + propertyName, "property null or undefined"));
			} else {
				const type = typeof property;
				if (type === "boolean" || type === "number" || type === "object" || type === "string")
					return this.validateParam(data, propertyName, type, errors, context);
				else if (errors != null && context != null)
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_TYPEMISMATCH, context.context + "::" + propertyName, "expected type was not found, effective type is " + type));
			}
		}
		return false;
	}

	/**
	 * Validate a choice param in a JSON object to meet an expected type and beeing existent
	 *
	 * @param data - A JSON object we are inspecting
	 * @param propertyName - Name of the property we are looking for
	 * @param expectedType - Type of object we expect
	 * @param errors - List of parsing errors
	 * @param context - context that is provided along with all the decoding operation
	 * @returns - true if the parameter in object data meets the expectations, or false other cases
	 */
	public static validateChoiceParam(data: object, propertyName: string, expectedType: "boolean" | "number" | "object" | "string", errors?: ParsingErrors, context?: FromJSONContext): boolean {
		if (Object.prototype.hasOwnProperty.call(data, propertyName)) {
			const datanever = data as never;
			if (datanever[propertyName] == null) {
				if (errors != null && context != null)
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_NULLORUNDEFINED, context.context + "::" + propertyName, "property null or undefined"));
			} else if (typeof datanever[propertyName] !== expectedType) {
				if (errors != null && context != null)
					errors.push(new ParsingError(ParsingErrorType.PROPERTY_TYPEMISMATCH, context.context + "::" + propertyName, "expected type was " + expectedType + ", effective type is " + typeof (datanever[propertyName])));
			} else
				return true;
		}
		return false;
	}

	/**
	 * Adds a calling context to log decoding errors.
	 * As we travers through the object in the tree we always add a new context whenever we walk in a branch
	 * Thus the decoder always knows for logging where the parser is currently parsing content
	 *
	 * @param context - An existing handed over context or undefined if we freshly create one
	 * @param parametername - The name of the parameter we are currently processing
	 * @param object - Or the object name we are currently processing in case the parameter name is missing
	 * @returns - The new adopted or created FromJSONContext
	 */
	public static addContext(context: undefined | FromJSONContext, parametername: string | undefined, object: string): FromJSONContext {
		const newContext = new FromJSONContext();
		if (context != null)
			Object.assign(newContext, context);

		if (newContext.context.length)
			newContext.context += "::";

		if (parametername != null)
			newContext.context += parametername;
		else
			newContext.context += object;
		return newContext;
	}

	/**
	 * Increased the indent and returns the new indent value (either the new string or undefined)
	 *
	 * @param context - An existing handed over context or undefined if we freshly create one
	 * @returns - The new adopted or created ToJSONContext
	 */
	public static increaseIndent(context?: ToJSONContext): ToJSONContext {
		const newContext = new ToJSONContext();
		if (!(context == null))
			Object.assign(newContext, context);
		newContext.bFirstElement = true;
		if (context != null && context.bPrettyPrint)
			newContext.indentation += "\t";
		return newContext;
	}

	/**
	 * Adds a boolean parameter to the JSON string output
	 *
	 * @param property - Name of the property we are adding
	 * @param value - The value to add
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The encoded property:value pair as JSON string
	 */
	public static addBooleanParam(property: string, value: boolean | undefined, context: ToJSONContext): string {
		if (value == null)
			return "";

		let str = this.addOpener(context);
		str += this.addProperty(property, context);
		str += value === true ? "true" : "false";
		return str;
	}

	/**
	 * Adds a number parameter to the json string output
	 *
	 * @param property - Name of the property we are adding
	 * @param value - The value to add
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The encoded property:value pair as JSON string
	 */
	public static addNumberParam(property: string, value: number | undefined, context: ToJSONContext): string {
		if (value == null)
			return "";

		let str = this.addOpener(context);
		str += this.addProperty(property, context);
		if (Number.isNaN(value)) {
			// Check what the object has been filled with. A number value was filled with an NaN value.
			// This needs to be checked and fixed when filling the object!
			debugger;
			str += "\"NaN\"";
		} else
			str += value.toString();
		return str;
	}

	/**
	 * Adds a string parameter to the json string output
	 *
	 * @param property - Name of the property we are adding
	 * @param value - The value to add
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The encoded property:value pair as JSON string
	 */
	public static addStringParam(property: string, value: string | undefined, context: ToJSONContext): string {
		if (value == null)
			return "";

		let str = this.addOpener(context);
		str += this.addProperty(property, context);
		str += "\"";
		str += this.escape(value);
		str += "\"";
		return str;
	}

	/**
	 * Adds a preformatted JSON string parameter to the json string output
	 *
	 * @param property - Name of the property we are adding
	 * @param value - The value to add
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The encoded property:value pair as JSON string
	 */
	public static addJSONStringParam(property: string, value: string | undefined, context: ToJSONContext): string {
		if (value == null)
			return "";

		const segments = value.split("\n");
		let str = this.addOpener(context);
		const indentation = this.addIndent(context);
		str += this.addProperty(property, context);
		let iCount = 0;
		for (const element of segments) {
			if (iCount && context.bPrettyPrint)
				str += "\n";
			if (iCount)
				str += indentation;
			str += element;
			iCount++;
		}
		return str;
	}

	/**
	 * Adds a date parameter to the json string output
	 *
	 * @param property - Name of the property we are adding
	 * @param value - The value to add
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The encoded property:value pair as JSON string
	 */
	public static addDateParam(property: string, value: Date | undefined, context: ToJSONContext): string {
		if (value == null)
			return "";

		let str = this.addOpener(context);
		str += this.addProperty(property, context);
		str += "\"";
		str += value.toISOString();
		str += "\"";
		return str;
	}

	/**
	 * Adds a binary parameter (base64encoded) to the json string output
	 *
	 * @param property - Name of the property we are adding
	 * @param value - The value to add
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The encoded property:value pair as JSON string
	 */
	public static addBinaryParam(property: string, value: Uint8Array | undefined, context: ToJSONContext): string {
		if (value == null || value.byteLength === 0)
			return "";

		let str = this.addOpener(context);
		str += this.addProperty(property, context);
		str += "\"";
		str += this.encode64(value);
		str += "\"";
		return str;
	}

	/**
	 * Adds a string parameter (unescaped) to the json string output
	 *
	 * @param property - Name of the property we are adding
	 * @param value - The value to add
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The encoded property:value pair as JSON string
	 */
	public static addObjectAsStringParam(property: string, value: string | undefined, context: ToJSONContext): string {
		if (value == null || (typeof value === "string" && value.length === 0))
			return "";

		let str = this.addOpener(context);
		str += this.addProperty(property, context);
		while (value.charAt(0) === " ")
			value = value.substring(1);
		str += value;

		return str;
	}

	/**
	 * Adds an any parameter to the json string output
	 *
	 * @param property - Name of the property we are adding
	 * @param value - The value to add
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The encoded property:value pair as JSON string
	 */
	public static addAnyParam(property: string, value: unknown, context: ToJSONContext): string {
		if (value == null)
			return "";

		if (typeof value === "number")
			return this.addNumberParam(property, value, context);
		else if (typeof value === "boolean")
			return this.addBooleanParam(property, value, context);
		else if (typeof value === "string")
			return this.addJSONStringParam(property, value, context);
		else if (typeof value === "object")
			return this.addObjectAsStringParam(property, JSON.stringify(value), context);
		else
			throw new Error(`TSConverterBase.addAnyParam failed to render object with type ${typeof value}`);
	}

	/**
	 * Adds an indented blob to the output
	 *
	 * @param leading - A prefix string in the output
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @param trailing - A suffix string in the output (or just the payload)
	 * @returns - The intended string
	 */
	public static addIndented(leading: string, context?: ToJSONContext, trailing?: string): string {
		let str = leading;
		str += this.addIndent(context);
		if (trailing != null)
			str += trailing;
		if (context == null || !context.bPrettyPrint)
			str = str.replace(/[\r\n\t]/g, "");
		return str;
	}

	/**
	 * Decodes base64 in a way that browsers and node support it
	 *
	 * @param base64data - The base64 data to decode
	 * @returns - The decoded base64 data
	 */
	public static decode64(base64data: string): Uint8Array {
		let paddingchars = 0;
		while (base64data.slice(-1) === "=") {
			paddingchars++;
			base64data = base64data.substring(0, base64data.length - 1);
		}
		const datalength = ((base64data.length * 3) - paddingchars) / 4;
		const binarydata = new Uint8Array(datalength);
		let j = 0;
		for (let i = 0; i < datalength; i += 3) {
			const e1 = this.base64chars.indexOf(base64data.charAt(j++));
			const e2 = this.base64chars.indexOf(base64data.charAt(j++));
			const e3 = this.base64chars.indexOf(base64data.charAt(j++));
			const e4 = this.base64chars.indexOf(base64data.charAt(j++));
			const c1 = (e1 << 2) | (e2 >> 4);
			const c2 = ((e2 & 15) << 4) | (e3 >> 2);
			const c3 = ((e3 & 3) << 6) | e4;
			binarydata[i] = c1;
			if (e3 !== 64)
				binarydata[i + 1] = c2;
			if (e4 !== 64)
				binarydata[i + 2] = c3;
		}
		return binarydata;
	}

	/**
	 * Encodes base64 in a way that browsers and node support it
	 *
	 * @param binarydata - The binary data to encode
	 * @returns - The encoded base64 data
	 */
	public static encode64(binarydata: Uint8Array): string {
		let base64data = "";
		const length = binarydata.byteLength;
		const padding = length % 3;
		const datalength = length - padding;

		for (let i = 0; i < datalength; i = i + 3) {
			const data1 = binarydata[i];
			const data2 = binarydata[i + 1];
			const data3 = binarydata[i + 2];
			if (data1 !== undefined && data2 !== undefined && data3 !== undefined) {
				const chunk = (data1 << 16) | (data2 << 8) | data3;
				const a = this.base64chars[(chunk & 16515072) >> 18];
				const b = this.base64chars[(chunk & 258048) >> 12];
				const c = this.base64chars[(chunk & 4032) >> 6];
				const d = this.base64chars[chunk & 63];
				if (a !== undefined && b !== undefined && c !== undefined && d !== undefined)
					base64data += a + b + c + d;
			}
		}
		if (padding === 1) {
			const chunk = binarydata[datalength];
			if (chunk !== undefined) {
				const a = this.base64chars[(chunk & 252) >> 2];
				const b = this.base64chars[(chunk & 3) << 4];
				if (a !== undefined && b !== undefined)
					base64data += a + b;
			}
		} else if (padding === 2) {
			const data1 = binarydata[datalength];
			const data2 = binarydata[datalength + 1];
			if (data1 !== undefined && data2 !== undefined) {
				const chunk = (data1 << 8) | data2;
				const a = this.base64chars[(chunk & 64512) >> 10];
				const b = this.base64chars[(chunk & 1008) >> 4];
				const c = this.base64chars[(chunk & 15) << 2];
				if (a !== undefined && b !== undefined && c !== undefined)
					base64data += a + b + c;
			}
		}
		return base64data;
	}

	/**
	 * Adds an opener if required by the ToJSONContext (new element begins)
	 *
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The opener to add to the output
	 */
	private static addOpener(context: ToJSONContext): string {
		let result = "";

		if (!context.bFirstElement) {
			result += ",";
			if (context.bPrettyPrint)
				result += "\n";
		}

		result += this.addIndent(context);
		context.bFirstElement = false;

		return result;
	}

	/**
	 * Adds a property to the output
	 *
	 * @param property - The property Name
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @returns - The propertyname as required for the output
	 */
	private static addProperty(property: string, context: ToJSONContext): string {
		let result = "";
		if (property.length) {
			result += "\"";
			result += property;
			result += "\":";
			if (context.bPrettyPrint)
				result += " ";
		}
		return result;
	}

	/**
	 * Adds an indent and returns the new indent value as string
	 *
	 * @param context - A to JSON Context that knows the context (e.g. indentation) while creating the output
	 * @param additional - Additional indentation steps to add
	 * @returns - The new indent as string
	 */
	private static addIndent(context?: ToJSONContext | undefined, additional = 0): string {
		if (context == null || context.indentation == null || !context.bPrettyPrint)
			return "";

		let str = context.indentation;
		while (additional > 0) {
			additional--;
			str += "\t";
		}
		return str;
	}

	/**
	 * Escapes a value
	 *
	 * @param str - A value to escape
	 * @returns - the escaped value
	 */
	private static escape(str: string): string {
		return str
			.replace(/[\\]/g, "\\\\")
			.replace(/["]/g, "\\\"")
			.replace(/[/]/g, "\\/")
			.replace(/[\b]/g, "\\b")
			.replace(/[\f]/g, "\\f")
			.replace(/[\n]/g, "\\n")
			.replace(/[\r]/g, "\\r")
			.replace(/[\t]/g, "\\t");
	}
}
