// [PrintTSCode]
// [PrintTSComments]
/*
 * ENetUC_Common_AsnContact.ts
 * "UC-Server-Access-Protocol-AsnContactCommon" ASN.1 stubs.
 * This file was generated by estos esnacc (V5.0.16, 10.08.2023)
 * based on Coral WinSnacc written by Deepak Gupta
 * NOTE: This is a machine generated file - editing not recommended
 */

// prettier-ignore
/* eslint-disable */
/**
 * Common interface definitions
 * ## Module description
 * This module contains structures that are mainly used in combination with the AsnContact
 * There should not be any function\/operation defintions in this module.
 *
 * THERE SHOULD NOT BE ANY FUNCTION\/OPERATION DEFINTIONS IN THIS MODULE.
 */
// [PrintTSImports]
import { TSDeprecatedCallback } from "./TSDeprecatedCallback";
import * as asn1ts from "@estos/asn1ts";
import * as ENetUC_Common from "./ENetUC_Common";
import * as ENetUC_Common_SIPCTI from "./ENetUC_Common_SIPCTI";
import * as ENetUC_Common_Appointments from "./ENetUC_Common_Appointments";
// [PrintTSRootTypes]
export const moduleName = "ENetUC_Common_AsnContact";

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnUserStaticRights {
	/** 0x00000001 - User may use text chat. */
	eUSERRIGHTSSENDUSERUSER = 1,
	/** 0x00000002 - User may use the SMS service. */
	eUSERRIGHTSSENDSMS = 2,
	/** 0x00000004 - User may login via WebServices. */
	eUSERRIGHTSEMPTY = 4,
	/** 0x00000008 - User may use the UCClient; should always be set. */
	eUSERRIGHTSUSEPROCALL = 8,
	/** 0x00000020 - User may change his account settings with the \"Workstation settings\" program. */
	eUSERRIGHTSSELFADMIN = 32,
	/** 0x00000040 - User may use CTI lines. */
	eUSERRIGHTSUSERCTI = 64,
	/** 0x00000080 - Reserved. */
	eDONOTUSE = 128,
	/** 0x00000100 - User may get email for calls while he was absent. */
	eUSERRIGHTSOFFLINEMAIL = 256,
	/** 0x00000200 - User may use call recorder. */
	eUSERRIGHTSCALLRECORDING = 512,
	/** 0x00000400 - User may use the search in phonebooks. */
	eUSERRIGHTSUSEPHONEBOOK = 1024,
	/** 0x00000800 - User is a CTI\/Presence user (should always be set). */
	eUSERRIGHTSUSERPRESENCE = 2048,
	/** 0x00001000 - Users of the group can see all other users in his monitor (available only for groups). */
	eUSERRIGHTSGROUPALLINMONITOR = 4096,
	/** 0x00002000 - Users of the group cannot change the content of their monitor (available only for groups). */
	eUSERRIGHTSGROUPNOCHANGEMONITOR = 8192,
	/** 0x00004000 - Users of the group may see the Journal\/History of other group members (available only for groups). */
	eUSERRIGHTSGROUPVIEWJOURNAL = 16384,
	/** 0x00008000 - The group leader may see the Journal\/History of all group members (available only for groups). */
	eUSERRIGHTSGROUPVIEWJOURNALLEADER = 32768,
	/** 0x00010000 - Users of a group may edit Journal\/History of other group members (available only for groups). */
	eUSERRIGHTSGROUPEDITJOURNAL = 65536,
	/** 0x00020000 - Reserved. */
	eDONOTUSE2 = 131072,
	/** 0x00040000 - Reserved. */
	eDONOTUSE3 = 262144,
	/** 0x00080000 - Journal\/History functions are available for the user; if not, acces to the journal database is not available for this user. */
	eUSERRIGHTSUSERVIEWJOURNAL = 524288,
	/** 0x00100000 - Every user of the system may delete Journal\/History entries. */
	eUSERRIGHTSGLOBALDELETEJOURNAL = 1048576,
	/** 0x00200000 - User may get SMS messages for calls whjile he is absent. */
	eUSERRIGHTSOFFLINESMS = 2097152,
	/** 0x00400000 - User may accept desktop sharing invitaions but cannot initiate own session. */
	eUSERRIGHTSDESKSHAREACCEPT = 4194304,
	/** 0x00800000 - User may accept or initiate desktop sharing session. */
	eUSERRIGHTSDESKSHAREFULL = 8388608,
	/** 0x01000000 - Reserved. */
	eDONOTUSE4 = 16777216,
	/** 0x02000000 - User may use audio communication features without video. */
	eUSERRIGHTSAVAUDIO = 33554432,
	/** 0x04000000 - User may use audio and video communication features. */
	eUSERRIGHTSAVVIDEO = 67108864,
	/** 0x08000000 - User may use desktop sharing over federation connections (experimental!). */
	eUSERRIGHTSFEDDESKSHARE = 134217728
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnUserStaticRights2 {
	/** 0x00000001 - User is visible for anonymous users (like web site vistors of LiveChat). */
	eUSERRIGHTS2ENABLEFORANONYMOUSAPI = 1,
	/** 0x00000002 - User may login from outside the company network (e.g. from mobile network GSM). */
	eUSERRIGHTS2LOGINOVERINTERNET = 2,
	/** 0x00000004 - User may login from a mobile device. */
	eUSERRIGHTS2LOGINBYMOBILE = 4,
	/** 0x00000008 - User is availible for the agent managemtn system; see Agent API for more details. Available for users and groups. */
	eUSERRIGHTS2ENABLEAGENTMANAGER = 8,
	/** 0x00000010 - User is agent subervisor and may change state of other agents. */
	eUSERRIGHTS2ISAGENTSUPERVISOR = 16,
	/** 0x00000020  - Group contains agents who may receive PUBLIC_CHATs and PUBLIC_CALLs. */
	eUSERRIGHTS2GROUPFORPUBLICCHAT = 32,
	/** 0x00000040 - Group contains agents who may be visible on the contact protal. (Named \"portal groups\" in the admin UI). */
	eUSERRIGHTS2GROUPFORPORTAL = 64,
	/** 0x00000080 - Group is available for administrative monitor content. */
	eUSERRIGHTS2GROUPFORMONITOR = 128,
	/**
	 * 0x00000100 - Internal use only.
	 * @private
	 */
	eUSERRIGHTS2ENABLEWEBCLIENTADMIN = 256,
	/**
	 * Content Sharing
	 * 0x00000200 - User is allowed to send binary data via the BinaryTransfer interface (default: allowed)
	 */
	eUSERRIGHTS2ENABLECONTENTSHARINGFILES = 512,
	/** 0x00000400 - User is allowed to share his location via the BinaryTransfer interface (default: allowed) */
	eUSERRIGHTS2ENABLECONTENTSHARINGLOCATION = 1024,
	/** 0x00000800 - User is allowed to share a contact via the BinaryTransfer interface (default: allowed) */
	eUSERRIGHTS2ENABLECONTENTSHARINGCONTACTS = 2048,
	/** 0x00001000 - User may use conferencing functionality - (default: off - needs seperate license!) */
	eUSERRIGHTS2USECONFERENCING = 4096
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnContactPhoneLineState {
	eLINEOFFLINE = 0,
	eLINEONLINE = 1
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnAbsentStateEnum {
	/** Available */
	eABSENTSTATENOTABSENT = 0,
	/** Out of office */
	eABSENTSTATEOUTOFOFFICE = 1,
	/** Busy */
	eABSENTSTATEBUSY = 2,
	/** Do not disturb */
	eABSENTSTATEDONOTDISTURB = 3,
	/** Absent state is not set */
	eABSENTSTATENOTSET = 4
}

// [PrintTSTypeDefCode]
// [PrintTSEnumDefCode]
export enum AsnReachabilityEnum {
	/** Empty (in the office) */
	eREACHABILITYNORMAL = 0,
	/** Pause */
	eREACHABILITYPAUSE = 1,
	/** Business trip */
	eREACHABILITYBUSINESSTRIP = 2,
	/** Home Office */
	eREACHABILITYHOMEOFFICE = 3,
	/** Vacation */
	eREACHABILITYVACATION = 4,
	/** Currently not in use */
	eREACHABILITYUNREACHABLE = 5
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * The authorizations a user has on another user or gets from him.
 * The \"dynamic rights\" are the authorizatio a user gives to or receives from another user. These are managed in levels and transported with these structures.
 */
export class AsnUserDynamicRights {
	public constructor(that: AsnUserDynamicRights) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnUserDynamicRights {
		return new AsnUserDynamicRights({
			u8sObjectID: "",
			iPresenceRights: 0,
			iPhoneControlRightsPrimary: 0,
			iPhoneControlRightsSecondary: 0
		});
	}

	public static type = "AsnUserDynamicRights";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnUserDynamicRights",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sObjectID" }),
				new asn1ts.Integer({ name: "iPresenceRights" }),
				new asn1ts.Integer({ name: "iPhoneControlRightsPrimary" }),
				new asn1ts.Integer({ name: "iPhoneControlRightsSecondary" })
			]
		});
	}

	public u8sObjectID!: string;
	public iPresenceRights!: number;
	public iPhoneControlRightsPrimary!: number;
	public iPhoneControlRightsSecondary!: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Call information for usage together with contacts.
 * To enable a subset of CTI functionallity in the context of presence\/contacts the AsnContactCallInformation provides basic Information
 * about a call the contact currently has. So a client can display the relevant information in its UI, filtered by authorization level on the server.
 *
 * A more complete and detailed call information is used in the CTI interface named AsnCtiCallInformation.
 *
 * @deprecated 13.07.2023 use AsnContactCallInformationEx instead (indexed and non indexed optionals)
 */
export class AsnContactCallInformation {
	public constructor(that: AsnContactCallInformation) {
		TSDeprecatedCallback.deprecatedObject(1689206400, moduleName, this);
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactCallInformation {
		return new AsnContactCallInformation({
			u8sContactChangedId: "",
			iPresenceState: 0,
			u8sConnectionID: "",
			u8sLinePhoneNumber: "",
			u8sCharging: "",
			iCallState: 0,
			iDisconnectedReason: 0,
			iFeatures: 0,
			bOutbound: false,
			bInternal: false,
			bPrivate: false,
			iCallRecordingState: 0,
			bConferenceCtlr: false,
			u8sCurrentNumberSC: ""
		});
	}

	public static type = "AsnContactCallInformation";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactCallInformation",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactChangedId" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				new asn1ts.Utf8String({ name: "u8sConnectionID" }),
				new asn1ts.Utf8String({ name: "u8sLinePhoneNumber" }),
				new asn1ts.Utf8String({ name: "u8sCharging" }),
				new asn1ts.Integer({ name: "iCallState" }),
				new asn1ts.Integer({ name: "iDisconnectedReason" }),
				new asn1ts.Integer({ name: "iFeatures" }),
				new asn1ts.Boolean({ name: "bOutbound" }),
				new asn1ts.Boolean({ name: "bInternal" }),
				new asn1ts.Boolean({ name: "bPrivate" }),
				new asn1ts.Integer({ name: "iCallRecordingState" }),
				new asn1ts.Boolean({ name: "bConferenceCtlr" }),
				new asn1ts.Utf8String({ name: "u8sCurrentNumberSC" }),
				new asn1ts.Utf8String({ name: "pu8sRedirectingNumber", optional: true }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "pRemoteContact", idBlock: { optionalID: 0 } }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", idBlock: { optionalID: 1 } })
			]
		});
	}

	/** The contact id of the contact this information is related to. */
	public u8sContactChangedId!: string;
	/** Actual presence state of the contact this information is related to. */
	public iPresenceState!: number;
	/** Unique identifier of the call. */
	public u8sConnectionID!: string;
	/** Line identfier (or phone number if URI is not available) of the phone */
	public u8sLinePhoneNumber!: string;
	/**
	 * line on which the call is active.
	 * @deprecated 21.10.2020 Call dues if the pBX supports such a feature.
	 */
	public u8sCharging!: string;
	/** Call state in which the call currently is. See EnumCallState for more details. */
	public iCallState!: number;
	/** If a call is disconnected, this could contain a reason for the disconnect. See EnumCallDisconnectedReason for more details. */
	public iDisconnectedReason!: number;
	/** This field contains the currently available call features - what can a client do with this call. See EnumCallFeatures for more details. */
	public iFeatures!: number;
	/** true if the call was initiated (dialed) on the attached line; false if the call is incoming from outside. */
	public bOutbound!: boolean;
	/** true if the call terminates in the same PBX; false if the other device is not attached to this PBX. */
	public bInternal!: boolean;
	/** true if the call is marked as private, false if not. */
	public bPrivate!: boolean;
	/** Call recording state of this call. See EnumCallRecordingState for more details. */
	public iCallRecordingState!: number;
	/** true if this call is used to control a conference. */
	public bConferenceCtlr!: boolean;
	/** Phone number of the partner. */
	public u8sCurrentNumberSC!: string;
	/** May contain the number of the line on which the call was received first and then redirected. */
	public pu8sRedirectingNumber?: string;
	/** If present, this Field contains contact data attached to this call (UCServer intern only) */
	public pRemoteContact?: ENetUC_Common.AsnNetDatabaseContact;
	/**
	 * optionalParams Name \"TrackerID\" Value String
	 * optionalParams Name \"eJournalCallType\" Value Number
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
export class AsnContactCallInformationEx {
	public constructor(that: AsnContactCallInformationEx) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactCallInformationEx {
		return new AsnContactCallInformationEx({
			u8sContactChangedId: "",
			iPresenceState: 0,
			u8sConnectionID: "",
			u8sLinePhoneNumber: "",
			u8sCharging: "",
			iCallState: 0,
			iDisconnectedReason: 0,
			iFeatures: 0,
			bOutbound: false,
			bInternal: false,
			bPrivate: false,
			iCallRecordingState: 0,
			bConferenceCtlr: false,
			u8sCurrentNumberSC: ""
		});
	}

	public static type = "AsnContactCallInformationEx";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactCallInformationEx",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactChangedId" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				new asn1ts.Utf8String({ name: "u8sConnectionID" }),
				new asn1ts.Utf8String({ name: "u8sLinePhoneNumber" }),
				new asn1ts.Utf8String({ name: "u8sCharging" }),
				new asn1ts.Integer({ name: "iCallState" }),
				new asn1ts.Integer({ name: "iDisconnectedReason" }),
				new asn1ts.Integer({ name: "iFeatures" }),
				new asn1ts.Boolean({ name: "bOutbound" }),
				new asn1ts.Boolean({ name: "bInternal" }),
				new asn1ts.Boolean({ name: "bPrivate" }),
				new asn1ts.Integer({ name: "iCallRecordingState" }),
				new asn1ts.Boolean({ name: "bConferenceCtlr" }),
				new asn1ts.Utf8String({ name: "u8sCurrentNumberSC" }),
				new asn1ts.Utf8String({ name: "pu8sRedirectingNumber", idBlock: { optionalID: 0 } }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "pRemoteContact", idBlock: { optionalID: 1 } }),
				new asn1ts.Utf8String({ name: "u8sTrackerID", idBlock: { optionalID: 2 } }),
				new asn1ts.Integer({ name: "eJournalCallType", idBlock: { optionalID: 3 } })
			]
		});
	}

	/** The contact id of the contact this information is related to. */
	public u8sContactChangedId!: string;
	/** Actual presence state of the contact this information is related to. */
	public iPresenceState!: number;
	/** Unique identifier of the call. */
	public u8sConnectionID!: string;
	/** Line identfier (or phone number if URI is not available) of the phone */
	public u8sLinePhoneNumber!: string;
	/**
	 * line on which the call is active.
	 * @deprecated 21.10.2020 Call dues if the pBX supports such a feature.
	 */
	public u8sCharging!: string;
	/** Call state in which the call currently is. See EnumCallState for more details. */
	public iCallState!: number;
	/** If a call is disconnected, this could contain a reason for the disconnect. See EnumCallDisconnectedReason for more details. */
	public iDisconnectedReason!: number;
	/** This field contains the currently available call features - what can a client do with this call. See EnumCallFeatures for more details. */
	public iFeatures!: number;
	/** true if the call was initiated (dialed) on the attached line; false if the call is incoming from outside. */
	public bOutbound!: boolean;
	/** true if the call terminates in the same PBX; false if the other device is not attached to this PBX. */
	public bInternal!: boolean;
	/** true if the call is marked as private, false if not. */
	public bPrivate!: boolean;
	/** Call recording state of this call. See EnumCallRecordingState for more details. */
	public iCallRecordingState!: number;
	/** true if this call is used to control a conference. */
	public bConferenceCtlr!: boolean;
	/** Phone number of the partner. */
	public u8sCurrentNumberSC!: string;
	/** May contain the number of the line on which the call was received first and then redirected. */
	public pu8sRedirectingNumber?: string;
	/** If present, this Field contains contact data attached to this call (UCServer intern only) */
	public pRemoteContact?: ENetUC_Common.AsnNetDatabaseContact;
	/** the call tracker id */
	public u8sTrackerID?: string;
	/** the journal call type */
	public eJournalCallType?: number;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * The absent state of a user\/contact in the ucserver.
 * AsnAbsentSate is used to describe the manual presence of a User.
 * The manual presence is used to override the system calculated presence state of the user until a new system
 * state is calculated or the system calculated one is back to \"available\" again.
 * So the manual presence overrides the actual presence at the moment it is set, but it will be overridden by new system
 * events other than \"available\".
 */
export class AsnAbsentState {
	public constructor(that: AsnAbsentState) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnAbsentState {
		return new AsnAbsentState({
			u8sContactId: "",
			u8sUsername: "",
			stTimeFrom: new Date(),
			stTimeTo: new Date(),
			iAbsentState: 0,
			u8sMessage: "",
			u8sAbsentReason: "",
			iPresenceState: 0
		});
	}

	public static type = "AsnAbsentState";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnAbsentState",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactId" }),
				new asn1ts.Utf8String({ name: "u8sUsername" }),
				new asn1ts.Real({ name: "stTimeFrom" }),
				new asn1ts.Real({ name: "stTimeTo" }),
				new asn1ts.Integer({ name: "iAbsentState" }),
				new asn1ts.Utf8String({ name: "u8sMessage" }),
				new asn1ts.Utf8String({ name: "u8sAbsentReason" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** The Contact ID of the user. */
	public u8sContactId!: string;
	/** The display name of the user. */
	public u8sUsername!: string;
	/** Timestamp from which the state is valid. */
	public stTimeFrom!: Date;
	/** Timestamp the state state is valid to. */
	public stTimeTo!: Date;
	/** The state to set. See AsnAbsentStateEnum for details. */
	public iAbsentState!: number;
	/**
	 * Deprecated, should be empty.
	 * @deprecated 21.10.2020 *
	 */
	public u8sMessage!: string;
	/**
	 * Deprecated, should be empty.
	 * @deprecated 21.10.2020 *
	 */
	public u8sAbsentReason!: string;
	/**
	 * The presence state of the contact. This is a 32 Bit field assembled with values from AsnPresenceStateEnum and bits of AsnPresenceStateFlags.
	 *
	 * If the AsnAbsentState structure is sent from the UCServer, this field contains the resulting presence state (calculated). This is the same value as in iPresenceState of AsnPresence.
	 *
	 * If the AsnAbsentState is sent from the client to the server, this field is ignored.
	 */
	public iPresenceState!: number;
	/**
	 * Optonal parameters
	 * - Key \"iReachability\" Value INTEGER - The reachability of the user, see AsnReachabilityEnum for details.
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Structure for handling the user defined note.
 */
export class AsnCustomNote {
	public constructor(that: AsnCustomNote) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnCustomNote {
		return new AsnCustomNote({
			u8sContactID: "",
			u8sUsername: "",
			u8sNote: ""
		});
	}

	public static type = "AsnCustomNote";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnCustomNote",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactID" }),
				new asn1ts.Utf8String({ name: "u8sUsername" }),
				new asn1ts.Utf8String({ name: "u8sNote" })
			]
		});
	}

	/** Contact ID of the user. */
	public u8sContactID!: string;
	/** The display name of the user. */
	public u8sUsername!: string;
	/** The text the user has set. */
	public u8sNote!: string;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnContactCallInformations extends Array<AsnContactCallInformation> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnContactCallInformation.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * Phone line object which is used in the presence object model.
 * Not every client will have full CTI functionality, but has to display some information baout the
 * lines and phone states of users. For this AsnContactPhoneLine represents teh state of a line in the presence model.
 */
export class AsnContactPhoneLine {
	public constructor(that: AsnContactPhoneLine) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContactPhoneLine {
		return new AsnContactPhoneLine({
			iType: 0,
			u8sLinePhoneNumber: "",
			u8sDescriptor: "",
			u8sNumber: "",
			seqCalls: new AsnContactCallInformations(),
			seqLineForwards: new ENetUC_Common_SIPCTI.AsnLineForwards(),
			iState: AsnContactPhoneLineState.eLINEOFFLINE,
			bDoNotDisturb: false,
			iForwardFeaturesPBX: 0
		});
	}

	public static type = "AsnContactPhoneLine";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContactPhoneLine",
			...params,
			value: [
				new asn1ts.Integer({ name: "iType" }),
				new asn1ts.Utf8String({ name: "u8sLinePhoneNumber" }),
				new asn1ts.Utf8String({ name: "u8sDescriptor" }),
				new asn1ts.Utf8String({ name: "u8sNumber" }),
				AsnContactCallInformations.getASN1Schema({ name: "seqCalls" }),
				ENetUC_Common_SIPCTI.AsnLineForwards.getASN1Schema({ name: "seqLineForwards" }),
				new asn1ts.Enumerated({ name: "iState" }),
				new asn1ts.Boolean({ name: "bDoNotDisturb" }),
				new asn1ts.Integer({ name: "iForwardFeaturesPBX" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/** Enum for the type of the line. See AsnContactPhoneLineType for details. */
	public iType!: number;
	/** The identificator of a line. This could be a Phonenumber or a phone URI. */
	public u8sLinePhoneNumber!: string;
	/** Description text for a line. */
	public u8sDescriptor!: string;
	/** Phone number of the line. */
	public u8sNumber!: string;
	/** Call which are currently on the line. */
	public seqCalls!: AsnContactCallInformations;
	/** Line forwardings currently set on the line. */
	public seqLineForwards!: ENetUC_Common_SIPCTI.AsnLineForwards;
	/** The line state. See AsnContactPhoneLineState for details (eLINEOFFLINE, eLINEONLINE, etc.) */
	public iState!: number;
	/** Do not disturb flag for this line. TRUE do not disturb is active, FALSE not. */
	public bDoNotDisturb!: boolean;
	/** Which sort of call forward is available on this line. See AsnForwardMode for details. */
	public iForwardFeaturesPBX!: number;
	/**
	 * Optonal parameters
	 * - Key \"AddressNames\" Value UTF8String - ';' separated list of line addresses.
	 * - Key \"AddressCaps\" Value UTF8String - XML with the capabilities of the line.
	 * - Key \"CTIFeatureCodes\" Value UTF8String - XML with the feature codes available for this line.
	 * - Key \"PrivateLine\" Value Bool - true: the line has to be treated as private, false: normal line, no private restrictions.
	 * - Key \"SIPAVMediaMode\" Value Integer - Media Mode on this audio\/video line; only available if the line ia an A\/V line.
	 * - Key \"SIPAVAudioCodecs\" Value List of Integer - Available codecs on this line;  only available if the line ia an A\/V line.
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnContactPhoneLines extends Array<AsnContactPhoneLine> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnContactPhoneLine.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSeqDefCode]
/**
 * The interface represntation of an ActveContact (or short \"contact\") of the UCServer with static contact and dynamic presence data.
 * The UCServer uses a technology called \"ActiveContacts\", whcih are represntinga contact with all its static contact informations and all
 * its dynamic\/calculated presence informations fromthe different services used by this contact (phone lines, calendar, federation etc.)
 *
 * All the data is filtered by the UCServer depending on the persmissions of the calling client\/user. So not every client will get all the information.
 */
export class AsnContact {
	public constructor(that: AsnContact) {
		Object.assign(this, that);
	}

	public static initEmpty(): AsnContact {
		return new AsnContact({
			u8sContactId: "",
			iContactFeatureSet: 0,
			iPresenceState: 0,
			iStaticRights: 0,
			iMonitorAccessRights: 0,
			asnRemoteContact: ENetUC_Common.AsnNetDatabaseContact.initEmpty(),
			seqPhoneLines: new AsnContactPhoneLines(),
			asnAbsentState: AsnAbsentState.initEmpty(),
			asnCustomNote: AsnCustomNote.initEmpty(),
			seqActualAppointments: new ENetUC_Common_Appointments.SEQAppointmentList(),
			asnNextAppointment: ENetUC_Common_Appointments.AsnAppointmentEntry.initEmpty(),
			asnUserPropertyBag: ENetUC_Common.AsnUserPropertyBag.initEmpty()
		});
	}

	public static type = "AsnContact";

	public static getASN1Schema(params?: asn1ts.ConstructedParams): asn1ts.Sequence {
		return new asn1ts.Sequence({
			name: "AsnContact",
			...params,
			value: [
				new asn1ts.Utf8String({ name: "u8sContactId" }),
				new asn1ts.Integer({ name: "iContactFeatureSet" }),
				new asn1ts.Integer({ name: "iPresenceState" }),
				new asn1ts.Integer({ name: "iStaticRights" }),
				new asn1ts.Integer({ name: "iMonitorAccessRights" }),
				ENetUC_Common.AsnNetDatabaseContact.getASN1Schema({ name: "asnRemoteContact" }),
				AsnContactPhoneLines.getASN1Schema({ name: "seqPhoneLines" }),
				AsnAbsentState.getASN1Schema({ name: "asnAbsentState" }),
				AsnCustomNote.getASN1Schema({ name: "asnCustomNote" }),
				ENetUC_Common_Appointments.SEQAppointmentList.getASN1Schema({ name: "seqActualAppointments" }),
				ENetUC_Common_Appointments.AsnAppointmentEntry.getASN1Schema({ name: "asnNextAppointment" }),
				ENetUC_Common.AsnUserPropertyBag.getASN1Schema({ name: "asnUserPropertyBag" }),
				ENetUC_Common.AsnOptionalParameters.getASN1Schema({ name: "optionalParams", optional: true })
			]
		});
	}

	/**
	 * The ID of the contact, which is used to get the presence infromations and updates form the server. Note: This ID is only unique for the presence information.
	 * The contact data behind this is depending on the data source, where the data is copied from while adding the contact to the active user managment.
	 * This means, a contact may have the same contact ID but different contact data if it is read from another database. To distiguish the different contact
	 * informations of the same contact see the fields u8sEntryIDDB, u8sEntryIDStore and u8sEntryID of AsnNetDatabaseContact.
	 */
	public u8sContactId!: string;
	/** FeatureSet of the contact on the UCServer. See AsnContactFeatureSetEnum fro more details.\ @linked AsnContactFeatureSetEnum */
	public iContactFeatureSet!: number;
	/** The presence state of the contact. This is a 32 Bit field assembled with values from AsnPresenceStateEnum and bits of AsnPresenceStateFlags. */
	public iPresenceState!: number;
	/** The active contact may use services and features of the UCServer. The iStaticRights are corresponding rights the admin granted. See AsnUserStaticRights for more details. */
	public iStaticRights!: number;
	/** These are the rights, the active contact has on the additional lines the administrator configured for him in the user manager. */
	public iMonitorAccessRights!: number;
	/** The static contact data of this contact. */
	public asnRemoteContact!: ENetUC_Common.AsnNetDatabaseContact;
	/** The actual line\/call state of the contact. Each line may contain the actual calls a contact currently has. */
	public seqPhoneLines!: AsnContactPhoneLines;
	/**
	 * The actual absent state of the contact. The absent state is the presence state of the user he set manually.
	 * This may be overwritten by changes on the different services, the presence of the contact is calculated from (phone, appointment etc.)
	 */
	public asnAbsentState!: AsnAbsentState;
	/** The contact can set a short note for other users. */
	public asnCustomNote!: AsnCustomNote;
	/** List of the currently active appointments of the contact. */
	public seqActualAppointments!: ENetUC_Common_Appointments.SEQAppointmentList;
	/**
	 * The next appointment the contact will have. (Helps to display an information like \"free for the next x hours\")
	 * Der n�chste Termin der aktiv wird
	 */
	public asnNextAppointment!: ENetUC_Common_Appointments.AsnAppointmentEntry;
	/** Additional information for this contact added for special use cases. */
	public asnUserPropertyBag!: ENetUC_Common.AsnUserPropertyBag;
	/**
	 * Optonal parameters
	 * - Key \"iStaticRights2\" Value DWORD - Additional rights of the contact on serviced added after PC 5.1. See AsnUserStaticRights2 for details.
	 */
	public optionalParams?: ENetUC_Common.AsnOptionalParameters;
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnUserDynamicRightsList extends Array<AsnUserDynamicRights> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnUserDynamicRights.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnContactCallInformationsEx extends Array<AsnContactCallInformationEx> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnContactCallInformationEx.getASN1Schema()
		});
	}
}

// [PrintTSTypeDefCode]
// [PrintTSSetOfDefCode]
// [PrintTSListClass]
export class AsnContacts extends Array<AsnContact> {
	public static getASN1Schema(params?: asn1ts.SequenceOfParams): asn1ts.SequenceOf {
		return new asn1ts.SequenceOf({
			...params,
			value: AsnContact.getASN1Schema()
		});
	}
}
