import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "../../components/common/Typography/Typography";
import { ContactList } from "../../components/ContactList/ContactList";
import { AsnNetDatabaseContact, AsnNetDatabaseContactList } from "../../ucserver/stubs/ENetUC_Common";

interface IContactListProps {
	favoriteContacts: AsnNetDatabaseContactList;
	selectedContactId: string | null;
	onClick: (contact: AsnNetDatabaseContact) => void;
}

export const FavoriteContacts: FC<IContactListProps> = ({ selectedContactId, favoriteContacts, onClick }) => {
	const { t } = useTranslation();

	return (
		<>
			<Typography variant="body2" sx={{ pl: 2 }}>
				{t("IDS_CONTACT_VIP_CONTACTS")}
			</Typography>
			<ContactList contacts={favoriteContacts} onClick={onClick} selectedContactId={selectedContactId} />
		</>
	);
};
